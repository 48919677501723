import { gql } from '@apollo/client/core';
import { inject, Injectable, Signal } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { from, map } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { CommunicationZoneInterface } from '../../../interfaces/communication-zone.interface';
import { ProfileSettingsVar } from '../../locals/profileSettings.var';
import { ProfileSettingInterface } from '../../../core.interfaces';

export const InsertCommunicationZoneMutation = gql`
    mutation insertCommunicationZoneMutation(
        $content: String!
        $title: String!
        $contactPerson: String!
        $referenceId: ID,
        $referenceType: String
    ) {
        SupportEnquiry(
            content: $content,
            title: $title,
            contactPerson: $contactPerson,
            referenceId: $referenceId
            referenceType: $referenceType
        ) {
            status
            message
        }
    }
`;

export const InsertOrderCommunicationZoneMutation = gql`
    mutation insertOrderCommunicationZoneMutation(
        $content: String!
        $contactPerson: String!
        $orderId: ID!
    ) {
        OrderSupportEnquiry(
            content: $content,
            contactPerson: $contactPerson,
            orderId: $orderId
        ) {
            status
            message
        }
    }
`;

export const UpdateCommunicationSolutionMutation = gql`
    mutation updateCommunicationSolutionMutation($id: Int!, $solved: Boolean!) {
        update_communicationZone_by_pk(_set: {solved: $solved}, pk_columns: {id: $id}) {
            id
            solved
        }
    }
`;

export const AllCommunicationZoneMutations = [
    InsertCommunicationZoneMutation,
    InsertOrderCommunicationZoneMutation,
    UpdateCommunicationSolutionMutation
];

@Injectable()
export class CommunicationZoneMutations{
    private apollo = inject(Apollo);
    private apiService = inject(ApiService);
    private profileSettingsVar = inject(ProfileSettingsVar);

    profileSettings: Signal<ProfileSettingInterface> = this.profileSettingsVar.profileSettings;

    /**
     * trim values
     * @param data - Data to trim (string or null)
     * @return string|null
     */
    formatInputs = (data) => {
        if (typeof data === 'string') {
            return data.trim().length > 0 ? data.trim() : null;
        }
        return data;
    }

    insertCommunicationZone(data: CommunicationZoneInterface) {
        let email = this.profileSettings().user.email || '';
        const maxValidCharacter = 120;
        const remainingCharacter = maxValidCharacter - data.contactPerson.length - 3;
        if(email.length > remainingCharacter){
            email = email.substring(0, remainingCharacter);
        }
        const communicationZoneData = {
            content: this.formatInputs(data.content),
            title: this.formatInputs(data.title.trim()),
            contactPerson: this.formatInputs(data.contactPerson + ' - ' + email),
            referenceId: data.referenceId,
            referenceType: this.formatInputs(data.referenceType)
        };
        this.apollo.mutate({
            mutation: InsertCommunicationZoneMutation,
            variables: communicationZoneData
        }).subscribe({
            next: result => {
                from([result])
                    .pipe(map(d => d?.data && d?.data['SupportEnquiry'] && d?.data['SupportEnquiry']['status']))
                    .subscribe((status: string) => {
                        if (!status || status === 'ERROR' || !!result['errors']) {
                            void this.apiService.presentErrorToast(
                                result['errors'],
                                'Die Anfrage konnte nicht an Ihr Kundenservice-Team übermittelt werden.'
                            );
                        } else {
                            void this.apiService.presentSuccessToastNoDuration('Ihr Kundenservice-Team bearbeitet nun Ihre Anfrage. ' +
                                'Ihre Anfragen finden Sie im Bereich "Kommunikation".');
                        }
                    }).unsubscribe();
            },
            error: error => this.apiService.presentErrorToast(error, 'Die Anfrage konnte nicht an Ihr Kundenservice-Team übermittelt werden.')
        });
    }

    insertOrderCommunicationZone(data: CommunicationZoneInterface) {
        let email = this.profileSettings().user.email || '';
        const maxValidCharacter = 120;
        const remainingCharacter = maxValidCharacter - data.contactPerson.length - 3;
        if(email.length > remainingCharacter){
            email = email.substring(0, remainingCharacter);
        }
        const communicationZoneData = {
            content: this.formatInputs(data.content),
            contactPerson: this.formatInputs(data.contactPerson + ' - ' + email),
            orderId: data.orderId
        };
        this.apollo.mutate({
            mutation: InsertOrderCommunicationZoneMutation,
            variables: communicationZoneData
        }).subscribe({
            next: result => {
                from([result])
                    .pipe(map(d => d?.data && d?.data['OrderSupportEnquiry'] && d?.data['OrderSupportEnquiry']['status']))
                    .subscribe((status: string) => {
                        if (!status || status === 'ERROR' || !!result['errors']) {
                            void this.apiService.presentErrorToast(
                                result['errors'],
                                'Die Anfrage konnte nicht an Ihr Kundenservice-Team übermittelt werden.'
                            );
                        } else {
                            void this.apiService.presentSuccessToast('Die Anfrage wurde erfolgreich an Ihr Kundenservice-Team ' +
                                'versendet. Alle Anfragen sind im Bereich "Kommunikation" einsehbar');
                        }
                    }).unsubscribe();
            },
            error: error => this.apiService.presentErrorToast(error, 'Die Anfrage konnte nicht an Ihr Kundenservice-Team übermittelt werden.')
        });
    }

    updateCommunicationSolution(solutionId: number, isSolved: boolean) {
        this.apollo.mutate({
            mutation: UpdateCommunicationSolutionMutation,
            variables: {
                id: solutionId,
                solved: isSolved
            }
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['update_communicationZone_by_pk'] && d?.data['update_communicationZone_by_pk']['solved']))
                  .subscribe((solved: boolean) => {
                      void this.apiService.presentSuccessToast(solved ?
                        'Die Supportanfrage wurde als gelöst markiert!' :
                        'Die Supportanfrage wurde als ungelöst markiert!');
                  }).unsubscribe();
            },
            error: error => this.apiService.presentErrorToast(error, 'Es ist ein Fehler bei der Speicherung des gelöst-Status aufgetreten.')
        });
    }
}
