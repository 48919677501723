import gql from 'graphql-tag';

export default gql`
  query getUserKey($user: ID!) {
    getUserKey(user: $user) {
      __typename
      user
      salt
      key
    }
  }
`;
