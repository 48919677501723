import { inject, Injectable, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { switchMap, tap } from 'rxjs';
import { DynamicPageQueries } from '../store/graphql/queries/dynamicPage.graphql';
import { DynamicPageInterface } from '../interfaces/dynamicPage.interface';
import { DynamicPageDisplayLocation } from '../enums/dynamicPage.enum';

@Injectable({
    providedIn: 'root',
})
export class DynamicPageService {
    private dynamicPageQueries = inject(DynamicPageQueries);


    // Dynamic Page Signal
    private _dynamicPage = signal<DynamicPageInterface>(null);
    public dynamicPage = this._dynamicPage.asReadonly();

    // isLoading Signal
    private _isLoading = signal<boolean>(true);
    public isLoading = this._isLoading.asReadonly();

    // Filters Signal
    private _displayLocation = signal<DynamicPageDisplayLocation>(DynamicPageDisplayLocation.mea);
    public displayLocation = this._displayLocation.asReadonly();

    // Receive new dynamicPage and update the signals
    private dynamicPage$ = toObservable(this._displayLocation).pipe(
        switchMap( displayLocation => (
            this.dynamicPageQueries.getDynamicPage(displayLocation)
        )),
        tap(data => {
            this._dynamicPage.set(data);
            this._isLoading.set(false);
        })
    );

    // toSignal automatically subscribes and unsubscribes to the observable
    dynamicPageReadOnly = toSignal(this.dynamicPage$, {initialValue: null as DynamicPageInterface});


    /**
     * Update of selected displayLocation
     *
     * @param displayLocation - the changed displayLocation
     */
    public setDisplayLocation(displayLocation: DynamicPageDisplayLocation): void {
        this._displayLocation.update(() =>
            (displayLocation)
        );
    }
}
