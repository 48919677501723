import { computed, effect, inject, signal, untracked } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Storage } from '@ionic/storage-angular';
import { combineLatest, debounceTime, map, Observable, switchMap, tap } from 'rxjs';
import { read, writeFile } from 'xlsx';
import { ReturnsQueries } from '../../../../../core/store/graphql/queries/returns.graphql';
import { ReturnsDetailInterface, ReturnsFiltersInterface, ReturnsInterface } from '../../../../../core/interfaces/returns.interface';
import { defaultReturnsFilters } from '../../../../../core/config/returns-filter.config';
import { DateSelectionEventInterface, DateSelectionFilterInterface } from '../../../../../core/interfaces/date-range.interface';
import {
    TableColumnInterface,
    TableFilterConfigInterface,
    TableSearchModalConfigInterface,
    TableSidebarInterface,
    TableSidebarItemsInterface,
    TableSortInterface
} from '../../../../../core/interfaces/table.interface';
import { TableFieldsEnum, TableFilterTypeEnum } from '../../../../../core/enums/table.enum';
import { DateRangeConfig } from '../../../../../core/config/date-range.config';
import { getReturnsStatusWithAll, getReturnsTypeWithAll } from '../../../../../core/config/returns.config';
import { NotesInterface } from '../../../../../core/interfaces/notes.interface';
import { deliveryNumberFormatting } from '../../../../../core/util/transformations.util';
import { GraphQLLimits } from '../../../../../core/config/graphql-limits.config';
import { ReturnsSortInterface } from '../../../../../core/interfaces/sorting.interface';
import { SortDirectionEnum } from '../../../../../core/enums/sort-direction.enum';
import { AuthStorageKeyEnum } from '../../../../../core/enums/authStorageKey.enum';
import { ButtonTypes } from '../../../../../core/enums/button-actions.enum';
import { BadgeTypeEnum } from '../../../../../core/enums/badge.enum';
import { FormatCurrencyPipe } from '../../../../../core/pipes/format-currency.pipe';
import { DocumentNumberFormattedPipe } from '../../../../../core/pipes/document-type.pipe';
import { ModalService } from '../../../../../core/services/modal.service';
import { ReturnsViewComponent } from '../widgets/returns-view/returns-view.component';
import {
    CommunicationZoneFormComponent
} from '../../../../communications/pages/communication-zone/widgets/communication-zone-form/communication-zone-form.component';
import { NoteWidgetComponent } from '../../../../../widgets/note-widget/note-widget.component';
import { NotesTypeEnum } from '../../../../../core/enums/notes.enum';
import { ExportFormatEnum } from '../../../../../core/enums/export-format.enum';
import { formatDateTimeToDate, getDate } from '../../../../../core/formatting/date.formatting';
import { ExcelQueries } from '../../../../../core/store/graphql/queries/excel.graphql';
import { ApiService } from '../../../../../core/services/api.service';
import { PdfProviderService } from '../../../../../core/services/pdfProvider.service';
import { PrintPdfTableTypeEnum } from '../../../../../core/enums/tableToPdf.enum';
import { unsubscribe } from '../../../../../core/util/subscriptions.util';
import { ToolbarStateVar } from '../../../../../core/store/locals/toolbarState.var';

/**
 * The returns service is not provided application wide but on a component level. If a component uses this service, use it as a provider in the
 * component annotation.
 */
export class ReturnsService {
    private returnsQueries = inject(ReturnsQueries);
    private storage = inject(Storage);
    private formatCurrency = inject(FormatCurrencyPipe);
    private documentNumberFormatted = inject(DocumentNumberFormattedPipe);
    private modalService = inject(ModalService);
    private excelQueries = inject(ExcelQueries);
    private apiService = inject(ApiService);
    private tableToPdfService = inject(PdfProviderService);
    private toolbarState = inject(ToolbarStateVar);

    public readonly columns: TableColumnInterface[] = [
        {id: 1, title: 'Name',    width: '11rem', class:'col-auto', sortable: true, dataKey: TableFieldsEnum.productName},
        {id: 2, title: 'Datum',   width: '8rem', sortable: true, dataKey: TableFieldsEnum.recTime},
        {id: 3, title: 'Typ',     width: '8rem', sortable: true, dataKey: TableFieldsEnum.type},
        {id: 4, title: 'Wert €',  width: '5rem', sortable: true, dataKey: TableFieldsEnum.price},
        {id: 5, title: 'Menge',   width: '5rem', sortable: true, dataKey: TableFieldsEnum.quantity},
        {id: 6, title: 'Status',  width: '10rem', sortable: true, dataKey: TableFieldsEnum.status},
        {id: 7, title: 'Notiz',   width: '5rem', sortable: true, dataKey: TableFieldsEnum.notes},
        {id: 8, title: '',        width: '2rem', sortable: false},
    ];

    /* ################ filters ################ */
    private _filters = signal<ReturnsFiltersInterface>(defaultReturnsFilters);
    public filters = this._filters.asReadonly();
    public dateFilter = computed<DateSelectionFilterInterface>(() => {
        const filters = this.filters();
        return {
            label: 'Datum',
            selectedValue: filters?.dateOption,
            selectedValues: {
                dateOption: filters?.dateOption,
                dateRangeOptions: DateRangeConfig,
                dateFrom: filters?.dateFrom,
                dateTo: filters?.dateTo,
            },
        };
    });
    public deliveryDateFilter = computed<DateSelectionFilterInterface>(() => {
        const filters = this.filters();
        return {
            label: 'Lieferbeleg Datum',
            selectedValue: filters?.deliveryNoteDateOption,
            selectedValues: {
                dateOption: filters?.deliveryNoteDateOption,
                dateRangeOptions: DateRangeConfig,
                dateFrom: filters?.deliveryNoteDateFrom,
                dateTo: filters?.deliveryNoteDateTo,
            },
        };
    });

    /* ################ sort ################ */
    private _sort = signal<TableSortInterface>({
        field: TableFieldsEnum.recTime,
        order: SortDirectionEnum.desc,
        onUpdate: (field: TableFieldsEnum) => {
            this.updateSort(field);
        }
    });
    public sort = this._sort.asReadonly();

    /**
     * Used to keep track of the current offset of the table when lazy loading is enabled and data is loaded during scrolling
     * @private
     */
    private _currentOffset = signal<number>(0);
    private _currentOffset$ = toObservable(this._currentOffset);

    /* ################ returns data ################ */
    private _returns$ = combineLatest([toObservable(this._filters), toObservable(this._sort)]).pipe(
        switchMap(([filters, sort]) => {
            const returnsSort: ReturnsSortInterface = {
                field: sort.field,
                direction: sort.order
            };

            // Filters or Sort has changed, so we need to reset the offset
            this._currentOffset.set(0);

            return this.returnsQueries.getReturnsCount(filters).pipe(
                switchMap(totalRows => {
                    const fullArray = new Array(totalRows).fill(null);
                    void this.toolbarState.setPageInformation(null, totalRows);
                    return this._currentOffset$.pipe(
                        switchMap(offset => {
                            return this.returnsQueries.getReturns(filters, offset, GraphQLLimits.returns, returnsSort).pipe(
                                map(returns => {
                                    fullArray.splice(offset, returns.length, ...returns);
                                    return fullArray;
                                })
                            );
                        }),
                        tap(() => {
                            this._isLoading.set(false);
                        })
                    );
                })
            );
        })
    );
    public returns = toSignal<ReturnsInterface[]>(this._returns$, {initialValue: null});

    /* ################ selected return data (details) ################ */
    private _returnById = signal<ReturnsDetailInterface>(null);
    public returnById = this._returnById.asReadonly();
    private _activeReturnId = signal<number>(null);
    public activeReturnId = this._activeReturnId.asReadonly();
    private _sidebar$: Observable<TableSidebarInterface> = toObservable(this._activeReturnId).pipe(
        debounceTime(200),  // debounce to prevent multiple requests when the active return id changes within a very small time frame
        switchMap(id => {
            if (id) {
                return this.returnsQueries.getReturnById(id).pipe(
                    tap(returnById => this._returnById.set(returnById)),
                    map(returnById => {
                        return {
                            title: returnById.type,
                            subTitle: 'Retouren-Typ',
                            actionPopoverItems: [
                                {label: 'Details', code: ButtonTypes.VIEW, onAction: () => this.openDetailsModal()},
                                {label: 'Kundenservice kontaktieren', code: ButtonTypes.SUPPORT, onAction: () => this.openSupportModal()},
                                {label: 'Interne Notiz bearbeiten', code: ButtonTypes.NOTE, onAction: () => this.openNoteModal()},
                            ],
                            sidebarItems: this.buildSidebarItems(returnById),
                            previousRow: () => this.updateActiveReturnId(-1),
                            nextRow: () => this.updateActiveReturnId(1)
                        };
                    }),
                );
            }
            return new Observable<TableSidebarInterface>(subscriber => {
                subscriber.next(null);
            });
        })
    );
    public sidebar = toSignal(this._sidebar$, {initialValue: null});

    /* ################ initial loading state (when page is first rendered) ################ */
    private _isLoading = signal<boolean>(true);
    public isLoading = this._isLoading.asReadonly();

    /* ################ notes attached to each return ################ */
    private _notes = signal<NotesInterface[]>([]);
    private _notes$ = toObservable(this.returns).pipe(
        switchMap(returns => {
            if (returns?.length) {
                const ids = returns.filter(r => r?.id).map(r => r?.id);
                return this.returnsQueries.getReturnsNotesByReturnsIds(ids);
            } else {
                return new Observable<NotesInterface[]>(observer => {
                    observer.next([]);
                });
            }
        }),
        tap(notes => {
            this._notes.set(notes);
        })
    );
    public notes = toSignal(this._notes$, {initialValue: []});
    public notesByReturnId = computed(() => {
        const returnById = this._returnById();
        const notes = this._notes();
        return notes.find(note => note.returnsId === returnById?.id);
    });

    /* ################ autocomplete search for pzn ################ */
    private _pznSearch = signal('');
    public pznSearch = this._pznSearch.asReadonly();
    private pznList$ = toObservable(this._pznSearch).pipe(
        switchMap((searchTerm) => {
            if(searchTerm?.length) {
                return this.returnsQueries.getReturnsPZNCollection(searchTerm);
            }
            return new Observable<string[]>(subscriber => {
                subscriber.next([]);
            });
        })
    );
    public pznList = toSignal(this.pznList$, {initialValue: []});

    /* ################ autocomplete search for producers ################ */
    private _producerSearch = signal('');
    public producerSearch = this._producerSearch.asReadonly();
    private _producersList$ = toObservable(this._producerSearch).pipe(
        switchMap((searchTerm) => {
            if(searchTerm?.length) {
                return this.returnsQueries.getReturnsProducerCollection(searchTerm);
            }
            return new Observable<string[]>(subscriber => {
                subscriber.next([]);
            });
        })
    );
    public producersList = toSignal(this._producersList$, {initialValue: []});

    /* ################ autocomplete search for delivery note numbers ################ */
    private _deliveryNoteNumberSearch = signal('');
    private _deliveryNoteNumberList$ = toObservable(this._deliveryNoteNumberSearch).pipe(
        switchMap((searchTerm) => {
            if(searchTerm?.length) {
                return this.returnsQueries.getDeliveryNoteNumberCollection(searchTerm);
            }
            return new Observable<string[]>(subscriber => {
                subscriber.next([]);
            });
        })
    );
    public deliveryNoteNumberList = toSignal(this._deliveryNoteNumberList$, {initialValue: []});

    private storageKeys = {
        filters: 'returnsTableFilters',
        sorting: 'returnsTableSorting'
    };

    /**
     * Configure the filters for the returns table
     */
    public filterLeftConfig: TableFilterConfigInterface[] = [
        {
            label: 'Datum',
            type: TableFilterTypeEnum.date,
            dataKey: 'date',
            selectableValues: DateRangeConfig,
            onAction: (newFilter: DateSelectionEventInterface) => {
                this.updateDateFilter(newFilter);
            }
        },
        {
            label: 'Typ',
            type: TableFilterTypeEnum.select,
            dataKey: 'type',
            selectableValues: getReturnsTypeWithAll(),
            onAction: (newFilter: string, filterKey: string) => {
                this.updateSelectFilter(newFilter, filterKey);
            }
        },
        {
            label: 'Status',
            type: TableFilterTypeEnum.select,
            dataKey: 'status',
            selectableValues: getReturnsStatusWithAll(),
            onAction: (newFilter: string, filterKey: string) => {
                this.updateSelectFilter(newFilter, filterKey);
            }
        },
        {
            label: 'Suche',
            type: TableFilterTypeEnum.search,
            dataKey: 'search'
        }
    ];

    /* ################ filters on the right side (above the sidebar) ################ */
    private _printLoading = signal(false);
    private _printEnabled = signal(true);
    private _downloadLoading = signal(false);
    public filterRightConfig: TableFilterConfigInterface[] = [
        {
            icon: 'print-outline',
            type: TableFilterTypeEnum.print,
            onAction: (event) => this.onPrint(event),
            payload: {
                isLoading: this._printLoading,
                isEnabled: this._printEnabled
            }
        },
        {
            icon: 'download-outline',
            type: TableFilterTypeEnum.download,
            onAction: (event) => this.onDownload(event),
            payload: {
                trigger: 'download-action',
                isLoading: this._downloadLoading
            }
        }
    ];

    /* ################ search modal filters configuration ################ */
    public SearchModalFilterConfig: TableSearchModalConfigInterface = {
        title: 'Retouren filtern',
        onAction: (newFilters: ReturnsFiltersInterface) => this.onSearchModalAction(newFilters),
        onReset: () => this.onSearchModalCancel(),
        items: [
            {
                label: 'Suche',
                type: TableFilterTypeEnum.search,
                dataKey: 'search',
            },
            {
                label: 'Datum',
                type: TableFilterTypeEnum.date,
                dataKey: 'date',
                selectableValues: DateRangeConfig,
                dateSignal: this.dateFilter,
                onAction: (newFilter: DateSelectionEventInterface) => {
                    this.updateDateFilter(newFilter);
                }
            },
            {
                label: 'Typ',
                type: TableFilterTypeEnum.select,
                dataKey: 'type',
                selectableValues: getReturnsTypeWithAll()
            },
            {
                label: 'Status',
                type: TableFilterTypeEnum.select,
                dataKey: 'status',
                selectableValues: getReturnsStatusWithAll()
            },
            {
                label: 'PZN',
                type: TableFilterTypeEnum.inputSelect,
                dataKey: 'pzn',
                selectableSignal: this.pznList,
                secondarySignal: this.pznSearch,
                setSecondarySignal: (value: string) => this.setPZNSearch(value)
            },
            {
                label: 'Hersteller',
                type: TableFilterTypeEnum.inputSelect,
                dataKey: 'producer',
                selectableSignal: this.producersList,
                secondarySignal: this.producerSearch,
                setSecondarySignal: (value: string) => this.setProducerSearch(value)
            },
            {
                label: 'Lieferbeleg-#',
                type: TableFilterTypeEnum.inputSelect,
                dataKey: 'deliveryNoteNumber',
                selectableSignal: this.deliveryNoteNumberList,
                secondarySignal: this._deliveryNoteNumberSearch,
                payload: {
                    deliveryNumberTransform: deliveryNumberFormatting
                },
                setSecondarySignal: (value: string) => this.setDeliveryNoteNumberSearch(value)
            },
            {
                label: 'Lieferbeleg Datum',
                type: TableFilterTypeEnum.date,
                dataKey: 'deliveryNoteDate',
                selectableValues: DateRangeConfig,
                dateSignal: this.deliveryDateFilter,
                onAction: (newFilter: DateSelectionEventInterface) => {
                    this.updateDateFilter(newFilter, 'deliveryNoteDate');
                }
            }
        ]
    };

    constructor() {
        const activePharmacy = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
        /**
         * Load the filters from the storage
         */
        this.storage.get(`${this.storageKeys.filters}_${activePharmacy}`).then((filters: ReturnsFiltersInterface) => {
            if (filters) {
                this._filters.update(prev => {
                    return {
                        ...prev,
                        ...filters,
                    };
                });
            }
        });

        /**
         * Load the sorting from the storage
         */
        this.storage.get(`${this.storageKeys.sorting}_${activePharmacy}`).then((sorting: TableSortInterface) => {
            if (sorting) {
                this._sort.update(prev => ({
                    ...prev,
                    ...sorting
                }));
            }
        });

        /**
         * Set values of pznSearch, producerSearch and deliveryNoteNumberSearch as soon as the filters are loaded
         */
        effect(() => {
            const filters = this.filters();
            untracked(() => {
                this._pznSearch.set(filters.pzn);
                this._producerSearch.set(filters.producer);
                this._deliveryNoteNumberSearch.set(filters.deliveryNoteNumber);
            });
        });

        /**
         * Set the active return id to the first return in the list as soon as the returns are initially loaded
         */
        effect(() => {
            const returns = this.returns();
            if (returns?.length && this._activeReturnId() === null
            || returns?.length && !returns.find(ret => ret?.id === this._activeReturnId())) {
                untracked(() => {
                    this._activeReturnId.set(returns[0]?.id);
                });
            } else if(!returns?.length) {
                untracked(() => {
                    this._activeReturnId.set(null);
                });
            }
        });

        /**
         * Save the filters to the storage as soon as they change
         */
        effect(() => {
            void this.storage.set(`${this.storageKeys.filters}_${activePharmacy}`, this._filters());
        });

        /**
         * Save the sorting to the storage as soon as it changes
         */
        effect(() => {
            void this.storage.set(`${this.storageKeys.sorting}_${activePharmacy}`, {field: this._sort().field, order: this._sort().order});
        });
    }

    /**
     * Update the date filter
     * @param newFilter the new date filter
     * @param keyPrefix prefix of the attribute keys, e.g. 'date' or 'deliveryNoteDate'
     */
    public updateDateFilter(newFilter: DateSelectionEventInterface, keyPrefix = 'date') {
        this._filters.update(prev => {
            return {
                ...prev,
                [keyPrefix + 'Option']: newFilter?.dateOption,
                [keyPrefix + 'From']: newFilter?.dateFrom,
                [keyPrefix + 'To']: newFilter?.dateTo,
            };
        });
    }

    /**
     * Save filters that are selected using a popover.
     * The filters are saved to the storage by using an effect to automatically save the filters as soon as they change.
     * @param newFilter string value of the new filter
     * @param filterKey key of the filter that is being updated
     */
    public updateSelectFilter(newFilter: string, filterKey: string) {
        this._filters.update(prev => {
            return {
                ...prev,
                [filterKey]: newFilter,
            };
        });
    }

    /**
     * Change the sorting of the table.
     * The sorting is saved to the storage by using an effect to automatically save the sorting as soon as it changes.
     * @param field
     */
    public updateSort(field: TableFieldsEnum) {
        this._sort.update(prev => ({
            ...prev,
            field,
            order: this._sort().order === SortDirectionEnum.asc ? SortDirectionEnum.desc : SortDirectionEnum.asc,
        }));
    }

    public updateOffset(offset: number) {
        this._currentOffset.set(offset);
    }

    public setActiveReturnId(id: number) {
        this._activeReturnId.set(id);
    }

    public updateActiveReturnId(upDown: number) {
        const selectedRowIndex = this.returns().findIndex(item => item.id === this._activeReturnId());
        const newSelectedId = this.returns()[selectedRowIndex + upDown]?.id || this._activeReturnId();
        this._activeReturnId.set(newSelectedId);
    }

    private onSearchModalAction(newFilters: ReturnsFiltersInterface) {
        this._filters.update(prev => {
            return {
                ...prev,
                ...newFilters,
            };
        });
    }

    private onSearchModalCancel() {
        this._filters.set(defaultReturnsFilters);
    }

    private setPZNSearch(search: string) {
        this._pznSearch.set(search);
    }

    private setProducerSearch(search: string) {
        this._producerSearch.set(search);
    }

    private setDeliveryNoteNumberSearch(search: string) {
        this._deliveryNoteNumberSearch.set(search);
    }

    async openDetailsModal() {
        const modal = await this.modalService.create(
            ReturnsViewComponent,
            {
                details: this.returnById,
                sidebar: this.sidebar,
                notesById: this.notesByReturnId
            },
        );
        return await this.modalService.present(modal);
    }

    private async openSupportModal() {
        const modal = await this.modalService.create(
            CommunicationZoneFormComponent,
            {returnsId: this.returnById().id}
        );
        await this.modalService.present(modal);
    }

    private async openNoteModal() {
        const modal = await this.modalService.create(
            NoteWidgetComponent,
            {
                noteType: NotesTypeEnum.returns,
                sourceId: this.returnById().id
            }
        );
        await this.modalService.present(modal);
    }

    /**
     * Open new browser tab and print table as PDF.
     */
    private async onPrint(event: any) {
        this._printEnabled.set(false);
        this._printLoading.set(true);
        // disable print button for 30 seconds
        setTimeout(() => {
            this._printEnabled.set(true);
        }, 30000);

        await this.tableToPdfService.getTablePdf(PrintPdfTableTypeEnum.returns, this.filters());
        this._printLoading.set(false);
    }

    /**
     * Convert table values to excel or csv and download the file.
     */
    private async onDownload(format: ExportFormatEnum = ExportFormatEnum.XLSX) {
        this._downloadLoading.set(true);
        const subscription = this.excelQueries.exportExcelReturns(this._filters()).subscribe(res => {
            if(res?.data && res?.filename) {
                const buf = res.data.split(',').map(n => parseInt(n, 10));
                const wb = read(Uint8Array.from(buf).buffer, {type: 'buffer'});

                const date = '_' + getDate();

                switch(format) {
                    case ExportFormatEnum.XLSX:
                        writeFile(wb, res?.filename + date + '.xlsx', {compression: true});
                        break;
                    case ExportFormatEnum.CSV:
                        writeFile(wb, res?.filename+ date + '.csv', {bookType: 'csv'});
                        break;
                }
            } else if(res?.status === 'ERROR') {
                void this.apiService.presentErrorToast(null, res.message);
            }
            unsubscribe(subscription);
        });
        this._downloadLoading.set(false);
    }

    private buildSidebarItems(returnById: ReturnsDetailInterface): TableSidebarItemsInterface[] {
        return [
            {
                key: 'status',
                value: returnById.status,
                colWidth: '100%',
                badgeType: BadgeTypeEnum.RETURNS_STATUS
            },
            {
                key: 'recTime',
                value: formatDateTimeToDate(returnById.recTime, true),
                label: 'Datum',
                colWidth: '100%',
            },
            {
                key: 'productName',
                value: returnById.productName,
                label: 'Name',
                colWidth: '100%',
            },
            {
                key: 'price',
                value: this.formatCurrency.transform(returnById.price, 'EUR'),
                label: 'Wert €',
                colWidth: '50%',
            },
            {
                key: 'quantity',
                value: returnById.quantity.toString() + ' St',
                label: 'Menge',
                colWidth: '50%',
            },
            {
                key: 'packageSize',
                value: returnById.packageSize,
                label: 'Packungsgröße',
                colWidth: '50%',
            },
            {
                key: 'dosageForm',
                value: returnById.dosageForm,
                label: 'Darreichung',
                colWidth: '50%',
            },
            {
                key: 'pzn',
                label: 'PZN',
                value: returnById.pzn,
                colWidth: '100%',
                onAction: () => {
                    this._filters.update(prev => ({
                        ...prev,
                        pzn: returnById.pzn
                    }));
                }
            },
            {
                key: 'producer',
                label: 'Hersteller',
                value: returnById.producer,
                colWidth: '100%',
                onAction: () => {
                    this._filters.update(prev => ({
                        ...prev,
                        producer: returnById.producer
                    }));
                }
            },
            {
                key: 'deliveryNoteNumber',
                label: 'Lieferbeleg-#',
                value: this.documentNumberFormatted.transform(returnById.deliveryNoteNumber),
                colWidth: '100%',
                onAction: () => {
                    this._filters.update(prev => ({
                        ...prev,
                        deliveryNoteNumber: returnById.deliveryNoteNumber
                    }));
                }
            },
            {
                key: 'deliveryNoteDate',
                label: 'Lieferbeleg Datum',
                value: formatDateTimeToDate(returnById.deliveryNoteDate, true),
                colWidth: '100%',
            },
            {
                key: 'rejection',
                label: 'Begründung Ablehnung',
                value: returnById.rejection,
                colWidth: '100%',
            }
        ];
    }
}
