import {
    Component,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { Subscription } from 'rxjs';

// Core Files
import { NotesTypeEnum, matomoIdsEnum } from '../../core/core.enums';
import { NotesInterface } from '../../core/core.interfaces';
import { NotesQueries, NotesMutations } from '../../core/core.store';
import { ModalService } from '../../core/services/modal.service';
import { ToastService } from '../../core/services/toast.service';
import { InputValidationService } from '../../core/services/inputValidation.service';
import { TOOLTIPS } from '../../core/core.config';
import { unsubscribe, unsubscribeAll } from '../../core/core.utils';

@Component({
    selector: 'app-note-widget',
    templateUrl: './note-widget.component.html',
    styleUrls: ['./note-widget.component.scss'],
})
export class NoteWidgetComponent implements OnInit {
    @Input() noteType: NotesTypeEnum;
    @Input() sourceId: number;
    @ViewChild('noteTextarea') noteTextarea;

    matomoId = matomoIdsEnum.ordersNote;

    noteFormGroup: FormGroup;
    profileSettings: any;
    noteEntries: NotesInterface;
    notes = '';
    notesSubscription: Subscription;
    tooltips = TOOLTIPS;

    constructor(
        public formBuilder: FormBuilder,
        private modalService: ModalService,
        public alertController: AlertController,
        public toastService: ToastService,
        private notesQueries: NotesQueries,
        private notesMutations: NotesMutations
    ) {
    }

    /**
     * Sets the notes form group
     */
    setNotesFormGroup() {
        this.noteFormGroup = this.formBuilder.group({
            notes: ['', [InputValidationService.noWhitespaceValidator, Validators.required]],
        });
    }

    ngOnInit() {
        this.setNotesFormGroup();
        unsubscribe(this.notesSubscription);
        this.notesSubscription = this.notesQueries.getNoteBySourceId(this.noteType ,this.sourceId)
            .subscribe((notes: Array<NotesInterface>) => {
            if (notes) {
                this.noteEntries = notes.find(note => note.note.length > 0);
                this.notes = this.noteEntries ? this.noteEntries.note : '';
                if (!this.noteFormGroup.dirty) {
                    this.noteFormGroup.patchValue({notes: this.notes});
                }
            }
        });
    }
    ionViewWillLeave(): void {
        unsubscribeAll([
            this.notesSubscription
        ]);
    }

    ionViewDidEnter() {
        if(this.noteTextarea && this.noteTextarea.el) {
            this.noteTextarea.setFocus();
            this.noteTextarea.el.setFocus();
        }
    }

    /**
     * trigger saving communication note form
     */
    onSendNote() {
        const notes: NotesInterface = {
            note: this.noteFormGroup.value.notes
        };

        switch (this.noteType) {
            case NotesTypeEnum.communication:
                 notes.communicationId = this.sourceId;
                 this.notesMutations.upsertCommunicationNotes(notes, this.noteEntries ? this.noteEntries.id : null);
                 break;
            case NotesTypeEnum.order:
                notes.orderId = this.sourceId;
                this.notesMutations.upsertOrderNotes(notes, this.noteEntries ? this.noteEntries.id : null);
                break;
            case NotesTypeEnum.returns:
                notes.returnsId = this.sourceId;
                this.notesMutations.upsertOrderNotes(notes, this.noteEntries ? this.noteEntries.id : null);
                break;
        }

        void this.modalService.dismiss();
    }

    /**
     * saves the order note
     */
    onSaveNote() {
        if (this.noteFormGroup.valid) {
            this.onSendNote();
        }
    }

    /**
     * deletes the order note
     */
    async onDeleteNote() {
        const alert = await this.alertController.create({
            header: 'Notiz löschen',
            message: 'Möchten Sie die Notiz wirklich löschen?',
            buttons: [
                {
                    text: 'Abbrechen',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    }
                }, {
                    text: 'Löschen',
                    handler: () => {
                        if(this.noteEntries) {
                            this.notesMutations.deleteNote(this.noteEntries.id);
                        } else {
                            this.toastService.presentWarning('Die Notiz kann nicht gelöscht werden, da sie noch nicht gespeichert wurde.');
                        }

                        this.modalService.dismiss();
                    }
                }
            ]
        });

        await alert.present();
    }
}
