/**
 * Return values for login methods
 */
export enum LoginStatus {
  /** Success: Login succeeded */
  Success = 'Success',
  /** LoginError: Login failed */
  LoginError = 'LoginError',
  /** RestorePrivateKeyPasswordError: The password does not match the stored value */
  RestorePrivateKeyPasswordError = 'RestorePrivateKeyPasswordError',
  /** RestorePrivateKeyError: The entered private key does not match the stored value */
  RestorePrivateKeyError = 'RestorePrivateKeyError',
  /** RestorePrivateKey: The private key needs to be entered and submitted */
  RestorePrivateKey = 'RestorePrivateKey',
  /** BackupPrivateKey: The private key needs to be created */
  BackupPrivateKey = 'BackupPrivateKey',
}
