import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import isEqual from 'lodash-es/isEqual';
import { combineLatest, distinctUntilChanged, map, Observable, shareReplay } from 'rxjs';
import { Dictionary } from 'ts-essentials';
import { EncryptionService } from '../../../common/resources/src/services/encryption/encryption.service';
import { CommonState } from '../../../store/src/common-store/common.state';
import { addDecryptedChatPartnerNames } from '../../../store/src/common-store/other/actions/chat-partner.actions';
import { selectUser } from '../../../store/src/common-store/user-store/selectors/user.selectors';
import { ChatPartnerMetadata } from '../../../essentials/types/src/chatPartnerMetadata';
import { MeaUser } from '../../../essentials/types/src/chatUser';
import DecryptionStatus from '../../../essentials/types/src/decryptionStatus';
import { ChatPartnerMetadataServiceInterface } from '../../../essentials/types/src/service-interfaces/chat-partner-metadata.service.interface';
import { isNotNullOrUndefined } from '../../../essentials/util/src/rxjs/isNotNullOrUndefined';

@Injectable({
  providedIn: 'root',
})
export class ChatPartnerDecryptionService {
  decryptedChatPartnerMetadataDictionary$: Observable<Dictionary<ChatPartnerMetadata>> = combineLatest([
    this.store.select(selectUser).pipe(isNotNullOrUndefined()),
    this.chatPartnerMetadataService.chatPartnerMetadataDictionary$,
  ]).pipe(
    map(([user, chatPartnerMetadataDictionary]) => {
      const newlyDecryptedChatPartnerNicknames = this.decryptChatPartnerNicknames(chatPartnerMetadataDictionary, user);
      const newlyDecryptedChatPartnerChatnames = this.decryptChatPartnerChatnames(chatPartnerMetadataDictionary, user);
      if (newlyDecryptedChatPartnerNicknames.length > 0 || newlyDecryptedChatPartnerChatnames.length > 0) {
        setTimeout(() =>
          this.store.dispatch(
            addDecryptedChatPartnerNames({ newlyDecryptedChatPartnerChatnames, newlyDecryptedChatPartnerNicknames })
          )
        );
      }
      return chatPartnerMetadataDictionary;
    }),
    distinctUntilChanged(isEqual),
    shareReplay(1)
  );

  constructor(
    @Inject('ChatPartnerMetadataService') private chatPartnerMetadataService: ChatPartnerMetadataServiceInterface,
    private encryptionService: EncryptionService,
    private store: Store<CommonState>
  ) {}

  decryptChatPartnerNicknames(
    chatPartnerMetadataDictionary: Dictionary<ChatPartnerMetadata>,
    user: MeaUser
  ): {
    cognitoId: string;
    chatPartnerId: string;
    chatPartnerNicknameDecryptionStatus: DecryptionStatus;
    decryptedChatPartnerNickname?: string;
  }[] {
    const newlyDecryptedChatPartnerNicknames: {
      cognitoId: string;
      chatPartnerId: string;
      chatPartnerNicknameDecryptionStatus: DecryptionStatus;
      decryptedChatPartnerNickname?: string;
    }[] = [];
    for (const [chatPartnerId, metadata] of Object.entries(chatPartnerMetadataDictionary)) {
      if (metadata.chatPartnerNicknameDecryptionStatus === 'encrypted') {
        const decryptedChatPartnerNickname =
          user?.privateKey && metadata.encryptedChatPartnerNickname
            ? this.encryptionService.decryptUsingPrivateKey(metadata.encryptedChatPartnerNickname, user.privateKey)
            : false;
        if (decryptedChatPartnerNickname !== false) {
          newlyDecryptedChatPartnerNicknames.push({
            cognitoId: user.cognitoId,
            chatPartnerId,
            decryptedChatPartnerNickname,
            chatPartnerNicknameDecryptionStatus: 'decrypted',
          });
        } else {
          newlyDecryptedChatPartnerNicknames.push({
            cognitoId: user.cognitoId,
            chatPartnerId,
            decryptedChatPartnerNickname: undefined,
            chatPartnerNicknameDecryptionStatus: 'failed',
          });
        }
      }
    }
    return newlyDecryptedChatPartnerNicknames;
  }

  decryptChatPartnerChatnames(
    chatPartnerMetadataDictionary: Dictionary<ChatPartnerMetadata>,
    user: MeaUser
  ): {
    cognitoId: string;
    chatPartnerId: string;
    chatPartnerChatnameDecryptionStatus: DecryptionStatus;
    decryptedChatPartnerChatname?: string;
  }[] {
    const newlyDecryptedChatPartnerChatnames: {
      cognitoId: string;
      chatPartnerId: string;
      chatPartnerChatnameDecryptionStatus: DecryptionStatus;
      decryptedChatPartnerChatname?: string;
    }[] = [];
    for (const [chatPartnerId, metadata] of Object.entries(chatPartnerMetadataDictionary)) {
      if (metadata.chatPartnerChatnameDecryptionStatus === 'encrypted') {
        const encryptedChatPartnerChatname = metadata.chatPartnerChatnameHistory?.at(-1)?.encryptedChatname;
        const decryptedChatPartnerChatname =
          encryptedChatPartnerChatname && user?.privateKey
            ? this.encryptionService.decryptUsingPrivateKey(encryptedChatPartnerChatname, user.privateKey)
            : false;
        if (decryptedChatPartnerChatname !== false) {
          newlyDecryptedChatPartnerChatnames.push({
            cognitoId: user.cognitoId,
            chatPartnerId,
            decryptedChatPartnerChatname,
            chatPartnerChatnameDecryptionStatus: 'decrypted',
          });
        } else {
          newlyDecryptedChatPartnerChatnames.push({
            cognitoId: user.cognitoId,
            chatPartnerId,
            decryptedChatPartnerChatname: undefined,
            chatPartnerChatnameDecryptionStatus: 'failed',
          });
        }
      }
    }
    return newlyDecryptedChatPartnerChatnames;
  }
}
