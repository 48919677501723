import { Component, Input, ChangeDetectionStrategy, TemplateRef } from '@angular/core';
import { ModalClassEnum } from '../../../core.enums';
import { ModalService } from '../../../services/modal.service';

@Component({
    selector: 'app-template-modal',
    templateUrl: './template-modal.component.html',
    styleUrls: ['./template-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateModalComponent {

    constructor(
        private modalService: ModalService
    ) { }
    static modalClass = ModalClassEnum.autoHeightMedium;

    @Input() title = '';
    @Input() closeButtonTitle = '';
    @Input() infoModalTemplate: TemplateRef<any>;

    onModalClose() {
        void this.modalService.dismiss();
    }
}
