import { Pipe, PipeTransform } from '@angular/core';
import { AuditLogConfig } from '../config/audit-log.config';

@Pipe({
    name: 'auditLogLabel'
})
export class AuditLogLabelPipe implements PipeTransform {

    transform(auditLogType: string): string {
        const auditLog = AuditLogConfig[auditLogType];
        return auditLog ? auditLog.title : '';
    }

}
