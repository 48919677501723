import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textMinLength'
})
export class TextMinLengthPipe implements PipeTransform {

    transform(value: string, minSize: number = 1): string {
        if(value && value.length && value.length < minSize) {
            return value + '&nbsp;'.repeat( minSize - value.length);
        }
        return value;
    }
}

@Pipe({
    name: 'textMinTwoLines'
})
export class TextMinTwoLinesPipe implements PipeTransform {

    transform(value: string, addBreakBeforeLength: number = 30): string {
        if(value && value.length && value.length < addBreakBeforeLength) {
            return value + '<br>';
        }
        return value;
    }
}
