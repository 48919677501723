import { Injectable, signal } from '@angular/core';
import { BulkActionCodeEnum } from '../../enums/bulk-action.enum';
import { ListFiltersInterface } from '../../interfaces/filter-result.interface';


@Injectable({
    providedIn: 'root',
})
export class BulkModeVar {

    private _isActive = signal<boolean>(false);
    public isActive = this._isActive.asReadonly();

    private _selectedItems = signal<number[]>([]);
    public selectedItems = this._selectedItems.asReadonly();

    private _selectedAction = signal<BulkActionCodeEnum>(BulkActionCodeEnum.DEFAULT);
    public selectedAction = this._selectedAction.asReadonly();

    filtersBeforeBulkMode: ListFiltersInterface = {};

    /**
     * Enable / Disable bulk mode
     * @param isActive - Indicator if bulk mode should be enabled / disabled
     * @param currentFilters - Current filters for revert after disabling bulk mode
     */
    setActive(isActive: boolean, currentFilters: ListFiltersInterface = {}): ListFiltersInterface {
        this._selectedItems.set([]);
        this.setSelectedAction(BulkActionCodeEnum.DEFAULT);
        if(isActive) {
            this.filtersBeforeBulkMode = currentFilters;
        }
        this._isActive.set(isActive);
        return this.filtersBeforeBulkMode;
    }

    /**
     * Change current action, clear selection
     *
     * @param action - Bulk action
     */
    setSelectedAction(action: BulkActionCodeEnum) {
        this._selectedItems.set([]);
        this._selectedAction.set(action);
    }


    /**
     * Update the bulk selection
     *
     * @param item - item (id) to add or remove from the selection
     * @param toAdd - true to add, false to remove
     */
    updateSelection(item: number, toAdd: boolean = true) {
        this._selectedItems.update(prevState => {
            if (toAdd) {
                prevState.push(item);
                return prevState;
            } else {
                return prevState.filter(i => i !== item);
            }
        });
    }
}
