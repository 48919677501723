import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { from, map } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import {
    DataChangedKeys as DCK,
    DateRangeOptionCodes,
    DocumentType
} from '../../../core.enums';
import { DocumentFiltersInterface } from '../../../interfaces/document.interface';
import { DocumentQueries } from '../queries/documents.graphql';
import { DataChangedForceStateVar } from '../../locals/dataChangeForceState.var';
import { unsubscribe } from '../../../util/subscriptions.util';

export const UpsertDocumentFiltersMutation = gql`
    mutation insertDocumentFiltersMutation($filters: [documentFilters_insert_input!]!) {
        insert_documentFilters(
            objects: $filters,
            on_conflict: {
                constraint: documentFilters_userId_documentType_key,
                update_columns: [
                    search,
                    recDateOption,
                    recDateFrom,
                    recDateTo,
                    documentType,
                    documentNumber,
                    documentSubType,
                    orderType,
                    hasBatchDocument
                ]
            }
        ) {
            affected_rows
        }
    }
`;

export const UpdateDocumentSearchMutation = gql`
    mutation updateDocumentSearchMutation($documentType: String!) {
        update_documentFilters(
            _set: {search: null},
            where: {documentType: {_eq: $documentType}, search: {_is_null: false}}
        ) {
            affected_rows
        }
    }
`;


export const AllDocumentMutations = [
    UpsertDocumentFiltersMutation,
    UpdateDocumentSearchMutation
];

@Injectable()
export class DocumentMutations {

    constructor(
        private apollo: Apollo,
        private apiService: ApiService,
        private documentQueries: DocumentQueries,
        private dataChangedForceState: DataChangedForceStateVar) {}

    static _normalizeFilters(normalizedFilters) {
        // reformat empty strings to null
        if(normalizedFilters['recDateFrom'] && normalizedFilters['recDateFrom'] === '') {
            normalizedFilters['recDateFrom'] = null;
        }
        if(normalizedFilters['recDateTo'] && normalizedFilters['recDateTo'] === '') {
            normalizedFilters['recDateTo'] = null;
        }

        // reformat all date options but individual to dateFrom/To to null
        if (normalizedFilters['recDateOption'] && normalizedFilters['recDateOption'] !== DateRangeOptionCodes.individual) {
            normalizedFilters['recDateFrom'] = null;
            normalizedFilters['recDateTo'] = null;
        }

        return normalizedFilters;
    }

    /**
     * trim values
     * @param data - Data to trim (string or null)
     * @return string|null
     */
    private formatInputs = (data) => {
        if (typeof data === 'string') {
            return data.trim().length > 0 ? data.trim() : null;
        }
        return data;
    }

    upsertDocumentFilters(filters: DocumentFiltersInterface) {
        void this.dataChangedForceState.setForceState({[
                filters.documentType === DocumentType.RE ? DCK.documentInvoicesFilterChanged : DCK.documentDeliveriesFilterChanged
            ]: null});
        Object.keys(filters).forEach(key => {
            filters[key] = this.formatInputs(filters[key]);
        });
        this.apollo.mutate({
            mutation: UpsertDocumentFiltersMutation,
            variables: {filters: DocumentMutations._normalizeFilters(filters)}
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['insert_documentFilters'] && d?.data['insert_documentFilters']['affected_rows']))
                  .subscribe((affectedRows: number) => {
                      if (affectedRows >= 1) {
                          if (filters.search) {
                              const filterSubscription = this.documentQueries.filterDocuments(filters.documentType, filters.search, '', '')
                                .subscribe(() => {
                                    unsubscribe(filterSubscription);
                                });
                          }
                      } else {
                          void this.apiService.presentErrorToast(result['errors'], 'Die Filter konnten nicht gesetzt werden.');
                      }
                  });
            },
            error: error => this.apiService.presentErrorToast(error, 'Die Filter konnten nicht gesetzt werden.')
        });
    }

    /**
     * Delete the search text by updating document filters with a null search value.
     * @param documentType - Type of document
     */
    deleteSearchFilter(documentType: DocumentType) {
        this.apollo.mutate({
            mutation: UpdateDocumentSearchMutation,
            variables: {documentType}
        }).subscribe(
            result => {
                from([result])
                    .pipe(map(d => d?.data && d?.data['update_documentFilters'] && d?.data['update_documentFilters']['affected_rows']))
                    .subscribe(() => {});
            }
        );
    }
}
