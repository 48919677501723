import { Injectable } from '@angular/core';
import { NavigationStart, Router, RouterEvent, Event } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { filter, Subscription } from 'rxjs';
import { Color } from '../enums/color.enum';
import { AppRoutesEnum } from '../enums/routes.enum';
import { unsubscribe, unsubscribeAll } from '../util/subscriptions.util';

@Injectable({
    providedIn: 'root',
})
export class ToastService {

    public logoutMessageKey = 'logoutMessage';

    routerSubscription: Subscription;
    private messages: Array<{message: string, shouldDismissOnLogout: boolean, toast: HTMLIonToastElement}> = [];

    private toasts: {
        notice: Promise<HTMLIonToastElement>
        success: Promise<HTMLIonToastElement>
        successNoDuration: Promise<HTMLIonToastElement>
        warning: Promise<HTMLIonToastElement>
        error: Promise<HTMLIonToastElement>
        errorNoDuration: Promise<HTMLIonToastElement>
    } = {
        notice: null,
        success: null,
        successNoDuration: null,
        warning: null,
        error: null,
        errorNoDuration: null
    };

    constructor(
        public toastController: ToastController,
        private storage: Storage,
        private router: Router
    ) {
        this.dismissOnNavigation();
    }

    /**
     * show response
     */
    private async present(
        message,
        duration: number = 2000,
        color?: Color | undefined,
        payload?: Array<string>,
        shouldDismissOnLogout = false
    ) {
        if (await this.storage.get('displayToasts') === false) {
            return;
        }
        // Prevent duplicate messages at the same time
        if(!this.messages.find(m => m.message === message)) {
            this.messages.push({message, shouldDismissOnLogout, toast: null});
            let finalMessage = message;
            if(payload && payload.length > 0){
                payload.forEach((value,index) => {
                    finalMessage = finalMessage.replace(`{${index}}`, value);
                });
            }

            const toast = await this.toastController.create({
                message: finalMessage,
                duration,
                color,
                buttons: [
                    {
                        icon: 'close',
                        role: 'cancel'
                    }
                ]
            });
            this.messages = this.messages.map(m => m.message === message ? {...m, toast} : m);
            toast.onDidDismiss().then(() => {
                this.messages = this.messages.filter(m => m.message !== message);
            });
            await toast.present();
            return toast;
        }
    }

    /**
     * show notice response
     */
    public async presentNotice(message, duration: number = 4000, shouldDismissOnLogout = false) {
        this.toasts.notice = this.present(message, duration, undefined, undefined, shouldDismissOnLogout);
        return this.toasts.notice;
    }

    /**
     * show response if the request was successfully
     */
    public async presentSuccess(message, duration: number | null = 4000) {
        const toast = this.present(message, duration, Color.SUCCESS);
        if(!duration) {
            this.toasts.successNoDuration = toast;
        } else {
            this.toasts.success = toast;
        }
        return toast;
    }


    /**
     * show response if the request needs a warning
     */
    public async presentWarning(message, duration: number = 4000) {
        this.toasts.warning = this.present(message, duration, Color.WARNING);
        return this.toasts.warning;
    }

    /**
     * show response if the request has an error
     */
    public async presentError(message, duration: number | null = 8000, payload?: Array<string>) {
        const toast = this.present(message, duration, Color.DANGER, payload);
        if (!duration) {
            this.toasts.errorNoDuration = toast;
        } else {
            this.toasts.error = toast;
        }
        return toast;
    }

    /**
     * Close notice toast
     */
    public async dismissNotice() {
        if(this.toasts.notice && (await this.toasts.notice)) {
            await (await this.toasts.notice).dismiss();
        }
    }

    /**
     * Close success toast
     */
    public async dismissSuccess(noDurationOnly = false) {
        if(this.toasts.success && (await this.toasts.success && !noDurationOnly)) {
            await (await this.toasts.success).dismiss();
        }
        if(this.toasts.successNoDuration && (await this.toasts.successNoDuration)) {
            await (await this.toasts.successNoDuration).dismiss();
        }
    }

    /**
     * Close warning toast
     */
    public async dismissWarning() {
        if(this.toasts.warning && (await this.toasts.warning)) {
            await (await this.toasts.warning).dismiss();
        }
    }

    /**
     * Close errors toast
     */
    public async dismissErrors(noDurationOnly = false) {
        if(this.toasts.error && (await this.toasts.error) && !noDurationOnly) {
            await (await this.toasts.error).dismiss();
        }
        if(this.toasts?.errorNoDuration && (await this.toasts.errorNoDuration)) {
            await (await this.toasts.errorNoDuration).dismiss();
        }
    }

    clearRouterSubscriptions = () => {
        unsubscribeAll([
            this.routerSubscription
        ]);
    }

    /**
     * show notice response
     */
    public dismissOnNavigation() {
        this.clearRouterSubscriptions();
        unsubscribe(this.routerSubscription);
        this.routerSubscription = this.router.events
            .pipe(filter( event => event instanceof NavigationStart))
            .subscribe(async (event: Event|RouterEvent) => {

            // Dismiss toasts on logout
            if (event instanceof RouterEvent && event.url === AppRoutesEnum.login) {
                const messages = this.messages.filter((m) => m.shouldDismissOnLogout);
                messages.forEach((m) => {
                    if (m.toast) {
                        m.toast.dismiss();
                    }
                });
                this.clearRouterSubscriptions();
            }

            // Dismiss all success toasts without duration
            await this.dismissSuccess(true);

            // Dismiss all error toasts without duration
            await this.dismissErrors(true);
        });
    }
}
