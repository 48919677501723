import gql from 'graphql-tag';
import { pharmacyAttributes, pharmacyChatUserAttributes } from '../attributes';

// This query should have the same return values as createPharmacyChatUser
export default gql`
  query getMyPharmacyUser {
    mePharmacyChatUser {
      ${pharmacyChatUserAttributes}
      pharmacy {
        ${pharmacyAttributes}
      }
    }
  }
`;
