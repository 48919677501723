import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isInArray'
})
export class IsInArrayPipe implements PipeTransform {

    transform(value: any, array: Array<any>, forceChange: any = null): boolean {
        if(!array || !value) {
            return false;
        }
        return array.findIndex(item => value === item) !== -1;
    }
}

@Pipe({
    name: 'isInArrayNotPure',
    pure: false
})
export class IsInArrayNotPurePipe implements PipeTransform {

    transform(value: any, array: Array<any>): boolean {
        if(!array || !value) {
            return false;
        }
        return array.findIndex(item => value === item) !== -1;
    }
}

/**
 * Find an item in an array and return it.
 * @Input items: Array<any> - The array to search in.
 * @Input value: string | number - The value to which the value of the array items are compared to.
 * @Input identifier: string - The identifier/key in the array to search for.
 *
 * @Output any - The found item.
 */
@Pipe({
    name: 'find'
})
export class FindPipe implements PipeTransform {
    transform(items: Array<any>, value: string | number, identifier: string): any {
        return items.find(item => item[identifier] === value);
    }
}

@Pipe({
    name: 'includes'
})
export class IncludesPipe implements PipeTransform {
        transform(haystack: string, needle: string, forceChange: any = null ): boolean {
            return haystack.includes(needle);
        }
}

