import {
    OrderStatusFilterInterface,
    OrderStatusInterface,
} from '../interfaces/order-status.interface';
import { OrderStatus, OrderStatusFilter } from '../enums/orders.enum';

export const OrderStatusConfig: OrderStatusInterface[]  = [
    {
        id: OrderStatus.PENDING,
        color: 'delivery-status-pending',
        title: 'In Bearbeitung',
        shortcode: 'In Bearbeitung',
        sortOrder: 10
    },
    {
        id: OrderStatus.DELEGATED,
        color: 'delivery-status-delegated',
        title: 'Direktlieferung',
        shortcode: 'Direkt',
        sortOrder: 20
    },
    {
        id: OrderStatus.ENROUTE,
        color: 'delivery-status-enroute',
        title: 'In Zustellung',
        shortcode: 'In Zustellung',
        sortOrder: 30
    },
    {
        id: OrderStatus.PARTIALLY_COMPLETED,
        color: 'delivery-status-partially-completed',
        title: 'Teilweise zugestellt',
        shortcode: 'Teilw. zugestellt',
        sortOrder: 40
    },
    {
        id: OrderStatus.COMPLETED,
        color: 'delivery-status-completed',
        title: 'Zugestellt',
        shortcode: 'Zugestellt',
        sortOrder: 50
    },
    {
        id: OrderStatus.CANCELLED,
        color: 'delivery-status-cancelled',
        title: 'Storniert',
        shortcode: 'Storniert',
        sortOrder: 60
    },
].sort((a, b) => a.sortOrder - b.sortOrder);

export const OrderStatusAllConfig: OrderStatusInterface = {
    id: OrderStatus.ALL,
    color: '',
    title: 'Alle',
    shortcode: 'Alle',
    sortOrder: 1
};

export const OrderStatusFilterConfig: OrderStatusFilterInterface[] = [
    {
        id: OrderStatusFilter.ALL, // STATUS = PENDING, ENROUTE, PARTIALLY_COMPLETED, COMPLETED, DELEGATED, CANCELLED
        color: 'delivery-status-filter-all',
        title: 'Alle',
        shortcode: 'Alle',
        sortOrder: 1,
        statusIds: [OrderStatus.ALL],
        excludeFromFilter: true
    },
    {
        id: OrderStatusFilter.OPEN, // STATUS = PENDING, ENROUTE, PARTIALLY_COMPLETED, DElEGATED
        color: 'delivery-status-filter-open',
        title: 'Offen',
        shortcode: 'Offen',
        sortOrder: 2,
        children:[
            {id: OrderStatus.PENDING, title: 'In Bearbeitung', shortcode: 'In Bearb.'},
            {id: OrderStatus.ENROUTE, title: 'In Zustellung', shortcode: 'In Zust.' },
            {id: OrderStatus.PARTIALLY_COMPLETED, title: 'Teilw. erledigt', shortcode: 'Teilw. erl.' },
            {id: OrderStatus.DELEGATED, title: 'Direktlieferung', shortcode: 'Direkt'}
        ],
        statusIds: [OrderStatus.PENDING, OrderStatus.ENROUTE, OrderStatus.PARTIALLY_COMPLETED, OrderStatus.DELEGATED]
    },
    {
        id: OrderStatusFilter.COMPLETED, // STATUS = COMPLETED, CANCELLED
        color: 'delivery-status-filter-completed',
        title: 'Erledigt',
        shortcode: 'Erledigt',
        sortOrder: 3,
        children:[
            {id: OrderStatus.CANCELLED, title: 'Storniert', shortcode: 'Storniert' },
            {id: OrderStatus.COMPLETED, title: 'Abgeschlossen', shortcode: 'Abgeschl.' },
        ],
        statusIds: [OrderStatus.CANCELLED, OrderStatus.COMPLETED]
    },
    {
        id: OrderStatusFilter.DELEGATED, // STATUS = DELEGATED
        color: 'delivery-status-filter-delegated',
        title: 'Direktlieferung',
        shortcode: 'Direkt',
        sortOrder: 4,
        statusIds: [OrderStatus.DELEGATED],
        excludeFromFilter: true
    },
    {
        id: OrderStatusFilter.ENROUTE, // STATUS = ENROUTE
        color: 'delivery-status-filter-enroute',
        title: 'In Zustellung',
        shortcode: 'In Zustellung',
        sortOrder: 5,
        statusIds: [OrderStatus.ENROUTE],
        excludeFromFilter: true
    }
];
