import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
    Signal,
    TemplateRef
} from '@angular/core';
import { TableSidebarInterface } from '../../../interfaces/table.interface';
import { ReturnsViewComponent } from '../../../../pages/logistics/pages/returns/widgets/returns-view/returns-view.component';
import { BulkModeVar } from '../../../store/locals/bulk-mode.var';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {

    private bulkModeVar = inject(BulkModeVar);

    @Input({required: true}) data: Signal<TableSidebarInterface>;
    @Input({required: true}) id: string;
    @Input() hasHeader = true;
    @Input() hasFooter = true;
    @Input() actionsPopoverRef?: TemplateRef<any>;
    @Input() detailsModalRef?: TemplateRef<ReturnsViewComponent>;
    @Output() closeSidebar = new EventEmitter<void>();

    bulkModeActive = this.bulkModeVar.isActive;

    constructor() {}

    onCloseSidebar() {
        this.closeSidebar.emit();
    }
}
