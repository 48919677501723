import { Injectable } from '@angular/core';
import escape from 'lodash-es/escape';
import Message, { DecryptedMessage } from '../../../../../essentials/types/src/message';
import { EncryptionService } from '../encryption/encryption.service';
import { AutolinkerService } from './autolinker.service';
import { MessageMediaDecryptionService } from './message-media-decryption.service';

@Injectable({ providedIn: 'root' })
export class MessageDecryptionService {
  readonly messageCouldNotBeDecrypted = '<em>Nachricht nicht lesbar</em>';

  constructor(
    private messageMediaDecryptionService: MessageMediaDecryptionService,
    private encryption: EncryptionService,
    private autolinkerService: AutolinkerService
  ) {}

  async decryptMessage(message: Message, conversationPassword: string | undefined): Promise<DecryptedMessage> {
    const decryptedMessage = this.decryptMessageText(message, conversationPassword);

    if (decryptedMessage.decryptionStatus === 'failed') {
      decryptedMessage.media = [];
      return decryptedMessage;
    }

    if (message.media) {
      decryptedMessage.media = await this.messageMediaDecryptionService.validateAndDecryptMediaArray(
        message.media,
        conversationPassword
      );
    }

    return decryptedMessage;
  }

  private decryptMessageText(message: Message, conversationPassword: string | undefined): DecryptedMessage {
    try {
      const decryptedTextContent = message.encryptedTextContent
        ? this.encryption.decryptUsingPassword(message.encryptedTextContent, conversationPassword)
        : '';
      return {
        ...message,
        decryptionStatus: 'decrypted',
        decryptedTextContent: decryptedTextContent && this.autolinkerService.link(escape(decryptedTextContent)),
      };
    } catch (_) {
      return { ...message, decryptionStatus: 'failed', decryptedTextContent: this.messageCouldNotBeDecrypted };
    }
  }
}
