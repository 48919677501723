import { gql } from '@apollo/client/core';
import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { PharmacyStoreInterface } from '../../../interfaces/customer.interface';
import { ModuleActivationInterface } from '../../../interfaces/moduleActivation.interface';
import { environment } from '../../../../../environments/environment';
import { GraphQLLimits } from '../../../config/graphql-limits.config';

export const GetPharmacyStoresPoll = gql`
    query getPharmacyStores($limit: Int) {
        pharmacyStore(limit: $limit, order_by: [{name: asc}]) {
            pharmacyStoreId
            apiUser
            name
            company
            street
            houseNumber
            zip
            city
            country
            telephone
            fax
            sanavendiAvailable: hasSanavendi
            sanacorpLocation
            membershipType
            firstInitialized
            isMea
            shopAvailable
            chatAvailable
        }
    }
`;

export const GetModuleActivation = gql`
    query GetModuleActivation($limit: Int) {
        moduleActivation(limit: $limit) {
            module
        }
    }
`;

export const AllAuthQueries = [
    GetPharmacyStoresPoll,
    GetModuleActivation
];

@Injectable()
export class AuthQueries {

    private apollo = inject(Apollo);

    /**
     * Get all pharmacy stores
     *
     * @param pollOften - Poll often
     * @param fetchPolicy - Fetch policy
     */
    public getPharmacyStoresByPolling(
        pollOften = false,
        fetchPolicy: QueryFetchPolicy = QueryFetchPolicy.NETWORK_ONLY
    ): Observable<PharmacyStoreInterface[]> {
        return this.apollo.watchQuery({
                query:GetPharmacyStoresPoll,
                fetchPolicy,
                variables: {limit: GraphQLLimits.defaultLimit},
                pollInterval: pollOften ? environment.pollingInterval.pharmacyStoresOften : environment.pollingInterval.pharmacyStores
    })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['pharmacyStore'])) as Observable<PharmacyStoreInterface[]>;
    }

    /**
     * Return module names that can be compared to ModulActivationEnum to determine if the module should be shown to the user.
     */
    public getActiveModules(): Observable<ModuleActivationInterface> {
        return this.apollo.watchQuery({
            query: GetModuleActivation,
            variables: {limit: GraphQLLimits.defaultLimit},
            fetchPolicy: QueryFetchPolicy.CACHE_AND_NETWORK,
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['moduleActivation'])) as Observable<ModuleActivationInterface>;
    }
}
