import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MarkdownComponent } from 'ngx-markdown';
import { SimpleModalComponent } from './simple-modal.component';
import { ModalModule } from '../modal/modal.module';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        MarkdownComponent,
        ModalModule
    ],
    exports: [
        SimpleModalComponent
    ],
    declarations: [
        SimpleModalComponent
    ]
})
export class SimpleModalModule {
}
