import { Validators } from '@angular/forms';

export function AppointmentTitleValidator() {
    return [Validators.required];
}

export function AppointmentDescriptionValidator() {
    return [];
}

export function AppointmentHourValidator() {
    return [Validators.required, Validators.pattern('^(0[0-9]|1[0-9]|2[0-3]|[0-9])$')];
}

export function AppointmentMinutesValidator() {
    return [Validators.required, Validators.pattern('^[0-5]?[0-9]$')];
}

export function AppointmentTimeValidator() {
    return [Validators.required, Validators.pattern('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')];
}
