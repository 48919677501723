import gql from 'graphql-tag';
import { updateMessageAttributes } from '../attributes';

export default gql`
  mutation setOwnMessageAsRead($messageId: ID!) {
    setOwnMessageAsRead(messageId: $messageId) {
      ${updateMessageAttributes}
    }
  }
`;
