import { EventEmitter, Injectable } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import {
  BackendMessage,
  BackendMessageUpdate,
  PaginatedBackendMessages,
} from '../../../../../essentials/types/src/backendMessage';
import Message from '../../../../../essentials/types/src/message';
import createMessage, { CreateMessageInput } from '../../graphql/mutations/createMessage';
import deleteMessage from '../../graphql/mutations/deleteMessage';
import setMessageAsRead from '../../graphql/mutations/setMessageAsRead';
import setOwnMessageAsRead from '../../graphql/mutations/setOwnMessageAsRead';
import startTyping, { Typing } from '../../graphql/mutations/startTyping';
import getConversationMessages from '../../graphql/queries/getConversationMessages';
import createdMessage from '../../graphql/subscriptions/createdMessage';
import createdOwnMessage from '../../graphql/subscriptions/createdOwnMessage';
import selfStartedTyping from '../../graphql/subscriptions/selfStartedTyping';
import startedTyping from '../../graphql/subscriptions/startedTyping';
import updatedReceivedMessage from '../../graphql/subscriptions/updatedReceivedMessage';
import updatedSentMessage from '../../graphql/subscriptions/updatedSentMessage';
import { AppsyncService } from './appsync.service';

@Injectable({ providedIn: 'root' })
export class AppsyncMessageService {
  onOwnMessage = new EventEmitter<BackendMessage>();

  constructor(private appSync: AppsyncService) {}

  // ************* Query *************

  async loadMessagesForSegmentFromBackend(segmentId: string, nextToken?: string): Promise<PaginatedBackendMessages> {
    const client = await this.appSync.getClient();
    const variables: any = { conversationId: segmentId };
    if (nextToken) {
      variables.nextToken = nextToken;
    }
    const options = {
      query: getConversationMessages,
      variables,
    };

    const { data } = await client.query(options);
    if (data) {
      return {
        messages: cloneDeep(data.getMessagesByConversation.messages).reverse(),
        nextToken: data.getMessagesByConversation.nextToken,
      };
    } else {
      return { messages: [], nextToken: null };
    }
  }

  // ************* Mutations *************

  async createMessage(message: CreateMessageInput): Promise<void> {
    const client = await this.appSync.getClient();
    const {
      data: { createMessage: response },
    } = await client.mutate({
      mutation: createMessage,
      variables: message,
    });
    this.onOwnMessage.emit(response);
  }

  async emitStartTyping(variables: Typing): Promise<Typing> {
    const client = await this.appSync.getClient();
    const { data } = await client.mutate({
      mutation: startTyping,
      variables,
    });
    return data.startTyping;
  }

  async markMessageAsRead(message: Message): Promise<BackendMessageUpdate> {
    const client = await this.appSync.getClient();
    const { data } = await client.mutate({
      mutation: setMessageAsRead,
      variables: { messageId: message.id },
    });
    return data.setMessageAsRead;
  }

  async markOwnMessageAsRead(message: Message): Promise<BackendMessageUpdate> {
    const client = await this.appSync.getClient();
    const { data } = await client.mutate({
      mutation: setOwnMessageAsRead,
      variables: { messageId: message.id },
    });
    return data.setOwnMessageAsRead;
  }

  async deleteMessage(messageId: string): Promise<BackendMessageUpdate> {
    const client = await this.appSync.getClient();
    const { data } = await client.mutate({
      mutation: deleteMessage,
      variables: { messageId },
    });
    return data.deleteMessage;
  }

  // ************* Subscriptions *************

  newOwnBackendMessages(senderId: string): Observable<BackendMessage> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: createdOwnMessage,
            variables: { senderId },
          })
          .pipe(map(({ data: { createdOwnMessage: message } }) => message))
      )
    );
  }

  newMessages(recipientId: string): Observable<BackendMessage> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: createdMessage,
            variables: { recipientId },
          })
          .pipe(map(({ data: { createdMessage: message } }) => message))
      )
    );
  }

  updatedSentMessages(senderId: string): Observable<BackendMessageUpdate> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: updatedSentMessage,
            variables: { senderId },
          })
          .pipe(map(({ data: { updatedSentMessage: message } }) => message))
      )
    );
  }

  updatedReceivedMessages(recipientId: string): Observable<BackendMessageUpdate> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: updatedReceivedMessage,
            variables: { recipientId },
          })
          .pipe(map(({ data: { updatedReceivedMessage: message } }) => message))
      )
    );
  }

  startedTyping(cognitoId: string): Observable<{ conversationId: string; participantId: string }> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: startedTyping,
            variables: { otherParticipantId: cognitoId },
          })
          .pipe(map(({ data }) => data.startedTyping))
      )
    );
  }

  selfStartedTyping(cognitoId: string): Observable<Typing> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: selfStartedTyping,
            variables: { participantId: cognitoId },
          })
          .pipe(map(({ data }) => data.selfStartedTyping))
      )
    );
  }
}
