import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';

export const masonryAnimation = [
    trigger('masonryColStagger', [
        transition(':enter', [
            query('.masonry-columns', [
                style({opacity: 0}),
                stagger(150, [
                    animate('0.5s ease-in-out', style({opacity: 1})),
                    animateChild()
                ])
            ])
        ])
    ]),
    trigger('masonryCardStagger', [
        transition(':enter', [
            query('app-offers-card', [
                style({opacity: 0}),
                stagger(150, [
                    animate('0.5s ease-in-out', style({opacity: 1}))
                ])
            ], {optional: true})
        ])
    ])
];
