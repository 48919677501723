import { Injectable } from '@angular/core';
import { OfferProductsInterface } from '../interfaces/offers.interface';

@Injectable({
    providedIn: 'root',
})
export class OffersService {

    /**
     * Indicates which tier of the graduated prices should be marked, when quantity threshold is met.
     * Sets attribute 'graduatedPrice_markIndex' in offerProducts.
     */
    static markGraduatedPrice(offerProducts: OfferProductsInterface[], quantitiesInCart: Array<number> = null) {
        const markGradPrices = [];
        if (offerProducts && offerProducts.length > 0) {
            offerProducts.forEach((offerProduct) => {
                let markIndex;
                const value = (quantitiesInCart ? quantitiesInCart[offerProduct.id] : offerProduct.quantity) || 0 ;
                if (offerProduct.graduatedPrice && offerProduct.graduatedPrice.length > 0) {
                    offerProduct.graduatedPrice.forEach((gradPrice, index) => {
                        if (value >= gradPrice.quantity) {
                            markIndex = index + 1;
                        }
                    });
                }
                markGradPrices[offerProduct.id] = markIndex ? markIndex - 1 : null;
            });
        }
        return markGradPrices;
    }
}
