import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayReduce'
})
export class ArrayReducePipe implements PipeTransform {

    transform(value: Array<any>, objectKey: string, spliceStart = 0): string {
        if (!value) {
            return '';
        }

        const reducedArray = value.map((val) => (val[objectKey]));
        return reducedArray.splice(spliceStart).join(', ');
    }

}
