import { Action, createReducer, on } from '@ngrx/store';
import update from 'immutability-helper';
import cloneDeep from 'lodash-es/cloneDeep';
import { RequestState } from '../../../../../essentials/types/src/requestState';
import {
  resetShopProductsSearch,
  searchForShopProducts,
  searchForShopProductsFailure,
  searchForShopProductsSuccess,
  setShopProductSearchTerm,
} from '../actions/shop-product-search.actions';
import { ShopProductSearchState } from '../state/shop-product-search.state';

export const initialShopProductSearchState: ShopProductSearchState = {
  searchTerm: '',
  limitOfLastSearch: undefined,
  searchResults: [],
  requestState: RequestState.Init,
};

const _searchReducer = createReducer(
  initialShopProductSearchState,

  on(setShopProductSearchTerm, (state, { searchTerm }) =>
    update(state, {
      searchTerm: { $set: searchTerm },
      searchResults: { $set: [] },
    })
  ),

  on(searchForShopProducts, (state: ShopProductSearchState, { limit }) =>
    update(state, {
      limitOfLastSearch: { $set: limit },
      requestState: { $set: RequestState.InProgress },
    })
  ),

  on(searchForShopProductsSuccess, (state: ShopProductSearchState, { searchResults }) =>
    update(state, {
      searchResults: { $set: searchResults },
      requestState: { $set: RequestState.Success },
    })
  ),

  on(searchForShopProductsFailure, (state: ShopProductSearchState) =>
    update(state, { requestState: { $set: RequestState.Error } })
  ),

  on(resetShopProductsSearch, () => cloneDeep(initialShopProductSearchState))
);

export function shopProductSearchReducer(
  state: ShopProductSearchState | undefined,
  action: Action
): ShopProductSearchState {
  return _searchReducer(state, action);
}
