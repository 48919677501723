import { Injectable } from '@angular/core';
import { DocumentNode, gql } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { from, map } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { DataChangedKeys as DCK } from '../../../core.enums';
import {
    AppointmentReminderInterface,
    AppointmenUpdatetInterface
} from '../../../interfaces/appointments.interface';
import { DataChangedForceStateVar } from '../../locals/dataChangeForceState.var';

export const CreateAppointmentMutation = gql`
    mutation createAppointment($appointment: [appointment_insert_input!]!) {
        insert_appointment(objects: $appointment) {
            affected_rows
        }
    }
`;

export const UpdateAppointmentWithReminderMutation = gql`
    mutation updateAppointment($id:Int!, $appointment: appointment_set_input!, $appointmentReminder: [appointmentReminder_insert_input!]!) {
        update_appointment(where: {id: {_eq:$id}}, _set: $appointment) {
            affected_rows
        }
        delete_appointmentReminder (where: {appointmentId: {_eq: $id}}) {
            affected_rows
        }
        insert_appointmentReminder(objects: $appointmentReminder) {
            affected_rows
        }
    }
`;
export const UpdateAppointmentMutation = gql`
    mutation updateAppointment($id:Int!, $appointment: appointment_set_input!) {
        update_appointment(where: {id: {_eq:$id}}, _set: $appointment) {
            affected_rows
        }
    }
`;

export const DeleteAppointmentNotification = gql`
   mutation deleteAppointment($id:Int!) {
        DeleteNotificationByReference(referenceId: $id, tableName: appointment) {
            status
        }
    }
`;

export const DeleteAppointmentMutation = gql`
   mutation deleteAppointment($id:Int!) {
        delete_appointment(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const DeleteAppointmentReminderMutation = gql`
   mutation delete_appointmentReminder($id:Int!) {
        delete_appointmentReminder(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const DeleteAllAppointmentReminderMutation = gql`
   mutation delete_appointmentReminder($idGte:Int, $idLte:Int) {
        delete_appointmentReminder(where: { _and: [{id:{_gte: $idGte}},{id:{_lte: $idLte}}]}) {
            affected_rows
        }
    }
`;


export const AllAppointmentMutations = [
    CreateAppointmentMutation,
    UpdateAppointmentWithReminderMutation,
    UpdateAppointmentMutation,
    DeleteAppointmentMutation,
    DeleteAppointmentReminderMutation,
    DeleteAllAppointmentReminderMutation,
    DeleteAppointmentNotification
];

@Injectable()
export class AppointmentMutations {

    constructor(
        private apollo: Apollo,
        private apiService: ApiService,
        private dataChangedForceState: DataChangedForceStateVar
    ){}

    /**
     * trim values
     * @param data - Data to trim (string or null)
     * @return string|null
     */
    formatInputs = (data) => {
        if (typeof data === 'string') {
            return data.trim().length > 0 ? data.trim() : null;
        }
        return data;
    }


    createAppointment(appointment: AppointmenUpdatetInterface) {
        void this.dataChangedForceState.setForceState({[DCK.appointmentChanged]: null});
        Object.keys(appointment).forEach(key => {
            appointment[key] = this.formatInputs(appointment[key]);
        });
        this.apollo.mutate({
            mutation: CreateAppointmentMutation,
            variables: {appointment}
        }).subscribe({
            next: () => this.apiService.presentSuccessToast('Der Termin wurde angelegt.'),
            error: error => this.apiService.presentErrorToast(error, 'Der Termin konnte nicht angelegt werden.')
        });
    }

    updateAppointment(
        id: number,
        appointment: AppointmenUpdatetInterface,
        appointmentReminder: AppointmentReminderInterface | Array<any>
    ) {
        void this.dataChangedForceState.setForceState({[DCK.appointmentChanged]: null});
        Object.keys(appointment).forEach(key => {
            appointment[key] = this.formatInputs(appointment[key]);
        });
        if (appointmentReminder) {
            Object.keys(appointmentReminder).forEach(key => {
                appointmentReminder[key] = this.formatInputs(appointmentReminder[key]);
            });
        }
        this.apollo.mutate({
            mutation: appointmentReminder ? UpdateAppointmentWithReminderMutation : UpdateAppointmentMutation,
            variables: appointmentReminder ?
                { id, appointment, appointmentReminder } :
                { id, appointment }
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['update_appointment'] && d?.data['update_appointment']['affected_rows']))
                  .subscribe((affectedRows: number) => {
                      if (affectedRows >= 1 && !result['errors']) {
                          void this.apiService.presentSuccessToast('Der Termin wurde gespeichert.');
                      } else {
                          void this.apiService.presentErrorToast(null, 'Der Termin konnte nicht gespeichert werden.');
                      }
                  }).unsubscribe();
            },
            error: error => this.apiService.presentErrorToast(error, 'Der Termin konnte nicht gespeichert werden.')
        });
    }

    async deleteAppointment(id: number) {
        // needs to be called one by one to guarantee the appointment is not deleted before the notifications
        await this.dataChangedForceState.setForceState({[DCK.appointmentChanged]: null});
        await this.performAppointmentDeletion(id, DeleteAppointmentNotification);
        return await this.performAppointmentDeletion(id, DeleteAppointmentMutation);
    }

    performAppointmentDeletion(id: number, mutationQuery: DocumentNode) {
        return new Promise((resolve, reject) => {
            this.apollo.mutate({
                mutation: mutationQuery,
                variables: {id}
            }).subscribe({
                next: result => {
                    void this.apiService.presentSuccessToast('Der Termin wurde gelöscht.');
                    resolve(true);
                },
                error: error => {
                    void this.apiService.presentErrorToast(error, 'Der Termin konnte nicht gelöscht werden.');
                    reject();
                }
            });
        });
    }

    deleteSingleAppointmentReminder(id: number) {
        this.apollo.mutate({
            mutation: DeleteAppointmentReminderMutation,
            variables: {id}
        }).subscribe({
            next: () => this.apiService.presentSuccessToast('Die Benachrichtigung wurde erfolgreich gelöscht.'),
            error: error => this.apiService.presentErrorToast(error, 'Die Benachrichtigung konnten nicht gelöscht werden.')
        });
    }

    deleteAllAppointmentReminder(idGte: number, idLte: number) {
        this.apollo.mutate({
            mutation: DeleteAllAppointmentReminderMutation,
            variables: {idGte, idLte}
        }).subscribe({
            next: () => this.apiService.presentSuccessToast('Alle Benachrichtigungen wurden gelöscht.'),
            error: error => this.apiService.presentErrorToast(error, 'Die Benachrichtigungen konnten nicht gelöscht werden.')
        });
    }
}
