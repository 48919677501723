import { Component, Input, OnInit } from '@angular/core';
import { ColorInterface } from '../../interfaces/theme-color.interface';
import { StatisticsSizeEnum } from '../../enums/statistics.enum';


/**
 *
 * Usage:
 * <app-widget>
 *   <div header></div>
 *   <div content></div>
 *   <div footer></div>
 * </app-widget>
 */
@Component({
    selector: 'app-widget-small',
    templateUrl: './widget-small.component.html',
    styleUrls: ['./widget-small.component.scss'],
})
export class WidgetSmallComponent implements OnInit {
    @Input() title = '';
    @Input() titleExtension = '';
    @Input() titleExtensionColorClass = '';
    @Input() tooltipKey = null;
    @Input() widerContent = false;
    @Input() footerLabel = 'Mehr';
    @Input() clickable = false;
    @Input() size = StatisticsSizeEnum.DEFAULT;
    @Input() colorScheme?: ColorInterface;

    // Input fields to set a title icon
    @Input() useTitleIcon = false;
    @Input() titleIconUrl = '';
    @Input() titleIconAlt = '';
    @Input() titleIconClassList = '';

    // Input field whether the card content should have padding
    @Input() hasCardContentPadding = true;

    // Input fields whether the widget should have a footer or not
    @Input() hasFooter = true;

    // Input fields to set a minimum size for the widget
    @Input() useMinSize = false;
    @Input() minWidth = '';
    @Input() minHeight = '';

    smallSize = StatisticsSizeEnum.SMALL;

    constructor() { }

    ngOnInit() {}
}
