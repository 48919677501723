<app-modal i18n-title title="{{newsPost && newsPost.title ? newsPost.title : 'Lade ...'}}">
    <ng-container headerActions></ng-container>
    <ng-container content>
        <div *ngIf="!newsPost" class="ion-padding ion-margin display-flex ion-justify-content-center">
            <ion-spinner name="crescent"></ion-spinner>
        </div>
        <div *ngIf="!newsPost" class="ion-padding ion-margin display-flex ion-justify-content-center">
            <ion-spinner name="crescent"></ion-spinner>
        </div>
        <ion-grid class="ion-no-padding" *ngIf="newsPost">
            <ion-row class="ion-no-padding full-height">
                <ion-col size="18" size-xs="14" size-sm="16" size-md="17" size-lg="18" size-xl="17" class="ion-padding">
                    <markdown class="ion-text-left" [data]="newsPost.contentHtml || newsPost.content"></markdown>
                    <div *ngIf="videoHtml" class="video-container ion-margin-top">
                        <div [innerHTML]="videoHtml" class="video"></div>
                    </div>
                </ion-col>
                <ion-col size="6" size-xs="10" size-sm="8" size-md="7" size-lg="6" size-xl="7" class="ion-no-padding">
                    <app-news-sidebar [newsId]="newsPost.id"></app-news-sidebar>
                </ion-col>
            </ion-row>
        </ion-grid>

    </ng-container>

    <!-- Footer -->
    <ng-container footer *ngIf="newsPost">
        <div class="display-flex ion-justify-content-between">
            <div appTooltip
                 ttp="bottom"
                 [ttk]="this.isAlreadySubmitted ? 'already_sent' : null"
                 class="clickable"
                 *ngIf="this.newsPost.formId">
                <ion-button
                        [disabled]="this.isAlreadySubmitted"
                        name="formModal"
                        class="button-tertiary button-solid" i18n
                        (click)="onNewsFormModal()">
                    <ion-text *ngIf="!this.isFormLoading">{{ newsPost.formButton}}</ion-text>
                    <ion-spinner *ngIf="this.isFormLoading" name="crescent" class="ion-padding-end print-button-spinner form-modal-spinner"></ion-spinner>
                </ion-button>
            </div>

            <ion-button
                    color="primary"
                    *ngIf="newsPost.link"
                    (click)="onExternalLinkClick()"
                    i18n
            >Details im Web öffnen</ion-button>
        </div>
    </ng-container>
</app-modal>



<ion-modal class="modal-auto-height modal-medium modal-overflow-visible" [isOpen]="openModal" (didDismiss)="onNewsFormModalClose()" showBackdrop="true" mode="md">
    <ng-template>
        <app-form-modal
                formId="{{newsPost.formId}}"
                buttonText="{{newsPost.formButton}}"
                (isFormSubmitted)="onFormSubmitted($event)"
        >
        </app-form-modal>
    </ng-template>

</ion-modal>