export enum HelpSectionTypes {
    orders = 'orders',
    seminars = 'seminars',
    dashboard = 'dashboard',
    meadirekt = 'meadirekt',
    calendar = 'calendar',
    statistics = 'statistics',
    news = 'news',
    meamind = 'meamind',
    settings = 'settings',
    communications = 'communications',
    account = 'account'
}

export enum DisplayTypes {
    modal = 'modal',
    page = 'page'
}

