import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModuleActivationStateVar } from '../core/store/locals/moduleActivationState.var';
import { environment } from '../../environments/environment';
import { ModulActivationEnum } from '../core/enums/module-activation.enum';
import { NavigationRoutesEnum } from '../navigation/navigation.routes';
import { unsubscribe } from '../core/core.utils';

@Injectable({
    providedIn: 'root'
})
export class ModuleActivationGuard  {
    moduleActivationSubscription: Subscription;

    constructor(
        private router: Router,
        private moduleActivationStateVar: ModuleActivationStateVar
    ) { }


    /**
     * Activates the route only if the user has provided some login data
     */
    canActivate(): Promise<boolean> {
        /* replace returns with module that is locked for the main users
        switch (state.url) {
            case LogisticsRoutesEnum.returns:
                return this.checkModuleActivation(ModulActivationEnum.returnModule);
        }
        */
        return Promise.resolve(true);
    }

    checkModuleActivation(module: ModulActivationEnum): Promise<boolean> {
        return new Promise(resolve => {
            unsubscribe(this.moduleActivationSubscription);
            this.moduleActivationSubscription = this.moduleActivationStateVar.get().subscribe(activeModules => {
                if (environment.name !== 'production' && environment.name !== 'staging'
                    || activeModules.findIndex(item => item.module === module) > -1) {
                    resolve(true);
                } else {
                    void this.router.navigateByUrl(NavigationRoutesEnum.dashboard);
                    resolve(false);
                }
            });
        });
    }
}
