import { MeamindQuestionFilterEnum } from '../enums/meamind-question-filter.enum';
import { MeamindQuestionFilterInterface } from '../interfaces/meamind.interface';

export const MeamindFilters: Array<MeamindQuestionFilterInterface> = [
    {id: 1,     filters: [],                                        label: 'Alle Fragen'},
    {id: 2,     filters: [MeamindQuestionFilterEnum.unsolved],      label: 'Alle Fragen / ungelöst'},
    {id: 3,     filters: [MeamindQuestionFilterEnum.myQuestions],   label: 'Fragen meiner Apotheke'},
    {id: 4,     filters: [
                MeamindQuestionFilterEnum.myQuestions,
                MeamindQuestionFilterEnum.unsolved
            ],                                                      label: 'Fragen meiner Apotheke / ungelöst'},
    {id: 5,     filters: [
                MeamindQuestionFilterEnum.myQuestions,
                MeamindQuestionFilterEnum.solved
            ],                                                      label: 'Fragen meiner Apotheke / gelöst'},
    {id: 6,     filters: [MeamindQuestionFilterEnum.mySolutions],   label: 'Antworten meiner Apotheke'},

];

export const MeamindFilterDefault: MeamindQuestionFilterInterface = MeamindFilters.find(filter => filter.id === 4);
export const MeamindFilterAllSolved: MeamindQuestionFilterInterface = {
    id: 7,
    filters: [MeamindQuestionFilterEnum.solved],
    label: 'Alle Fragen / gelöst'
};
