import gql from 'graphql-tag';
import { backendUserConversationQueryAttributes } from '../queries/getUserConversations';

export default gql`
  subscription createdOwnUserConversation($ownerId: ID!) {
    createdOwnUserConversation(ownerId: $ownerId) {
      userConversations {
        ${backendUserConversationQueryAttributes}
      }
    }
  }
`;
