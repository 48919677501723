import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import last from 'lodash-es/last';
import { mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  openDeleteChatModal,
  updateDeletionRecordForConversation,
  updateOpenTicketReminderNotification,
  updateTicketHistory,
} from '../../../../../../store/src/common-store/chat-store/actions/chat-conversation-update.actions';
import { selectActiveConversationId } from '../../../../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { CommonState } from '../../../../../../store/src/common-store/common.state';
import { analyticsDuettEvent } from '../../../../../../store/src/common-store/other/actions/common-analytics.actions';
import { redirectAfterDeletedConversation } from '../../../../../../store/src/common-store/other/actions/navigation.actions';
import { selectCognitoId } from '../../../../../../store/src/common-store/user-store/selectors/user.selectors';
import { TicketEvent } from '../../../../../../essentials/types/src/backendConversation';
import { RemoveCommonChatDuettFields } from '../../../../../../essentials/types/src/common-chat-duett-fields';
import { CloseConversation } from '../../../../../../essentials/types/src/duett-schema/de.meineapotheke.chat/close_conversation/jsonschema/1-0-0';
import { DeleteConversation } from '../../../../../../essentials/types/src/duett-schema/de.meineapotheke.chat/delete_conversation/jsonschema/1-1-0';
import { ExtendConversation } from '../../../../../../essentials/types/src/duett-schema/de.meineapotheke.chat/extend_conversation/jsonschema/1-0-0';
import { ReopenConversation } from '../../../../../../essentials/types/src/duett-schema/de.meineapotheke.chat/reopen_conversation/jsonschema/1-0-0';
import FeatureFlags from '../../../../../../essentials/types/src/feature-flags';
import { MeaConfig } from '../../../../../../essentials/types/src/mea-config';
import TicketStatus from '../../../../../../essentials/types/src/ticketStatus';
import { AlertComponent } from '../../../../../ui-components/src/ionic/alert/alert.component';
import { CustomModalController } from '../../../../../ui-components/src/ionic/controllers/custom-modal.controller';
import { Logger } from '../../../../../../essentials/util/src/logger';
import { AppsyncConversationService } from '../../../services/appsync/appsync-conversation.service';

enum Roles {
  'CANCEL' = 'cancel',
  'DELETE' = 'delete',
}

const logger = new Logger('ChatConversationUpdateEffects');

@Injectable()
export class ChatConversationUpdateEffects {
  updateTicketHistory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateTicketHistory),
        mergeMap(async ({ conversationId, ticketEvent }) => {
          if (this.featureFlags.trackTicketUpdates) {
            this.trackTicketEvent(conversationId, ticketEvent);
          }
          try {
            await this.appsyncConversationService.updateTicketHistory(
              conversationId,
              ticketEvent.updatedStatus,
              ticketEvent.updatedBy
            );
          } catch (e) {
            logger.error('Error updating ticket history', e);
          }
        })
      ),
    { dispatch: false }
  );

  updateOpenTicketReminderNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateOpenTicketReminderNotification),
        mergeMap(async ({ backendUserConversationId, reminderNotification }) => {
          try {
            await this.appsyncConversationService.updateOpenTicketReminderNotification(
              backendUserConversationId,
              reminderNotification
            );
          } catch (e) {
            logger.error('Error updating open ticket reminder notification', e);
          }
        })
      ),
    { dispatch: false }
  );

  openDeleteChatModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openDeleteChatModal),
        switchMap(async (action) => {
          const translations = this.translationService.instant('DELETE_CHAT');
          const conversationId = action.conversationId;

          const modal = await this.modalCtrl.create({
            component: AlertComponent,
            cssClass: 'mea-alert',
            componentProps: {
              header: translations.HEADER,
              body: translations.BODY,
              buttons: [
                { type: 'secondary', text: translations.CANCEL, role: Roles.CANCEL },
                {
                  type: 'danger',
                  text: translations.DELETE,
                  role: Roles.DELETE,
                  handler: () => this.deleteConversation(conversationId),
                },
              ],
            },
            showBackdrop: true,
            backdropDismiss: true,
          });
          await modal.present();
        })
      ),
    { dispatch: false }
  );

  updateDeletionRecordForConversation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateDeletionRecordForConversation),
        withLatestFrom(this.store.select(selectActiveConversationId), this.store.select(selectCognitoId)),
        tap(([{ conversationId, deletionRecord }, activeConversationId, cognitoId]) => {
          if (
            this.isPharmacy &&
            conversationId === activeConversationId &&
            last(deletionRecord)?.cognitoId === cognitoId
          ) {
            this.store.dispatch(redirectAfterDeletedConversation({ deletedConversationId: conversationId }));
          }
        })
      ),
    { dispatch: false }
  );

  private readonly featureFlags: FeatureFlags;
  private readonly isPharmacy: boolean;

  constructor(
    @Inject('config') private config: MeaConfig,
    private actions$: Actions,
    private modalCtrl: CustomModalController,
    private translationService: TranslateService,
    private appsyncConversationService: AppsyncConversationService,
    private store: Store<CommonState>
  ) {
    this.isPharmacy = !this.config.clientApp;
    this.featureFlags = this.config.featureFlags;
  }

  private async deleteConversation(conversationId: string) {
    await this.appsyncConversationService.deleteConversation(conversationId);
    this.store.dispatch(redirectAfterDeletedConversation({ deletedConversationId: conversationId }));
    this.trackDeleteConversationEvent(conversationId);
  }

  private trackDeleteConversationEvent(conversationId: string) {
    const event: RemoveCommonChatDuettFields<DeleteConversation> = {
      event_type: 'delete_conversation',
      frontend_conversation_id: conversationId,
    };
    this.store.dispatch(analyticsDuettEvent({ event }));
  }

  private trackTicketEvent(conversationId: string, { updatedBy, updatedStatus }: TicketEvent) {
    switch (updatedStatus) {
      case TicketStatus.EXTENDED:
        {
          const event: RemoveCommonChatDuettFields<ExtendConversation> = {
            event_type: 'extend_conversation',
            frontend_conversation_id: conversationId,
            updated_by: updatedBy,
          };
          this.store.dispatch(analyticsDuettEvent({ event }));
        }
        break;
      case TicketStatus.CLOSED:
        {
          const event: RemoveCommonChatDuettFields<CloseConversation> = {
            event_type: 'close_conversation',
            frontend_conversation_id: conversationId,
            updated_by: updatedBy,
          };
          this.store.dispatch(analyticsDuettEvent({ event }));
        }
        break;
      case TicketStatus.REOPENED:
        {
          const event: RemoveCommonChatDuettFields<ReopenConversation> = {
            event_type: 'reopen_conversation',
            frontend_conversation_id: conversationId,
            updated_by: updatedBy,
          };
          this.store.dispatch(analyticsDuettEvent({ event }));
        }
        break;
      default:
        break;
    }
  }
}
