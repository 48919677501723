import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';
import { Appearance } from '../../../../../essentials/types/src/appearance';

export const setIsOnline = createAction(`${ActionType.Device}: Set isOnline`, props<{ isOnline: boolean }>());
export const setIsVisible = createAction(`${ActionType.Device}: Set isVisible`, props<{ isVisible: boolean }>());
export const setIsSmallDevice = createAction(
  `${ActionType.Device}: Set isSmallDevice`,
  props<{ isSmallDevice: boolean }>()
);
export const setShowEmailNotification = createAction(
  `${ActionType.Device}: Set show email notification`,
  props<{ showEmailNotification: boolean }>()
);

export const setMinimiseEmailNotification = createAction(
  `${ActionType.Device}: Set minimise email notification`,
  props<{ minimiseEmailNotification: boolean }>()
);

export const setAppearance = createAction(
  `${ActionType.Device}: Set appearance`,
  props<{ cognitoId?: string; appearance: Appearance }>()
);
