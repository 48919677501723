import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BannerComponent } from './banner.component';
import { SimpleModalModule } from '../simple-modal/simple-modal.module';
import { ModalModule } from '../modal/modal.module';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        SimpleModalModule,
        ModalModule
    ],
    exports: [
        BannerComponent
    ],
    declarations: [
        BannerComponent
    ]
})
export class BannerModule {
}
