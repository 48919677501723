<app-modal i18n-title title="{{title}}" subtitle="{{subtitle}}" [contentPadding]="false">
    <ng-container headerActions></ng-container>
    <ng-container content>
        <p-table class="bulk-action-modal-table" *ngIf="bulkItems.length > 0" [value]="bulkItems" responsiveLayout="scroll">
            <ng-template pTemplate="body" let-bulkItem>
                <tr [class.hasError]="bulkItem['hasError']">
                    <td class="type">{{bulkItem.type | orderTypeFilterLabel}}</td>
                    <td class="id">{{bulkItem['orderId']}}</td>
                    <td class="name">{{bulkItem['productName']}} - {{bulkItem['packageSize']}}</td>
                    <td class="pzn">PZN: {{bulkItem['pzn']}}</td>
                    <td class="hint-icon clickable ion-text-end">
                        <ion-icon *ngIf="bulkItem.hasError" appTooltip ttp="bottom" [ttk]="bulkAlertTooltip" class="bulk-alert-icon" name="alert-circle-outline"></ion-icon>
                    </td>
                    <td class="close clickable" (click)="removeItem(bulkItem.id)"><ion-icon name="close"></ion-icon></td>
                </tr>
            </ng-template>
        </p-table>
        <div *ngIf="bulkItems.length === 0" class="no-results" i18n>
            <ion-text class="h1">Bitte wählen Sie einen oder mehrere Aufträge aus.</ion-text>
        </div>
    </ng-container>

    <ng-container footer>
        <div appTooltip
             ttp="bottom"
             [ttk]="itemHasError ? 'bulk_item_error' : null"
             class="clickable"
        >
            <ion-button color="primary" [disabled]="bulkItems.length === 0 || itemHasError" (click)="submitBulkChanges()" i18n>{{ctaButtonLabel}}</ion-button>
        </div>
        <ion-button class="button-tertiary" (click)="closeModal(false)" i18n>Abbrechen</ion-button>
    </ng-container>
</app-modal>
