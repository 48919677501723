import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import {
    NotificationDataChangeInterface
} from '../../interfaces/notification.interface';
import { DataChangedCountKeys } from '../../enums/data-changed-keys.enum';

const GetNotificationCounts = gql`
    query GetNotificationCounts {
        notificationCounts @client {
            notificationReadNotArchivedCount
            notificationNotArchivedCount
            showReleaseNoteHint
        }
    }
`;
@Injectable({
    providedIn: 'root',
})
export class NotificationCountsVar {
    private notificationCountsObservable: Observable<NotificationDataChangeInterface>;
    private apollo: Apollo;
    private cache;
    private defaultValue = Object.values(DataChangedCountKeys)
        .reduce((a, v) => ({ ...a, [v]: null}), {});

    constructor(apollo: Apollo) {
        this.apollo = apollo;
    }

    init() {
        if(this.apollo.client) {
            this.cache = this.apollo.client.cache;
            this.notificationCountsObservable = new Observable<NotificationDataChangeInterface>(subscriber => {
                this.cache.watch({
                    query: GetNotificationCounts,
                    callback: res => {
                        subscriber.next(res.result && res.result.notificationCounts || this.defaultValue);
                    },
                    immediate: true,
                    optimistic: true
                });
            });
        }
    }

    get(): Observable<NotificationDataChangeInterface> {
        this.init();
        return this.notificationCountsObservable;
    }

    set(notificationCounts: NotificationDataChangeInterface) {
        this.init();
        if (this.cache) {
            this.cache.writeQuery({
                query: GetNotificationCounts,
                data: {
                    notificationCounts
                }
            });
        }
    }
}
