import gql from 'graphql-tag';

export interface Typing {
  participantId: string;
  conversationId: string;
  otherParticipantId?: string;
  frontendSessionId: string;
}

export default gql`
  mutation startTyping($participantId: ID!, $conversationId: ID!, $otherParticipantId: ID, $frontendSessionId: ID) {
    startTyping(
      participantId: $participantId
      conversationId: $conversationId
      otherParticipantId: $otherParticipantId
      frontendSessionId: $frontendSessionId
    ) {
      __typename
      participantId
      conversationId
      otherParticipantId
      frontendSessionId
    }
  }
`;
