import { Inject, Injectable } from '@angular/core';
import { Amplify } from '@aws-amplify/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { LogoutService } from '../../../common/resources/src/services/logout.service';
import { LoadStatus } from '../../../essentials/types/src/loadStatus';
import { LogoutType } from '../../../essentials/types/src/logoutType';
import { MeaConfig } from '../../../essentials/types/src/mea-config';
import { isNotNullOrUndefined } from '../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { selectChatData } from '../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { CommonState } from '../../../store/src/common-store/common.state';
import { selectUser } from '../../../store/src/common-store/user-store/selectors/user.selectors';
import { MeaChatConversationsService } from './mea-chat-conversations.service';
import { MeaChatSignInService } from './mea-chat-signin.service';
import { LoginStatus } from './types/loginStatus';
import { SconnectConversation } from './types/sconnectConversation';

@Injectable({
  providedIn: 'root',
})
export class MeaChatSconnectService {
  /** indicator if there are unread messages from endusers or pharmacies */
  hasUnreadMessages$: Observable<boolean> = this.meaChatConversationsService.hasUnreadMessages$;

  /** conversations with endusers */
  enduserConversations$: Observable<SconnectConversation[]> = this.meaChatConversationsService.enduserConversations$;

  /** conversations with endusers that are not marked as finished by the pharmacy */
  openEnduserConversations$: Observable<SconnectConversation[]> =
    this.meaChatConversationsService.openEnduserConversations$;

  /** conversations with pharmacies */
  pharmacyConversations$: Observable<SconnectConversation[]> = this.meaChatConversationsService.pharmacyConversations$;

  /** user is set in store after successful login to mea chat, triggers loading of conversations */
  userIsSet$: Observable<boolean> = this.store.select(selectUser).pipe(map((user) => !!user));

  /** conversations can be displayed once they are loaded */
  chatDataIsUpToDate$: Observable<boolean> = this.store.select(selectChatData).pipe(
    isNotNullOrUndefined(),
    map((data) => data.conversationsLoadStatus === LoadStatus.UpToDate)
  );

  constructor(
    private logoutService: LogoutService,
    private meaChatConversationsService: MeaChatConversationsService,
    private meaChatSignInService: MeaChatSignInService,
    private store: Store<CommonState>,
    private translate: TranslateService,
    @Inject('config') private config: MeaConfig
  ) {}

  /** must be called once in Sconnect AppComponent constructor */
  init() {
    Amplify.configure({ ...this.config.aws_config, Analytics: { disabled: true } });
    this.translate.setDefaultLang('de');
  }

  /** login pharmacy to mea chat with keycloak bearer token */
  async loginToMeaChat(sanacorpCustomerId: string | undefined, bearerToken: string | undefined): Promise<LoginStatus> {
    if (sanacorpCustomerId && bearerToken) {
      return this.meaChatSignInService.amplifySignInWithSsoToken(sanacorpCustomerId, bearerToken);
    }
    return LoginStatus.LoginError;
  }

  /** restore user's private key in case login returns LoginStatus.RestorePrivateKey */
  async restorePrivateKey(restorePassword: string): Promise<LoginStatus> {
    return this.meaChatSignInService.restorePrivateKeyAndContinueSignIn(restorePassword);
  }

  /** logout pharmacy from mea chat */
  async logoutFromMeaChat(): Promise<void> {
    await this.logoutService.logout(LogoutType.ActiveSconnectLogout);
  }

  /** get redirect link to conversation in mea chat frontend */
  getMeaChatRedirectUrl(sanacorpCustomerId: string, conversationId: string): string {
    const ssoRedirectRoute = 'sanacorp-login';
    const queryUrl = `/${ssoRedirectRoute}?customerId=${sanacorpCustomerId}&conversationId=${conversationId}`;
    return this.config.meaChatUrl + queryUrl;
  }
}
