<div class="tag-container">
    <ion-input
        i18n-label
        type="text"
        label="Schlagwörter"
        class="required"
        [disabled]="!editable"
        [placeholder]="tooltips.meamind_question_tags"
        (keyup.enter)="onSubmitNewTag()" 
        [(ngModel)]="customTag" 
        (ionChange)="onCustomTagChange($event)"
    ></ion-input>
    <ion-button
            *ngIf="editable"
            [class.disabled]="customTag.length === 0"
            (click)="onSubmitNewTag()"
            i18n
    >Hinzufügen</ion-button>
</div>
<div class="input-container tag-suggestions" [@tagsVisible]="customTag.length > 0 || tags.length > 0 || tagSuggestions.length > 0 ? 'visible' : 'hidden'">
    <ion-chip *ngFor="let tag of tagSuggestions" (click)="onSuggestedTagClick(tag)">
        <ion-icon name="add-outline"></ion-icon>
        <app-meamind-tag [tagIconVisible]="false" [tag]="tag"></app-meamind-tag>
    </ion-chip>
</div>

<div class="input-container" [@tagsVisible]="tags.length > 0 ? 'visible' : 'hidden'">
    <ion-text i18n>Ausgewählt:</ion-text>
    <ion-chip *ngFor="let tag of tags">
        <app-meamind-tag [tag]="tag"></app-meamind-tag>
        <ion-icon name="close" *ngIf="editable" (click)="onRemoveTagClick(tag)"></ion-icon>
    </ion-chip>
</div>
