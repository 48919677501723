import { Pipe, PipeTransform } from '@angular/core';
import { formatDateTimeToDate } from '../formatting/date.formatting';

@Pipe({
    name: 'replacePayload'
})
export class ReplacePayloadPipe implements PipeTransform {

    transform(text: string, payload: string | object, orderNumber: string): string {
        let values = payload && typeof payload === 'string' ? JSON.parse(payload.replace(/\\"/ig, '"')) : {};
        values = typeof payload === 'object' ? {...payload} : values;
        if (orderNumber) {
            values.order = orderNumber;
        }

        // Replace user object with first and last name of it
        if (values.user) {
            if (values.user.firstName && values.user.lastName) {
                values.user = values.user.firstName + ' ' + values.user.lastName;
            } else {
                // set "Unknown" if firstName or lastName were not set
                // otherwise [object Object] will displayed in frontend
                values.user = 'Unbekannt';
            }
        }

        if (text) {
            Object.keys(values).forEach(key => {
                let payloadValue = values[key];
                switch (key) {
                    case 'expiryDate':
                        payloadValue = formatDateTimeToDate(payloadValue);
                }
                text = text.replace('{{' + key + '}}', payloadValue);
            });
        }

        return text;
    }
}

