import { from, map } from 'rxjs';
import { DocumentNode, gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApiService } from '../../../services/api.service';
import { MeamindItemType } from '../../../enums/meamind-item-type.enum';

export const CreateMeamindEntryMutation = gql`
    mutation createMeamindEntry($type: MeamindType!, $title: String, $content: String!, $reference: Int, $tags: [String]) {
        CreateMeamindEntry(type: $type title: $title, content: $content, reference: $reference, tags: $tags) {
            status
            message
        }
    }
`;
export const UpdateMeamindEntryMutation = gql`
    mutation updateMeamindEntry($id: Int!, $title: String, $content: String!) {
        UpdateMeamindEntry(id: $id, title: $title, content: $content) {
            status
            message
        }
    }
`;

export const MarkQuestionAsSolved = gql`
    mutation markQuestionAsSolved($id: Int!, $isSolved: Boolean!) {
        MarkMeamindAnswerAsSolution(id: $id, isSolved: $isSolved) {
            status
            message
        }
    }
`;

export const DeleteMeamindNotification = gql`
    mutation deleteMeamindNotification($id: Int!) {
        DeleteNotificationByReference(referenceId: $id, tableName: meamind) {
            status
        }
    }
`;

export const DeleteMeamindEntry = gql`
    mutation deleteMeamindEntry($id: Int!) {
        delete_meamind_by_pk(id: $id) {
            id
        }
    }
`;

export const AllMeamindMutations = [
    CreateMeamindEntryMutation,
    UpdateMeamindEntryMutation,
    MarkQuestionAsSolved,
    DeleteMeamindEntry,
    DeleteMeamindNotification
];

@Injectable()
export class MeamindMutations {

    constructor(
        private apollo: Apollo,
        private apiService: ApiService
    ) {}

    /**
     * Create a new comment to a question or answer on meamind
     *
     * @param reference - id of the question or answer to which the comment refers
     * @param content - Comment Text
     */
    createComment(reference: number, content: string): Promise<void> {
        return new Promise( (resolve, reject) => {
            this._createMeamindItem({
                reference,
                content,
                type: MeamindItemType.comment
            }).subscribe({
                next: result => this._createMeamindItemResponse(result, resolve, reject, 'Ihr Kommentar'),
                error: error => {
                    void this.apiService.presentErrorToast(error, 'Ihr Kommentar konnte nicht übermittelt werden.');
                    reject();
                }
            });
        });
    }

    /**
     * Create a new answer to a question on meamind
     *
     * @param reference - id of the question to which the answer refers
     * @param content - Answer Text
     */
    createAnswer(reference: number, content: string): Promise<void>{
        return new Promise( (resolve, reject) => {
            this._createMeamindItem({
                reference,
                content,
                type: MeamindItemType.answer
            }).subscribe({
                next: result => this._createMeamindItemResponse(result, resolve, reject, 'Ihre Antwort'),
                error: error => {
                    void this.apiService.presentErrorToast(error, 'Ihre Antwort konnte nicht übermittelt werden.');
                    reject();
                }
            });
        });
    }

    /**
     * Create a new question on meamind
     *
     * @param title - Question Title
     * @param content - Question Text
     * @param tags - Question Tags
     */
    createQuestion(title: string, content: string, tags: string[]): Promise<void>{
        return new Promise( (resolve, reject) => {
            this._createMeamindItem({
                title,
                content,
                tags,
                type: MeamindItemType.question
            }).subscribe({
                next: result => this._createMeamindItemResponse(result, resolve, reject, 'Ihre Frage'),
                error: error => {
                    void this.apiService.presentErrorToast(error, 'Ihre Frage konnte nicht übermittelt werden.');
                    reject();
                }
            });
        });
    }

    /**
     * Update an entry on meamind
     *
     * @param type - Type of the entry
     * @param id - ID of the entry
     * @param title - Title of the entry
     * @param content - Content of the entry
     */
    updateMeamindEntry(type: MeamindItemType, id: number, title: string, content: string): Promise<void> {
        return new Promise( (resolve, reject) => {
            this.apollo.mutate({
                mutation: UpdateMeamindEntryMutation,
                variables: {
                    type,
                    id,
                    title,
                    content
                }
            }).subscribe({
                next: result => from([result])
                    .pipe(map(d => d?.data && d?.data['UpdateMeamindEntry'] && d?.data['UpdateMeamindEntry']['status']))
                    .subscribe((status: string) => {
                        if (!status || status === 'ERROR' || !!result['errors']) {
                            void this.apiService.presentErrorToast(result['errors'], `Der Eintrag konnte nicht geändert werden.`);
                            reject();
                        } else {
                            void this.apiService.presentSuccessToast(`Der Eintrag wurde geändert.`);
                            resolve();
                        }
                    }).unsubscribe(),
                error: error => {
                    void this.apiService.presentErrorToast(error, 'Der Eintrag konnte nicht geändert werden.');
                    reject();
                }
            });
        });
    }

    /**
     * Mark a new question on meamind
     *
     * @param id - ID of the question
     * @param isSolved - Is the question solved?
     */
    markQuestionAsSolved(id: number, isSolved: boolean): Promise<void>{
        return new Promise( (resolve, reject) => {
            this.apollo.mutate({
                mutation: MarkQuestionAsSolved,
                variables: {
                    id,
                    isSolved
                }
            }).subscribe({
                next: result => {
                    from([result])
                        .pipe(map(d => d?.data && d?.data['MarkMeamindAnswerAsSolution'] && d?.data['MarkMeamindAnswerAsSolution']['status']))
                        .subscribe((status: string) => {
                            if (!status || status === 'ERROR' || !!result['errors']) {
                                void this.apiService.presentErrorToast(result['errors'],
                                    isSolved ?
                                        'Die Antwort konnte nicht als Lösung markiert werden.' :
                                        'Die Markierung als Lösung konnte nicht entfernt werden.'
                                );
                                reject();
                            } else {
                                void this.apiService.presentSuccessToast(
                                    isSolved ?
                                        'Die Antwort wurde als Lösung markiert.' :
                                        'Die Markierung als Lösung wurde entfernt.'
                                );
                                resolve();
                            }
                        }).unsubscribe();
                },
                error: error => {
                    void this.apiService.presentErrorToast(error,
                        isSolved ?
                        'Die Antwort konnte nicht als Lösung markiert werden.' :
                        'Die Markierung als Lösung konnte nicht entfernt werden.'
                    );
                    reject();
                }
            });
        });
    }

    async deleteMeamindEntry(id: number): Promise<{id: number}> {
        // needs to be called one by one to guarantee the questions is not deleted before the notifications
        await this.performMeamindDeletion(id, DeleteMeamindNotification, 'DeleteNotificationByReference', true);
        return await this.performMeamindDeletion(id, DeleteMeamindEntry, 'delete_meamind_by_pk');
    }
    
    performMeamindDeletion(id: number, mutationQuery: DocumentNode, mutationName: string, ignoreDeletedItems = false): Promise<{id: number}> {
        return new Promise((resolve, reject) => {
            this.apollo.mutate({
                mutation: mutationQuery,
                variables: {id}
            })
                .subscribe({
                    next: result => {
                        from([result])
                            .pipe(map(d => d?.data && d?.data[mutationName]))
                            .subscribe((deletedItem => {
                                if (deletedItem?.id || ignoreDeletedItems) {
                                    void this.apiService.presentSuccessToast('Ihr  Beitrag wurde gelöscht.');
                                    resolve({id: deletedItem.id});
                                } else {
                                    void this.apiService.presentErrorToast(null, 'Ihr Beitrag konnte nicht gelöscht werden.');
                                    reject();
                                }
                            }))
                            .unsubscribe();
                    },
                    error: error => {
                        void this.apiService.presentErrorToast(error, 'Ihr Beitrag konnte nicht gelöscht werden.');
                        reject();
                    }
                });
        });
    }

    /**
     * Create a new question / answer / comment on meamind
     *
     * @param meamindItem - Details of the item, must include at least a type of MeamindItemType.
     * @private
     */
    private _createMeamindItem(meamindItem) {
        return this.apollo.mutate({
            mutation: CreateMeamindEntryMutation,
            variables: {
                ...meamindItem
            }
        });
    }

    private _createMeamindItemResponse(result, resolve, reject, type) {
        from([result])
            .pipe(map(d => d?.data && d?.data['CreateMeamindEntry'] && d?.data['CreateMeamindEntry']['status']))
            .subscribe((status: string) => {
                if (!status || status === 'ERROR' || !!result['errors']) {
                    void this.apiService.presentErrorToast(result['errors'], `${type} konnte nicht übermittelt werden.`);
                    reject();
                } else {
                    void this.apiService.presentSuccessToast(`${type} wurde übermittelt und veröffentlicht.`);
                    resolve();
                }
            }).unsubscribe();
    }

}
