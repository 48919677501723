export enum DateEnum {
    day = 'dd',
    dayNum = 'DD',
    month = 'MM',
    monthLabelShort = 'MMM',
    monthLabelLong = 'MMMM',
    year = 'YYYY',
    hour = 'HH',
    minute = 'mm',
    second = 'ss',
    yearMonth = 'YYYYMM',
    yearMonthDay = 'YYYYMMDD',
    dayMonthYear = 'DD.MM.YYYY'
}

export enum CompareDateEnum {
    isBefore,
    isSameOrBefore,
    isSame,
    isAfter,
    isSameOrAfter,
    diffInDays,
    diffInMonths,
    diffInHours,
    diffInMinutes
}
