import { Action, createReducer } from '@ngrx/store';
import { ReleaseState } from '../state/release.state';

// This is set by the webpack DefinePlugin
declare let VERSION: string;
const releaseVersion = typeof VERSION !== 'undefined' ? VERSION : 'development';

export const initialReleaseState: ReleaseState = {
  version: releaseVersion,
};

const _releaseReducer = createReducer(initialReleaseState);

export function releaseReducer(state: ReleaseState | undefined, action: Action): ReleaseState {
  return _releaseReducer(state, action);
}
