<app-modal i18n-title title="{{ title }}">
    <ng-container headerActions></ng-container>
    <ng-container content>
        <ng-container [ngTemplateOutlet]="infoModalTemplate"></ng-container>
    </ng-container>
    <!-- Footer -->
    <ng-container footer>
        <div>
            <ion-button (click)="onModalClose()" i18n>{{ closeButtonTitle }}</ion-button>
        </div>
    </ng-container>
</app-modal>