import gql from 'graphql-tag';
import { backendUserConversationQueryAttributes } from '../queries/getUserConversations';

export default gql`
  subscription createdUserConversation($otherParticipantId: ID!) {
    createdUserConversation(otherParticipantId: $otherParticipantId) {
      otherParticipantId
      userConversations {
        ${backendUserConversationQueryAttributes}
      }
    }
  }
`;
