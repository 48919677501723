import { Component, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-content-card',
    templateUrl: './content-card.component.html',
    styleUrls: ['./content-card.component.scss'],
})
export class ContentCardComponent {

    // Templates
    @Input() topBarTmpl: TemplateRef<any>;
    @Input() titleTmpl: TemplateRef<any>;
    @Input() contentTmpl: TemplateRef<any>;
    @Input() infoTmpl: TemplateRef<any>;
    @Input() actionBarTmpl: TemplateRef<any>;

    @Input() isLoading = false;
    @Input() item = null;

    constructor() {
    }


}
