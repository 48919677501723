import { Injectable } from '@angular/core';
import { Router, RouterEvent, Event } from '@angular/router';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../util/subscriptions.util';

const routeLeaveSubscription: {[key: string]: Subscription} = {};
export function onRouteChange(route: string, enterCallable = null, leaveCallable = null) {
    const routeLeaveService = RouteLeaveDecoratorService.getService();
    let oldUrl: string;
    const urlPrefix = route.slice(0, route.lastIndexOf('/'));
    if(routeLeaveService) {
        unsubscribe(routeLeaveSubscription[route]);
        routeLeaveSubscription[route] = routeLeaveService.events.subscribe((event: Event|RouterEvent) => {
            const url = event instanceof RouterEvent ? event?.url : null;
            if (url) {
                if (
                    leaveCallable && !url.includes(route) && urlPrefix !== url.slice(0, url.lastIndexOf('/')) && (!oldUrl || oldUrl === route)
                ) {
                    oldUrl = url;
                    leaveCallable();
                }
                if (
                    enterCallable && url.includes(route) && oldUrl && url !== oldUrl && urlPrefix !== oldUrl.slice(0, oldUrl.lastIndexOf('/'))
                ) {
                    oldUrl = url;
                    enterCallable();
                }
            }
            oldUrl = url || oldUrl;
        });
    }
}


/**
 * Necessary decorator service for injecting other services
 *
 * This service must be provided and **initialized** in a module!!!
 */
@Injectable()
export class RouteLeaveDecoratorService {
    private static router: Router | undefined = undefined;
    public constructor(private router: Router) {
        RouteLeaveDecoratorService.router = router;
    }
    public static getService(): Router {
        if(!RouteLeaveDecoratorService.router) {
            return null;
        }
        return RouteLeaveDecoratorService.router;
    }
}
