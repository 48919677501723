import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';

import { AuthGuard } from './guard/auth.guard';
import { AccessGuard } from './guard/access.guard';
import { ModuleActivationGuard } from './guard/moduleActivation.guard';
import { routes } from './app.routes';

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, bindToComponentInputs: true })
    ],
    providers: [AuthGuard, AccessGuard, ModuleActivationGuard],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
