<div class="main">
    <div class="bar-chart" #barChart id="{{uniqueID}}"
     [class.has-legend]="showLegend"
     [class.highlight-current-month]="highlightCurrentMonth"
     [class.highlight-last-year]="highlightLastYear"
    ></div>
    <ion-spinner name="crescent" *ngIf="isLoading"></ion-spinner>
    <div *ngIf="showLegend && !isLoading" class="statistics-bar-chart-legend">
        <ion-text i18n>Legende</ion-text>
        <div class="statistics-bar-chart-legend-current-month" (mouseover)="highlightCurrentMonth = true" (mouseout)="highlightCurrentMonth = false">
            <span></span>
            <ion-text i18n>Aktueller Monat</ion-text>
        </div>
        <div class="statistics-bar-chart-legend-last-year" (mouseover)="highlightLastYear = true" (mouseout)="highlightLastYear = false">
            <span></span>
            <ion-text i18n>Vorjahreszeitraum</ion-text>
        </div>
        <!-- TODO No sales data available
        <ion-text i18n>Monatsumsätze</ion-text>
        <div class="statistics-bar-chart-sales" *ngFor="let month of previousMonthSales;trackBy:trackBy">
            <ion-label>{{month.label}}</ion-label>
            <ion-text>{{month.value}} €</ion-text>
        </div>
        --->
        <!--<ion-button *ngIf="!showDetails" fill="clear" i18n class="ion-no-padding ion-text-uppercase widget-text-element ion-float-right ion-no-margin"
                    color="medium" size="small" (click)="onMoreButtonClick()">Mehr</ion-button>-->
    </div>
</div>
<div *ngIf="showDetails && !isLoading" class="details">
    <h3 i18n>Das aktuelle Jahr in Zahlen</h3>
    <div class="month-value-pairs">
        <div *ngFor="let current of data.current" class="month-value-pair">
            <ion-text>{{current.value}} {{unit}}</ion-text>
            <ion-label>{{formatMonthAndYear(current.month)}}</ion-label>
        </div>
    </div>
</div>
