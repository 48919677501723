<ion-button
  (click)="onClick($event)"
  [disabled]="disabled || loading"
  [expand]="expand"
  [fill]="fill"
  [ngClass]="[shadow ? 'shadow' : '', _buttonStyle]"
  [type]="type"
>
  @if (loading) {
    <ion-spinner></ion-spinner>
  }
  @if (icon) {
    <span [style.visibility]="loading ? 'hidden' : 'visible'" class="icon-span">
      <ion-icon slot="start" [src]="icon | iconName"></ion-icon>
    </span>
  }
  <span [style.visibility]="loading ? 'hidden' : 'visible'">
    <ng-content></ng-content>
  </span>
</ion-button>
