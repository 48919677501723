import { gql } from '@apollo/client/core';
import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable, switchMap } from 'rxjs';
import { FetchPolicyKeys as FPK } from '../../../enums/fetch-policy-keys.enum';
import { DataChangedKeys as DCK } from '../../../enums/data-changed-keys.enum';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { QueryWrapper } from '../query.wrapper';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import {
    MeaDownloadsDataInterface,
    MeaDownloadsFiltersInterface,
    MeaDownloadTypesInterface
} from '../../../interfaces/mea.interface';
import { formatStrapiDownloads, getFiltersStrapi } from '../utils';
import { MeaDownloadTypes, MeaFiltersTypeEnum } from '../../../enums/mea.enum';
import { PharmacyStoreStateVar } from '../../locals/pharmacyStoreState.var';
import { PharmacyStoreInterface } from '../../../core.interfaces';
export const GetMeaDownloads = (queryName) => gql`
    query ${queryName}($filters: DownloadFiltersInput) {
        downloads(filters: $filters) {
            data {
                id
                attributes {
                    description
                    label
                    downloadTypes {
                        data {
                            attributes {
                                type
                                color
                            }
                        }
                    }
                    createdAt
                    updatedAt
                    file {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const GetMeaDownloadFilter = (queryName) => gql`
    query ${queryName} {
        downloadTypes(pagination: {limit: 100}, sort: "type:asc") {
            data {
                id
                attributes {
                    type
                    color
                }
            }
        }
    }
`;


export const AllMeaQueries = [
    GetMeaDownloads('test'),
    GetMeaDownloadFilter('test')
];

@Injectable()
export class MeaQueries extends QueryWrapper {
    private pharmacyStoreStateVar = inject(PharmacyStoreStateVar);

    activePharmacyStore$ = this.pharmacyStoreStateVar.activePharmacyStoreState$;

    fetchPolicies = {
        [FPK.getMeaDownloads]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getMeaDownloadTypes]: QueryFetchPolicy.NETWORK_ONLY
    };
    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar,
    ) {
        super(apollo, dataChangedVar, {
            [DCK.downloadsChanged]: [FPK.getMeaDownloads],
            [DCK.downloadTypeChanged]: [FPK.getMeaDownloadTypes]
        });
    }

    public getMeaDownloads(filters: MeaDownloadsFiltersInterface[]): Observable<MeaDownloadsDataInterface[]> {
        const fetchPolicyKey = FPK.getMeaDownloads;
        const meaDownloadType = filters.find((f) => f.filterType === MeaFiltersTypeEnum.type);
        return this.activePharmacyStore$.pipe(
            switchMap(
                (pharmacy) =>
                    this.apollo
                        .watchQuery({
                            query: GetMeaDownloads(fetchPolicyKey),
                            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                            variables: this.getMeaDownloadsVariables(filters, pharmacy),
                        })
                        .valueChanges.pipe(
                            map((d) => d?.data && d.data['downloads']),
                            map((downloads) => formatStrapiDownloads(downloads, meaDownloadType)),
                            map((d) => {
                                if (d) this.updateFetchPolicy(fetchPolicyKey);
                                return d;
                            })
                        ) as Observable<MeaDownloadsDataInterface[]>
            )
        );
    }

    public getMeaDownloadTypes(): Observable<MeaDownloadTypesInterface[]> {
        const fetchPolicyKey = FPK.getMeaDownloadTypes;
        return this.apollo.watchQuery({
            query: GetMeaDownloadFilter(fetchPolicyKey),
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(
                map(d => d?.data && d.data['downloadTypes']),
                map(downloads => downloads ? downloads?.data?.map(
                    (download: {id: string, attributes: object}) => ({id: download.id, ...download.attributes})
                ) : []),
                map(d => {
                    if (d) this.updateFetchPolicy(fetchPolicyKey);
                    return d;
                })
            ) as Observable<MeaDownloadTypesInterface[]>;
    }

    public getMeaDownloadsVariables(filters: MeaDownloadsFiltersInterface[], pharmacy: PharmacyStoreInterface) {
        const variables = {
            filters: {
                and: [],
                or: []
            }
        };
        filters.forEach(filter => {
            switch (filter.filterType) {
                case MeaFiltersTypeEnum.date:
                    if (filter?.selectedValues?.dateFrom && filter.selectedValues?.dateTo) {
                        variables.filters.and.push({
                            createdAt: {
                                gte: `${filter.selectedValues.dateFrom}T00:00:00Z`,
                                lte: `${filter.selectedValues.dateTo}T23:59:59Z`
                            }
                        });
                    }
                    break;
                case MeaFiltersTypeEnum.type:
                    if (filter?.selectedValue && filter.selectedValue !== MeaDownloadTypes.ALL) {
                        variables.filters.and.push({
                            downloadTypes: {
                                type: { eq: filter.selectedValue }
                            }
                        });
                    }
                    break;
                case MeaFiltersTypeEnum.search:
                    if (filter?.selectedValue) {
                        variables.filters.or.push({
                            label: {
                                containsi: filter.selectedValue
                            }
                        });
                        variables.filters.or.push({
                            description: {
                                containsi: filter.selectedValue
                            }
                        });
                    }
                    break;
            }
        });
        variables.filters.and.push({
                isMea: {eq: true}
        });
        variables.filters.and.push(
            getFiltersStrapi(pharmacy)
        );
        if (variables.filters?.or.length === 0) {
            delete variables.filters.or;
        }
        return variables;
    }
}
