import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { ComponentRef } from '@ionic/core';
import { Observable, take } from 'rxjs';

import {
    FullMeaRoutesEnum,
    NotificationsEnum,
    NotificationsReminderTypeEnum,
    OffersAppAreaEnum,
    StaticPageTypeEnum
} from '../core.enums';
import { NotificationInterface } from '../interfaces/notification.interface';
import { NavigationRoutesEnum } from '../../navigation/navigation.routes';
import { NewsPostModalComponent } from '../../pages/news/components/news-post-modal/news-post-modal.component';
import { StaticPageModalComponent } from '../components/static-page-modal/static-page-modal.component';
import { InformationRoutesEnum } from '../../pages/information/information.routes';
import { SettingsRoutesEnum } from '../../pages/settings/settings.routes';
import { ModalService } from './modal.service';
import { DocumentService } from './document.service';
import { NewsPostQueries } from '../store/graphql/queries/news-post.graphql';
import { StaticPagesQueries } from '../store/graphql/queries/static-pages.graphql';
import { OffersQueries } from '../store/graphql/queries/offers.graphql';
import { ReturnsQueries } from '../store/graphql/queries/returns.graphql';
import { MeamindQueries } from '../store/graphql/queries/meamind.graphql';
import { OrderQueries } from '../store/graphql/queries/order.graphql';
import { SurveyModalComponent } from '../components/survey-modal/survey-modal.component';
import { SurveyService } from './survey.service';
import { StatisticsRoutesEnum } from '../../pages/statistics/statistics.routes';
import { OrdersService } from '../../pages/logistics/pages/orders/components/orders.service';
import { ReturnsService } from '../../pages/logistics/pages/returns/components/returns.service';


interface NotificationTypeInterface {
    id: NotificationsEnum;
    canGoToEntry: boolean;
    beforeGoToEntry?: (notification: NotificationInterface) => Promise<void>;
    goToEntry?: (notification: NotificationInterface) => Promise<void>;
    checkCanGoToEntry?: (notification: NotificationInterface) => Promise<boolean>;
}
interface NotificationTypePartInterface {
    beforeGoToEntry?: (notification: NotificationInterface) => Promise<void>;
    goToEntry?: (notification: NotificationInterface) => Promise<void>;
    checkCanGoToEntry?: (notification: NotificationInterface) => Promise<boolean>;
}

@Injectable({
    providedIn: 'root',
})
export class NotificationGoToEntryService {
    private ordersService = inject(OrdersService);
    private returnsService = inject(ReturnsService);

    constructor(
        private router: Router,
        private newsQueries: NewsPostQueries,
        private orderQueries: OrderQueries,
        private offersQueries: OffersQueries,
        private returnsQueries: ReturnsQueries,
        private meamindQueries: MeamindQueries,
        private surveyService: SurveyService,
        private staticPagesQueries: StaticPagesQueries,
        private modalService: ModalService,
        private navCtrl: NavController,
        private documentService: DocumentService,
        private alertController: AlertController
    ) {
    }

    notificationOrderType: NotificationTypePartInterface = {
        goToEntry: async(notification: NotificationInterface) => {
            if(notification.payload && notification.payload['realOrderId']) {
                // Ignore any previously received orders
                this.ordersService.setActiveOrderId(parseInt(notification.payload['realOrderId'], 10), true);
                await this.ordersService.openDetailsModal();
            }
        },
        checkCanGoToEntry: async(notification: NotificationInterface) => (
            this.checkGoToEntryQuery(notification, 'realOrderId', (realOrderId) => this.orderQueries.getOrderById(parseInt(realOrderId, 10)))
        )
    };
    notificationMeamindType: NotificationTypePartInterface = {
        goToEntry: async(notification: NotificationInterface) => this.goToMeamindPage(notification),
        checkCanGoToEntry: async(notification: NotificationInterface) => (
            this.checkGoToEntryQuery(notification, 'questionId', (questionId) => this.meamindQueries.getQuestionById(parseInt(questionId, 10)))
        )
    };
    notificationReturnsType: NotificationTypePartInterface = {
        goToEntry: async(notification: NotificationInterface) => {
            if(notification.payload && notification.payload['returnsId']) {
                this.returnsService.setActiveReturnId(notification.payload['returnsId']);
                await this.returnsService.openDetailsModal();
            }
        },
        checkCanGoToEntry: async(notification: NotificationInterface) => (
            this.checkGoToEntryQuery(notification, 'returnsId', (returnsId) => this.returnsQueries.getReturnById(parseInt(returnsId, 10)))
        )
    };
    notificationOfferType: NotificationTypePartInterface = {
        goToEntry: async(notification: NotificationInterface) => {await this.goToOfferPage(notification);},
        checkCanGoToEntry: async(notification) => (
            this.checkGoToEntryQuery(notification, 'id', (id) => this.offersQueries.getOffersWithDetails(id))
        )
    };
    notificationNewsType: NotificationTypePartInterface = {
        goToEntry: async(notification: NotificationInterface) => {
            await this.presentEntryModal(NewsPostModalComponent, 'newsId', notification, 'id');
        },
        checkCanGoToEntry: async(notification) => (
            this.checkGoToEntryQuery(notification, 'id', (id) => this.newsQueries.getNewsPost(id))
        )
    };
    notificationDocumentType: NotificationTypePartInterface = {
        goToEntry:async(notification: NotificationInterface) => {
            await this.navCtrl.navigateRoot(NavigationRoutesEnum.invoicePage, {
                queryParams: {
                    documentNumber: notification.payload['documentNumber']
                }
            });
        },
        checkCanGoToEntry: async(notification: NotificationInterface) => this.checkGoToEntryParameter(notification, 'documentNumber')
    };
    notificationSurveyType: NotificationTypePartInterface = {
        goToEntry: async(notification: NotificationInterface) => {await this.presentEntryModal(SurveyModalComponent, 'surveyId', notification, 'surveyId');},
        checkCanGoToEntry: async(notification) => (
            this.checkGoToEntryQuery(notification, 'surveyId', (surveyId) => this.surveyService.getSurveyByIdWithUserCheck(parseInt(surveyId, 10)))
        )
    };
    notificationReleaseNoteType: NotificationTypePartInterface = {
        goToEntry: async () => {await this.navCtrl.navigateRoot(SettingsRoutesEnum.releaseNotes);},
        checkCanGoToEntry: async() => true
    };
    notificationStaticPageType: NotificationTypePartInterface = {
        goToEntry: async(notification: NotificationInterface) => this.goToStaticPage(notification),
        checkCanGoToEntry: async(notification) => (
            this.checkGoToEntryQuery(notification, 'type', (type) => this.staticPagesQueries.getStaticPageByType(type as StaticPageTypeEnum))
        )
    };
    notificationDataPolicyType: NotificationTypePartInterface = {
        goToEntry: async() => {await this.navCtrl.navigateRoot(SettingsRoutesEnum.termsOfUse);},
        checkCanGoToEntry: async() => true
    };
    notificationUserWelcomeType: NotificationTypePartInterface = {
        goToEntry: async () => {await this.navCtrl.navigateRoot(InformationRoutesEnum.general, {queryParams: {url: 'dashboard'}});},
        checkCanGoToEntry: async() => true
    };
    notificationMeaShopType: NotificationTypePartInterface = {
        goToEntry: async (notification) => { window.open(notification.payload.orderUrl);},
        checkCanGoToEntry: async(notification) => this.checkGoToEntryParameter(notification, 'orderUrl')
    };
    notificationAssortmentStatisticsType: NotificationTypePartInterface = {
        goToEntry: async () => {await this.navCtrl.navigateRoot(StatisticsRoutesEnum.assortment);},
        checkCanGoToEntry: async() => true
    };

    notificationTypes: Array<NotificationTypeInterface> = [
        { id: NotificationsEnum.REMINDER_APPOINTMENT, canGoToEntry: false},
        { id: NotificationsEnum.REMINDER_SEMINAR_REGISTRATION, canGoToEntry: false},
        { id: NotificationsEnum.MEASHOP_NEW_ORDER, canGoToEntry: true, ...this.notificationMeaShopType},
        { id: NotificationsEnum.ORDER_ADDITIONAL_DEADLINE, canGoToEntry: true, ...this.notificationOrderType},
        { id: NotificationsEnum.ORDER_DISPO_DIRECT_OUT_OF_DATE, canGoToEntry: true, ...this.notificationOrderType},
        { id: NotificationsEnum.ORDER_STATUS_ENROUTE, canGoToEntry: true, ...this.notificationOrderType},
        { id: NotificationsEnum.ORDER_STATUS_CANCELLED, canGoToEntry: true, ...this.notificationOrderType},
        { id: NotificationsEnum.ORDER_QUANTITY_CHANGE, canGoToEntry: true, ...this.notificationOrderType},
        { id: NotificationsEnum.TOUR_NOTIFICATION, canGoToEntry: false},
        { id: NotificationsEnum.RETURNS_PRODUCT_MISSING, canGoToEntry: true, ...this.notificationReturnsType},
        { id: NotificationsEnum.RETURNS_STOCK_ERROR, canGoToEntry: true, ...this.notificationReturnsType},
        { id: NotificationsEnum.ORDER_CANCEL_ERROR, canGoToEntry: true, ...this.notificationOrderType},
        { id: NotificationsEnum.ORDER_EXTEND_ERROR, canGoToEntry: true, ...this.notificationOrderType},
        { id: NotificationsEnum.NEW_OFFER, canGoToEntry: true, ...this.notificationOfferType},
        { id: NotificationsEnum.NEW_NEWS, canGoToEntry: true, ...this.notificationNewsType},
        { id: NotificationsEnum.NEW_IMPORTANT_NEWS, canGoToEntry: true, ...this.notificationNewsType},
        { id: NotificationsEnum.NEW_DOCUMENT, canGoToEntry: true, ...this.notificationDocumentType},
        { id: NotificationsEnum.MEAMIND_NEW_QUESTION, canGoToEntry: true, ...this.notificationMeamindType},
        { id: NotificationsEnum.MEAMIND_NEW_ANSWER, canGoToEntry: true, ...this.notificationMeamindType},
        { id: NotificationsEnum.MEAMIND_NEW_COMMENT, canGoToEntry: true, ...this.notificationMeamindType},
        { id: NotificationsEnum.MEAMIND_UPDATE_ANSWER, canGoToEntry: true, ...this.notificationMeamindType},
        { id: NotificationsEnum.MEAMIND_UPDATE_QUESTION, canGoToEntry: true, ...this.notificationMeamindType},
        { id: NotificationsEnum.MEAMIND_UPDATE_COMMENT, canGoToEntry: true, ...this.notificationMeamindType},
        { id: NotificationsEnum.NEW_SURVEY, canGoToEntry: true, ...this.notificationSurveyType},
        { id: NotificationsEnum.NEW_RELEASE_NOTE, canGoToEntry: true, ...this.notificationReleaseNoteType },
        { id: NotificationsEnum.NEW_STATIC_PAGE, canGoToEntry: true, ...this.notificationStaticPageType},
        { id: NotificationsEnum.NEW_MAINTENANCE, canGoToEntry: false},
        { id: NotificationsEnum.NEW_USER_DATA_POLICY, canGoToEntry: true, ...this.notificationDataPolicyType },
        { id: NotificationsEnum.NEW_USER_WELCOME, canGoToEntry: true, ...this.notificationUserWelcomeType},
        { id: NotificationsEnum.COOPERATION_REVENUE_CURRENT_YEAR, canGoToEntry: false},
        { id: NotificationsEnum.SALES_VOLUME_WITHOUT_HIGH_PRICED, canGoToEntry: false},
        { id: NotificationsEnum.NEW_ASSORTMENT_STATISTIC, canGoToEntry: true, ...this.notificationAssortmentStatisticsType},
    ];


    // ==========================================================================================
    // Public
    // ==========================================================================================

    /**
     * Goes to the source of the notification
     */
    async goToEntry(notification: NotificationInterface, hasModal = true) {
        const notificationConfig = this.notificationTypes.find(n => n.id === notification.type);
        if (!notificationConfig || !notificationConfig.canGoToEntry) {
            return;
        }
        const isAvailable = await this.checkGoToEntry(notification);
        if(!isAvailable) {
            return;
        }
        if (notificationConfig.beforeGoToEntry) {
            await notificationConfig.beforeGoToEntry(notification);
        }
        await this.closeParentModal(hasModal);
        await notificationConfig.goToEntry(notification);
    }

    /**
     * Check if entry is available.
     *
     * Notice: This uses the same queries as after the navigation to fill the cache.
     * There shouldn't be duplicate calls.
     *
     * @param notification
     * @private
     */
    async checkGoToEntry(notification: NotificationInterface): Promise<boolean> {
        const notificationConfig = this.notificationTypes.find(n => n.id === notification.type);
        if (!notificationConfig || !notificationConfig.canGoToEntry) {
            return false;
        }

        const isAvailable = await notificationConfig.checkCanGoToEntry(notification);
        if(!isAvailable) {
            const alert = await this.alertController.create({
                header: 'Nicht gefunden',
                message: 'Der Eintrag ist leider nicht mehr verfügbar.',
                buttons: [{
                    text: 'Ok'
                }]
            });
            await alert.present();
        }
        return isAvailable;
    }

    /**
     * Check if entry is available.
     *
     * @param notificationType
     * @private
     */
    checkHasEntry(notificationType: NotificationsEnum | NotificationsReminderTypeEnum): boolean {
        const notificationConfig = this.notificationTypes.find(n => n.id === notificationType);
        return !!notificationConfig?.canGoToEntry;
    }


    // ==========================================================================================
    // Private
    // ==========================================================================================

    private async checkGoToEntryQuery(
        notification: NotificationInterface,
        payloadType: string,
        queryObservable: (queryParam: string) => Observable<any>,
        primaryKey = 'id'
    ): Promise<boolean> {
        return new Promise(resolve => {
            const queryParam = notification.payload && notification.payload[payloadType];
            if (!queryParam) {
                // true because of fallback navigation
                resolve(true);
                return;
            }
            queryObservable(queryParam).pipe(take(1)).subscribe(page => {
                resolve(!!(page && page[primaryKey]));
            });
        });
    }
    private checkGoToEntryParameter(
        notification: NotificationInterface,
        payloadType: string,
    ): boolean {
        return !!(notification.payload && notification.payload[payloadType]);
    }


    private async goToStaticPage(notification: NotificationInterface) {
        const pageType = notification.payload?.type;
        if(pageType) {
            await this.presentEntryModal(StaticPageModalComponent, 'pageType', notification, 'type');
        } else {
            await this.navCtrl.navigateRoot(SettingsRoutesEnum.privacy);
        }
    }
    private async goToMeamindPage(notification: NotificationInterface) {
        await this.router.navigate([NavigationRoutesEnum.meamind, {id: notification.payload.questionId}]);
    }
    private async goToOfferPage(notification: NotificationInterface) {
        const isSanacorpOffer = notification.payload['appArea']?.includes(OffersAppAreaEnum.SANACORP);
        const isMeaOffer = !isSanacorpOffer && notification.payload['appArea']?.includes(OffersAppAreaEnum.MEA);
        const notificationId = notification.payload['id'] ? notification.payload['id'] : '';
        if (isMeaOffer && !isSanacorpOffer) {
            // Navigate to mea offers page
            return await this.router.navigateByUrl(`${FullMeaRoutesEnum.offers}/${notificationId}`);
        }
        // Navigate to offer page
        return await this.router.navigateByUrl(`${NavigationRoutesEnum.offers}/${notificationId}`);
    }

    /**
     * open the go-to entry modal
     *
     * @param component
     * @param componentPropKey
     * @param notification
     * @param payloadKey
     */
    private async presentEntryModal(
        component: ComponentRef,
        componentPropKey: string,
        notification: NotificationInterface,
        payloadKey: string
    ){
        if(notification.payload && notification.payload[payloadKey]) {
            const value = notification.payload[payloadKey];
            const modal = await this.modalService.create(
                component,
                {
                    [componentPropKey]: value
                },
            );
            return await this.modalService.present(modal);
        }
    }

    /**
     * closes the parent modal if hasModal is true
     *
     * @param hasModal : boolean
     * @private
     */
    private async closeParentModal(hasModal: boolean) {
        if (hasModal) {
            await this.modalService.dismiss({closeParentModal:true});
        }
    }
}
