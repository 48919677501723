<mea-optional-shadow-root
  ><div class="mea-alert-wrapper">
    @if (header) {
      <div class="mea-alert-header">{{ header }}</div>
    }
    @if (body) {
      <div class="mea-alert-body">{{ body }}</div>
    }
    @if (innerHtml) {
      <div [innerHTML]="innerHtml" class="mea-alert-html-body"></div>
    }
    @if (buttons) {
      <div class="mea-alert-button-container">
        @for (button of buttons; track button; let buttonIndex = $index) {
          <mea-button
            (buttonClick)="onButtonClick(button, buttonIndex)"
            [disabled]="anyHandlerIsInProgress()"
            [loading]="thisHandlerIsInProgress(buttonIndex)"
            [buttonStyle]="button.type"
            >{{ button.text }}</mea-button
          >
        }
      </div>
    }
  </div>
</mea-optional-shadow-root>
