import { Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';

import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import 'd3-transition';

import { SelectPopoverComponent } from '../select-popover/select-popover.component';
import { StatisticTypeLabelPipe } from '../../pipes/statistic-type-label.pipe';
import { StatisticTypeValueInterface } from '../../interfaces/statistics.interface';
import { PopoverService } from '../../services/popover.service';


@Component({
    selector: 'app-statistics-donut-chart',
    templateUrl: './statistics-donut-chart.component.html',
    styleUrls: ['./statistics-donut-chart.component.scss'],
    providers: [StatisticTypeLabelPipe]
})
export class StatisticsDonutChartComponent implements OnInit, OnChanges {
    @Input() data: StatisticTypeValueInterface[];
    @Input() isLoading = false;
    @Input() showDetails = false;
    @ViewChild('donutChart') donutChart;

    uniqueID = '';

    chosenItem = null;
    chartData = [];

    // Chart Variables
    width: number;
    height: number;
    radius: number;
    svg: any;
    g: any;
    arc: any;
    color: any;
    circle: any;
    isChartInitialized = false;
    tries = 0;

    constructor(
        private statisticTypeLabelPipe: StatisticTypeLabelPipe,
        private popoverService: PopoverService

    ) {
        this.generateUniqueId();
    }

    ngOnInit() {}


    ngOnChanges(changes: SimpleChanges): void {
        if(changes.isLoading && this.data.length > 0
        || changes.data && !this.isLoading) {
            if(!this.isChartInitialized) {
                setTimeout( _ => {
                    this.initChart();
                }, 500);

            } else {
                this.chosenItem = this.data[0];
                this.updateChartData();
                this.updateChart();
            }
        }
    }

    /**
     * Check if it is possible to create the chart, initialize it if it is possible
     */
    initChart() {
        if(this.donutChart && this.donutChart.nativeElement
            && this.donutChart.nativeElement.offsetHeight
            && !this.isChartInitialized && !this.isLoading) {

            this.height = this.donutChart.nativeElement.offsetHeight;
            this.width = this.donutChart.nativeElement.offsetWidth;
            this.radius = Math.min(this.width, this.height) / 1.75;

            this.chosenItem = this.data[0];
            this.updateChartData();

            this.initSVG();
            this.drawChart();
            this.isChartInitialized = true;
        } else if(this.tries < 1000) {
            this.tries++;
            setTimeout(() => {
                this.initChart();
            }, 250);
        }
    }

    /**
     * Generate a unique id to prevent duplicate id's in html
     */
    generateUniqueId() {
        this.uniqueID = 'donutChart_' + Math.round(Math.random() * 10000000);
    }

    /**
     * Get percentage for chart
     */
    updateChartData() {
        let restPercentage = 100 - this.chosenItem.value;
        restPercentage = restPercentage > 0 ? restPercentage : 0;

        this.chartData = [];
        this.chartData.push({percent: this.chosenItem.value});
        this.chartData.push({percent: restPercentage});
    }

    /**
     * Initialize the chart svg
     */
    initSVG() {
        this.color = d3Scale.scaleOrdinal()
            .range(['url(#gradient-' + this.uniqueID + ')', 'var(--ion-color-light)']);

        this.arc = d3Shape.arc()
            .innerRadius(this.radius - (Math.min(this.width, this.height) / 6 + 4))
            .outerRadius(this.radius - (Math.min(this.width, this.height) / 6));

        const pieValue = d3Shape.pie().value((d: any) => d.percent);
        this.circle = pieValue.sort(null);

        this.svg = d3.select('#'+this.uniqueID)
            .append('svg')
            .attr('preserveAspectRatio', 'xMinYMin meet')
            .attr('viewBox', `0 0 ${this.width} ${this.height}`);

        this.g = this.svg.append('g')
            .attr('transform', 'translate(' + Math.min(this.width, this.height) / 2 + ',' + Math.min(this.width, this.height) / 2 + ')');

        this.addGradient();
    }


    /**
     * Draw the chart depending on the values
     */
    drawChart() {
        this.g.selectAll('path')
            .data(this.circle(this.chartData))
            .enter()
            .append('path')
            .attr('d', this.arc)
            .attr('fill', (d: any) => d.index === 0 ? 'url(#gradient-' + this.uniqueID + ')' : 'var(--ion-color-light)')
            .attr('transform', 'translate(0, 0)');
    }

    /**
     * Update the cart with new data
     */
    updateChart() {
        const path = this.g.selectAll('path')
            .data(this.circle(this.chartData));

        // TODO Remove if transition is working
        path.attr('d', this.arc);

        /* TODO Transition has a "swing" effect
        path.transition()
            .duration(1500)
            .attr('d', this.arc);
            */
    }

    /**
     * User has pressed the type button
     */
    async presentTypePopover(event: any) {
        const typePopover = await this.popoverService.create({
            component: SelectPopoverComponent,
            componentProps: {
                options: this.data.map(item => ({
                    id: item.statisticType,
                    title: this.statisticTypeLabelPipe.transform(item.statisticType)
                })),
                selectedKey: this.chosenItem.statisticType
            },
            event,
            translucent: true
        });

        typePopover.onDidDismiss().then(result => {
            if(result.data && result.data.key) {
                const chosenItem = this.data.filter(item => item.statisticType === result.data.key)[0];
                if(chosenItem) {
                    this.chosenItem = chosenItem;
                    this.updateChartData();
                    this.updateChart();
                }
            }
        });

        return await this.popoverService.present(typePopover);

    }

    /**
     * Add linear gradient definition to the svg
     */
    addGradient() {
        // Add gradient color definition
        const gradient = this.g.append('defs')
            .append('linearGradient')
            .attr('id', 'gradient-' + this.uniqueID);

        gradient.append('stop')
            .attr('offset', '0%')
            .attr('class', 'donut-chart-primary-color');

        gradient.append('stop')
            .attr('offset', '50%')
            .attr('class', 'donut-chart-secondary-color');
    }
}
