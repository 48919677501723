import gql from 'graphql-tag';
import { backendUserConversationQueryAttributes } from './getUserConversations';

export default gql`
  query getUserConversationByConversationId($conversationId: ID!) {
    getUserConversationByConversationId(conversationId: $conversationId) {
      ${backendUserConversationQueryAttributes}
    }
  }
`;
