import gql from 'graphql-tag';
import { endUserAttributes } from '../attributes';

export default gql`
  query getMyEndUser($geo: GeoInput) {
    meEndUser(geo: $geo) {
      ${endUserAttributes}
    }
  }
`;
