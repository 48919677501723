import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';

// Core Files
import { ContactPersonService, InputValidationService, ModalService } from '../../../../../../core/core.services';
import {
    CommunicationZoneFormEnum,
    CommunicationZoneFormSubstituteEnum,
    CommunicationZoneReferenceTypeEnum,
    InputValidationFieldEnum,
    matomoIdsEnum,
    ModalClassEnum
} from '../../../../../../core/core.enums';
import {
    OrderDetailInterface,
    ReturnsDetailInterface
} from '../../../../../../core/core.interfaces';
import { formatDateTimeToDate, formatDateTimeToTime } from '../../../../../../core/core.formatting';
import { OrderQueries, ProfileSettingsVar, ReturnsQueries } from '../../../../../../core/core.store';
import { CommunicationZoneFormsConfig } from '../../../../../../core/config/communication-zone-forms.config';
import { CommunicationZoneMutations } from '../../../../../../core/store/graphql/mutations/communication-zone.graphql';
import { OrderTypesTranslationConfig, TOOLTIPS } from '../../../../../../core/core.config';
import { CommunicationZoneFormsSubstituteInterface } from '../../../../../../core/interfaces/communication-zone.interface';
import { unsubscribe, unsubscribeAll } from '../../../../../../core/core.utils';

@Component({
    selector: 'app-communication-zone-form',
    templateUrl: './communication-zone-form.component.html',
    styleUrls: ['./communication-zone-form.component.scss'],
    animations: [
        trigger('suggestionsVisible', [
            state('visible', style({
                flex: '1 1 32%',
                width: '*',
                padding: '*',
                opacity: 1
            })),
            state('hidden', style({
                flex: '0 0 0',
                padding: 0,
                width: 0,
                opacity: 0
            })),
            transition('visible=>hidden', animate('250ms')),
            transition('hidden=>visible', animate('250ms')),
        ])
    ]
})
export class CommunicationZoneFormComponent implements OnInit {
    static modalClass = ModalClassEnum.large;

    private communicationZoneMutation = inject(CommunicationZoneMutations);
    private contactPersonService = inject(ContactPersonService);
    private validationService = inject(InputValidationService);
    private orderQueries = inject(OrderQueries);
    private returnsQueries = inject(ReturnsQueries);
    private modalService = inject(ModalService);
    private formBuilder = inject(FormBuilder);
    private profileSettingVar = inject(ProfileSettingsVar);

    @Input() orderId: number = null;
    @Input() returnsId: number = null;
    @Input() payload: CommunicationZoneFormsSubstituteInterface = null;
    @Input() predefinedForm: CommunicationZoneFormEnum = null;
    @Input() predefinedFormSubstitute: CommunicationZoneFormSubstituteEnum = null;
    @Input() isReadonly: boolean = null;
    @Input() reference: { type: CommunicationZoneReferenceTypeEnum, id: number };

    matomoId = matomoIdsEnum.contactSupport;

    tooltips = TOOLTIPS;

    validatorSubscription: Subscription;
    orderSubscription: Subscription;
    returnsSubscription: Subscription;
    order: OrderDetailInterface;
    returns: ReturnsDetailInterface;
    validators = this.validationService.communicationZoneValidators;
    contentPrefix = '';
    validationFieldsEnum = InputValidationFieldEnum;
    title = '';
    contactNames: Array<string>;
    showContactList = false;

    // Popover Controller, provided by ionic
    popover;

    validationFormGroup: FormGroup;

    ngOnInit() {
        this.setValidationFormGroup();

        // Check if there is an oder id provided.
        // If yes, add some details of the order to the content (not visible to the user)
        if (this.orderId > 0) {
            this.reference = {
                type: CommunicationZoneReferenceTypeEnum.ORDERS,
                id: this.orderId
            };
            unsubscribe(this.orderSubscription);
            this.orderSubscription = this.orderQueries.getOrderById(this.orderId).subscribe(order => {
                this.order = order;
                const orderType = OrderTypesTranslationConfig[order.type] ? OrderTypesTranslationConfig[order.type] : '';
                this.title = `Rückfrage zu ${orderType} Nr. ${order.orderIdOrg}`;
                this.contentPrefix = orderType + ' Nr. ' +
                    (this.order.orderIdOrg ? this.order.orderIdOrg : '')  + '\n' +
                    (this.order.quantity ? this.order.quantity : '') + ' * ' +
                    (this.order.productName ? this.order.productName : '') + ', ' +
                    (this.order.dosageForm ? this.order.dosageForm : '') + ', ' +
                    (this.order.packageSize ? this.order.packageSize : '') + ', ' +
                    (this.order.producer ? this.order.producer : '') +
                    (this.order.pzn ? ', PZN ' + this.order.pzn.padStart(8, '0') : '') + '\n' +
                    'Bestelldatum: ' + formatDateTimeToDate(this.order.recTime, true) + ' ' +
                    formatDateTimeToTime(this.order.recTime, true) + '\n\n';
            });
        }

        // Check if there is a returns id provided.
        // If yes, add some details of the returns to the content (not visible to the user)
        if (this.returnsId > 0) {
            this.reference = {
                type: CommunicationZoneReferenceTypeEnum.RETURNS,
                id: this.returnsId
            };
            unsubscribe(this.returnsSubscription);
            this.returnsSubscription = this.returnsQueries.getReturnById(this.returnsId).subscribe(returns => {
                this.returns = returns;
                this.title = this.predefinedForm && this.predefinedForm === CommunicationZoneFormEnum.returnsProductMissing ?
                    'Retoure im Status "'+ returns.status +'" ' + returns.productName :
                    'Rückfrage zur RETOURE ' + returns.productName;
                const recTime = returns.recTime ? formatDateTimeToDate(returns.recTime, true) : 'unbekannt';
                const deliveryDate = returns.deliveryNoteDate ? formatDateTimeToDate(returns.deliveryNoteDate, false) : 'unbekannt';
                this.contentPrefix = 'Datum: ' + recTime + '\n' +
                    (returns.quantity ? returns.quantity : '') + ' * ' +
                    (returns.productName ? returns.productName : '') + ', ' +
                    (returns.dosageForm ? returns.dosageForm : '') + ', ' +
                    (returns.packageSize ? returns.packageSize : '') + ', ' +
                    (returns.producer ? returns.producer : '') +
                    (returns.pzn ? ', PZN ' + returns.pzn.padStart(8, '0') : '') + '\n' +
                    'Lieferscheinnummer: ' + returns.deliveryNoteNumber + '\n' +
                    'Lieferscheindatum: ' + deliveryDate + '\n\n';

                // set title if predefined and disable it
                if ((this.returnsId || this.orderId || this.isReadonly) && this.title.length > 0) {
                    this.validationFormGroup.controls['title'].disable();
                    this.validationFormGroup.controls['title'].setValue(this.title);
                }
            });
        }

        this.contactNames = this.contactPersonService.get();
    }

    ionViewWillLeave() {
        unsubscribeAll([
            this.orderSubscription,
            this.returnsSubscription,
            this.validatorSubscription
        ]);
    }

    /**
     * Validate the form data
     */
    setValidationFormGroup() {
        const formGroup = {
            title: [],
            content: ['', this.validationService.getFormValidators(InputValidationFieldEnum.content, this.validators)],
            contactPerson: ['', this.validationService.getFormValidators(InputValidationFieldEnum.contactPerson, this.validators)]
        };
        if (!this.orderId && !this.returnsId && (!this.payload || (this.payload && !this.payload.documentNumber))) {
            formGroup.title = ['', this.validationService.getFormValidators(InputValidationFieldEnum.title, this.validators)];
        }
        this.validationFormGroup = this.formBuilder.group(formGroup);

        switch (this.predefinedFormSubstitute) {
            case CommunicationZoneFormSubstituteEnum.companyChangeRequest:
                this.checkPredefinedForm();
                break;
            case CommunicationZoneFormSubstituteEnum.customerEmail:
                this.checkPredefinedForm(this.profileSettingVar.profileSettings() ? this.profileSettingVar.profileSettings().user.email : null);
                break;
            default:
                this.checkPredefinedForm();
        }
    }

    /**
     * Check if there is a predefined form value
     */
    checkPredefinedForm(customerEmail: string = null) {
        if(this.predefinedForm !== null && !this.returnsId) {
            // Use JSON parse to avoid reference to the original object
            const predefinedFormValues = JSON.parse(JSON.stringify(CommunicationZoneFormsConfig
                .find(item => item.formId === this.predefinedForm)));
            if (customerEmail) {
                predefinedFormValues.values.content = predefinedFormValues.values.content.replace('{{CUSTOMER_EMAIL}}', customerEmail);
            }
            // TODO Improve with forEachLoop through configuration
            if (this.payload && this.predefinedFormSubstitute) {
                predefinedFormValues.values.content =
                    predefinedFormValues.values.content.replace('{{DOCUMENT_NUMBER}}', this.payload.documentNumber);
                predefinedFormValues.values.title =
                    predefinedFormValues.values.title.replace('{{DOCUMENT_NUMBER}}', this.payload.documentNumber);
                predefinedFormValues.values.content =
                    predefinedFormValues.values.content.replace('{{DOCUMENT_REC_DATE}}', this.payload.documentRecDate);
                predefinedFormValues.values.title =
                    predefinedFormValues.values.title.replace('{{DOCUMENT_REC_DATE}}', this.payload.documentRecDate);

                this.contentPrefix = predefinedFormValues.values.content;
            }
            if (this.isReadonly) {
                this.validationFormGroup.patchValue({
                        title: predefinedFormValues.values.title,
                        contactPerson: predefinedFormValues.values.contactPerson
                    });
                this.title = predefinedFormValues.values.content;
                this.contentPrefix = predefinedFormValues.values.content;
            } else if (this.predefinedFormSubstitute && this.predefinedFormSubstitute === CommunicationZoneFormSubstituteEnum.document) {
                this.validationFormGroup.patchValue({
                    title: predefinedFormValues.values.title,
                });
            } else {
                this.validationFormGroup.patchValue(predefinedFormValues.values);
            }
        }
    }

    onClickOutsideOfContactInput() {
        this.showContactList = false;
    }

    onContactClicked(contact: string) {
        this.showContactList = false;
        this.validationFormGroup.controls.contactPerson.setValue(contact);
    }

    /**
     * User submitted the form
     *
     * @param values - form values
     */
    onSubmitCommunicationZoneData(values) {
        if (values) {
            if (this.orderId > 0) {
                this.communicationZoneMutation.insertOrderCommunicationZone({
                    content: this.contentPrefix + values.content,
                    contactPerson: values.contactPerson,
                    orderId: this.orderId
                });
            } else {
                this.communicationZoneMutation.insertCommunicationZone({
                    title: this.returnsId ? this.title : values.title,
                    content: this.contentPrefix + values.content,
                    contactPerson: values.contactPerson,
                    referenceType: this.reference ? this.reference.type : null,
                    referenceId: this.reference ? this.reference.id : null
                });
            }
            this.setContactPerson(values.contactPerson);
            void this.modalService.dismiss();
        }
    }

    setContactPerson(contactPerson: string) {
        void this.contactPersonService.set(contactPerson);
    }
}
