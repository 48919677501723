import gql from 'graphql-tag';
import { endUserAttributes } from '../attributes';

export default gql`
  subscription endUserUpdated($cognitoId: ID!) {
    endUserUpdated(cognitoId: $cognitoId) {
      ${endUserAttributes}
    }
  }
`;
