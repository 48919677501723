/* eslint-disable max-len */
import { CurrentAppContext } from '../../types/src/appContext';
import { ConversationIntent } from '../../types/src/conversationIntent';
import { InitialMessageContext, InitialMessageContextKey, InitialMessages } from '../../types/src/initialMessages';
import { PlaceholderVariables } from '../../types/src/placeholderVariables';
import { assertUnreachable } from './typescript.util';

export class InitialMessageUtil {
  public static getTemplate(context: InitialMessageContext, initialMessages?: InitialMessages) {
    const contextKey = this.mapContextToContextKey(context);
    return initialMessages?.[contextKey] ?? this.getDefaultTemplate(context);
  }

  public static getDefaultTemplate({ appContext, isOpen }: InitialMessageContext): string {
    const segments = [`Hallo ${PlaceholderVariables.CHAT_PARTNER_NAME},`];
    switch (appContext) {
      case 'ASK_QUESTION':
        segments.push(
          'gerne helfen wir Ihnen bei Fragen rund um unsere Apothekenleistungen, Produkte und Medikamente weiter. Wie können wir Ihnen heute helfen?'
        );
        break;
      case 'BOOK_APPOINTMENT':
        segments.push(
          'vielen Dank für Ihre Terminbuchung. Falls nötig, melden wir uns nach Prüfung Ihrer Angaben zeitnah bei Ihnen. Haben Sie noch Fragen oder Anmerkungen zum Termin? Hinterlassen Sie uns hier einfach eine Nachricht.'
        );
        break;
      case 'PREORDER':
        segments.push(
          'Ihr Warenkorb wurde erfolgreich versendet. Wir melden uns bei Ihnen, sobald wir Ihr Anliegen prüfen konnten. Sollten Sie noch Fragen oder Anmerkungen haben, können Sie uns hier einfach eine Nachricht hinterlassen.'
        );
        break;
      case 'PRODUCT_QUERY':
        segments.push(
          'gerne helfen wir Ihnen bei Fragen zu einem Produkt weiter, beraten bei der Auswahl oder prüfen die Verfügbarkeit. Um welches Produkt geht es?'
        );
        break;
      default:
        assertUnreachable(appContext);
    }
    if (!isOpen) {
      segments.push(
        'Bitte beachten Sie, dass wir aktuell geschlossen haben. Wir melden uns innerhalb unserer Öffnungszeiten bei Ihnen.',
        '',
        'Öffnungszeiten',
        PlaceholderVariables.OPENING_HOURS_GENERAL
      );
    }
    return segments.join('\n');
  }

  public static mapContextToContextKey({ appContext, isOpen }: InitialMessageContext): InitialMessageContextKey {
    switch (appContext) {
      case 'ASK_QUESTION':
        return isOpen ? 'askQuestion' : 'askQuestionClosed';
      case 'BOOK_APPOINTMENT':
        return isOpen ? 'bookAppointment' : 'bookAppointmentClosed';
      case 'PREORDER':
        return isOpen ? 'preorder' : 'preorderClosed';
      case 'PRODUCT_QUERY':
        return isOpen ? 'productQuery' : 'productQueryClosed';
    }
  }

  public static mapConversationIntentToCurrentAppContext(intent: ConversationIntent): CurrentAppContext {
    switch (intent) {
      case 'ASK_QUESTION':
      case 'UPLOAD_PRESCRIPTION':
        return 'ASK_QUESTION';
      case 'BOOK_APPOINTMENT':
        return 'BOOK_APPOINTMENT';
      case 'MEDICATION_QUERY':
        return 'PRODUCT_QUERY';
    }
  }
}
