import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'masonry'
})
export class MasonryPipe implements PipeTransform {
    /**
     * This pipe returns a new array containing only items of one column of a masonry layout.
     * E.g. for a layout having three columns, the first array contains items nr. 1, 4, 7 and so on.
     * The second array (items of the second column) contains items nr. 2, 5, 8, ...
     */
    transform(value: any[], numColumns: number, colNum: number): unknown {
        if (value && value.length === 0) return value;
        if (numColumns < 1 || colNum < 1 || isNaN(numColumns) || isNaN(colNum) ||colNum > numColumns) {
            console.error('Invalid column configuration');
            return value;
        }
        return value.filter((val, index) => {
            return index % numColumns  === colNum-1;
        });
    }
}
