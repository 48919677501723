import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';
import {
  BackendChatPartnerMetadata,
  ChatPartnerMetadata,
} from '../../../../../essentials/types/src/chatPartnerMetadata';
import { Conversation } from '../../../../../essentials/types/src/conversation';
import DecryptionStatus from '../../../../../essentials/types/src/decryptionStatus';

export const loadChatPartnerMetadata = createAction(`${ActionType.ChatPartner}: Load chat partner metadata`);

export const loadChatPartnerMetadataSuccess = createAction(
  `${ActionType.ChatPartner}: Load chat partner metadata success`,
  props<{ allChatPartnerMetadata: ChatPartnerMetadata[] }>()
);

export const loadChatPartnerMetadataFailure = createAction(
  `${ActionType.ChatPartner}: Load chat partner metadata failure`
);

export const updateChatPartnerMetadata = createAction(
  `${ActionType.ChatPartner}: Update chat partner metadata`,
  props<{ chatPartnerMetadata: BackendChatPartnerMetadata }>()
);

export const deleteAllChatPartnerMetadata = createAction(`${ActionType.ChatPartner}: Delete all chat partner metadata`);

export const setChatPartnerNickname = createAction(
  `${ActionType.ChatPartner}: Set chat partner nickname`,
  props<{
    decryptedChatPartnerNickname: string;
    encryptedChatPartnerNickname: string;
    chatPartnerId: string;
    cognitoId: string;
  }>()
);

export const editChatPartnerNickname = createAction(
  `${ActionType.ChatPartner}: Edit chat partner nickname`,
  props<{ conversation: Conversation; chatPartnerNickname: string }>()
);

export const addDecryptedChatPartnerNames = createAction(
  `${ActionType.ChatPartner}: Add decrypted chat partner names`,
  props<{
    newlyDecryptedChatPartnerNicknames: {
      cognitoId: string;
      chatPartnerId: string;
      chatPartnerNicknameDecryptionStatus: DecryptionStatus;
      decryptedChatPartnerNickname?: string;
    }[];
    newlyDecryptedChatPartnerChatnames: {
      cognitoId: string;
      chatPartnerId: string;
      chatPartnerChatnameDecryptionStatus: DecryptionStatus;
      decryptedChatPartnerChatname?: string;
    }[];
  }>()
);
