import { Validators } from '@angular/forms';

export function QuantityValidator(required = true, max = 99) {
    const validator = required ? [Validators.required] : [];
    validator.push(Validators.pattern('^(0|[1-9][0-9]*)$'));
    validator.push(Validators.min(1));
    validator.push(Validators.max( max));

    return validator;
}
