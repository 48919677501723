import { Routes } from '@angular/router';
import { AccessGuard } from '../../guard/access.guard';
import { AccessRightEnum } from '../../core/enums/access-right.enum';
import { FeatureFlagGuard } from '../../guard/feature-flag.guard';

export enum StatisticsRoutesEnum {
    sales = '/app/statistics/sales',
    packages = '/app/statistics/packages',
    orders = '/app/statistics/orders',
    assortment = '/app/statistics/assortment',
}

export const childrenRoutes: Routes = [
    {
        path: 'sales',
        loadChildren: () => import('./pages/statistics-sales/statistics-sales.module').then(m => m.StatisticsSalesModule),
        canActivate: [AccessGuard],
        data: {accessRight: AccessRightEnum.statistics}
    },
    {
        path: 'packages',
        loadChildren: () => import('./pages/statistics-packages/statistics-packages.module').then(m => m.StatisticsPackagesModule),
        canActivate: [AccessGuard],
        data: {accessRight: AccessRightEnum.statistics}
    },
    {
        path: 'orders',
        loadChildren: () => import('./pages/statistics-orders/statistics-orders.module').then(m => m.StatisticsOrdersModule),
        canActivate: [AccessGuard],
        data: {accessRight: AccessRightEnum.statistics}
    },
    {
        path: 'assortment',
        loadChildren: () => import('./pages/statistics-assortment/statistics-assortment.module').then(m => m.StatisticsAssortmentModule),
        canActivate: [FeatureFlagGuard, AccessGuard],
        data: {accessRight: AccessRightEnum.statistics}
    },
    {
        path: 'no-access',
        loadChildren: () => import('../common/no-access/no-access.module').then(m => m.NoAccessPageModule)
    },
    {
        path: '',
        redirectTo: 'sales',
        pathMatch: 'full'
    }
];
