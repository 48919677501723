import gql from 'graphql-tag';

export default gql`
  subscription startedTyping($otherParticipantId: ID!) {
    startedTyping(otherParticipantId: $otherParticipantId) {
      __typename
      participantId
      conversationId
      otherParticipantId
      frontendSessionId
    }
  }
`;
