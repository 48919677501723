import { gql } from '@apollo/client/core';
import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable, switchMap } from 'rxjs';

import { QueryFetchPolicy } from '../../../enums/api.enum';
import { formatStrapiNewsData, formatStrapiOffersConnectionData, getFiltersStrapi } from '../utils';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { QueryWrapper } from '../query.wrapper';
import {
    FetchPolicyKeys as FPK
} from '../../../enums/fetch-policy-keys.enum';
import{
    DataChangedKeys as DCK
} from '../../../enums/data-changed-keys.enum';
import { getNewsPostVariables, newsPostFields } from './news-post.graphql';
import { getOffersVariables, offerFields } from './offers.graphql';
import { PharmacyStoreStateVar } from '../../locals/pharmacyStoreState.var';
import { DynamicPageInterface } from '../../../interfaces/dynamicPage.interface';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { DynamicPageDisplayLocation } from '../../../enums/dynamicPage.enum';

export const GetDynamicPage = (queryName) => gql`
    query ${queryName}(
    $filters: DynamicPageFiltersInput,
    $newsFilters: NewsPostFiltersInput,
    $offerFilters: OfferFiltersInput,
    $offerFiltersLimit: Int
    ) {
        dynamicPages(
            filters: $filters,
            pagination: {limit: 1},
            sort: [
                "filter.customerNumbers:asc"
                "filter.chatUserOnly:desc"
                "filter.shopUserOnly:desc"
                "filter.sanacorpLocations.id:asc"
            ]
        ) {
            data {
                id
                attributes {
                    dynamicContent {
                        ... on ComponentDynamicContentContentModule {
                            id
                            headline
                            subheadline
                            content
                            image {
                                data {
                                    id
                                    attributes {
                                        url
                                    }
                                }
                            }
                            simpleModal {
                                id
                                modalLink
                                modalContent
                            }
                            __typename
                        }
                        ... on ComponentDynamicContentNewsSlider {
                            id
                            newsSliderElement(filters: {news: $newsFilters}, pagination: {limit: 100}) {
                                id
                                news {
                                    ${newsPostFields}
                                }
                            }
                            __typename
                        }
                        ... on ComponentDynamicContentOfferSlider {
                            id
                            offerSliderElement(filters: {offer: $offerFilters}, pagination: {limit: 100}) {
                                id
                                offer {
                                    ${offerFields}
                                }
                            }
                            __typename
                        }
                    }
                }
            }
        }
    }
`;


export const AllDynamicPageQueries = [
    GetDynamicPage('test')
];

@Injectable()
export class DynamicPageQueries extends QueryWrapper {
    private pharmacyStoreStateVar = inject(PharmacyStoreStateVar);

    fetchPolicies = {
        [FPK.getDynamicPage]: QueryFetchPolicy.NETWORK_ONLY
    };
    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar,
    ) {
        super(apollo, dataChangedVar, {
            [DCK.dynamicPageChanged]: [FPK.getDynamicPage],
            [DCK.newsPostChanged]: [FPK.getDynamicPage],
            [DCK.offerChanged]: [FPK.getDynamicPage],
        });
    }

    activePharmacyStore$ = this.pharmacyStoreStateVar.activePharmacyStoreState$;

    public getDynamicPage(displayLocationValue: DynamicPageDisplayLocation = DynamicPageDisplayLocation.mea): Observable<DynamicPageInterface> {
        const fetchPolicyKey = FPK.getDynamicPage;

        return this.activePharmacyStore$.pipe(
            switchMap(
                (pharmacy) =>
                    this.apollo
                        .watchQuery({
                            query: GetDynamicPage(fetchPolicyKey),
                            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                            variables: {
                                filters: {
                                    displayLocation: { containsi: displayLocationValue },
                                    ...getFiltersStrapi(pharmacy),
                                },
                                newsFilters: getNewsPostVariables(pharmacy, null).filters,
                                offerFilters: getOffersVariables(pharmacy, null, GraphQLLimits.offers, null).filters,
                                offerFiltersLimit: GraphQLLimits.offerFilters,
                            },
                        })
                        .valueChanges.pipe(
                            map((d) => d?.data && d.data['dynamicPages']),
                            map((page) => (page?.data?.length > 0 ? page?.data[0] : null)),
                            map((page) =>
                                page
                                    ? {
                                          id: page?.id,
                                          ...page?.attributes,
                                          dynamicContent: page?.attributes?.dynamicContent.map((content: any) => {
                                              if (content?.image) {
                                                  return { ...content, image: content?.image?.data?.attributes?.url };
                                              }
                                              if (content?.newsSliderElement) {
                                                  return {
                                                      ...content,
                                                      newsSliderElement: content?.newsSliderElement
                                                          ?.map((news) => formatStrapiNewsData(news?.news?.data, false))
                                                          .filter((news: any) => !!news),
                                                  };
                                              }
                                              if (content?.offerSliderElement) {
                                                  return {
                                                      ...content,
                                                      offerSliderElement: content?.offerSliderElement
                                                          ?.map((offer) => formatStrapiOffersConnectionData(offer?.offer?.data))
                                                          .filter((offer: any) => !!offer),
                                                  };
                                              }
                                              return content;
                                          }),
                                      }
                                    : null
                            ),
                            map((d) => {
                                if (d) this.updateFetchPolicy(fetchPolicyKey);
                                return d;
                            })
                        ) as Observable<DynamicPageInterface>
            )
        );
    }
}
