import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DownloadFileResult } from '../../../../../essentials/types/src/fileData';
import { MessageWithMedia } from '../../../../../essentials/types/src/message';
import MessageMedia, {
  AppointmentMedia,
  DecryptedCalendarEvent,
  DecryptedFileMedia,
  DecryptedImageMedia,
  DecryptedMessageMedia,
  DecryptedShopProductMedia,
  ERezeptMedia,
  FileMedia,
  ImageBase64Media,
  ShopProductMedia,
} from '../../../../../essentials/types/src/messageMedia';
import { MimeTypeEnum } from '../../../../../essentials/types/src/mimeTypeEnum';
import { Product } from '../../../../../essentials/types/src/product';
import { ShopProduct } from '../../../../../essentials/types/src/shopProduct';
import { DataUrlUtil } from '../../../../../essentials/util/src/chat/data-url.util';
import { FileUtil } from '../../../../../essentials/util/src/chat/file.util';
import { Logger } from '../../../../../essentials/util/src/logger';
import { ShopProductUtil } from '../../../../../essentials/util/src/shop-product.util';
import { updateMessageMedia } from '../../../../../store/src/common-store/chat-store/actions/chat-message.actions';
import { CommonState } from '../../../../../store/src/common-store/common.state';
import { EncryptionService } from '../encryption/encryption.service';
import { S3Service } from '../s3.service';

const logger = new Logger('MessageMediaDecryptionService');

@Injectable({ providedIn: 'root' })
export class MessageMediaDecryptionService {
  constructor(
    private encryption: EncryptionService,
    private s3Service: S3Service,
    private store: Store<CommonState>
  ) {}

  async createPdf(
    fileMedia: DecryptedFileMedia,
    conversationPassword: string | null | undefined,
    message: MessageWithMedia
  ): Promise<DownloadFileResult> {
    if (!fileMedia.decryptedContent || !fileMedia.decryptedFilename || !conversationPassword) {
      return { error: { isRecoverable: true } };
    }
    const downloadFileResult = await this.downloadAndDecryptFile({
      conversationPassword,
      fileName: fileMedia.decryptedFilename,
      filePath: fileMedia.decryptedContent,
    });
    if (downloadFileResult.error) {
      this.store.dispatch(
        updateMessageMedia({
          message,
          media: {
            ...fileMedia,
            error: downloadFileResult.error.isRecoverable ? 'FAILED_TO_DOWNLOAD' : 'FILES_NOT_FOUND',
          },
        })
      );
    }
    return downloadFileResult;
  }

  async downloadAndDecryptFile({
    conversationPassword,
    fileName,
    filePath,
  }: {
    conversationPassword: string;
    fileName: string;
    filePath: string;
  }): Promise<DownloadFileResult> {
    try {
      const base64Content = await this.getBase64Content(filePath, conversationPassword);
      const { objectUrl, fileString } = FileUtil.convertBase64ContentToFile(base64Content);
      return {
        data: {
          fileDataUrl: DataUrlUtil.convertContentToDataUrl(base64Content, { mimeType: MimeTypeEnum.PDF }),
          objectUrl,
          title: fileName.replace(/ /g, '_'),
          fileString,
        },
      };
    } catch (e) {
      logger.info('Failed to create pdf', e);
      return { error: { isRecoverable: !this.attachmentHasBeenDeletedInS3(e) } };
    }
  }

  async downloadAndDecryptFileAsBase64({
    conversationPassword,
    filePath,
  }: {
    conversationPassword: string;
    filePath: string;
  }): Promise<string | undefined> {
    try {
      return await this.getBase64Content(filePath, conversationPassword);
    } catch (e) {
      logger.info('Failed to download and decrypt file as base64', e);
      return undefined;
    }
  }

  async downloadAndDecryptImage(
    encryptedLink: string | undefined,
    password: string | undefined,
    contentKey: 'decryptedPreview' | 'decryptedContent'
  ): Promise<Partial<DecryptedImageMedia>> {
    const result: Partial<DecryptedImageMedia> = {};
    if (!encryptedLink) {
      result.error = 'FAILED_TO_DOWNLOAD';
      return result;
    }

    try {
      const decryptedLink = this.encryption.decryptUsingPassword(encryptedLink, password);
      return this.downloadImage(decryptedLink, password, contentKey);
    } catch (e) {
      result.error = 'FAILED_TO_DOWNLOAD';
    }
    return result;
  }

  async downloadImage(
    decryptedLink: string,
    password: string | undefined,
    contentKey: 'decryptedPreview' | 'decryptedContent'
  ) {
    const result: Partial<DecryptedImageMedia> = {};
    try {
      const file = await this.s3Service.getFile(decryptedLink);
      result[contentKey] = this.encryption.decryptUsingPassword(file.body, password);
      result.contentType = file.contentType;
      result.error = undefined;
    } catch (error: any) {
      if (this.attachmentHasBeenDeletedInS3(error)) {
        result.error = 'IMAGES_NOT_FOUND';
      } else {
        result.error = 'FAILED_TO_DOWNLOAD';
      }
    }
    return result;
  }

  async validateAndDecryptMediaArray(
    media: MessageMedia[],
    password: string | undefined
  ): Promise<DecryptedMessageMedia[]> {
    const decryptedMedia: DecryptedMessageMedia[] = [];
    for (const medium of media) {
      switch (medium.mediaType) {
        case 'FILE':
          if (medium.encryptedLink) {
            const decryptedMedium = await this.getDecryptedFileNameAndLink(medium, password);
            decryptedMedia.push(Object.assign({}, medium, decryptedMedium));
          }
          break;
        case 'IMAGE':
          const decryptedImage = await this.validateAndDecryptImageMedia(medium, password);
          if (decryptedImage) {
            decryptedMedia.push(decryptedImage);
          }
          break;
        case 'SHOP_PRODUCT':
          const decryptedShopProduct = this.decryptShopProduct(medium, password);
          decryptedMedia.push(decryptedShopProduct);
          break;
        case 'E_REZEPT':
          const decryptedERezept = this.decryptERezept(medium, password);
          if (decryptedERezept) {
            decryptedMedia.push({ ...medium, decryptedERezept });
          }
          break;
        case 'IMAGE_BASE64':
          const decryptedImageData = this.decryptImageBase64(medium, password);
          if (decryptedImageData.decryptedImage || decryptedImageData.decryptedText) {
            decryptedMedia.push({ ...medium, ...decryptedImageData });
          }
          break;
        case 'APPOINTMENT':
          const decryptedAppointmentData = this.decryptAppointment(medium, password);
          if (decryptedAppointmentData.decryptedCalendarEvent || decryptedAppointmentData.decryptedText) {
            decryptedMedia.push({ ...medium, ...decryptedAppointmentData });
          }
          break;
        default:
          decryptedMedia.push(medium);
      }
    }
    return decryptedMedia;
  }

  private attachmentHasBeenDeletedInS3(error: any) {
    return error?.$metadata?.httpStatusCode === 403;
  }

  private async getBase64Content(path: string, conversationPassword: string): Promise<string> {
    const file = await this.s3Service.getFile(path);
    return this.encryption.decryptUsingPassword(file.body, conversationPassword);
  }

  private async validateAndDecryptImageMedia(
    medium: DecryptedImageMedia,
    password: string | undefined
  ): Promise<DecryptedImageMedia | null> {
    if (medium.encryptedPreviewLink) {
      const decryptedPreview = await this.downloadAndDecryptPreviewImage(medium, password);
      if (decryptedPreview.error) {
        const decryptedFullSizeImage = await this.downloadAndDecryptFullSizeImage(medium, password);
        return Object.assign({}, medium, decryptedFullSizeImage);
      } else {
        return Object.assign({}, medium, decryptedPreview);
      }
    } else if (medium.encryptedLink) {
      logger.error('No encrypted preview link for image found');
      const decryptedFullSizeImage = await this.downloadAndDecryptFullSizeImage(medium, password);
      return Object.assign({}, medium, decryptedFullSizeImage);
    } else if (medium.externalPreviewLink) {
      return this.addErrorIfFileDoesNotExist(medium, medium.externalPreviewLink);
    } else if (medium.externalLink) {
      return this.addErrorIfFileDoesNotExist(medium, medium.externalLink);
    } else {
      logger.error('Invalid IMAGE medium', medium);
      return null;
    }
  }

  private async downloadAndDecryptPreviewImage(
    medium: DecryptedImageMedia,
    password: string | undefined
  ): Promise<Partial<DecryptedImageMedia>> {
    return this.downloadAndDecryptImage(medium.encryptedPreviewLink, password, 'decryptedPreview');
  }

  private async downloadAndDecryptFullSizeImage(
    medium: DecryptedMessageMedia,
    password: string | undefined
  ): Promise<Partial<DecryptedMessageMedia>> {
    return this.downloadAndDecryptImage(medium.encryptedLink, password, 'decryptedContent');
  }

  private async addErrorIfFileDoesNotExist<T = DecryptedImageMedia | DecryptedFileMedia>(medium: T, link: string) {
    return (await this.checkLink(link)) ? medium : Object.assign({}, medium, { error: 'FAILED_TO_DOWNLOAD' });
  }

  private async checkLink(link: string): Promise<boolean> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onerror = () => {
        resolve(false);
      };
      img.onload = () => {
        resolve(true);
      };
      img.src = link;
    });
  }

  private async getDecryptedFileNameAndLink(
    medium: FileMedia,
    password: string | undefined
  ): Promise<Partial<DecryptedFileMedia>> {
    const decryptedFilename = medium.encryptedFilename
      ? this.encryption.decryptUsingPassword(medium.encryptedFilename, password)
      : undefined;
    const decryptedContent =
      medium.encryptedLink && this.encryption.decryptUsingPassword(medium.encryptedLink, password);
    const fileExists = decryptedContent && (await this.s3Service.doesFileExist(decryptedContent));
    if (fileExists) {
      const { decryptedPreview } = await this.downloadAndDecryptImage(
        medium.encryptedPreviewLink,
        password,
        'decryptedPreview'
      );
      return { decryptedContent, decryptedFilename, decryptedPreview, error: undefined };
    }
    return { decryptedContent, decryptedFilename, error: 'FAILED_TO_DOWNLOAD' };
  }

  private decryptShopProduct(medium: ShopProductMedia, password: string | undefined): DecryptedShopProductMedia {
    if (!medium.encryptedShopProduct) {
      return { ...medium, error: 'PRODUCT_CARD_REMOVED' };
    }
    try {
      const decryptedProductString = this.encryption.decryptUsingPassword(medium.encryptedShopProduct, password);
      const decryptedProduct: ShopProduct | Product = JSON.parse(decryptedProductString);
      if (ShopProductUtil.isShopProduct(decryptedProduct)) {
        const mappedDecryptedProduct = ShopProductUtil.mapShopProductToProduct(decryptedProduct);
        return { ...medium, decryptedShopProduct: mappedDecryptedProduct };
      }
      return { ...medium, decryptedShopProduct: decryptedProduct };
    } catch (e) {
      logger.error('Error decrypting ShopProduct', e);
      return { ...medium, error: 'PRODUCT_CARD_REMOVED' };
    }
  }

  private decryptImageBase64(
    medium: ImageBase64Media,
    password: string | undefined
  ): { decryptedImage?: string; decryptedText?: string } {
    let decryptedImage: string | undefined;
    let decryptedText: string | undefined;
    if (medium.encryptedImage) {
      try {
        decryptedImage = this.encryption.decryptUsingPassword(medium.encryptedImage, password);
      } catch (e) {
        logger.error('Error decrypting ImageBase64', e);
      }
    }
    if (medium.encryptedText) {
      try {
        decryptedText = this.encryption.decryptUsingPassword(medium.encryptedText, password);
      } catch (e) {
        logger.error('Error decrypting ImageBase64', e);
      }
    }
    return { decryptedImage, decryptedText };
  }

  private decryptAppointment(
    medium: AppointmentMedia,
    password: string | undefined
  ): { decryptedCalendarEvent?: DecryptedCalendarEvent; decryptedText?: string } {
    let decryptedCalendarEvent: DecryptedCalendarEvent | undefined;
    let decryptedText: string | undefined;
    if (medium.encryptedCalendarEvent) {
      try {
        const decryptedCalendarEventString = this.encryption.decryptUsingPassword(
          medium.encryptedCalendarEvent,
          password
        );
        decryptedCalendarEvent = JSON.parse(decryptedCalendarEventString);
      } catch (e) {
        logger.error('Error decrypting Appointment', e);
      }
    }
    if (medium.encryptedText) {
      try {
        decryptedText = this.encryption.decryptUsingPassword(medium.encryptedText, password);
      } catch (e) {
        logger.error('Error decrypting Appointment', e);
      }
    }
    return { decryptedCalendarEvent, decryptedText };
  }

  private decryptERezept(medium: ERezeptMedia, password: string | undefined): string[] | undefined {
    if (!medium.encryptedERezept) {
      return undefined;
    }
    try {
      const decryptedERezeptString = this.encryption.decryptUsingPassword(medium.encryptedERezept, password);
      return JSON.parse(decryptedERezeptString);
    } catch (e) {
      logger.error('Error decrypting ERezept', e);
      return undefined;
    }
  }
}
