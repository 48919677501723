import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatusDescriptionConfig } from '../config/order-status-description.config';

/**
 * This pipe returns the description of a delivery status by its id
 */
@Pipe({
    name: 'getOrderStatusDescription'
})
export class GetOrderStatusDescriptionPipe implements PipeTransform {

    transform(id: string): string {
        const temp =  OrderStatusDescriptionConfig.filter(deliveryStatus => deliveryStatus.id === id);

        if(temp && temp[0] && temp[0].description) {
            return temp[0].description;
        }
        return '';
    }

}
