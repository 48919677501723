import gql from 'graphql-tag';
import { messageAttributes, pharmacyAttributes } from '../attributes';

export const backendUserConversationQueryAttributes = `
        id
        conversationId
        ownerId
        participantsIds
        encryptedConversationPassword
        participants {
          __typename
          cognitoId
          chatname
          isGuest
          isAppUser
          userType
          userStatus
          publicKey
          pharmacy {
            ${pharmacyAttributes}
            pharmacyChatUser {
              __typename
              pharmacyId
              cognitoId
              cognitoUsername
              publicKey
              userType
              userStatus
              favoritedByAppUserIds
              favoritedByAppUserCountAtStartOfMonth
              addons {
                __typename
                type
                status
                subscriptionTimestamp
                expirationTimestamp
              }
              initialMessages {
                askQuestion
                askQuestionClosed
                bookAppointment
                bookAppointmentClosed
                preorder
                preorderClosed
                productQuery
                productQueryClosed
              }
              holidays {
                state
                customHolidays
              }
              vacation {
                from
                until
              }
              emergencyOpeningStatus
            }
          }
        }
        conversation {
          id
          firstSegmentId
          createdAt
          type
          intent
          appContext
          archivedByEnduser
          earliestExpirationTimestamp
          externalUsername
          deletionRecord {
            cognitoId
            deletedAt
          }
          ticketHistory {
            timestamp
            updatedStatus
            updatedBy
            accepted
          }
          encryptedShoppingCart
          encryptedAppointment
          lastMessage {
            ${messageAttributes}
          }
        }
        conversationLink
        encryptedChatPartnerNickname
        externalChatPartnerNickname
        reminderNotification
        showReminder`;

export default gql`
  query getUserConversations($nextToken: String) {
    getUserConversations(nextToken: $nextToken) {
      __typename
      nextToken
      userConversations {
        ${backendUserConversationQueryAttributes}
      }
    }
  }
`;
