import { inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import { InfoModalsQueries } from '../store/graphql/queries/info-modals.graphql';
import { InfoModalInterface } from '../interfaces/info-modal.interface';

@Injectable({
    providedIn: 'root'
})
export class InfoModalsService {

    private infoModalsQueries = inject(InfoModalsQueries);

    // InfoModals Signal
    private _infoModals = signal<InfoModalInterface[]>([]);
    infoModals = this._infoModals.asReadonly();


    private infoModals$ = this.infoModalsQueries.getInfoModals().pipe(
        tap(data => {
            this._infoModals.set(data);
        })
    );

    updatesReadOnly = toSignal(this.infoModals$, {
        initialValue: [] as InfoModalInterface[]
    });

    constructor() {
    }
}
