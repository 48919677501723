import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { DataChangedStateInterface } from '../../interfaces/pharmacy-state.interface';
import { ConnectionStatusService } from '../../services/connectionStatus.service';
import { DataChangedKeys } from '../../enums/data-changed-keys.enum';

export const GetDataChangedForceState = gql`
    query getDataChangedForceState {
        dataChangedForceState @client {
            ${Object.values(DataChangedKeys).join( )}
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DataChangedForceStateVar {
    /**
     * produces an Object like notificationChanged: null, xxx: null ....
     * @private
     */
    private defaultValue = Object.values(DataChangedKeys)
        .reduce((a, v) => ({ ...a, [v]: null}), {});

    private dataChangedForceStateObservable: Observable<DataChangedStateInterface>;
    private apollo: Apollo;
    private cache;

    constructor(
        private connectionStatusService: ConnectionStatusService,
        apollo: Apollo,
    ) {
        this.apollo = apollo;
    }

    init() {
        if(this.apollo.client) {
            this.cache = this.apollo.client.cache;
            this.dataChangedForceStateObservable = new Observable<DataChangedStateInterface>(subscriber => {
                this.cache.watch({
                    query: GetDataChangedForceState,
                    callback: res => {
                        subscriber.next(res.result && res.result.dataChangedForceState || this.defaultValue);
                    },
                    immediate: true,
                    optimistic: true,
                });
            });
        } else {
            this.dataChangedForceStateObservable = new Observable();
        }
    }

    get(): Observable<DataChangedStateInterface> {
        this.init();
        return this.dataChangedForceStateObservable;
    }

    getViaPromise() {
        return new Promise<DataChangedStateInterface>((resolve) => {
            this.init();
            if (this.cache) {
                this.cache.watch({
                    query: GetDataChangedForceState,
                    callback: res => {
                        resolve(res.result && res.result.dataChangedForceState || this.defaultValue);
                    },
                    immediate: true,
                    optimistic: true,
                });
            } else {
                resolve(null);
            }
        });
    }

    set(dataChangedForceState: DataChangedStateInterface) {
        this.init();
        if (this.cache) {
            this.cache.writeQuery({
                query: GetDataChangedForceState,
                data: {
                    dataChangedForceState
                }
            });
        }
    }
    async setForceState(dataChangedForceState: DataChangedStateInterface) {
        this.init();
        let dataChangedForceStateFormatted = {};
        Object.keys(dataChangedForceState).forEach(key => {
            dataChangedForceStateFormatted = {...dataChangedForceStateFormatted, [key]: Math.random().toString()};
        });
        const oldToolbarData = await this.getViaPromise();
        if (this.cache && oldToolbarData) {
            this.cache.writeQuery({
                query: GetDataChangedForceState,
                data: {
                    dataChangedForceState: {
                        ...oldToolbarData,
                        ...dataChangedForceStateFormatted
                    }
                }
            });
        }
    }
}
