import { Inject, Pipe, PipeTransform } from '@angular/core';
import { MeaConfig } from '../../../types/src/mea-config';

@Pipe({
  name: 'iconName',
  standalone: true,
})
export class IconNamePipe implements PipeTransform {
  baseUrl: string;

  constructor(@Inject('config') private config: MeaConfig) {
    this.baseUrl = config.baseUrl || '';
  }

  transform(value: string): any {
    return `${this.baseUrl}mea-cd-icons/icon/${value}.svg`;
  }
}
