export enum SortDirectionEnum {
    asc = 'asc',
    ascNullsFirst = 'asc_nulls_first',
    ascNullsLast = 'asc_nulls_last',
    desc = 'desc',
    descNullsFirst = 'desc_nulls_first',
    descNullsLast = 'desc_nulls_last'
}
export enum SortSimpleEnum {
    asc = 'asc',
    desc = 'desc'
}
