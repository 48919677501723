import gql from 'graphql-tag';
import { messageAttributes } from '../attributes';

export default gql`
  subscription createdOwnMessage($senderId: ID!) {
    createdOwnMessage(senderId: $senderId) {
      ${messageAttributes}
    }
  }
`;
