import gql from 'graphql-tag';

export default gql`
  query getExpirationTimestamp($frontendConversationId: ID!) {
    getExpirationTimestamp(frontendConversationId: $frontendConversationId) {
      frontendConversationId
      expirationTimestamp
    }
  }
`;
