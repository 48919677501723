import gql from 'graphql-tag';

export default gql`
  mutation updateOpenTicketReminderNotification($id: ID!, $reminderNotification: Boolean!) {
    updateUserConversation(id: $id, reminderNotification: $reminderNotification) {
      __typename
      id
      ownerId
      conversationId
      reminderNotification
      showReminder
    }
  }
`;
