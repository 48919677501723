import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UserAdministrationService {
    constructor() {
    }

    public userDataChanged: EventEmitter<boolean> = new EventEmitter();
    public userDataSetLoading: EventEmitter<boolean> = new EventEmitter();
}
