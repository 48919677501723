import { UserAccessModulesEnum, UserAccessRightsEnum } from '../enums/user-administration.enum';
import { UserAdministrationFilterInterface } from '../interfaces/user-administration.interface';

export const UserAccessRightsConfig = {
    [UserAccessModulesEnum.SCONNECT]: {
        sortOrder: 0,
        label: 'Sanacorp Connect',
        color: 'user-administration-sconnect',
        accessRights: {
            [UserAccessRightsEnum.INVOICES]: {
                label: 'e-Rechnung'
            },
            [UserAccessRightsEnum.STATISTICS]: {
                label: 'Statistik'
            },
            [UserAccessRightsEnum.USER_ADMINISTRATION]: {
                label: 'Nutzerverwaltung'
            }
        },
        ownerRights: [
            UserAccessRightsEnum.INVOICES,
            UserAccessRightsEnum.STATISTICS,
            UserAccessRightsEnum.USER_ADMINISTRATION
        ]
    },
    [UserAccessModulesEnum.CHAT]: {
        sortOrder: 2,
        label: 'mea Chat',
        color: 'user-administration-chat',
        accessRights: {},
        ownerRights: []
    },
    [UserAccessModulesEnum.SHOP]: {
        sortOrder: 3,
        label: 'mea Shop',
        color: 'user-administration-shop',
        accessRights: {},
        ownerRights: []
    },
    [UserAccessModulesEnum.SACADEMY]: {
        sortOrder: 4,
        label: 'Sanacorp Akademie',
        color: 'user-administration-sacademy',
        accessRights: {},
        ownerRights: []
    },
    [UserAccessModulesEnum.SANAVENDI]: {
        sortOrder: 5,
        label: 'SanaVendi',
        color: 'user-administration-sanavendi',
        accessRights: {},
        ownerRights: []
    }
};

export const UserAccessModulesConfig = [
    UserAccessModulesEnum.SCONNECT,
    UserAccessModulesEnum.CHAT,
    UserAccessModulesEnum.SHOP,
    UserAccessModulesEnum.SACADEMY,
    UserAccessModulesEnum.SANAVENDI
];

export const DefaultUserAdministrationFilters: UserAdministrationFilterInterface = {
    pharmacyFilter: {
        id: 'all',
        title: 'Alle'
    },
    searchText: null
};
