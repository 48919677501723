import { Injectable } from '@angular/core';
import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { PubSub } from '@aws-amplify/pubsub';
import { DocumentNode } from 'graphql/language';
import { from, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppsyncErrorUtil } from '../../../../../essentials/util/src/appsync-error.util';
import { CustomToastController } from '../../../../ui-components/src/ionic/controllers/custom-toast.controller';

const NUMBER_OF_RETRIES = 7;

@Injectable({
  providedIn: 'root',
})
export class AppsyncService {
  private readonly client: AppsyncServiceClient;

  constructor(toastController: CustomToastController) {
    PubSub.configure({});
    this.client = new AppsyncServiceClient(toastController);
  }

  getClient(): Promise<AppsyncServiceClient> {
    return Promise.resolve(this.client);
  }
}

export class AppsyncServiceClient {
  private unsubscribe$ = new Subject<void>();

  constructor(private toastController: CustomToastController) {
    window.addEventListener('beforeunload', (event) => {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      delete event['returnValue'];
    });
  }

  async query<T = any>(operation: {
    query: DocumentNode;
    variables?: { [key: string]: any };
    fetchPolicy?: string;
  }): Promise<GraphQLResult<T>> {
    return this.retryWhenElasticsearchRuntimeErrorOccurred<GraphQLResult<T>>(
      async () => (await API.graphql(operation)) as unknown as Promise<GraphQLResult<T>>
    );
  }

  async mutate({
    mutation,
    variables,
  }: {
    mutation: DocumentNode;
    variables?: { [key: string]: any };
    fetchPolicy?: string;
  }): Promise<GraphQLResult<any>> {
    return this.retryWhenElasticsearchRuntimeErrorOccurred<GraphQLResult<any>>(
      async () => (await API.graphql({ query: mutation, variables })) as Promise<GraphQLResult<any>>
    );
  }

  subscribe({ query, variables }: { query: DocumentNode; variables?: { [key: string]: any } }): Observable<any> {
    return from((API.graphql(graphqlOperation(query, variables)) as any).map((data: any) => data && data.value)).pipe(
      takeUntil(this.unsubscribe$)
    );
  }

  async retryWhenElasticsearchRuntimeErrorOccurred<T = any>(apiCall: () => Promise<T>): Promise<T> {
    for (let i = 0; i <= NUMBER_OF_RETRIES; i++) {
      try {
        return await apiCall();
      } catch (e) {
        const elasticsearchRuntimeErrorOccurred = this.includesElasticsearchRuntimeError(e);
        if (!elasticsearchRuntimeErrorOccurred) {
          throw e;
        } else if (i === NUMBER_OF_RETRIES) {
          await this.toastController.createAndPresentToast({
            message: 'COMMON_ERROR.ELASTICSEARCH_RUNTIME_EXCEPTION',
          });
          throw e;
        } else {
          await this.timeout(200);
        }
      }
    }
    throw Error('Error during retryWhenElasticsearchRuntimeErrorOccurred, this should not be reachable');
  }

  private async timeout(delayMs: number) {
    return new Promise<void>((resolve) => setTimeout(() => resolve(), delayMs));
  }

  private includesElasticsearchRuntimeError(caughtError: unknown): boolean {
    return (
      AppsyncErrorUtil.isAppsyncError(caughtError) &&
      caughtError.errors.map((error) => error['errorType']).includes('Elasticsearch:RuntimeException')
    );
  }
}
