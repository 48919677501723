import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { from, map } from 'rxjs';

import { DataChangedKeys as DCK } from '../../../core.enums';
import { ApiService } from '../../../services/api.service';
import { startOfWithUTC } from '../../../formatting/date.formatting';
import { DataChangedForceStateVar } from '../../locals/dataChangeForceState.var';
import { OfferQuoteItemInterface } from '../../../interfaces/offers.interface';

export const UpsertOfferQuoteOne = gql`
    mutation InsertOfferQuoteOne($offerQuote: offerQuote_insert_input!) {
        insert_offerQuote_one(
            object: $offerQuote,
            on_conflict: {
                constraint: offerQuote_pharmacyStoreId_userId_offerId_key,
                update_columns: [offerId]
            }
        ) {
            offerId
        }
    }
`;

export const UpsertOfferQuoteOneAndDeleteOthers = gql`
    mutation UpsertOfferQuoteOneAndDeleteOthers($offerId: String, $offerQuote: offerQuote_insert_input!) {
        delete_offerQuote(where: {offerId: {_neq: $offerId}}) {
            affected_rows
        }
        insert_offerQuote_one(
            object: $offerQuote,
            on_conflict: {
                constraint: offerQuote_pharmacyStoreId_userId_offerId_key,
                update_columns: [offerId]
            }
        ) {
            offerId
        }
    }
`;

export const UpsertOfferQuoteItemOne = gql`
    mutation InsertOfferQuoteItemOne($offerQuoteItem: offerQuoteItem_insert_input!) {
        insert_offerQuoteItem_one(
            object: $offerQuoteItem,
            on_conflict: {
                constraint: offerQuoteItem_productId_offerQuoteId_key,
                update_columns: [
                    quantity
                ]
            }
        ) {
            offerQuoteId
        }
    }
`;

export const DeleteOfferQuote = gql`
    mutation DeleteOfferQuote($id: Int!) {
        delete_offerQuote_by_pk(id: $id) {
            id
        }
    }
`;

export const DeleteAllOfferQuote = gql`
    mutation DeleteAllOfferQuote($yesterday: timestamptz) {
        delete_offerQuote(where: {created_at: {_lte: $yesterday}}) {
            affected_rows
        }
    }
`;

export const DeleteOfferQuoteItem = gql`
    mutation DeleteOfferQuoteItem($offerProductId: String) {
        delete_offerQuoteItem(where: {productId: {_eq: $offerProductId}}) {
            affected_rows
        }
    }
`;

export const OrderOfferMutation = gql`
    mutation OrderOffer($offerId: ID!, $items: [OfferItem!]!) {
        OrderOffer(offerId: $offerId, items: $items) {
            status
            code
            message
            failedItemIds
        }
    }
`;

export const AllOffersMutations = [
    UpsertOfferQuoteOne,
    UpsertOfferQuoteOneAndDeleteOthers,
    UpsertOfferQuoteItemOne,
    DeleteOfferQuote,
    DeleteAllOfferQuote,
    DeleteOfferQuoteItem,
    OrderOfferMutation
];

@Injectable()
export class OffersMutations {
    constructor(
        private apollo: Apollo,
        private apiService: ApiService,
        private dataChangedForceState: DataChangedForceStateVar
    ) {}

    insertOfferQuote(offerId: number, offerItems: Array<OfferQuoteItemInterface> = [], deleteOtherOffers = false): Promise<void>  {
        void this.dataChangedForceState.setForceState({[DCK.offerQuoteChanged]: null});
        const offerQuote: {offerId: number, offerQuoteItems?: object} = { offerId };
        if (offerItems?.length > 0) {
            offerQuote.offerQuoteItems = {
                data: offerItems,
                on_conflict: {
                    constraint: 'offerQuoteItem_productId_offerQuoteId_key',
                    update_columns: ['quantity']
                }
            };
        }
        return new Promise(resolve => {
            this.apollo.mutate({
                mutation: deleteOtherOffers ? UpsertOfferQuoteOneAndDeleteOthers : UpsertOfferQuoteOne,
                variables: deleteOtherOffers ? {offerQuote, offerId} : {offerQuote}
            }).subscribe({
                next: result => {
                    from([result])
                      .pipe(map(d => d?.data && d?.data['insert_offerQuote_one'] && d?.data['insert_offerQuote_one']['offerId']))
                      .subscribe((status: string) => {
                          if (!status || status === 'ERROR' || !!result['errors']) {
                              void this.apiService.presentErrorToast(result['errors'], 'Der Artikel konnte nicht in Ihren Warenkorb gelegt werden.');
                          }
                          resolve();
                      });
                },
                error: error => {
                    void this.apiService.presentErrorToast(error, 'Der Artikel konnte nicht in Ihren Warenkorb gelegt werden.');
                    resolve();
                }
            });
        });
    }

    insertOfferQuoteItem(offerProduct) {
        void this.dataChangedForceState.setForceState({[DCK.offerQuoteChanged]: null});
        const offerQuoteItem = offerProduct;
        offerQuoteItem.offerQuoteId = +offerQuoteItem.offerQuoteId;
        this.apollo.mutate({
            mutation: UpsertOfferQuoteItemOne,
            variables: {offerQuoteItem}
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['insert_offerQuoteItem_one']
                    && d?.data['insert_offerQuoteItem_one']['offerQuoteId']))
                  .subscribe((status: string) => {
                      if (!status || status === 'ERROR' || !!result['errors']) {
                          void this.apiService.presentErrorToast(result['errors'], 'Der Artikel konnte nicht in Ihren Warenkorb gelegt werden.');
                      }
                  });
            },
            error: error => this.apiService.presentErrorToast(error, 'Der Artikel konnte nicht in Ihren Warenkorb gelegt werden.')
        });
    }

    deleteOfferQuote(id: number) {
        void this.dataChangedForceState.setForceState({[DCK.offerQuoteChanged]: null});
        this.apollo.mutate({
            mutation: DeleteOfferQuote,
            variables: {id}
        }).subscribe(
            result => {
                from([result])
                    .pipe(map(d => d?.data && d?.data['delete_offerQuote_by_pk']))
                    .subscribe(() => {
                        if(!!result['errors']) {
                            // deleteAllOfferQuote is not triggered by user but by the system, hence do not show an error to the user
                        }
                    });
            }
        );
    }

    deleteAllOfferQuotes() {
        void this.dataChangedForceState.setForceState({[DCK.offerQuoteChanged]: null});
        const yesterday = startOfWithUTC('hours', false, null, 24, true);

        this.apollo.mutate({
            mutation: DeleteAllOfferQuote,
            variables: {yesterday}
        }).subscribe(
            result => {
                from([result])
                    .pipe(map(d => d?.data && d?.data['delete_offerQuote'] && d?.data['delete_offerQuote']['affected_rows']))
                    .subscribe(() => {
                        if(!!result['errors']) {
                            // deleteAllOfferQuote is not triggered by user but by the system, hence do not show an error to the user
                        }
                    });
            }
        );
    }

    deleteOfferQuoteItem(offerOfferQuoteItemId: number): Promise<void> {
        void this.dataChangedForceState.setForceState({[DCK.offerQuoteChanged]: null});
        return new Promise(resolve => {
            const offerProductId = offerOfferQuoteItemId;
            this.apollo.mutate({
                mutation: DeleteOfferQuoteItem,
                variables: {offerProductId}
            }).subscribe({
                next: result => {
                    from([result])
                      .pipe(map(d => d?.data && d?.data['delete_offerQuoteItem'] && d?.data['delete_offerQuoteItem']['affected_rows']))
                      .subscribe(() => {
                          if (!!result['errors']) {
                              void this.apiService.presentErrorToast(result['errors'], 'Der Artikel konnte nicht gelöscht werden.');
                          }
                          resolve();
                      });
                },
                error: error => {
                    void this.apiService.presentErrorToast(error, 'Der Artikel konnte nicht gelöscht werden.');
                    resolve();
                }
            });
        });

    }

    orderOffer(offerId: number, items: [{itemId: number, amount: number}]): Promise<Array<string>> {
        void this.dataChangedForceState.setForceState({[DCK.offerQuoteChanged]: null});
        return new Promise((resolve, reject) => {
            this.apollo.mutate({
                mutation: OrderOfferMutation,
                variables: {offerId, items}
            }).subscribe({
                next: result => {
                    from([result])
                      .pipe(map(d => d?.data && d?.data['OrderOffer']))
                      .subscribe((error: { status: string, code: number, message: string, failedItemIds: [string] }) => {
                          if (!!result['errors'] || (error && error.status === 'ERROR')) {
                              const errorText = error.code === 403 ? 'Sie sind nicht berechtigt, diese Bestellung abzuschicken.' :
                                  'Die Bestellung konnte nicht abgeschickt werden.';
                              void this.apiService.presentErrorToast(result['errors'], errorText);
                              error.code === 403 ? reject() : resolve(error.failedItemIds);
                          } else {
                              resolve(null);
                          }
                      });
                },
                error: error => {
                    void this.apiService.presentErrorToast(error, 'Die Bestellung konnte nicht abgeschickt werden.');
                    resolve([]);
                }
            });
        });
    }
}
