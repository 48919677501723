import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { pharmacyChatPartnerReducer } from '../../../../store/src/pharmacy/pharmacy-chat-partner-store/pharmacy-chat-partner.reducer';
import { PharmacyChatPartnerEffects } from './pharmacy-chat-partner.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('pharmacyChatPartnerMetadata', pharmacyChatPartnerReducer),
    EffectsModule.forFeature([PharmacyChatPartnerEffects]),
  ],
})
export class PharmacyChatPartnerStoreModule {}
