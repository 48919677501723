import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { CommonState } from '../../../../store/src/common-store/common.state';
import { BasicChatEvent } from '../../../../essentials/types/src/duett-schema/de.meineapotheke.chat/chat_event/jsonschema/1-0-0';
import { MeaConfig } from '../../../../essentials/types/src/mea-config';
import { RemoveIndex } from '../../../../essentials/types/src/types.util';
import { Logger } from '../../../../essentials/util/src/logger';
import { assertUnreachable } from '../../../../essentials/util/src/typescript.util';
import trackEvent from '../graphql/mutations/trackEvent';
import { AppsyncService } from './appsync/appsync.service';

export const logger = new Logger('DuettAnalyticsService');

type FrontendType = 'app' | 'pharmacy' | 'chat-widget' | 'enduser' | 'pharmacy-app';

@Injectable({
  providedIn: 'root',
})
export class DuettAnalyticsService {
  application: BasicChatEvent['application'];
  environment: string;

  constructor(
    private appSync: AppsyncService,
    protected store: Store<CommonState>,
    @Inject('config') private config: MeaConfig
  ) {
    this.application = this.getApplication(config.frontendType);

    this.environment = config.name;
  }

  getBaseEventData() {
    return {
      application: this.application,
      env: this.environment,
      client_timestamp: dayjs().unix(),
    };
  }

  async trackEvent(eventData: RemoveIndex<BasicChatEvent>) {
    if (this.environment === 'e2e') {
      return;
    }
    try {
      const client = await this.appSync.getClient();
      const result = await client.mutate({
        mutation: trackEvent,
        variables: {
          data: JSON.stringify(eventData),
        },
      });
      if (!result.data.trackEvent) {
        logger.error('Failed to track event of type', eventData.event_type);
      }
    } catch (e) {
      logger.error(`Failed to track event of type ${eventData.event_type}`, e);
    }
  }

  private getApplication(frontendType: FrontendType): BasicChatEvent['application'] {
    if (frontendType === 'app') {
      return 'de.meineapotheke.app';
    } else if (frontendType === 'pharmacy') {
      return 'de.meineapotheke.apotheke';
    } else if (frontendType === 'pharmacy-app') {
      return 'de.meineapotheke.companion-app';
    } else if (frontendType === 'chat-widget') {
      return 'de.meineapotheke.widget';
    } else if (frontendType === 'enduser') {
      return 'de.meineapotheke.meadirekt-chat';
    } else {
      assertUnreachable(frontendType);
    }
  }
}
