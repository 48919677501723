export enum HintPositionEnum {
    TOP='top',
    BOTTOM='bottom',
    LEFT='left',
    RIGHT='right',
    CENTER='center',
    TOP_LEFT='top-left',
    TOP_RIGHT='top-right',
    BOTTOM_LEFT='bottom-left',
    BOTTOM_RIGHT='bottom-right'
}
