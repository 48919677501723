import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import { environment } from '../environments/environment';

Sentry.init({
    dsn: environment.sentryUri, // 'https://<key>@sentry.io/<project>'
    environment: environment.name,
    release: environment.sentry.release,
    beforeSend(event, hint) {

        // Prevent error logging in sentry on login
        if(hint.originalException) {
            const message = hint.originalException['message'];
            if (message
                && message.indexOf('path: ["login"]') > -1
                && message.indexOf('path: ["isLogin"]') === -1
                && message.indexOf('code: "INTERNAL_SERVER_ERROR"') > -1 ) {
                return null;
            }
            if (message === 'Aborted') {
                return null;
            }
        }

        // Prevent error logging in sentry in local development
        if(environment.name === 'local') {
            return null;
        }

        return event;
    },
    integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new BrowserTracing({
            tracingOrigins: environment.sentry.tracingOrigins,
            routingInstrumentation: Sentry.routingInstrumentation,
            traceFetch: false
        }),
    ],
    beforeBreadcrumb: excludeGraphQLFetch,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment.sentry.tracesSampleRate,

    ignoreErrors: [
        // No internet connection
        'Cannot read property \'isProxied\'',
        'undefined is not an object (evaluating \'kt.isProxied\')',
        'Failed to fetch',
        'Http failure response',

        // from 'optimism' package. Cache functionality already running
        'already recomputing',

        // missing token and logout are handled, but the last request can't be canceled
        'Missing "mea-auth-token" in request header',
        'Cannot return null for non-nullable field',

        // Ignore error which are not a valid error object (results in message 'Non-Error exception captured with keys: error, headers, message, name, ok')
        // These errors can't be resolved via sentry investigation
        'Non-Error exception captured',
        'Object captured as exception with keys:',

        // https://github.com/getsentry/sentry-javascript/discussions/5875
        '@webkit-masked-url(://hidden/)',

        // Abort errors
        'The operation was aborted.',

        // error from some browser extensions
        /change_ua/
    ]
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() { }
    handleError(error) {
       /* const eventId = Sentry.captureException(error.originalError || error);
        this.sentryStateVar.set(eventId);
        if(environment.showReportDialog) {
            Sentry.showReportDialog({eventId});
        }*/
    }
}
