<ion-card class="ion-no-margin ion-no-padding">
    <ion-card-content class="ion-no-padding">
        <!-- tabs row -->
        <div class="ion-no-padding tabs-wrapper">
            <div
                    *ngFor="let tab of tabs; index as i"
                    class="tab clickable ion-padding-horizontal ion-padding-bottom-half ion-padding-top-half display-flex-column"
                    [class.active]="activeTabIndex === i"
                    (click)="onTabClick(i)">
                <ion-label>{{tab.title}}</ion-label>
            </div>
        </div>
        <!-- content row -->
        <div class="content-row">
            <ng-content select="[content]"></ng-content>
        </div>
    </ion-card-content>
</ion-card>
