import update, { Spec } from 'immutability-helper';
import { Dictionary } from 'ts-essentials';
import { Conversation } from '../../../../../../essentials/types/src/conversation';
import Message from '../../../../../../essentials/types/src/message';
import { ChatState } from '../../state/chat.state';

export function findConversationForSegment(
  conversations: Dictionary<Conversation>,
  segmentId: string
): Conversation | undefined {
  let conversationToUpdate: Conversation | undefined = conversations[segmentId];
  if (!conversationToUpdate) {
    conversationToUpdate = Object.values(conversations).find(
      (conversation) =>
        conversation.segments.length > 1 && conversation.segments.find((segment) => segment.id === segmentId)
    );
  }
  return conversationToUpdate;
}

function updateMessages<T extends Message>(
  updates: { messageId: string; messageUpdate: Spec<T> }[],
  state: ChatState,
  segmentId: string
): ChatState {
  const messagesSpec: Spec<Message[]> = updates.reduce((acc, { messageId, messageUpdate }) => {
    const messageIndex = state.messages?.[segmentId]?.messages.findIndex((msg) => msg.id === messageId);
    if (messageIndex !== undefined && messageIndex >= 0) {
      return { ...acc, [messageIndex]: messageUpdate };
    }
    return acc;
  }, {});
  return update(state, {
    messages: {
      [segmentId]: {
        messages: messagesSpec,
      },
    },
  });
}

export interface MessageUpdate<T extends Message = Message> {
  messageId: string;
  messageUpdate: Spec<T>;
}

export function performMessageUpdate<T extends Message>(
  state: ChatState,
  segmentId: string,
  updates: MessageUpdate<T>[]
) {
  if (state.conversations && state.messages && state.messages[segmentId]) {
    return updateMessages(updates, state, segmentId);
  }
  return state;
}
