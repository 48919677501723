import { Injectable } from '@angular/core';
import { ActionButtonsEnum, ActionButtonTypesEnum } from '../enums/action-button-types.enum';
import { OrderHeaderActionButtons, ToolbarActionButtons } from '../config/toolbar-actions.config';
import { PopoverService } from './popover.service';
import { ModalService } from './modal.service';
import { AddActionsPopoverComponent } from '../../navigation/components/add-actions-popover/add-actions-popover.component';
import { AppointmentFormComponent } from '../../pages/calendar/widgets/appointment-form/appointment-form.component';
import { CommunicationZoneFormComponent } from '../../pages/communications/pages/communication-zone/widgets/communication-zone-form/communication-zone-form.component';
import { DispositionRequestComponent } from '../../pages/logistics/pages/orders/widgets/disposition-request/disposition-request.component';
import { MeamindQuestionModalComponent } from '../../pages/knowledge/pages/mea-mind/widgets/meamind-question-modal/meamind-question-modal.component';
import { ReturnsRequestComponent } from '../../pages/logistics/pages/returns/widgets/returns-request/returns-request.component';

@Injectable({
    providedIn: 'root',
})
export class ActionButtonService {
    constructor(
        private modalService: ModalService,
        private popoverService: PopoverService,
    ) {
    }

    /**
     * User has pressed the create disposition request icon. Show modal window
     *
     * @param event - click event
     * @param actionButton - buttons that are shown in popover
     */
    async presentAddActionsPopover(event: any, actionButton: ActionButtonsEnum = ActionButtonsEnum.ALL_ACTION_BUTTONS) {
        const toolbarActionsButtons = [...ToolbarActionButtons];
        const addActionsRequestPopover = await this.popoverService.create({
            component: AddActionsPopoverComponent,
            componentProps: {
                actionButtons: actionButton === ActionButtonsEnum.ORDERS_ACTION_BUTTONS ? OrderHeaderActionButtons : toolbarActionsButtons
            },
            event,
            translucent: true
        });

        addActionsRequestPopover.onDidDismiss().then(result => {
            if(result.data && result.data.code) {
                this.openModals(null, result.data.code);
            }
        });
        return await this.popoverService.present(addActionsRequestPopover);
    }

    /**
     * Called if the action button is clicked
     *
     * @param event - click event
     * @param actionButtonType - type of action button
     * @param actionButton - buttons that are shown in popover
     */
    openModals(event, actionButtonType: ActionButtonTypesEnum = null, actionButton = ActionButtonsEnum.ALL_ACTION_BUTTONS) {
        switch(actionButtonType) {
            case ActionButtonTypesEnum.NEW_ORDER_REQUEST:
                void this.createDispositionRequest();
                break;
            case ActionButtonTypesEnum.NEW_APPOINTMENT:
                void this.createAppointmentButtonPressed();
                break;
            case ActionButtonTypesEnum.NEW_MEAMIND_QUESTION:
                void this.createMeamindQuestion();
                break;
            case ActionButtonTypesEnum.NEW_COMMUNICATION_ZONE_ITEM:
                void this.createCommunicationZoneItem();
                break;
            case ActionButtonTypesEnum.NEW_RETURN_REQUEST:
                void this.createReturnRequest();
                break;
            default:
                void this.presentAddActionsPopover(event, actionButton);
                break;
        }
    }

    /**
     * User has pressed the create disposition request icon. Show modal window
     */
    async createDispositionRequest() {
        const createDispositionRequestModal = await this.modalService.create(DispositionRequestComponent);
        return await this.modalService.present(createDispositionRequestModal);
    }

    /**
     * User has pressed the create appointment request icon. Show modal window
     */
    async createAppointmentButtonPressed() {
        const createAppointmentRequestModal = await this.modalService.create(AppointmentFormComponent);
        return await this.modalService.present(createAppointmentRequestModal);
    }

    /**
     * User has pressed the create a question on meamind button. Show modal window
     */
    async createMeamindQuestion() {
        const createMeamindQuestionModal = await this.modalService.create(MeamindQuestionModalComponent);
        return await this.modalService.present(createMeamindQuestionModal);
    }

    /**
     * User has pressed the create a new commuication zone item button. Show modal window
     */
    async createCommunicationZoneItem() {
        const createCommunicationZoneItemModal = await this.modalService.create(CommunicationZoneFormComponent);
        return await this.modalService.present(createCommunicationZoneItemModal);
    }

    /**
     * User has pressed the create a new return request button. Show modal window
     */
    async createReturnRequest() {
        const createReturnRequestModal = await this.modalService.create(ReturnsRequestComponent);
        return await this.modalService.present(createReturnRequestModal);
    }

}
