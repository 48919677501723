import { Pipe, PipeTransform } from '@angular/core';
import { NotificationsEnum, NotificationsReminderTypeEnum } from '../enums/notifications.enum';

export const NotificationReminderTypeLabels = {
    [NotificationsReminderTypeEnum.SEMINAR_REGISTRATION_REMINDER]: 'Termin',
    [NotificationsReminderTypeEnum.APPOINTMENT_REMINDER]: 'Termin',
    [NotificationsEnum.REMINDER_SEMINAR_REGISTRATION]: 'Termin',
    [NotificationsEnum.REMINDER_APPOINTMENT]: 'Termin'
};


@Pipe({
    name: 'labelNotificationReminderType'
})
export class LabelNotificationReminderTypePipe implements PipeTransform {

    transform(notificationType: NotificationsEnum | NotificationsReminderTypeEnum): string {
        return NotificationReminderTypeLabels[notificationType] || '';
    }
}
