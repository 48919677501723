import { Apollo, gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BulkChangesInterface } from '../../interfaces/bulk-changes.interface';

export const GetSelectedBulkOrders = gql`
    query getSelectedBulkOrders {
        selectedBulkOrders @client {
            id
            type
            orderId
            productName
            packageSize
            pzn
            expiryDate
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class SelectedBulkOrdersVar {
    private defaultValue = [];
    private selectedBulkItemsObservable: Observable<BulkChangesInterface[]>;
    private apollo: Apollo;
    private cache;

    constructor(
        apollo: Apollo,
    ) {
        this.apollo = apollo;
    }

    init() {
        if(this.apollo.client) {
            this.cache = this.apollo.client.cache;
            this.selectedBulkItemsObservable = new Observable<BulkChangesInterface[]>(subscriber => {
                this.cache.watch({
                    query: GetSelectedBulkOrders,
                    callback: res => {
                        subscriber.next(res.result?.selectedBulkOrders || this.defaultValue);
                    },
                    immediate: true,
                    optimistic: true
                });
            });
        }
    }

    getSelectedBulkItems() {
        this.init();
        return this.selectedBulkItemsObservable;
    }

    addAllSelectedBulkItems(items: BulkChangesInterface[]) {
        this.init();
        if (this.cache) {
            this.cache.writeQuery({
                query: GetSelectedBulkOrders,
                data: {
                    selectedBulkOrders: items,
                },
            });
        }
    }

    addSelectedBulkItem(item: BulkChangesInterface) {
        this.init();
        const currentItems = this.cache.readQuery({ query: GetSelectedBulkOrders })?.selectedBulkOrders ?? [];
        const newItems = [...currentItems, item];
        this.cache.writeQuery({
            query: GetSelectedBulkOrders,
            data: {
                selectedBulkOrders: newItems,
            },
        });
    }

    removeSelectedBulkItem(item: BulkChangesInterface) {
        this.init();
        const currentItems = this.cache.readQuery({ query: GetSelectedBulkOrders })?.selectedBulkOrders ?? [];
        const newItems = currentItems.filter((i) => i.id !== item.id);
        this.cache.writeQuery({
            query: GetSelectedBulkOrders,
            data: {
                selectedBulkOrders: newItems,
            },
        });
    }


    clearSelectedBulkItems() {
        this.addAllSelectedBulkItems(this.defaultValue);
    }
}
