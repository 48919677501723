import gql from 'graphql-tag';
import { updateMessageAttributes } from '../attributes';

export default gql`
  mutation deleteMessage($messageId: ID!) {
    deleteMessage(messageId: $messageId) {
      ${updateMessageAttributes}
    }
  }
`;
