import { Injectable } from '@angular/core';
import { PdfViewerPayloadInterface } from '../../interfaces/pdf.interface';
import { PdfViewerType } from '../../enums/pdf.enum';
import { PdfService } from '../../services/pdf.service';
import { DocumentService } from '../../services/document.service';
import { AssortmentService } from '../../services/statistics/assortment.service';
import { SafetyDatasheetsService } from '../../services/safety-datasheets.service';

@Injectable({
    providedIn: 'root',
})
export class PdfViewerService {

    constructor(
        private documentService: DocumentService,
        private pdfService: PdfService,
        private assortmentService: AssortmentService,
        private safetyDatasheetsService: SafetyDatasheetsService
    ) { }
    /**
     * Prints the pfd as integer array
     *
     * @param pdf as integer array
     * @param newtab reference to the new tab window
     */
    static print(pdf: number[], newtab) {
        return PdfService.print(pdf, newtab);
    }

    /**
     * Gets the pfd as integer array
     *
     * @param type of the pdf
     * @param payload of the pdf
     */
    async getPdfAsByteArray(type: PdfViewerType, payload: PdfViewerPayloadInterface) : Promise<{result: number[], error: string}> {
        switch (type) {
            case PdfViewerType.delivery:
            case PdfViewerType.batch:
            case PdfViewerType.invoice:
                return this.documentService.getDeliveryDocumentPdf(payload.id);
            case PdfViewerType.statistics:
                return this.assortmentService.getPdf(payload.id);
            case PdfViewerType.safetyDataSheet:
                return this.safetyDatasheetsService.getSafetyDataSheetPdf(payload.id);
            default:
                return new Promise<{result: number[], error: string}>((resolve) => {
                    resolve({result: [], error: null});
                });
        }
    }

    /**
     * Prints the pfd as integer array
     *
     * @param pdf as integer array
     * @param filename of the pdf
     */
    download(pdf: number[], filename: string) {
        return this.pdfService.download(pdf, filename);
    }
}
