import {
    Component,
    OnChanges,
    ViewChild,
    SimpleChanges,
    HostListener
} from '@angular/core';

import * as d3S from 'd3-selection';
import * as d3Array from 'd3-array';
import 'd3-transition';
import { BarChartBaseComponent } from '../bar-chart-base-component/bar-chart-base.component';

// noinspection JSDeprecatedSymbols
@Component({
    selector: 'app-statistics-bar-chart-trend',
    templateUrl: './statistics-bar-chart-trend.component.html',
    styleUrls: ['../bar-chart-base-component/bar-chart-base.component.scss', './statistics-bar-chart-trend.component.scss'],
})
export class StatisticsBarChartTrendComponent extends BarChartBaseComponent implements OnChanges {

    constructor() {
        super();
        this.generateUniqueId();
        this.offset = 5;
    }
    @ViewChild('barChartTrend') barChart;

    line: any;
    yMin = 0;

    @HostListener('window:resize')
    onResize() {
        if(this.barChart && this.barChart.nativeElement
        && this.barChart.nativeElement.offsetHeight) {
            // Remove svg first to make sure we receive the right sizes
            d3S.select('#' + this.uniqueID + ' svg').remove();
            d3S.select('#' + this.uniqueID + ' .tooltip').remove();

            this.initSVG();
            this.drawChart();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChanges(changes);
    }

    rerenderChart() {
        if(this.barChart && this.barChart.nativeElement
            && this.barChart.nativeElement.offsetHeight !== undefined) {
            // Remove svg first to make sure we receive the right sizes
            d3S.select('#' + this.uniqueID + ' svg').remove();
            d3S.select('#' + this.uniqueID + ' .tooltip').remove();

            this.calculateWidthHeight();
            this.initSVG();
            this.drawChart();
        }
    }

    calculateWidthHeight() {
        super.calculateWidthHeight();
        this.height = this.barChart.nativeElement.offsetHeight - 20;
        this.width = this.barChart.nativeElement.offsetWidth;
    }

    /**
     * Generate a unique id to prevent duplicate id's in html
     */
    generateUniqueId() {
        super.generateUniqueId();
    }

    /**
     * Check if it is possible to create the chart, initialize it if it is possible
     */
    initChart() {
        super.initChart();
        if(this.barChart && this.barChart.nativeElement
            && this.barChart.nativeElement.offsetHeight && !this.isChartInitialized && this.data?.current) {
            this.calculateWidthHeight();
            this.initSVG();
            this.drawChart();
            this.isChartInitialized = true;
        } else if(this.tries < 1000 && !this.isChartInitialized) {
            this.tries++;
            setTimeout(() => {
                this.initChart();
            }, 250);
        }
    }

    /**
     * Initialize the chart svg
     */
    initSVG() {
        super.initSVG();
        this.line = this.svg.append('g')
            .attr('class', 'line')
            .attr('transform', 'translate(0,0)');
    }

    calculateYMaxMin() {
        super.calculateYMaxMin();
        this.yMax = d3Array.max(this.data.current, item => item.value);
        this.yMax = this.yMax > 0 ? this.yMax : 100;
        this.yMin = d3Array.min(this.data.current, item => item.value);
        this.yMin = this.yMin < -700 ? this.yMin : -700;
    }


    /**
     * Draw the chart depending on the values
     */
    drawChart() {
        super.drawChart();
        this.yScale.domain([this.yMin, this.yMax]);

        const update = this.chart.selectAll('.bar')
            .data(this.data.current);
        update.remove().exit();

        this.drawLabels();
        this.drawZeroLine();
        if (this.showYLine) {
            this.drawCrosshair();
        }

        update.enter()
            .append('rect')
            .on('mouseover', (event, item) => this.handleMouseOver(event, item))
            .on('mouseout', () => this.handleMouseOut())
            .on('click', (event, item) => this.barClicked.emit(item))
            .attr('class', item => this.getBarClass(item.month))
            .attr('x', item => this.xScale(item.month))
            .attr('width', this.xScale.bandwidth())
            .style('fill', item => item.value >= 0 ? 'var(--communication-zone-dark)' : 'var(--ion-color-primary)')
            .style('opacity', 0.08)
            .attr('y', item =>  item.value >= 0 ? this.yScale(item.value) : this.yScale(0))
            .attr('height', item => item.value >= 0 ?
                (this.yScale(0) - this.yScale(item.value)) :
                this.yScale(item.value) - this.yScale(0));

        update.enter()
            .append('rect')
            .attr('class', item => this.getBarClass(item.month, 'border'))
            .attr('x', item => this.xScale(item.month))
            .attr('y', item => this.yScale(item.value))
            .attr('width', this.xScale.bandwidth())
            .attr('height', 1)
            .style('fill', item => item.value >= 0 ? 'var(--communication-zone-dark)' : 'var(--ion-color-primary)');
    }

    /**
     * User hovers a bar
     *
     * @param event - The mouseover event
     * @param item - The item which is hovered
     */
    handleMouseOver(event, item) {
        super.handleMouseOver(event, item);
    }

    /**
     * User stops to hover the bar
     */
    handleMouseOut() {
        super.handleMouseOut();
    }

    /**
     * User hovers the chart
     */
    handleRootMouseOver() {
        super.handleRootMouseOver();
    }

    /**
     * User stops to hover the chart
     *
     * @param event - The mouseout event
     */
    handleRootMouseOut(event) {
        super.handleRootMouseOut(event);
    }

    handleRootMouseMove(event) {
        super.handleRootMouseMove(event);
    }

    handleClick(item) {
        super.handleClick(item);
    }

    drawCrosshair() {
        super.drawCrosshair();
    }

    /**
     * Returns the class depending on the delivery
     *
     * @param date - Date of the item
     * @param classes - Additional classes for styling
     */
    getBarClass(date, classes = '') {
        return super.getBarClass(date, classes);
    }

    /**
     * Returns the class depending on the delivery
     *
     * @param date - Date of the item
     * @param classes - Additional classes for styling
     */
    getTextClass(date, classes = '') {
        return super.getTextClass(date, classes);
    }

    /**
     * track by
     *
     * @param index - The index of the item
     * @param item - The item to track
     */
    trackBy(index, item) {
        return super.trackBy(index, item);
    }

    /**
     * convert date or month to month
     *
     * @param dateOrMonth - The date or month to convert
     */
    convertToMonth(dateOrMonth) {
       return super.convertToMonth(dateOrMonth);
    }

    private drawZeroLine() {
        this.line.append('line')
            .style('stroke', 'var(--ion-color-light-shade)')  // colour the line
            .attr('x1', 0)     // x position of the first end of the line
            .attr('y1', this.yScale(0))      // y position of the first end of the line
            .attr('x2', this.width)     // x position of the second end of the line
            .attr('y2', this.yScale(0));
    }

    /**
     * Draw month labels
     */
    private drawLabels() {
        const barWidth = Math.floor(this.width / this.data.current.length) - 1;
        const update = this.monthLabels.selectAll('.month')
            .data(this.data.current);
        update.remove().exit();

        update.enter().append('g')
            .on('mouseover', (event, item) => this.handleMouseOver(event, item))
            .on('mouseout', () => this.handleMouseOut())
            .attr('class', 'month')
            .attr('transform', item => 'translate(' + (this.xScale(item.month)  + (barWidth / 2) ) + ',0)')
            .append('text')
            .attr('class', item => this.getTextClass(item.month))
            .attr('y', item => {
                // show label outside, when box isn't big enough to contain label
                if(this.yScale(0) - this.yScale(item.value) < 20 && item.value > 0) {
                    return this.yScale(item.value) - 5;
                } else if (this.yScale(item.value) - this.yScale(0) < 20 && item.value < 0) {
                    return this.yScale(item.value) + 13;
                } else if (item.value > 0) {
                    return this.yScale(item.value) + 13;
                } else {
                    return this.yScale(item.value) - 5;
                }
            })
            .attr('text-anchor', 'middle')
            .text(item => item.value === 0 ? '' :
                ((Math.abs(item.value) >= 1000) ? (Math.round(item.value / 1000 * 10) / 10 + 'k') :
                    Intl.NumberFormat('de-DE').format(Math.round(item.value * 100) / 100)))
            .style('font-size', () => this.size === 'small' ?  'var(--ion-font-size-small-text)' : 'var(--ion-font-size-text)')
            .style('font-weight', 'var(--ion-font-weight-subheadline)')
            .style('fill', item => item.value >= 0 ? 'var(--communication-zone-dark)' : 'var(--ion-color-primary)')
            .style('pointer-events', 'none');
    }
}
