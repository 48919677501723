import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { gql } from '@apollo/client/core';
import { Observable, map } from 'rxjs';
import { NotesInterface } from '../../../core.interfaces';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { NotesTypeEnum } from '../../../enums/notes.enum';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { QueryWrapper } from '../query.wrapper';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import {
    FetchPolicyKeys as FPK
} from '../../../enums/fetch-policy-keys.enum';
import{
    DataChangedKeys as DCK
} from '../../../enums/data-changed-keys.enum';

const GetNotes = (queryName) => gql`
    query ${queryName}($variables: note_bool_exp!, $limit: Int) {
        note(where: $variables, limit: $limit) {
            id
            communicationId
            orderId
            note
            created_at
            updated_at
        }
    }`;

export const AllNotesQueries = [
    GetNotes('test')
];


@Injectable()
export class NotesQueries extends QueryWrapper {
    fetchPolicies = {
        [FPK.getNoteBySourceId]: QueryFetchPolicy.NETWORK_ONLY
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar) {
        super(apollo, dataChangedVar, {
                [DCK.noteChanged]:[FPK.getNoteBySourceId]
        });
    }

    /**
     * Return a note of the given order id
     */
    public getNoteBySourceId(
        noteType: NotesTypeEnum,
        sourceId: number
    ): Observable<NotesInterface[]> {
        let variables = {};

        switch (noteType) {
            case NotesTypeEnum.communication:
                variables = {communicationId: {_eq: sourceId}};
                break;
            case NotesTypeEnum.order:
                variables = {orderId: {_eq: sourceId}};
                break;
            case NotesTypeEnum.returns:
                variables = {returnsId: {_eq: sourceId}};
                break;
        }

        const fetchPolicyKey = FPK.getNoteBySourceId;
        return this.apollo.watchQuery({
            query: GetNotes(fetchPolicyKey),
            variables: {variables, limit: GraphQLLimits.note},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['note']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<NotesInterface[]>;
    }
}

