import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { DataChangedStateInterface } from '../../interfaces/pharmacy-state.interface';
import { ConnectionStatusService } from '../../services/connectionStatus.service';
import { DataChangedKeys } from '../../enums/data-changed-keys.enum';

export const GetDataChangedState = gql`
    query getDataChangedState {
        dataChangedState @client {
            ${Object.values(DataChangedKeys).join( )}
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DataChangedStateVar {
    /**
     * produces an Object like notificationChanged: null, xxx: null ....
     * @private
     */
    private defaultValue = Object.values(DataChangedKeys)
        .reduce((a, v) => ({ ...a, [v]: null}), {});

    private dataChangedStateObservable: Observable<DataChangedStateInterface>;
    private apollo: Apollo;
    private cache;

    constructor(
        private connectionStatusService: ConnectionStatusService,
        apollo: Apollo,
    ) {
        this.apollo = apollo;
    }

    init() {
        if(this.apollo.client) {
            this.cache = this.apollo.client.cache;
            this.dataChangedStateObservable = new Observable<DataChangedStateInterface>(subscriber => {
                this.cache.watch({
                    query: GetDataChangedState,
                    callback: res => {
                        subscriber.next(res.result && res.result.dataChangedState || this.defaultValue);
                    },
                    immediate: true,
                    optimistic: true,
                });
            });
        } else {
            this.dataChangedStateObservable = new Observable();
        }
    }

    get(): Observable<DataChangedStateInterface> {
        this.init();
        return this.dataChangedStateObservable;
    }

    set(dataChangedState: DataChangedStateInterface) {
        this.init();
        if (this.cache) {
            this.cache.writeQuery({
                query: GetDataChangedState,
                data: {
                    dataChangedState
                }
            });
        }
    }
}
