import { OrderTypeInterface } from '../interfaces/order.interface';
import { OrderType } from '../enums/orders.enum';

export const OrderTypesConfig: OrderTypeInterface[]  = [
    {id: OrderType.ADDITIONAL, title: 'Nachlieferung', shortcode: 'Nachl.'},
    {id: OrderType.DISPOSITION, title: 'Disposition', shortcode: 'Dispo'},
    {id: OrderType.UE, title: 'Überweiser', shortcode: 'Überw.'},
    {id: OrderType.N_PLUS, title: 'Bevorratung', shortcode: 'Bevor.'},
];

export const OrderTypesAllConfig: OrderTypeInterface  = {id: 'all', title: 'Alle', shortcode: 'Alle'};
export const OrderTypesWithAllConfig: OrderTypeInterface[]  = [OrderTypesAllConfig, ...OrderTypesConfig];

export const getOrderTypesWithAll = () => {
    // JSON parse is necessary, cause otherwise the DeliveryTypeAllConfig will get an All button on dashboard
    const options =  [...OrderTypesConfig];
    if (options.indexOf(OrderTypesAllConfig) === -1) {
        options.unshift(OrderTypesAllConfig);
    }
    return options;
};

export const OrderTypesTranslationConfig = {
    disposition: 'Dispoauftrag',
    additional: 'Nachlieferauftrag',
    UE: 'Überweiserauftrag',
    N_PLUS: 'Bevorratungsauftrag'
};
