import { Component, Input } from '@angular/core';
import { ThemeColorEnum } from '../../enums/color.enum';

@Component({
    selector: 'app-pagination-bar',
    templateUrl: './pagination-bar.component.html',
    styleUrls: ['./pagination-bar.component.scss'],
})
export class PaginationBarComponent {

    @Input() current = 0;
    @Input() total = 0;
    @Input() markerColor?: ThemeColorEnum;
    @Input() remainderColor?: ThemeColorEnum;

    constructor() {
    }
}
