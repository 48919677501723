import { ReturnsStatusInterface, ReturnsTypeInterface } from '../interfaces/returns.interface';
import { ReturnsStatusEnum, ReturnsTypeEnum } from '../enums/returns.enum';

export const ReturnsStatusConfig: ReturnsStatusInterface[] = [
    {
        id: ReturnsStatusEnum.cancelled,
        color: 'returns-status-cancelled',
        title: 'storniert Kunde',
        shortcode: 'storniert Kunde',
        tooltipCode: 'cancelled',
        sortOrder: 10
    },{
        id: ReturnsStatusEnum.goodsReturned,
        color: 'returns-status-goodsReturned',
        title: 'Ware zurück',
        shortcode: 'Ware zurück',
        tooltipCode: 'goods_returned',
        sortOrder: 20
    },{
        id: ReturnsStatusEnum.credited,
        color: 'returns-status-credited',
        title: 'gutgeschrieben',
        shortcode: 'gutgeschrieben',
        tooltipCode: 'credited',
        sortOrder: 30
    },{
        id: ReturnsStatusEnum.processing,
        color: 'returns-status-processing',
        title: 'in Bearbeitung',
        shortcode: 'in Bearbeitung',
        tooltipCode: 'processing',
        sortOrder: 40
    },{
        id: ReturnsStatusEnum.destroyed,
        color: 'returns-status-destroyed',
        title: 'vernichtet',
        shortcode: 'vernichtet',
        tooltipCode: 'destroyed',
        sortOrder: 50
    },{
        id: ReturnsStatusEnum.returnMissing,
        color: 'returns-status-returnMissing',
        title: 'Retoure fehlt',
        shortcode: 'Retoure fehlt',
        tooltipCode: 'return_missing',
        sortOrder: 60
    },{
        id: ReturnsStatusEnum.withoutCalculation,
        color: 'returns-status-withoutCalculation',
        title: 'ohne Berechnung',
        shortcode: 'ohne Berechnung',
        tooltipCode: 'without_calculation',
        sortOrder: 70
    },
].sort((a, b) => a.sortOrder - b.sortOrder);

export const ReturnsStatusAllConfig: ReturnsStatusInterface = {
    id: ReturnsStatusEnum.all,
    color: '',
    title: 'Alle',
    shortcode: 'Alle',
    tooltipCode: 'all',
    sortOrder: 1
};

export const ReturnsTypeConfig: ReturnsTypeInterface[] = [
    {
        id: ReturnsTypeEnum.recall,
        color: 'returns-type-recall',
        title: ReturnsTypeEnum.recall,
        shortcode: ReturnsTypeEnum.recall,
        tooltipCode: 'recall',
        sortOrder: 10
    },{
        id: ReturnsTypeEnum.stockDefect,
        color: 'returns-type-stockDefect',
        title: ReturnsTypeEnum.stockDefect,
        shortcode: ReturnsTypeEnum.stockDefect,
        tooltipCode: 'stock_defect',
        sortOrder: 20
    },{
        id: ReturnsTypeEnum.withdrawal,
        color: 'returns-type-withdrawal',
        title: ReturnsTypeEnum.withdrawal,
        shortcode: ReturnsTypeEnum.withdrawal,
        tooltipCode: 'withdrawal',
        sortOrder: 30
    },{
        id: ReturnsTypeEnum.return,
        color: 'returns-type-return',
        title: ReturnsTypeEnum.return,
        shortcode: ReturnsTypeEnum.return,
        tooltipCode: 'return',
        sortOrder: 40
    },
];

export const ReturnsTypeAllConfig: ReturnsTypeInterface = {
    id: ReturnsTypeEnum.all,
    color: '',
    title: 'Alle',
    shortcode: 'Alle',
    tooltipCode: 'all',
    sortOrder: 1
};

export const getReturnsStatusWithAll = () => {
    const status = [...ReturnsStatusConfig];
    if (status.indexOf(ReturnsStatusAllConfig) === -1) {
        status.unshift(ReturnsStatusAllConfig);
    }
    return status;
};

export const getReturnsTypeWithAll = () => {
    const type = [...ReturnsTypeConfig];
    if (type.indexOf(ReturnsTypeAllConfig) === -1) {
        type.unshift(ReturnsTypeAllConfig);
    }
    return type;
};
