import { FieldLengthEnum } from '../enums/input-validation.enum';

/**
 * get the field lengths
 */
export const FieldLengths  = {
    [FieldLengthEnum.communicationZoneTitle]: 125,
    [FieldLengthEnum.communicationZoneContent]: 1020,
    [FieldLengthEnum.communicationZoneContactPerson]: 125,
    [FieldLengthEnum.cancelOrder]: 125,
    [FieldLengthEnum.dispoEnquiry]: 1024,
    [FieldLengthEnum.dispoEnquiryQty]: 3,
    [FieldLengthEnum.dispoEnquiryProductName]: 100,
    [FieldLengthEnum.dispoEnquiryNarcotic]: 10,
    [FieldLengthEnum.dispoEnquiryPackageSize]: 20,
    [FieldLengthEnum.dispoEnquiryDosageForm]: 30,
    [FieldLengthEnum.dispoEnquiryProducer]: 100,
    [FieldLengthEnum.dispoEnquiryPZN]: 10,
    [FieldLengthEnum.dispoEnquiryAdditionalInformation]: 400,
    [FieldLengthEnum.returnRequest]: 2000,
    [FieldLengthEnum.returnRequestRemarks]: 100
};
