import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findAttr'
})
export class FindAttributePipe implements PipeTransform {

  transform(items: Array<any>,
            value: string | number,
            identifier: string,
            attribute: string
  ): string | number {

    try {
      return items.find(item => item[identifier] === value)[attribute];
    } catch {
      return '';
    }
  }
}
