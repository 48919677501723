import { Pipe, PipeTransform } from '@angular/core';
import { Md5 } from 'ts-md5';
import { UserInterface } from '../interfaces/appointments.interface';
import { environment } from '../../../environments/environment';



/**
 * suffix is explained here: https://de.gravatar.com/site/implement/images/
 * d = default image is the mystery person
 * r = rating is set to "suitable for all websites"
 * s = size is set to 100px
 */
const GRAVATAR_URL_SUFFIX = '?d=mp&r=g&s=100';
const GRAVATAR_URL = 'https://0.gravatar.com/avatar/';
const GRAVATAR_DEFAULT_USER = 'https://0.gravatar.com/avatar/2dbbd4b9e9979d179f1e902cc80e3907?d=mp&r=g&s=100';

/**
 * encodes email address with md5
 *
 * @param emailAddress - Mail address to encode
 */
const encodeEmailAddress = (emailAddress) => {
    const md5 = new Md5();
    return md5.appendStr(emailAddress.trim().toLowerCase()).end();
};

@Pipe({
    name: 'getUserAvatar'
})
export class GetUserAvatarPipe implements PipeTransform {
    transform(user: UserInterface): string {
        const mediaUri = environment.mediaUriStrapi;
        if (user && user.avatar && user.avatar.url) {
            if(user.avatar.url.indexOf('http') > -1) {
                return user.avatar.url;
            }
            return mediaUri + user.avatar.url;


        } else if(user && user.email) {
            let url = GRAVATAR_URL;
            url += encodeEmailAddress(user.email);
            url += GRAVATAR_URL_SUFFIX;
            return url;
        }

        // Default user avatar
        return GRAVATAR_DEFAULT_USER;
    }

}

@Pipe({
    name: 'getMeaUserAvatar'
})
export class GetMeaUserAvatarPipe implements PipeTransform {
    transform(isAvatarAvailable: boolean): string {
        if(!isAvatarAvailable) {
            return GRAVATAR_DEFAULT_USER;
        }
        // todo set image
        return GRAVATAR_DEFAULT_USER;
    }
}


@Pipe({
    name: 'getDefaultAvatar'
})
export class GetDefaultAvatarPipe implements PipeTransform {
    transform(): string {
        return GRAVATAR_DEFAULT_USER;
    }
}



