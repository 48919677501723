<ion-item class="background-input app-outside-click-directive" (appClickOutside)="onClickOutside()">
        <ion-input i18n-label fill="outline" type="text" aria-label="" trim
                   label="{{label}}"
                   class="required"
                   [ngClass]="{
                        'invalid': isInvalid
                   }"
                   [minlength]="validationFieldEnum | inputValidationMinLength:validators"
                   [maxlength]="validationFieldEnum | inputValidationMaxLength:validators"
                   [placeholder]="placeholder"
                   (ionFocus)="showDropdown = true"
                   (click)="showDropdown = true"
                   (ionChange)="onInputChange($event)"
                   #input
        ></ion-input>
        <ion-icon name="alert-circle"
                  color="primary"
                  *ngIf="isInvalid"
                  appTooltip
                  [tt]="tooltipInvalidValue"
                  ttp="right">
        </ion-icon>
        <app-dropdown-list [listData]="items" [showList]="showDropdown && items?.length > 0" (itemClicked)="onItemClick($event)"></app-dropdown-list>
</ion-item>
