import { OrderStatusDescriptionInterface } from '../interfaces/order-status.interface';
import { OrderStatus } from '../enums/orders.enum';

export const OrderStatusDescriptionConfig: OrderStatusDescriptionInterface[]  = [
    { id: OrderStatus.PENDING,               description: 'Die Bestellung ist bei Sanacorp eingegangen und in Bearbeitung.' },
    { id: OrderStatus.DELEGATED,             description: 'Die Bestellabwicklung erfolgt vollständig durch den Lieferanten.' },
    { id: OrderStatus.ENROUTE,               description: 'Der Artikel wird der Apotheke in Kürze zugestellt.' },
    { id: OrderStatus.PARTIALLY_COMPLETED,   description: 'Eine Teilmenge wurde an die Apotheke geliefert.' },
    { id: OrderStatus.COMPLETED,             description: 'Der Artikel wurde der Apotheke geliefert.' },
    { id: OrderStatus.CANCELLED,             description: 'Die Bestellung wurde storniert.' },
];
