<swiper-container *ngIf="isLoading || items?.length > 0"
    [config]="swiperConfig"
    appSwiper
    #swiper>
    <ng-container *ngIf="isLoading">
        <swiper-slide *ngFor="let item of [1,2,3]">
            <ion-card>
                <div class="header-image ion-padding-big ion-text-center">
                    <ion-spinner name="crescent" color="light" class="loader"></ion-spinner>
                </div>
                <ion-card-header>
                    <ion-card-title>
                        <ion-skeleton-text animated style="width: 120px; height: 16px" class="ion-margin-bottom"></ion-skeleton-text>
                    </ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                    <ion-skeleton-text animated style="width: 100%" class="ion-margin-bottom"></ion-skeleton-text>
                    <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                    <ion-skeleton-text animated style="width: 100%" class="ion-margin-bottom"></ion-skeleton-text>
                </ion-card-content>
            </ion-card>
        </swiper-slide>
    </ng-container>
    <ng-container *ngIf="!isLoading">
        <swiper-slide *ngFor="let item of items">
            <ng-container *ngTemplateOutlet="ionCardTemplate; context: {$implicit: item}"></ng-container>
            <ng-container *ngIf="!ionCardTemplate">
                <ion-card (click)="onItemClick(item)" [class.has-header-image]="headerImageTemplate || imageField">
                    <div class="header-image" *ngIf="headerImageTemplate">
                        <ng-container *ngTemplateOutlet="headerImageTemplate; context: {$implicit: item}"></ng-container>
                    </div>
                    <div class="header-image" *ngIf="!headerImageTemplate && imageField && item[imageField]"
                         [style.backgroundImage]="'url('+ (item[imageField] | getImageUrl) +')'"></div>
                    <ion-card-header>
                        <ion-card-title><ng-container *ngTemplateOutlet="titleTemplate; context: {$implicit: item}"></ng-container></ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        <ng-container *ngTemplateOutlet="contentTemplate; context: {$implicit: item}"></ng-container>
                    </ion-card-content>
                </ion-card>
            </ng-container>
        </swiper-slide>
    </ng-container>
</swiper-container>
<div class="slider-nav-buttons" [class.has-header]="headerImageTemplate" [hidden]="items && items.length <= 3" >
    <ion-button [class.swiper-button-disabled]="swiperRef?.nativeElement?.swiper?.isEnd" fill="clear"  (click)="moveToSlide(true)" [style.--theme-color]="themeColor?.mainColor">
        <ion-icon [name]="'arrow-forward'" size="large" mode="ios"></ion-icon>
    </ion-button>
    <ion-button [class.swiper-button-disabled]="swiperRef?.nativeElement?.swiper?.isBeginning" fill="clear" (click)="moveToSlide(false)" [style.--theme-color]="themeColor?.mainColor">
        <ion-icon [name]="'arrow-back'" size="large"  mode="ios"></ion-icon>
    </ion-button>
</div>
