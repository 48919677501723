<ng-container *ngIf="!filePreviewUrl || imageNotAvailable">
    <span>{{title}}</span>
    <span>{{fileExtension}}</span>
</ng-container>
<ng-container *ngIf="filePreviewUrl && !imageNotAvailable">
    <ion-img [src]="filePreviewUrl" (ionError)="onImageLoadError()"></ion-img>
</ng-container>


<div><ion-icon src="assets/icons/download.svg" mode="md"></ion-icon></div>
