import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { environment } from '../../../environments/environment';

@Directive({
    selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

    constructor(private elementRef: ElementRef) { }

    @Output() appClickOutside = new EventEmitter<MouseEvent>();

    @HostListener('document:click', ['$event', '$event.target'])
    public onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (!targetElement) {
            return;
        }
        const className = 'app-outside-click-directive';
        if (!this.elementRef?.nativeElement?.classList?.contains(className) && environment.name !== 'production') {
            console.error('Necessary class name ' + className + ' missing!');
            return;
        }
        let parentElement = targetElement.parentElement;
        let elementFound = false;
        for (let i=0; i<=6; i++) {
            if (parentElement && parentElement.classList.contains(className)) {
                elementFound = true;
                break;
            } else {
                parentElement = parentElement?.parentElement;
            }
        }
        if (!elementFound) {
            this.appClickOutside.emit(event);
        }
    }
}
