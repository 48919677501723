import update, { Spec } from 'immutability-helper';
import omit from 'lodash-es/omit';
import sortBy from 'lodash-es/sortBy';
import {
  Conversation,
  ConversationAndLastMessage,
  ConversationSegment,
} from '../../../../../../essentials/types/src/conversation';
import { LoadStatus } from '../../../../../../essentials/types/src/loadStatus';
import Message from '../../../../../../essentials/types/src/message';
import PaginatedMessages from '../../../../../../essentials/types/src/paginatedMessages';
import { ChatState } from '../../state/chat.state';

export const performSetConversations = (
  state: ChatState,
  conversationsAndLastMessages: ConversationAndLastMessage[],
  { newLoadStatus = LoadStatus.UpToDate, removeNonExistingConversations = true } = {}
) => {
  if (removeNonExistingConversations) {
    state = removeNonExistingConversationsFromState(state, conversationsAndLastMessages);
  }
  for (const { conversation, lastMessage } of conversationsAndLastMessages) {
    if (state.conversations[conversation.id]) {
      state = updateExistingConversation(state, conversation, lastMessage);
    } else {
      state = addNewConversationToState(state, conversation, lastMessage);
    }
  }

  return update(state, {
    conversationsLoadStatus: { $set: newLoadStatus },
  });
};

const removeNonExistingConversationsFromState = (
  state: ChatState,
  conversationsAndLastMessages: ConversationAndLastMessage[]
): ChatState => {
  const idsOfExistingConversations = conversationsAndLastMessages.map(({ conversation: { id } }) => id);
  const idsOfNonExistingConversations = Object.keys(state.conversations).filter(
    (id) => !idsOfExistingConversations.includes(id)
  );
  return update(state, {
    conversations: { $unset: idsOfNonExistingConversations },
    messages: { $unset: idsOfNonExistingConversations },
  });
};

const updateExistingConversation = (state: ChatState, conversation: Conversation, lastMessage: Message | undefined) => {
  for (const newSegment of conversation.segments) {
    const existingSegmentIndex = (state.conversations[conversation.id] as Conversation).segments.findIndex(
      (segment) => segment.id === newSegment.id
    );
    if (existingSegmentIndex >= 0) {
      state = updateExistingSegment(state, newSegment, lastMessage, conversation, existingSegmentIndex);
    } else {
      const messages = lastMessage && lastMessage.conversationId === newSegment.id ? [lastMessage] : [];
      state = update(state, {
        conversations: {
          [conversation.id]: {
            segments: {
              $apply: (existingSegments: ConversationSegment[]) =>
                sortBy([...existingSegments, newSegment], 'createdAt'),
            },
          },
        },
        messages: {
          [newSegment.id]: {
            $set: {
              messages,
              hasMoreMessages: true,
              nextToken: '',
            },
          },
        },
      });
    }
  }
  state = updateBaseConversation(state, conversation);
  return state;
};

const updateExistingSegment = (
  state: ChatState,
  newSegment: ConversationSegment,
  lastMessage: Message | undefined,
  conversation: Conversation,
  existingSegmentIndex: number
) => {
  state = update(state, {
    conversations: {
      [conversation.id]: {
        segments: {
          [existingSegmentIndex]: (existingSegment: ConversationSegment): ConversationSegment => {
            return update(existingSegment, { $merge: omit(newSegment, ['decryptionStatus']) });
          },
        },
      },
    },
  });
  if (lastMessage && lastMessage.conversationId === newSegment.id) {
    const existingLastMessageIndex = (state.messages[newSegment.id] as PaginatedMessages).messages.findIndex(
      (message) => message.id === lastMessage.id
    );
    const messagesUpdate: Spec<Message[]> =
      existingLastMessageIndex >= 0
        ? { [existingLastMessageIndex]: mergeMessage(lastMessage) }
        : { $push: [lastMessage] };

    state = update(state, {
      messages: {
        [newSegment.id]: {
          messages: messagesUpdate,
        },
      },
    });
  }
  return state;
};

function updateBaseConversation(state: ChatState, conversation: Conversation): ChatState {
  return update(state, {
    conversations: {
      [conversation.id]: { $merge: omit(conversation, ['segments', 'messagesInitializationStatus']) },
    },
  });
}

const addNewConversationToState = (state: ChatState, conversation: Conversation, lastMessage: Message | undefined) => {
  // add conversation to store
  state = update(state, {
    conversations: {
      [conversation.id]: { $set: conversation },
    },
  });

  // add empty message segments and last message to store
  for (const segment of conversation.segments) {
    const messages = lastMessage && lastMessage.conversationId === segment.id ? [lastMessage] : [];
    state = update(state, {
      messages: {
        [segment.id]: {
          $set: {
            messages,
            hasMoreMessages: true,
            nextToken: '',
          },
        },
      },
    });
  }
  return state;
};

export const mergeMessage =
  (newLastMessage: Message) =>
  (existingLastMessage: Message): Message => {
    existingLastMessage = update(existingLastMessage, {
      $merge: omit(newLastMessage, ['decryptionStatus', 'decryptedTextContent', 'media']),
    });
    if (newLastMessage.media) {
      const media = [];
      for (const newMedia of newLastMessage.media) {
        const existingMedia = existingLastMessage.media?.find((entry) => entry.id === newMedia.id);
        if (existingMedia) {
          media.push(
            update(existingMedia, {
              $merge: omit(newMedia, ['decryptedContent', 'decryptedPreview', 'decryptedFilename']) as any,
            })
          );
        } else if (existingLastMessage.decryptionStatus !== 'failed') {
          media.push(newMedia);
        }
        // ignore missing media if decryptionStatus === 'failed', since media entries are removed in the client when decryption fails.
      }
      existingLastMessage = update(existingLastMessage, {
        media: { $set: media },
      });
    }
    return existingLastMessage;
  };
