import gql from 'graphql-tag';
import { updateMessageAttributes } from '../attributes';

export default gql`
  subscription updatedSentMessage($senderId: ID!) {
    updatedSentMessage(senderId: $senderId) {
      ${updateMessageAttributes}
    }
  }
`;
