import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'stringToNumber'
})
export class StringToNumberPipe implements PipeTransform {
    transform(value: string): number {
        return Number(value);
    }
}

@Pipe({
    name: 'lastPartOfString'
})
export class LastPartOfStringPipe implements PipeTransform {
    transform(value: string, character: string): string|undefined {
        return value ? value.split(character).pop() : undefined;
    }
}

@Pipe({
    name: 'stringToUppercase'
})
export class StringToUppercasePipe implements PipeTransform {
    transform(value: string): string|undefined {
        return value?.toUpperCase();
    }
}

@Pipe({
    name: 'bold'
})
export class BoldPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string): SafeHtml {
        const newValue = value.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
        return this.sanitizer.bypassSecurityTrustHtml(newValue);
    }

}


@Pipe({
    name: 'substring'
})
export class SubstringPipe implements PipeTransform {

    transform(value: string, maxLength: number): string {
        if (value.length > maxLength) {
            return value.substring(0, maxLength) + '...';
        }
        return value;
    }

}

@Pipe({
    name: 'removeInlineStyles'
})
export class RemoveInlineStyles implements PipeTransform {

    transform(html): string {
        return html.replace(/style\s*=\s*(['"])(.*?)\1/gi, '');
    }

}
