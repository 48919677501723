import { Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-expandable-sidebar',
    templateUrl: 'expandable-sidebar.component.html',
    styleUrls: ['expandable-sidebar.component.scss'],
    animations: [
        trigger('contentVisible', [
            state('visible', style({
                left: 0
            })),
            state('hidden', style({
                left: '100%',
            })),
            transition('visible=>hidden', animate('250ms')),
            transition('hidden=>visible', animate('250ms')),
        ]),
        trigger('listVisible', [
            state('visible', style({
                left: 0
            })),
            state('hidden', style({
                left: '-100%',
            })),
            transition('visible=>hidden', animate('250ms')),
            transition('hidden=>visible', animate('250ms')),
        ]),
    ]
})
export class ExpandableSidebarComponent {
    @Input() title: string;

    @Input() isSidebarVisible = false;
    @Input() isDetailsVisible = false;
    isLoading = false;
    selectedSimilarQuestion = null;


    constructor() {
    }


}
