import { Routes } from '@angular/router';

export enum InformationRoutesEnum {
    general = '/app/information/general',
    customerService = '/app/information/customer-service'
}

export const childrenRoutes: Routes = [
    {
        path: 'general',
        loadChildren: () => import('./pages/general/general.module').then(m => m.GeneralPageModule)
    },
    {
        path: 'customer-service',
        loadChildren: () => import('./pages/customer-service/customer-service.module').then(m => m.CustomerServicePageModule)
    },
    {
        path: '',
        redirectTo: 'general',
        pathMatch: 'full'
    }
];
