import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'divideBy',
})
export class DivideByPipe implements PipeTransform {
    transform(
        value: number,
        divideBy: number
    ): number {
        return value / divideBy;
    }
}
