import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { ViewTypeEnum } from '../../enums/view-type.enum';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    animations: [
        trigger('bulkVisible', [
            state('visible', style({
                flex: '0 0 auto',
                width: '*',
                padding: '*',
                margin: '0 var(--ion-margin-medium) 0 0',
                opacity: 1
            })),
            state('hidden', style({
                flex: '0 0 0',
                padding: 0,
                margin: 0,
                width: 0,
                opacity: 0
            })),
            transition('visible=>hidden', animate('250ms')),
            transition('hidden=>visible', animate('250ms')),
        ])
    ]
})
export class ListComponent implements OnChanges {

    // Templates
    @Input() noResultsTmpl: TemplateRef<any>;
    @Input() titleBeforeTmpl: TemplateRef<any>;
    @Input() titleTmpl: TemplateRef<any>;
    @Input() titleAfterTmpl: TemplateRef<any>;
    @Input() titleRightTmpl: TemplateRef<any>;
    @Input() contentCommonTmpl: TemplateRef<any>;
    @Input() contentAdditionalTmpl: TemplateRef<any>;

    @Input() isLoading = false;
    @Input() isBulkActive = false;
    @Input() bulkSelectedItems = [];
    @Input() items = null;
    @Input() activeId = null;
    @Input() color = 'magenta';
    @Input() viewType = ViewTypeEnum.LIST;


    @Output() loadMore = new EventEmitter<ListComponent>();
    @Output() changeActiveId = new EventEmitter<number>();
    @Output() changeBulkSelectedItems = new EventEmitter<any[]>();

    @ViewChild('infiniteScroll') infiniteScroll;

    viewTypeTable = ViewTypeEnum.TABLE;


    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Reactivate infinite scroll if items changed (e.g. after search or filter change)
        if(changes && changes.items && this.infiniteScroll) {
            this.infiniteScroll.disabled = false;
        }
    }

    /**
     * User clicked on item
     *
     * @param id - id of the clicked item
     */
    onItemClick(id){
        this.changeActiveId.emit(id);
    }

    onBulkItemChange(event, id) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        if(this.bulkSelectedItems.findIndex(item => item === id) !== -1) {
            this.bulkSelectedItems = [...this.bulkSelectedItems.filter(item => item !== id)];
        } else {
            this.bulkSelectedItems = [...this.bulkSelectedItems, id];
        }
        this.cdr.detectChanges();

        this.changeBulkSelectedItems.emit(this.bulkSelectedItems);
    }

    /**
     * User scrolled down to load more items
     */
    onLoadMore() {
        this.loadMore.emit(this);
    }

    complete() {
        this.infiniteScroll.complete();
    }
    disable() {
        this.infiniteScroll.disabled = true;
    }
}
