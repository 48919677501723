import { Component, Input, OnInit } from '@angular/core';
import { ColorInterface } from '../../interfaces/theme-color.interface';


/**
 *
 * Usage:
 * <app-interferer [content]="5.5%"> </app-interferer>
 */
@Component({
    selector: 'app-interferer',
    templateUrl: './interferer.component.html',
    styleUrls: ['./interferer.component.scss'],
})
export class InterfererComponent implements OnInit {
    @Input() head = '';
    @Input() content = '';
    @Input() isCard = false;
    @Input() themeColor?: ColorInterface;


    ngOnInit() {

    }
}
