import { Injectable } from '@angular/core';
import { ToastService } from './toast.service';
import { MaxExecutionErrorMessage } from '../enums/api.enum';

@Injectable({
    providedIn: 'root',
})
export class ApiService {

    constructor(
        public toastService: ToastService
    ) { }

    /**
     * check if a global message (in store.module) is already handled (i.e. a toast will be created)
     * - check for max-execution time
     *
     * @param message - error message
     */
    static hasGlobalErrorMessage(message: string): boolean {
        return (
            message &&
            message.toString().startsWith(MaxExecutionErrorMessage.prefix + MaxExecutionErrorMessage.startsWith) &&
            message.toString().endsWith(MaxExecutionErrorMessage.endsWith)
        );
    }


    async presentSuccessToast(text) {
       await this.toastService.presentSuccess(text);
    }

    async presentSuccessToastNoDuration(text) {
        await this.toastService.presentSuccess(text, null);
    }

    async presentErrorToast(error, text) {
        if(!error) {
            await this.toastService.presentError(text);
        }
        if(!ApiService.hasGlobalErrorMessage(error)) {
            await this.toastService.presentError(text);
        }
    }

    async presentErrorToastNoDuration(error, text: string) {
        if(!error) {
            await this.toastService.presentError(text, null);
        }
        if(!ApiService.hasGlobalErrorMessage(error)) {
            await this.toastService.presentError(text, null);
        }
    }
}
