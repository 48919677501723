import { Component, Input, OnInit } from '@angular/core';
import { ButtonClickResponseInterface } from '../../../core/core.interfaces';
import { ActionButtonTypesEnum } from '../../../core/enums/action-button-types.enum';
import { ToolbarActionButtons } from '../../../core/config/toolbar-actions.config';


@Component({
    selector: 'app-add-actions-popover',
    templateUrl: './add-actions-popover.component.html',
    styleUrls: ['./add-actions-popover.component.scss'],
})
export class AddActionsPopoverComponent implements OnInit {

    // Popover Controller, provided by ionic
    popover;
    @Input() actionButtons = ToolbarActionButtons;

    constructor() {
    }

    ngOnInit() {
    }

    /**
     * Called if add action button is clicked
     *
     * @param event - Click event
     */
    async onButtonClick(event: ButtonClickResponseInterface) {
        if (this.popover && this.popover.dismiss) {
            this.popover.dismiss({code: event.code as ActionButtonTypesEnum});
        }
    }

}
