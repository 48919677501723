import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { from, map } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { DataChangedKeys as DCK } from '../../../enums/data-changed-keys.enum';
import { DataChangedForceStateVar } from '../../locals/dataChangeForceState.var';

export const RequestSafetyDataSheetPdf = gql`
    mutation requestSafetyDataSheetPdf($pzn:String!) {
        RequestSafetyDataSheetPdf(pzn: $pzn) {
            status
            message
        }
    }
`;
export const AllSafetyDatasheetMutations  = [
    RequestSafetyDataSheetPdf
];

@Injectable()
export class SafetyDatasheetMutations {
    constructor(
        private apollo: Apollo,
        private apiService: ApiService,
        private dataChangedForceState: DataChangedForceStateVar
    ) {}

    requestSafetyDataSheetPdf(pzn: string) {
        this.apollo.mutate( {
            mutation: RequestSafetyDataSheetPdf,
            variables: {
                pzn
            }
        }).subscribe({
            next: result => {
                from([result])
                    .pipe(map(d => d?.data && d?.data['RequestSafetyDataSheetPdf'] && d?.data['RequestSafetyDataSheetPdf']['status']))
                    .subscribe((status: string) => {
                        if (!status || status === 'ERROR' || !!result['errors']) {
                            void this.apiService.presentErrorToast(
                                result['errors'],
                                'Die Anfrage konnte nicht übermittelt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.'
                            );
                        } else {
                            void this.apiService.presentSuccessToastNoDuration('Ihre Suche wurde gesendet, dies kann einen Moment dauern.');
                        }
                        void this.dataChangedForceState.setForceState({[DCK.safetyDataSheetsChanged]: null});
                    }).unsubscribe();
            },
            error: error => {
                void this.apiService.presentErrorToast(error, 'Die Anfrage konnte nicht übermittelt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.');
            }
        });
    }

}
