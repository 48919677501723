import { Action, createReducer, on } from '@ngrx/store';
import { removeLoadingStatus, setLoadingStatus } from '../actions/loading-status.actions';
import { LoadingStatusState } from '../state/loading-status.state';

export const initialLoadingStatusState: LoadingStatusState = {};

const _loadingStatusReducer = createReducer(
  initialLoadingStatusState,
  on(setLoadingStatus, (state, { message }) => ({ ...state, backupRestore: message })),
  on(removeLoadingStatus, (state, { requestKey }) => ({ ...state, [requestKey]: undefined }))
);

export function loadingStatusReducer(state: LoadingStatusState | undefined, action: Action): LoadingStatusState {
  return _loadingStatusReducer(state, action);
}
