import { SettingsConfigInterface } from '../interfaces/settings.interface';

/**
 * get the general, unsorted date range config
 */
export const SettingsConfig: SettingsConfigInterface  = {
        language: {
            iso: {
                readonly: true
            },
            title: {
                readonly: false
            },
            index: {
                readonly: true
            }
        },
        notification: {
            common: {
                soundEnabled: {
                    readonly: false
                },
                soundInterval: {
                    readonly: false,
                    depends: [
                        {
                            path: 'common/soundEnabled',
                            value: true
                        }
                    ]
                }
            },
            chat: {
                soundEnabled: {
                    readonly: false
                },
                soundInterval: {
                    readonly: false,
                    depends: [
                        {
                            path: 'chat/soundEnabled',
                            value: true
                        }
                    ]
                }
            }
        },
        calendar: {
            sanacorpEvents: {
                allowed: {
                    readonly: false
                }
            },
        },
        statistics: {
            targetSales: {
                readonly: false
            }
        },
        security: {
            pin: {
                readonly: false
            }
        }
    };

