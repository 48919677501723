import { Action, createReducer, on } from '@ngrx/store';
import update from 'immutability-helper';
import cloneDeep from 'lodash-es/cloneDeep';
import isNil from 'lodash-es/isNil';
import { Dictionary } from 'ts-essentials';
import { ChatPartnerMetadata } from '../../../../essentials/types/src/chatPartnerMetadata';
import { LoadStatus } from '../../../../essentials/types/src/loadStatus';
import {
  addDecryptedChatPartnerNames,
  deleteAllChatPartnerMetadata,
  loadChatPartnerMetadata,
  loadChatPartnerMetadataFailure,
  loadChatPartnerMetadataSuccess,
  setChatPartnerNickname,
  updateChatPartnerMetadata,
} from '../../common-store/other/actions/chat-partner.actions';
import { markDataWithSubscriptionsAsStale } from '../../common-store/other/actions/subscription.actions';
import { clearUserOnLogout, setUserOnLogin } from '../../common-store/user-store/actions/user.actions';
import { PharmacyChatPartnerState } from './pharmacy-chat-partner.state';

export const initialPharmacyChatPartnerState: PharmacyChatPartnerState = {
  pharmacyChatPartnerMetadataDictionary: {},
  pharmacyChatPartnerLoadStatus: LoadStatus.Init,
};

const _pharmacyChatPartnerReducer = createReducer(
  initialPharmacyChatPartnerState,

  on(loadChatPartnerMetadata, (state) =>
    update(state, {
      pharmacyChatPartnerLoadStatus: {
        $set:
          state.pharmacyChatPartnerLoadStatus === LoadStatus.Init ? LoadStatus.LoadingInitial : LoadStatus.Revalidating,
      },
    })
  ),

  on(loadChatPartnerMetadataSuccess, (state, { allChatPartnerMetadata }) => {
    let updatedChatPartnerMetadataDictionary = state.pharmacyChatPartnerMetadataDictionary;
    allChatPartnerMetadata.forEach((newMetadata) => {
      updatedChatPartnerMetadataDictionary = updateMetadataForChatPartner(
        updatedChatPartnerMetadataDictionary,
        newMetadata
      );
    });
    return update(state, {
      pharmacyChatPartnerMetadataDictionary: { $set: updatedChatPartnerMetadataDictionary },
      pharmacyChatPartnerLoadStatus: { $set: LoadStatus.UpToDate },
    });
  }),

  on(loadChatPartnerMetadataFailure, (state) =>
    update(state, { pharmacyChatPartnerLoadStatus: { $set: LoadStatus.Error } })
  ),

  on(updateChatPartnerMetadata, (state, { chatPartnerMetadata }) =>
    update(state, {
      pharmacyChatPartnerMetadataDictionary: {
        $apply: (currentDictionary: Dictionary<ChatPartnerMetadata>) =>
          updateMetadataForChatPartner(currentDictionary, chatPartnerMetadata),
      },
    })
  ),

  on(deleteAllChatPartnerMetadata, (state) =>
    update(state, {
      pharmacyChatPartnerMetadataDictionary: { $set: {} },
    })
  ),

  on(
    setChatPartnerNickname,
    (state, { decryptedChatPartnerNickname, encryptedChatPartnerNickname, chatPartnerId, cognitoId }) => {
      const existingMetadataForUser = state.pharmacyChatPartnerMetadataDictionary[chatPartnerId];
      if (existingMetadataForUser) {
        return update(state, {
          pharmacyChatPartnerMetadataDictionary: {
            [chatPartnerId]: {
              $merge: {
                decryptedChatPartnerNickname,
                encryptedChatPartnerNickname,
                chatPartnerNicknameDecryptionStatus: 'decrypted',
              },
            },
          },
        });
      }
      return update(state, {
        pharmacyChatPartnerMetadataDictionary: {
          [chatPartnerId]: {
            $set: {
              cognitoId,
              chatPartnerId,
              decryptedChatPartnerNickname,
              encryptedChatPartnerNickname,
              chatPartnerNicknameDecryptionStatus: 'decrypted',
            },
          },
        },
      });
    }
  ),

  on(
    addDecryptedChatPartnerNames,
    (state, { newlyDecryptedChatPartnerChatnames, newlyDecryptedChatPartnerNicknames }) => {
      let updatedChatPartnerMetadataDictionary = state.pharmacyChatPartnerMetadataDictionary;
      newlyDecryptedChatPartnerNicknames.forEach((newMetadata) => {
        updatedChatPartnerMetadataDictionary = update(updatedChatPartnerMetadataDictionary, {
          [newMetadata.chatPartnerId]: {
            $apply: (existing) =>
              existing && {
                ...existing,
                decryptedChatPartnerNickname: newMetadata.decryptedChatPartnerNickname,
                chatPartnerNicknameDecryptionStatus: newMetadata.chatPartnerNicknameDecryptionStatus,
              },
          },
        });
      });
      newlyDecryptedChatPartnerChatnames.forEach((newMetadata) => {
        updatedChatPartnerMetadataDictionary = update(updatedChatPartnerMetadataDictionary, {
          [newMetadata.chatPartnerId]: {
            $apply: (existing) =>
              existing && {
                ...existing,
                decryptedChatPartnerChatname: newMetadata.decryptedChatPartnerChatname,
                chatPartnerChatnameDecryptionStatus: newMetadata.chatPartnerChatnameDecryptionStatus,
              },
          },
        });
      });
      return update(state, { pharmacyChatPartnerMetadataDictionary: { $set: updatedChatPartnerMetadataDictionary } });
    }
  ),

  on(markDataWithSubscriptionsAsStale, (state) => {
    if (state.pharmacyChatPartnerLoadStatus === LoadStatus.UpToDate) {
      return update(state, { pharmacyChatPartnerLoadStatus: { $set: LoadStatus.Stale } });
    }
    return state;
  }),

  on(clearUserOnLogout, setUserOnLogin, () => cloneDeep(initialPharmacyChatPartnerState))
);

export function pharmacyChatPartnerReducer(
  state: PharmacyChatPartnerState | undefined,
  action: Action
): PharmacyChatPartnerState {
  return _pharmacyChatPartnerReducer(state, action);
}

function updateMetadataForChatPartner(
  pharmacyChatPartnerMetadataDictionary: Dictionary<ChatPartnerMetadata>,
  newMetadata: ChatPartnerMetadata
): Dictionary<ChatPartnerMetadata> {
  const existingMetadata = pharmacyChatPartnerMetadataDictionary[newMetadata.chatPartnerId];
  if (!existingMetadata) {
    const metadataToAdd: ChatPartnerMetadata = { ...newMetadata };
    if (metadataToAdd.encryptedChatPartnerNickname) {
      metadataToAdd.chatPartnerNicknameDecryptionStatus = 'encrypted';
    }
    if (metadataToAdd.chatPartnerChatnameHistory) {
      metadataToAdd.chatPartnerChatnameDecryptionStatus = 'encrypted';
    }
    return update(pharmacyChatPartnerMetadataDictionary, {
      [newMetadata.chatPartnerId]: {
        $set: metadataToAdd,
      },
    });
  }
  const metadataUpdate = getUpdatesForMetadata(newMetadata, existingMetadata);
  return update(pharmacyChatPartnerMetadataDictionary, {
    [newMetadata.chatPartnerId]: {
      $merge: metadataUpdate,
    },
  });
}

function getUpdatesForMetadata(newMetadata: ChatPartnerMetadata, existingMetadata: ChatPartnerMetadata) {
  const metadataUpdate: Partial<ChatPartnerMetadata> = {};
  if (!isNil(newMetadata.isSuspended) && newMetadata.isSuspended !== existingMetadata.isSuspended) {
    metadataUpdate.isSuspended = newMetadata.isSuspended;
  }
  if (
    !isNil(newMetadata.isUnlockedForDemoPharmacy) &&
    newMetadata.isUnlockedForDemoPharmacy !== existingMetadata.isUnlockedForDemoPharmacy
  ) {
    metadataUpdate.isUnlockedForDemoPharmacy = newMetadata.isUnlockedForDemoPharmacy;
  }
  if (
    newMetadata.encryptedChatPartnerNickname &&
    newMetadata.encryptedChatPartnerNickname !== existingMetadata.encryptedChatPartnerNickname
  ) {
    metadataUpdate.encryptedChatPartnerNickname = newMetadata.encryptedChatPartnerNickname;
    metadataUpdate.decryptedChatPartnerNickname = undefined;
    metadataUpdate.chatPartnerNicknameDecryptionStatus = 'encrypted';
  }
  if (
    newMetadata.chatPartnerChatnameHistory &&
    newMetadata.chatPartnerChatnameHistory.length !== existingMetadata.chatPartnerChatnameHistory?.length
  ) {
    metadataUpdate.chatPartnerChatnameHistory = newMetadata.chatPartnerChatnameHistory;
    metadataUpdate.decryptedChatPartnerChatname = undefined;
    metadataUpdate.chatPartnerChatnameDecryptionStatus = 'encrypted';
  }
  return metadataUpdate;
}
