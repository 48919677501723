import gql from 'graphql-tag';
import { chatPartnerMetadataAttributes } from '../attributes';

export default gql`
  query getAllChatPartnerMetadataForPharmacy($nextToken: String) {
    getAllChatPartnerMetadataForPharmacy(nextToken: $nextToken) {
      __typename
      chatPartnerMetadata {
        ${chatPartnerMetadataAttributes}
      }
      nextToken
    }
  }
`;
