import { Inject, Injectable } from '@angular/core';
import { MeaConfig } from '../../../../../essentials/types/src/mea-config';
import Message, { DecryptedMessage } from '../../../../../essentials/types/src/message';
import { areSameMedia, DecryptedMessageMedia } from '../../../../../essentials/types/src/messageMedia';
import { Logger } from '../../../../../essentials/util/src/logger';
import { StorageService } from '../storage.service';

export interface StorableMessage {
  timestamp: number;
  decryptedTextContent?: string;
  media?: DecryptedMessageMedia[];
}

interface DeprecatedStorableMessage extends StorableMessage {
  contentType?: string;
  decryptedFileContent?: string;
  decryptedImageContent?: string;
}

export const logger = new Logger('MessageStoreService');

@Injectable({
  providedIn: 'root',
})
export class MessageStoreService {
  constructor(
    @Inject('config') private config: MeaConfig,
    private storageService: StorageService
  ) {
    if (this.isMessageCacheEnabled()) {
      let msg = '::: Message cache is ENABLED';
      if (this.config.msgCache.lifetime) {
        msg += ` ::: ${this.config.msgCache.lifetime}ms lifetime`;
      } else {
        msg += ' ::: infinite lifetime';
      }
      logger.debug(msg);
    } else {
      logger.debug('::: Message cache is DISABLED');
    }
  }

  async getFromStorage<T extends Message>(
    key: string,
    msg: T
  ): Promise<(T & DecryptedMessage & DeprecatedStorableMessage) | null> {
    let storedMessage: StorableMessage | DeprecatedStorableMessage = await this.storageService.get(key);
    if (!storedMessage) {
      const storedMessageJson = localStorage.getItem(key);
      if (!storedMessageJson) {
        return null;
      }

      storedMessage = JSON.parse(storedMessageJson);

      await this.storageService.set(key, storedMessage);
      localStorage.removeItem(key);
    }

    if (
      !this.config.msgCache ||
      !this.config.msgCache.lifetime ||
      this.config.msgCache.lifetime > new Date().getTime() - storedMessage.timestamp
    ) {
      if (storedMessage.media) {
        storedMessage.media = this.addUserOptions(storedMessage, msg);
      }
      return { ...msg, ...storedMessage, decryptionStatus: 'decrypted' };
    } else {
      await this.storageService.remove(key);
      return null;
    }
  }

  async store(storageKey: string, message: DecryptedMessage): Promise<void> {
    if (!this.isMessageCacheEnabled()) {
      logger.debug('::: Message cache is DISABLED, store request will be ignored.');
    } else {
      try {
        await this.storageService.set(storageKey, this.createStorableMessage(message));
      } catch (err) {
        logger.error('Error storing message', err);
      }
    }
  }

  async removeFromStore(storageKey: string) {
    await this.storageService.remove(storageKey);
  }

  async deleteCache(cognitoId: string) {
    await this.storageService.forEach((value: any, key: string) => {
      if (key.startsWith(cognitoId)) {
        this.storageService.remove(key);
      }
    });
  }

  private isMessageCacheEnabled(): boolean {
    return this.config.msgCache && this.config.msgCache.enabled;
  }

  private createStorableMessage = ({ decryptedTextContent, media }: DecryptedMessage): StorableMessage => ({
    timestamp: new Date().getTime(),
    decryptedTextContent,
    media,
  });

  private addUserOptions(storedMessage: StorableMessage | DeprecatedStorableMessage, msg: Message) {
    return storedMessage.media?.map((media) => {
      const updated = msg.media?.find((updatedMedia) => areSameMedia(updatedMedia, media));
      if (updated && updated.mediaType === 'IMAGE') {
        return Object.assign(media, { exifOrientation: updated.exifOrientation });
      }
      return media;
    });
  }
}
