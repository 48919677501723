import { DateRangeOptionCodes } from '../enums/date-range.enum';
import { OrderTypesAllConfig } from './order-types.config';
import { OrderStatusFilterConfig } from './order-status.config';
import { OrderStatusFilter } from '../enums/orders.enum';

export const OrderDefaultFilters = {
    expiryDateOption: DateRangeOptionCodes.all,
    expiryDateFrom: '',
    expiryDateTo: '',
    recTimeOption: DateRangeOptionCodes.all,
    recTimeFrom: '',
    recTimeTo: '',
    type: OrderTypesAllConfig.id,
    status: OrderStatusFilterConfig.find(status => status.id === OrderStatusFilter.ALL).id,
    producer: '',
    pzn: '',
    search: ''
};
