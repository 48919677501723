import gql from 'graphql-tag';
import { endUserAttributes } from '../attributes';

export default gql`
  mutation updateEndUserEncryptionSalt($cognitoId: ID!, $encryptionSalt: String!) {
    updateEndUser(cognitoId: $cognitoId, user: { encryptionSalt: $encryptionSalt }) {
      ${endUserAttributes}
    }
  }
`;
