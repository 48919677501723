import { EmergencyOpeningStatus } from '../../types/src/openingHours';
import {
  EmergencyHoursRange,
  OpeningHoursRange,
  OpeningStatusClass,
  PharmacyDynamicProperties,
  PharmacyOpeningStatus,
} from '../../types/src/pharmacy';
import { assertUnreachable } from './typescript.util';

export class PharmacyOpeningStatusUtil {
  public static getPharmacyOpeningStatus(pharmacy: PharmacyDynamicProperties): PharmacyOpeningStatus {
    if (pharmacy.isOpen && pharmacy.currRange) {
      if (pharmacy.currCloseDiff > 60) {
        return this.getStatusDefaultOpen();
      } else {
        return this.updatePharmacyStatusIfPharmacyIsOpenAndClosesSoon(pharmacy);
      }
    } else if (!pharmacy.isOpen) {
      return this.updatePharmacyStatusIfPharmacyIsNotOpen(pharmacy);
    }
    return this.getStatusDefaultClosed();
  }

  private static updatePharmacyStatusIfPharmacyIsOpenAndClosesSoon(
    pharmacy: PharmacyDynamicProperties
  ): PharmacyOpeningStatus {
    if (!pharmacy.currRange) {
      return this.getStatusDefaultClosed();
    }
    if (pharmacy.nextRange) {
      return this.getStatusClosesAndReopens(this.formatTo(pharmacy.currRange), this.formatFrom(pharmacy.nextRange));
    } else if (pharmacy.emergencyDutyToday && pharmacy.emergencyDutyStartsSoon) {
      return this.updatePharmacyStatusIfPharmacyIsOpenAndEmergencyDutyStartsSoon(pharmacy);
    } else {
      return this.getStatusCloses(this.formatTo(pharmacy.currRange));
    }
  }

  private static updatePharmacyStatusIfPharmacyIsOpenAndEmergencyDutyStartsSoon(
    pharmacy: PharmacyDynamicProperties
  ): PharmacyOpeningStatus {
    if (pharmacy.currRange && pharmacy.emergencyDutyToday) {
      switch (pharmacy.emergencyOpeningStatus) {
        case EmergencyOpeningStatus.DEFAULT:
          return this.getStatusClosesWithEmergencyDuty(
            this.formatTo(pharmacy.currRange),
            this.formatFrom(pharmacy.emergencyDutyToday)
          );
        case EmergencyOpeningStatus.OPEN:
          if (pharmacy.emergencyDutyStartDiff - pharmacy.currCloseDiff <= 0) {
            return this.getStatusDefaultOpen();
          } else {
            return this.getStatusClosesAndReopens(
              this.formatTo(pharmacy.currRange),
              this.formatFrom(pharmacy.emergencyDutyToday)
            );
          }
        case EmergencyOpeningStatus.CLOSED:
          return this.getStatusCloses(this.formatTo(pharmacy.currRange));
        default:
          return assertUnreachable(pharmacy.emergencyOpeningStatus);
      }
    }

    return this.getStatusDefaultClosed();
  }

  private static updatePharmacyStatusIfPharmacyIsNotOpen(pharmacy: PharmacyDynamicProperties): PharmacyOpeningStatus {
    if (pharmacy.isOnVacation) {
      return this.getStatusVacation(pharmacy.vacationEndDate?.format('DD.MM.YYYY'));
    } else if (pharmacy.wasOpen && pharmacy.currRange) {
      return this.getStatusReopens(this.formatFrom(pharmacy.currRange));
    } else if (pharmacy.willOpenSoon && pharmacy.currRange) {
      return this.updatePharmacyStatusIfPharmacyWillOpenSoon(pharmacy);
    } else if (pharmacy.emergencyDutyToday && pharmacy.emergencyDutyStartsSoon) {
      return this.updatePharmacyStatusIfPharmacyIsClosedAndEmergencyDutyStartsSoon(pharmacy);
    } else if (pharmacy.emergencyDutyToday && pharmacy.emergencyDutyEndsSoon) {
      return this.updatePharmacyStatusIfPharmacyIsClosedAndEmergencyDutyEndsSoon(pharmacy);
    } else if (
      pharmacy.emergencyDutyToday &&
      !pharmacy.isOnEmergencyDuty &&
      pharmacy.emergencyOpeningStatus === EmergencyOpeningStatus.OPEN
    ) {
      return this.getStatusReopens(this.formatFrom(pharmacy.emergencyDutyToday));
    } else if (pharmacy.isOnEmergencyDuty) {
      return this.updatePharmacyStatusIfPharmacyIsClosedAndIsOnEmergencyDuty(pharmacy);
    }
    return this.getStatusDefaultClosed();
  }

  private static updatePharmacyStatusIfPharmacyWillOpenSoon(
    pharmacy: PharmacyDynamicProperties
  ): PharmacyOpeningStatus {
    if (pharmacy.currRange) {
      if (pharmacy.isOnEmergencyDuty) {
        switch (pharmacy.emergencyOpeningStatus) {
          case EmergencyOpeningStatus.DEFAULT:
            return this.getStatusEmergencyDutyOpens(this.formatFrom(pharmacy.currRange));
          case EmergencyOpeningStatus.OPEN:
            return this.getStatusDefaultOpen();
          case EmergencyOpeningStatus.CLOSED:
            return this.getStatusOpens(this.formatFrom(pharmacy.currRange));
          default:
            return assertUnreachable(pharmacy.emergencyOpeningStatus);
        }
      } else {
        return this.getStatusOpens(this.formatFrom(pharmacy.currRange));
      }
    }
    return this.getStatusDefaultClosed();
  }

  private static updatePharmacyStatusIfPharmacyIsClosedAndEmergencyDutyStartsSoon(
    pharmacy: PharmacyDynamicProperties
  ): PharmacyOpeningStatus {
    if (pharmacy.emergencyDutyToday) {
      switch (pharmacy.emergencyOpeningStatus) {
        case EmergencyOpeningStatus.DEFAULT:
          return this.getStatusClosedWithEmergencyDuty(this.formatFrom(pharmacy.emergencyDutyToday));
        case EmergencyOpeningStatus.OPEN:
          return this.getStatusReopens(this.formatFrom(pharmacy.emergencyDutyToday));
        case EmergencyOpeningStatus.CLOSED:
          return this.getStatusDefaultClosed();
        default:
          return assertUnreachable(pharmacy.emergencyOpeningStatus);
      }
    }
    return this.getStatusDefaultClosed();
  }

  private static updatePharmacyStatusIfPharmacyIsClosedAndEmergencyDutyEndsSoon(
    pharmacy: PharmacyDynamicProperties
  ): PharmacyOpeningStatus {
    if (pharmacy.emergencyDutyToday) {
      switch (pharmacy.emergencyOpeningStatus) {
        case EmergencyOpeningStatus.DEFAULT:
          return this.getStatusEmergencyDutyCloses(this.formatTo(pharmacy.emergencyDutyToday));
        case EmergencyOpeningStatus.OPEN:
          return this.getStatusCloses(this.formatTo(pharmacy.emergencyDutyToday));
        case EmergencyOpeningStatus.CLOSED:
          return this.getStatusDefaultClosed();
        default:
          return assertUnreachable(pharmacy.emergencyOpeningStatus);
      }
    }
    return this.getStatusDefaultClosed();
  }

  private static updatePharmacyStatusIfPharmacyIsClosedAndIsOnEmergencyDuty(
    pharmacy: PharmacyDynamicProperties
  ): PharmacyOpeningStatus {
    switch (pharmacy.emergencyOpeningStatus) {
      case EmergencyOpeningStatus.DEFAULT:
        return this.getStatusEmergencyDuty();
      case EmergencyOpeningStatus.OPEN:
        return this.getStatusDefaultOpen();
      case EmergencyOpeningStatus.CLOSED:
        return this.getStatusDefaultClosed();
      default:
        return assertUnreachable(pharmacy.emergencyOpeningStatus);
    }
  }

  private static getStatusDefaultOpen(): PharmacyOpeningStatus {
    return { text: 'Geöffnet', class: OpeningStatusClass.Open };
  }

  private static getStatusDefaultClosed(): PharmacyOpeningStatus {
    return { text: 'Geschlossen', class: OpeningStatusClass.Closed };
  }

  private static getStatusEmergencyDuty(): PharmacyOpeningStatus {
    return { text: 'Im Notdienst', class: OpeningStatusClass.Open };
  }

  private static getStatusOpens(openingTime: string): PharmacyOpeningStatus {
    return { text: `Öffnet ${openingTime}`, class: OpeningStatusClass.Closed };
  }

  private static getStatusReopens(reopeningTime: string): PharmacyOpeningStatus {
    return { text: `Öffnet erneut ${reopeningTime}`, class: OpeningStatusClass.Closed };
  }

  private static getStatusCloses(closingTime: string): PharmacyOpeningStatus {
    return { text: `Schließt ${closingTime}`, class: OpeningStatusClass.Open };
  }

  private static getStatusVacation(vacationEndDate?: string): PharmacyOpeningStatus {
    if (vacationEndDate) {
      return { text: `Geschlossen bis ${vacationEndDate}`, class: OpeningStatusClass.Closed };
    }
    return this.getStatusDefaultClosed();
  }

  private static getStatusClosesWithEmergencyDuty(
    closingTime: string,
    emergencyDutyStartingTime: string
  ): PharmacyOpeningStatus {
    return {
      text: `Schließt ${closingTime}, Notdienst ab ${emergencyDutyStartingTime}`,
      class: OpeningStatusClass.Open,
    };
  }

  private static getStatusClosesAndReopens(closingTime: string, reopeningTime: string): PharmacyOpeningStatus {
    return { text: `Schließt ${closingTime}, öffnet erneut ${reopeningTime}`, class: OpeningStatusClass.Open };
  }

  private static getStatusEmergencyDutyOpens(openingTime: string): PharmacyOpeningStatus {
    return { text: `Im Notdienst, öffnet ${openingTime}`, class: OpeningStatusClass.Open };
  }

  private static getStatusEmergencyDutyCloses(closingTime: string): PharmacyOpeningStatus {
    return { text: `Im Notdienst, schließt ${closingTime}`, class: OpeningStatusClass.Open };
  }

  private static getStatusClosedWithEmergencyDuty(emergencyDutyStartingTime: string): PharmacyOpeningStatus {
    return { text: `Geschlossen, Notdienst ab ${emergencyDutyStartingTime}`, class: OpeningStatusClass.Closed };
  }

  private static formatFrom(range: OpeningHoursRange | EmergencyHoursRange) {
    return range.from.format('HH:mm');
  }

  private static formatTo(range: OpeningHoursRange | EmergencyHoursRange) {
    return range.to.format('HH:mm');
  }
}
