<ion-card class="ion-no-margin"
          [class.clickable-animated]="clickable"
          [class.default-scheme]="!colorScheme"
          [style.--background-color]="colorScheme?.mainColor"
          [style.--border-color]="colorScheme?.shadeColor"
          [style.--background-color-footer]="colorScheme?.shadeColor"
          [style.min-height]="useMinSize && minHeight ? minHeight : 'auto'"
          [style.min-width]="useMinSize && minWidth ? minWidth : 'auto'"
>
    <ion-card-header class="ion-no-padding">
        <ion-card-title>
            <img *ngIf="useTitleIcon" [alt]="titleIconAlt" [class]="titleIconClassList" [ngSrc]="titleIconUrl" height="18" width="18"/>
            {{ title }}
            <ion-icon *ngIf="tooltipKey" name="information-circle-outline" appTooltip ttk="{{tooltipKey}}" ttp="bottom" class="widget-info"></ion-icon>
        </ion-card-title>
        <ion-text class="title-extension {{titleExtensionColorClass}}">{{ titleExtension }}</ion-text>
        <ng-content select="[headerActions]"></ng-content>
    </ion-card-header>
    <ion-card-content [class.wider-content]="widerContent" [class.no-padding]="!hasCardContentPadding">
        <ng-content select="[content]"></ng-content>
    </ion-card-content>
    <div *ngIf="hasFooter" class="widget-card-footer" [class.text-right]="footerLabel === 'Mehr'">
        <ion-text *ngIf="footerLabel" [class.font-size-small]="size === smallSize">{{footerLabel}}</ion-text>
        <ng-content select="[footerActions]"></ng-content>
    </div>
</ion-card>
