import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'linksTarget'
})
export class LinksTargetPipe implements PipeTransform {
    transform(value: string): string {
        let output = value;
        if(typeof output === 'string' && output.indexOf('href="http') === -1) {
            output = output.replaceAll('href="', 'href="http://');
        }
        return typeof output === 'string' ? output.replaceAll('<a ', '<a target="_blank" ') : output;
    }
}
