import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class NgxHintStorageService {

    storageKey = 'ngx-hints';


    private static generateHintId(input){
        const len = input.length;
        let hash = 0;
        for (let i = 0; i < len; i++) {
            hash  = ((hash << 5) - hash) + input.charCodeAt(i);
            hash |= 0;
        }
        return hash;
    }


    public setHintViewed(hint) {
        const hintId = NgxHintStorageService.generateHintId(hint);
        this.addViewedHint(hintId);
    }

    public isHintViewed(hint) {
        const hintId = NgxHintStorageService.generateHintId(hint);
        const hints = this.getViewedHints();
        return hints.indexOf(hintId) !== -1;
    }

    private addViewedHint(hintId) {
        const hints = this.getViewedHints();
        hints.push(hintId);
        localStorage.setItem(this.storageKey, JSON.stringify(hints));
    }

    private getViewedHints() {
        const hints = localStorage.getItem(this.storageKey);
        return hints ? JSON.parse(hints): [];
    }
}
