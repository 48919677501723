import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';


@Component({
    selector: 'app-dropdown-list',
    templateUrl: './dropdown-list.component.html',
    styleUrls: ['./dropdown-list.component.scss'],
})

export class DropdownListComponent {
    @Input() listData: Array<string>;
    @Input() showList = false;

    @Output() itemClicked = new EventEmitter<string>();

    constructor() { }

    onItemClicked(item) {
        this.itemClicked.emit(item);
    }

}
