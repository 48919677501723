import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { InformationMutations } from '../store/graphql/mutations/information.graphql';

@Injectable({
    providedIn: 'root',
})

export class NotificationService {

    constructor(
        private informationMutations: InformationMutations
    ) {
    }

    routerSubscription: Subscription;


    /**
     * set notification as read
     */
    setNotificationAsRead(notifications: { id: number }[], isRead: boolean) {
        if (notifications && notifications.length > 0) {
            const notificationIdsForUpsert: Array<{notificationId: number}> = [];
            notifications.forEach((notification) => {
                notificationIdsForUpsert.push({notificationId: notification.id});
            });
            if (notificationIdsForUpsert && notificationIdsForUpsert.length > 0) {
                this.informationMutations.upsertMultipleNotificationReadStatus(notificationIdsForUpsert, isRead);
            }
        }
    }
}
