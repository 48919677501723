import gql from 'graphql-tag';

export default gql`
  mutation setPharmacyImage($imageType: PharmacyImageType!, $base64content: String!, $oldFileId: String) {
    setPharmacyImage(imageUpdate: { imageType: $imageType, base64content: $base64content, oldFileId: $oldFileId }) {
      __typename
      fileId
      image
    }
  }
`;
