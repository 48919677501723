import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import isEqual from 'lodash/isEqual';

@Component({
    selector: 'app-download-preview',
    templateUrl: './download-preview.component.html',
    styleUrls: ['./download-preview.component.scss'],
})
export class DownloadPreviewComponent implements OnChanges {
    @Input() filePreviewUrl: string;
    @Input() title: string;
    @Input() fileExtension: string;
    @Input() color: string;

    imageNotAvailable = false;

    ngOnChanges(changes: SimpleChanges) {
        // Reset imageNotAvailable to false when the input properties change
        const urlChanges = changes.filePreviewUrl;
        if (urlChanges && !isEqual(urlChanges.currentValue, urlChanges.previousValue)) {
            this.imageNotAvailable = false;
        }
    }

    @HostBinding('class') get hostClasses() {
        const classList = [];
        if (!this.filePreviewUrl || this.imageNotAvailable) {
            classList.push('download-placeholder');
        }

        if (this.color) {
            classList.push('border-color-' + this.color);
        }

        return classList;
    }

    /**
     * If the image isn't available, show the placeholder
     */
    onImageLoadError() {
        this.imageNotAvailable = true;
    }
}
