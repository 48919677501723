import { Inject, Injectable } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import isToday from 'dayjs/plugin/isToday';
import weekday from 'dayjs/plugin/weekday';
import { Observable, startWith, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { MeaUser } from '../../../../essentials/types/src/chatUser';
import { CombinedPharmacy } from '../../../../essentials/types/src/combinedPharmacy';
import { MeaConfig } from '../../../../essentials/types/src/mea-config';
import { EmergencyOpeningStatus } from '../../../../essentials/types/src/openingHours';
import {
  EmergencyHoursRange,
  NotdienstEntry,
  OpeningHoursDay,
  OpeningHoursEntry,
  OpeningHoursRange,
  OpeningHoursWeek,
  Pharmacy,
  PharmacyDynamicProperties,
  PharmacyOpeningStatus,
  PharmacyWithChatUser,
  UpcomingEmergencyHours,
  UpcomingVacationRanges,
  VacationRange,
} from '../../../../essentials/types/src/pharmacy';
import { HolidayUtil } from '../../../../essentials/util/src/holiday.util';
import { PharmacyOpeningStatusUtil } from '../../../../essentials/util/src/pharmacy-opening-status.util';

dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(isoWeek);
dayjs.extend(weekday);

@Injectable({
  providedIn: 'root',
})
export class DynamicPropertiesService {
  private readonly shouldCheckForHolidays = this.config.checkForHolidays;

  constructor(@Inject('config') private config: MeaConfig) {}

  public pharmacyOpeningStatus$(pharmacy: CombinedPharmacy | PharmacyWithChatUser): Observable<PharmacyOpeningStatus> {
    const millisecondsUntilNextMinute = (60 - dayjs().second()) * 1000 - dayjs().millisecond();
    return timer(millisecondsUntilNextMinute, 60 * 1000).pipe(
      startWith(0),
      map(() => {
        const dynamicProperties = this.getDynamicProperties(pharmacy);
        return PharmacyOpeningStatusUtil.getPharmacyOpeningStatus(dynamicProperties);
      })
    );
  }

  public getDynamicProperties(pharmacy: CombinedPharmacy | PharmacyWithChatUser): PharmacyDynamicProperties {
    const now = dayjs();
    const openingHoursWeek = this.getOpeningHoursWeek(pharmacy, now);
    const pharmacyChatUser = pharmacy.pharmacyChatUser;

    let isOnHoliday = false;
    if (this.shouldCheckForHolidays) {
      if (pharmacyChatUser?.holidays) {
        isOnHoliday = HolidayUtil.checkForHolidaysWithSettings(pharmacyChatUser.holidays, now);
      } else {
        isOnHoliday = HolidayUtil.checkForHolidays(pharmacy.address.postalCode, now);
      }
    }
    const currentDay = (now.isoWeekday() + 6) % 7;
    const openingHoursToday = openingHoursWeek[currentDay] as OpeningHoursDay;
    const openHour = openingHoursToday.filter((value) => value.isOpen);
    const willOpenHours = openingHoursToday.filter((value) => value.willOpen);
    const wasOpen = openingHoursToday.filter((value) => value.wasOpen).length > 0;

    const { vacationEndDate, isOnVacation, vacationRanges } = this.getVacationProperties(pharmacyChatUser, now);

    const { currCloseDiff, currRange, nextRange, isOpen, willOpenSoon } = this.getCurrentOpeningHourProperties(
      isOnHoliday,
      isOnVacation,
      openHour[0],
      willOpenHours[0],
      now
    );

    const emergencyOpeningStatus = pharmacyChatUser?.emergencyOpeningStatus ?? EmergencyOpeningStatus.DEFAULT;
    const emergencyHoursFormatted: UpcomingEmergencyHours = this.getUpcomingEmergencyHours(pharmacy.notdienst, now);

    const {
      isOnEmergencyDuty,
      emergencyDutyToday,
      emergencyDutyStartsSoon,
      emergencyDutyEndsSoon,
      emergencyDutyStartDiff,
    } = this.getEmergencyDutyProperties(emergencyHoursFormatted, now, currRange);

    return {
      openingHoursFormatted: openingHoursWeek,
      isOpen,
      wasOpen,
      willOpenSoon,
      currCloseDiff,
      currRange,
      nextRange,
      isOnEmergencyDuty,
      emergencyDutyStartsSoon,
      emergencyDutyStartDiff,
      emergencyDutyEndsSoon,
      emergencyDutyToday,
      emergencyHoursFormatted,
      emergencyOpeningStatus,
      isOnHoliday,
      vacationRanges,
      isOnVacation,
      vacationEndDate,
    };
  }

  public getOpeningHoursWeek(pharmacy: CombinedPharmacy | Pharmacy, nowInput?: dayjs.Dayjs) {
    const now = nowInput ?? dayjs();
    const openingHoursWeek: OpeningHoursWeek = this.getOpeningHoursRangeForWeek(pharmacy.openingHours).map((day) => {
      let hadBefore = false;
      return day.map((range) => {
        const isFromBeforeTo = range.from.isBefore(range.to);
        const wasOpenToday = !now.isBefore(range.to) && isFromBeforeTo;
        const willOpenToday = now.isBefore(range.from) && range.from.isToday() && !hadBefore;
        if (willOpenToday) {
          hadBefore = true;
        }
        const _isOpen = isFromBeforeTo ? now.isBetween(range.from, range.to) : !now.isBetween(range.to, range.from);
        const _closeDiff = isFromBeforeTo
          ? Math.abs(Math.floor(now.diff(range.to, 'minute')))
          : 60 * 24 - Math.abs(Math.floor(now.diff(range.to, 'minute')));
        return {
          range,
          wasOpen: wasOpenToday,
          isOpen: _isOpen,
          willOpen: willOpenToday,
          closeDiff: _closeDiff,
        };
      });
    });
    return openingHoursWeek;
  }

  public isOpenAt(pharmacy: Pharmacy, timestamp: number): boolean {
    const timestampAsDayjs = dayjs.unix(timestamp);
    const dayOfTimestamp = timestampAsDayjs.weekday();
    const openingHoursToday: boolean[] = this.getOpeningHoursRangeForDay(pharmacy.openingHours, dayOfTimestamp).map(
      (range) => timestampAsDayjs.isBetween(range.from, range.to)
    );
    return openingHoursToday.filter((isOpen) => isOpen).length > 0;
  }

  private getOpeningHoursRangeForWeek(openingHoursForWeek: string[]): OpeningHoursRange[][] {
    return openingHoursForWeek.map((_, index) => this.getOpeningHoursRangeForDay(openingHoursForWeek, index));
  }

  private getOpeningHoursRangeForDay(openingHours: string[], dayOfWeek: number): OpeningHoursRange[] {
    const dayOutput: OpeningHoursRange[] = [];
    const dayParts = (openingHours[dayOfWeek] as string)
      .replace(/\s/g, '') // remove all spaces
      .split(',');
    for (const item of dayParts) {
      if (item !== 'Closed') {
        const dates = item
          .split('--') // get each time
          .map((time) => {
            let newDayjs = dayjs(time, 'HH:mm');
            newDayjs = newDayjs.isoWeekday(dayOfWeek + 1);
            return newDayjs;
          }); // parse to Dayjs object
        dayOutput.push({
          from: dates[0] as Dayjs,
          to: dates[1] as Dayjs,
        });
      }
    }
    return dayOutput;
  }

  private getUpcomingEmergencyHours(
    notdienst: NotdienstEntry[] | null | undefined,
    now: dayjs.Dayjs
  ): UpcomingEmergencyHours {
    const output: UpcomingEmergencyHours = [];
    if (notdienst) {
      notdienst.forEach((notdienstEntry) => {
        const range: EmergencyHoursRange = {
          from: dayjs(notdienstEntry.von),
          to: dayjs(notdienstEntry.bis),
        };
        if (range.to.isAfter(now)) {
          output.push(range);
        }
      });
    }
    return output;
  }

  private getCurrentOpeningHourProperties(
    isOnHoliday: boolean,
    isOnVacation: boolean,
    firstOpenEntry: OpeningHoursEntry | undefined,
    firstWillOpenEntry: OpeningHoursEntry | undefined,
    now: Dayjs
  ) {
    let currCloseDiff = 0;
    let currRange: OpeningHoursRange | null = null;
    let nextRange: OpeningHoursRange | null = null;
    let isOpen;
    let willOpenSoon;
    if (isOnHoliday || isOnVacation) {
      isOpen = false;
      willOpenSoon = false;
    } else {
      isOpen = !!firstOpenEntry;
      willOpenSoon = false;
      if (firstOpenEntry) {
        currCloseDiff = firstOpenEntry.closeDiff;
        currRange = firstOpenEntry.range;
        if (firstWillOpenEntry) {
          nextRange = firstWillOpenEntry.range;
        }
      }
      if (!firstOpenEntry && firstWillOpenEntry) {
        currRange = firstWillOpenEntry.range;
        willOpenSoon = firstWillOpenEntry.range.from.diff(now, 'minute') <= 60;
      }
    }

    return { currCloseDiff, currRange, nextRange, isOpen, willOpenSoon };
  }

  private getVacationProperties(pharmacyChatUser: MeaUser | undefined, now: Dayjs) {
    let vacationEndDate: Dayjs | null = null;
    let isOnVacation = false;
    const vacationRanges: UpcomingVacationRanges = [];
    if (pharmacyChatUser?.vacation) {
      pharmacyChatUser.vacation.forEach((vacation) => {
        const range: VacationRange = {
          from: dayjs(vacation.from ?? 0),
          to: dayjs(vacation.until),
        };
        if (range.to.isSame(now, 'day') || range.to.isAfter(now)) {
          vacationRanges.push(range);
          if (
            (range.from.isSame(now, 'day') || range.from.isBefore(now)) &&
            (!vacationEndDate || range.to.isAfter(vacationEndDate))
          ) {
            vacationEndDate = range.to;
            isOnVacation = true;
          }
        }
      });
    }
    return { vacationEndDate, isOnVacation, vacationRanges };
  }

  private getEmergencyDutyProperties(
    emergencyHoursFormatted: UpcomingEmergencyHours,
    now: Dayjs,
    currRange: OpeningHoursRange | null
  ) {
    const isOnEmergencyDuty = emergencyHoursFormatted.some(
      (range) => now.isAfter(range.from) && now.isBefore(range.to)
    );
    const emergencyDutyToday =
      emergencyHoursFormatted.find((range) => range.from.isToday() || range.to.isToday()) || null;
    let emergencyDutyStartsSoon = false;
    let emergencyDutyEndsSoon = false;
    let emergencyDutyStartDiff = 0;
    if (emergencyDutyToday) {
      emergencyDutyToday.from = currRange?.to.isBetween(emergencyDutyToday.from, emergencyDutyToday.to)
        ? currRange?.to
        : emergencyDutyToday.from;
      emergencyDutyStartsSoon =
        emergencyDutyToday.from.diff(now, 'minute') <= 60 && now.isBefore(emergencyDutyToday?.from);
      emergencyDutyStartDiff = now.isBefore(emergencyDutyToday?.from) ? emergencyDutyToday.from.diff(now, 'minute') : 0;
      emergencyDutyEndsSoon =
        emergencyDutyToday.to.diff(now, 'minute') <= 60 &&
        now.isAfter(emergencyDutyToday?.from) &&
        now.isBefore(emergencyDutyToday?.to);
    }

    return {
      isOnEmergencyDuty,
      emergencyDutyToday,
      emergencyDutyStartsSoon,
      emergencyDutyEndsSoon,
      emergencyDutyStartDiff,
    };
  }
}
