import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';

import { ReleaseNoteInterface } from '../../../interfaces/release-note.interface';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { today } from '../../../formatting/date.formatting';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { environment } from '../../../../../environments/environment';
import { formatStrapiData } from '../utils';

export const GetReleaseNotes = gql`
   query ReleaseNotes($limit: Int) {
	releaseNotes(
        pagination: {limit: $limit},
        sort: ["date:desc"],
        filters: {
            date: {lte: "${today()}"}
        }) {
        data {
            id
            attributes {
                version
                description
                date
                details {
                    data {
                        id
                        attributes {
                            title
                            description
                            type
                        }
                    }
                }
            }
        }
      }
    }
`;
export const AllReleaseNoteQueries = [
    GetReleaseNotes
];

@Injectable()
export class ReleaseNoteQueries {
    constructor(private apollo: Apollo) {
    }

    public pollReleaseNotes(fetchPolicy: QueryFetchPolicy = QueryFetchPolicy.CACHE_AND_NETWORK): Observable<ReleaseNoteInterface[]> {
        return this.apollo.watchQuery({
                query: GetReleaseNotes,
                variables: {limit: GraphQLLimits.releaseNotes},
                fetchPolicy,
                pollInterval: environment.pollingInterval.releaseNotes
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['releaseNotes'] && d?.data['releaseNotes']['data']))
            .pipe(map((data: Array<ReleaseNoteInterface>) => {
                return data && data[0] ? data.map(entry => {
                    const entryFormatted = formatStrapiData(entry);
                    return {
                        ...entryFormatted,
                        details: entryFormatted?.details?.data ? entryFormatted.details.data.map(detail => formatStrapiData(detail)) : []
                    };
                }) : null;
            })) as Observable<ReleaseNoteInterface[]>;
    }
}
