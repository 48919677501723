import { Pipe, PipeTransform } from '@angular/core';
import { InputValidationInterface } from '../interfaces/input-validation.interface';
import { InputValidationFieldEnum } from '../enums/input-validation.enum';

/**
 * Filters a list of numbers by the given filter (number).
 * Use the limit parameter to splice the amount of returned values.
 *
 * Usage:
 *  ... | filterNumberArray:1235:10
 */
@Pipe({
    name: 'inputValidationMaxLength'
})
export class InputValidationMaxLengthPipe implements PipeTransform {

    transform(field: InputValidationFieldEnum, inputValidators: InputValidationInterface[], round = true): number {
        const inputValidator = inputValidators ? inputValidators.find(validator => validator.field === field) : undefined;
        return (inputValidator && inputValidator.maxLength !== null)
            ? (round ?  Math.floor(inputValidator.maxLength / 5) * 5 :  inputValidator.maxLength)
            : 99999999999999999999995;
    }
}
@Pipe({
    name: 'inputValidationMinLength'
})
export class InputValidationMinLengthPipe implements PipeTransform {

    transform(field: InputValidationFieldEnum, inputValidators: InputValidationInterface[], round = true): number {
        const inputValidator = inputValidators ? inputValidators.find(validator => validator.field === field) : undefined;

        return (inputValidator && inputValidator.minLength !== null)
            ? (round ?  Math.floor(inputValidator.minLength / 5) * 5 :  inputValidator.minLength)
            : 0;
    }
}
@Pipe({
    name: 'inputValidationRequired'
})
export class InputValidationRequiredPipe implements PipeTransform {

    transform(field: InputValidationFieldEnum, inputValidators: InputValidationInterface[]): boolean {
        const inputValidator = inputValidators ? inputValidators.find(validator => validator.field === field) : undefined;
        return inputValidator && inputValidator.isNullable === false;
    }
}
