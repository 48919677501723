import {
    Component,
    Input
} from '@angular/core';
import { ComponentRef } from '@ionic/core';
import {
    BadgeTypeEnum
} from '../../core.enums';
import { FilterResultInterface, FilterResultItemInterface } from '../../interfaces/filter-result.interface';
import { FilterService } from '../../services/filter.service';
import { TableTypeEnum } from '../../enums/table-type.enum';

@Component({
    selector: 'app-table-old-no-results',
    templateUrl: './table-no-results.component.html',
    styleUrls: ['./table-no-results.component.scss']
})
export class TableNoResultsComponent {
    @Input() isLoading = {data: true, count: true};
    @Input() count = 0;
    @Input() statusErrorMessage: string;
    @Input() noResultInformation: FilterResultInterface;
    @Input() noResultDateInformation: FilterResultItemInterface;
    @Input() filters: object;
    @Input() badgeType = BadgeTypeEnum.FILTER_RESULT;
    @Input() searchInProgress: boolean;
    @Input() type: TableTypeEnum;
    @Input() searchModalComponent: ComponentRef;


    constructor(private filterService: FilterService) {
    }


    updateDateFilter(event, confId) {
        this.filterService.init(this.type);
        this.filterService.updateDateFilter(event, confId, this.filters);
    }

    /**
     * User has pressed the create disposition request icon. Show modal window
     *
     * @param focusOnSearch - Should the search input be focused?
     */
    async openSearchModal(focusOnSearch: boolean) {
        this.filterService.init(this.type);
        await this.filterService.openSearchModal(focusOnSearch, this.filters['search'], this.searchModalComponent);
    }

    /**
     * track by
     *
     * @param index - Index of the item in the array
     * @param item - The item to track
     */
    trackBy(index, item) {
        return item.id;
    }
}
