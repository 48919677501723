import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { from, map } from 'rxjs';
import {
    PharmaciesInterface,
    SSOPermissionsInterface,
    SSOUserInterface,
    UserAdministrationInterface
} from '../../../interfaces/user-administration.interface';
import { UserAccessModulesEnum } from '../../../enums/user-administration.enum';
import { ApiService } from '../../../services/api.service';
import { UserAdministrationService } from '../../../services/user-administration.service';

export const CreateSSOUser = gql`
    mutation CreateSSOUser($user: CreateInputSSOUser!) {
        createSSOUser(user: $user)
    }
`;

export const UpdateSSOUser = gql`
    mutation UpdateSSOUser($user: UpdateInputSSOUser!) {
        updateSSOUser(user: $user)
    }
`;

export const DeleteSSOUser = gql`
    mutation DeleteSSOUser($id: String!) {
        deleteSSOUser(id: $id)
    }
`;

export const AllUserAdministrationMutations = [
    CreateSSOUser,
    UpdateSSOUser,
    DeleteSSOUser
];

@Injectable()
export class UserAdministrationMutations {
    constructor(
        private apollo: Apollo,
        private apiService: ApiService,
        private userAdministrationService: UserAdministrationService
    ) {
    }

    public upsertUser(isEditUser, newUser: UserAdministrationInterface, pharmacies: PharmaciesInterface[]) {
        let ssoUser: SSOUserInterface = {
            email: newUser.email,
            salutation: newUser.salutation,
            title: newUser.title,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            enabled: true,
            sconnect: this.mapAccessRights(pharmacies, UserAccessModulesEnum.SCONNECT),
            chat: this.mapAccessRights(pharmacies, UserAccessModulesEnum.CHAT),
            shop: this.mapAccessRights(pharmacies, UserAccessModulesEnum.SHOP),
            sacademy: this.mapAccessRights(pharmacies, UserAccessModulesEnum.SACADEMY),
            sanavendi: this.mapAccessRights(pharmacies, UserAccessModulesEnum.SANAVENDI)
        };
        let mutation = CreateSSOUser;
        let pipe = 'createSSOUser';
        let successMessage = 'Nutzer wurde erfolgreich erstellt.';
        let errorMessage = 'Der Nutzer konnte nicht erstellt werden.';

        if (isEditUser) {
            ssoUser = {
                id: newUser.id.toString(),
                ...ssoUser
            };
            mutation = UpdateSSOUser;
            pipe = 'updateSSOUser';
            successMessage = 'Nutzer wurde erfolgreich bearbeitet.';
            errorMessage = 'Die Änderungen an dem Nutzer konnten leider nicht vorgenommen werden.';
        }

        return this.apollo.mutate({
            mutation,
            variables: {user: ssoUser}
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data[pipe]))
                  .subscribe((status: string) => {
                      if (!status || status === 'ERROR' || !!result['errors']) {
                          void this.apiService.presentErrorToast(result['errors'], errorMessage);
                          this.userAdministrationService.userDataSetLoading.next(false);
                      } else {
                          void this.apiService.presentSuccessToast(successMessage);
                          this.userAdministrationService.userDataChanged.next(true);
                      }
                  }).unsubscribe();
            },
            error: error => {
                from([error])
                  .pipe(map(d => d && d['message']))
                  .subscribe(message => {
                      this.displayErrorMessage(errorMessage, error, message, ssoUser);
                  }).unsubscribe();
            }
        });
    }

    public deleteSingleUser(userid) {
        return this.apollo.mutate({
            mutation: DeleteSSOUser,
            variables: {id: userid}
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['deleteSSOUser']))
                  .subscribe((status: string) => {
                      if (!status || status === 'ERROR' || !!result['errors']) {
                          void this.apiService
                            .presentErrorToast(result['errors'], 'Der Nutzer konnte nicht gelöscht werden.');
                          this.userAdministrationService.userDataSetLoading.next(false);
                      } else {
                          void this.apiService.presentSuccessToast('Nutzer wurde erfolgreich gelöscht.');
                          this.userAdministrationService.userDataChanged.next(true);
                      }
                  }).unsubscribe();
            },
            error: error => {
                from([error])
                  .pipe(map(d => d && d['message']))
                  .subscribe(message => {
                      this.displayErrorMessage('Der Nutzer konnte nicht gelöscht werden.', error, message);
                  }).unsubscribe();
            },
        });
    }


    /**
     * This function maps the pharmacies object from the app to an SSOPermissions object.
     *
     * @param pharmacies Array<PharmaciesInterface>
     * @param moduleName UserAccessModulesEnum
     * @private
     */
    private mapAccessRights(pharmacies: Array<PharmaciesInterface>, moduleName: UserAccessModulesEnum): Array<SSOPermissionsInterface> {
        const ssoPermission: Array<SSOPermissionsInterface> = [];
        pharmacies.forEach(pharmacy => {
            if (pharmacy[moduleName] && pharmacy[moduleName].length > 0) {
                ssoPermission.push({
                    pharmacyId: pharmacy.apiUser,
                    permissions: pharmacy[moduleName]
                });
            }
        });
        return ssoPermission;
    }

    private displayErrorMessage(defaultMessage, error, message, payload = {firstName: '', lastName: ''}) {
        let errorMessage = defaultMessage;
        switch (message) {
            case '400: Bad Request':
            case '401: ':
            case '500: Internal Server Error':
                break;
            case '403: Forbidden':
                errorMessage = 'Der Nutzer hat keine Berechtigung diese Aktion auszuführen. Nutzerdaten und Zugriffsrechte können ausschließlich vom Inhaber gepflegt werden.';
                break;
            case '404: Not Found':
                errorMessage = 'Der Nutzer ist bereits gelöscht/existiert nicht mehr';
                break;
            case '409: Conflict':
                errorMessage = `Die E-Mail-Adresse zum Nutzer ${payload.lastName}, ${payload.firstName} kann nicht gespeichert werden. Bitte verwenden Sie eine andere E-Mail-Adresse.`;
                break;
            case '424: Failed Dependency':
                errorMessage = 'Es gab eine Problem bei der Erstellung des Nutzers. ' +
                    'Bitte versuchen Sie es in einigen Minuten erneut.';
                break;
        }
        void this.apiService.presentErrorToast(error, errorMessage);
        this.userAdministrationService.userDataSetLoading.next(false);
    }
}
