import { Pipe, PipeTransform } from '@angular/core';
import { TableSidebarItemsInterface } from '../interfaces/table.interface';

/**
 * Filters a list of strings by the given filter (string).
 * Use the limit parameter to splice the amount of returned values.
 * The changedIndicator is used to trigger the pipe when the filter changes.
 *
 * Usage:
 *  ... | filterStringArray:test:10
 */
@Pipe({
    name: 'filterVisibleTableItems'
})
export class FilterVisibleTableItemsPipe implements PipeTransform {

    transform(items: TableSidebarItemsInterface[]): TableSidebarItemsInterface[] {
        if(!items) {
            return items;
        }
        return items.filter(item => item.isVisible === undefined || item.isVisible);
    }
}
