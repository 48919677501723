import { Renderer2, Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ScriptService {

    constructor(
        @Inject(DOCUMENT) private document: Document
    ) { }

    /**
     * Append the JS tag to the Document Body.
     * @param renderer The Angular Renderer
     * @param src The path to the script
     * @returns the script element
     */
    public loadJsScript(renderer: Renderer2, src: string): HTMLScriptElement {
        // check that script doesn't already exist
        const scripts = this.document.getElementsByTagName('script');
        for (let i = 0; i < scripts.length; i++) {
            if (scripts[i].src.includes(src)) {
                return scripts[i];
            }
        }

        const script = renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        renderer.appendChild(this.document.body, script);
        return script;
    }

    public unloadJsScript(src: string) {
        const scripts = this.document.getElementsByTagName('script');
        for (let i = 0; i < scripts.length; i++) {
            if (scripts[i].src.includes(src)) {
                scripts[i].parentNode.removeChild(scripts[i]);
            }
        }
    }
}
