import { UserAgent } from '@adeprez/capacitor-user-agent';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CapacitorHttp } from '@capacitor/core';
import { firstValueFrom, from, Observable } from 'rxjs';
import { map, retry, timeout } from 'rxjs/operators';
import { MeaConfig } from '../../../../essentials/types/src/mea-config';
import { AvailabilityRequestProduct, AvailabilityResponse } from '../../../../essentials/types/src/shopAvailability';
import { ShopProduct, ShopProductDetails } from '../../../../essentials/types/src/shopProduct';
import { ShopProductUtil } from '../../../../essentials/util/src/shop-product.util';

@Injectable({
  providedIn: 'root',
})
export class ShopApiService {
  private readonly isNativeApp = this.config.featureFlags.isNativeApp;
  private readonly numberOfRetries = 2;
  private readonly requestTimeout = 5000;

  constructor(
    @Inject('config') private config: MeaConfig,
    private http: HttpClient
  ) {}

  public async getFeaturedProducts(shopApiBaseUrl: string) {
    const featuredProducts = await this.getData<{ products: ShopProduct[] }>(`${shopApiBaseUrl}api/product/featured`);
    return featuredProducts.products.map((product) => ShopProductUtil.mapShopProductToProduct(product));
  }

  public async getProductDetails(shopApiBaseUrl: string, pzn: string) {
    const shopProductDetails = await this.getData<ShopProductDetails>(`${shopApiBaseUrl}api/product/${pzn}`);
    return ShopProductUtil.mapShopProductDetailsToProductDetails(shopProductDetails);
  }

  public async getProductAvailability(shopApiBaseUrl: string, requestedProducts: AvailabilityRequestProduct[]) {
    return (
      await this.postData<AvailabilityResponse>(`${shopApiBaseUrl}api/product/availability`, {
        products: requestedProducts,
      })
    ).products;
  }

  public async searchForProducts(shopApiBaseUrl: string, params: { term: string; limit: string }) {
    const shopProducts = await this.getData<{ products: ShopProduct[] }>(`${shopApiBaseUrl}api/product/search`, params);
    return shopProducts.products.map((product) => ShopProductUtil.mapShopProductToProduct(product));
  }

  private async getData<T>(url: string, params?: { [key: string]: string }) {
    let dataSource: Observable<T>;
    if (this.isNativeApp) {
      const userAgent = await this.getUserAgent();
      dataSource = from(CapacitorHttp.get({ url, headers: { 'User-Agent': userAgent }, params })).pipe(
        map((response) => response.data as T)
      );
    } else {
      dataSource = this.http.get<T>(url, { params });
    }
    return firstValueFrom(dataSource.pipe(retry(this.numberOfRetries), timeout(this.requestTimeout)));
  }

  private async postData<T>(url: string, data?: any, params?: { [key: string]: string }) {
    let dataSource: Observable<T>;
    if (this.isNativeApp) {
      const userAgent = await this.getUserAgent();
      dataSource = from(
        CapacitorHttp.post({
          url,
          data,
          headers: { 'Content-Type': 'application/json', 'User-Agent': userAgent },
          params,
        })
      ).pipe(map((response) => response.data as T));
    } else {
      dataSource = this.http.post<T>(url, data, { params });
    }
    return firstValueFrom(dataSource.pipe(retry(this.numberOfRetries), timeout(this.requestTimeout)));
  }

  private async getUserAgent() {
    return (await UserAgent.get()).userAgent;
  }
}
