import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';
import { LoadingStatusType } from '../state/loading-status.state';

export const setLoadingStatus = createAction(
  `${ActionType.UiUx}: Set loading status`,
  props<{ requestKey: LoadingStatusType; message: string }>()
);
export const removeLoadingStatus = createAction(
  `${ActionType.UiUx}: Remove loading status`,
  props<{ requestKey: LoadingStatusType }>()
);
