export enum NavigationEnum {
    dashboard,
    meaChat,    // TODO remove with feature flag
    mea,
    logistics,
    offers,
    seminars,
    calendar,
    statistics,
    knowledge,
    settings,
    releaseNotes,
    information,
    news,
    sanavendi,
    communications,
    releaseHistory
}
