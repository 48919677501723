import gql from 'graphql-tag';
import { chatPartnerMetadataAttributes } from '../attributes';

export default gql`
    mutation updateChatPartnerMetadata($chatPartnerId: ID!, $chatPartnerMetadata: ChatPartnerMetadataInput) {
        updateChatPartnerMetadata(chatPartnerId: $chatPartnerId, chatPartnerMetadata: $chatPartnerMetadata) {
            ${chatPartnerMetadataAttributes}
        }
    }
`;
