<ng-container *ngIf="useReactiveFilters; else nonReactive">
    <ng-container *ngIf="dateFilter() as dateFilter">
        <ng-container *ngIf="displayButtonInsteadOfDateRange; else defaultDateTemplate">
            <ion-button (click)="openDateRangePicker(dateFilter)" i18n>{{title ? title : 'Zeitraum für Belegdatum anpassen'}}</ion-button>
        </ng-container>
        <ng-template #defaultDateTemplate>
            <ion-text class="filter-label">{{title ?? dateFilter.label}}</ion-text>
            <ion-text *ngIf="dateFilter?.selectedValues.dateOption !== individualDateRange; else individual"
                      class="filter-text"
                      [class.disabled]="readonly"
                      (click)="openDateRangePicker(dateFilter)">
                {{dateFilter?.selectedValues?.dateOption | dateRangeToLabel}}
                <ion-icon *ngIf="!readonly" name="caret-down-sharp"></ion-icon>
            </ion-text>
            <ng-template #individual>
                <ion-text *ngIf="dateFilter?.selectedValues.dateOption === individualDateRange"
                          class="filter-text"
                          [class.disabled]="readonly"
                          (click)="openDateRangePicker(dateFilter)">
                    {{dateFilter?.selectedValues?.dateFrom | dateTimeToDate : false}}
                    {{dateFilter?.selectedValues?.dateTo ? ' - ' + (dateFilter?.selectedValues?.dateTo | dateTimeToDate : false) : ''}}
                    <ion-icon *ngIf="!readonly" name="caret-down-sharp"></ion-icon>
                </ion-text>
            </ng-template>
        </ng-template>
    </ng-container>
</ng-container>
<ng-template #nonReactive>
    <div *ngIf="selectedDateRange && !displayButtonInsteadOfDateRange" [class.disabled]="readonly" class="widget-container">
        <ion-text i18n class="filter-label" *ngIf="title && title.length > 0">{{title}}</ion-text>
        <div (click)="openDatePickerRange()">
            <ion-text class="filter-text" [class.disabled]="readonly"  *ngIf="!displayDatesInsteadOfDateRange && selectedDateRange.id !== individualDateRange">
                {{selectedDateRange.title}} <ion-icon *ngIf="!readonly" name="caret-down-sharp"></ion-icon>
            </ion-text>
            <ion-text class="filter-text" [class.disabled]="readonly" *ngIf="!fromDateEqualsToDate && (displayDatesInsteadOfDateRange || selectedDateRange.id === individualDateRange)">
                {{fromDate ? (fromDate | dateTimeToDate : false) : null}} - {{toDate ? (toDate | dateTimeToDate : false) : null}}
                <ion-icon *ngIf="!readonly" name="caret-down-sharp"></ion-icon>
            </ion-text>
            <ion-text class="filter-text" [class.disabled]="readonly" *ngIf="fromDateEqualsToDate && (displayDatesInsteadOfDateRange || selectedDateRange.id === individualDateRange)">
                {{fromDate ? (fromDate | dateTimeToDate : false) : null}}
                <ion-icon *ngIf="!readonly" name="caret-down-sharp"></ion-icon>
            </ion-text>
            <ion-text class="filter-text" [class.disabled]="readonly" *ngIf="displayTime">
                {{fromTime}} - {{toTime}} <ion-icon *ngIf="!readonly" name="caret-down-sharp"></ion-icon>
            </ion-text>
        </div>
    </div>

    <ion-button *ngIf="selectedDateRange && displayButtonInsteadOfDateRange" (click)="openDatePickerRange()" i18n>Zeitraum für Belegdatum anpassen</ion-button>
</ng-template>
