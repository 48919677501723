import { Md5 } from 'ts-md5';

/**
 * @param rawPassword - the password to be hashed
 * @returns string
 */
export const hash = (rawPassword) => {
    return Md5.hashStr(rawPassword).toString();
};

/**
 * @param rawPassword - the raw password
 * @param hashedPassword - the hashed password
 * @returns boolean
 */
export const compareHash = (rawPassword, hashedPassword) => {
    try {
        const hashedRawPassword = hash(rawPassword);
        return hashedPassword === hashedRawPassword;
    } catch (error) {
        throw Error(error.message);
    }
};
