import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AppointmentButtonClickResponseInterface, AppointmentInterface } from '../../core.interfaces';
import { ButtonTypes } from '../../enums/button-actions.enum';

@Component({
    selector: 'app-appointment-badge',
    templateUrl: './appointment-badge.component.html',
    styleUrls: ['./appointment-badge.component.scss'],
})
export class AppointmentBadgeComponent implements OnInit {

    @Input() appointment: AppointmentInterface;
    @Input() showActions = false;
    @Input() showAppointmentTitle = false;
    @Input() isSeminar = false;
    @Output() buttonClickEvent = new EventEmitter<AppointmentButtonClickResponseInterface>();

    speaker = '';

    constructor() {
    }

    ngOnInit() {
        if (this.appointment) {
            if (this.appointment.seminar && this.appointment.seminar.speaker) {
                this.speaker = this.appointment.seminar.speaker;
                this.isSeminar = true;
            }
        }
    }

    /**
     * User want to participate in appointment
     *
     * @param event : any
     */
    async onAcceptAppointmentClick(event) {
        await this.onButtonClick(event, ButtonTypes.ACCEPT);
    }

    /**
     * User want to withdraw his registration or not to participate in appointment
     *
     * @param event : any
     */
    async onCancelAppointmentClick(event) {
        await this.onButtonClick(event, ButtonTypes.CANCEL);

    }

    /**
     * User has pressed a appointment item
     *
     * @param event : any
     */
    async onAppointmentClick(event) {
        await this.onButtonClick(event, ButtonTypes.MODAL);
    }

    /**
     * User has pressed a appointment button
     *
     * @param event : any
     * @param buttonCode : ButtonTypes
     */
    async onButtonClick(event, buttonCode: ButtonTypes) {
        const eventParameters: AppointmentButtonClickResponseInterface = {
            button: {
                code: buttonCode,
                event
            },
            appointment: this.appointment
        };
        this.buttonClickEvent.emit(eventParameters);
    }
}
