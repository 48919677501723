<ion-content>
    <app-widget i18n-title title="" [displayHeader]="false" [removeMarginTop]="true"
                [noPadding]="true" [scrollableOverflow]="true" [class.is-loading]="isLoading">
        <ng-container content *ngIf="!isLoading && item">
            <div class="content">
                <div class="top-bar" *ngIf="topBarTmpl">
                    <ng-container *ngTemplateOutlet="topBarTmpl; context: { $implicit: item }"></ng-container>
                </div>
                <div class="title">
                    <ion-text>
                        <ng-container *ngTemplateOutlet="titleTmpl ? titleTmpl : defaultTitleTmpl; context: { $implicit: item }"></ng-container>
                        <ng-template #defaultTitleTmpl let-item>{{item.title}}</ng-template>
                    </ion-text>
                </div>
                <div class="info" *ngIf="infoTmpl">
                    <ng-container *ngTemplateOutlet="infoTmpl; context: { $implicit: item }"></ng-container>
                </div>
                <ng-container *ngTemplateOutlet="contentTmpl ? contentTmpl : defaultContentTmpl; context: { $implicit: item }"></ng-container>
                <ng-template #defaultContentTmpl let-item>
                    <ion-text class="description" *ngIf="item.content" [innerHTML]="item.content | linksTarget"></ion-text>
                </ng-template>
            </div>

            <div class="action-bar" *ngIf="actionBarTmpl">
                <ng-container *ngTemplateOutlet="actionBarTmpl; context: { $implicit: item }"></ng-container>
            </div>
        </ng-container>
        <ng-container content *ngIf="isLoading">
            <ion-spinner name="crescent" color="primary" class="loader"></ion-spinner>
        </ng-container>
        <ng-container content *ngIf="!isLoading && !item">
            <div class="display-flex full-height ion-justify-content-center ion-align-items-center no-result">
                <ion-text class="h2" i18n color="medium">Bitte wählen Sie einen Eintrag aus der Liste links.</ion-text>
            </div>
        </ng-container>
    </app-widget>
</ion-content>
