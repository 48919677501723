import { BackendConversationAttachments } from './backendConversation';
import ConversationType from './conversationType';
import DecryptionStatus from './decryptionStatus';
import { Author } from './messageAuthor';
import MessageMedia, { DecryptedMessageMedia } from './messageMedia';

interface Message {
  // Generated id for a message -- read-only
  id: string;
  // Id of the sender
  senderId?: string | null;
  // Id of the recipient
  recipientId?: string | null;
  //  The id of the Conversation this message belongs to. This is the table primary key.
  conversationId: string;
  // The content of the message encrypted using the public key of the sender this allows to display the message on the users screen
  encryptedTextContent?: string | null;

  decryptedTextContent?: string;

  // Flag denoting if the message was read by the recipient.
  readByRecipient?: boolean | null;
  //  The message timestamp. This is also the table sort key.
  createdAt: number;
  // The timestamp of when the message was read on the client side
  readByRecipientAt?: number | null;
  // Flag denoting if the message is displayed as unread for the sender
  displayOwnAsUnread?: boolean | null;

  media?: MessageMedia[];
  displayAsPharmacyMessage?: boolean;

  automatic?: boolean;
  initialMessage?: boolean;

  decryptionStatus: DecryptionStatus;

  isDeleted?: boolean | null;
  isWelcomeMessage?: boolean | null;

  type?: ConversationType;

  // message author displayed in the chat
  author?: Author;

  conversation: BackendConversationAttachments;
}

export interface MessageWithMedia extends Message {
  media: MessageMedia[];
}
export const messageHasMedia = (message: Message): message is MessageWithMedia =>
  !!(message.media && message.media.length > 0);

export interface DecryptedMessage extends Message {
  decryptionStatus: 'decrypted' | 'failed';
  media?: DecryptedMessageMedia[];
}

export interface DecryptedMessageWithMedia extends DecryptedMessage {
  media: DecryptedMessageMedia[];
}

export const isSameMessage = (message1: Message, message2: Message): boolean => message1.id === message2.id;

export const deletedMessageReplacementText = '<em>Nachricht gelöscht</em>';

export default Message;
