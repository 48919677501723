import gql from 'graphql-tag';
import { messageAttributes } from '../attributes';

export default gql`
  query getConversationMessages($conversationId: ID!, $limit: Int, $nextToken: String) {
    getMessagesByConversation(conversationId: $conversationId, limit: $limit, nextToken: $nextToken) {
      __typename
      messages {
        ${messageAttributes}
      }
      nextToken
    }
  }
`;
