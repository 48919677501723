import { FederalStates } from './openingHours';

export const postalCodeRecords: { [key: string]: FederalStates } = {
  // first entry for dev pharmacies
  '12345': FederalStates.BE,
  '10115': FederalStates.BE,
  '10117': FederalStates.BE,
  '10119': FederalStates.BE,
  '10178': FederalStates.BE,
  '10179': FederalStates.BE,
  '10243': FederalStates.BE,
  '10245': FederalStates.BE,
  '10247': FederalStates.BE,
  '10249': FederalStates.BE,
  '10315': FederalStates.BE,
  '10317': FederalStates.BE,
  '10318': FederalStates.BE,
  '10319': FederalStates.BE,
  '10365': FederalStates.BE,
  '10367': FederalStates.BE,
  '10369': FederalStates.BE,
  '10405': FederalStates.BE,
  '10407': FederalStates.BE,
  '10409': FederalStates.BE,
  '10435': FederalStates.BE,
  '10437': FederalStates.BE,
  '10439': FederalStates.BE,
  '10551': FederalStates.BE,
  '10553': FederalStates.BE,
  '10555': FederalStates.BE,
  '10557': FederalStates.BE,
  '10559': FederalStates.BE,
  '10585': FederalStates.BE,
  '10587': FederalStates.BE,
  '10589': FederalStates.BE,
  '10623': FederalStates.BE,
  '10625': FederalStates.BE,
  '10627': FederalStates.BE,
  '10629': FederalStates.BE,
  '10707': FederalStates.BE,
  '10709': FederalStates.BE,
  '10711': FederalStates.BE,
  '10713': FederalStates.BE,
  '10715': FederalStates.BE,
  '10717': FederalStates.BE,
  '10719': FederalStates.BE,
  '10777': FederalStates.BE,
  '10779': FederalStates.BE,
  '10781': FederalStates.BE,
  '10783': FederalStates.BE,
  '10785': FederalStates.BE,
  '10787': FederalStates.BE,
  '10789': FederalStates.BE,
  '10823': FederalStates.BE,
  '10825': FederalStates.BE,
  '10827': FederalStates.BE,
  '10829': FederalStates.BE,
  '10961': FederalStates.BE,
  '10963': FederalStates.BE,
  '10965': FederalStates.BE,
  '10967': FederalStates.BE,
  '10969': FederalStates.BE,
  '10997': FederalStates.BE,
  '10999': FederalStates.BE,
  '11011': FederalStates.BE,
  '12043': FederalStates.BE,
  '12045': FederalStates.BE,
  '12047': FederalStates.BE,
  '12049': FederalStates.BE,
  '12051': FederalStates.BE,
  '12053': FederalStates.BE,
  '12055': FederalStates.BE,
  '12057': FederalStates.BE,
  '12059': FederalStates.BE,
  '12099': FederalStates.BE,
  '12101': FederalStates.BE,
  '12103': FederalStates.BE,
  '12105': FederalStates.BE,
  '12107': FederalStates.BE,
  '12109': FederalStates.BE,
  '12157': FederalStates.BE,
  '12159': FederalStates.BE,
  '12161': FederalStates.BE,
  '12163': FederalStates.BE,
  '12165': FederalStates.BE,
  '12167': FederalStates.BE,
  '12169': FederalStates.BE,
  '12203': FederalStates.BE,
  '12205': FederalStates.BE,
  '12207': FederalStates.BE,
  '12209': FederalStates.BE,
  '12247': FederalStates.BE,
  '12249': FederalStates.BE,
  '12277': FederalStates.BE,
  '12279': FederalStates.BE,
  '12305': FederalStates.BE,
  '12307': FederalStates.BE,
  '12309': FederalStates.BE,
  '12347': FederalStates.BE,
  '12349': FederalStates.BE,
  '12351': FederalStates.BE,
  '12353': FederalStates.BE,
  '12355': FederalStates.BE,
  '12357': FederalStates.BE,
  '12359': FederalStates.BE,
  '12435': FederalStates.BE,
  '12437': FederalStates.BE,
  '12439': FederalStates.BE,
  '12459': FederalStates.BE,
  '12487': FederalStates.BE,
  '12489': FederalStates.BE,
  '12524': FederalStates.BE,
  '12526': FederalStates.BE,
  '12527': FederalStates.BE,
  '12529': FederalStates.BE,
  '12555': FederalStates.BE,
  '12557': FederalStates.BE,
  '12559': FederalStates.BE,
  '12587': FederalStates.BE,
  '12589': FederalStates.BE,
  '12619': FederalStates.BE,
  '12621': FederalStates.BE,
  '12623': FederalStates.BE,
  '12627': FederalStates.BE,
  '12629': FederalStates.BE,
  '12679': FederalStates.BE,
  '12681': FederalStates.BE,
  '12683': FederalStates.BE,
  '12685': FederalStates.BE,
  '12687': FederalStates.BE,
  '12689': FederalStates.BE,
  '13047': FederalStates.BE,
  '13051': FederalStates.BE,
  '13053': FederalStates.BE,
  '13055': FederalStates.BE,
  '13057': FederalStates.BE,
  '13059': FederalStates.BE,
  '13086': FederalStates.BE,
  '13088': FederalStates.BE,
  '13089': FederalStates.BE,
  '13125': FederalStates.BE,
  '13127': FederalStates.BE,
  '13129': FederalStates.BE,
  '13156': FederalStates.BE,
  '13158': FederalStates.BE,
  '13159': FederalStates.BE,
  '13187': FederalStates.BE,
  '13189': FederalStates.BE,
  '13347': FederalStates.BE,
  '13349': FederalStates.BE,
  '13351': FederalStates.BE,
  '13353': FederalStates.BE,
  '13355': FederalStates.BE,
  '13357': FederalStates.BE,
  '13359': FederalStates.BE,
  '13403': FederalStates.BE,
  '13405': FederalStates.BE,
  '13407': FederalStates.BE,
  '13409': FederalStates.BE,
  '13435': FederalStates.BE,
  '13437': FederalStates.BE,
  '13439': FederalStates.BE,
  '13465': FederalStates.BE,
  '13467': FederalStates.BE,
  '13469': FederalStates.BE,
  '13503': FederalStates.BE,
  '13505': FederalStates.BE,
  '13507': FederalStates.BE,
  '13509': FederalStates.BE,
  '13581': FederalStates.BE,
  '13583': FederalStates.BE,
  '13585': FederalStates.BE,
  '13587': FederalStates.BE,
  '13589': FederalStates.BE,
  '13591': FederalStates.BE,
  '13593': FederalStates.BE,
  '13595': FederalStates.BE,
  '13597': FederalStates.BE,
  '13599': FederalStates.BE,
  '13627': FederalStates.BE,
  '13629': FederalStates.BE,
  '14050': FederalStates.BE,
  '14052': FederalStates.BE,
  '14053': FederalStates.BE,
  '14055': FederalStates.BE,
  '14057': FederalStates.BE,
  '14059': FederalStates.BE,
  '14089': FederalStates.BE,
  '14109': FederalStates.BE,
  '14129': FederalStates.BE,
  '14131': FederalStates.BE,
  '14163': FederalStates.BE,
  '14165': FederalStates.BE,
  '14167': FederalStates.BE,
  '14169': FederalStates.BE,
  '14193': FederalStates.BE,
  '14195': FederalStates.BE,
  '14197': FederalStates.BE,
  '14199': FederalStates.BE,
  '14461': FederalStates.BB,
  '14467': FederalStates.BB,
  '14469': FederalStates.BB,
  '14471': FederalStates.BB,
  '14473': FederalStates.BB,
  '14476': FederalStates.BB,
  '14478': FederalStates.BB,
  '14480': FederalStates.BB,
  '14482': FederalStates.BB,
  '14513': FederalStates.BB,
  '14532': FederalStates.BB,
  '14542': FederalStates.BB,
  '14547': FederalStates.BB,
  '14548': FederalStates.BB,
  '14550': FederalStates.BB,
  '14552': FederalStates.BB,
  '14554': FederalStates.BB,
  '14557': FederalStates.BB,
  '14558': FederalStates.BB,
  '14612': FederalStates.BB,
  '14621': FederalStates.BB,
  '14624': FederalStates.BB,
  '14627': FederalStates.BB,
  '14641': FederalStates.BB,
  '14656': FederalStates.BB,
  '14662': FederalStates.BB,
  '14669': FederalStates.BB,
  '14712': FederalStates.BB,
  '14715': FederalStates.BB,
  '14727': FederalStates.BB,
  '14728': FederalStates.BB,
  '14770': FederalStates.BB,
  '14772': FederalStates.BB,
  '14774': FederalStates.BB,
  '14776': FederalStates.BB,
  '14778': FederalStates.BB,
  '14789': FederalStates.BB,
  '14793': FederalStates.BB,
  '14797': FederalStates.BB,
  '14798': FederalStates.BB,
  '14806': FederalStates.BB,
  '14822': FederalStates.BB,
  '14823': FederalStates.BB,
  '14827': FederalStates.BB,
  '14828': FederalStates.BB,
  '14913': FederalStates.BB,
  '14929': FederalStates.BB,
  '14943': FederalStates.BB,
  '14947': FederalStates.BB,
  '14959': FederalStates.BB,
  '14974': FederalStates.BB,
  '14979': FederalStates.BB,
  '15230': FederalStates.BB,
  '15232': FederalStates.BB,
  '15234': FederalStates.BB,
  '15236': FederalStates.BB,
  '15295': FederalStates.BB,
  '15299': FederalStates.BB,
  '15306': FederalStates.BB,
  '15320': FederalStates.BB,
  '15324': FederalStates.BB,
  '15326': FederalStates.BB,
  '15328': FederalStates.BB,
  '15344': FederalStates.BB,
  '15345': FederalStates.BB,
  '15366': FederalStates.BB,
  '15370': FederalStates.BB,
  '15372': FederalStates.BB,
  '15374': FederalStates.BB,
  '15377': FederalStates.BB,
  '15378': FederalStates.BB,
  '15517': FederalStates.BB,
  '15518': FederalStates.BB,
  '15526': FederalStates.BB,
  '15528': FederalStates.BB,
  '15537': FederalStates.BB,
  '15562': FederalStates.BB,
  '15566': FederalStates.BB,
  '15569': FederalStates.BB,
  '15711': FederalStates.BB,
  '15712': FederalStates.BB,
  '15713': FederalStates.BB,
  '15732': FederalStates.BB,
  '15738': FederalStates.BB,
  '15741': FederalStates.BB,
  '15745': FederalStates.BB,
  '15746': FederalStates.BB,
  '15748': FederalStates.BB,
  '15749': FederalStates.BB,
  '15752': FederalStates.BB,
  '15754': FederalStates.BB,
  '15755': FederalStates.BB,
  '15757': FederalStates.BB,
  '15806': FederalStates.BB,
  '15827': FederalStates.BB,
  '15831': FederalStates.BB,
  '15834': FederalStates.BB,
  '15837': FederalStates.BB,
  '15838': FederalStates.BB,
  '15848': FederalStates.BB,
  '15859': FederalStates.BB,
  '15864': FederalStates.BB,
  '15868': FederalStates.BB,
  '15890': FederalStates.BB,
  '15898': FederalStates.BB,
  '15907': FederalStates.BB,
  '15910': FederalStates.BB,
  '15913': FederalStates.BB,
  '15926': FederalStates.BB,
  '15936': FederalStates.BB,
  '15938': FederalStates.BB,
  '16225': FederalStates.BB,
  '16227': FederalStates.BB,
  '16230': FederalStates.BB,
  '16244': FederalStates.BB,
  '16247': FederalStates.BB,
  '16248': FederalStates.BB,
  '16259': FederalStates.BB,
  '16269': FederalStates.BB,
  '16278': FederalStates.BB,
  '16303': FederalStates.BB,
  '16306': FederalStates.BB,
  '16307': FederalStates.BB,
  '16321': FederalStates.BB,
  '16341': FederalStates.BB,
  '16348': FederalStates.BB,
  '16352': FederalStates.BB,
  '16356': FederalStates.BB,
  '16359': FederalStates.BB,
  '16515': FederalStates.BB,
  '16540': FederalStates.BB,
  '16547': FederalStates.BB,
  '16548': FederalStates.BB,
  '16552': FederalStates.BB,
  '16556': FederalStates.BB,
  '16559': FederalStates.BB,
  '16562': FederalStates.BB,
  '16565': FederalStates.BB,
  '16567': FederalStates.BB,
  '16727': FederalStates.BB,
  '16761': FederalStates.BB,
  '16766': FederalStates.BB,
  '16767': FederalStates.BB,
  '16775': FederalStates.BB,
  '16792': FederalStates.BB,
  '16798': FederalStates.BB,
  '16816': FederalStates.BB,
  '16818': FederalStates.BB,
  '16827': FederalStates.BB,
  '16831': FederalStates.BB,
  '16833': FederalStates.BB,
  '16835': FederalStates.BB,
  '16837': FederalStates.BB,
  '16845': FederalStates.BB,
  '16866': FederalStates.BB,
  '16868': FederalStates.BB,
  '16909': FederalStates.BB,
  '16918': FederalStates.BB,
  '16921': FederalStates.BB,
  '16928': FederalStates.BB,
  '16945': FederalStates.BB,
  '16949': FederalStates.BB,
  '17033': FederalStates.MV,
  '17034': FederalStates.MV,
  '17036': FederalStates.MV,
  '17039': FederalStates.MV,
  '17087': FederalStates.MV,
  '17089': FederalStates.MV,
  '17091': FederalStates.MV,
  '17094': FederalStates.MV,
  '17098': FederalStates.MV,
  '17099': FederalStates.MV,
  '17109': FederalStates.MV,
  '17111': FederalStates.MV,
  '17121': FederalStates.MV,
  '17126': FederalStates.MV,
  '17129': FederalStates.MV,
  '17139': FederalStates.MV,
  '17153': FederalStates.MV,
  '17154': FederalStates.MV,
  '17159': FederalStates.MV,
  '17166': FederalStates.MV,
  '17168': FederalStates.MV,
  '17179': FederalStates.MV,
  '17192': FederalStates.MV,
  '17194': FederalStates.MV,
  '17207': FederalStates.MV,
  '17209': FederalStates.MV,
  '17213': FederalStates.MV,
  '17214': FederalStates.MV,
  '17217': FederalStates.MV,
  '17219': FederalStates.MV,
  '17235': FederalStates.MV,
  '17237': FederalStates.MV,
  '17248': FederalStates.MV,
  '17252': FederalStates.MV,
  '17255': FederalStates.MV,
  '17258': FederalStates.MV,
  '17259': FederalStates.MV,
  '17268': FederalStates.BB,
  '17279': FederalStates.BB,
  '17291': FederalStates.BB,
  '17309': FederalStates.MV,
  '17321': FederalStates.MV,
  '17322': FederalStates.MV,
  '17326': FederalStates.BB,
  '17328': FederalStates.MV,
  '17329': FederalStates.MV,
  '17335': FederalStates.MV,
  '17337': FederalStates.MV,
  '17348': FederalStates.MV,
  '17349': FederalStates.MV,
  '17358': FederalStates.MV,
  '17367': FederalStates.MV,
  '17373': FederalStates.MV,
  '17375': FederalStates.MV,
  '17379': FederalStates.MV,
  '17389': FederalStates.MV,
  '17390': FederalStates.MV,
  '17391': FederalStates.MV,
  '17392': FederalStates.MV,
  '17398': FederalStates.MV,
  '17406': FederalStates.MV,
  '17419': FederalStates.MV,
  '17424': FederalStates.MV,
  '17429': FederalStates.MV,
  '17438': FederalStates.MV,
  '17440': FederalStates.MV,
  '17449': FederalStates.MV,
  '17454': FederalStates.MV,
  '17459': FederalStates.MV,
  '17489': FederalStates.MV,
  '17491': FederalStates.MV,
  '17493': FederalStates.MV,
  '17495': FederalStates.MV,
  '17498': FederalStates.MV,
  '17506': FederalStates.MV,
  '17509': FederalStates.MV,
  '18055': FederalStates.MV,
  '18057': FederalStates.MV,
  '18059': FederalStates.MV,
  '18069': FederalStates.MV,
  '18106': FederalStates.MV,
  '18107': FederalStates.MV,
  '18109': FederalStates.MV,
  '18119': FederalStates.MV,
  '18146': FederalStates.MV,
  '18147': FederalStates.MV,
  '18181': FederalStates.MV,
  '18182': FederalStates.MV,
  '18184': FederalStates.MV,
  '18190': FederalStates.MV,
  '18195': FederalStates.MV,
  '18196': FederalStates.MV,
  '18198': FederalStates.MV,
  '18209': FederalStates.MV,
  '18211': FederalStates.MV,
  '18225': FederalStates.MV,
  '18230': FederalStates.MV,
  '18231': FederalStates.MV,
  '18233': FederalStates.MV,
  '18236': FederalStates.MV,
  '18239': FederalStates.MV,
  '18246': FederalStates.MV,
  '18249': FederalStates.MV,
  '18258': FederalStates.MV,
  '18273': FederalStates.MV,
  '18276': FederalStates.MV,
  '18279': FederalStates.MV,
  '18292': FederalStates.MV,
  '18299': FederalStates.MV,
  '18311': FederalStates.MV,
  '18314': FederalStates.MV,
  '18317': FederalStates.MV,
  '18320': FederalStates.MV,
  '18334': FederalStates.MV,
  '18337': FederalStates.MV,
  '18347': FederalStates.MV,
  '18356': FederalStates.MV,
  '18374': FederalStates.MV,
  '18375': FederalStates.MV,
  '18435': FederalStates.MV,
  '18437': FederalStates.MV,
  '18439': FederalStates.MV,
  '18442': FederalStates.MV,
  '18445': FederalStates.MV,
  '18461': FederalStates.MV,
  '18465': FederalStates.MV,
  '18469': FederalStates.MV,
  '18507': FederalStates.MV,
  '18510': FederalStates.MV,
  '18513': FederalStates.MV,
  '18516': FederalStates.MV,
  '18519': FederalStates.MV,
  '18528': FederalStates.MV,
  '18546': FederalStates.MV,
  '18551': FederalStates.MV,
  '18556': FederalStates.MV,
  '18565': FederalStates.MV,
  '18569': FederalStates.MV,
  '18573': FederalStates.MV,
  '18574': FederalStates.MV,
  '18581': FederalStates.MV,
  '18586': FederalStates.MV,
  '18609': FederalStates.MV,
  '19053': FederalStates.MV,
  '19055': FederalStates.MV,
  '19057': FederalStates.MV,
  '19059': FederalStates.MV,
  '19061': FederalStates.MV,
  '19063': FederalStates.MV,
  '19065': FederalStates.MV,
  '19067': FederalStates.MV,
  '19069': FederalStates.MV,
  '19071': FederalStates.MV,
  '19073': FederalStates.MV,
  '19075': FederalStates.MV,
  '19077': FederalStates.MV,
  '19079': FederalStates.MV,
  '19086': FederalStates.MV,
  '19089': FederalStates.MV,
  '19205': FederalStates.MV,
  '19209': FederalStates.MV,
  '19217': FederalStates.MV,
  '19230': FederalStates.MV,
  '19243': FederalStates.MV,
  '19246': FederalStates.MV,
  '19249': FederalStates.MV,
  '19258': FederalStates.MV,
  '19260': FederalStates.MV,
  '19273': FederalStates.NI,
  '19288': FederalStates.MV,
  '19294': FederalStates.MV,
  '19300': FederalStates.MV,
  '19303': FederalStates.MV,
  '19306': FederalStates.MV,
  '19309': FederalStates.BB,
  '19322': FederalStates.BB,
  '19336': FederalStates.BB,
  '19339': FederalStates.BB,
  '19348': FederalStates.BB,
  '19357': FederalStates.MV,
  '19370': FederalStates.MV,
  '19372': FederalStates.MV,
  '19374': FederalStates.MV,
  '19376': FederalStates.MV,
  '19386': FederalStates.MV,
  '19395': FederalStates.MV,
  '19399': FederalStates.MV,
  '19406': FederalStates.MV,
  '19412': FederalStates.MV,
  '19417': FederalStates.MV,
  '20038': FederalStates.HH,
  '20095': FederalStates.HH,
  '20097': FederalStates.HH,
  '20099': FederalStates.HH,
  '20144': FederalStates.HH,
  '20146': FederalStates.HH,
  '20148': FederalStates.HH,
  '20149': FederalStates.HH,
  '20249': FederalStates.HH,
  '20251': FederalStates.HH,
  '20253': FederalStates.HH,
  '20255': FederalStates.HH,
  '20257': FederalStates.HH,
  '20259': FederalStates.HH,
  '20354': FederalStates.HH,
  '20355': FederalStates.HH,
  '20357': FederalStates.HH,
  '20359': FederalStates.HH,
  '20457': FederalStates.HH,
  '20459': FederalStates.HH,
  '20535': FederalStates.HH,
  '20537': FederalStates.HH,
  '20539': FederalStates.HH,
  '21029': FederalStates.HH,
  '21031': FederalStates.HH,
  '21033': FederalStates.HH,
  '21035': FederalStates.HH,
  '21037': FederalStates.HH,
  '21039': FederalStates.SH,
  '21073': FederalStates.HH,
  '21075': FederalStates.HH,
  '21077': FederalStates.HH,
  '21079': FederalStates.HH,
  '21107': FederalStates.HH,
  '21109': FederalStates.HH,
  '21129': FederalStates.HH,
  '21147': FederalStates.HH,
  '21149': FederalStates.HH,
  '21217': FederalStates.NI,
  '21218': FederalStates.NI,
  '21220': FederalStates.NI,
  '21224': FederalStates.NI,
  '21227': FederalStates.NI,
  '21228': FederalStates.NI,
  '21244': FederalStates.NI,
  '21255': FederalStates.NI,
  '21256': FederalStates.NI,
  '21258': FederalStates.NI,
  '21259': FederalStates.NI,
  '21261': FederalStates.NI,
  '21266': FederalStates.NI,
  '21271': FederalStates.NI,
  '21272': FederalStates.NI,
  '21274': FederalStates.NI,
  '21279': FederalStates.NI,
  '21335': FederalStates.NI,
  '21337': FederalStates.NI,
  '21339': FederalStates.NI,
  '21354': FederalStates.NI,
  '21357': FederalStates.NI,
  '21358': FederalStates.NI,
  '21360': FederalStates.NI,
  '21365': FederalStates.NI,
  '21368': FederalStates.NI,
  '21369': FederalStates.NI,
  '21371': FederalStates.NI,
  '21376': FederalStates.NI,
  '21379': FederalStates.NI,
  '21380': FederalStates.NI,
  '21382': FederalStates.NI,
  '21385': FederalStates.NI,
  '21386': FederalStates.NI,
  '21388': FederalStates.NI,
  '21391': FederalStates.NI,
  '21394': FederalStates.NI,
  '21395': FederalStates.NI,
  '21397': FederalStates.NI,
  '21398': FederalStates.NI,
  '21400': FederalStates.NI,
  '21401': FederalStates.NI,
  '21403': FederalStates.NI,
  '21406': FederalStates.NI,
  '21407': FederalStates.NI,
  '21409': FederalStates.NI,
  '21423': FederalStates.NI,
  '21435': FederalStates.NI,
  '21436': FederalStates.NI,
  '21438': FederalStates.NI,
  '21439': FederalStates.NI,
  '21441': FederalStates.NI,
  '21442': FederalStates.NI,
  '21444': FederalStates.NI,
  '21445': FederalStates.NI,
  '21447': FederalStates.NI,
  '21449': FederalStates.NI,
  '21465': FederalStates.SH,
  '21481': FederalStates.SH,
  '21483': FederalStates.SH,
  '21493': FederalStates.SH,
  '21502': FederalStates.SH,
  '21509': FederalStates.SH,
  '21514': FederalStates.SH,
  '21516': FederalStates.SH,
  '21521': FederalStates.SH,
  '21522': FederalStates.NI,
  '21524': FederalStates.SH,
  '21526': FederalStates.SH,
  '21527': FederalStates.SH,
  '21529': FederalStates.SH,
  '21614': FederalStates.NI,
  '21629': FederalStates.NI,
  '21635': FederalStates.NI,
  '21640': FederalStates.NI,
  '21641': FederalStates.NI,
  '21643': FederalStates.NI,
  '21644': FederalStates.NI,
  '21646': FederalStates.NI,
  '21647': FederalStates.NI,
  '21649': FederalStates.NI,
  '21680': FederalStates.NI,
  '21682': FederalStates.NI,
  '21683': FederalStates.NI,
  '21684': FederalStates.NI,
  '21698': FederalStates.NI,
  '21702': FederalStates.NI,
  '21706': FederalStates.NI,
  '21709': FederalStates.NI,
  '21710': FederalStates.NI,
  '21712': FederalStates.NI,
  '21714': FederalStates.NI,
  '21717': FederalStates.NI,
  '21720': FederalStates.NI,
  '21723': FederalStates.NI,
  '21726': FederalStates.NI,
  '21727': FederalStates.NI,
  '21729': FederalStates.NI,
  '21730': FederalStates.NI,
  '21732': FederalStates.NI,
  '21734': FederalStates.NI,
  '21737': FederalStates.NI,
  '21739': FederalStates.NI,
  '21745': FederalStates.NI,
  '21755': FederalStates.NI,
  '21756': FederalStates.NI,
  '21762': FederalStates.NI,
  '21763': FederalStates.NI,
  '21765': FederalStates.NI,
  '21769': FederalStates.NI,
  '21770': FederalStates.NI,
  '21772': FederalStates.NI,
  '21775': FederalStates.NI,
  '21776': FederalStates.NI,
  '21781': FederalStates.NI,
  '21782': FederalStates.NI,
  '21784': FederalStates.NI,
  '21785': FederalStates.NI,
  '21787': FederalStates.NI,
  '21789': FederalStates.NI,
  '22041': FederalStates.HH,
  '22043': FederalStates.HH,
  '22045': FederalStates.HH,
  '22047': FederalStates.HH,
  '22049': FederalStates.HH,
  '22081': FederalStates.HH,
  '22083': FederalStates.HH,
  '22085': FederalStates.HH,
  '22087': FederalStates.HH,
  '22089': FederalStates.HH,
  '22111': FederalStates.HH,
  '22113': FederalStates.SH,
  '22115': FederalStates.HH,
  '22117': FederalStates.HH,
  '22119': FederalStates.HH,
  '22143': FederalStates.HH,
  '22145': FederalStates.SH,
  '22147': FederalStates.HH,
  '22149': FederalStates.HH,
  '22159': FederalStates.HH,
  '22175': FederalStates.HH,
  '22177': FederalStates.HH,
  '22179': FederalStates.HH,
  '22297': FederalStates.HH,
  '22299': FederalStates.HH,
  '22301': FederalStates.HH,
  '22303': FederalStates.HH,
  '22305': FederalStates.HH,
  '22307': FederalStates.HH,
  '22309': FederalStates.HH,
  '22335': FederalStates.HH,
  '22337': FederalStates.HH,
  '22339': FederalStates.HH,
  '22359': FederalStates.HH,
  '22391': FederalStates.HH,
  '22393': FederalStates.HH,
  '22395': FederalStates.HH,
  '22397': FederalStates.HH,
  '22399': FederalStates.HH,
  '22415': FederalStates.HH,
  '22417': FederalStates.HH,
  '22419': FederalStates.HH,
  '22453': FederalStates.HH,
  '22455': FederalStates.HH,
  '22457': FederalStates.HH,
  '22459': FederalStates.HH,
  '22523': FederalStates.HH,
  '22525': FederalStates.HH,
  '22527': FederalStates.HH,
  '22529': FederalStates.HH,
  '22547': FederalStates.HH,
  '22549': FederalStates.HH,
  '22559': FederalStates.HH,
  '22587': FederalStates.HH,
  '22589': FederalStates.HH,
  '22605': FederalStates.HH,
  '22607': FederalStates.HH,
  '22609': FederalStates.HH,
  '22761': FederalStates.HH,
  '22763': FederalStates.HH,
  '22765': FederalStates.HH,
  '22767': FederalStates.HH,
  '22769': FederalStates.HH,
  '22844': FederalStates.SH,
  '22846': FederalStates.SH,
  '22848': FederalStates.SH,
  '22850': FederalStates.SH,
  '22851': FederalStates.SH,
  '22869': FederalStates.SH,
  '22880': FederalStates.SH,
  '22885': FederalStates.SH,
  '22889': FederalStates.SH,
  '22926': FederalStates.SH,
  '22927': FederalStates.SH,
  '22929': FederalStates.SH,
  '22941': FederalStates.SH,
  '22946': FederalStates.SH,
  '22949': FederalStates.SH,
  '22952': FederalStates.SH,
  '22955': FederalStates.SH,
  '22956': FederalStates.SH,
  '22958': FederalStates.SH,
  '22959': FederalStates.SH,
  '22962': FederalStates.SH,
  '22964': FederalStates.SH,
  '22965': FederalStates.SH,
  '22967': FederalStates.SH,
  '22969': FederalStates.SH,
  '23539': FederalStates.SH,
  '23552': FederalStates.SH,
  '23554': FederalStates.SH,
  '23556': FederalStates.SH,
  '23558': FederalStates.SH,
  '23560': FederalStates.SH,
  '23562': FederalStates.SH,
  '23564': FederalStates.SH,
  '23566': FederalStates.SH,
  '23568': FederalStates.SH,
  '23569': FederalStates.SH,
  '23570': FederalStates.SH,
  '23611': FederalStates.SH,
  '23617': FederalStates.SH,
  '23619': FederalStates.SH,
  '23623': FederalStates.SH,
  '23626': FederalStates.SH,
  '23627': FederalStates.SH,
  '23628': FederalStates.SH,
  '23629': FederalStates.SH,
  '23669': FederalStates.SH,
  '23683': FederalStates.SH,
  '23684': FederalStates.SH,
  '23689': FederalStates.SH,
  '23701': FederalStates.SH,
  '23714': FederalStates.SH,
  '23715': FederalStates.SH,
  '23717': FederalStates.SH,
  '23719': FederalStates.SH,
  '23730': FederalStates.SH,
  '23738': FederalStates.SH,
  '23743': FederalStates.SH,
  '23744': FederalStates.SH,
  '23746': FederalStates.SH,
  '23747': FederalStates.SH,
  '23749': FederalStates.SH,
  '23758': FederalStates.SH,
  '23769': FederalStates.SH,
  '23774': FederalStates.SH,
  '23775': FederalStates.SH,
  '23777': FederalStates.SH,
  '23779': FederalStates.SH,
  '23795': FederalStates.SH,
  '23812': FederalStates.SH,
  '23813': FederalStates.SH,
  '23815': FederalStates.SH,
  '23816': FederalStates.SH,
  '23818': FederalStates.SH,
  '23820': FederalStates.SH,
  '23821': FederalStates.SH,
  '23823': FederalStates.SH,
  '23824': FederalStates.SH,
  '23826': FederalStates.SH,
  '23827': FederalStates.SH,
  '23829': FederalStates.SH,
  '23843': FederalStates.SH,
  '23845': FederalStates.SH,
  '23847': FederalStates.SH,
  '23858': FederalStates.SH,
  '23860': FederalStates.SH,
  '23863': FederalStates.SH,
  '23866': FederalStates.SH,
  '23867': FederalStates.SH,
  '23869': FederalStates.SH,
  '23879': FederalStates.SH,
  '23881': FederalStates.SH,
  '23883': FederalStates.SH,
  '23896': FederalStates.SH,
  '23898': FederalStates.SH,
  '23899': FederalStates.SH,
  '23909': FederalStates.SH,
  '23911': FederalStates.SH,
  '23919': FederalStates.SH,
  '23923': FederalStates.MV,
  '23936': FederalStates.MV,
  '23942': FederalStates.MV,
  '23944': FederalStates.MV,
  '23946': FederalStates.MV,
  '23948': FederalStates.MV,
  '23952': FederalStates.MV,
  '23966': FederalStates.MV,
  '23968': FederalStates.MV,
  '23970': FederalStates.MV,
  '23972': FederalStates.MV,
  '23974': FederalStates.MV,
  '23992': FederalStates.MV,
  '23996': FederalStates.MV,
  '23999': FederalStates.MV,
  '24103': FederalStates.SH,
  '24105': FederalStates.SH,
  '24106': FederalStates.SH,
  '24107': FederalStates.SH,
  '24109': FederalStates.SH,
  '24111': FederalStates.SH,
  '24113': FederalStates.SH,
  '24114': FederalStates.SH,
  '24116': FederalStates.SH,
  '24118': FederalStates.SH,
  '24119': FederalStates.SH,
  '24143': FederalStates.SH,
  '24145': FederalStates.SH,
  '24146': FederalStates.SH,
  '24147': FederalStates.SH,
  '24148': FederalStates.SH,
  '24149': FederalStates.SH,
  '24159': FederalStates.SH,
  '24161': FederalStates.SH,
  '24211': FederalStates.SH,
  '24214': FederalStates.SH,
  '24217': FederalStates.SH,
  '24220': FederalStates.SH,
  '24222': FederalStates.SH,
  '24223': FederalStates.SH,
  '24226': FederalStates.SH,
  '24229': FederalStates.SH,
  '24232': FederalStates.SH,
  '24235': FederalStates.SH,
  '24238': FederalStates.SH,
  '24239': FederalStates.SH,
  '24241': FederalStates.SH,
  '24242': FederalStates.SH,
  '24244': FederalStates.SH,
  '24245': FederalStates.SH,
  '24247': FederalStates.SH,
  '24248': FederalStates.SH,
  '24250': FederalStates.SH,
  '24251': FederalStates.SH,
  '24253': FederalStates.SH,
  '24254': FederalStates.SH,
  '24256': FederalStates.SH,
  '24257': FederalStates.SH,
  '24259': FederalStates.SH,
  '24306': FederalStates.SH,
  '24321': FederalStates.SH,
  '24326': FederalStates.SH,
  '24327': FederalStates.SH,
  '24329': FederalStates.SH,
  '24340': FederalStates.SH,
  '24351': FederalStates.SH,
  '24354': FederalStates.SH,
  '24357': FederalStates.SH,
  '24358': FederalStates.SH,
  '24360': FederalStates.SH,
  '24361': FederalStates.SH,
  '24363': FederalStates.SH,
  '24364': FederalStates.SH,
  '24366': FederalStates.SH,
  '24367': FederalStates.SH,
  '24369': FederalStates.SH,
  '24376': FederalStates.SH,
  '24392': FederalStates.SH,
  '24395': FederalStates.SH,
  '24398': FederalStates.SH,
  '24399': FederalStates.SH,
  '24401': FederalStates.SH,
  '24402': FederalStates.SH,
  '24404': FederalStates.SH,
  '24405': FederalStates.SH,
  '24407': FederalStates.SH,
  '24409': FederalStates.SH,
  '24534': FederalStates.SH,
  '24536': FederalStates.SH,
  '24537': FederalStates.SH,
  '24539': FederalStates.SH,
  '24558': FederalStates.SH,
  '24568': FederalStates.SH,
  '24576': FederalStates.SH,
  '24582': FederalStates.SH,
  '24589': FederalStates.SH,
  '24594': FederalStates.SH,
  '24598': FederalStates.SH,
  '24601': FederalStates.SH,
  '24610': FederalStates.SH,
  '24613': FederalStates.SH,
  '24616': FederalStates.SH,
  '24619': FederalStates.SH,
  '24620': FederalStates.SH,
  '24622': FederalStates.SH,
  '24623': FederalStates.SH,
  '24625': FederalStates.SH,
  '24626': FederalStates.SH,
  '24628': FederalStates.SH,
  '24629': FederalStates.SH,
  '24631': FederalStates.SH,
  '24632': FederalStates.SH,
  '24634': FederalStates.SH,
  '24635': FederalStates.SH,
  '24637': FederalStates.SH,
  '24638': FederalStates.SH,
  '24640': FederalStates.SH,
  '24641': FederalStates.SH,
  '24643': FederalStates.SH,
  '24644': FederalStates.SH,
  '24646': FederalStates.SH,
  '24647': FederalStates.SH,
  '24649': FederalStates.SH,
  '24768': FederalStates.SH,
  '24782': FederalStates.SH,
  '24783': FederalStates.SH,
  '24784': FederalStates.SH,
  '24787': FederalStates.SH,
  '24790': FederalStates.SH,
  '24791': FederalStates.SH,
  '24793': FederalStates.SH,
  '24794': FederalStates.SH,
  '24796': FederalStates.SH,
  '24797': FederalStates.SH,
  '24799': FederalStates.SH,
  '24800': FederalStates.SH,
  '24802': FederalStates.SH,
  '24803': FederalStates.SH,
  '24805': FederalStates.SH,
  '24806': FederalStates.SH,
  '24808': FederalStates.SH,
  '24809': FederalStates.SH,
  '24811': FederalStates.SH,
  '24813': FederalStates.SH,
  '24814': FederalStates.SH,
  '24816': FederalStates.SH,
  '24817': FederalStates.SH,
  '24819': FederalStates.SH,
  '24837': FederalStates.SH,
  '24848': FederalStates.SH,
  '24850': FederalStates.SH,
  '24852': FederalStates.SH,
  '24855': FederalStates.SH,
  '24857': FederalStates.SH,
  '24860': FederalStates.SH,
  '24861': FederalStates.SH,
  '24863': FederalStates.SH,
  '24864': FederalStates.SH,
  '24866': FederalStates.SH,
  '24867': FederalStates.SH,
  '24869': FederalStates.SH,
  '24870': FederalStates.SH,
  '24872': FederalStates.SH,
  '24873': FederalStates.SH,
  '24875': FederalStates.SH,
  '24876': FederalStates.SH,
  '24878': FederalStates.SH,
  '24879': FederalStates.SH,
  '24881': FederalStates.SH,
  '24882': FederalStates.SH,
  '24884': FederalStates.SH,
  '24885': FederalStates.SH,
  '24887': FederalStates.SH,
  '24888': FederalStates.SH,
  '24890': FederalStates.SH,
  '24891': FederalStates.SH,
  '24893': FederalStates.SH,
  '24894': FederalStates.SH,
  '24896': FederalStates.SH,
  '24897': FederalStates.SH,
  '24899': FederalStates.SH,
  '24937': FederalStates.SH,
  '24939': FederalStates.SH,
  '24941': FederalStates.SH,
  '24943': FederalStates.SH,
  '24944': FederalStates.SH,
  '24955': FederalStates.SH,
  '24960': FederalStates.SH,
  '24963': FederalStates.SH,
  '24966': FederalStates.SH,
  '24969': FederalStates.SH,
  '24972': FederalStates.SH,
  '24975': FederalStates.SH,
  '24976': FederalStates.SH,
  '24977': FederalStates.SH,
  '24980': FederalStates.SH,
  '24983': FederalStates.SH,
  '24986': FederalStates.SH,
  '24988': FederalStates.SH,
  '24989': FederalStates.SH,
  '24991': FederalStates.SH,
  '24992': FederalStates.SH,
  '24994': FederalStates.SH,
  '24996': FederalStates.SH,
  '24997': FederalStates.SH,
  '24999': FederalStates.SH,
  '25335': FederalStates.SH,
  '25336': FederalStates.SH,
  '25337': FederalStates.SH,
  '25348': FederalStates.SH,
  '25355': FederalStates.SH,
  '25358': FederalStates.SH,
  '25361': FederalStates.SH,
  '25364': FederalStates.SH,
  '25365': FederalStates.SH,
  '25368': FederalStates.SH,
  '25370': FederalStates.SH,
  '25371': FederalStates.SH,
  '25373': FederalStates.SH,
  '25376': FederalStates.SH,
  '25377': FederalStates.SH,
  '25379': FederalStates.SH,
  '25404': FederalStates.SH,
  '25421': FederalStates.SH,
  '25429': FederalStates.SH,
  '25436': FederalStates.SH,
  '25437': FederalStates.SH,
  '25451': FederalStates.SH,
  '25462': FederalStates.SH,
  '25469': FederalStates.SH,
  '25474': FederalStates.SH,
  '25479': FederalStates.SH,
  '25482': FederalStates.SH,
  '25485': FederalStates.SH,
  '25486': FederalStates.SH,
  '25488': FederalStates.SH,
  '25489': FederalStates.SH,
  '25491': FederalStates.SH,
  '25492': FederalStates.SH,
  '25494': FederalStates.SH,
  '25495': FederalStates.SH,
  '25497': FederalStates.SH,
  '25499': FederalStates.SH,
  '25524': FederalStates.SH,
  '25541': FederalStates.SH,
  '25548': FederalStates.SH,
  '25551': FederalStates.SH,
  '25554': FederalStates.SH,
  '25557': FederalStates.SH,
  '25560': FederalStates.SH,
  '25563': FederalStates.SH,
  '25566': FederalStates.SH,
  '25569': FederalStates.SH,
  '25572': FederalStates.SH,
  '25573': FederalStates.SH,
  '25575': FederalStates.SH,
  '25576': FederalStates.SH,
  '25578': FederalStates.SH,
  '25579': FederalStates.SH,
  '25581': FederalStates.SH,
  '25582': FederalStates.SH,
  '25584': FederalStates.SH,
  '25585': FederalStates.SH,
  '25587': FederalStates.SH,
  '25588': FederalStates.SH,
  '25590': FederalStates.SH,
  '25591': FederalStates.SH,
  '25593': FederalStates.SH,
  '25594': FederalStates.SH,
  '25596': FederalStates.SH,
  '25597': FederalStates.SH,
  '25599': FederalStates.SH,
  '25693': FederalStates.SH,
  '25704': FederalStates.SH,
  '25709': FederalStates.SH,
  '25712': FederalStates.SH,
  '25715': FederalStates.SH,
  '25718': FederalStates.SH,
  '25719': FederalStates.SH,
  '25721': FederalStates.SH,
  '25724': FederalStates.SH,
  '25725': FederalStates.SH,
  '25727': FederalStates.SH,
  '25729': FederalStates.SH,
  '25746': FederalStates.SH,
  '25761': FederalStates.SH,
  '25764': FederalStates.SH,
  '25767': FederalStates.SH,
  '25770': FederalStates.SH,
  '25774': FederalStates.SH,
  '25776': FederalStates.SH,
  '25779': FederalStates.SH,
  '25782': FederalStates.SH,
  '25785': FederalStates.SH,
  '25786': FederalStates.SH,
  '25788': FederalStates.SH,
  '25791': FederalStates.SH,
  '25792': FederalStates.SH,
  '25794': FederalStates.SH,
  '25795': FederalStates.SH,
  '25797': FederalStates.SH,
  '25799': FederalStates.SH,
  '25813': FederalStates.SH,
  '25821': FederalStates.SH,
  '25826': FederalStates.SH,
  '25832': FederalStates.SH,
  '25836': FederalStates.SH,
  '25840': FederalStates.SH,
  '25842': FederalStates.SH,
  '25845': FederalStates.SH,
  '25849': FederalStates.SH,
  '25850': FederalStates.SH,
  '25852': FederalStates.SH,
  '25853': FederalStates.SH,
  '25855': FederalStates.SH,
  '25856': FederalStates.SH,
  '25858': FederalStates.SH,
  '25859': FederalStates.SH,
  '25860': FederalStates.SH,
  '25862': FederalStates.SH,
  '25863': FederalStates.SH,
  '25864': FederalStates.SH,
  '25866': FederalStates.SH,
  '25868': FederalStates.SH,
  '25869': FederalStates.SH,
  '25870': FederalStates.SH,
  '25872': FederalStates.SH,
  '25873': FederalStates.SH,
  '25875': FederalStates.SH,
  '25876': FederalStates.SH,
  '25878': FederalStates.SH,
  '25879': FederalStates.SH,
  '25881': FederalStates.SH,
  '25882': FederalStates.SH,
  '25884': FederalStates.SH,
  '25885': FederalStates.SH,
  '25887': FederalStates.SH,
  '25889': FederalStates.SH,
  '25899': FederalStates.SH,
  '25917': FederalStates.SH,
  '25920': FederalStates.SH,
  '25923': FederalStates.SH,
  '25924': FederalStates.SH,
  '25926': FederalStates.SH,
  '25927': FederalStates.SH,
  '25938': FederalStates.SH,
  '25946': FederalStates.SH,
  '25980': FederalStates.SH,
  '25992': FederalStates.SH,
  '25996': FederalStates.SH,
  '25997': FederalStates.SH,
  '25999': FederalStates.SH,
  '26121': FederalStates.NI,
  '26122': FederalStates.NI,
  '26123': FederalStates.NI,
  '26125': FederalStates.NI,
  '26127': FederalStates.NI,
  '26129': FederalStates.NI,
  '26131': FederalStates.NI,
  '26133': FederalStates.NI,
  '26135': FederalStates.NI,
  '26160': FederalStates.NI,
  '26169': FederalStates.NI,
  '26180': FederalStates.NI,
  '26188': FederalStates.NI,
  '26197': FederalStates.NI,
  '26203': FederalStates.NI,
  '26209': FederalStates.NI,
  '26215': FederalStates.NI,
  '26219': FederalStates.NI,
  '26316': FederalStates.NI,
  '26340': FederalStates.NI,
  '26345': FederalStates.NI,
  '26349': FederalStates.NI,
  '26382': FederalStates.NI,
  '26384': FederalStates.NI,
  '26386': FederalStates.NI,
  '26388': FederalStates.NI,
  '26389': FederalStates.NI,
  '26409': FederalStates.NI,
  '26419': FederalStates.NI,
  '26427': FederalStates.NI,
  '26434': FederalStates.NI,
  '26441': FederalStates.NI,
  '26446': FederalStates.NI,
  '26452': FederalStates.NI,
  '26465': FederalStates.NI,
  '26474': FederalStates.NI,
  '26486': FederalStates.NI,
  '26487': FederalStates.NI,
  '26489': FederalStates.NI,
  '26506': FederalStates.NI,
  '26524': FederalStates.NI,
  '26529': FederalStates.NI,
  '26532': FederalStates.NI,
  '26548': FederalStates.NI,
  '26553': FederalStates.NI,
  '26556': FederalStates.NI,
  '26571': FederalStates.NI,
  '26579': FederalStates.NI,
  '26603': FederalStates.NI,
  '26605': FederalStates.NI,
  '26607': FederalStates.NI,
  '26624': FederalStates.NI,
  '26629': FederalStates.NI,
  '26632': FederalStates.NI,
  '26639': FederalStates.NI,
  '26655': FederalStates.NI,
  '26670': FederalStates.NI,
  '26676': FederalStates.NI,
  '26683': FederalStates.NI,
  '26689': FederalStates.NI,
  '26721': FederalStates.NI,
  '26723': FederalStates.NI,
  '26725': FederalStates.NI,
  '26736': FederalStates.NI,
  '26757': FederalStates.NI,
  '26759': FederalStates.NI,
  '26789': FederalStates.NI,
  '26802': FederalStates.NI,
  '26810': FederalStates.NI,
  '26817': FederalStates.NI,
  '26826': FederalStates.NI,
  '26831': FederalStates.NI,
  '26835': FederalStates.NI,
  '26842': FederalStates.NI,
  '26844': FederalStates.NI,
  '26845': FederalStates.NI,
  '26847': FederalStates.NI,
  '26849': FederalStates.NI,
  '26871': FederalStates.NI,
  '26892': FederalStates.NI,
  '26897': FederalStates.NI,
  '26899': FederalStates.NI,
  '26901': FederalStates.NI,
  '26903': FederalStates.NI,
  '26904': FederalStates.NI,
  '26906': FederalStates.NI,
  '26907': FederalStates.NI,
  '26909': FederalStates.NI,
  '26919': FederalStates.NI,
  '26931': FederalStates.NI,
  '26935': FederalStates.NI,
  '26936': FederalStates.NI,
  '26937': FederalStates.NI,
  '26939': FederalStates.NI,
  '26954': FederalStates.NI,
  '26969': FederalStates.NI,
  '27211': FederalStates.NI,
  '27232': FederalStates.NI,
  '27239': FederalStates.NI,
  '27243': FederalStates.NI,
  '27245': FederalStates.NI,
  '27246': FederalStates.NI,
  '27248': FederalStates.NI,
  '27249': FederalStates.NI,
  '27251': FederalStates.NI,
  '27252': FederalStates.NI,
  '27254': FederalStates.NI,
  '27257': FederalStates.NI,
  '27259': FederalStates.NI,
  '27283': FederalStates.NI,
  '27299': FederalStates.NI,
  '27305': FederalStates.NI,
  '27308': FederalStates.NI,
  '27313': FederalStates.NI,
  '27318': FederalStates.NI,
  '27321': FederalStates.NI,
  '27324': FederalStates.NI,
  '27327': FederalStates.NI,
  '27330': FederalStates.NI,
  '27333': FederalStates.NI,
  '27336': FederalStates.NI,
  '27337': FederalStates.NI,
  '27339': FederalStates.NI,
  '27356': FederalStates.NI,
  '27367': FederalStates.NI,
  '27374': FederalStates.NI,
  '27383': FederalStates.NI,
  '27386': FederalStates.NI,
  '27389': FederalStates.NI,
  '27404': FederalStates.NI,
  '27412': FederalStates.NI,
  '27419': FederalStates.NI,
  '27432': FederalStates.NI,
  '27442': FederalStates.NI,
  '27446': FederalStates.NI,
  '27449': FederalStates.NI,
  '27472': FederalStates.NI,
  '27474': FederalStates.NI,
  '27476': FederalStates.NI,
  '27478': FederalStates.NI,
  '27498': FederalStates.SH,
  '27499': FederalStates.HH,
  '27568': FederalStates.HB,
  '27570': FederalStates.HB,
  '27572': FederalStates.HB,
  '27574': FederalStates.HB,
  '27576': FederalStates.HB,
  '27578': FederalStates.HB,
  '27580': FederalStates.HB,
  '27607': FederalStates.NI,
  '27612': FederalStates.NI,
  '27616': FederalStates.NI,
  '27619': FederalStates.NI,
  '27624': FederalStates.NI,
  '27628': FederalStates.NI,
  '27637': FederalStates.NI,
  '27638': FederalStates.NI,
  '27639': FederalStates.NI,
  '27711': FederalStates.NI,
  '27721': FederalStates.NI,
  '27726': FederalStates.NI,
  '27729': FederalStates.NI,
  '27749': FederalStates.NI,
  '27751': FederalStates.NI,
  '27753': FederalStates.NI,
  '27755': FederalStates.NI,
  '27777': FederalStates.NI,
  '27793': FederalStates.NI,
  '27798': FederalStates.NI,
  '27801': FederalStates.NI,
  '27804': FederalStates.NI,
  '27809': FederalStates.NI,
  '28195': FederalStates.HB,
  '28197': FederalStates.HB,
  '28199': FederalStates.HB,
  '28201': FederalStates.HB,
  '28203': FederalStates.HB,
  '28205': FederalStates.HB,
  '28207': FederalStates.HB,
  '28209': FederalStates.HB,
  '28211': FederalStates.HB,
  '28213': FederalStates.HB,
  '28215': FederalStates.HB,
  '28217': FederalStates.HB,
  '28219': FederalStates.HB,
  '28237': FederalStates.HB,
  '28239': FederalStates.HB,
  '28259': FederalStates.HB,
  '28277': FederalStates.HB,
  '28279': FederalStates.HB,
  '28307': FederalStates.HB,
  '28309': FederalStates.HB,
  '28325': FederalStates.HB,
  '28327': FederalStates.HB,
  '28329': FederalStates.HB,
  '28335': FederalStates.HB,
  '28355': FederalStates.HB,
  '28357': FederalStates.HB,
  '28359': FederalStates.HB,
  '28717': FederalStates.HB,
  '28719': FederalStates.HB,
  '28755': FederalStates.HB,
  '28757': FederalStates.HB,
  '28759': FederalStates.HB,
  '28777': FederalStates.HB,
  '28779': FederalStates.HB,
  '28790': FederalStates.NI,
  '28816': FederalStates.NI,
  '28832': FederalStates.NI,
  '28844': FederalStates.NI,
  '28857': FederalStates.NI,
  '28865': FederalStates.NI,
  '28870': FederalStates.NI,
  '28876': FederalStates.NI,
  '28879': FederalStates.NI,
  '29221': FederalStates.NI,
  '29223': FederalStates.NI,
  '29225': FederalStates.NI,
  '29227': FederalStates.NI,
  '29229': FederalStates.NI,
  '29303': FederalStates.NI,
  '29308': FederalStates.NI,
  '29313': FederalStates.NI,
  '29320': FederalStates.NI,
  '29323': FederalStates.NI,
  '29328': FederalStates.NI,
  '29331': FederalStates.NI,
  '29336': FederalStates.NI,
  '29339': FederalStates.NI,
  '29342': FederalStates.NI,
  '29345': FederalStates.NI,
  '29348': FederalStates.NI,
  '29351': FederalStates.NI,
  '29352': FederalStates.NI,
  '29353': FederalStates.NI,
  '29355': FederalStates.NI,
  '29356': FederalStates.NI,
  '29358': FederalStates.NI,
  '29359': FederalStates.NI,
  '29361': FederalStates.NI,
  '29362': FederalStates.NI,
  '29364': FederalStates.NI,
  '29365': FederalStates.NI,
  '29367': FederalStates.NI,
  '29369': FederalStates.NI,
  '29378': FederalStates.NI,
  '29379': FederalStates.NI,
  '29386': FederalStates.NI,
  '29389': FederalStates.NI,
  '29392': FederalStates.NI,
  '29393': FederalStates.NI,
  '29394': FederalStates.NI,
  '29396': FederalStates.NI,
  '29399': FederalStates.NI,
  '29410': FederalStates.ST,
  '29413': FederalStates.ST,
  '29416': FederalStates.ST,
  '29439': FederalStates.NI,
  '29451': FederalStates.NI,
  '29456': FederalStates.NI,
  '29459': FederalStates.NI,
  '29462': FederalStates.NI,
  '29465': FederalStates.NI,
  '29468': FederalStates.NI,
  '29471': FederalStates.NI,
  '29472': FederalStates.NI,
  '29473': FederalStates.NI,
  '29475': FederalStates.NI,
  '29476': FederalStates.NI,
  '29478': FederalStates.NI,
  '29479': FederalStates.NI,
  '29481': FederalStates.NI,
  '29482': FederalStates.NI,
  '29484': FederalStates.NI,
  '29485': FederalStates.NI,
  '29487': FederalStates.NI,
  '29488': FederalStates.NI,
  '29490': FederalStates.NI,
  '29491': FederalStates.NI,
  '29493': FederalStates.NI,
  '29494': FederalStates.NI,
  '29496': FederalStates.NI,
  '29497': FederalStates.NI,
  '29499': FederalStates.NI,
  '29525': FederalStates.NI,
  '29549': FederalStates.NI,
  '29553': FederalStates.NI,
  '29556': FederalStates.NI,
  '29559': FederalStates.NI,
  '29562': FederalStates.NI,
  '29565': FederalStates.NI,
  '29568': FederalStates.NI,
  '29571': FederalStates.NI,
  '29574': FederalStates.NI,
  '29575': FederalStates.NI,
  '29576': FederalStates.NI,
  '29578': FederalStates.NI,
  '29579': FederalStates.NI,
  '29581': FederalStates.NI,
  '29582': FederalStates.NI,
  '29584': FederalStates.NI,
  '29585': FederalStates.NI,
  '29587': FederalStates.NI,
  '29588': FederalStates.NI,
  '29590': FederalStates.NI,
  '29591': FederalStates.NI,
  '29593': FederalStates.NI,
  '29594': FederalStates.NI,
  '29596': FederalStates.NI,
  '29597': FederalStates.NI,
  '29599': FederalStates.NI,
  '29614': FederalStates.NI,
  '29633': FederalStates.NI,
  '29640': FederalStates.NI,
  '29643': FederalStates.NI,
  '29646': FederalStates.NI,
  '29649': FederalStates.NI,
  '29664': FederalStates.NI,
  '29683': FederalStates.NI,
  '29690': FederalStates.NI,
  '29693': FederalStates.NI,
  '29699': FederalStates.NI,
  '30159': FederalStates.NI,
  '30161': FederalStates.NI,
  '30163': FederalStates.NI,
  '30165': FederalStates.NI,
  '30167': FederalStates.NI,
  '30169': FederalStates.NI,
  '30171': FederalStates.NI,
  '30173': FederalStates.NI,
  '30175': FederalStates.NI,
  '30177': FederalStates.NI,
  '30179': FederalStates.NI,
  '30419': FederalStates.NI,
  '30449': FederalStates.NI,
  '30451': FederalStates.NI,
  '30453': FederalStates.NI,
  '30455': FederalStates.NI,
  '30457': FederalStates.NI,
  '30459': FederalStates.NI,
  '30519': FederalStates.NI,
  '30521': FederalStates.NI,
  '30539': FederalStates.NI,
  '30559': FederalStates.NI,
  '30625': FederalStates.NI,
  '30627': FederalStates.NI,
  '30629': FederalStates.NI,
  '30655': FederalStates.NI,
  '30657': FederalStates.NI,
  '30659': FederalStates.NI,
  '30669': FederalStates.NI,
  '30823': FederalStates.NI,
  '30826': FederalStates.NI,
  '30827': FederalStates.NI,
  '30851': FederalStates.NI,
  '30853': FederalStates.NI,
  '30855': FederalStates.NI,
  '30880': FederalStates.NI,
  '30890': FederalStates.NI,
  '30900': FederalStates.NI,
  '30916': FederalStates.NI,
  '30926': FederalStates.NI,
  '30938': FederalStates.NI,
  '30952': FederalStates.NI,
  '30966': FederalStates.NI,
  '30974': FederalStates.NI,
  '30982': FederalStates.NI,
  '30989': FederalStates.NI,
  '31008': FederalStates.NI,
  '31020': FederalStates.NI,
  '31028': FederalStates.NI,
  '31029': FederalStates.NI,
  '31032': FederalStates.NI,
  '31033': FederalStates.NI,
  '31036': FederalStates.NI,
  '31039': FederalStates.NI,
  '31061': FederalStates.NI,
  '31073': FederalStates.NI,
  '31079': FederalStates.NI,
  '31084': FederalStates.NI,
  '31085': FederalStates.NI,
  '31088': FederalStates.NI,
  '31089': FederalStates.NI,
  '31091': FederalStates.NI,
  '31093': FederalStates.NI,
  '31094': FederalStates.NI,
  '31096': FederalStates.NI,
  '31097': FederalStates.NI,
  '31099': FederalStates.NI,
  '31134': FederalStates.NI,
  '31135': FederalStates.NI,
  '31137': FederalStates.NI,
  '31139': FederalStates.NI,
  '31141': FederalStates.NI,
  '31157': FederalStates.NI,
  '31162': FederalStates.NI,
  '31167': FederalStates.NI,
  '31171': FederalStates.NI,
  '31174': FederalStates.NI,
  '31177': FederalStates.NI,
  '31180': FederalStates.NI,
  '31185': FederalStates.NI,
  '31188': FederalStates.NI,
  '31191': FederalStates.NI,
  '31195': FederalStates.NI,
  '31196': FederalStates.NI,
  '31199': FederalStates.NI,
  '31224': FederalStates.NI,
  '31226': FederalStates.NI,
  '31228': FederalStates.NI,
  '31234': FederalStates.NI,
  '31241': FederalStates.NI,
  '31246': FederalStates.NI,
  '31249': FederalStates.NI,
  '31275': FederalStates.NI,
  '31303': FederalStates.NI,
  '31311': FederalStates.NI,
  '31319': FederalStates.NI,
  '31515': FederalStates.NI,
  '31535': FederalStates.NI,
  '31542': FederalStates.NI,
  '31547': FederalStates.NI,
  '31552': FederalStates.NI,
  '31553': FederalStates.NI,
  '31555': FederalStates.NI,
  '31556': FederalStates.NI,
  '31558': FederalStates.NI,
  '31559': FederalStates.NI,
  '31582': FederalStates.NI,
  '31592': FederalStates.NI,
  '31595': FederalStates.NI,
  '31600': FederalStates.NI,
  '31603': FederalStates.NI,
  '31604': FederalStates.NI,
  '31606': FederalStates.NI,
  '31608': FederalStates.NI,
  '31609': FederalStates.NI,
  '31613': FederalStates.NI,
  '31618': FederalStates.NI,
  '31619': FederalStates.NI,
  '31621': FederalStates.NI,
  '31622': FederalStates.NI,
  '31623': FederalStates.NI,
  '31626': FederalStates.NI,
  '31627': FederalStates.NI,
  '31628': FederalStates.NI,
  '31629': FederalStates.NI,
  '31632': FederalStates.NI,
  '31633': FederalStates.NI,
  '31634': FederalStates.NI,
  '31636': FederalStates.NI,
  '31637': FederalStates.NI,
  '31638': FederalStates.NI,
  '31655': FederalStates.NI,
  '31675': FederalStates.NI,
  '31683': FederalStates.NI,
  '31688': FederalStates.NI,
  '31691': FederalStates.NI,
  '31693': FederalStates.NI,
  '31698': FederalStates.NI,
  '31699': FederalStates.NI,
  '31700': FederalStates.NI,
  '31702': FederalStates.NI,
  '31707': FederalStates.NI,
  '31708': FederalStates.NI,
  '31710': FederalStates.NI,
  '31711': FederalStates.NI,
  '31712': FederalStates.NI,
  '31714': FederalStates.NI,
  '31715': FederalStates.NI,
  '31717': FederalStates.NI,
  '31718': FederalStates.NI,
  '31719': FederalStates.NI,
  '31737': FederalStates.NI,
  '31749': FederalStates.NI,
  '31785': FederalStates.NI,
  '31787': FederalStates.NI,
  '31789': FederalStates.NI,
  '31812': FederalStates.NI,
  '31832': FederalStates.NI,
  '31840': FederalStates.NI,
  '31848': FederalStates.NI,
  '31855': FederalStates.NI,
  '31860': FederalStates.NI,
  '31863': FederalStates.NI,
  '31867': FederalStates.NI,
  '31868': FederalStates.NI,
  '32049': FederalStates.NW,
  '32051': FederalStates.NW,
  '32052': FederalStates.NW,
  '32105': FederalStates.NW,
  '32107': FederalStates.NW,
  '32108': FederalStates.NW,
  '32120': FederalStates.NW,
  '32130': FederalStates.NW,
  '32139': FederalStates.NW,
  '32257': FederalStates.NW,
  '32278': FederalStates.NW,
  '32289': FederalStates.NW,
  '32312': FederalStates.NW,
  '32339': FederalStates.NW,
  '32351': FederalStates.NW,
  '32361': FederalStates.NW,
  '32369': FederalStates.NW,
  '32423': FederalStates.NW,
  '32425': FederalStates.NW,
  '32427': FederalStates.NW,
  '32429': FederalStates.NW,
  '32457': FederalStates.NW,
  '32469': FederalStates.NW,
  '32479': FederalStates.NW,
  '32545': FederalStates.NW,
  '32547': FederalStates.NW,
  '32549': FederalStates.NW,
  '32584': FederalStates.NW,
  '32602': FederalStates.NW,
  '32609': FederalStates.NW,
  '32657': FederalStates.NW,
  '32676': FederalStates.NW,
  '32683': FederalStates.NW,
  '32689': FederalStates.NW,
  '32694': FederalStates.NW,
  '32699': FederalStates.NW,
  '32756': FederalStates.NW,
  '32758': FederalStates.NW,
  '32760': FederalStates.NW,
  '32791': FederalStates.NW,
  '32805': FederalStates.NW,
  '32816': FederalStates.NW,
  '32825': FederalStates.NW,
  '32832': FederalStates.NW,
  '32839': FederalStates.NW,
  '33014': FederalStates.NW,
  '33034': FederalStates.NW,
  '33039': FederalStates.NW,
  '33098': FederalStates.NW,
  '33100': FederalStates.NW,
  '33102': FederalStates.NW,
  '33104': FederalStates.NW,
  '33106': FederalStates.NW,
  '33129': FederalStates.NW,
  '33142': FederalStates.NW,
  '33154': FederalStates.NW,
  '33161': FederalStates.NW,
  '33165': FederalStates.NW,
  '33175': FederalStates.NW,
  '33178': FederalStates.NW,
  '33181': FederalStates.NW,
  '33184': FederalStates.NW,
  '33189': FederalStates.NW,
  '33311': FederalStates.NW,
  '33330': FederalStates.NW,
  '33332': FederalStates.NW,
  '33334': FederalStates.NW,
  '33335': FederalStates.NW,
  '33378': FederalStates.NW,
  '33397': FederalStates.NW,
  '33415': FederalStates.NW,
  '33428': FederalStates.NW,
  '33442': FederalStates.NW,
  '33449': FederalStates.NW,
  '33519': FederalStates.NW,
  '33602': FederalStates.NW,
  '33604': FederalStates.NW,
  '33605': FederalStates.NW,
  '33607': FederalStates.NW,
  '33609': FederalStates.NW,
  '33611': FederalStates.NW,
  '33613': FederalStates.NW,
  '33615': FederalStates.NW,
  '33617': FederalStates.NW,
  '33619': FederalStates.NW,
  '33647': FederalStates.NW,
  '33649': FederalStates.NW,
  '33659': FederalStates.NW,
  '33689': FederalStates.NW,
  '33699': FederalStates.NW,
  '33719': FederalStates.NW,
  '33729': FederalStates.NW,
  '33739': FederalStates.NW,
  '33758': FederalStates.NW,
  '33775': FederalStates.NW,
  '33790': FederalStates.NW,
  '33803': FederalStates.NW,
  '33813': FederalStates.NW,
  '33818': FederalStates.NW,
  '33824': FederalStates.NW,
  '33829': FederalStates.NW,
  '34117': FederalStates.HE,
  '34119': FederalStates.HE,
  '34121': FederalStates.HE,
  '34123': FederalStates.HE,
  '34125': FederalStates.HE,
  '34127': FederalStates.HE,
  '34128': FederalStates.HE,
  '34130': FederalStates.HE,
  '34131': FederalStates.HE,
  '34132': FederalStates.HE,
  '34134': FederalStates.HE,
  '34212': FederalStates.HE,
  '34225': FederalStates.HE,
  '34233': FederalStates.HE,
  '34246': FederalStates.HE,
  '34253': FederalStates.HE,
  '34260': FederalStates.HE,
  '34266': FederalStates.HE,
  '34270': FederalStates.HE,
  '34277': FederalStates.HE,
  '34281': FederalStates.HE,
  '34286': FederalStates.HE,
  '34289': FederalStates.HE,
  '34292': FederalStates.HE,
  '34295': FederalStates.HE,
  '34298': FederalStates.HE,
  '34302': FederalStates.HE,
  '34305': FederalStates.HE,
  '34308': FederalStates.HE,
  '34311': FederalStates.HE,
  '34314': FederalStates.HE,
  '34317': FederalStates.HE,
  '34320': FederalStates.HE,
  '34323': FederalStates.HE,
  '34326': FederalStates.HE,
  '34327': FederalStates.HE,
  '34329': FederalStates.HE,
  '34346': FederalStates.NI,
  '34355': FederalStates.NI,
  '34359': FederalStates.HE,
  '34369': FederalStates.HE,
  '34376': FederalStates.HE,
  '34379': FederalStates.HE,
  '34385': FederalStates.HE,
  '34388': FederalStates.HE,
  '34393': FederalStates.HE,
  '34396': FederalStates.HE,
  '34399': FederalStates.HE,
  '34414': FederalStates.NW,
  '34431': FederalStates.NW,
  '34434': FederalStates.NW,
  '34439': FederalStates.NW,
  '34454': FederalStates.HE,
  '34466': FederalStates.HE,
  '34471': FederalStates.HE,
  '34474': FederalStates.HE,
  '34477': FederalStates.HE,
  '34479': FederalStates.HE,
  '34497': FederalStates.HE,
  '34508': FederalStates.HE,
  '34513': FederalStates.HE,
  '34516': FederalStates.HE,
  '34519': FederalStates.HE,
  '34537': FederalStates.HE,
  '34549': FederalStates.HE,
  '34560': FederalStates.HE,
  '34576': FederalStates.HE,
  '34582': FederalStates.HE,
  '34587': FederalStates.HE,
  '34590': FederalStates.HE,
  '34593': FederalStates.HE,
  '34596': FederalStates.HE,
  '34599': FederalStates.HE,
  '34613': FederalStates.HE,
  '34621': FederalStates.HE,
  '34626': FederalStates.HE,
  '34628': FederalStates.HE,
  '34630': FederalStates.HE,
  '34632': FederalStates.HE,
  '34633': FederalStates.HE,
  '34637': FederalStates.HE,
  '34639': FederalStates.HE,
  '35037': FederalStates.HE,
  '35039': FederalStates.HE,
  '35041': FederalStates.HE,
  '35043': FederalStates.HE,
  '35066': FederalStates.HE,
  '35075': FederalStates.HE,
  '35080': FederalStates.HE,
  '35083': FederalStates.HE,
  '35085': FederalStates.HE,
  '35088': FederalStates.HE,
  '35091': FederalStates.HE,
  '35094': FederalStates.HE,
  '35096': FederalStates.HE,
  '35099': FederalStates.HE,
  '35102': FederalStates.HE,
  '35104': FederalStates.HE,
  '35108': FederalStates.HE,
  '35110': FederalStates.HE,
  '35112': FederalStates.HE,
  '35114': FederalStates.HE,
  '35116': FederalStates.HE,
  '35117': FederalStates.HE,
  '35119': FederalStates.HE,
  '35216': FederalStates.HE,
  '35232': FederalStates.HE,
  '35236': FederalStates.HE,
  '35239': FederalStates.HE,
  '35260': FederalStates.HE,
  '35274': FederalStates.HE,
  '35279': FederalStates.HE,
  '35282': FederalStates.HE,
  '35285': FederalStates.HE,
  '35287': FederalStates.HE,
  '35288': FederalStates.HE,
  '35305': FederalStates.HE,
  '35315': FederalStates.HE,
  '35321': FederalStates.HE,
  '35325': FederalStates.HE,
  '35327': FederalStates.HE,
  '35329': FederalStates.HE,
  '35390': FederalStates.HE,
  '35392': FederalStates.HE,
  '35394': FederalStates.HE,
  '35396': FederalStates.HE,
  '35398': FederalStates.HE,
  '35410': FederalStates.HE,
  '35415': FederalStates.HE,
  '35418': FederalStates.HE,
  '35423': FederalStates.HE,
  '35428': FederalStates.HE,
  '35435': FederalStates.HE,
  '35440': FederalStates.HE,
  '35444': FederalStates.HE,
  '35447': FederalStates.HE,
  '35452': FederalStates.HE,
  '35457': FederalStates.HE,
  '35460': FederalStates.HE,
  '35463': FederalStates.HE,
  '35466': FederalStates.HE,
  '35469': FederalStates.HE,
  '35510': FederalStates.HE,
  '35516': FederalStates.HE,
  '35519': FederalStates.HE,
  '35576': FederalStates.HE,
  '35578': FederalStates.HE,
  '35579': FederalStates.HE,
  '35580': FederalStates.HE,
  '35581': FederalStates.HE,
  '35582': FederalStates.HE,
  '35583': FederalStates.HE,
  '35584': FederalStates.HE,
  '35585': FederalStates.HE,
  '35586': FederalStates.HE,
  '35606': FederalStates.HE,
  '35614': FederalStates.HE,
  '35619': FederalStates.HE,
  '35625': FederalStates.HE,
  '35630': FederalStates.HE,
  '35633': FederalStates.HE,
  '35638': FederalStates.HE,
  '35641': FederalStates.HE,
  '35644': FederalStates.HE,
  '35647': FederalStates.HE,
  '35649': FederalStates.HE,
  '35683': FederalStates.HE,
  '35684': FederalStates.HE,
  '35685': FederalStates.HE,
  '35686': FederalStates.HE,
  '35687': FederalStates.HE,
  '35688': FederalStates.HE,
  '35689': FederalStates.HE,
  '35690': FederalStates.HE,
  '35708': FederalStates.HE,
  '35713': FederalStates.HE,
  '35716': FederalStates.HE,
  '35719': FederalStates.HE,
  '35745': FederalStates.HE,
  '35753': FederalStates.HE,
  '35756': FederalStates.HE,
  '35759': FederalStates.HE,
  '35764': FederalStates.HE,
  '35767': FederalStates.HE,
  '35768': FederalStates.HE,
  '35781': FederalStates.HE,
  '35789': FederalStates.HE,
  '35792': FederalStates.HE,
  '35794': FederalStates.HE,
  '35796': FederalStates.HE,
  '35799': FederalStates.HE,
  '36037': FederalStates.HE,
  '36039': FederalStates.HE,
  '36041': FederalStates.HE,
  '36043': FederalStates.HE,
  '36088': FederalStates.HE,
  '36093': FederalStates.HE,
  '36100': FederalStates.HE,
  '36103': FederalStates.HE,
  '36110': FederalStates.HE,
  '36115': FederalStates.HE,
  '36119': FederalStates.HE,
  '36124': FederalStates.HE,
  '36129': FederalStates.HE,
  '36132': FederalStates.HE,
  '36137': FederalStates.HE,
  '36142': FederalStates.HE,
  '36145': FederalStates.HE,
  '36148': FederalStates.HE,
  '36151': FederalStates.HE,
  '36154': FederalStates.HE,
  '36157': FederalStates.HE,
  '36160': FederalStates.HE,
  '36163': FederalStates.HE,
  '36166': FederalStates.HE,
  '36167': FederalStates.HE,
  '36169': FederalStates.HE,
  '36179': FederalStates.HE,
  '36199': FederalStates.HE,
  '36205': FederalStates.HE,
  '36208': FederalStates.HE,
  '36211': FederalStates.HE,
  '36214': FederalStates.HE,
  '36217': FederalStates.HE,
  '36219': FederalStates.HE,
  '36251': FederalStates.HE,
  '36266': FederalStates.HE,
  '36269': FederalStates.HE,
  '36272': FederalStates.HE,
  '36275': FederalStates.HE,
  '36277': FederalStates.HE,
  '36280': FederalStates.HE,
  '36282': FederalStates.HE,
  '36284': FederalStates.HE,
  '36286': FederalStates.HE,
  '36287': FederalStates.HE,
  '36289': FederalStates.HE,
  '36304': FederalStates.HE,
  '36318': FederalStates.HE,
  '36320': FederalStates.HE,
  '36323': FederalStates.HE,
  '36325': FederalStates.HE,
  '36326': FederalStates.HE,
  '36329': FederalStates.HE,
  '36341': FederalStates.HE,
  '36355': FederalStates.HE,
  '36358': FederalStates.HE,
  '36364': FederalStates.HE,
  '36367': FederalStates.HE,
  '36369': FederalStates.HE,
  '36381': FederalStates.HE,
  '36391': FederalStates.HE,
  '36396': FederalStates.HE,
  '36399': FederalStates.HE,
  '36404': FederalStates.TH,
  '36414': FederalStates.TH,
  '36419': FederalStates.TH,
  '36433': FederalStates.TH,
  '36448': FederalStates.TH,
  '36452': FederalStates.TH,
  '36456': FederalStates.TH,
  '36457': FederalStates.TH,
  '36460': FederalStates.TH,
  '36466': FederalStates.TH,
  '36469': FederalStates.TH,
  '37073': FederalStates.NI,
  '37075': FederalStates.NI,
  '37077': FederalStates.NI,
  '37079': FederalStates.NI,
  '37081': FederalStates.NI,
  '37083': FederalStates.NI,
  '37085': FederalStates.NI,
  '37115': FederalStates.NI,
  '37120': FederalStates.NI,
  '37124': FederalStates.NI,
  '37127': FederalStates.NI,
  '37130': FederalStates.NI,
  '37133': FederalStates.NI,
  '37136': FederalStates.NI,
  '37139': FederalStates.NI,
  '37154': FederalStates.NI,
  '37170': FederalStates.NI,
  '37176': FederalStates.NI,
  '37181': FederalStates.NI,
  '37186': FederalStates.NI,
  '37191': FederalStates.NI,
  '37194': FederalStates.NI,
  '37197': FederalStates.NI,
  '37199': FederalStates.NI,
  '37213': FederalStates.HE,
  '37214': FederalStates.HE,
  '37215': FederalStates.HE,
  '37216': FederalStates.HE,
  '37217': FederalStates.HE,
  '37218': FederalStates.HE,
  '37235': FederalStates.HE,
  '37242': FederalStates.HE,
  '37247': FederalStates.HE,
  '37249': FederalStates.HE,
  '37269': FederalStates.HE,
  '37276': FederalStates.HE,
  '37281': FederalStates.HE,
  '37284': FederalStates.HE,
  '37287': FederalStates.HE,
  '37290': FederalStates.HE,
  '37293': FederalStates.HE,
  '37296': FederalStates.HE,
  '37297': FederalStates.HE,
  '37299': FederalStates.HE,
  '37308': FederalStates.TH,
  '37318': FederalStates.TH,
  '37327': FederalStates.TH,
  '37339': FederalStates.TH,
  '37345': FederalStates.TH,
  '37351': FederalStates.TH,
  '37355': FederalStates.TH,
  '37359': FederalStates.TH,
  '37412': FederalStates.NI,
  '37431': FederalStates.NI,
  '37434': FederalStates.NI,
  '37441': FederalStates.NI,
  '37444': FederalStates.NI,
  '37445': FederalStates.NI,
  '37447': FederalStates.NI,
  '37449': FederalStates.NI,
  '37520': FederalStates.NI,
  '37534': FederalStates.NI,
  '37539': FederalStates.NI,
  '37547': FederalStates.NI,
  '37574': FederalStates.NI,
  '37581': FederalStates.NI,
  '37586': FederalStates.NI,
  '37589': FederalStates.NI,
  '37603': FederalStates.NI,
  '37619': FederalStates.NI,
  '37620': FederalStates.NI,
  '37627': FederalStates.NI,
  '37632': FederalStates.NI,
  '37633': FederalStates.NI,
  '37635': FederalStates.NI,
  '37639': FederalStates.NI,
  '37640': FederalStates.NI,
  '37642': FederalStates.NI,
  '37643': FederalStates.NI,
  '37647': FederalStates.NI,
  '37649': FederalStates.NI,
  '37671': FederalStates.NW,
  '37688': FederalStates.NW,
  '37691': FederalStates.NI,
  '37696': FederalStates.NW,
  '37697': FederalStates.NI,
  '37699': FederalStates.NI,
  '38023': FederalStates.NI,
  '38100': FederalStates.NI,
  '38102': FederalStates.NI,
  '38104': FederalStates.NI,
  '38106': FederalStates.NI,
  '38108': FederalStates.NI,
  '38110': FederalStates.NI,
  '38112': FederalStates.NI,
  '38114': FederalStates.NI,
  '38116': FederalStates.NI,
  '38118': FederalStates.NI,
  '38120': FederalStates.NI,
  '38122': FederalStates.NI,
  '38124': FederalStates.NI,
  '38126': FederalStates.NI,
  '38154': FederalStates.NI,
  '38159': FederalStates.NI,
  '38162': FederalStates.NI,
  '38165': FederalStates.NI,
  '38170': FederalStates.NI,
  '38173': FederalStates.NI,
  '38176': FederalStates.NI,
  '38179': FederalStates.NI,
  '38226': FederalStates.NI,
  '38228': FederalStates.NI,
  '38229': FederalStates.NI,
  '38239': FederalStates.NI,
  '38259': FederalStates.NI,
  '38268': FederalStates.NI,
  '38271': FederalStates.NI,
  '38272': FederalStates.NI,
  '38274': FederalStates.NI,
  '38275': FederalStates.NI,
  '38277': FederalStates.NI,
  '38279': FederalStates.NI,
  '38281': FederalStates.NI,
  '38300': FederalStates.NI,
  '38302': FederalStates.NI,
  '38304': FederalStates.NI,
  '38312': FederalStates.NI,
  '38315': FederalStates.NI,
  '38319': FederalStates.NI,
  '38321': FederalStates.NI,
  '38322': FederalStates.NI,
  '38324': FederalStates.NI,
  '38325': FederalStates.NI,
  '38327': FederalStates.NI,
  '38329': FederalStates.NI,
  '38350': FederalStates.NI,
  '38364': FederalStates.NI,
  '38368': FederalStates.NI,
  '38372': FederalStates.NI,
  '38373': FederalStates.NI,
  '38375': FederalStates.NI,
  '38376': FederalStates.NI,
  '38378': FederalStates.NI,
  '38379': FederalStates.NI,
  '38381': FederalStates.NI,
  '38382': FederalStates.NI,
  '38384': FederalStates.NI,
  '38385': FederalStates.NI,
  '38387': FederalStates.NI,
  '38388': FederalStates.NI,
  '38440': FederalStates.NI,
  '38442': FederalStates.NI,
  '38444': FederalStates.NI,
  '38446': FederalStates.NI,
  '38448': FederalStates.NI,
  '38458': FederalStates.NI,
  '38459': FederalStates.NI,
  '38461': FederalStates.NI,
  '38462': FederalStates.NI,
  '38464': FederalStates.NI,
  '38465': FederalStates.NI,
  '38467': FederalStates.NI,
  '38468': FederalStates.NI,
  '38470': FederalStates.NI,
  '38471': FederalStates.NI,
  '38473': FederalStates.NI,
  '38474': FederalStates.NI,
  '38476': FederalStates.NI,
  '38477': FederalStates.NI,
  '38479': FederalStates.NI,
  '38486': FederalStates.ST,
  '38489': FederalStates.ST,
  '38518': FederalStates.NI,
  '38524': FederalStates.NI,
  '38527': FederalStates.NI,
  '38528': FederalStates.NI,
  '38530': FederalStates.NI,
  '38531': FederalStates.NI,
  '38533': FederalStates.NI,
  '38536': FederalStates.NI,
  '38539': FederalStates.NI,
  '38542': FederalStates.NI,
  '38543': FederalStates.NI,
  '38547': FederalStates.NI,
  '38550': FederalStates.NI,
  '38551': FederalStates.NI,
  '38553': FederalStates.NI,
  '38554': FederalStates.NI,
  '38556': FederalStates.NI,
  '38557': FederalStates.NI,
  '38559': FederalStates.NI,
  '38640': FederalStates.NI,
  '38642': FederalStates.NI,
  '38644': FederalStates.NI,
  '38667': FederalStates.NI,
  '38678': FederalStates.NI,
  '38685': FederalStates.NI,
  '38690': FederalStates.NI,
  '38700': FederalStates.NI,
  '38704': FederalStates.NI,
  '38707': FederalStates.NI,
  '38709': FederalStates.NI,
  '38723': FederalStates.NI,
  '38729': FederalStates.NI,
  '38820': FederalStates.ST,
  '38822': FederalStates.ST,
  '38828': FederalStates.ST,
  '38829': FederalStates.ST,
  '38835': FederalStates.ST,
  '38836': FederalStates.ST,
  '38838': FederalStates.ST,
  '38855': FederalStates.ST,
  '38871': FederalStates.ST,
  '38875': FederalStates.ST,
  '38877': FederalStates.ST,
  '38879': FederalStates.ST,
  '38889': FederalStates.ST,
  '38895': FederalStates.ST,
  '38899': FederalStates.ST,
  '39014': FederalStates.ST,
  '39104': FederalStates.ST,
  '39106': FederalStates.ST,
  '39108': FederalStates.ST,
  '39110': FederalStates.ST,
  '39112': FederalStates.ST,
  '39114': FederalStates.ST,
  '39116': FederalStates.ST,
  '39118': FederalStates.ST,
  '39120': FederalStates.ST,
  '39122': FederalStates.ST,
  '39124': FederalStates.ST,
  '39126': FederalStates.ST,
  '39128': FederalStates.ST,
  '39130': FederalStates.ST,
  '39164': FederalStates.ST,
  '39167': FederalStates.ST,
  '39171': FederalStates.ST,
  '39175': FederalStates.ST,
  '39179': FederalStates.ST,
  '39217': FederalStates.ST,
  '39218': FederalStates.ST,
  '39221': FederalStates.ST,
  '39240': FederalStates.ST,
  '39245': FederalStates.ST,
  '39249': FederalStates.ST,
  '39261': FederalStates.ST,
  '39264': FederalStates.ST,
  '39279': FederalStates.ST,
  '39288': FederalStates.ST,
  '39291': FederalStates.ST,
  '39307': FederalStates.ST,
  '39317': FederalStates.ST,
  '39319': FederalStates.ST,
  '39326': FederalStates.ST,
  '39340': FederalStates.ST,
  '39343': FederalStates.ST,
  '39345': FederalStates.ST,
  '39356': FederalStates.ST,
  '39359': FederalStates.ST,
  '39365': FederalStates.ST,
  '39387': FederalStates.ST,
  '39393': FederalStates.ST,
  '39397': FederalStates.ST,
  '39398': FederalStates.ST,
  '39418': FederalStates.ST,
  '39434': FederalStates.ST,
  '39435': FederalStates.ST,
  '39439': FederalStates.ST,
  '39443': FederalStates.ST,
  '39444': FederalStates.ST,
  '39446': FederalStates.ST,
  '39448': FederalStates.ST,
  '39517': FederalStates.ST,
  '39524': FederalStates.ST,
  '39539': FederalStates.ST,
  '39576': FederalStates.ST,
  '39579': FederalStates.ST,
  '39590': FederalStates.ST,
  '39596': FederalStates.ST,
  '39599': FederalStates.ST,
  '39606': FederalStates.ST,
  '39615': FederalStates.ST,
  '39619': FederalStates.ST,
  '39624': FederalStates.ST,
  '39629': FederalStates.ST,
  '39638': FederalStates.ST,
  '39646': FederalStates.ST,
  '39649': FederalStates.ST,
  '40210': FederalStates.NW,
  '40211': FederalStates.NW,
  '40212': FederalStates.NW,
  '40213': FederalStates.NW,
  '40215': FederalStates.NW,
  '40217': FederalStates.NW,
  '40219': FederalStates.NW,
  '40221': FederalStates.NW,
  '40223': FederalStates.NW,
  '40225': FederalStates.NW,
  '40227': FederalStates.NW,
  '40229': FederalStates.NW,
  '40231': FederalStates.NW,
  '40233': FederalStates.NW,
  '40235': FederalStates.NW,
  '40237': FederalStates.NW,
  '40239': FederalStates.NW,
  '40468': FederalStates.NW,
  '40470': FederalStates.NW,
  '40472': FederalStates.NW,
  '40474': FederalStates.NW,
  '40476': FederalStates.NW,
  '40477': FederalStates.NW,
  '40479': FederalStates.NW,
  '40489': FederalStates.NW,
  '40545': FederalStates.NW,
  '40547': FederalStates.NW,
  '40549': FederalStates.NW,
  '40589': FederalStates.NW,
  '40591': FederalStates.NW,
  '40593': FederalStates.NW,
  '40595': FederalStates.NW,
  '40597': FederalStates.NW,
  '40599': FederalStates.NW,
  '40625': FederalStates.NW,
  '40627': FederalStates.NW,
  '40629': FederalStates.NW,
  '40667': FederalStates.NW,
  '40668': FederalStates.NW,
  '40670': FederalStates.NW,
  '40699': FederalStates.NW,
  '40721': FederalStates.NW,
  '40723': FederalStates.NW,
  '40724': FederalStates.NW,
  '40764': FederalStates.NW,
  '40789': FederalStates.NW,
  '40822': FederalStates.NW,
  '40832': FederalStates.NW,
  '40878': FederalStates.NW,
  '40880': FederalStates.NW,
  '40882': FederalStates.NW,
  '40883': FederalStates.NW,
  '40885': FederalStates.NW,
  '41061': FederalStates.NW,
  '41063': FederalStates.NW,
  '41065': FederalStates.NW,
  '41066': FederalStates.NW,
  '41068': FederalStates.NW,
  '41069': FederalStates.NW,
  '41169': FederalStates.NW,
  '41179': FederalStates.NW,
  '41189': FederalStates.NW,
  '41199': FederalStates.NW,
  '41236': FederalStates.NW,
  '41238': FederalStates.NW,
  '41239': FederalStates.NW,
  '41334': FederalStates.NW,
  '41352': FederalStates.NW,
  '41363': FederalStates.NW,
  '41366': FederalStates.NW,
  '41372': FederalStates.NW,
  '41379': FederalStates.NW,
  '41460': FederalStates.NW,
  '41462': FederalStates.NW,
  '41464': FederalStates.NW,
  '41466': FederalStates.NW,
  '41468': FederalStates.NW,
  '41469': FederalStates.NW,
  '41470': FederalStates.NW,
  '41472': FederalStates.NW,
  '41515': FederalStates.NW,
  '41516': FederalStates.NW,
  '41517': FederalStates.NW,
  '41539': FederalStates.NW,
  '41540': FederalStates.NW,
  '41541': FederalStates.NW,
  '41542': FederalStates.NW,
  '41564': FederalStates.NW,
  '41569': FederalStates.NW,
  '41747': FederalStates.NW,
  '41748': FederalStates.NW,
  '41749': FederalStates.NW,
  '41751': FederalStates.NW,
  '41812': FederalStates.NW,
  '41836': FederalStates.NW,
  '41844': FederalStates.NW,
  '41849': FederalStates.NW,
  '42103': FederalStates.NW,
  '42105': FederalStates.NW,
  '42107': FederalStates.NW,
  '42109': FederalStates.NW,
  '42111': FederalStates.NW,
  '42113': FederalStates.NW,
  '42115': FederalStates.NW,
  '42117': FederalStates.NW,
  '42119': FederalStates.NW,
  '42275': FederalStates.NW,
  '42277': FederalStates.NW,
  '42279': FederalStates.NW,
  '42281': FederalStates.NW,
  '42283': FederalStates.NW,
  '42285': FederalStates.NW,
  '42287': FederalStates.NW,
  '42289': FederalStates.NW,
  '42327': FederalStates.NW,
  '42329': FederalStates.NW,
  '42349': FederalStates.NW,
  '42369': FederalStates.NW,
  '42389': FederalStates.NW,
  '42399': FederalStates.NW,
  '42477': FederalStates.NW,
  '42489': FederalStates.NW,
  '42499': FederalStates.NW,
  '42549': FederalStates.NW,
  '42551': FederalStates.NW,
  '42553': FederalStates.NW,
  '42555': FederalStates.NW,
  '42579': FederalStates.NW,
  '42651': FederalStates.NW,
  '42653': FederalStates.NW,
  '42655': FederalStates.NW,
  '42657': FederalStates.NW,
  '42659': FederalStates.NW,
  '42697': FederalStates.NW,
  '42699': FederalStates.NW,
  '42719': FederalStates.NW,
  '42781': FederalStates.NW,
  '42799': FederalStates.NW,
  '42853': FederalStates.NW,
  '42855': FederalStates.NW,
  '42857': FederalStates.NW,
  '42859': FederalStates.NW,
  '42897': FederalStates.NW,
  '42899': FederalStates.NW,
  '42929': FederalStates.NW,
  '44135': FederalStates.NW,
  '44137': FederalStates.NW,
  '44139': FederalStates.NW,
  '44141': FederalStates.NW,
  '44143': FederalStates.NW,
  '44145': FederalStates.NW,
  '44147': FederalStates.NW,
  '44149': FederalStates.NW,
  '44225': FederalStates.NW,
  '44227': FederalStates.NW,
  '44229': FederalStates.NW,
  '44263': FederalStates.NW,
  '44265': FederalStates.NW,
  '44267': FederalStates.NW,
  '44269': FederalStates.NW,
  '44287': FederalStates.NW,
  '44289': FederalStates.NW,
  '44309': FederalStates.NW,
  '44319': FederalStates.NW,
  '44328': FederalStates.NW,
  '44329': FederalStates.NW,
  '44339': FederalStates.NW,
  '44357': FederalStates.NW,
  '44359': FederalStates.NW,
  '44369': FederalStates.NW,
  '44379': FederalStates.NW,
  '44388': FederalStates.NW,
  '44532': FederalStates.NW,
  '44534': FederalStates.NW,
  '44536': FederalStates.NW,
  '44575': FederalStates.NW,
  '44577': FederalStates.NW,
  '44579': FederalStates.NW,
  '44581': FederalStates.NW,
  '44623': FederalStates.NW,
  '44625': FederalStates.NW,
  '44627': FederalStates.NW,
  '44628': FederalStates.NW,
  '44629': FederalStates.NW,
  '44649': FederalStates.NW,
  '44651': FederalStates.NW,
  '44652': FederalStates.NW,
  '44653': FederalStates.NW,
  '44787': FederalStates.NW,
  '44789': FederalStates.NW,
  '44791': FederalStates.NW,
  '44793': FederalStates.NW,
  '44795': FederalStates.NW,
  '44797': FederalStates.NW,
  '44799': FederalStates.NW,
  '44801': FederalStates.NW,
  '44803': FederalStates.NW,
  '44805': FederalStates.NW,
  '44807': FederalStates.NW,
  '44809': FederalStates.NW,
  '44866': FederalStates.NW,
  '44867': FederalStates.NW,
  '44869': FederalStates.NW,
  '44879': FederalStates.NW,
  '44892': FederalStates.NW,
  '44894': FederalStates.NW,
  '45127': FederalStates.NW,
  '45128': FederalStates.NW,
  '45130': FederalStates.NW,
  '45131': FederalStates.NW,
  '45133': FederalStates.NW,
  '45134': FederalStates.NW,
  '45136': FederalStates.NW,
  '45138': FederalStates.NW,
  '45139': FederalStates.NW,
  '45141': FederalStates.NW,
  '45143': FederalStates.NW,
  '45144': FederalStates.NW,
  '45145': FederalStates.NW,
  '45147': FederalStates.NW,
  '45149': FederalStates.NW,
  '45219': FederalStates.NW,
  '45239': FederalStates.NW,
  '45257': FederalStates.NW,
  '45259': FederalStates.NW,
  '45276': FederalStates.NW,
  '45277': FederalStates.NW,
  '45279': FederalStates.NW,
  '45289': FederalStates.NW,
  '45307': FederalStates.NW,
  '45309': FederalStates.NW,
  '45326': FederalStates.NW,
  '45327': FederalStates.NW,
  '45329': FederalStates.NW,
  '45355': FederalStates.NW,
  '45356': FederalStates.NW,
  '45357': FederalStates.NW,
  '45359': FederalStates.NW,
  '45403': FederalStates.NW,
  '45468': FederalStates.NW,
  '45470': FederalStates.NW,
  '45472': FederalStates.NW,
  '45473': FederalStates.NW,
  '45475': FederalStates.NW,
  '45476': FederalStates.NW,
  '45478': FederalStates.NW,
  '45479': FederalStates.NW,
  '45481': FederalStates.NW,
  '45525': FederalStates.NW,
  '45527': FederalStates.NW,
  '45529': FederalStates.NW,
  '45549': FederalStates.NW,
  '45657': FederalStates.NW,
  '45659': FederalStates.NW,
  '45661': FederalStates.NW,
  '45663': FederalStates.NW,
  '45665': FederalStates.NW,
  '45699': FederalStates.NW,
  '45701': FederalStates.NW,
  '45711': FederalStates.NW,
  '45721': FederalStates.NW,
  '45731': FederalStates.NW,
  '45739': FederalStates.NW,
  '45768': FederalStates.NW,
  '45770': FederalStates.NW,
  '45772': FederalStates.NW,
  '45879': FederalStates.NW,
  '45881': FederalStates.NW,
  '45883': FederalStates.NW,
  '45884': FederalStates.NW,
  '45886': FederalStates.NW,
  '45888': FederalStates.NW,
  '45889': FederalStates.NW,
  '45891': FederalStates.NW,
  '45892': FederalStates.NW,
  '45894': FederalStates.NW,
  '45896': FederalStates.NW,
  '45897': FederalStates.NW,
  '45899': FederalStates.NW,
  '45964': FederalStates.NW,
  '45966': FederalStates.NW,
  '45968': FederalStates.NW,
  '46045': FederalStates.NW,
  '46047': FederalStates.NW,
  '46049': FederalStates.NW,
  '46117': FederalStates.NW,
  '46119': FederalStates.NW,
  '46145': FederalStates.NW,
  '46147': FederalStates.NW,
  '46149': FederalStates.NW,
  '46236': FederalStates.NW,
  '46238': FederalStates.NW,
  '46240': FederalStates.NW,
  '46242': FederalStates.NW,
  '46244': FederalStates.NW,
  '46282': FederalStates.NW,
  '46284': FederalStates.NW,
  '46286': FederalStates.NW,
  '46325': FederalStates.NW,
  '46342': FederalStates.NW,
  '46348': FederalStates.NW,
  '46354': FederalStates.NW,
  '46359': FederalStates.NW,
  '46395': FederalStates.NW,
  '46397': FederalStates.NW,
  '46399': FederalStates.NW,
  '46414': FederalStates.NW,
  '46419': FederalStates.NW,
  '46446': FederalStates.NW,
  '46459': FederalStates.NW,
  '46483': FederalStates.NW,
  '46485': FederalStates.NW,
  '46487': FederalStates.NW,
  '46499': FederalStates.NW,
  '46509': FederalStates.NW,
  '46514': FederalStates.NW,
  '46519': FederalStates.NW,
  '46535': FederalStates.NW,
  '46537': FederalStates.NW,
  '46539': FederalStates.NW,
  '46562': FederalStates.NW,
  '46569': FederalStates.NW,
  '47051': FederalStates.NW,
  '47053': FederalStates.NW,
  '47055': FederalStates.NW,
  '47057': FederalStates.NW,
  '47058': FederalStates.NW,
  '47059': FederalStates.NW,
  '47119': FederalStates.NW,
  '47137': FederalStates.NW,
  '47138': FederalStates.NW,
  '47139': FederalStates.NW,
  '47166': FederalStates.NW,
  '47167': FederalStates.NW,
  '47169': FederalStates.NW,
  '47178': FederalStates.NW,
  '47179': FederalStates.NW,
  '47198': FederalStates.NW,
  '47199': FederalStates.NW,
  '47226': FederalStates.NW,
  '47228': FederalStates.NW,
  '47229': FederalStates.NW,
  '47239': FederalStates.NW,
  '47249': FederalStates.NW,
  '47259': FederalStates.NW,
  '47269': FederalStates.NW,
  '47279': FederalStates.NW,
  '47441': FederalStates.NW,
  '47443': FederalStates.NW,
  '47445': FederalStates.NW,
  '47447': FederalStates.NW,
  '47475': FederalStates.NW,
  '47495': FederalStates.NW,
  '47506': FederalStates.NW,
  '47509': FederalStates.NW,
  '47533': FederalStates.NW,
  '47546': FederalStates.NW,
  '47551': FederalStates.NW,
  '47559': FederalStates.NW,
  '47574': FederalStates.NW,
  '47589': FederalStates.NW,
  '47608': FederalStates.NW,
  '47623': FederalStates.NW,
  '47624': FederalStates.NW,
  '47625': FederalStates.NW,
  '47626': FederalStates.NW,
  '47627': FederalStates.NW,
  '47638': FederalStates.NW,
  '47647': FederalStates.NW,
  '47652': FederalStates.NW,
  '47661': FederalStates.NW,
  '47665': FederalStates.NW,
  '47669': FederalStates.NW,
  '47798': FederalStates.NW,
  '47799': FederalStates.NW,
  '47800': FederalStates.NW,
  '47802': FederalStates.NW,
  '47803': FederalStates.NW,
  '47804': FederalStates.NW,
  '47805': FederalStates.NW,
  '47807': FederalStates.NW,
  '47809': FederalStates.NW,
  '47829': FederalStates.NW,
  '47839': FederalStates.NW,
  '47877': FederalStates.NW,
  '47906': FederalStates.NW,
  '47918': FederalStates.NW,
  '47929': FederalStates.NW,
  '48079': FederalStates.NW,
  '48143': FederalStates.NW,
  '48145': FederalStates.NW,
  '48147': FederalStates.NW,
  '48149': FederalStates.NW,
  '48151': FederalStates.NW,
  '48153': FederalStates.NW,
  '48155': FederalStates.NW,
  '48157': FederalStates.NW,
  '48159': FederalStates.NW,
  '48161': FederalStates.NW,
  '48163': FederalStates.NW,
  '48165': FederalStates.NW,
  '48167': FederalStates.NW,
  '48231': FederalStates.NW,
  '48249': FederalStates.NW,
  '48268': FederalStates.NW,
  '48282': FederalStates.NW,
  '48291': FederalStates.NW,
  '48301': FederalStates.NW,
  '48308': FederalStates.NW,
  '48317': FederalStates.NW,
  '48324': FederalStates.NW,
  '48329': FederalStates.NW,
  '48336': FederalStates.NW,
  '48341': FederalStates.NW,
  '48346': FederalStates.NW,
  '48351': FederalStates.NW,
  '48356': FederalStates.NW,
  '48361': FederalStates.NW,
  '48366': FederalStates.NW,
  '48369': FederalStates.NW,
  '48429': FederalStates.NW,
  '48431': FederalStates.NW,
  '48432': FederalStates.NW,
  '48455': FederalStates.NI,
  '48465': FederalStates.NI,
  '48477': FederalStates.NW,
  '48480': FederalStates.NI,
  '48485': FederalStates.NW,
  '48488': FederalStates.NI,
  '48493': FederalStates.NW,
  '48496': FederalStates.NW,
  '48499': FederalStates.NI,
  '48527': FederalStates.NI,
  '48529': FederalStates.NI,
  '48531': FederalStates.NI,
  '48565': FederalStates.NW,
  '48599': FederalStates.NW,
  '48607': FederalStates.NW,
  '48612': FederalStates.NW,
  '48619': FederalStates.NW,
  '48624': FederalStates.NW,
  '48629': FederalStates.NW,
  '48653': FederalStates.NW,
  '48683': FederalStates.NW,
  '48691': FederalStates.NW,
  '48703': FederalStates.NW,
  '48712': FederalStates.NW,
  '48720': FederalStates.NW,
  '48727': FederalStates.NW,
  '48734': FederalStates.NW,
  '48739': FederalStates.NW,
  '49074': FederalStates.NI,
  '49076': FederalStates.NI,
  '49078': FederalStates.NI,
  '49080': FederalStates.NI,
  '49082': FederalStates.NI,
  '49084': FederalStates.NI,
  '49086': FederalStates.NI,
  '49088': FederalStates.NI,
  '49090': FederalStates.NI,
  '49124': FederalStates.NI,
  '49134': FederalStates.NI,
  '49143': FederalStates.NI,
  '49152': FederalStates.NI,
  '49163': FederalStates.NI,
  '49170': FederalStates.NI,
  '49176': FederalStates.NI,
  '49179': FederalStates.NI,
  '49186': FederalStates.NI,
  '49191': FederalStates.NI,
  '49196': FederalStates.NI,
  '49201': FederalStates.NI,
  '49205': FederalStates.NI,
  '49214': FederalStates.NI,
  '49219': FederalStates.NI,
  '49324': FederalStates.NI,
  '49326': FederalStates.NI,
  '49328': FederalStates.NI,
  '49356': FederalStates.NI,
  '49377': FederalStates.NI,
  '49393': FederalStates.NI,
  '49401': FederalStates.NI,
  '49406': FederalStates.NI,
  '49413': FederalStates.NI,
  '49419': FederalStates.NI,
  '49424': FederalStates.NI,
  '49429': FederalStates.NI,
  '49434': FederalStates.NI,
  '49439': FederalStates.NI,
  '49448': FederalStates.NI,
  '49451': FederalStates.NI,
  '49453': FederalStates.NI,
  '49456': FederalStates.NI,
  '49457': FederalStates.NI,
  '49459': FederalStates.NI,
  '49477': FederalStates.NW,
  '49479': FederalStates.NW,
  '49492': FederalStates.NW,
  '49497': FederalStates.NW,
  '49504': FederalStates.NW,
  '49509': FederalStates.NW,
  '49525': FederalStates.NW,
  '49536': FederalStates.NW,
  '49545': FederalStates.NW,
  '49549': FederalStates.NW,
  '49565': FederalStates.NI,
  '49577': FederalStates.NI,
  '49584': FederalStates.NI,
  '49586': FederalStates.NI,
  '49593': FederalStates.NI,
  '49594': FederalStates.NI,
  '49596': FederalStates.NI,
  '49597': FederalStates.NI,
  '49599': FederalStates.NI,
  '49610': FederalStates.NI,
  '49624': FederalStates.NI,
  '49626': FederalStates.NI,
  '49632': FederalStates.NI,
  '49635': FederalStates.NI,
  '49637': FederalStates.NI,
  '49638': FederalStates.NI,
  '49661': FederalStates.NI,
  '49681': FederalStates.NI,
  '49685': FederalStates.NI,
  '49688': FederalStates.NI,
  '49692': FederalStates.NI,
  '49696': FederalStates.NI,
  '49699': FederalStates.NI,
  '49716': FederalStates.NI,
  '49733': FederalStates.NI,
  '49740': FederalStates.NI,
  '49744': FederalStates.NI,
  '49751': FederalStates.NI,
  '49757': FederalStates.NI,
  '49762': FederalStates.NI,
  '49767': FederalStates.NI,
  '49770': FederalStates.NI,
  '49774': FederalStates.NI,
  '49777': FederalStates.NI,
  '49779': FederalStates.NI,
  '49808': FederalStates.NI,
  '49809': FederalStates.NI,
  '49811': FederalStates.NI,
  '49824': FederalStates.NI,
  '49828': FederalStates.NI,
  '49832': FederalStates.NI,
  '49835': FederalStates.NI,
  '49838': FederalStates.NI,
  '49843': FederalStates.NI,
  '49844': FederalStates.NI,
  '49846': FederalStates.NI,
  '49847': FederalStates.NI,
  '49849': FederalStates.NI,
  '50126': FederalStates.NW,
  '50127': FederalStates.NW,
  '50129': FederalStates.NW,
  '50169': FederalStates.NW,
  '50170': FederalStates.NW,
  '50171': FederalStates.NW,
  '50181': FederalStates.NW,
  '50189': FederalStates.NW,
  '50226': FederalStates.NW,
  '50259': FederalStates.NW,
  '50321': FederalStates.NW,
  '50354': FederalStates.NW,
  '50374': FederalStates.NW,
  '50389': FederalStates.NW,
  '50667': FederalStates.NW,
  '50668': FederalStates.NW,
  '50670': FederalStates.NW,
  '50672': FederalStates.NW,
  '50674': FederalStates.NW,
  '50676': FederalStates.NW,
  '50677': FederalStates.NW,
  '50678': FederalStates.NW,
  '50679': FederalStates.NW,
  '50733': FederalStates.NW,
  '50735': FederalStates.NW,
  '50737': FederalStates.NW,
  '50739': FederalStates.NW,
  '50765': FederalStates.NW,
  '50767': FederalStates.NW,
  '50769': FederalStates.NW,
  '50823': FederalStates.NW,
  '50825': FederalStates.NW,
  '50827': FederalStates.NW,
  '50829': FederalStates.NW,
  '50858': FederalStates.NW,
  '50859': FederalStates.NW,
  '50931': FederalStates.NW,
  '50933': FederalStates.NW,
  '50935': FederalStates.NW,
  '50937': FederalStates.NW,
  '50939': FederalStates.NW,
  '50968': FederalStates.NW,
  '50969': FederalStates.NW,
  '50996': FederalStates.NW,
  '50997': FederalStates.NW,
  '50999': FederalStates.NW,
  '51061': FederalStates.NW,
  '51063': FederalStates.NW,
  '51065': FederalStates.NW,
  '51067': FederalStates.NW,
  '51069': FederalStates.NW,
  '51103': FederalStates.NW,
  '51105': FederalStates.NW,
  '51107': FederalStates.NW,
  '51109': FederalStates.NW,
  '51143': FederalStates.NW,
  '51145': FederalStates.NW,
  '51147': FederalStates.NW,
  '51149': FederalStates.NW,
  '51371': FederalStates.NW,
  '51373': FederalStates.NW,
  '51375': FederalStates.NW,
  '51377': FederalStates.NW,
  '51379': FederalStates.NW,
  '51381': FederalStates.NW,
  '51399': FederalStates.NW,
  '51427': FederalStates.NW,
  '51429': FederalStates.NW,
  '51465': FederalStates.NW,
  '51467': FederalStates.NW,
  '51469': FederalStates.NW,
  '51491': FederalStates.NW,
  '51503': FederalStates.NW,
  '51515': FederalStates.NW,
  '51519': FederalStates.NW,
  '51545': FederalStates.NW,
  '51570': FederalStates.NW,
  '51580': FederalStates.NW,
  '51588': FederalStates.NW,
  '51597': FederalStates.NW,
  '51598': FederalStates.RP,
  '51643': FederalStates.NW,
  '51645': FederalStates.NW,
  '51647': FederalStates.NW,
  '51674': FederalStates.NW,
  '51688': FederalStates.NW,
  '51702': FederalStates.NW,
  '51709': FederalStates.NW,
  '51766': FederalStates.NW,
  '51789': FederalStates.NW,
  '52062': FederalStates.NW,
  '52064': FederalStates.NW,
  '52066': FederalStates.NW,
  '52068': FederalStates.NW,
  '52070': FederalStates.NW,
  '52072': FederalStates.NW,
  '52074': FederalStates.NW,
  '52076': FederalStates.NW,
  '52078': FederalStates.NW,
  '52080': FederalStates.NW,
  '52134': FederalStates.NW,
  '52146': FederalStates.NW,
  '52152': FederalStates.NW,
  '52156': FederalStates.NW,
  '52159': FederalStates.NW,
  '52222': FederalStates.NW,
  '52223': FederalStates.NW,
  '52224': FederalStates.NW,
  '52249': FederalStates.NW,
  '52349': FederalStates.NW,
  '52351': FederalStates.NW,
  '52353': FederalStates.NW,
  '52355': FederalStates.NW,
  '52372': FederalStates.NW,
  '52379': FederalStates.NW,
  '52382': FederalStates.NW,
  '52385': FederalStates.NW,
  '52388': FederalStates.NW,
  '52391': FederalStates.NW,
  '52393': FederalStates.NW,
  '52396': FederalStates.NW,
  '52399': FederalStates.NW,
  '52428': FederalStates.NW,
  '52441': FederalStates.NW,
  '52445': FederalStates.NW,
  '52457': FederalStates.NW,
  '52459': FederalStates.NW,
  '52477': FederalStates.NW,
  '52499': FederalStates.NW,
  '52511': FederalStates.NW,
  '52525': FederalStates.NW,
  '52531': FederalStates.NW,
  '52538': FederalStates.NW,
  '53111': FederalStates.NW,
  '53113': FederalStates.NW,
  '53115': FederalStates.NW,
  '53117': FederalStates.NW,
  '53119': FederalStates.NW,
  '53121': FederalStates.NW,
  '53123': FederalStates.NW,
  '53125': FederalStates.NW,
  '53127': FederalStates.NW,
  '53129': FederalStates.NW,
  '53173': FederalStates.NW,
  '53175': FederalStates.NW,
  '53177': FederalStates.NW,
  '53179': FederalStates.NW,
  '53225': FederalStates.NW,
  '53227': FederalStates.NW,
  '53229': FederalStates.NW,
  '53332': FederalStates.NW,
  '53340': FederalStates.NW,
  '53343': FederalStates.NW,
  '53347': FederalStates.NW,
  '53359': FederalStates.NW,
  '53424': FederalStates.RP,
  '53426': FederalStates.RP,
  '53474': FederalStates.RP,
  '53489': FederalStates.RP,
  '53498': FederalStates.RP,
  '53501': FederalStates.RP,
  '53505': FederalStates.RP,
  '53506': FederalStates.RP,
  '53507': FederalStates.RP,
  '53508': FederalStates.RP,
  '53518': FederalStates.RP,
  '53520': FederalStates.RP,
  '53533': FederalStates.RP,
  '53534': FederalStates.RP,
  '53539': FederalStates.RP,
  '53545': FederalStates.RP,
  '53547': FederalStates.RP,
  '53557': FederalStates.RP,
  '53560': FederalStates.RP,
  '53562': FederalStates.RP,
  '53567': FederalStates.RP,
  '53572': FederalStates.RP,
  '53577': FederalStates.RP,
  '53578': FederalStates.RP,
  '53579': FederalStates.RP,
  '53604': FederalStates.NW,
  '53619': FederalStates.RP,
  '53639': FederalStates.NW,
  '53721': FederalStates.NW,
  '53757': FederalStates.NW,
  '53773': FederalStates.NW,
  '53783': FederalStates.NW,
  '53797': FederalStates.NW,
  '53804': FederalStates.NW,
  '53809': FederalStates.NW,
  '53819': FederalStates.NW,
  '53840': FederalStates.NW,
  '53842': FederalStates.NW,
  '53844': FederalStates.NW,
  '53859': FederalStates.NW,
  '53879': FederalStates.NW,
  '53881': FederalStates.NW,
  '53894': FederalStates.NW,
  '53902': FederalStates.NW,
  '53909': FederalStates.NW,
  '53913': FederalStates.NW,
  '53919': FederalStates.NW,
  '53925': FederalStates.NW,
  '53937': FederalStates.NW,
  '53940': FederalStates.NW,
  '53945': FederalStates.NW,
  '53947': FederalStates.NW,
  '53949': FederalStates.NW,
  '54290': FederalStates.RP,
  '54292': FederalStates.RP,
  '54293': FederalStates.RP,
  '54294': FederalStates.RP,
  '54295': FederalStates.RP,
  '54296': FederalStates.RP,
  '54298': FederalStates.RP,
  '54306': FederalStates.RP,
  '54308': FederalStates.RP,
  '54309': FederalStates.RP,
  '54310': FederalStates.RP,
  '54311': FederalStates.RP,
  '54313': FederalStates.RP,
  '54314': FederalStates.RP,
  '54316': FederalStates.RP,
  '54317': FederalStates.RP,
  '54318': FederalStates.RP,
  '54320': FederalStates.RP,
  '54329': FederalStates.RP,
  '54331': FederalStates.RP,
  '54332': FederalStates.RP,
  '54338': FederalStates.RP,
  '54340': FederalStates.RP,
  '54341': FederalStates.RP,
  '54343': FederalStates.RP,
  '54344': FederalStates.RP,
  '54346': FederalStates.RP,
  '54347': FederalStates.RP,
  '54349': FederalStates.RP,
  '54411': FederalStates.RP,
  '54413': FederalStates.RP,
  '54421': FederalStates.RP,
  '54422': FederalStates.RP,
  '54424': FederalStates.RP,
  '54426': FederalStates.RP,
  '54427': FederalStates.RP,
  '54429': FederalStates.RP,
  '54439': FederalStates.RP,
  '54441': FederalStates.RP,
  '54450': FederalStates.RP,
  '54451': FederalStates.RP,
  '54453': FederalStates.RP,
  '54455': FederalStates.RP,
  '54456': FederalStates.RP,
  '54457': FederalStates.RP,
  '54459': FederalStates.RP,
  '54470': FederalStates.RP,
  '54472': FederalStates.RP,
  '54483': FederalStates.RP,
  '54484': FederalStates.RP,
  '54486': FederalStates.RP,
  '54487': FederalStates.RP,
  '54492': FederalStates.RP,
  '54497': FederalStates.RP,
  '54498': FederalStates.RP,
  '54516': FederalStates.RP,
  '54518': FederalStates.RP,
  '54523': FederalStates.RP,
  '54524': FederalStates.RP,
  '54526': FederalStates.RP,
  '54528': FederalStates.RP,
  '54529': FederalStates.RP,
  '54531': FederalStates.RP,
  '54533': FederalStates.RP,
  '54534': FederalStates.RP,
  '54536': FederalStates.RP,
  '54538': FederalStates.RP,
  '54539': FederalStates.RP,
  '54550': FederalStates.RP,
  '54552': FederalStates.RP,
  '54558': FederalStates.RP,
  '54568': FederalStates.RP,
  '54570': FederalStates.RP,
  '54574': FederalStates.RP,
  '54576': FederalStates.RP,
  '54578': FederalStates.RP,
  '54579': FederalStates.RP,
  '54584': FederalStates.RP,
  '54585': FederalStates.RP,
  '54586': FederalStates.RP,
  '54587': FederalStates.RP,
  '54589': FederalStates.RP,
  '54595': FederalStates.RP,
  '54597': FederalStates.RP,
  '54608': FederalStates.RP,
  '54610': FederalStates.RP,
  '54611': FederalStates.RP,
  '54612': FederalStates.RP,
  '54614': FederalStates.RP,
  '54616': FederalStates.RP,
  '54617': FederalStates.RP,
  '54619': FederalStates.RP,
  '54634': FederalStates.RP,
  '54636': FederalStates.RP,
  '54646': FederalStates.RP,
  '54647': FederalStates.RP,
  '54649': FederalStates.RP,
  '54655': FederalStates.RP,
  '54657': FederalStates.RP,
  '54662': FederalStates.RP,
  '54664': FederalStates.RP,
  '54666': FederalStates.RP,
  '54668': FederalStates.RP,
  '54669': FederalStates.RP,
  '54673': FederalStates.RP,
  '54675': FederalStates.RP,
  '54687': FederalStates.RP,
  '54689': FederalStates.RP,
  '55116': FederalStates.RP,
  '55118': FederalStates.RP,
  '55120': FederalStates.RP,
  '55122': FederalStates.RP,
  '55124': FederalStates.RP,
  '55126': FederalStates.RP,
  '55127': FederalStates.RP,
  '55128': FederalStates.RP,
  '55129': FederalStates.RP,
  '55130': FederalStates.RP,
  '55131': FederalStates.RP,
  '55218': FederalStates.RP,
  '55232': FederalStates.RP,
  '55234': FederalStates.RP,
  '55237': FederalStates.RP,
  '55239': FederalStates.RP,
  '55246': FederalStates.HE,
  '55252': FederalStates.HE,
  '55257': FederalStates.RP,
  '55262': FederalStates.RP,
  '55263': FederalStates.RP,
  '55268': FederalStates.RP,
  '55270': FederalStates.RP,
  '55271': FederalStates.RP,
  '55276': FederalStates.RP,
  '55278': FederalStates.RP,
  '55283': FederalStates.RP,
  '55286': FederalStates.RP,
  '55288': FederalStates.RP,
  '55291': FederalStates.RP,
  '55294': FederalStates.RP,
  '55296': FederalStates.RP,
  '55299': FederalStates.RP,
  '55411': FederalStates.RP,
  '55413': FederalStates.RP,
  '55422': FederalStates.RP,
  '55424': FederalStates.RP,
  '55425': FederalStates.RP,
  '55430': FederalStates.RP,
  '55432': FederalStates.RP,
  '55435': FederalStates.RP,
  '55437': FederalStates.RP,
  '55442': FederalStates.RP,
  '55444': FederalStates.RP,
  '55450': FederalStates.RP,
  '55452': FederalStates.RP,
  '55457': FederalStates.RP,
  '55459': FederalStates.RP,
  '55469': FederalStates.RP,
  '55471': FederalStates.RP,
  '55481': FederalStates.RP,
  '55483': FederalStates.RP,
  '55487': FederalStates.RP,
  '55490': FederalStates.RP,
  '55491': FederalStates.RP,
  '55494': FederalStates.RP,
  '55496': FederalStates.RP,
  '55497': FederalStates.RP,
  '55499': FederalStates.RP,
  '55543': FederalStates.RP,
  '55545': FederalStates.RP,
  '55546': FederalStates.RP,
  '55559': FederalStates.RP,
  '55566': FederalStates.RP,
  '55568': FederalStates.RP,
  '55569': FederalStates.RP,
  '55571': FederalStates.RP,
  '55576': FederalStates.RP,
  '55578': FederalStates.RP,
  '55583': FederalStates.RP,
  '55585': FederalStates.RP,
  '55590': FederalStates.RP,
  '55592': FederalStates.RP,
  '55593': FederalStates.RP,
  '55595': FederalStates.RP,
  '55596': FederalStates.RP,
  '55597': FederalStates.RP,
  '55599': FederalStates.RP,
  '55606': FederalStates.RP,
  '55608': FederalStates.RP,
  '55618': FederalStates.RP,
  '55619': FederalStates.RP,
  '55621': FederalStates.RP,
  '55624': FederalStates.RP,
  '55626': FederalStates.RP,
  '55627': FederalStates.RP,
  '55629': FederalStates.RP,
  '55743': FederalStates.RP,
  '55756': FederalStates.RP,
  '55758': FederalStates.RP,
  '55765': FederalStates.RP,
  '55767': FederalStates.RP,
  '55768': FederalStates.RP,
  '55774': FederalStates.RP,
  '55776': FederalStates.RP,
  '55777': FederalStates.RP,
  '55779': FederalStates.RP,
  '56068': FederalStates.RP,
  '56070': FederalStates.RP,
  '56072': FederalStates.RP,
  '56073': FederalStates.RP,
  '56075': FederalStates.RP,
  '56076': FederalStates.RP,
  '56077': FederalStates.RP,
  '56112': FederalStates.RP,
  '56130': FederalStates.RP,
  '56132': FederalStates.RP,
  '56133': FederalStates.RP,
  '56154': FederalStates.RP,
  '56170': FederalStates.RP,
  '56179': FederalStates.RP,
  '56182': FederalStates.RP,
  '56191': FederalStates.RP,
  '56203': FederalStates.RP,
  '56204': FederalStates.RP,
  '56206': FederalStates.RP,
  '56218': FederalStates.RP,
  '56220': FederalStates.RP,
  '56235': FederalStates.RP,
  '56237': FederalStates.RP,
  '56242': FederalStates.RP,
  '56244': FederalStates.RP,
  '56249': FederalStates.RP,
  '56253': FederalStates.RP,
  '56254': FederalStates.RP,
  '56269': FederalStates.RP,
  '56271': FederalStates.RP,
  '56276': FederalStates.RP,
  '56281': FederalStates.RP,
  '56283': FederalStates.RP,
  '56288': FederalStates.RP,
  '56290': FederalStates.RP,
  '56291': FederalStates.RP,
  '56294': FederalStates.RP,
  '56295': FederalStates.RP,
  '56299': FederalStates.RP,
  '56305': FederalStates.RP,
  '56307': FederalStates.RP,
  '56316': FederalStates.RP,
  '56317': FederalStates.RP,
  '56321': FederalStates.RP,
  '56322': FederalStates.RP,
  '56323': FederalStates.RP,
  '56329': FederalStates.RP,
  '56330': FederalStates.RP,
  '56332': FederalStates.RP,
  '56333': FederalStates.RP,
  '56335': FederalStates.RP,
  '56337': FederalStates.RP,
  '56338': FederalStates.RP,
  '56340': FederalStates.RP,
  '56341': FederalStates.RP,
  '56346': FederalStates.RP,
  '56348': FederalStates.RP,
  '56349': FederalStates.RP,
  '56355': FederalStates.RP,
  '56357': FederalStates.RP,
  '56368': FederalStates.RP,
  '56370': FederalStates.RP,
  '56377': FederalStates.RP,
  '56379': FederalStates.RP,
  '56410': FederalStates.RP,
  '56412': FederalStates.RP,
  '56414': FederalStates.RP,
  '56422': FederalStates.RP,
  '56424': FederalStates.RP,
  '56427': FederalStates.RP,
  '56428': FederalStates.RP,
  '56457': FederalStates.RP,
  '56459': FederalStates.RP,
  '56462': FederalStates.RP,
  '56470': FederalStates.RP,
  '56472': FederalStates.RP,
  '56477': FederalStates.RP,
  '56479': FederalStates.RP,
  '56564': FederalStates.RP,
  '56566': FederalStates.RP,
  '56567': FederalStates.RP,
  '56575': FederalStates.RP,
  '56579': FederalStates.RP,
  '56581': FederalStates.RP,
  '56584': FederalStates.RP,
  '56587': FederalStates.RP,
  '56588': FederalStates.RP,
  '56589': FederalStates.RP,
  '56593': FederalStates.RP,
  '56594': FederalStates.RP,
  '56598': FederalStates.RP,
  '56599': FederalStates.RP,
  '56626': FederalStates.RP,
  '56630': FederalStates.RP,
  '56637': FederalStates.RP,
  '56642': FederalStates.RP,
  '56645': FederalStates.RP,
  '56648': FederalStates.RP,
  '56651': FederalStates.RP,
  '56653': FederalStates.RP,
  '56656': FederalStates.RP,
  '56659': FederalStates.RP,
  '56727': FederalStates.RP,
  '56729': FederalStates.RP,
  '56736': FederalStates.RP,
  '56743': FederalStates.RP,
  '56745': FederalStates.RP,
  '56746': FederalStates.RP,
  '56751': FederalStates.RP,
  '56753': FederalStates.RP,
  '56754': FederalStates.RP,
  '56759': FederalStates.RP,
  '56761': FederalStates.RP,
  '56766': FederalStates.RP,
  '56767': FederalStates.RP,
  '56769': FederalStates.RP,
  '56812': FederalStates.RP,
  '56814': FederalStates.RP,
  '56818': FederalStates.RP,
  '56820': FederalStates.RP,
  '56821': FederalStates.RP,
  '56823': FederalStates.RP,
  '56825': FederalStates.RP,
  '56826': FederalStates.RP,
  '56828': FederalStates.RP,
  '56829': FederalStates.RP,
  '56841': FederalStates.RP,
  '56843': FederalStates.RP,
  '56850': FederalStates.RP,
  '56856': FederalStates.RP,
  '56858': FederalStates.RP,
  '56859': FederalStates.RP,
  '56861': FederalStates.RP,
  '56862': FederalStates.RP,
  '56864': FederalStates.RP,
  '56865': FederalStates.RP,
  '56867': FederalStates.RP,
  '56869': FederalStates.RP,
  '57072': FederalStates.NW,
  '57074': FederalStates.NW,
  '57076': FederalStates.NW,
  '57078': FederalStates.NW,
  '57080': FederalStates.NW,
  '57223': FederalStates.NW,
  '57234': FederalStates.NW,
  '57250': FederalStates.NW,
  '57258': FederalStates.NW,
  '57271': FederalStates.NW,
  '57290': FederalStates.NW,
  '57299': FederalStates.NW,
  '57319': FederalStates.NW,
  '57334': FederalStates.NW,
  '57339': FederalStates.NW,
  '57368': FederalStates.NW,
  '57392': FederalStates.NW,
  '57399': FederalStates.NW,
  '57413': FederalStates.NW,
  '57439': FederalStates.NW,
  '57462': FederalStates.NW,
  '57482': FederalStates.NW,
  '57489': FederalStates.NW,
  '57518': FederalStates.RP,
  '57520': FederalStates.RP,
  '57537': FederalStates.RP,
  '57539': FederalStates.RP,
  '57548': FederalStates.RP,
  '57555': FederalStates.RP,
  '57562': FederalStates.RP,
  '57567': FederalStates.RP,
  '57572': FederalStates.RP,
  '57577': FederalStates.RP,
  '57578': FederalStates.RP,
  '57580': FederalStates.RP,
  '57581': FederalStates.RP,
  '57583': FederalStates.RP,
  '57584': FederalStates.RP,
  '57586': FederalStates.RP,
  '57587': FederalStates.RP,
  '57589': FederalStates.RP,
  '57610': FederalStates.RP,
  '57612': FederalStates.RP,
  '57614': FederalStates.RP,
  '57627': FederalStates.RP,
  '57629': FederalStates.RP,
  '57632': FederalStates.RP,
  '57635': FederalStates.RP,
  '57636': FederalStates.RP,
  '57638': FederalStates.RP,
  '57639': FederalStates.RP,
  '57641': FederalStates.RP,
  '57642': FederalStates.RP,
  '57644': FederalStates.RP,
  '57645': FederalStates.RP,
  '57647': FederalStates.RP,
  '57648': FederalStates.RP,
  '58089': FederalStates.NW,
  '58091': FederalStates.NW,
  '58093': FederalStates.NW,
  '58095': FederalStates.NW,
  '58097': FederalStates.NW,
  '58099': FederalStates.NW,
  '58119': FederalStates.NW,
  '58135': FederalStates.NW,
  '58239': FederalStates.NW,
  '58256': FederalStates.NW,
  '58285': FederalStates.NW,
  '58300': FederalStates.NW,
  '58313': FederalStates.NW,
  '58332': FederalStates.NW,
  '58339': FederalStates.NW,
  '58452': FederalStates.NW,
  '58453': FederalStates.NW,
  '58454': FederalStates.NW,
  '58455': FederalStates.NW,
  '58456': FederalStates.NW,
  '58507': FederalStates.NW,
  '58509': FederalStates.NW,
  '58511': FederalStates.NW,
  '58513': FederalStates.NW,
  '58515': FederalStates.NW,
  '58540': FederalStates.NW,
  '58553': FederalStates.NW,
  '58566': FederalStates.NW,
  '58579': FederalStates.NW,
  '58636': FederalStates.NW,
  '58638': FederalStates.NW,
  '58640': FederalStates.NW,
  '58642': FederalStates.NW,
  '58644': FederalStates.NW,
  '58675': FederalStates.NW,
  '58706': FederalStates.NW,
  '58708': FederalStates.NW,
  '58710': FederalStates.NW,
  '58730': FederalStates.NW,
  '58739': FederalStates.NW,
  '58762': FederalStates.NW,
  '58769': FederalStates.NW,
  '58791': FederalStates.NW,
  '58802': FederalStates.NW,
  '58809': FederalStates.NW,
  '58840': FederalStates.NW,
  '58849': FederalStates.NW,
  '59063': FederalStates.NW,
  '59065': FederalStates.NW,
  '59067': FederalStates.NW,
  '59069': FederalStates.NW,
  '59071': FederalStates.NW,
  '59073': FederalStates.NW,
  '59075': FederalStates.NW,
  '59077': FederalStates.NW,
  '59174': FederalStates.NW,
  '59192': FederalStates.NW,
  '59199': FederalStates.NW,
  '59227': FederalStates.NW,
  '59229': FederalStates.NW,
  '59269': FederalStates.NW,
  '59302': FederalStates.NW,
  '59320': FederalStates.NW,
  '59329': FederalStates.NW,
  '59348': FederalStates.NW,
  '59368': FederalStates.NW,
  '59379': FederalStates.NW,
  '59387': FederalStates.NW,
  '59394': FederalStates.NW,
  '59399': FederalStates.NW,
  '59423': FederalStates.NW,
  '59425': FederalStates.NW,
  '59427': FederalStates.NW,
  '59439': FederalStates.NW,
  '59457': FederalStates.NW,
  '59469': FederalStates.NW,
  '59494': FederalStates.NW,
  '59505': FederalStates.NW,
  '59510': FederalStates.NW,
  '59514': FederalStates.NW,
  '59519': FederalStates.NW,
  '59555': FederalStates.NW,
  '59556': FederalStates.NW,
  '59557': FederalStates.NW,
  '59558': FederalStates.NW,
  '59581': FederalStates.NW,
  '59590': FederalStates.NW,
  '59597': FederalStates.NW,
  '59602': FederalStates.NW,
  '59609': FederalStates.NW,
  '59755': FederalStates.NW,
  '59757': FederalStates.NW,
  '59759': FederalStates.NW,
  '59821': FederalStates.NW,
  '59823': FederalStates.NW,
  '59846': FederalStates.NW,
  '59872': FederalStates.NW,
  '59889': FederalStates.NW,
  '59909': FederalStates.NW,
  '59929': FederalStates.NW,
  '59939': FederalStates.NW,
  '59955': FederalStates.NW,
  '59964': FederalStates.NW,
  '59969': FederalStates.NW,
  '60306': FederalStates.HE,
  '60308': FederalStates.HE,
  '60310': FederalStates.HE,
  '60311': FederalStates.HE,
  '60313': FederalStates.HE,
  '60314': FederalStates.HE,
  '60316': FederalStates.HE,
  '60318': FederalStates.HE,
  '60320': FederalStates.HE,
  '60322': FederalStates.HE,
  '60323': FederalStates.HE,
  '60325': FederalStates.HE,
  '60326': FederalStates.HE,
  '60327': FederalStates.HE,
  '60329': FederalStates.HE,
  '60385': FederalStates.HE,
  '60386': FederalStates.HE,
  '60388': FederalStates.HE,
  '60389': FederalStates.HE,
  '60431': FederalStates.HE,
  '60433': FederalStates.HE,
  '60435': FederalStates.HE,
  '60437': FederalStates.HE,
  '60438': FederalStates.HE,
  '60439': FederalStates.HE,
  '60486': FederalStates.HE,
  '60487': FederalStates.HE,
  '60488': FederalStates.HE,
  '60489': FederalStates.HE,
  '60528': FederalStates.HE,
  '60529': FederalStates.HE,
  '60549': FederalStates.HE,
  '60594': FederalStates.HE,
  '60596': FederalStates.HE,
  '60598': FederalStates.HE,
  '60599': FederalStates.HE,
  '61118': FederalStates.HE,
  '61130': FederalStates.HE,
  '61137': FederalStates.HE,
  '61138': FederalStates.HE,
  '61169': FederalStates.HE,
  '61184': FederalStates.HE,
  '61191': FederalStates.HE,
  '61194': FederalStates.HE,
  '61197': FederalStates.HE,
  '61200': FederalStates.HE,
  '61203': FederalStates.HE,
  '61206': FederalStates.HE,
  '61209': FederalStates.HE,
  '61231': FederalStates.HE,
  '61239': FederalStates.HE,
  '61250': FederalStates.HE,
  '61267': FederalStates.HE,
  '61273': FederalStates.HE,
  '61276': FederalStates.HE,
  '61279': FederalStates.HE,
  '61348': FederalStates.HE,
  '61350': FederalStates.HE,
  '61352': FederalStates.HE,
  '61381': FederalStates.HE,
  '61389': FederalStates.HE,
  '61440': FederalStates.HE,
  '61449': FederalStates.HE,
  '61462': FederalStates.HE,
  '61476': FederalStates.HE,
  '61479': FederalStates.HE,
  '63065': FederalStates.HE,
  '63067': FederalStates.HE,
  '63069': FederalStates.HE,
  '63071': FederalStates.HE,
  '63073': FederalStates.HE,
  '63075': FederalStates.HE,
  '63110': FederalStates.HE,
  '63128': FederalStates.HE,
  '63150': FederalStates.HE,
  '63165': FederalStates.HE,
  '63179': FederalStates.HE,
  '63225': FederalStates.HE,
  '63263': FederalStates.HE,
  '63303': FederalStates.HE,
  '63322': FederalStates.HE,
  '63329': FederalStates.HE,
  '63405': FederalStates.HE,
  '63450': FederalStates.HE,
  '63452': FederalStates.HE,
  '63454': FederalStates.HE,
  '63456': FederalStates.HE,
  '63457': FederalStates.HE,
  '63477': FederalStates.HE,
  '63486': FederalStates.HE,
  '63500': FederalStates.HE,
  '63505': FederalStates.HE,
  '63512': FederalStates.HE,
  '63517': FederalStates.HE,
  '63526': FederalStates.HE,
  '63533': FederalStates.HE,
  '63538': FederalStates.HE,
  '63543': FederalStates.HE,
  '63546': FederalStates.HE,
  '63549': FederalStates.HE,
  '63571': FederalStates.HE,
  '63579': FederalStates.HE,
  '63584': FederalStates.HE,
  '63589': FederalStates.HE,
  '63594': FederalStates.HE,
  '63599': FederalStates.HE,
  '63607': FederalStates.HE,
  '63619': FederalStates.HE,
  '63628': FederalStates.HE,
  '63633': FederalStates.HE,
  '63636': FederalStates.HE,
  '63637': FederalStates.HE,
  '63639': FederalStates.HE,
  '63654': FederalStates.HE,
  '63667': FederalStates.HE,
  '63674': FederalStates.HE,
  '63679': FederalStates.HE,
  '63683': FederalStates.HE,
  '63688': FederalStates.HE,
  '63691': FederalStates.HE,
  '63694': FederalStates.HE,
  '63695': FederalStates.HE,
  '63697': FederalStates.HE,
  '63699': FederalStates.HE,
  '63739': FederalStates.BY,
  '63741': FederalStates.BY,
  '63743': FederalStates.BY,
  '63755': FederalStates.BY,
  '63762': FederalStates.BY,
  '63768': FederalStates.BY,
  '63773': FederalStates.BY,
  '63776': FederalStates.BY,
  '63785': FederalStates.BY,
  '63791': FederalStates.BY,
  '63796': FederalStates.BY,
  '63801': FederalStates.BY,
  '63808': FederalStates.BY,
  '63811': FederalStates.BY,
  '63814': FederalStates.BY,
  '63820': FederalStates.BY,
  '63825': FederalStates.BY,
  '63826': FederalStates.BY,
  '63828': FederalStates.BY,
  '63829': FederalStates.BY,
  '63831': FederalStates.BY,
  '63834': FederalStates.BY,
  '63839': FederalStates.BY,
  '63840': FederalStates.BY,
  '63843': FederalStates.BY,
  '63846': FederalStates.BY,
  '63849': FederalStates.BY,
  '63853': FederalStates.BY,
  '63856': FederalStates.BY,
  '63857': FederalStates.BY,
  '63860': FederalStates.BY,
  '63863': FederalStates.BY,
  '63864': FederalStates.BY,
  '63867': FederalStates.BY,
  '63868': FederalStates.BY,
  '63869': FederalStates.BY,
  '63871': FederalStates.BY,
  '63872': FederalStates.BY,
  '63874': FederalStates.BY,
  '63875': FederalStates.BY,
  '63877': FederalStates.BY,
  '63879': FederalStates.BY,
  '63897': FederalStates.BY,
  '63906': FederalStates.BY,
  '63911': FederalStates.BY,
  '63916': FederalStates.BY,
  '63920': FederalStates.BY,
  '63924': FederalStates.BY,
  '63925': FederalStates.BY,
  '63927': FederalStates.BY,
  '63928': FederalStates.BY,
  '63930': FederalStates.BY,
  '63931': FederalStates.BY,
  '63933': FederalStates.BY,
  '63934': FederalStates.BY,
  '63936': FederalStates.BY,
  '63937': FederalStates.BY,
  '63939': FederalStates.BY,
  '64283': FederalStates.HE,
  '64285': FederalStates.HE,
  '64287': FederalStates.HE,
  '64289': FederalStates.HE,
  '64291': FederalStates.HE,
  '64293': FederalStates.HE,
  '64295': FederalStates.HE,
  '64297': FederalStates.HE,
  '64319': FederalStates.HE,
  '64331': FederalStates.HE,
  '64342': FederalStates.HE,
  '64347': FederalStates.HE,
  '64354': FederalStates.HE,
  '64367': FederalStates.HE,
  '64372': FederalStates.HE,
  '64380': FederalStates.HE,
  '64385': FederalStates.HE,
  '64390': FederalStates.HE,
  '64395': FederalStates.HE,
  '64397': FederalStates.HE,
  '64401': FederalStates.HE,
  '64404': FederalStates.HE,
  '64405': FederalStates.HE,
  '64407': FederalStates.HE,
  '64409': FederalStates.HE,
  '64521': FederalStates.HE,
  '64546': FederalStates.HE,
  '64560': FederalStates.HE,
  '64569': FederalStates.HE,
  '64572': FederalStates.HE,
  '64579': FederalStates.HE,
  '64584': FederalStates.HE,
  '64589': FederalStates.HE,
  '64625': FederalStates.HE,
  '64646': FederalStates.HE,
  '64653': FederalStates.HE,
  '64658': FederalStates.HE,
  '64665': FederalStates.HE,
  '64668': FederalStates.HE,
  '64673': FederalStates.HE,
  '64678': FederalStates.HE,
  '64683': FederalStates.HE,
  '64686': FederalStates.HE,
  '64689': FederalStates.HE,
  '64711': FederalStates.HE,
  '64720': FederalStates.HE,
  '64732': FederalStates.HE,
  '64739': FederalStates.HE,
  '64743': FederalStates.HE,
  '64747': FederalStates.HE,
  '64750': FederalStates.HE,
  '64753': FederalStates.HE,
  '64754': FederalStates.HE,
  '64756': FederalStates.HE,
  '64757': FederalStates.HE,
  '64807': FederalStates.HE,
  '64823': FederalStates.HE,
  '64832': FederalStates.HE,
  '64839': FederalStates.HE,
  '64846': FederalStates.HE,
  '64850': FederalStates.HE,
  '64853': FederalStates.HE,
  '64859': FederalStates.HE,
  '65183': FederalStates.HE,
  '65185': FederalStates.HE,
  '65187': FederalStates.HE,
  '65189': FederalStates.HE,
  '65191': FederalStates.HE,
  '65193': FederalStates.HE,
  '65195': FederalStates.HE,
  '65197': FederalStates.HE,
  '65199': FederalStates.HE,
  '65201': FederalStates.HE,
  '65203': FederalStates.HE,
  '65205': FederalStates.HE,
  '65207': FederalStates.HE,
  '65219': FederalStates.HE,
  '65220': FederalStates.HE,
  '65221': FederalStates.HE,
  '65222': FederalStates.HE,
  '65223': FederalStates.HE,
  '65224': FederalStates.HE,
  '65232': FederalStates.HE,
  '65239': FederalStates.HE,
  '65307': FederalStates.HE,
  '65321': FederalStates.HE,
  '65326': FederalStates.HE,
  '65329': FederalStates.HE,
  '65343': FederalStates.HE,
  '65344': FederalStates.HE,
  '65345': FederalStates.HE,
  '65346': FederalStates.HE,
  '65347': FederalStates.HE,
  '65366': FederalStates.HE,
  '65375': FederalStates.HE,
  '65385': FederalStates.HE,
  '65388': FederalStates.HE,
  '65391': FederalStates.RP,
  '65396': FederalStates.HE,
  '65399': FederalStates.HE,
  '65428': FederalStates.HE,
  '65439': FederalStates.HE,
  '65451': FederalStates.HE,
  '65462': FederalStates.HE,
  '65468': FederalStates.HE,
  '65474': FederalStates.HE,
  '65479': FederalStates.HE,
  '65510': FederalStates.HE,
  '65520': FederalStates.HE,
  '65527': FederalStates.HE,
  '65529': FederalStates.HE,
  '65549': FederalStates.HE,
  '65550': FederalStates.HE,
  '65551': FederalStates.HE,
  '65552': FederalStates.HE,
  '65553': FederalStates.HE,
  '65554': FederalStates.HE,
  '65555': FederalStates.HE,
  '65556': FederalStates.HE,
  '65558': FederalStates.RP,
  '65582': FederalStates.RP,
  '65589': FederalStates.HE,
  '65594': FederalStates.HE,
  '65597': FederalStates.HE,
  '65599': FederalStates.HE,
  '65604': FederalStates.HE,
  '65606': FederalStates.HE,
  '65611': FederalStates.HE,
  '65614': FederalStates.HE,
  '65618': FederalStates.HE,
  '65620': FederalStates.HE,
  '65623': FederalStates.RP,
  '65624': FederalStates.RP,
  '65626': FederalStates.RP,
  '65627': FederalStates.HE,
  '65629': FederalStates.RP,
  '65719': FederalStates.HE,
  '65760': FederalStates.HE,
  '65779': FederalStates.HE,
  '65795': FederalStates.HE,
  '65812': FederalStates.HE,
  '65817': FederalStates.HE,
  '65824': FederalStates.HE,
  '65830': FederalStates.HE,
  '65835': FederalStates.HE,
  '65843': FederalStates.HE,
  '65929': FederalStates.HE,
  '65931': FederalStates.HE,
  '65933': FederalStates.HE,
  '65934': FederalStates.HE,
  '65936': FederalStates.HE,
  '66111': FederalStates.SL,
  '66113': FederalStates.SL,
  '66115': FederalStates.SL,
  '66117': FederalStates.SL,
  '66119': FederalStates.SL,
  '66121': FederalStates.SL,
  '66123': FederalStates.SL,
  '66125': FederalStates.SL,
  '66126': FederalStates.SL,
  '66127': FederalStates.SL,
  '66128': FederalStates.SL,
  '66129': FederalStates.SL,
  '66130': FederalStates.SL,
  '66131': FederalStates.SL,
  '66132': FederalStates.SL,
  '66133': FederalStates.SL,
  '66265': FederalStates.SL,
  '66271': FederalStates.SL,
  '66280': FederalStates.SL,
  '66287': FederalStates.SL,
  '66292': FederalStates.SL,
  '66299': FederalStates.SL,
  '66333': FederalStates.SL,
  '66346': FederalStates.SL,
  '66352': FederalStates.SL,
  '66359': FederalStates.SL,
  '66386': FederalStates.SL,
  '66399': FederalStates.SL,
  '66424': FederalStates.SL,
  '66440': FederalStates.SL,
  '66450': FederalStates.SL,
  '66453': FederalStates.SL,
  '66459': FederalStates.SL,
  '66482': FederalStates.RP,
  '66484': FederalStates.RP,
  '66497': FederalStates.RP,
  '66500': FederalStates.RP,
  '66501': FederalStates.RP,
  '66503': FederalStates.RP,
  '66504': FederalStates.RP,
  '66506': FederalStates.RP,
  '66507': FederalStates.RP,
  '66509': FederalStates.RP,
  '66538': FederalStates.SL,
  '66539': FederalStates.SL,
  '66540': FederalStates.SL,
  '66557': FederalStates.SL,
  '66564': FederalStates.SL,
  '66571': FederalStates.SL,
  '66578': FederalStates.SL,
  '66583': FederalStates.SL,
  '66589': FederalStates.SL,
  '66606': FederalStates.SL,
  '66620': FederalStates.SL,
  '66625': FederalStates.SL,
  '66629': FederalStates.SL,
  '66636': FederalStates.SL,
  '66640': FederalStates.SL,
  '66646': FederalStates.SL,
  '66649': FederalStates.SL,
  '66663': FederalStates.SL,
  '66679': FederalStates.SL,
  '66687': FederalStates.SL,
  '66693': FederalStates.SL,
  '66701': FederalStates.SL,
  '66706': FederalStates.SL,
  '66709': FederalStates.SL,
  '66740': FederalStates.SL,
  '66763': FederalStates.SL,
  '66773': FederalStates.SL,
  '66780': FederalStates.SL,
  '66787': FederalStates.SL,
  '66793': FederalStates.SL,
  '66798': FederalStates.SL,
  '66802': FederalStates.SL,
  '66806': FederalStates.SL,
  '66809': FederalStates.SL,
  '66822': FederalStates.SL,
  '66839': FederalStates.SL,
  '66849': FederalStates.RP,
  '66851': FederalStates.RP,
  '66862': FederalStates.RP,
  '66869': FederalStates.RP,
  '66871': FederalStates.RP,
  '66877': FederalStates.RP,
  '66879': FederalStates.RP,
  '66882': FederalStates.RP,
  '66885': FederalStates.RP,
  '66887': FederalStates.RP,
  '66892': FederalStates.RP,
  '66894': FederalStates.RP,
  '66901': FederalStates.RP,
  '66903': FederalStates.RP,
  '66904': FederalStates.RP,
  '66907': FederalStates.RP,
  '66909': FederalStates.RP,
  '66914': FederalStates.RP,
  '66916': FederalStates.RP,
  '66917': FederalStates.RP,
  '66919': FederalStates.RP,
  '66953': FederalStates.RP,
  '66954': FederalStates.RP,
  '66955': FederalStates.RP,
  '66957': FederalStates.RP,
  '66969': FederalStates.RP,
  '66976': FederalStates.RP,
  '66978': FederalStates.RP,
  '66981': FederalStates.RP,
  '66987': FederalStates.RP,
  '66989': FederalStates.RP,
  '66994': FederalStates.RP,
  '66996': FederalStates.RP,
  '66999': FederalStates.RP,
  '67059': FederalStates.RP,
  '67061': FederalStates.RP,
  '67063': FederalStates.RP,
  '67065': FederalStates.RP,
  '67067': FederalStates.RP,
  '67069': FederalStates.RP,
  '67071': FederalStates.RP,
  '67098': FederalStates.RP,
  '67105': FederalStates.RP,
  '67112': FederalStates.RP,
  '67117': FederalStates.RP,
  '67122': FederalStates.RP,
  '67125': FederalStates.RP,
  '67126': FederalStates.RP,
  '67127': FederalStates.RP,
  '67133': FederalStates.RP,
  '67134': FederalStates.RP,
  '67136': FederalStates.RP,
  '67141': FederalStates.RP,
  '67146': FederalStates.RP,
  '67147': FederalStates.RP,
  '67149': FederalStates.RP,
  '67150': FederalStates.RP,
  '67152': FederalStates.RP,
  '67157': FederalStates.RP,
  '67158': FederalStates.RP,
  '67159': FederalStates.RP,
  '67161': FederalStates.RP,
  '67165': FederalStates.RP,
  '67166': FederalStates.RP,
  '67167': FederalStates.RP,
  '67169': FederalStates.RP,
  '67227': FederalStates.RP,
  '67229': FederalStates.RP,
  '67240': FederalStates.RP,
  '67245': FederalStates.RP,
  '67246': FederalStates.RP,
  '67251': FederalStates.RP,
  '67256': FederalStates.RP,
  '67258': FederalStates.RP,
  '67259': FederalStates.RP,
  '67269': FederalStates.RP,
  '67271': FederalStates.RP,
  '67273': FederalStates.RP,
  '67278': FederalStates.RP,
  '67280': FederalStates.RP,
  '67281': FederalStates.RP,
  '67283': FederalStates.RP,
  '67292': FederalStates.RP,
  '67294': FederalStates.RP,
  '67295': FederalStates.RP,
  '67297': FederalStates.RP,
  '67304': FederalStates.RP,
  '67305': FederalStates.RP,
  '67307': FederalStates.RP,
  '67308': FederalStates.RP,
  '67310': FederalStates.RP,
  '67311': FederalStates.RP,
  '67316': FederalStates.RP,
  '67317': FederalStates.RP,
  '67319': FederalStates.RP,
  '67346': FederalStates.RP,
  '67354': FederalStates.RP,
  '67360': FederalStates.RP,
  '67361': FederalStates.RP,
  '67363': FederalStates.RP,
  '67365': FederalStates.RP,
  '67366': FederalStates.RP,
  '67368': FederalStates.RP,
  '67373': FederalStates.RP,
  '67374': FederalStates.RP,
  '67376': FederalStates.RP,
  '67377': FederalStates.RP,
  '67378': FederalStates.RP,
  '67433': FederalStates.RP,
  '67434': FederalStates.RP,
  '67435': FederalStates.RP,
  '67454': FederalStates.RP,
  '67459': FederalStates.RP,
  '67466': FederalStates.RP,
  '67468': FederalStates.RP,
  '67471': FederalStates.RP,
  '67472': FederalStates.RP,
  '67473': FederalStates.RP,
  '67475': FederalStates.RP,
  '67480': FederalStates.RP,
  '67482': FederalStates.RP,
  '67483': FederalStates.RP,
  '67487': FederalStates.RP,
  '67489': FederalStates.RP,
  '67547': FederalStates.RP,
  '67549': FederalStates.RP,
  '67550': FederalStates.RP,
  '67551': FederalStates.RP,
  '67574': FederalStates.RP,
  '67575': FederalStates.RP,
  '67577': FederalStates.RP,
  '67578': FederalStates.RP,
  '67580': FederalStates.RP,
  '67582': FederalStates.RP,
  '67583': FederalStates.RP,
  '67585': FederalStates.RP,
  '67586': FederalStates.RP,
  '67587': FederalStates.RP,
  '67590': FederalStates.RP,
  '67591': FederalStates.RP,
  '67592': FederalStates.RP,
  '67593': FederalStates.RP,
  '67595': FederalStates.RP,
  '67596': FederalStates.RP,
  '67598': FederalStates.RP,
  '67599': FederalStates.RP,
  '67653': FederalStates.RP,
  '67655': FederalStates.RP,
  '67657': FederalStates.RP,
  '67659': FederalStates.RP,
  '67661': FederalStates.RP,
  '67663': FederalStates.RP,
  '67677': FederalStates.RP,
  '67678': FederalStates.RP,
  '67680': FederalStates.RP,
  '67681': FederalStates.RP,
  '67685': FederalStates.RP,
  '67686': FederalStates.RP,
  '67688': FederalStates.RP,
  '67691': FederalStates.RP,
  '67693': FederalStates.RP,
  '67697': FederalStates.RP,
  '67699': FederalStates.RP,
  '67700': FederalStates.RP,
  '67701': FederalStates.RP,
  '67705': FederalStates.RP,
  '67706': FederalStates.RP,
  '67707': FederalStates.RP,
  '67714': FederalStates.RP,
  '67715': FederalStates.RP,
  '67716': FederalStates.RP,
  '67718': FederalStates.RP,
  '67722': FederalStates.RP,
  '67724': FederalStates.RP,
  '67725': FederalStates.RP,
  '67727': FederalStates.RP,
  '67728': FederalStates.RP,
  '67729': FederalStates.RP,
  '67731': FederalStates.RP,
  '67732': FederalStates.RP,
  '67734': FederalStates.RP,
  '67735': FederalStates.RP,
  '67737': FederalStates.RP,
  '67742': FederalStates.RP,
  '67744': FederalStates.RP,
  '67745': FederalStates.RP,
  '67746': FederalStates.RP,
  '67748': FederalStates.RP,
  '67749': FederalStates.RP,
  '67752': FederalStates.RP,
  '67753': FederalStates.RP,
  '67754': FederalStates.RP,
  '67756': FederalStates.RP,
  '67757': FederalStates.RP,
  '67759': FederalStates.RP,
  '67806': FederalStates.RP,
  '67808': FederalStates.RP,
  '67811': FederalStates.RP,
  '67813': FederalStates.RP,
  '67814': FederalStates.RP,
  '67816': FederalStates.RP,
  '67817': FederalStates.RP,
  '67819': FederalStates.RP,
  '67821': FederalStates.RP,
  '67822': FederalStates.RP,
  '67823': FederalStates.RP,
  '67824': FederalStates.RP,
  '67826': FederalStates.RP,
  '67827': FederalStates.RP,
  '67829': FederalStates.RP,
  '68159': FederalStates.BW,
  '68161': FederalStates.BW,
  '68163': FederalStates.BW,
  '68165': FederalStates.BW,
  '68167': FederalStates.BW,
  '68169': FederalStates.BW,
  '68199': FederalStates.BW,
  '68219': FederalStates.BW,
  '68229': FederalStates.BW,
  '68239': FederalStates.BW,
  '68259': FederalStates.BW,
  '68305': FederalStates.BW,
  '68307': FederalStates.BW,
  '68309': FederalStates.BW,
  '68519': FederalStates.HE,
  '68526': FederalStates.BW,
  '68535': FederalStates.BW,
  '68542': FederalStates.BW,
  '68549': FederalStates.BW,
  '68623': FederalStates.HE,
  '68642': FederalStates.HE,
  '68647': FederalStates.HE,
  '68649': FederalStates.HE,
  '68723': FederalStates.BW,
  '68753': FederalStates.BW,
  '68766': FederalStates.BW,
  '68775': FederalStates.BW,
  '68782': FederalStates.BW,
  '68789': FederalStates.BW,
  '68794': FederalStates.BW,
  '68799': FederalStates.BW,
  '68804': FederalStates.BW,
  '68809': FederalStates.BW,
  '69115': FederalStates.BW,
  '69117': FederalStates.BW,
  '69118': FederalStates.BW,
  '69120': FederalStates.BW,
  '69121': FederalStates.BW,
  '69123': FederalStates.BW,
  '69124': FederalStates.BW,
  '69126': FederalStates.BW,
  '69151': FederalStates.BW,
  '69168': FederalStates.BW,
  '69181': FederalStates.BW,
  '69190': FederalStates.BW,
  '69198': FederalStates.BW,
  '69207': FederalStates.BW,
  '69214': FederalStates.BW,
  '69221': FederalStates.BW,
  '69226': FederalStates.BW,
  '69231': FederalStates.BW,
  '69234': FederalStates.BW,
  '69239': FederalStates.HE,
  '69242': FederalStates.BW,
  '69245': FederalStates.BW,
  '69250': FederalStates.BW,
  '69251': FederalStates.BW,
  '69253': FederalStates.BW,
  '69254': FederalStates.BW,
  '69256': FederalStates.BW,
  '69257': FederalStates.BW,
  '69259': FederalStates.BW,
  '69412': FederalStates.BW,
  '69427': FederalStates.BW,
  '69429': FederalStates.BW,
  '69434': FederalStates.HE,
  '69436': FederalStates.BW,
  '69437': FederalStates.BW,
  '69439': FederalStates.BW,
  '69469': FederalStates.BW,
  '69483': FederalStates.HE,
  '69488': FederalStates.HE,
  '69493': FederalStates.BW,
  '69502': FederalStates.BW,
  '69509': FederalStates.HE,
  '69514': FederalStates.BW,
  '69517': FederalStates.HE,
  '69518': FederalStates.HE,
  '70173': FederalStates.BW,
  '70174': FederalStates.BW,
  '70176': FederalStates.BW,
  '70178': FederalStates.BW,
  '70180': FederalStates.BW,
  '70182': FederalStates.BW,
  '70184': FederalStates.BW,
  '70186': FederalStates.BW,
  '70188': FederalStates.BW,
  '70190': FederalStates.BW,
  '70191': FederalStates.BW,
  '70192': FederalStates.BW,
  '70193': FederalStates.BW,
  '70195': FederalStates.BW,
  '70197': FederalStates.BW,
  '70199': FederalStates.BW,
  '70327': FederalStates.BW,
  '70329': FederalStates.BW,
  '70372': FederalStates.BW,
  '70374': FederalStates.BW,
  '70376': FederalStates.BW,
  '70378': FederalStates.BW,
  '70435': FederalStates.BW,
  '70437': FederalStates.BW,
  '70439': FederalStates.BW,
  '70469': FederalStates.BW,
  '70499': FederalStates.BW,
  '70563': FederalStates.BW,
  '70565': FederalStates.BW,
  '70567': FederalStates.BW,
  '70569': FederalStates.BW,
  '70597': FederalStates.BW,
  '70599': FederalStates.BW,
  '70619': FederalStates.BW,
  '70629': FederalStates.BW,
  '70734': FederalStates.BW,
  '70736': FederalStates.BW,
  '70771': FederalStates.BW,
  '70794': FederalStates.BW,
  '70806': FederalStates.BW,
  '70825': FederalStates.BW,
  '70839': FederalStates.BW,
  '71032': FederalStates.BW,
  '71034': FederalStates.BW,
  '71063': FederalStates.BW,
  '71065': FederalStates.BW,
  '71067': FederalStates.BW,
  '71069': FederalStates.BW,
  '71083': FederalStates.BW,
  '71088': FederalStates.BW,
  '71093': FederalStates.BW,
  '71101': FederalStates.BW,
  '71106': FederalStates.BW,
  '71111': FederalStates.BW,
  '71116': FederalStates.BW,
  '71120': FederalStates.BW,
  '71126': FederalStates.BW,
  '71131': FederalStates.BW,
  '71134': FederalStates.BW,
  '71139': FederalStates.BW,
  '71144': FederalStates.BW,
  '71149': FederalStates.BW,
  '71154': FederalStates.BW,
  '71155': FederalStates.BW,
  '71157': FederalStates.BW,
  '71159': FederalStates.BW,
  '71229': FederalStates.BW,
  '71254': FederalStates.BW,
  '71263': FederalStates.BW,
  '71272': FederalStates.BW,
  '71277': FederalStates.BW,
  '71282': FederalStates.BW,
  '71287': FederalStates.BW,
  '71292': FederalStates.BW,
  '71296': FederalStates.BW,
  '71297': FederalStates.BW,
  '71299': FederalStates.BW,
  '71332': FederalStates.BW,
  '71334': FederalStates.BW,
  '71336': FederalStates.BW,
  '71364': FederalStates.BW,
  '71384': FederalStates.BW,
  '71394': FederalStates.BW,
  '71397': FederalStates.BW,
  '71404': FederalStates.BW,
  '71409': FederalStates.BW,
  '71522': FederalStates.BW,
  '71540': FederalStates.BW,
  '71543': FederalStates.BW,
  '71546': FederalStates.BW,
  '71549': FederalStates.BW,
  '71554': FederalStates.BW,
  '71560': FederalStates.BW,
  '71563': FederalStates.BW,
  '71566': FederalStates.BW,
  '71570': FederalStates.BW,
  '71573': FederalStates.BW,
  '71576': FederalStates.BW,
  '71577': FederalStates.BW,
  '71579': FederalStates.BW,
  '71634': FederalStates.BW,
  '71636': FederalStates.BW,
  '71638': FederalStates.BW,
  '71640': FederalStates.BW,
  '71642': FederalStates.BW,
  '71665': FederalStates.BW,
  '71672': FederalStates.BW,
  '71679': FederalStates.BW,
  '71686': FederalStates.BW,
  '71691': FederalStates.BW,
  '71696': FederalStates.BW,
  '71701': FederalStates.BW,
  '71706': FederalStates.BW,
  '71711': FederalStates.BW,
  '71717': FederalStates.BW,
  '71718': FederalStates.BW,
  '71720': FederalStates.BW,
  '71723': FederalStates.BW,
  '71726': FederalStates.BW,
  '71729': FederalStates.BW,
  '71732': FederalStates.BW,
  '71735': FederalStates.BW,
  '71737': FederalStates.BW,
  '71739': FederalStates.BW,
  '72070': FederalStates.BW,
  '72072': FederalStates.BW,
  '72074': FederalStates.BW,
  '72076': FederalStates.BW,
  '72108': FederalStates.BW,
  '72116': FederalStates.BW,
  '72119': FederalStates.BW,
  '72124': FederalStates.BW,
  '72127': FederalStates.BW,
  '72131': FederalStates.BW,
  '72135': FederalStates.BW,
  '72138': FederalStates.BW,
  '72141': FederalStates.BW,
  '72144': FederalStates.BW,
  '72145': FederalStates.BW,
  '72147': FederalStates.BW,
  '72149': FederalStates.BW,
  '72160': FederalStates.BW,
  '72172': FederalStates.BW,
  '72175': FederalStates.BW,
  '72178': FederalStates.BW,
  '72181': FederalStates.BW,
  '72184': FederalStates.BW,
  '72186': FederalStates.BW,
  '72189': FederalStates.BW,
  '72202': FederalStates.BW,
  '72213': FederalStates.BW,
  '72218': FederalStates.BW,
  '72221': FederalStates.BW,
  '72224': FederalStates.BW,
  '72226': FederalStates.BW,
  '72227': FederalStates.BW,
  '72229': FederalStates.BW,
  '72250': FederalStates.BW,
  '72270': FederalStates.BW,
  '72275': FederalStates.BW,
  '72280': FederalStates.BW,
  '72285': FederalStates.BW,
  '72290': FederalStates.BW,
  '72291': FederalStates.BW,
  '72293': FederalStates.BW,
  '72294': FederalStates.BW,
  '72296': FederalStates.BW,
  '72297': FederalStates.BW,
  '72299': FederalStates.BW,
  '72336': FederalStates.BW,
  '72348': FederalStates.BW,
  '72351': FederalStates.BW,
  '72355': FederalStates.BW,
  '72356': FederalStates.BW,
  '72358': FederalStates.BW,
  '72359': FederalStates.BW,
  '72361': FederalStates.BW,
  '72362': FederalStates.BW,
  '72364': FederalStates.BW,
  '72365': FederalStates.BW,
  '72367': FederalStates.BW,
  '72369': FederalStates.BW,
  '72379': FederalStates.BW,
  '72393': FederalStates.BW,
  '72401': FederalStates.BW,
  '72406': FederalStates.BW,
  '72411': FederalStates.BW,
  '72414': FederalStates.BW,
  '72415': FederalStates.BW,
  '72417': FederalStates.BW,
  '72419': FederalStates.BW,
  '72458': FederalStates.BW,
  '72459': FederalStates.BW,
  '72461': FederalStates.BW,
  '72469': FederalStates.BW,
  '72474': FederalStates.BW,
  '72475': FederalStates.BW,
  '72477': FederalStates.BW,
  '72479': FederalStates.BW,
  '72488': FederalStates.BW,
  '72501': FederalStates.BW,
  '72505': FederalStates.BW,
  '72510': FederalStates.BW,
  '72511': FederalStates.BW,
  '72513': FederalStates.BW,
  '72514': FederalStates.BW,
  '72516': FederalStates.BW,
  '72517': FederalStates.BW,
  '72519': FederalStates.BW,
  '72525': FederalStates.BW,
  '72531': FederalStates.BW,
  '72532': FederalStates.BW,
  '72534': FederalStates.BW,
  '72535': FederalStates.BW,
  '72537': FederalStates.BW,
  '72539': FederalStates.BW,
  '72555': FederalStates.BW,
  '72574': FederalStates.BW,
  '72581': FederalStates.BW,
  '72582': FederalStates.BW,
  '72584': FederalStates.BW,
  '72585': FederalStates.BW,
  '72587': FederalStates.BW,
  '72589': FederalStates.BW,
  '72622': FederalStates.BW,
  '72631': FederalStates.BW,
  '72636': FederalStates.BW,
  '72639': FederalStates.BW,
  '72644': FederalStates.BW,
  '72649': FederalStates.BW,
  '72654': FederalStates.BW,
  '72655': FederalStates.BW,
  '72657': FederalStates.BW,
  '72658': FederalStates.BW,
  '72660': FederalStates.BW,
  '72661': FederalStates.BW,
  '72663': FederalStates.BW,
  '72664': FederalStates.BW,
  '72666': FederalStates.BW,
  '72667': FederalStates.BW,
  '72669': FederalStates.BW,
  '72760': FederalStates.BW,
  '72762': FederalStates.BW,
  '72764': FederalStates.BW,
  '72766': FederalStates.BW,
  '72768': FederalStates.BW,
  '72770': FederalStates.BW,
  '72793': FederalStates.BW,
  '72800': FederalStates.BW,
  '72805': FederalStates.BW,
  '72810': FederalStates.BW,
  '72813': FederalStates.BW,
  '72818': FederalStates.BW,
  '72820': FederalStates.BW,
  '72827': FederalStates.BW,
  '72829': FederalStates.BW,
  '73033': FederalStates.BW,
  '73035': FederalStates.BW,
  '73037': FederalStates.BW,
  '73054': FederalStates.BW,
  '73061': FederalStates.BW,
  '73066': FederalStates.BW,
  '73072': FederalStates.BW,
  '73079': FederalStates.BW,
  '73084': FederalStates.BW,
  '73087': FederalStates.BW,
  '73092': FederalStates.BW,
  '73095': FederalStates.BW,
  '73098': FederalStates.BW,
  '73099': FederalStates.BW,
  '73101': FederalStates.BW,
  '73102': FederalStates.BW,
  '73104': FederalStates.BW,
  '73105': FederalStates.BW,
  '73107': FederalStates.BW,
  '73108': FederalStates.BW,
  '73110': FederalStates.BW,
  '73111': FederalStates.BW,
  '73113': FederalStates.BW,
  '73114': FederalStates.BW,
  '73116': FederalStates.BW,
  '73117': FederalStates.BW,
  '73119': FederalStates.BW,
  '73207': FederalStates.BW,
  '73230': FederalStates.BW,
  '73235': FederalStates.BW,
  '73240': FederalStates.BW,
  '73249': FederalStates.BW,
  '73252': FederalStates.BW,
  '73257': FederalStates.BW,
  '73262': FederalStates.BW,
  '73265': FederalStates.BW,
  '73266': FederalStates.BW,
  '73268': FederalStates.BW,
  '73269': FederalStates.BW,
  '73271': FederalStates.BW,
  '73272': FederalStates.BW,
  '73274': FederalStates.BW,
  '73275': FederalStates.BW,
  '73277': FederalStates.BW,
  '73278': FederalStates.BW,
  '73312': FederalStates.BW,
  '73326': FederalStates.BW,
  '73329': FederalStates.BW,
  '73333': FederalStates.BW,
  '73337': FederalStates.BW,
  '73340': FederalStates.BW,
  '73342': FederalStates.BW,
  '73344': FederalStates.BW,
  '73345': FederalStates.BW,
  '73347': FederalStates.BW,
  '73349': FederalStates.BW,
  '73430': FederalStates.BW,
  '73431': FederalStates.BW,
  '73432': FederalStates.BW,
  '73433': FederalStates.BW,
  '73434': FederalStates.BW,
  '73441': FederalStates.BW,
  '73447': FederalStates.BW,
  '73450': FederalStates.BW,
  '73453': FederalStates.BW,
  '73457': FederalStates.BW,
  '73460': FederalStates.BW,
  '73463': FederalStates.BW,
  '73466': FederalStates.BW,
  '73467': FederalStates.BW,
  '73469': FederalStates.BW,
  '73479': FederalStates.BW,
  '73485': FederalStates.BW,
  '73486': FederalStates.BW,
  '73488': FederalStates.BW,
  '73489': FederalStates.BW,
  '73491': FederalStates.BW,
  '73492': FederalStates.BW,
  '73494': FederalStates.BW,
  '73495': FederalStates.BW,
  '73497': FederalStates.BW,
  '73499': FederalStates.BW,
  '73525': FederalStates.BW,
  '73527': FederalStates.BW,
  '73529': FederalStates.BW,
  '73540': FederalStates.BW,
  '73547': FederalStates.BW,
  '73550': FederalStates.BW,
  '73553': FederalStates.BW,
  '73557': FederalStates.BW,
  '73560': FederalStates.BW,
  '73563': FederalStates.BW,
  '73565': FederalStates.BW,
  '73566': FederalStates.BW,
  '73568': FederalStates.BW,
  '73569': FederalStates.BW,
  '73571': FederalStates.BW,
  '73572': FederalStates.BW,
  '73574': FederalStates.BW,
  '73575': FederalStates.BW,
  '73577': FederalStates.BW,
  '73579': FederalStates.BW,
  '73614': FederalStates.BW,
  '73630': FederalStates.BW,
  '73635': FederalStates.BW,
  '73642': FederalStates.BW,
  '73650': FederalStates.BW,
  '73655': FederalStates.BW,
  '73660': FederalStates.BW,
  '73663': FederalStates.BW,
  '73666': FederalStates.BW,
  '73667': FederalStates.BW,
  '73669': FederalStates.BW,
  '73728': FederalStates.BW,
  '73730': FederalStates.BW,
  '73732': FederalStates.BW,
  '73733': FederalStates.BW,
  '73734': FederalStates.BW,
  '73760': FederalStates.BW,
  '73765': FederalStates.BW,
  '73770': FederalStates.BW,
  '73773': FederalStates.BW,
  '73776': FederalStates.BW,
  '73779': FederalStates.BW,
  '74072': FederalStates.BW,
  '74074': FederalStates.BW,
  '74076': FederalStates.BW,
  '74078': FederalStates.BW,
  '74080': FederalStates.BW,
  '74081': FederalStates.BW,
  '74172': FederalStates.BW,
  '74177': FederalStates.BW,
  '74182': FederalStates.BW,
  '74189': FederalStates.BW,
  '74193': FederalStates.BW,
  '74196': FederalStates.BW,
  '74199': FederalStates.BW,
  '74206': FederalStates.BW,
  '74211': FederalStates.BW,
  '74214': FederalStates.BW,
  '74219': FederalStates.BW,
  '74223': FederalStates.BW,
  '74226': FederalStates.BW,
  '74229': FederalStates.BW,
  '74232': FederalStates.BW,
  '74235': FederalStates.BW,
  '74238': FederalStates.BW,
  '74239': FederalStates.BW,
  '74243': FederalStates.BW,
  '74245': FederalStates.BW,
  '74246': FederalStates.BW,
  '74248': FederalStates.BW,
  '74249': FederalStates.BW,
  '74251': FederalStates.BW,
  '74252': FederalStates.BW,
  '74254': FederalStates.BW,
  '74255': FederalStates.BW,
  '74257': FederalStates.BW,
  '74259': FederalStates.BW,
  '74321': FederalStates.BW,
  '74336': FederalStates.BW,
  '74337': FederalStates.BW,
  '74343': FederalStates.BW,
  '74348': FederalStates.BW,
  '74354': FederalStates.BW,
  '74357': FederalStates.BW,
  '74360': FederalStates.BW,
  '74363': FederalStates.BW,
  '74366': FederalStates.BW,
  '74369': FederalStates.BW,
  '74372': FederalStates.BW,
  '74374': FederalStates.BW,
  '74376': FederalStates.BW,
  '74379': FederalStates.BW,
  '74382': FederalStates.BW,
  '74385': FederalStates.BW,
  '74388': FederalStates.BW,
  '74389': FederalStates.BW,
  '74391': FederalStates.BW,
  '74392': FederalStates.BW,
  '74394': FederalStates.BW,
  '74395': FederalStates.BW,
  '74397': FederalStates.BW,
  '74399': FederalStates.BW,
  '74405': FederalStates.BW,
  '74417': FederalStates.BW,
  '74420': FederalStates.BW,
  '74423': FederalStates.BW,
  '74424': FederalStates.BW,
  '74426': FederalStates.BW,
  '74427': FederalStates.BW,
  '74429': FederalStates.BW,
  '74523': FederalStates.BW,
  '74532': FederalStates.BW,
  '74535': FederalStates.BW,
  '74538': FederalStates.BW,
  '74541': FederalStates.BW,
  '74542': FederalStates.BW,
  '74544': FederalStates.BW,
  '74545': FederalStates.BW,
  '74547': FederalStates.BW,
  '74549': FederalStates.BW,
  '74564': FederalStates.BW,
  '74572': FederalStates.BW,
  '74575': FederalStates.BW,
  '74579': FederalStates.BW,
  '74582': FederalStates.BW,
  '74585': FederalStates.BW,
  '74586': FederalStates.BW,
  '74589': FederalStates.BW,
  '74592': FederalStates.BW,
  '74594': FederalStates.BW,
  '74595': FederalStates.BW,
  '74597': FederalStates.BW,
  '74599': FederalStates.BW,
  '74613': FederalStates.BW,
  '74626': FederalStates.BW,
  '74629': FederalStates.BW,
  '74632': FederalStates.BW,
  '74635': FederalStates.BW,
  '74638': FederalStates.BW,
  '74639': FederalStates.BW,
  '74653': FederalStates.BW,
  '74670': FederalStates.BW,
  '74673': FederalStates.BW,
  '74676': FederalStates.BW,
  '74677': FederalStates.BW,
  '74679': FederalStates.BW,
  '74706': FederalStates.BW,
  '74722': FederalStates.BW,
  '74731': FederalStates.BW,
  '74736': FederalStates.BW,
  '74740': FederalStates.BW,
  '74743': FederalStates.BW,
  '74744': FederalStates.BW,
  '74746': FederalStates.BW,
  '74747': FederalStates.BW,
  '74749': FederalStates.BW,
  '74821': FederalStates.BW,
  '74831': FederalStates.BW,
  '74832': FederalStates.BW,
  '74834': FederalStates.BW,
  '74838': FederalStates.BW,
  '74842': FederalStates.BW,
  '74847': FederalStates.BW,
  '74850': FederalStates.BW,
  '74855': FederalStates.BW,
  '74858': FederalStates.BW,
  '74861': FederalStates.BW,
  '74862': FederalStates.BW,
  '74864': FederalStates.BW,
  '74865': FederalStates.BW,
  '74867': FederalStates.BW,
  '74869': FederalStates.BW,
  '74889': FederalStates.BW,
  '74906': FederalStates.BW,
  '74909': FederalStates.BW,
  '74912': FederalStates.BW,
  '74915': FederalStates.BW,
  '74918': FederalStates.BW,
  '74921': FederalStates.BW,
  '74924': FederalStates.BW,
  '74925': FederalStates.BW,
  '74927': FederalStates.BW,
  '74928': FederalStates.BW,
  '74930': FederalStates.BW,
  '74931': FederalStates.BW,
  '74933': FederalStates.BW,
  '74934': FederalStates.BW,
  '74936': FederalStates.BW,
  '74937': FederalStates.BW,
  '74939': FederalStates.BW,
  '75015': FederalStates.BW,
  '75031': FederalStates.BW,
  '75038': FederalStates.BW,
  '75045': FederalStates.BW,
  '75050': FederalStates.BW,
  '75053': FederalStates.BW,
  '75056': FederalStates.BW,
  '75057': FederalStates.BW,
  '75059': FederalStates.BW,
  '75172': FederalStates.BW,
  '75173': FederalStates.BW,
  '75175': FederalStates.BW,
  '75177': FederalStates.BW,
  '75179': FederalStates.BW,
  '75180': FederalStates.BW,
  '75181': FederalStates.BW,
  '75196': FederalStates.BW,
  '75203': FederalStates.BW,
  '75210': FederalStates.BW,
  '75217': FederalStates.BW,
  '75223': FederalStates.BW,
  '75228': FederalStates.BW,
  '75233': FederalStates.BW,
  '75236': FederalStates.BW,
  '75239': FederalStates.BW,
  '75242': FederalStates.BW,
  '75245': FederalStates.BW,
  '75248': FederalStates.BW,
  '75249': FederalStates.BW,
  '75305': FederalStates.BW,
  '75323': FederalStates.BW,
  '75328': FederalStates.BW,
  '75331': FederalStates.BW,
  '75334': FederalStates.BW,
  '75335': FederalStates.BW,
  '75337': FederalStates.BW,
  '75339': FederalStates.BW,
  '75365': FederalStates.BW,
  '75378': FederalStates.BW,
  '75382': FederalStates.BW,
  '75385': FederalStates.BW,
  '75387': FederalStates.BW,
  '75389': FederalStates.BW,
  '75391': FederalStates.BW,
  '75392': FederalStates.BW,
  '75394': FederalStates.BW,
  '75395': FederalStates.BW,
  '75397': FederalStates.BW,
  '75399': FederalStates.BW,
  '75417': FederalStates.BW,
  '75428': FederalStates.BW,
  '75433': FederalStates.BW,
  '75438': FederalStates.BW,
  '75443': FederalStates.BW,
  '75446': FederalStates.BW,
  '75447': FederalStates.BW,
  '75449': FederalStates.BW,
  '76006': FederalStates.BW,
  '76131': FederalStates.BW,
  '76133': FederalStates.BW,
  '76135': FederalStates.BW,
  '76137': FederalStates.BW,
  '76139': FederalStates.BW,
  '76149': FederalStates.BW,
  '76185': FederalStates.BW,
  '76187': FederalStates.BW,
  '76189': FederalStates.BW,
  '76199': FederalStates.BW,
  '76227': FederalStates.BW,
  '76228': FederalStates.BW,
  '76229': FederalStates.BW,
  '76275': FederalStates.BW,
  '76287': FederalStates.BW,
  '76297': FederalStates.BW,
  '76307': FederalStates.BW,
  '76316': FederalStates.BW,
  '76327': FederalStates.BW,
  '76332': FederalStates.BW,
  '76337': FederalStates.BW,
  '76344': FederalStates.BW,
  '76351': FederalStates.BW,
  '76356': FederalStates.BW,
  '76359': FederalStates.BW,
  '76437': FederalStates.BW,
  '76448': FederalStates.BW,
  '76456': FederalStates.BW,
  '76461': FederalStates.BW,
  '76467': FederalStates.BW,
  '76470': FederalStates.BW,
  '76473': FederalStates.BW,
  '76474': FederalStates.BW,
  '76476': FederalStates.BW,
  '76477': FederalStates.BW,
  '76479': FederalStates.BW,
  '76530': FederalStates.BW,
  '76532': FederalStates.BW,
  '76534': FederalStates.BW,
  '76547': FederalStates.BW,
  '76549': FederalStates.BW,
  '76571': FederalStates.BW,
  '76593': FederalStates.BW,
  '76596': FederalStates.BW,
  '76597': FederalStates.BW,
  '76599': FederalStates.BW,
  '76646': FederalStates.BW,
  '76661': FederalStates.BW,
  '76669': FederalStates.BW,
  '76676': FederalStates.BW,
  '76684': FederalStates.BW,
  '76689': FederalStates.BW,
  '76694': FederalStates.BW,
  '76698': FederalStates.BW,
  '76703': FederalStates.BW,
  '76706': FederalStates.BW,
  '76707': FederalStates.BW,
  '76709': FederalStates.BW,
  '76726': FederalStates.RP,
  '76744': FederalStates.RP,
  '76751': FederalStates.RP,
  '76756': FederalStates.RP,
  '76761': FederalStates.RP,
  '76764': FederalStates.RP,
  '76767': FederalStates.RP,
  '76768': FederalStates.RP,
  '76770': FederalStates.RP,
  '76771': FederalStates.RP,
  '76773': FederalStates.RP,
  '76774': FederalStates.RP,
  '76776': FederalStates.RP,
  '76777': FederalStates.RP,
  '76779': FederalStates.RP,
  '76829': FederalStates.RP,
  '76831': FederalStates.RP,
  '76833': FederalStates.RP,
  '76835': FederalStates.RP,
  '76846': FederalStates.RP,
  '76848': FederalStates.RP,
  '76855': FederalStates.RP,
  '76857': FederalStates.RP,
  '76863': FederalStates.RP,
  '76865': FederalStates.RP,
  '76870': FederalStates.RP,
  '76872': FederalStates.RP,
  '76877': FederalStates.RP,
  '76879': FederalStates.RP,
  '76887': FederalStates.RP,
  '76889': FederalStates.RP,
  '76891': FederalStates.RP,
  '77652': FederalStates.BW,
  '77654': FederalStates.BW,
  '77656': FederalStates.BW,
  '77694': FederalStates.BW,
  '77704': FederalStates.BW,
  '77709': FederalStates.BW,
  '77716': FederalStates.BW,
  '77723': FederalStates.BW,
  '77728': FederalStates.BW,
  '77731': FederalStates.BW,
  '77736': FederalStates.BW,
  '77740': FederalStates.BW,
  '77743': FederalStates.BW,
  '77746': FederalStates.BW,
  '77749': FederalStates.BW,
  '77756': FederalStates.BW,
  '77761': FederalStates.BW,
  '77767': FederalStates.BW,
  '77770': FederalStates.BW,
  '77773': FederalStates.BW,
  '77776': FederalStates.BW,
  '77781': FederalStates.BW,
  '77784': FederalStates.BW,
  '77787': FederalStates.BW,
  '77790': FederalStates.BW,
  '77791': FederalStates.BW,
  '77793': FederalStates.BW,
  '77794': FederalStates.BW,
  '77796': FederalStates.BW,
  '77797': FederalStates.BW,
  '77799': FederalStates.BW,
  '77815': FederalStates.BW,
  '77830': FederalStates.BW,
  '77833': FederalStates.BW,
  '77836': FederalStates.BW,
  '77839': FederalStates.BW,
  '77855': FederalStates.BW,
  '77866': FederalStates.BW,
  '77871': FederalStates.BW,
  '77876': FederalStates.BW,
  '77880': FederalStates.BW,
  '77883': FederalStates.BW,
  '77886': FederalStates.BW,
  '77887': FederalStates.BW,
  '77889': FederalStates.BW,
  '77933': FederalStates.BW,
  '77944': FederalStates.BW,
  '77948': FederalStates.BW,
  '77955': FederalStates.BW,
  '77960': FederalStates.BW,
  '77963': FederalStates.BW,
  '77966': FederalStates.BW,
  '77971': FederalStates.BW,
  '77972': FederalStates.BW,
  '77974': FederalStates.BW,
  '77975': FederalStates.BW,
  '77977': FederalStates.BW,
  '77978': FederalStates.BW,
  '78048': FederalStates.BW,
  '78050': FederalStates.BW,
  '78052': FederalStates.BW,
  '78054': FederalStates.BW,
  '78056': FederalStates.BW,
  '78073': FederalStates.BW,
  '78078': FederalStates.BW,
  '78083': FederalStates.BW,
  '78086': FederalStates.BW,
  '78087': FederalStates.BW,
  '78089': FederalStates.BW,
  '78098': FederalStates.BW,
  '78112': FederalStates.BW,
  '78120': FederalStates.BW,
  '78126': FederalStates.BW,
  '78132': FederalStates.BW,
  '78136': FederalStates.BW,
  '78141': FederalStates.BW,
  '78144': FederalStates.BW,
  '78147': FederalStates.BW,
  '78148': FederalStates.BW,
  '78166': FederalStates.BW,
  '78176': FederalStates.BW,
  '78183': FederalStates.BW,
  '78187': FederalStates.BW,
  '78194': FederalStates.BW,
  '78199': FederalStates.BW,
  '78224': FederalStates.BW,
  '78234': FederalStates.BW,
  '78239': FederalStates.BW,
  '78244': FederalStates.BW,
  '78247': FederalStates.BW,
  '78250': FederalStates.BW,
  '78253': FederalStates.BW,
  '78256': FederalStates.BW,
  '78259': FederalStates.BW,
  '78262': FederalStates.BW,
  '78266': FederalStates.BW,
  '78267': FederalStates.BW,
  '78269': FederalStates.BW,
  '78315': FederalStates.BW,
  '78333': FederalStates.BW,
  '78337': FederalStates.BW,
  '78343': FederalStates.BW,
  '78345': FederalStates.BW,
  '78351': FederalStates.BW,
  '78354': FederalStates.BW,
  '78355': FederalStates.BW,
  '78357': FederalStates.BW,
  '78359': FederalStates.BW,
  '78462': FederalStates.BW,
  '78464': FederalStates.BW,
  '78465': FederalStates.BW,
  '78467': FederalStates.BW,
  '78476': FederalStates.BW,
  '78479': FederalStates.BW,
  '78532': FederalStates.BW,
  '78549': FederalStates.BW,
  '78554': FederalStates.BW,
  '78559': FederalStates.BW,
  '78564': FederalStates.BW,
  '78567': FederalStates.BW,
  '78570': FederalStates.BW,
  '78573': FederalStates.BW,
  '78576': FederalStates.BW,
  '78579': FederalStates.BW,
  '78580': FederalStates.BW,
  '78582': FederalStates.BW,
  '78583': FederalStates.BW,
  '78585': FederalStates.BW,
  '78586': FederalStates.BW,
  '78588': FederalStates.BW,
  '78589': FederalStates.BW,
  '78591': FederalStates.BW,
  '78592': FederalStates.BW,
  '78594': FederalStates.BW,
  '78595': FederalStates.BW,
  '78597': FederalStates.BW,
  '78598': FederalStates.BW,
  '78600': FederalStates.BW,
  '78601': FederalStates.BW,
  '78603': FederalStates.BW,
  '78604': FederalStates.BW,
  '78606': FederalStates.BW,
  '78607': FederalStates.BW,
  '78609': FederalStates.BW,
  '78628': FederalStates.BW,
  '78647': FederalStates.BW,
  '78652': FederalStates.BW,
  '78655': FederalStates.BW,
  '78658': FederalStates.BW,
  '78661': FederalStates.BW,
  '78662': FederalStates.BW,
  '78664': FederalStates.BW,
  '78665': FederalStates.BW,
  '78667': FederalStates.BW,
  '78669': FederalStates.BW,
  '78713': FederalStates.BW,
  '78727': FederalStates.BW,
  '78730': FederalStates.BW,
  '78733': FederalStates.BW,
  '78736': FederalStates.BW,
  '78737': FederalStates.BW,
  '78739': FederalStates.BW,
  '79098': FederalStates.BW,
  '79100': FederalStates.BW,
  '79102': FederalStates.BW,
  '79104': FederalStates.BW,
  '79106': FederalStates.BW,
  '79108': FederalStates.BW,
  '79110': FederalStates.BW,
  '79111': FederalStates.BW,
  '79112': FederalStates.BW,
  '79114': FederalStates.BW,
  '79115': FederalStates.BW,
  '79117': FederalStates.BW,
  '79183': FederalStates.BW,
  '79189': FederalStates.BW,
  '79194': FederalStates.BW,
  '79199': FederalStates.BW,
  '79206': FederalStates.BW,
  '79211': FederalStates.BW,
  '79215': FederalStates.BW,
  '79219': FederalStates.BW,
  '79224': FederalStates.BW,
  '79227': FederalStates.BW,
  '79232': FederalStates.BW,
  '79235': FederalStates.BW,
  '79238': FederalStates.BW,
  '79241': FederalStates.BW,
  '79244': FederalStates.BW,
  '79249': FederalStates.BW,
  '79252': FederalStates.BW,
  '79254': FederalStates.BW,
  '79256': FederalStates.BW,
  '79258': FederalStates.BW,
  '79261': FederalStates.BW,
  '79263': FederalStates.BW,
  '79268': FederalStates.BW,
  '79271': FederalStates.BW,
  '79274': FederalStates.BW,
  '79276': FederalStates.BW,
  '79279': FederalStates.BW,
  '79280': FederalStates.BW,
  '79282': FederalStates.BW,
  '79283': FederalStates.BW,
  '79285': FederalStates.BW,
  '79286': FederalStates.BW,
  '79288': FederalStates.BW,
  '79289': FederalStates.BW,
  '79291': FederalStates.BW,
  '79292': FederalStates.BW,
  '79294': FederalStates.BW,
  '79295': FederalStates.BW,
  '79297': FederalStates.BW,
  '79299': FederalStates.BW,
  '79312': FederalStates.BW,
  '79331': FederalStates.BW,
  '79336': FederalStates.BW,
  '79341': FederalStates.BW,
  '79346': FederalStates.BW,
  '79348': FederalStates.BW,
  '79350': FederalStates.BW,
  '79353': FederalStates.BW,
  '79356': FederalStates.BW,
  '79359': FederalStates.BW,
  '79361': FederalStates.BW,
  '79362': FederalStates.BW,
  '79364': FederalStates.BW,
  '79365': FederalStates.BW,
  '79367': FederalStates.BW,
  '79369': FederalStates.BW,
  '79379': FederalStates.BW,
  '79395': FederalStates.BW,
  '79400': FederalStates.BW,
  '79410': FederalStates.BW,
  '79415': FederalStates.BW,
  '79418': FederalStates.BW,
  '79423': FederalStates.BW,
  '79424': FederalStates.BW,
  '79426': FederalStates.BW,
  '79427': FederalStates.BW,
  '79429': FederalStates.BW,
  '79513': FederalStates.BW,
  '79539': FederalStates.BW,
  '79540': FederalStates.BW,
  '79541': FederalStates.BW,
  '79576': FederalStates.BW,
  '79578': FederalStates.BW,
  '79585': FederalStates.BW,
  '79588': FederalStates.BW,
  '79589': FederalStates.BW,
  '79591': FederalStates.BW,
  '79592': FederalStates.BW,
  '79594': FederalStates.BW,
  '79595': FederalStates.BW,
  '79597': FederalStates.BW,
  '79599': FederalStates.BW,
  '79618': FederalStates.BW,
  '79639': FederalStates.BW,
  '79650': FederalStates.BW,
  '79664': FederalStates.BW,
  '79669': FederalStates.BW,
  '79674': FederalStates.BW,
  '79677': FederalStates.BW,
  '79682': FederalStates.BW,
  '79683': FederalStates.BW,
  '79685': FederalStates.BW,
  '79686': FederalStates.BW,
  '79688': FederalStates.BW,
  '79689': FederalStates.BW,
  '79691': FederalStates.BW,
  '79692': FederalStates.BW,
  '79694': FederalStates.BW,
  '79695': FederalStates.BW,
  '79697': FederalStates.BW,
  '79699': FederalStates.BW,
  '79713': FederalStates.BW,
  '79725': FederalStates.BW,
  '79730': FederalStates.BW,
  '79733': FederalStates.BW,
  '79736': FederalStates.BW,
  '79737': FederalStates.BW,
  '79739': FederalStates.BW,
  '79761': FederalStates.BW,
  '79771': FederalStates.BW,
  '79774': FederalStates.BW,
  '79777': FederalStates.BW,
  '79780': FederalStates.BW,
  '79787': FederalStates.BW,
  '79790': FederalStates.BW,
  '79793': FederalStates.BW,
  '79798': FederalStates.BW,
  '79801': FederalStates.BW,
  '79802': FederalStates.BW,
  '79804': FederalStates.BW,
  '79805': FederalStates.BW,
  '79807': FederalStates.BW,
  '79809': FederalStates.BW,
  '79822': FederalStates.BW,
  '79837': FederalStates.BW,
  '79843': FederalStates.BW,
  '79848': FederalStates.BW,
  '79853': FederalStates.BW,
  '79856': FederalStates.BW,
  '79859': FederalStates.BW,
  '79862': FederalStates.BW,
  '79865': FederalStates.BW,
  '79868': FederalStates.BW,
  '79871': FederalStates.BW,
  '79872': FederalStates.BW,
  '79874': FederalStates.BW,
  '79875': FederalStates.BW,
  '79877': FederalStates.BW,
  '79879': FederalStates.BW,
  '80331': FederalStates.BY,
  '80333': FederalStates.BY,
  '80335': FederalStates.BY,
  '80336': FederalStates.BY,
  '80337': FederalStates.BY,
  '80339': FederalStates.BY,
  '80469': FederalStates.BY,
  '80538': FederalStates.BY,
  '80539': FederalStates.BY,
  '80634': FederalStates.BY,
  '80636': FederalStates.BY,
  '80637': FederalStates.BY,
  '80638': FederalStates.BY,
  '80639': FederalStates.BY,
  '80686': FederalStates.BY,
  '80687': FederalStates.BY,
  '80689': FederalStates.BY,
  '80796': FederalStates.BY,
  '80797': FederalStates.BY,
  '80798': FederalStates.BY,
  '80799': FederalStates.BY,
  '80801': FederalStates.BY,
  '80802': FederalStates.BY,
  '80803': FederalStates.BY,
  '80804': FederalStates.BY,
  '80805': FederalStates.BY,
  '80807': FederalStates.BY,
  '80809': FederalStates.BY,
  '80933': FederalStates.BY,
  '80935': FederalStates.BY,
  '80937': FederalStates.BY,
  '80939': FederalStates.BY,
  '80992': FederalStates.BY,
  '80993': FederalStates.BY,
  '80995': FederalStates.BY,
  '80997': FederalStates.BY,
  '80999': FederalStates.BY,
  '81241': FederalStates.BY,
  '81243': FederalStates.BY,
  '81245': FederalStates.BY,
  '81247': FederalStates.BY,
  '81249': FederalStates.BY,
  '81369': FederalStates.BY,
  '81371': FederalStates.BY,
  '81373': FederalStates.BY,
  '81375': FederalStates.BY,
  '81377': FederalStates.BY,
  '81379': FederalStates.BY,
  '81475': FederalStates.BY,
  '81476': FederalStates.BY,
  '81477': FederalStates.BY,
  '81479': FederalStates.BY,
  '81539': FederalStates.BY,
  '81541': FederalStates.BY,
  '81543': FederalStates.BY,
  '81545': FederalStates.BY,
  '81547': FederalStates.BY,
  '81549': FederalStates.BY,
  '81667': FederalStates.BY,
  '81669': FederalStates.BY,
  '81671': FederalStates.BY,
  '81673': FederalStates.BY,
  '81675': FederalStates.BY,
  '81677': FederalStates.BY,
  '81679': FederalStates.BY,
  '81735': FederalStates.BY,
  '81737': FederalStates.BY,
  '81739': FederalStates.BY,
  '81825': FederalStates.BY,
  '81827': FederalStates.BY,
  '81829': FederalStates.BY,
  '81925': FederalStates.BY,
  '81927': FederalStates.BY,
  '81929': FederalStates.BY,
  '82008': FederalStates.BY,
  '82024': FederalStates.BY,
  '82031': FederalStates.BY,
  '82032': FederalStates.BY,
  '82041': FederalStates.BY,
  '82049': FederalStates.BY,
  '82054': FederalStates.BY,
  '82057': FederalStates.BY,
  '82061': FederalStates.BY,
  '82064': FederalStates.BY,
  '82065': FederalStates.BY,
  '82067': FederalStates.BY,
  '82069': FederalStates.BY,
  '82110': FederalStates.BY,
  '82131': FederalStates.BY,
  '82140': FederalStates.BY,
  '82152': FederalStates.BY,
  '82166': FederalStates.BY,
  '82178': FederalStates.BY,
  '82194': FederalStates.BY,
  '82205': FederalStates.BY,
  '82211': FederalStates.BY,
  '82216': FederalStates.BY,
  '82223': FederalStates.BY,
  '82229': FederalStates.BY,
  '82234': FederalStates.BY,
  '82237': FederalStates.BY,
  '82239': FederalStates.BY,
  '82256': FederalStates.BY,
  '82266': FederalStates.BY,
  '82269': FederalStates.BY,
  '82272': FederalStates.BY,
  '82275': FederalStates.BY,
  '82276': FederalStates.BY,
  '82278': FederalStates.BY,
  '82279': FederalStates.BY,
  '82281': FederalStates.BY,
  '82282': FederalStates.BY,
  '82284': FederalStates.BY,
  '82285': FederalStates.BY,
  '82287': FederalStates.BY,
  '82288': FederalStates.BY,
  '82290': FederalStates.BY,
  '82291': FederalStates.BY,
  '82293': FederalStates.BY,
  '82294': FederalStates.BY,
  '82296': FederalStates.BY,
  '82297': FederalStates.BY,
  '82299': FederalStates.BY,
  '82319': FederalStates.BY,
  '82327': FederalStates.BY,
  '82335': FederalStates.BY,
  '82340': FederalStates.BY,
  '82343': FederalStates.BY,
  '82346': FederalStates.BY,
  '82347': FederalStates.BY,
  '82349': FederalStates.BY,
  '82362': FederalStates.BY,
  '82377': FederalStates.BY,
  '82380': FederalStates.BY,
  '82383': FederalStates.BY,
  '82386': FederalStates.BY,
  '82387': FederalStates.BY,
  '82389': FederalStates.BY,
  '82390': FederalStates.BY,
  '82392': FederalStates.BY,
  '82393': FederalStates.BY,
  '82395': FederalStates.BY,
  '82396': FederalStates.BY,
  '82398': FederalStates.BY,
  '82399': FederalStates.BY,
  '82401': FederalStates.BY,
  '82402': FederalStates.BY,
  '82404': FederalStates.BY,
  '82405': FederalStates.BY,
  '82407': FederalStates.BY,
  '82409': FederalStates.BY,
  '82418': FederalStates.BY,
  '82431': FederalStates.BY,
  '82432': FederalStates.BY,
  '82433': FederalStates.BY,
  '82435': FederalStates.BY,
  '82436': FederalStates.BY,
  '82438': FederalStates.BY,
  '82439': FederalStates.BY,
  '82441': FederalStates.BY,
  '82442': FederalStates.BY,
  '82444': FederalStates.BY,
  '82445': FederalStates.BY,
  '82447': FederalStates.BY,
  '82449': FederalStates.BY,
  '82467': FederalStates.BY,
  '82481': FederalStates.BY,
  '82487': FederalStates.BY,
  '82488': FederalStates.BY,
  '82490': FederalStates.BY,
  '82491': FederalStates.BY,
  '82493': FederalStates.BY,
  '82494': FederalStates.BY,
  '82496': FederalStates.BY,
  '82497': FederalStates.BY,
  '82499': FederalStates.BY,
  '82515': FederalStates.BY,
  '82538': FederalStates.BY,
  '82541': FederalStates.BY,
  '82544': FederalStates.BY,
  '82547': FederalStates.BY,
  '82549': FederalStates.BY,
  '83022': FederalStates.BY,
  '83024': FederalStates.BY,
  '83026': FederalStates.BY,
  '83043': FederalStates.BY,
  '83052': FederalStates.BY,
  '83059': FederalStates.BY,
  '83064': FederalStates.BY,
  '83071': FederalStates.BY,
  '83075': FederalStates.BY,
  '83080': FederalStates.BY,
  '83083': FederalStates.BY,
  '83088': FederalStates.BY,
  '83093': FederalStates.BY,
  '83098': FederalStates.BY,
  '83101': FederalStates.BY,
  '83104': FederalStates.BY,
  '83109': FederalStates.BY,
  '83112': FederalStates.BY,
  '83115': FederalStates.BY,
  '83119': FederalStates.BY,
  '83122': FederalStates.BY,
  '83123': FederalStates.BY,
  '83125': FederalStates.BY,
  '83126': FederalStates.BY,
  '83128': FederalStates.BY,
  '83129': FederalStates.BY,
  '83131': FederalStates.BY,
  '83132': FederalStates.BY,
  '83134': FederalStates.BY,
  '83135': FederalStates.BY,
  '83137': FederalStates.BY,
  '83139': FederalStates.BY,
  '83209': FederalStates.BY,
  '83224': FederalStates.BY,
  '83229': FederalStates.BY,
  '83233': FederalStates.BY,
  '83236': FederalStates.BY,
  '83242': FederalStates.BY,
  '83246': FederalStates.BY,
  '83250': FederalStates.BY,
  '83253': FederalStates.BY,
  '83254': FederalStates.BY,
  '83256': FederalStates.BY,
  '83257': FederalStates.BY,
  '83259': FederalStates.BY,
  '83278': FederalStates.BY,
  '83301': FederalStates.BY,
  '83308': FederalStates.BY,
  '83313': FederalStates.BY,
  '83317': FederalStates.BY,
  '83324': FederalStates.BY,
  '83329': FederalStates.BY,
  '83334': FederalStates.BY,
  '83339': FederalStates.BY,
  '83342': FederalStates.BY,
  '83344': FederalStates.BY,
  '83346': FederalStates.BY,
  '83349': FederalStates.BY,
  '83352': FederalStates.BY,
  '83355': FederalStates.BY,
  '83358': FederalStates.BY,
  '83359': FederalStates.BY,
  '83361': FederalStates.BY,
  '83362': FederalStates.BY,
  '83364': FederalStates.BY,
  '83365': FederalStates.BY,
  '83367': FederalStates.BY,
  '83368': FederalStates.BY,
  '83370': FederalStates.BY,
  '83371': FederalStates.BY,
  '83373': FederalStates.BY,
  '83374': FederalStates.BY,
  '83376': FederalStates.BY,
  '83377': FederalStates.BY,
  '83379': FederalStates.BY,
  '83395': FederalStates.BY,
  '83404': FederalStates.BY,
  '83410': FederalStates.BY,
  '83413': FederalStates.BY,
  '83416': FederalStates.BY,
  '83417': FederalStates.BY,
  '83435': FederalStates.BY,
  '83451': FederalStates.BY,
  '83454': FederalStates.BY,
  '83457': FederalStates.BY,
  '83458': FederalStates.BY,
  '83471': FederalStates.BY,
  '83483': FederalStates.BY,
  '83486': FederalStates.BY,
  '83487': FederalStates.BY,
  '83489': FederalStates.BY,
  '83512': FederalStates.BY,
  '83527': FederalStates.BY,
  '83530': FederalStates.BY,
  '83533': FederalStates.BY,
  '83536': FederalStates.BY,
  '83539': FederalStates.BY,
  '83543': FederalStates.BY,
  '83544': FederalStates.BY,
  '83546': FederalStates.BY,
  '83547': FederalStates.BY,
  '83549': FederalStates.BY,
  '83550': FederalStates.BY,
  '83552': FederalStates.BY,
  '83553': FederalStates.BY,
  '83555': FederalStates.BY,
  '83556': FederalStates.BY,
  '83558': FederalStates.BY,
  '83559': FederalStates.BY,
  '83561': FederalStates.BY,
  '83562': FederalStates.BY,
  '83564': FederalStates.BY,
  '83565': FederalStates.BY,
  '83567': FederalStates.BY,
  '83569': FederalStates.BY,
  '83607': FederalStates.BY,
  '83620': FederalStates.BY,
  '83623': FederalStates.BY,
  '83624': FederalStates.BY,
  '83626': FederalStates.BY,
  '83627': FederalStates.BY,
  '83629': FederalStates.BY,
  '83646': FederalStates.BY,
  '83661': FederalStates.BY,
  '83666': FederalStates.BY,
  '83670': FederalStates.BY,
  '83671': FederalStates.BY,
  '83673': FederalStates.BY,
  '83674': FederalStates.BY,
  '83676': FederalStates.BY,
  '83677': FederalStates.BY,
  '83679': FederalStates.BY,
  '83684': FederalStates.BY,
  '83700': FederalStates.BY,
  '83703': FederalStates.BY,
  '83707': FederalStates.BY,
  '83708': FederalStates.BY,
  '83714': FederalStates.BY,
  '83727': FederalStates.BY,
  '83730': FederalStates.BY,
  '83734': FederalStates.BY,
  '83735': FederalStates.BY,
  '83737': FederalStates.BY,
  '83739': FederalStates.BY,
  '84028': FederalStates.BY,
  '84030': FederalStates.BY,
  '84032': FederalStates.BY,
  '84034': FederalStates.BY,
  '84036': FederalStates.BY,
  '84048': FederalStates.BY,
  '84051': FederalStates.BY,
  '84056': FederalStates.BY,
  '84061': FederalStates.BY,
  '84066': FederalStates.BY,
  '84069': FederalStates.BY,
  '84072': FederalStates.BY,
  '84076': FederalStates.BY,
  '84079': FederalStates.BY,
  '84082': FederalStates.BY,
  '84085': FederalStates.BY,
  '84088': FederalStates.BY,
  '84089': FederalStates.BY,
  '84091': FederalStates.BY,
  '84092': FederalStates.BY,
  '84094': FederalStates.BY,
  '84095': FederalStates.BY,
  '84097': FederalStates.BY,
  '84098': FederalStates.BY,
  '84100': FederalStates.BY,
  '84101': FederalStates.BY,
  '84103': FederalStates.BY,
  '84104': FederalStates.BY,
  '84106': FederalStates.BY,
  '84107': FederalStates.BY,
  '84109': FederalStates.BY,
  '84130': FederalStates.BY,
  '84137': FederalStates.BY,
  '84140': FederalStates.BY,
  '84144': FederalStates.BY,
  '84149': FederalStates.BY,
  '84152': FederalStates.BY,
  '84155': FederalStates.BY,
  '84160': FederalStates.BY,
  '84163': FederalStates.BY,
  '84164': FederalStates.BY,
  '84166': FederalStates.BY,
  '84168': FederalStates.BY,
  '84169': FederalStates.BY,
  '84171': FederalStates.BY,
  '84172': FederalStates.BY,
  '84174': FederalStates.BY,
  '84175': FederalStates.BY,
  '84177': FederalStates.BY,
  '84178': FederalStates.BY,
  '84180': FederalStates.BY,
  '84181': FederalStates.BY,
  '84183': FederalStates.BY,
  '84184': FederalStates.BY,
  '84186': FederalStates.BY,
  '84187': FederalStates.BY,
  '84189': FederalStates.BY,
  '84307': FederalStates.BY,
  '84323': FederalStates.BY,
  '84326': FederalStates.BY,
  '84329': FederalStates.BY,
  '84332': FederalStates.BY,
  '84333': FederalStates.BY,
  '84335': FederalStates.BY,
  '84337': FederalStates.BY,
  '84339': FederalStates.BY,
  '84347': FederalStates.BY,
  '84359': FederalStates.BY,
  '84364': FederalStates.BY,
  '84367': FederalStates.BY,
  '84371': FederalStates.BY,
  '84375': FederalStates.BY,
  '84378': FederalStates.BY,
  '84381': FederalStates.BY,
  '84384': FederalStates.BY,
  '84385': FederalStates.BY,
  '84387': FederalStates.BY,
  '84389': FederalStates.BY,
  '84405': FederalStates.BY,
  '84416': FederalStates.BY,
  '84419': FederalStates.BY,
  '84424': FederalStates.BY,
  '84427': FederalStates.BY,
  '84428': FederalStates.BY,
  '84431': FederalStates.BY,
  '84432': FederalStates.BY,
  '84434': FederalStates.BY,
  '84435': FederalStates.BY,
  '84437': FederalStates.BY,
  '84439': FederalStates.BY,
  '84453': FederalStates.BY,
  '84478': FederalStates.BY,
  '84489': FederalStates.BY,
  '84494': FederalStates.BY,
  '84503': FederalStates.BY,
  '84508': FederalStates.BY,
  '84513': FederalStates.BY,
  '84518': FederalStates.BY,
  '84524': FederalStates.BY,
  '84529': FederalStates.BY,
  '84533': FederalStates.BY,
  '84539': FederalStates.BY,
  '84543': FederalStates.BY,
  '84544': FederalStates.BY,
  '84546': FederalStates.BY,
  '84547': FederalStates.BY,
  '84549': FederalStates.BY,
  '84550': FederalStates.BY,
  '84552': FederalStates.BY,
  '84553': FederalStates.BY,
  '84555': FederalStates.BY,
  '84556': FederalStates.BY,
  '84558': FederalStates.BY,
  '84559': FederalStates.BY,
  '84561': FederalStates.BY,
  '84562': FederalStates.BY,
  '84564': FederalStates.BY,
  '84565': FederalStates.BY,
  '84567': FederalStates.BY,
  '84568': FederalStates.BY,
  '84570': FederalStates.BY,
  '84571': FederalStates.BY,
  '84573': FederalStates.BY,
  '84574': FederalStates.BY,
  '84576': FederalStates.BY,
  '84577': FederalStates.BY,
  '84579': FederalStates.BY,
  '85049': FederalStates.BY,
  '85051': FederalStates.BY,
  '85053': FederalStates.BY,
  '85055': FederalStates.BY,
  '85057': FederalStates.BY,
  '85072': FederalStates.BY,
  '85077': FederalStates.BY,
  '85080': FederalStates.BY,
  '85084': FederalStates.BY,
  '85088': FederalStates.BY,
  '85092': FederalStates.BY,
  '85095': FederalStates.BY,
  '85098': FederalStates.BY,
  '85101': FederalStates.BY,
  '85104': FederalStates.BY,
  '85107': FederalStates.BY,
  '85110': FederalStates.BY,
  '85111': FederalStates.BY,
  '85113': FederalStates.BY,
  '85114': FederalStates.BY,
  '85116': FederalStates.BY,
  '85117': FederalStates.BY,
  '85119': FederalStates.BY,
  '85120': FederalStates.BY,
  '85122': FederalStates.BY,
  '85123': FederalStates.BY,
  '85125': FederalStates.BY,
  '85126': FederalStates.BY,
  '85128': FederalStates.BY,
  '85129': FederalStates.BY,
  '85131': FederalStates.BY,
  '85132': FederalStates.BY,
  '85134': FederalStates.BY,
  '85135': FederalStates.BY,
  '85137': FederalStates.BY,
  '85139': FederalStates.BY,
  '85221': FederalStates.BY,
  '85229': FederalStates.BY,
  '85232': FederalStates.BY,
  '85235': FederalStates.BY,
  '85238': FederalStates.BY,
  '85241': FederalStates.BY,
  '85244': FederalStates.BY,
  '85247': FederalStates.BY,
  '85250': FederalStates.BY,
  '85253': FederalStates.BY,
  '85254': FederalStates.BY,
  '85256': FederalStates.BY,
  '85258': FederalStates.BY,
  '85259': FederalStates.BY,
  '85276': FederalStates.BY,
  '85283': FederalStates.BY,
  '85290': FederalStates.BY,
  '85293': FederalStates.BY,
  '85296': FederalStates.BY,
  '85298': FederalStates.BY,
  '85301': FederalStates.BY,
  '85302': FederalStates.BY,
  '85304': FederalStates.BY,
  '85305': FederalStates.BY,
  '85307': FederalStates.BY,
  '85309': FederalStates.BY,
  '85354': FederalStates.BY,
  '85356': FederalStates.BY,
  '85368': FederalStates.BY,
  '85375': FederalStates.BY,
  '85376': FederalStates.BY,
  '85386': FederalStates.BY,
  '85391': FederalStates.BY,
  '85395': FederalStates.BY,
  '85399': FederalStates.BY,
  '85402': FederalStates.BY,
  '85405': FederalStates.BY,
  '85406': FederalStates.BY,
  '85408': FederalStates.BY,
  '85410': FederalStates.BY,
  '85411': FederalStates.BY,
  '85413': FederalStates.BY,
  '85414': FederalStates.BY,
  '85416': FederalStates.BY,
  '85417': FederalStates.BY,
  '85419': FederalStates.BY,
  '85435': FederalStates.BY,
  '85445': FederalStates.BY,
  '85447': FederalStates.BY,
  '85452': FederalStates.BY,
  '85456': FederalStates.BY,
  '85457': FederalStates.BY,
  '85459': FederalStates.BY,
  '85461': FederalStates.BY,
  '85462': FederalStates.BY,
  '85464': FederalStates.BY,
  '85465': FederalStates.BY,
  '85467': FederalStates.BY,
  '85469': FederalStates.BY,
  '85521': FederalStates.BY,
  '85540': FederalStates.BY,
  '85551': FederalStates.BY,
  '85560': FederalStates.BY,
  '85567': FederalStates.BY,
  '85570': FederalStates.BY,
  '85579': FederalStates.BY,
  '85586': FederalStates.BY,
  '85591': FederalStates.BY,
  '85598': FederalStates.BY,
  '85599': FederalStates.BY,
  '85604': FederalStates.BY,
  '85609': FederalStates.BY,
  '85614': FederalStates.BY,
  '85617': FederalStates.BY,
  '85622': FederalStates.BY,
  '85625': FederalStates.BY,
  '85630': FederalStates.BY,
  '85635': FederalStates.BY,
  '85640': FederalStates.BY,
  '85643': FederalStates.BY,
  '85646': FederalStates.BY,
  '85649': FederalStates.BY,
  '85652': FederalStates.BY,
  '85653': FederalStates.BY,
  '85655': FederalStates.BY,
  '85656': FederalStates.BY,
  '85658': FederalStates.BY,
  '85659': FederalStates.BY,
  '85661': FederalStates.BY,
  '85662': FederalStates.BY,
  '85664': FederalStates.BY,
  '85665': FederalStates.BY,
  '85667': FederalStates.BY,
  '85669': FederalStates.BY,
  '85716': FederalStates.BY,
  '85737': FederalStates.BY,
  '85748': FederalStates.BY,
  '85757': FederalStates.BY,
  '85764': FederalStates.BY,
  '85774': FederalStates.BY,
  '85777': FederalStates.BY,
  '85778': FederalStates.BY,
  '86150': FederalStates.BY,
  '86152': FederalStates.BY,
  '86153': FederalStates.BY,
  '86154': FederalStates.BY,
  '86156': FederalStates.BY,
  '86157': FederalStates.BY,
  '86159': FederalStates.BY,
  '86161': FederalStates.BY,
  '86163': FederalStates.BY,
  '86165': FederalStates.BY,
  '86167': FederalStates.BY,
  '86169': FederalStates.BY,
  '86179': FederalStates.BY,
  '86199': FederalStates.BY,
  '86316': FederalStates.BY,
  '86343': FederalStates.BY,
  '86356': FederalStates.BY,
  '86368': FederalStates.BY,
  '86381': FederalStates.BY,
  '86391': FederalStates.BY,
  '86399': FederalStates.BY,
  '86405': FederalStates.BY,
  '86415': FederalStates.BY,
  '86420': FederalStates.BY,
  '86424': FederalStates.BY,
  '86438': FederalStates.BY,
  '86441': FederalStates.BY,
  '86444': FederalStates.BY,
  '86447': FederalStates.BY,
  '86450': FederalStates.BY,
  '86453': FederalStates.BY,
  '86456': FederalStates.BY,
  '86459': FederalStates.BY,
  '86462': FederalStates.BY,
  '86465': FederalStates.BY,
  '86470': FederalStates.BY,
  '86473': FederalStates.BY,
  '86476': FederalStates.BY,
  '86477': FederalStates.BY,
  '86479': FederalStates.BY,
  '86480': FederalStates.BY,
  '86482': FederalStates.BY,
  '86483': FederalStates.BY,
  '86485': FederalStates.BY,
  '86486': FederalStates.BY,
  '86488': FederalStates.BY,
  '86489': FederalStates.BY,
  '86491': FederalStates.BY,
  '86492': FederalStates.BY,
  '86494': FederalStates.BY,
  '86495': FederalStates.BY,
  '86497': FederalStates.BY,
  '86498': FederalStates.BY,
  '86500': FederalStates.BY,
  '86502': FederalStates.BY,
  '86504': FederalStates.BY,
  '86505': FederalStates.BY,
  '86507': FederalStates.BY,
  '86508': FederalStates.BY,
  '86510': FederalStates.BY,
  '86511': FederalStates.BY,
  '86513': FederalStates.BY,
  '86514': FederalStates.BY,
  '86517': FederalStates.BY,
  '86519': FederalStates.BY,
  '86529': FederalStates.BY,
  '86551': FederalStates.BY,
  '86554': FederalStates.BY,
  '86556': FederalStates.BY,
  '86558': FederalStates.BY,
  '86559': FederalStates.BY,
  '86561': FederalStates.BY,
  '86562': FederalStates.BY,
  '86564': FederalStates.BY,
  '86565': FederalStates.BY,
  '86567': FederalStates.BY,
  '86568': FederalStates.BY,
  '86570': FederalStates.BY,
  '86571': FederalStates.BY,
  '86573': FederalStates.BY,
  '86574': FederalStates.BY,
  '86576': FederalStates.BY,
  '86577': FederalStates.BY,
  '86579': FederalStates.BY,
  '86609': FederalStates.BY,
  '86633': FederalStates.BY,
  '86637': FederalStates.BY,
  '86641': FederalStates.BY,
  '86643': FederalStates.BY,
  '86647': FederalStates.BY,
  '86650': FederalStates.BY,
  '86653': FederalStates.BY,
  '86655': FederalStates.BY,
  '86657': FederalStates.BY,
  '86660': FederalStates.BY,
  '86663': FederalStates.BY,
  '86666': FederalStates.BY,
  '86668': FederalStates.BY,
  '86669': FederalStates.BY,
  '86672': FederalStates.BY,
  '86673': FederalStates.BY,
  '86674': FederalStates.BY,
  '86675': FederalStates.BY,
  '86676': FederalStates.BY,
  '86678': FederalStates.BY,
  '86679': FederalStates.BY,
  '86681': FederalStates.BY,
  '86682': FederalStates.BY,
  '86684': FederalStates.BY,
  '86685': FederalStates.BY,
  '86687': FederalStates.BY,
  '86688': FederalStates.BY,
  '86690': FederalStates.BY,
  '86692': FederalStates.BY,
  '86694': FederalStates.BY,
  '86695': FederalStates.BY,
  '86697': FederalStates.BY,
  '86698': FederalStates.BY,
  '86700': FederalStates.BY,
  '86701': FederalStates.BY,
  '86703': FederalStates.BY,
  '86704': FederalStates.BY,
  '86706': FederalStates.BY,
  '86707': FederalStates.BY,
  '86709': FederalStates.BY,
  '86720': FederalStates.BY,
  '86732': FederalStates.BY,
  '86733': FederalStates.BY,
  '86735': FederalStates.BY,
  '86736': FederalStates.BY,
  '86738': FederalStates.BY,
  '86739': FederalStates.BY,
  '86741': FederalStates.BY,
  '86742': FederalStates.BY,
  '86744': FederalStates.BY,
  '86745': FederalStates.BY,
  '86747': FederalStates.BY,
  '86748': FederalStates.BY,
  '86750': FederalStates.BY,
  '86751': FederalStates.BY,
  '86753': FederalStates.BY,
  '86754': FederalStates.BY,
  '86756': FederalStates.BY,
  '86757': FederalStates.BY,
  '86759': FederalStates.BY,
  '86807': FederalStates.BY,
  '86825': FederalStates.BY,
  '86830': FederalStates.BY,
  '86833': FederalStates.BY,
  '86836': FederalStates.BY,
  '86842': FederalStates.BY,
  '86845': FederalStates.BY,
  '86850': FederalStates.BY,
  '86853': FederalStates.BY,
  '86854': FederalStates.BY,
  '86856': FederalStates.BY,
  '86857': FederalStates.BY,
  '86859': FederalStates.BY,
  '86860': FederalStates.BY,
  '86862': FederalStates.BY,
  '86863': FederalStates.BY,
  '86865': FederalStates.BY,
  '86866': FederalStates.BY,
  '86868': FederalStates.BY,
  '86869': FederalStates.BY,
  '86871': FederalStates.BY,
  '86872': FederalStates.BY,
  '86874': FederalStates.BY,
  '86875': FederalStates.BY,
  '86877': FederalStates.BY,
  '86879': FederalStates.BY,
  '86899': FederalStates.BY,
  '86911': FederalStates.BY,
  '86916': FederalStates.BY,
  '86919': FederalStates.BY,
  '86920': FederalStates.BY,
  '86922': FederalStates.BY,
  '86923': FederalStates.BY,
  '86925': FederalStates.BY,
  '86926': FederalStates.BY,
  '86928': FederalStates.BY,
  '86929': FederalStates.BY,
  '86931': FederalStates.BY,
  '86932': FederalStates.BY,
  '86934': FederalStates.BY,
  '86935': FederalStates.BY,
  '86937': FederalStates.BY,
  '86938': FederalStates.BY,
  '86940': FederalStates.BY,
  '86941': FederalStates.BY,
  '86943': FederalStates.BY,
  '86944': FederalStates.BY,
  '86946': FederalStates.BY,
  '86947': FederalStates.BY,
  '86949': FederalStates.BY,
  '86956': FederalStates.BY,
  '86971': FederalStates.BY,
  '86972': FederalStates.BY,
  '86974': FederalStates.BY,
  '86975': FederalStates.BY,
  '86977': FederalStates.BY,
  '86978': FederalStates.BY,
  '86980': FederalStates.BY,
  '86981': FederalStates.BY,
  '86983': FederalStates.BY,
  '86984': FederalStates.BY,
  '86986': FederalStates.BY,
  '86987': FederalStates.BY,
  '86989': FederalStates.BY,
  '87435': FederalStates.BY,
  '87437': FederalStates.BY,
  '87439': FederalStates.BY,
  '87448': FederalStates.BY,
  '87452': FederalStates.BY,
  '87459': FederalStates.BY,
  '87463': FederalStates.BY,
  '87466': FederalStates.BY,
  '87471': FederalStates.BY,
  '87474': FederalStates.BY,
  '87477': FederalStates.BY,
  '87480': FederalStates.BY,
  '87484': FederalStates.BY,
  '87487': FederalStates.BY,
  '87488': FederalStates.BY,
  '87490': FederalStates.BY,
  '87493': FederalStates.BY,
  '87494': FederalStates.BY,
  '87496': FederalStates.BY,
  '87497': FederalStates.BY,
  '87499': FederalStates.BY,
  '87509': FederalStates.BY,
  '87527': FederalStates.BY,
  '87534': FederalStates.BY,
  '87538': FederalStates.BY,
  '87541': FederalStates.BY,
  '87544': FederalStates.BY,
  '87545': FederalStates.BY,
  '87547': FederalStates.BY,
  '87549': FederalStates.BY,
  '87561': FederalStates.BY,
  '87600': FederalStates.BY,
  '87616': FederalStates.BY,
  '87629': FederalStates.BY,
  '87634': FederalStates.BY,
  '87637': FederalStates.BY,
  '87640': FederalStates.BY,
  '87642': FederalStates.BY,
  '87645': FederalStates.BY,
  '87647': FederalStates.BY,
  '87648': FederalStates.BY,
  '87650': FederalStates.BY,
  '87651': FederalStates.BY,
  '87653': FederalStates.BY,
  '87654': FederalStates.BY,
  '87656': FederalStates.BY,
  '87657': FederalStates.BY,
  '87659': FederalStates.BY,
  '87660': FederalStates.BY,
  '87662': FederalStates.BY,
  '87663': FederalStates.BY,
  '87665': FederalStates.BY,
  '87666': FederalStates.BY,
  '87668': FederalStates.BY,
  '87669': FederalStates.BY,
  '87671': FederalStates.BY,
  '87672': FederalStates.BY,
  '87674': FederalStates.BY,
  '87675': FederalStates.BY,
  '87677': FederalStates.BY,
  '87679': FederalStates.BY,
  '87700': FederalStates.BY,
  '87719': FederalStates.BY,
  '87724': FederalStates.BY,
  '87727': FederalStates.BY,
  '87730': FederalStates.BY,
  '87733': FederalStates.BY,
  '87734': FederalStates.BY,
  '87736': FederalStates.BY,
  '87737': FederalStates.BY,
  '87739': FederalStates.BY,
  '87740': FederalStates.BY,
  '87742': FederalStates.BY,
  '87743': FederalStates.BY,
  '87745': FederalStates.BY,
  '87746': FederalStates.BY,
  '87748': FederalStates.BY,
  '87749': FederalStates.BY,
  '87751': FederalStates.BY,
  '87752': FederalStates.BY,
  '87754': FederalStates.BY,
  '87755': FederalStates.BY,
  '87757': FederalStates.BY,
  '87758': FederalStates.BY,
  '87760': FederalStates.BY,
  '87761': FederalStates.BY,
  '87763': FederalStates.BY,
  '87764': FederalStates.BY,
  '87766': FederalStates.BY,
  '87767': FederalStates.BY,
  '87769': FederalStates.BY,
  '87770': FederalStates.BY,
  '87772': FederalStates.BY,
  '87773': FederalStates.BY,
  '87775': FederalStates.BY,
  '87776': FederalStates.BY,
  '87778': FederalStates.BY,
  '87779': FederalStates.BY,
  '87781': FederalStates.BY,
  '87782': FederalStates.BY,
  '87784': FederalStates.BY,
  '87785': FederalStates.BY,
  '87787': FederalStates.BY,
  '87789': FederalStates.BY,
  '88045': FederalStates.BW,
  '88046': FederalStates.BW,
  '88048': FederalStates.BW,
  '88069': FederalStates.BW,
  '88074': FederalStates.BW,
  '88079': FederalStates.BW,
  '88085': FederalStates.BW,
  '88086': FederalStates.BW,
  '88090': FederalStates.BW,
  '88094': FederalStates.BW,
  '88097': FederalStates.BW,
  '88099': FederalStates.BW,
  '88131': FederalStates.BY,
  '88138': FederalStates.BY,
  '88142': FederalStates.BY,
  '88145': FederalStates.BY,
  '88147': FederalStates.BW,
  '88149': FederalStates.BY,
  '88161': FederalStates.BY,
  '88167': FederalStates.BY,
  '88171': FederalStates.BY,
  '88175': FederalStates.BY,
  '88178': FederalStates.BY,
  '88179': FederalStates.BY,
  '88212': FederalStates.BW,
  '88213': FederalStates.BW,
  '88214': FederalStates.BW,
  '88239': FederalStates.BW,
  '88250': FederalStates.BW,
  '88255': FederalStates.BW,
  '88260': FederalStates.BW,
  '88263': FederalStates.BW,
  '88267': FederalStates.BW,
  '88271': FederalStates.BW,
  '88273': FederalStates.BW,
  '88276': FederalStates.BW,
  '88279': FederalStates.BW,
  '88281': FederalStates.BW,
  '88284': FederalStates.BW,
  '88285': FederalStates.BW,
  '88287': FederalStates.BW,
  '88289': FederalStates.BW,
  '88299': FederalStates.BW,
  '88316': FederalStates.BW,
  '88317': FederalStates.BW,
  '88319': FederalStates.BW,
  '88326': FederalStates.BW,
  '88339': FederalStates.BW,
  '88348': FederalStates.BW,
  '88353': FederalStates.BW,
  '88356': FederalStates.BW,
  '88361': FederalStates.BW,
  '88364': FederalStates.BW,
  '88367': FederalStates.BW,
  '88368': FederalStates.BW,
  '88370': FederalStates.BW,
  '88371': FederalStates.BW,
  '88373': FederalStates.BW,
  '88374': FederalStates.BW,
  '88376': FederalStates.BW,
  '88377': FederalStates.BW,
  '88379': FederalStates.BW,
  '88400': FederalStates.BW,
  '88410': FederalStates.BW,
  '88416': FederalStates.BW,
  '88422': FederalStates.BW,
  '88427': FederalStates.BW,
  '88430': FederalStates.BW,
  '88433': FederalStates.BW,
  '88436': FederalStates.BW,
  '88437': FederalStates.BW,
  '88441': FederalStates.BW,
  '88444': FederalStates.BW,
  '88447': FederalStates.BW,
  '88448': FederalStates.BW,
  '88450': FederalStates.BW,
  '88451': FederalStates.BW,
  '88453': FederalStates.BW,
  '88454': FederalStates.BW,
  '88456': FederalStates.BW,
  '88457': FederalStates.BW,
  '88459': FederalStates.BW,
  '88471': FederalStates.BW,
  '88477': FederalStates.BW,
  '88480': FederalStates.BW,
  '88481': FederalStates.BW,
  '88483': FederalStates.BW,
  '88484': FederalStates.BW,
  '88486': FederalStates.BW,
  '88487': FederalStates.BW,
  '88489': FederalStates.BW,
  '88499': FederalStates.BW,
  '88512': FederalStates.BW,
  '88515': FederalStates.BW,
  '88518': FederalStates.BW,
  '88521': FederalStates.BW,
  '88524': FederalStates.BW,
  '88525': FederalStates.BW,
  '88527': FederalStates.BW,
  '88529': FederalStates.BW,
  '88605': FederalStates.BW,
  '88630': FederalStates.BW,
  '88631': FederalStates.BW,
  '88633': FederalStates.BW,
  '88634': FederalStates.BW,
  '88636': FederalStates.BW,
  '88637': FederalStates.BW,
  '88639': FederalStates.BW,
  '88662': FederalStates.BW,
  '88677': FederalStates.BW,
  '88682': FederalStates.BW,
  '88690': FederalStates.BW,
  '88693': FederalStates.BW,
  '88696': FederalStates.BW,
  '88697': FederalStates.BW,
  '88699': FederalStates.BW,
  '88709': FederalStates.BW,
  '88718': FederalStates.BW,
  '88719': FederalStates.BW,
  '89040': FederalStates.BW,
  '89073': FederalStates.BW,
  '89075': FederalStates.BW,
  '89077': FederalStates.BW,
  '89079': FederalStates.BW,
  '89081': FederalStates.BW,
  '89129': FederalStates.BW,
  '89134': FederalStates.BW,
  '89143': FederalStates.BW,
  '89150': FederalStates.BW,
  '89155': FederalStates.BW,
  '89160': FederalStates.BW,
  '89165': FederalStates.BW,
  '89168': FederalStates.BW,
  '89171': FederalStates.BW,
  '89173': FederalStates.BW,
  '89174': FederalStates.BW,
  '89176': FederalStates.BW,
  '89177': FederalStates.BW,
  '89179': FederalStates.BW,
  '89180': FederalStates.BW,
  '89182': FederalStates.BW,
  '89183': FederalStates.BW,
  '89185': FederalStates.BW,
  '89186': FederalStates.BW,
  '89188': FederalStates.BW,
  '89189': FederalStates.BW,
  '89191': FederalStates.BW,
  '89192': FederalStates.BW,
  '89194': FederalStates.BW,
  '89195': FederalStates.BW,
  '89197': FederalStates.BW,
  '89198': FederalStates.BW,
  '89231': FederalStates.BY,
  '89233': FederalStates.BY,
  '89250': FederalStates.BY,
  '89257': FederalStates.BY,
  '89264': FederalStates.BY,
  '89269': FederalStates.BY,
  '89275': FederalStates.BY,
  '89278': FederalStates.BY,
  '89281': FederalStates.BY,
  '89284': FederalStates.BY,
  '89287': FederalStates.BY,
  '89290': FederalStates.BY,
  '89291': FederalStates.BY,
  '89293': FederalStates.BY,
  '89294': FederalStates.BY,
  '89296': FederalStates.BY,
  '89297': FederalStates.BY,
  '89299': FederalStates.BY,
  '89312': FederalStates.BY,
  '89331': FederalStates.BY,
  '89335': FederalStates.BY,
  '89340': FederalStates.BY,
  '89343': FederalStates.BY,
  '89344': FederalStates.BY,
  '89346': FederalStates.BY,
  '89347': FederalStates.BY,
  '89349': FederalStates.BY,
  '89350': FederalStates.BY,
  '89352': FederalStates.BY,
  '89353': FederalStates.BY,
  '89355': FederalStates.BY,
  '89356': FederalStates.BY,
  '89358': FederalStates.BY,
  '89359': FederalStates.BY,
  '89361': FederalStates.BY,
  '89362': FederalStates.BY,
  '89364': FederalStates.BY,
  '89365': FederalStates.BY,
  '89367': FederalStates.BY,
  '89368': FederalStates.BY,
  '89407': FederalStates.BY,
  '89415': FederalStates.BY,
  '89420': FederalStates.BY,
  '89423': FederalStates.BY,
  '89426': FederalStates.BY,
  '89428': FederalStates.BY,
  '89429': FederalStates.BY,
  '89431': FederalStates.BY,
  '89432': FederalStates.BY,
  '89434': FederalStates.BY,
  '89435': FederalStates.BY,
  '89437': FederalStates.BY,
  '89438': FederalStates.BY,
  '89440': FederalStates.BY,
  '89441': FederalStates.BY,
  '89443': FederalStates.BY,
  '89444': FederalStates.BY,
  '89446': FederalStates.BY,
  '89447': FederalStates.BY,
  '89449': FederalStates.BY,
  '89518': FederalStates.BW,
  '89520': FederalStates.BW,
  '89522': FederalStates.BW,
  '89537': FederalStates.BW,
  '89542': FederalStates.BW,
  '89547': FederalStates.BW,
  '89551': FederalStates.BW,
  '89555': FederalStates.BW,
  '89558': FederalStates.BW,
  '89561': FederalStates.BW,
  '89564': FederalStates.BW,
  '89567': FederalStates.BW,
  '89568': FederalStates.BW,
  '89584': FederalStates.BW,
  '89597': FederalStates.BW,
  '89601': FederalStates.BW,
  '89604': FederalStates.BW,
  '89605': FederalStates.BW,
  '89607': FederalStates.BW,
  '89608': FederalStates.BW,
  '89610': FederalStates.BW,
  '89611': FederalStates.BW,
  '89613': FederalStates.BW,
  '89614': FederalStates.BW,
  '89616': FederalStates.BW,
  '89617': FederalStates.BW,
  '89619': FederalStates.BW,
  '90402': FederalStates.BY,
  '90403': FederalStates.BY,
  '90408': FederalStates.BY,
  '90409': FederalStates.BY,
  '90411': FederalStates.BY,
  '90419': FederalStates.BY,
  '90425': FederalStates.BY,
  '90427': FederalStates.BY,
  '90429': FederalStates.BY,
  '90431': FederalStates.BY,
  '90439': FederalStates.BY,
  '90441': FederalStates.BY,
  '90443': FederalStates.BY,
  '90449': FederalStates.BY,
  '90451': FederalStates.BY,
  '90453': FederalStates.BY,
  '90455': FederalStates.BY,
  '90457': FederalStates.BY,
  '90459': FederalStates.BY,
  '90461': FederalStates.BY,
  '90469': FederalStates.BY,
  '90471': FederalStates.BY,
  '90473': FederalStates.BY,
  '90475': FederalStates.BY,
  '90478': FederalStates.BY,
  '90480': FederalStates.BY,
  '90482': FederalStates.BY,
  '90489': FederalStates.BY,
  '90491': FederalStates.BY,
  '90513': FederalStates.BY,
  '90518': FederalStates.BY,
  '90522': FederalStates.BY,
  '90530': FederalStates.BY,
  '90537': FederalStates.BY,
  '90542': FederalStates.BY,
  '90547': FederalStates.BY,
  '90552': FederalStates.BY,
  '90556': FederalStates.BY,
  '90559': FederalStates.BY,
  '90562': FederalStates.BY,
  '90571': FederalStates.BY,
  '90574': FederalStates.BY,
  '90579': FederalStates.BY,
  '90584': FederalStates.BY,
  '90587': FederalStates.BY,
  '90592': FederalStates.BY,
  '90596': FederalStates.BY,
  '90599': FederalStates.BY,
  '90602': FederalStates.BY,
  '90607': FederalStates.BY,
  '90610': FederalStates.BY,
  '90613': FederalStates.BY,
  '90614': FederalStates.BY,
  '90616': FederalStates.BY,
  '90617': FederalStates.BY,
  '90619': FederalStates.BY,
  '90762': FederalStates.BY,
  '90763': FederalStates.BY,
  '90765': FederalStates.BY,
  '90766': FederalStates.BY,
  '90768': FederalStates.BY,
  '91052': FederalStates.BY,
  '91054': FederalStates.BY,
  '91056': FederalStates.BY,
  '91058': FederalStates.BY,
  '91074': FederalStates.BY,
  '91077': FederalStates.BY,
  '91080': FederalStates.BY,
  '91083': FederalStates.BY,
  '91085': FederalStates.BY,
  '91086': FederalStates.BY,
  '91088': FederalStates.BY,
  '91090': FederalStates.BY,
  '91091': FederalStates.BY,
  '91093': FederalStates.BY,
  '91094': FederalStates.BY,
  '91096': FederalStates.BY,
  '91097': FederalStates.BY,
  '91099': FederalStates.BY,
  '91126': FederalStates.BY,
  '91154': FederalStates.BY,
  '91161': FederalStates.BY,
  '91166': FederalStates.BY,
  '91171': FederalStates.BY,
  '91174': FederalStates.BY,
  '91177': FederalStates.BY,
  '91180': FederalStates.BY,
  '91183': FederalStates.BY,
  '91186': FederalStates.BY,
  '91187': FederalStates.BY,
  '91189': FederalStates.BY,
  '91207': FederalStates.BY,
  '91217': FederalStates.BY,
  '91220': FederalStates.BY,
  '91224': FederalStates.BY,
  '91227': FederalStates.BY,
  '91230': FederalStates.BY,
  '91233': FederalStates.BY,
  '91235': FederalStates.BY,
  '91236': FederalStates.BY,
  '91238': FederalStates.BY,
  '91239': FederalStates.BY,
  '91241': FederalStates.BY,
  '91242': FederalStates.BY,
  '91244': FederalStates.BY,
  '91245': FederalStates.BY,
  '91247': FederalStates.BY,
  '91249': FederalStates.BY,
  '91257': FederalStates.BY,
  '91275': FederalStates.BY,
  '91278': FederalStates.BY,
  '91281': FederalStates.BY,
  '91282': FederalStates.BY,
  '91284': FederalStates.BY,
  '91286': FederalStates.BY,
  '91287': FederalStates.BY,
  '91289': FederalStates.BY,
  '91301': FederalStates.BY,
  '91315': FederalStates.BY,
  '91320': FederalStates.BY,
  '91322': FederalStates.BY,
  '91325': FederalStates.BY,
  '91327': FederalStates.BY,
  '91330': FederalStates.BY,
  '91332': FederalStates.BY,
  '91334': FederalStates.BY,
  '91336': FederalStates.BY,
  '91338': FederalStates.BY,
  '91341': FederalStates.BY,
  '91344': FederalStates.BY,
  '91346': FederalStates.BY,
  '91347': FederalStates.BY,
  '91349': FederalStates.BY,
  '91350': FederalStates.BY,
  '91352': FederalStates.BY,
  '91353': FederalStates.BY,
  '91355': FederalStates.BY,
  '91356': FederalStates.BY,
  '91358': FederalStates.BY,
  '91359': FederalStates.BY,
  '91361': FederalStates.BY,
  '91362': FederalStates.BY,
  '91364': FederalStates.BY,
  '91365': FederalStates.BY,
  '91367': FederalStates.BY,
  '91369': FederalStates.BY,
  '91413': FederalStates.BY,
  '91438': FederalStates.BY,
  '91443': FederalStates.BY,
  '91448': FederalStates.BY,
  '91452': FederalStates.BY,
  '91456': FederalStates.BY,
  '91459': FederalStates.BY,
  '91460': FederalStates.BY,
  '91462': FederalStates.BY,
  '91463': FederalStates.BY,
  '91465': FederalStates.BY,
  '91466': FederalStates.BY,
  '91468': FederalStates.BY,
  '91469': FederalStates.BY,
  '91471': FederalStates.BY,
  '91472': FederalStates.BY,
  '91474': FederalStates.BY,
  '91475': FederalStates.BY,
  '91477': FederalStates.BY,
  '91478': FederalStates.BY,
  '91480': FederalStates.BY,
  '91481': FederalStates.BY,
  '91483': FederalStates.BY,
  '91484': FederalStates.BY,
  '91486': FederalStates.BY,
  '91487': FederalStates.BY,
  '91489': FederalStates.BY,
  '91522': FederalStates.BY,
  '91541': FederalStates.BY,
  '91550': FederalStates.BY,
  '91555': FederalStates.BY,
  '91560': FederalStates.BY,
  '91564': FederalStates.BY,
  '91567': FederalStates.BY,
  '91572': FederalStates.BY,
  '91575': FederalStates.BY,
  '91578': FederalStates.BY,
  '91580': FederalStates.BY,
  '91583': FederalStates.BY,
  '91586': FederalStates.BY,
  '91587': FederalStates.BY,
  '91589': FederalStates.BY,
  '91590': FederalStates.BY,
  '91592': FederalStates.BY,
  '91593': FederalStates.BY,
  '91595': FederalStates.BY,
  '91596': FederalStates.BY,
  '91598': FederalStates.BY,
  '91599': FederalStates.BY,
  '91601': FederalStates.BY,
  '91602': FederalStates.BY,
  '91604': FederalStates.BY,
  '91605': FederalStates.BY,
  '91607': FederalStates.BY,
  '91608': FederalStates.BY,
  '91610': FederalStates.BY,
  '91611': FederalStates.BY,
  '91613': FederalStates.BY,
  '91614': FederalStates.BY,
  '91616': FederalStates.BY,
  '91617': FederalStates.BY,
  '91619': FederalStates.BY,
  '91620': FederalStates.BY,
  '91622': FederalStates.BY,
  '91623': FederalStates.BY,
  '91625': FederalStates.BY,
  '91626': FederalStates.BY,
  '91628': FederalStates.BY,
  '91629': FederalStates.BY,
  '91631': FederalStates.BY,
  '91632': FederalStates.BY,
  '91634': FederalStates.BY,
  '91635': FederalStates.BY,
  '91637': FederalStates.BY,
  '91639': FederalStates.BY,
  '91710': FederalStates.BY,
  '91717': FederalStates.BY,
  '91719': FederalStates.BY,
  '91720': FederalStates.BY,
  '91722': FederalStates.BY,
  '91723': FederalStates.BY,
  '91725': FederalStates.BY,
  '91726': FederalStates.BY,
  '91728': FederalStates.BY,
  '91729': FederalStates.BY,
  '91731': FederalStates.BY,
  '91732': FederalStates.BY,
  '91734': FederalStates.BY,
  '91735': FederalStates.BY,
  '91737': FederalStates.BY,
  '91738': FederalStates.BY,
  '91740': FederalStates.BY,
  '91741': FederalStates.BY,
  '91743': FederalStates.BY,
  '91744': FederalStates.BY,
  '91746': FederalStates.BY,
  '91747': FederalStates.BY,
  '91749': FederalStates.BY,
  '91757': FederalStates.BY,
  '91781': FederalStates.BY,
  '91785': FederalStates.BY,
  '91788': FederalStates.BY,
  '91790': FederalStates.BY,
  '91792': FederalStates.BY,
  '91793': FederalStates.BY,
  '91795': FederalStates.BY,
  '91796': FederalStates.BY,
  '91798': FederalStates.BY,
  '91799': FederalStates.BY,
  '91801': FederalStates.BY,
  '91802': FederalStates.BY,
  '91804': FederalStates.BY,
  '91805': FederalStates.BY,
  '91807': FederalStates.BY,
  '91809': FederalStates.BY,
  '92224': FederalStates.BY,
  '92237': FederalStates.BY,
  '92242': FederalStates.BY,
  '92245': FederalStates.BY,
  '92249': FederalStates.BY,
  '92253': FederalStates.BY,
  '92256': FederalStates.BY,
  '92259': FederalStates.BY,
  '92260': FederalStates.BY,
  '92262': FederalStates.BY,
  '92263': FederalStates.BY,
  '92265': FederalStates.BY,
  '92266': FederalStates.BY,
  '92268': FederalStates.BY,
  '92269': FederalStates.BY,
  '92271': FederalStates.BY,
  '92272': FederalStates.BY,
  '92274': FederalStates.BY,
  '92275': FederalStates.BY,
  '92277': FederalStates.BY,
  '92278': FederalStates.BY,
  '92280': FederalStates.BY,
  '92281': FederalStates.BY,
  '92283': FederalStates.BY,
  '92284': FederalStates.BY,
  '92286': FederalStates.BY,
  '92287': FederalStates.BY,
  '92289': FederalStates.BY,
  '92318': FederalStates.BY,
  '92331': FederalStates.BY,
  '92334': FederalStates.BY,
  '92339': FederalStates.BY,
  '92342': FederalStates.BY,
  '92345': FederalStates.BY,
  '92348': FederalStates.BY,
  '92353': FederalStates.BY,
  '92355': FederalStates.BY,
  '92358': FederalStates.BY,
  '92360': FederalStates.BY,
  '92361': FederalStates.BY,
  '92363': FederalStates.BY,
  '92364': FederalStates.BY,
  '92366': FederalStates.BY,
  '92367': FederalStates.BY,
  '92369': FederalStates.BY,
  '92421': FederalStates.BY,
  '92431': FederalStates.BY,
  '92436': FederalStates.BY,
  '92439': FederalStates.BY,
  '92442': FederalStates.BY,
  '92444': FederalStates.BY,
  '92445': FederalStates.BY,
  '92447': FederalStates.BY,
  '92449': FederalStates.BY,
  '92507': FederalStates.BY,
  '92521': FederalStates.BY,
  '92526': FederalStates.BY,
  '92533': FederalStates.BY,
  '92536': FederalStates.BY,
  '92539': FederalStates.BY,
  '92540': FederalStates.BY,
  '92542': FederalStates.BY,
  '92543': FederalStates.BY,
  '92545': FederalStates.BY,
  '92546': FederalStates.BY,
  '92548': FederalStates.BY,
  '92549': FederalStates.BY,
  '92551': FederalStates.BY,
  '92552': FederalStates.BY,
  '92554': FederalStates.BY,
  '92555': FederalStates.BY,
  '92557': FederalStates.BY,
  '92559': FederalStates.BY,
  '92637': FederalStates.BY,
  '92648': FederalStates.BY,
  '92655': FederalStates.BY,
  '92660': FederalStates.BY,
  '92665': FederalStates.BY,
  '92670': FederalStates.BY,
  '92676': FederalStates.BY,
  '92681': FederalStates.BY,
  '92685': FederalStates.BY,
  '92690': FederalStates.BY,
  '92693': FederalStates.BY,
  '92694': FederalStates.BY,
  '92696': FederalStates.BY,
  '92697': FederalStates.BY,
  '92699': FederalStates.BY,
  '92700': FederalStates.BY,
  '92702': FederalStates.BY,
  '92703': FederalStates.BY,
  '92705': FederalStates.BY,
  '92706': FederalStates.BY,
  '92708': FederalStates.BY,
  '92709': FederalStates.BY,
  '92711': FederalStates.BY,
  '92712': FederalStates.BY,
  '92714': FederalStates.BY,
  '92715': FederalStates.BY,
  '92717': FederalStates.BY,
  '92718': FederalStates.BY,
  '92720': FederalStates.BY,
  '92721': FederalStates.BY,
  '92723': FederalStates.BY,
  '92724': FederalStates.BY,
  '92726': FederalStates.BY,
  '92727': FederalStates.BY,
  '92729': FederalStates.BY,
  '93047': FederalStates.BY,
  '93049': FederalStates.BY,
  '93051': FederalStates.BY,
  '93053': FederalStates.BY,
  '93055': FederalStates.BY,
  '93057': FederalStates.BY,
  '93059': FederalStates.BY,
  '93073': FederalStates.BY,
  '93077': FederalStates.BY,
  '93080': FederalStates.BY,
  '93083': FederalStates.BY,
  '93086': FederalStates.BY,
  '93087': FederalStates.BY,
  '93089': FederalStates.BY,
  '93090': FederalStates.BY,
  '93092': FederalStates.BY,
  '93093': FederalStates.BY,
  '93095': FederalStates.BY,
  '93096': FederalStates.BY,
  '93098': FederalStates.BY,
  '93099': FederalStates.BY,
  '93101': FederalStates.BY,
  '93102': FederalStates.BY,
  '93104': FederalStates.BY,
  '93105': FederalStates.BY,
  '93107': FederalStates.BY,
  '93109': FederalStates.BY,
  '93128': FederalStates.BY,
  '93133': FederalStates.BY,
  '93138': FederalStates.BY,
  '93142': FederalStates.BY,
  '93149': FederalStates.BY,
  '93152': FederalStates.BY,
  '93155': FederalStates.BY,
  '93158': FederalStates.BY,
  '93161': FederalStates.BY,
  '93164': FederalStates.BY,
  '93167': FederalStates.BY,
  '93170': FederalStates.BY,
  '93173': FederalStates.BY,
  '93176': FederalStates.BY,
  '93177': FederalStates.BY,
  '93179': FederalStates.BY,
  '93180': FederalStates.BY,
  '93182': FederalStates.BY,
  '93183': FederalStates.BY,
  '93185': FederalStates.BY,
  '93186': FederalStates.BY,
  '93188': FederalStates.BY,
  '93189': FederalStates.BY,
  '93191': FederalStates.BY,
  '93192': FederalStates.BY,
  '93194': FederalStates.BY,
  '93195': FederalStates.BY,
  '93197': FederalStates.BY,
  '93199': FederalStates.BY,
  '93309': FederalStates.BY,
  '93326': FederalStates.BY,
  '93333': FederalStates.BY,
  '93336': FederalStates.BY,
  '93339': FederalStates.BY,
  '93342': FederalStates.BY,
  '93343': FederalStates.BY,
  '93345': FederalStates.BY,
  '93346': FederalStates.BY,
  '93348': FederalStates.BY,
  '93349': FederalStates.BY,
  '93351': FederalStates.BY,
  '93352': FederalStates.BY,
  '93354': FederalStates.BY,
  '93356': FederalStates.BY,
  '93358': FederalStates.BY,
  '93359': FederalStates.BY,
  '93413': FederalStates.BY,
  '93426': FederalStates.BY,
  '93437': FederalStates.BY,
  '93444': FederalStates.BY,
  '93449': FederalStates.BY,
  '93453': FederalStates.BY,
  '93455': FederalStates.BY,
  '93458': FederalStates.BY,
  '93462': FederalStates.BY,
  '93464': FederalStates.BY,
  '93466': FederalStates.BY,
  '93468': FederalStates.BY,
  '93470': FederalStates.BY,
  '93471': FederalStates.BY,
  '93473': FederalStates.BY,
  '93474': FederalStates.BY,
  '93476': FederalStates.BY,
  '93477': FederalStates.BY,
  '93479': FederalStates.BY,
  '93480': FederalStates.BY,
  '93482': FederalStates.BY,
  '93483': FederalStates.BY,
  '93485': FederalStates.BY,
  '93486': FederalStates.BY,
  '93488': FederalStates.BY,
  '93489': FederalStates.BY,
  '93491': FederalStates.BY,
  '93492': FederalStates.BY,
  '93494': FederalStates.BY,
  '93495': FederalStates.BY,
  '93497': FederalStates.BY,
  '93499': FederalStates.BY,
  '94032': FederalStates.BY,
  '94034': FederalStates.BY,
  '94036': FederalStates.BY,
  '94051': FederalStates.BY,
  '94060': FederalStates.BY,
  '94065': FederalStates.BY,
  '94072': FederalStates.BY,
  '94078': FederalStates.BY,
  '94081': FederalStates.BY,
  '94086': FederalStates.BY,
  '94089': FederalStates.BY,
  '94094': FederalStates.BY,
  '94099': FederalStates.BY,
  '94104': FederalStates.BY,
  '94107': FederalStates.BY,
  '94110': FederalStates.BY,
  '94113': FederalStates.BY,
  '94116': FederalStates.BY,
  '94118': FederalStates.BY,
  '94121': FederalStates.BY,
  '94124': FederalStates.BY,
  '94127': FederalStates.BY,
  '94130': FederalStates.BY,
  '94133': FederalStates.BY,
  '94136': FederalStates.BY,
  '94137': FederalStates.BY,
  '94139': FederalStates.BY,
  '94140': FederalStates.BY,
  '94142': FederalStates.BY,
  '94143': FederalStates.BY,
  '94145': FederalStates.BY,
  '94146': FederalStates.BY,
  '94148': FederalStates.BY,
  '94149': FederalStates.BY,
  '94151': FederalStates.BY,
  '94152': FederalStates.BY,
  '94154': FederalStates.BY,
  '94155': FederalStates.BY,
  '94157': FederalStates.BY,
  '94158': FederalStates.BY,
  '94160': FederalStates.BY,
  '94161': FederalStates.BY,
  '94163': FederalStates.BY,
  '94164': FederalStates.BY,
  '94166': FederalStates.BY,
  '94167': FederalStates.BY,
  '94169': FederalStates.BY,
  '94209': FederalStates.BY,
  '94227': FederalStates.BY,
  '94234': FederalStates.BY,
  '94239': FederalStates.BY,
  '94244': FederalStates.BY,
  '94249': FederalStates.BY,
  '94250': FederalStates.BY,
  '94252': FederalStates.BY,
  '94253': FederalStates.BY,
  '94255': FederalStates.BY,
  '94256': FederalStates.BY,
  '94258': FederalStates.BY,
  '94259': FederalStates.BY,
  '94261': FederalStates.BY,
  '94262': FederalStates.BY,
  '94264': FederalStates.BY,
  '94265': FederalStates.BY,
  '94267': FederalStates.BY,
  '94269': FederalStates.BY,
  '94315': FederalStates.BY,
  '94327': FederalStates.BY,
  '94330': FederalStates.BY,
  '94333': FederalStates.BY,
  '94336': FederalStates.BY,
  '94339': FederalStates.BY,
  '94342': FederalStates.BY,
  '94344': FederalStates.BY,
  '94345': FederalStates.BY,
  '94347': FederalStates.BY,
  '94348': FederalStates.BY,
  '94350': FederalStates.BY,
  '94351': FederalStates.BY,
  '94353': FederalStates.BY,
  '94354': FederalStates.BY,
  '94356': FederalStates.BY,
  '94357': FederalStates.BY,
  '94359': FederalStates.BY,
  '94360': FederalStates.BY,
  '94362': FederalStates.BY,
  '94363': FederalStates.BY,
  '94365': FederalStates.BY,
  '94366': FederalStates.BY,
  '94368': FederalStates.BY,
  '94369': FederalStates.BY,
  '94371': FederalStates.BY,
  '94372': FederalStates.BY,
  '94374': FederalStates.BY,
  '94375': FederalStates.BY,
  '94377': FederalStates.BY,
  '94379': FederalStates.BY,
  '94405': FederalStates.BY,
  '94419': FederalStates.BY,
  '94424': FederalStates.BY,
  '94428': FederalStates.BY,
  '94431': FederalStates.BY,
  '94436': FederalStates.BY,
  '94437': FederalStates.BY,
  '94439': FederalStates.BY,
  '94447': FederalStates.BY,
  '94469': FederalStates.BY,
  '94474': FederalStates.BY,
  '94481': FederalStates.BY,
  '94486': FederalStates.BY,
  '94491': FederalStates.BY,
  '94496': FederalStates.BY,
  '94501': FederalStates.BY,
  '94505': FederalStates.BY,
  '94508': FederalStates.BY,
  '94513': FederalStates.BY,
  '94518': FederalStates.BY,
  '94522': FederalStates.BY,
  '94526': FederalStates.BY,
  '94527': FederalStates.BY,
  '94529': FederalStates.BY,
  '94530': FederalStates.BY,
  '94532': FederalStates.BY,
  '94533': FederalStates.BY,
  '94535': FederalStates.BY,
  '94536': FederalStates.BY,
  '94538': FederalStates.BY,
  '94539': FederalStates.BY,
  '94541': FederalStates.BY,
  '94542': FederalStates.BY,
  '94544': FederalStates.BY,
  '94545': FederalStates.BY,
  '94547': FederalStates.BY,
  '94548': FederalStates.BY,
  '94550': FederalStates.BY,
  '94551': FederalStates.BY,
  '94553': FederalStates.BY,
  '94554': FederalStates.BY,
  '94556': FederalStates.BY,
  '94557': FederalStates.BY,
  '94559': FederalStates.BY,
  '94560': FederalStates.BY,
  '94562': FederalStates.BY,
  '94563': FederalStates.BY,
  '94565': FederalStates.BY,
  '94566': FederalStates.BY,
  '94568': FederalStates.BY,
  '94569': FederalStates.BY,
  '94571': FederalStates.BY,
  '94572': FederalStates.BY,
  '94574': FederalStates.BY,
  '94575': FederalStates.BY,
  '94577': FederalStates.BY,
  '94579': FederalStates.BY,
  '95028': FederalStates.BY,
  '95030': FederalStates.BY,
  '95032': FederalStates.BY,
  '95100': FederalStates.BY,
  '95111': FederalStates.BY,
  '95119': FederalStates.BY,
  '95126': FederalStates.BY,
  '95131': FederalStates.BY,
  '95138': FederalStates.BY,
  '95145': FederalStates.BY,
  '95152': FederalStates.BY,
  '95158': FederalStates.BY,
  '95163': FederalStates.BY,
  '95168': FederalStates.BY,
  '95173': FederalStates.BY,
  '95176': FederalStates.BY,
  '95179': FederalStates.BY,
  '95180': FederalStates.BY,
  '95182': FederalStates.BY,
  '95183': FederalStates.BY,
  '95185': FederalStates.BY,
  '95186': FederalStates.BY,
  '95188': FederalStates.BY,
  '95189': FederalStates.BY,
  '95191': FederalStates.BY,
  '95192': FederalStates.BY,
  '95194': FederalStates.BY,
  '95195': FederalStates.BY,
  '95197': FederalStates.BY,
  '95199': FederalStates.BY,
  '95213': FederalStates.BY,
  '95233': FederalStates.BY,
  '95234': FederalStates.BY,
  '95236': FederalStates.BY,
  '95237': FederalStates.BY,
  '95239': FederalStates.BY,
  '95326': FederalStates.BY,
  '95336': FederalStates.BY,
  '95339': FederalStates.BY,
  '95346': FederalStates.BY,
  '95349': FederalStates.BY,
  '95352': FederalStates.BY,
  '95355': FederalStates.BY,
  '95356': FederalStates.BY,
  '95358': FederalStates.BY,
  '95359': FederalStates.BY,
  '95361': FederalStates.BY,
  '95362': FederalStates.BY,
  '95364': FederalStates.BY,
  '95365': FederalStates.BY,
  '95367': FederalStates.BY,
  '95369': FederalStates.BY,
  '95444': FederalStates.BY,
  '95445': FederalStates.BY,
  '95447': FederalStates.BY,
  '95448': FederalStates.BY,
  '95460': FederalStates.BY,
  '95463': FederalStates.BY,
  '95466': FederalStates.BY,
  '95469': FederalStates.BY,
  '95473': FederalStates.BY,
  '95478': FederalStates.BY,
  '95482': FederalStates.BY,
  '95485': FederalStates.BY,
  '95488': FederalStates.BY,
  '95490': FederalStates.BY,
  '95491': FederalStates.BY,
  '95493': FederalStates.BY,
  '95494': FederalStates.BY,
  '95496': FederalStates.BY,
  '95497': FederalStates.BY,
  '95499': FederalStates.BY,
  '95500': FederalStates.BY,
  '95502': FederalStates.BY,
  '95503': FederalStates.BY,
  '95505': FederalStates.BY,
  '95506': FederalStates.BY,
  '95508': FederalStates.BY,
  '95509': FederalStates.BY,
  '95511': FederalStates.BY,
  '95512': FederalStates.BY,
  '95514': FederalStates.BY,
  '95515': FederalStates.BY,
  '95517': FederalStates.BY,
  '95519': FederalStates.BY,
  '95615': FederalStates.BY,
  '95632': FederalStates.BY,
  '95643': FederalStates.BY,
  '95652': FederalStates.BY,
  '95659': FederalStates.BY,
  '95666': FederalStates.BY,
  '95671': FederalStates.BY,
  '95676': FederalStates.BY,
  '95679': FederalStates.BY,
  '95680': FederalStates.BY,
  '95682': FederalStates.BY,
  '95683': FederalStates.BY,
  '95685': FederalStates.BY,
  '95686': FederalStates.BY,
  '95688': FederalStates.BY,
  '95689': FederalStates.BY,
  '95691': FederalStates.BY,
  '95692': FederalStates.BY,
  '95694': FederalStates.BY,
  '95695': FederalStates.BY,
  '95697': FederalStates.BY,
  '95698': FederalStates.BY,
  '95700': FederalStates.BY,
  '95701': FederalStates.BY,
  '95703': FederalStates.BY,
  '95704': FederalStates.BY,
  '95706': FederalStates.BY,
  '95707': FederalStates.BY,
  '95709': FederalStates.BY,
  '96047': FederalStates.BY,
  '96049': FederalStates.BY,
  '96050': FederalStates.BY,
  '96052': FederalStates.BY,
  '96103': FederalStates.BY,
  '96106': FederalStates.BY,
  '96110': FederalStates.BY,
  '96114': FederalStates.BY,
  '96117': FederalStates.BY,
  '96120': FederalStates.BY,
  '96123': FederalStates.BY,
  '96126': FederalStates.BY,
  '96129': FederalStates.BY,
  '96132': FederalStates.BY,
  '96135': FederalStates.BY,
  '96138': FederalStates.BY,
  '96142': FederalStates.BY,
  '96145': FederalStates.BY,
  '96146': FederalStates.BY,
  '96148': FederalStates.BY,
  '96149': FederalStates.BY,
  '96151': FederalStates.BY,
  '96152': FederalStates.BY,
  '96154': FederalStates.BY,
  '96155': FederalStates.BY,
  '96157': FederalStates.BY,
  '96158': FederalStates.BY,
  '96160': FederalStates.BY,
  '96161': FederalStates.BY,
  '96163': FederalStates.BY,
  '96164': FederalStates.BY,
  '96166': FederalStates.BY,
  '96167': FederalStates.BY,
  '96169': FederalStates.BY,
  '96170': FederalStates.BY,
  '96172': FederalStates.BY,
  '96173': FederalStates.BY,
  '96175': FederalStates.BY,
  '96176': FederalStates.BY,
  '96178': FederalStates.BY,
  '96179': FederalStates.BY,
  '96181': FederalStates.BY,
  '96182': FederalStates.BY,
  '96184': FederalStates.BY,
  '96185': FederalStates.BY,
  '96187': FederalStates.BY,
  '96188': FederalStates.BY,
  '96190': FederalStates.BY,
  '96191': FederalStates.BY,
  '96193': FederalStates.BY,
  '96194': FederalStates.BY,
  '96196': FederalStates.BY,
  '96197': FederalStates.BY,
  '96199': FederalStates.BY,
  '96215': FederalStates.BY,
  '96224': FederalStates.BY,
  '96231': FederalStates.BY,
  '96237': FederalStates.BY,
  '96242': FederalStates.BY,
  '96247': FederalStates.BY,
  '96250': FederalStates.BY,
  '96253': FederalStates.BY,
  '96257': FederalStates.BY,
  '96260': FederalStates.BY,
  '96264': FederalStates.BY,
  '96268': FederalStates.BY,
  '96269': FederalStates.BY,
  '96271': FederalStates.BY,
  '96272': FederalStates.BY,
  '96274': FederalStates.BY,
  '96275': FederalStates.BY,
  '96277': FederalStates.BY,
  '96279': FederalStates.BY,
  '96317': FederalStates.BY,
  '96328': FederalStates.BY,
  '96332': FederalStates.BY,
  '96337': FederalStates.BY,
  '96342': FederalStates.BY,
  '96346': FederalStates.BY,
  '96349': FederalStates.BY,
  '96352': FederalStates.BY,
  '96355': FederalStates.BY,
  '96358': FederalStates.BY,
  '96361': FederalStates.BY,
  '96364': FederalStates.BY,
  '96365': FederalStates.BY,
  '96367': FederalStates.BY,
  '96369': FederalStates.BY,
  '96450': FederalStates.BY,
  '96465': FederalStates.BY,
  '96472': FederalStates.BY,
  '96476': FederalStates.BY,
  '96479': FederalStates.BY,
  '96482': FederalStates.BY,
  '96484': FederalStates.BY,
  '96486': FederalStates.BY,
  '96487': FederalStates.BY,
  '96489': FederalStates.BY,
  '96515': FederalStates.TH,
  '96523': FederalStates.TH,
  '96524': FederalStates.TH,
  '96528': FederalStates.TH,
  '97070': FederalStates.BY,
  '97072': FederalStates.BY,
  '97074': FederalStates.BY,
  '97076': FederalStates.BY,
  '97078': FederalStates.BY,
  '97080': FederalStates.BY,
  '97082': FederalStates.BY,
  '97084': FederalStates.BY,
  '97199': FederalStates.BY,
  '97204': FederalStates.BY,
  '97209': FederalStates.BY,
  '97215': FederalStates.BY,
  '97218': FederalStates.BY,
  '97222': FederalStates.BY,
  '97225': FederalStates.BY,
  '97228': FederalStates.BY,
  '97230': FederalStates.BY,
  '97232': FederalStates.BY,
  '97234': FederalStates.BY,
  '97236': FederalStates.BY,
  '97237': FederalStates.BY,
  '97239': FederalStates.BY,
  '97241': FederalStates.BY,
  '97243': FederalStates.BY,
  '97244': FederalStates.BY,
  '97246': FederalStates.BY,
  '97247': FederalStates.BY,
  '97249': FederalStates.BY,
  '97250': FederalStates.BY,
  '97252': FederalStates.BY,
  '97253': FederalStates.BY,
  '97255': FederalStates.BY,
  '97256': FederalStates.BY,
  '97258': FederalStates.BY,
  '97259': FederalStates.BY,
  '97261': FederalStates.BY,
  '97262': FederalStates.BY,
  '97264': FederalStates.BY,
  '97265': FederalStates.BY,
  '97267': FederalStates.BY,
  '97268': FederalStates.BY,
  '97270': FederalStates.BY,
  '97271': FederalStates.BY,
  '97273': FederalStates.BY,
  '97274': FederalStates.BY,
  '97276': FederalStates.BY,
  '97277': FederalStates.BY,
  '97279': FederalStates.BY,
  '97280': FederalStates.BY,
  '97282': FederalStates.BY,
  '97283': FederalStates.BY,
  '97285': FederalStates.BY,
  '97286': FederalStates.BY,
  '97288': FederalStates.BY,
  '97289': FederalStates.BY,
  '97291': FederalStates.BY,
  '97292': FederalStates.BY,
  '97294': FederalStates.BY,
  '97295': FederalStates.BY,
  '97297': FederalStates.BY,
  '97299': FederalStates.BY,
  '97318': FederalStates.BY,
  '97320': FederalStates.BY,
  '97332': FederalStates.BY,
  '97334': FederalStates.BY,
  '97337': FederalStates.BY,
  '97340': FederalStates.BY,
  '97342': FederalStates.BY,
  '97346': FederalStates.BY,
  '97348': FederalStates.BY,
  '97350': FederalStates.BY,
  '97353': FederalStates.BY,
  '97355': FederalStates.BY,
  '97357': FederalStates.BY,
  '97359': FederalStates.BY,
  '97412': FederalStates.BY,
  '97421': FederalStates.BY,
  '97422': FederalStates.BY,
  '97424': FederalStates.BY,
  '97437': FederalStates.BY,
  '97440': FederalStates.BY,
  '97447': FederalStates.BY,
  '97450': FederalStates.BY,
  '97453': FederalStates.BY,
  '97456': FederalStates.BY,
  '97461': FederalStates.BY,
  '97464': FederalStates.BY,
  '97469': FederalStates.BY,
  '97475': FederalStates.BY,
  '97478': FederalStates.BY,
  '97483': FederalStates.BY,
  '97486': FederalStates.BY,
  '97488': FederalStates.BY,
  '97490': FederalStates.BY,
  '97491': FederalStates.BY,
  '97493': FederalStates.BY,
  '97494': FederalStates.BY,
  '97496': FederalStates.BY,
  '97497': FederalStates.BY,
  '97499': FederalStates.BY,
  '97500': FederalStates.BY,
  '97502': FederalStates.BY,
  '97503': FederalStates.BY,
  '97505': FederalStates.BY,
  '97506': FederalStates.BY,
  '97508': FederalStates.BY,
  '97509': FederalStates.BY,
  '97511': FederalStates.BY,
  '97513': FederalStates.BY,
  '97514': FederalStates.BY,
  '97516': FederalStates.BY,
  '97517': FederalStates.BY,
  '97519': FederalStates.BY,
  '97520': FederalStates.BY,
  '97522': FederalStates.BY,
  '97523': FederalStates.BY,
  '97525': FederalStates.BY,
  '97526': FederalStates.BY,
  '97528': FederalStates.BY,
  '97529': FederalStates.BY,
  '97531': FederalStates.BY,
  '97532': FederalStates.BY,
  '97534': FederalStates.BY,
  '97535': FederalStates.BY,
  '97537': FederalStates.BY,
  '97539': FederalStates.BY,
  '97616': FederalStates.BY,
  '97618': FederalStates.BY,
  '97631': FederalStates.BY,
  '97633': FederalStates.BY,
  '97638': FederalStates.BY,
  '97640': FederalStates.BY,
  '97645': FederalStates.BY,
  '97647': FederalStates.BY,
  '97650': FederalStates.BY,
  '97653': FederalStates.BY,
  '97654': FederalStates.BY,
  '97656': FederalStates.BY,
  '97657': FederalStates.BY,
  '97659': FederalStates.BY,
  '97688': FederalStates.BY,
  '97702': FederalStates.BY,
  '97705': FederalStates.BY,
  '97708': FederalStates.BY,
  '97711': FederalStates.BY,
  '97714': FederalStates.BY,
  '97717': FederalStates.BY,
  '97720': FederalStates.BY,
  '97723': FederalStates.BY,
  '97724': FederalStates.BY,
  '97725': FederalStates.BY,
  '97727': FederalStates.BY,
  '97729': FederalStates.BY,
  '97737': FederalStates.BY,
  '97753': FederalStates.BY,
  '97762': FederalStates.BY,
  '97769': FederalStates.BY,
  '97772': FederalStates.BY,
  '97773': FederalStates.BY,
  '97775': FederalStates.BY,
  '97776': FederalStates.BY,
  '97778': FederalStates.BY,
  '97779': FederalStates.BY,
  '97780': FederalStates.BY,
  '97782': FederalStates.BY,
  '97783': FederalStates.BY,
  '97785': FederalStates.BY,
  '97786': FederalStates.BY,
  '97788': FederalStates.BY,
  '97789': FederalStates.BY,
  '97791': FederalStates.BY,
  '97792': FederalStates.BY,
  '97794': FederalStates.BY,
  '97795': FederalStates.BY,
  '97797': FederalStates.BY,
  '97799': FederalStates.BY,
  '97816': FederalStates.BY,
  '97828': FederalStates.BY,
  '97833': FederalStates.BY,
  '97834': FederalStates.BY,
  '97836': FederalStates.BY,
  '97837': FederalStates.BY,
  '97839': FederalStates.BY,
  '97840': FederalStates.BY,
  '97842': FederalStates.BY,
  '97843': FederalStates.BY,
  '97845': FederalStates.BY,
  '97846': FederalStates.BY,
  '97848': FederalStates.BY,
  '97849': FederalStates.BY,
  '97851': FederalStates.BY,
  '97852': FederalStates.BY,
  '97854': FederalStates.BY,
  '97855': FederalStates.BY,
  '97857': FederalStates.BY,
  '97859': FederalStates.BY,
  '97877': FederalStates.BW,
  '97892': FederalStates.BY,
  '97896': FederalStates.BW,
  '97900': FederalStates.BW,
  '97901': FederalStates.BY,
  '97903': FederalStates.BY,
  '97904': FederalStates.BY,
  '97906': FederalStates.BY,
  '97907': FederalStates.BY,
  '97909': FederalStates.BY,
  '97922': FederalStates.BW,
  '97941': FederalStates.BW,
  '97944': FederalStates.BW,
  '97947': FederalStates.BW,
  '97950': FederalStates.BW,
  '97953': FederalStates.BW,
  '97956': FederalStates.BW,
  '97957': FederalStates.BW,
  '97959': FederalStates.BW,
  '97980': FederalStates.BW,
  '97990': FederalStates.BW,
  '97993': FederalStates.BW,
  '97996': FederalStates.BW,
  '97999': FederalStates.BW,
  '98527': FederalStates.TH,
  '98528': FederalStates.TH,
  '98529': FederalStates.TH,
  '98530': FederalStates.TH,
  '98544': FederalStates.TH,
  '98547': FederalStates.TH,
  '98553': FederalStates.TH,
  '98554': FederalStates.TH,
  '98559': FederalStates.TH,
  '98574': FederalStates.TH,
  '98587': FederalStates.TH,
  '98590': FederalStates.TH,
  '98593': FederalStates.TH,
  '98596': FederalStates.TH,
  '98597': FederalStates.TH,
  '98599': FederalStates.TH,
  '98617': FederalStates.TH,
  '98630': FederalStates.TH,
  '98631': FederalStates.TH,
  '98634': FederalStates.TH,
  '98639': FederalStates.TH,
  '98646': FederalStates.TH,
  '98660': FederalStates.TH,
  '98663': FederalStates.TH,
  '98666': FederalStates.TH,
  '98667': FederalStates.TH,
  '98669': FederalStates.TH,
  '98673': FederalStates.TH,
  '98678': FederalStates.TH,
  '98693': FederalStates.TH,
  '98701': FederalStates.TH,
  '98704': FederalStates.TH,
  '98708': FederalStates.TH,
  '98711': FederalStates.TH,
  '98714': FederalStates.TH,
  '98716': FederalStates.TH,
  '98724': FederalStates.TH,
  '98739': FederalStates.TH,
  '98743': FederalStates.TH,
  '98744': FederalStates.TH,
  '98746': FederalStates.TH,
  '98749': FederalStates.TH,
  '99084': FederalStates.TH,
  '99085': FederalStates.TH,
  '99086': FederalStates.TH,
  '99087': FederalStates.TH,
  '99089': FederalStates.TH,
  '99091': FederalStates.TH,
  '99092': FederalStates.TH,
  '99094': FederalStates.TH,
  '99096': FederalStates.TH,
  '99097': FederalStates.TH,
  '99098': FederalStates.TH,
  '99099': FederalStates.TH,
  '99100': FederalStates.TH,
  '99102': FederalStates.TH,
  '99130': FederalStates.TH,
  '99189': FederalStates.TH,
  '99192': FederalStates.TH,
  '99195': FederalStates.TH,
  '99198': FederalStates.TH,
  '99310': FederalStates.TH,
  '99326': FederalStates.TH,
  '99330': FederalStates.TH,
  '99334': FederalStates.TH,
  '99338': FederalStates.TH,
  '99423': FederalStates.TH,
  '99425': FederalStates.TH,
  '99427': FederalStates.TH,
  '99428': FederalStates.TH,
  '99438': FederalStates.TH,
  '99439': FederalStates.TH,
  '99441': FederalStates.TH,
  '99444': FederalStates.TH,
  '99448': FederalStates.TH,
  '99510': FederalStates.TH,
  '99518': FederalStates.TH,
  '99610': FederalStates.TH,
  '99625': FederalStates.TH,
  '99628': FederalStates.TH,
  '99631': FederalStates.TH,
  '99634': FederalStates.TH,
  '99636': FederalStates.TH,
  '99638': FederalStates.TH,
  '99706': FederalStates.TH,
  '99707': FederalStates.TH,
  '99713': FederalStates.TH,
  '99718': FederalStates.TH,
  '99734': FederalStates.TH,
  '99735': FederalStates.TH,
  '99752': FederalStates.TH,
  '99755': FederalStates.TH,
  '99759': FederalStates.TH,
  '99762': FederalStates.TH,
  '99765': FederalStates.TH,
  '99768': FederalStates.TH,
  '99817': FederalStates.TH,
  '99819': FederalStates.TH,
  '99820': FederalStates.TH,
  '99826': FederalStates.TH,
  '99830': FederalStates.TH,
  '99831': FederalStates.TH,
  '99834': FederalStates.TH,
  '99837': FederalStates.TH,
  '99842': FederalStates.TH,
  '99846': FederalStates.TH,
  '99848': FederalStates.TH,
  '99867': FederalStates.TH,
  '99869': FederalStates.TH,
  '99880': FederalStates.TH,
  '99885': FederalStates.TH,
  '99887': FederalStates.TH,
  '99891': FederalStates.TH,
  '99894': FederalStates.TH,
  '99897': FederalStates.TH,
  '99947': FederalStates.TH,
  '99955': FederalStates.TH,
  '99958': FederalStates.TH,
  '99974': FederalStates.TH,
  '99976': FederalStates.TH,
  '99986': FederalStates.TH,
  '99988': FederalStates.TH,
  '99991': FederalStates.TH,
  '99994': FederalStates.TH,
  '99996': FederalStates.TH,
  '99998': FederalStates.TH,
  '01945': FederalStates.BB,
  '01968': FederalStates.BB,
  '01979': FederalStates.BB,
  '01983': FederalStates.BB,
  '01987': FederalStates.BB,
  '01990': FederalStates.BB,
  '01993': FederalStates.BB,
  '01994': FederalStates.BB,
  '01996': FederalStates.BB,
  '01998': FederalStates.BB,
  '03042': FederalStates.BB,
  '03044': FederalStates.BB,
  '03046': FederalStates.BB,
  '03048': FederalStates.BB,
  '03050': FederalStates.BB,
  '03051': FederalStates.BB,
  '03052': FederalStates.BB,
  '03053': FederalStates.BB,
  '03054': FederalStates.BB,
  '03055': FederalStates.BB,
  '03058': FederalStates.BB,
  '03096': FederalStates.BB,
  '03099': FederalStates.BB,
  '03103': FederalStates.BB,
  '03116': FederalStates.BB,
  '03119': FederalStates.BB,
  '03130': FederalStates.BB,
  '03139': FederalStates.BB,
  '03149': FederalStates.BB,
  '03159': FederalStates.BB,
  '03172': FederalStates.BB,
  '03185': FederalStates.BB,
  '03197': FederalStates.BB,
  '03202': FederalStates.BB,
  '03205': FederalStates.BB,
  '03222': FederalStates.BB,
  '03226': FederalStates.BB,
  '03229': FederalStates.BB,
  '03238': FederalStates.BB,
  '03246': FederalStates.BB,
  '03249': FederalStates.BB,
  '03253': FederalStates.BB,
  '04895': FederalStates.BB,
  '04910': FederalStates.BB,
  '04916': FederalStates.BB,
  '04924': FederalStates.BB,
  '04928': FederalStates.BB,
  '04931': FederalStates.BB,
  '04932': FederalStates.BB,
  '04934': FederalStates.BB,
  '04936': FederalStates.BB,
  '04938': FederalStates.BB,
  '01067': FederalStates.SN,
  '01069': FederalStates.SN,
  '01097': FederalStates.SN,
  '01099': FederalStates.SN,
  '01108': FederalStates.SN,
  '01109': FederalStates.SN,
  '01127': FederalStates.SN,
  '01129': FederalStates.SN,
  '01139': FederalStates.SN,
  '01156': FederalStates.SN,
  '01157': FederalStates.SN,
  '01159': FederalStates.SN,
  '01169': FederalStates.SN,
  '01187': FederalStates.SN,
  '01189': FederalStates.SN,
  '01217': FederalStates.SN,
  '01219': FederalStates.SN,
  '01237': FederalStates.SN,
  '01239': FederalStates.SN,
  '01257': FederalStates.SN,
  '01259': FederalStates.SN,
  '01277': FederalStates.SN,
  '01279': FederalStates.SN,
  '01307': FederalStates.SN,
  '01309': FederalStates.SN,
  '01324': FederalStates.SN,
  '01326': FederalStates.SN,
  '01328': FederalStates.SN,
  '01445': FederalStates.SN,
  '01454': FederalStates.SN,
  '01458': FederalStates.SN,
  '01465': FederalStates.SN,
  '01468': FederalStates.SN,
  '01471': FederalStates.SN,
  '01477': FederalStates.SN,
  '01558': FederalStates.SN,
  '01561': FederalStates.SN,
  '01587': FederalStates.SN,
  '01589': FederalStates.SN,
  '01591': FederalStates.SN,
  '01594': FederalStates.SN,
  '01609': FederalStates.SN,
  '01612': FederalStates.SN,
  '01616': FederalStates.SN,
  '01619': FederalStates.SN,
  '01623': FederalStates.SN,
  '01640': FederalStates.SN,
  '01662': FederalStates.SN,
  '01665': FederalStates.SN,
  '01683': FederalStates.SN,
  '01689': FederalStates.SN,
  '01705': FederalStates.SN,
  '01723': FederalStates.SN,
  '01728': FederalStates.SN,
  '01731': FederalStates.SN,
  '01734': FederalStates.SN,
  '01737': FederalStates.SN,
  '01738': FederalStates.SN,
  '01744': FederalStates.SN,
  '01762': FederalStates.SN,
  '01768': FederalStates.SN,
  '01773': FederalStates.SN,
  '01774': FederalStates.SN,
  '01776': FederalStates.SN,
  '01778': FederalStates.SN,
  '01796': FederalStates.SN,
  '01809': FederalStates.SN,
  '01814': FederalStates.SN,
  '01816': FederalStates.SN,
  '01819': FederalStates.SN,
  '01824': FederalStates.SN,
  '01825': FederalStates.SN,
  '01829': FederalStates.SN,
  '01833': FederalStates.SN,
  '01844': FederalStates.SN,
  '01847': FederalStates.SN,
  '01848': FederalStates.SN,
  '01855': FederalStates.SN,
  '01877': FederalStates.SN,
  '01896': FederalStates.SN,
  '01900': FederalStates.SN,
  '01904': FederalStates.SN,
  '01906': FederalStates.SN,
  '01909': FederalStates.SN,
  '01917': FederalStates.SN,
  '01920': FederalStates.SN,
  '01936': FederalStates.SN,
  '02625': FederalStates.SN,
  '02627': FederalStates.SN,
  '02633': FederalStates.SN,
  '02681': FederalStates.SN,
  '02689': FederalStates.SN,
  '02692': FederalStates.SN,
  '02694': FederalStates.SN,
  '02699': FederalStates.SN,
  '02708': FederalStates.SN,
  '02727': FederalStates.SN,
  '02730': FederalStates.SN,
  '02733': FederalStates.SN,
  '02736': FederalStates.SN,
  '02739': FederalStates.SN,
  '02742': FederalStates.SN,
  '02747': FederalStates.SN,
  '02748': FederalStates.SN,
  '02763': FederalStates.SN,
  '02779': FederalStates.SN,
  '02782': FederalStates.SN,
  '02785': FederalStates.SN,
  '02788': FederalStates.SN,
  '02791': FederalStates.SN,
  '02794': FederalStates.SN,
  '02796': FederalStates.SN,
  '02797': FederalStates.SN,
  '02799': FederalStates.SN,
  '02826': FederalStates.SN,
  '02827': FederalStates.SN,
  '02828': FederalStates.SN,
  '02829': FederalStates.SN,
  '02894': FederalStates.SN,
  '02899': FederalStates.SN,
  '02906': FederalStates.SN,
  '02923': FederalStates.SN,
  '02929': FederalStates.SN,
  '02943': FederalStates.SN,
  '02953': FederalStates.SN,
  '02956': FederalStates.SN,
  '02957': FederalStates.SN,
  '02959': FederalStates.SN,
  '02977': FederalStates.SN,
  '02979': FederalStates.SN,
  '02991': FederalStates.SN,
  '02994': FederalStates.SN,
  '02997': FederalStates.SN,
  '02999': FederalStates.SN,
  '04103': FederalStates.SN,
  '04105': FederalStates.SN,
  '04107': FederalStates.SN,
  '04109': FederalStates.SN,
  '04129': FederalStates.SN,
  '04155': FederalStates.SN,
  '04157': FederalStates.SN,
  '04158': FederalStates.SN,
  '04159': FederalStates.SN,
  '04177': FederalStates.SN,
  '04178': FederalStates.SN,
  '04179': FederalStates.SN,
  '04205': FederalStates.SN,
  '04207': FederalStates.SN,
  '04209': FederalStates.SN,
  '04229': FederalStates.SN,
  '04249': FederalStates.SN,
  '04275': FederalStates.SN,
  '04277': FederalStates.SN,
  '04279': FederalStates.SN,
  '04288': FederalStates.SN,
  '04289': FederalStates.SN,
  '04299': FederalStates.SN,
  '04315': FederalStates.SN,
  '04316': FederalStates.SN,
  '04317': FederalStates.SN,
  '04318': FederalStates.SN,
  '04319': FederalStates.SN,
  '04328': FederalStates.SN,
  '04329': FederalStates.SN,
  '04347': FederalStates.SN,
  '04349': FederalStates.SN,
  '04356': FederalStates.SN,
  '04357': FederalStates.SN,
  '04416': FederalStates.SN,
  '04420': FederalStates.SN,
  '04425': FederalStates.SN,
  '04435': FederalStates.SN,
  '04442': FederalStates.SN,
  '04451': FederalStates.SN,
  '04460': FederalStates.SN,
  '04463': FederalStates.SN,
  '04509': FederalStates.SN,
  '04519': FederalStates.SN,
  '04523': FederalStates.SN,
  '04539': FederalStates.SN,
  '04552': FederalStates.SN,
  '04564': FederalStates.SN,
  '04565': FederalStates.SN,
  '04567': FederalStates.SN,
  '04571': FederalStates.SN,
  '04574': FederalStates.SN,
  '04575': FederalStates.SN,
  '04579': FederalStates.SN,
  '04643': FederalStates.SN,
  '04651': FederalStates.SN,
  '04654': FederalStates.SN,
  '04655': FederalStates.SN,
  '04657': FederalStates.SN,
  '04668': FederalStates.SN,
  '04680': FederalStates.SN,
  '04683': FederalStates.SN,
  '04685': FederalStates.SN,
  '04687': FederalStates.SN,
  '04688': FederalStates.SN,
  '04703': FederalStates.SN,
  '04720': FederalStates.SN,
  '04736': FederalStates.SN,
  '04741': FederalStates.SN,
  '04746': FederalStates.SN,
  '04749': FederalStates.SN,
  '04758': FederalStates.SN,
  '04769': FederalStates.SN,
  '04774': FederalStates.SN,
  '04779': FederalStates.SN,
  '04808': FederalStates.SN,
  '04821': FederalStates.SN,
  '04824': FederalStates.SN,
  '04827': FederalStates.SN,
  '04828': FederalStates.SN,
  '04838': FederalStates.SN,
  '04849': FederalStates.SN,
  '04860': FederalStates.SN,
  '04862': FederalStates.SN,
  '04874': FederalStates.SN,
  '04880': FederalStates.SN,
  '04886': FederalStates.SN,
  '04889': FederalStates.SN,
  '07919': FederalStates.TH,
  '07952': FederalStates.SN,
  '07985': FederalStates.SN,
  '08056': FederalStates.SN,
  '08058': FederalStates.SN,
  '08060': FederalStates.SN,
  '08062': FederalStates.SN,
  '08064': FederalStates.SN,
  '08066': FederalStates.SN,
  '08107': FederalStates.SN,
  '08112': FederalStates.SN,
  '08115': FederalStates.SN,
  '08118': FederalStates.SN,
  '08132': FederalStates.SN,
  '08134': FederalStates.SN,
  '08141': FederalStates.SN,
  '08144': FederalStates.SN,
  '08147': FederalStates.SN,
  '08209': FederalStates.SN,
  '08223': FederalStates.SN,
  '08228': FederalStates.SN,
  '08233': FederalStates.SN,
  '08236': FederalStates.SN,
  '08237': FederalStates.SN,
  '08239': FederalStates.SN,
  '08248': FederalStates.SN,
  '08258': FederalStates.SN,
  '08261': FederalStates.SN,
  '08262': FederalStates.SN,
  '08265': FederalStates.SN,
  '08267': FederalStates.SN,
  '08269': FederalStates.SN,
  '08280': FederalStates.SN,
  '08289': FederalStates.SN,
  '08294': FederalStates.SN,
  '08297': FederalStates.SN,
  '08301': FederalStates.SN,
  '08304': FederalStates.SN,
  '08309': FederalStates.SN,
  '08312': FederalStates.SN,
  '08315': FederalStates.SN,
  '08318': FederalStates.SN,
  '08321': FederalStates.SN,
  '08324': FederalStates.SN,
  '08325': FederalStates.SN,
  '08326': FederalStates.SN,
  '08328': FederalStates.SN,
  '08340': FederalStates.SN,
  '08344': FederalStates.SN,
  '08349': FederalStates.SN,
  '08352': FederalStates.SN,
  '08355': FederalStates.SN,
  '08359': FederalStates.SN,
  '08371': FederalStates.SN,
  '08373': FederalStates.SN,
  '08393': FederalStates.SN,
  '08396': FederalStates.SN,
  '08412': FederalStates.SN,
  '08427': FederalStates.SN,
  '08428': FederalStates.SN,
  '08432': FederalStates.SN,
  '08439': FederalStates.SN,
  '08451': FederalStates.SN,
  '08459': FederalStates.SN,
  '08468': FederalStates.SN,
  '08485': FederalStates.SN,
  '08491': FederalStates.SN,
  '08496': FederalStates.SN,
  '08499': FederalStates.SN,
  '08523': FederalStates.SN,
  '08525': FederalStates.SN,
  '08527': FederalStates.SN,
  '08529': FederalStates.SN,
  '08538': FederalStates.SN,
  '08539': FederalStates.SN,
  '08541': FederalStates.SN,
  '08543': FederalStates.SN,
  '08547': FederalStates.SN,
  '08548': FederalStates.SN,
  '08606': FederalStates.SN,
  '08626': FederalStates.SN,
  '08645': FederalStates.SN,
  '08648': FederalStates.SN,
  '09028': FederalStates.SN,
  '09030': FederalStates.SN,
  '09111': FederalStates.SN,
  '09112': FederalStates.SN,
  '09113': FederalStates.SN,
  '09114': FederalStates.SN,
  '09116': FederalStates.SN,
  '09117': FederalStates.SN,
  '09119': FederalStates.SN,
  '09120': FederalStates.SN,
  '09122': FederalStates.SN,
  '09123': FederalStates.SN,
  '09125': FederalStates.SN,
  '09126': FederalStates.SN,
  '09127': FederalStates.SN,
  '09128': FederalStates.SN,
  '09130': FederalStates.SN,
  '09131': FederalStates.SN,
  '09212': FederalStates.SN,
  '09217': FederalStates.SN,
  '09221': FederalStates.SN,
  '09224': FederalStates.SN,
  '09227': FederalStates.SN,
  '09228': FederalStates.SN,
  '09232': FederalStates.SN,
  '09235': FederalStates.SN,
  '09236': FederalStates.SN,
  '09241': FederalStates.SN,
  '09243': FederalStates.SN,
  '09244': FederalStates.SN,
  '09247': FederalStates.SN,
  '09249': FederalStates.SN,
  '09306': FederalStates.SN,
  '09322': FederalStates.SN,
  '09326': FederalStates.SN,
  '09328': FederalStates.SN,
  '09337': FederalStates.SN,
  '09350': FederalStates.SN,
  '09353': FederalStates.SN,
  '09355': FederalStates.SN,
  '09356': FederalStates.SN,
  '09366': FederalStates.SN,
  '09376': FederalStates.SN,
  '09380': FederalStates.SN,
  '09385': FederalStates.SN,
  '09387': FederalStates.SN,
  '09390': FederalStates.SN,
  '09392': FederalStates.SN,
  '09394': FederalStates.SN,
  '09395': FederalStates.SN,
  '09399': FederalStates.SN,
  '09405': FederalStates.SN,
  '09419': FederalStates.SN,
  '09423': FederalStates.SN,
  '09427': FederalStates.SN,
  '09429': FederalStates.SN,
  '09430': FederalStates.SN,
  '09432': FederalStates.SN,
  '09435': FederalStates.SN,
  '09437': FederalStates.SN,
  '09439': FederalStates.SN,
  '09456': FederalStates.SN,
  '09465': FederalStates.SN,
  '09468': FederalStates.SN,
  '09471': FederalStates.SN,
  '09474': FederalStates.SN,
  '09477': FederalStates.SN,
  '09481': FederalStates.SN,
  '09484': FederalStates.SN,
  '09487': FederalStates.SN,
  '09488': FederalStates.SN,
  '09496': FederalStates.SN,
  '09509': FederalStates.SN,
  '09514': FederalStates.SN,
  '09517': FederalStates.SN,
  '09518': FederalStates.SN,
  '09526': FederalStates.SN,
  '09544': FederalStates.SN,
  '09548': FederalStates.SN,
  '09557': FederalStates.SN,
  '09569': FederalStates.SN,
  '09573': FederalStates.SN,
  '09575': FederalStates.SN,
  '09577': FederalStates.SN,
  '09579': FederalStates.SN,
  '09599': FederalStates.SN,
  '09600': FederalStates.SN,
  '09603': FederalStates.SN,
  '09618': FederalStates.SN,
  '09619': FederalStates.SN,
  '09623': FederalStates.SN,
  '09627': FederalStates.SN,
  '09629': FederalStates.SN,
  '09633': FederalStates.SN,
  '09634': FederalStates.SN,
  '09638': FederalStates.SN,
  '09648': FederalStates.SN,
  '09661': FederalStates.SN,
  '09669': FederalStates.SN,
  '06108': FederalStates.ST,
  '06110': FederalStates.ST,
  '06112': FederalStates.ST,
  '06114': FederalStates.ST,
  '06116': FederalStates.ST,
  '06118': FederalStates.ST,
  '06120': FederalStates.ST,
  '06122': FederalStates.ST,
  '06124': FederalStates.ST,
  '06126': FederalStates.ST,
  '06128': FederalStates.ST,
  '06130': FederalStates.ST,
  '06132': FederalStates.ST,
  '06179': FederalStates.ST,
  '06184': FederalStates.ST,
  '06188': FederalStates.ST,
  '06193': FederalStates.ST,
  '06198': FederalStates.ST,
  '06217': FederalStates.ST,
  '06231': FederalStates.ST,
  '06237': FederalStates.ST,
  '06242': FederalStates.ST,
  '06246': FederalStates.ST,
  '06249': FederalStates.ST,
  '06254': FederalStates.ST,
  '06255': FederalStates.ST,
  '06258': FederalStates.ST,
  '06259': FederalStates.ST,
  '06268': FederalStates.ST,
  '06279': FederalStates.ST,
  '06295': FederalStates.ST,
  '06308': FederalStates.ST,
  '06311': FederalStates.ST,
  '06313': FederalStates.ST,
  '06317': FederalStates.ST,
  '06318': FederalStates.ST,
  '06333': FederalStates.ST,
  '06343': FederalStates.ST,
  '06347': FederalStates.ST,
  '06348': FederalStates.ST,
  '06366': FederalStates.ST,
  '06369': FederalStates.ST,
  '06385': FederalStates.ST,
  '06386': FederalStates.ST,
  '06388': FederalStates.ST,
  '06406': FederalStates.ST,
  '06408': FederalStates.ST,
  '06420': FederalStates.ST,
  '06425': FederalStates.ST,
  '06429': FederalStates.ST,
  '06449': FederalStates.ST,
  '06456': FederalStates.ST,
  '06458': FederalStates.ST,
  '06463': FederalStates.ST,
  '06464': FederalStates.ST,
  '06466': FederalStates.ST,
  '06467': FederalStates.ST,
  '06469': FederalStates.ST,
  '06484': FederalStates.ST,
  '06493': FederalStates.ST,
  '06502': FederalStates.ST,
  '06507': FederalStates.ST,
  '06526': FederalStates.ST,
  '06528': FederalStates.ST,
  '06536': FederalStates.ST,
  '06537': FederalStates.ST,
  '06542': FederalStates.ST,
  '06543': FederalStates.ST,
  '06547': FederalStates.ST,
  '06618': FederalStates.ST,
  '06628': FederalStates.ST,
  '06632': FederalStates.ST,
  '06636': FederalStates.ST,
  '06638': FederalStates.ST,
  '06642': FederalStates.ST,
  '06647': FederalStates.ST,
  '06648': FederalStates.ST,
  '06667': FederalStates.ST,
  '06679': FederalStates.ST,
  '06682': FederalStates.ST,
  '06686': FederalStates.ST,
  '06688': FederalStates.ST,
  '06712': FederalStates.ST,
  '06721': FederalStates.ST,
  '06722': FederalStates.ST,
  '06724': FederalStates.ST,
  '06725': FederalStates.ST,
  '06727': FederalStates.ST,
  '06729': FederalStates.ST,
  '06731': FederalStates.ST,
  '06749': FederalStates.ST,
  '06766': FederalStates.ST,
  '06773': FederalStates.ST,
  '06774': FederalStates.ST,
  '06779': FederalStates.ST,
  '06780': FederalStates.ST,
  '06785': FederalStates.ST,
  '06786': FederalStates.ST,
  '06791': FederalStates.ST,
  '06792': FederalStates.ST,
  '06794': FederalStates.ST,
  '06796': FederalStates.ST,
  '06800': FederalStates.ST,
  '06803': FederalStates.ST,
  '06804': FederalStates.ST,
  '06808': FederalStates.ST,
  '06809': FederalStates.ST,
  '06842': FederalStates.ST,
  '06844': FederalStates.ST,
  '06846': FederalStates.ST,
  '06847': FederalStates.ST,
  '06849': FederalStates.ST,
  '06861': FederalStates.ST,
  '06862': FederalStates.ST,
  '06868': FederalStates.ST,
  '06869': FederalStates.ST,
  '06886': FederalStates.ST,
  '06888': FederalStates.ST,
  '06889': FederalStates.ST,
  '06895': FederalStates.ST,
  '06896': FederalStates.ST,
  '06901': FederalStates.ST,
  '06905': FederalStates.ST,
  '06909': FederalStates.ST,
  '06917': FederalStates.ST,
  '06918': FederalStates.ST,
  '06922': FederalStates.ST,
  '06925': FederalStates.ST,
  '06926': FederalStates.ST,
  '06928': FederalStates.ST,
  '04600': FederalStates.TH,
  '04603': FederalStates.TH,
  '04610': FederalStates.TH,
  '04613': FederalStates.TH,
  '04617': FederalStates.TH,
  '04618': FederalStates.TH,
  '04626': FederalStates.TH,
  '04639': FederalStates.TH,
  '06556': FederalStates.TH,
  '06567': FederalStates.TH,
  '06571': FederalStates.TH,
  '06577': FederalStates.TH,
  '06578': FederalStates.TH,
  '07318': FederalStates.TH,
  '07330': FederalStates.TH,
  '07333': FederalStates.TH,
  '07334': FederalStates.TH,
  '07336': FederalStates.TH,
  '07338': FederalStates.TH,
  '07343': FederalStates.TH,
  '07349': FederalStates.TH,
  '07356': FederalStates.TH,
  '07366': FederalStates.TH,
  '07368': FederalStates.TH,
  '07381': FederalStates.TH,
  '07387': FederalStates.TH,
  '07389': FederalStates.TH,
  '07407': FederalStates.TH,
  '07422': FederalStates.TH,
  '07426': FederalStates.TH,
  '07427': FederalStates.TH,
  '07429': FederalStates.TH,
  '07545': FederalStates.TH,
  '07546': FederalStates.TH,
  '07548': FederalStates.TH,
  '07549': FederalStates.TH,
  '07551': FederalStates.TH,
  '07552': FederalStates.TH,
  '07554': FederalStates.TH,
  '07557': FederalStates.TH,
  '07570': FederalStates.TH,
  '07580': FederalStates.TH,
  '07586': FederalStates.TH,
  '07589': FederalStates.TH,
  '07607': FederalStates.TH,
  '07613': FederalStates.TH,
  '07616': FederalStates.TH,
  '07619': FederalStates.TH,
  '07629': FederalStates.TH,
  '07639': FederalStates.TH,
  '07646': FederalStates.TH,
  '07743': FederalStates.TH,
  '07745': FederalStates.TH,
  '07747': FederalStates.TH,
  '07749': FederalStates.TH,
  '07751': FederalStates.TH,
  '07768': FederalStates.TH,
  '07774': FederalStates.TH,
  '07778': FederalStates.TH,
  '07806': FederalStates.TH,
  '07819': FederalStates.TH,
  '07907': FederalStates.TH,
  '07922': FederalStates.TH,
  '07924': FederalStates.TH,
  '07926': FederalStates.TH,
  '07927': FederalStates.TH,
  '07929': FederalStates.TH,
  '07937': FederalStates.TH,
  '07950': FederalStates.TH,
  '07955': FederalStates.TH,
  '07957': FederalStates.TH,
  '07958': FederalStates.TH,
  '07973': FederalStates.TH,
  '07980': FederalStates.TH,
  '07987': FederalStates.TH,
  '07989': FederalStates.TH,
};
