export enum AuditLogEnum {
    ORDER_EXPIRE_EXTEND_REQUESTED = 'ORDER_EXPIRE_EXTEND_REQUESTED',
    ORDER_EXPIRE_EXTEND_REQUESTED_SENT = 'ORDER_EXPIRE_EXTEND_REQUESTED_SENT',
    ORDER_CANCELLATION_REQUESTED = 'ORDER_CANCELLATION_REQUESTED',
    ORDER_CANCELLATION_REQUESTED_SENT = 'ORDER_CANCELLATION_REQUESTED_SENT',
    REORDER_REQUESTED = 'REORDER_REQUESTED',
    REORDER_REQUESTED_SENT = 'REORDER_REQUESTED_SENT',
    ORDER_IS_CHANGED = 'ORDER_IS_CHANGED',
    ORDER_IS_CANCELLED = 'ORDER_IS_CANCELLED',
    ORDER_IS_CONFIRMED = 'ORDER_IS_CONFIRMED',
    ORDER_CANCEL_ERROR = 'ORDER_CANCEL_ERROR',
    ORDER_CANCEL_SUCCESS = 'ORDER_CANCEL_SUCCESS',
    ORDER_EXTEND_ERROR = 'ORDER_EXTEND_ERROR',
    ORDER_EXTEND_SUCCESS = 'ORDER_EXTEND_SUCCESS',
    REORDER_SUCCESS = 'REORDER_SUCCESS'
}

