import { gql } from '@apollo/client/core';
import { inject, Injectable, Signal } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { from, map } from 'rxjs';
import * as moment from 'moment';
import { ApiService } from '../../../services/api.service';
import { formatMomentDateToDefaultDateFormat } from '../../../formatting/date.formatting';
import { OrderFiltersInterface } from '../../../interfaces/order.interface';
import { DateRangeOptionCodes, DataChangedKeys as DCK } from '../../../core.enums';
import { DataChangedForceStateVar } from '../../locals/dataChangeForceState.var';
import { ProfileSettingsVar } from '../../locals/profileSettings.var';
import { ProfileSettingInterface } from '../../../core.interfaces';


export const CancelOrderMutation = gql`
    mutation cancelOrderMutation($orderId: ID!, $reason: String!) {
        CancelOrder(orderId: $orderId, reason: $reason) {
            status
            message
        }
    }
`;

export const DispoReOrderMutation = gql`
    mutation dispoReOrderMutation($orderId: ID!, $qty: Int!, $latestExpected: Date!) {
        DispoReOrder(orderId: $orderId, qty: $qty, latestExpected: $latestExpected) {
            status
            message
        }
    }
`;

export const DispoEnquiryMutation = gql`
    mutation dispoEnquiryMutation($contents: String!, $productName: String, $contactPerson: String!) {
        DispoEnquiry(contents: $contents, productName: $productName, contactPerson: $contactPerson) {
            status
            message
        }
    }
`;

export const AdditionalExtendMutation = gql`
    mutation additionalExtendMutation($orderId: ID!, $extendByWeeks: Int!) {
        AdditionalExtend(orderId: $orderId, extendByWeeks: $extendByWeeks) {
            status
            message
        }
    }
`;

export const DispoAcknowledgeExtendMutation = gql`
    mutation dispoAcknowledgeMutation($orderId: ID!) {
        DispoAcknowledge(orderId: $orderId) {
            status
            message
        }
    }
`;

export const UpsertOrderFiltersMutation = gql`
    mutation insertOrderFiltersMutation($filters: [orderFilters_insert_input!]!) {
        insert_orderFilters(
            objects: $filters,
            on_conflict: {
                constraint: orderFilters_userId_key,
                update_columns: [
                    search,
                    status,
                    deliveryDateOption,
                    expiryDateOption,
                    orderDateOption,
                    producer,
                    pzn,
                    type,
                    deliveryDateFrom, deliveryDateTo,
                    expiryDateFrom, expiryDateTo,
                    orderDateFrom, orderDateTo
                ]
            }
        ) {
            affected_rows
        }
    }
`;
export const SaveOrderFiltersHistoryMutation = gql`
    mutation SaveOrderFilterHistory(
        $search: String
        $status: String
        $deliveryDateOption: String
        $deliveryDateFrom: Date
        $deliveryDateTo: Date
        $orderDateOption: String
        $orderDateFrom: Date
        $orderDateTo: Date
        $expiryDateOption: String
        $expiryDateFrom: Date
        $expiryDateTo: Date
        $pzn: String
        $producer: String
        $type: String

    ) {
        SaveOrderFilterHistory(
           search: $search
           status: $status
           deliveryDateOption: $deliveryDateOption
           deliveryDateFrom: $deliveryDateFrom
           deliveryDateTo: $deliveryDateTo
           orderDateOption: $orderDateOption
           orderDateFrom: $orderDateFrom
           orderDateTo: $orderDateTo
           expiryDateOption: $expiryDateOption
           expiryDateFrom: $expiryDateFrom
           expiryDateTo: $expiryDateTo
           pzn: $pzn
           producer: $producer
           type: $type
        ) {
            status
            message
        }
    }
`;


export const AllOrdersMutations = [
    CancelOrderMutation,
    DispoReOrderMutation,
    DispoEnquiryMutation,
    AdditionalExtendMutation,
    DispoAcknowledgeExtendMutation,
    UpsertOrderFiltersMutation,
    SaveOrderFiltersHistoryMutation
];

@Injectable()
export class OrdersMutations {
    private apollo = inject(Apollo);
    private apiService = inject(ApiService);
    private profileSettingsVar = inject(ProfileSettingsVar);
    private dataChangedForceState = inject(DataChangedForceStateVar);

    profileSettings: Signal<ProfileSettingInterface> = this.profileSettingsVar.profileSettings;

    static _normalizeFilters(normalizedFilters) {
        // reformat empty strings to null
        if(normalizedFilters['deliveryDateFrom'] && normalizedFilters['deliveryDateFrom'] === '') {
            normalizedFilters['deliveryDateFrom'] = null;
        }
        if(normalizedFilters['deliveryDateTo'] && normalizedFilters['deliveryDateTo'] === '') {
            normalizedFilters['deliveryDateTo'] = null;
        }
        if(normalizedFilters['expiryDateFrom'] && normalizedFilters['expiryDateFrom'] === '') {
            normalizedFilters['expiryDateFrom'] = null;
        }
        if(normalizedFilters['expiryDateTo'] && normalizedFilters['expiryDateTo'] === '') {
            normalizedFilters['expiryDateTo'] = null;
        }
        if(normalizedFilters['orderDateFrom'] && normalizedFilters['orderDateFrom'] === '') {
            normalizedFilters['orderDateFrom'] = null;
        }
        if(normalizedFilters['orderDateTo'] && normalizedFilters['orderDateTo'] === '') {
            normalizedFilters['orderDateTo'] = null;
        }

        // reformat all date options but individual to dateFrom/To to null
        if (normalizedFilters['deliveryDateOption'] && normalizedFilters['deliveryDateOption'] !== DateRangeOptionCodes.individual) {
            normalizedFilters['deliveryDateFrom'] = null;
            normalizedFilters['deliveryDateTo'] = null;
        }
        if (normalizedFilters['expiryDateOption'] && normalizedFilters['expiryDateOption'] !== DateRangeOptionCodes.individual) {
            normalizedFilters['expiryDateFrom'] = null;
            normalizedFilters['expiryDateTo'] = null;
        }
        if (normalizedFilters['orderDateOption'] && normalizedFilters['orderDateOption'] !== DateRangeOptionCodes.individual) {
            normalizedFilters['orderDateFrom'] = null;
            normalizedFilters['orderDateTo'] = null;
        }

        return normalizedFilters;
    }

    /**
     * trim values
     * @param data - Data to trim (string or null)
     * @return string|null
     */
    formatInputs = (data) => {
        if (typeof data === 'string') {
            return data.trim().length > 0 ? data.trim() : null;
        }
        return data;
    }

    cancelOrder(orderId: number, reason: string) {
        void this.dataChangedForceState.setForceState({[DCK.ordersChanged]: null});
        this.apollo.mutate({
            mutation: CancelOrderMutation,
            variables: {orderId, reason: this.formatInputs(reason)}
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['CancelOrder'] && d?.data['CancelOrder']['status']))
                  .subscribe((status: string) => {
                      if (!status || status === 'ERROR' || !!result['errors']) {
                          void this.apiService.presentErrorToast(result['errors'], 'Die Bestellung kann nicht storniert werden.');
                      } else {
                          void this.apiService.presentSuccessToast('Die Stornierung wurde erfolgreich beantragt.');
                      }
                  }).unsubscribe();
            },
            error: error => this.apiService.presentErrorToast(error, 'Die Bestellung kann nicht storniert werden.')
        });
    }

    dispoReOrder(orderId: number, qty: number) {
        void this.dataChangedForceState.setForceState({[DCK.ordersChanged]: null});
        this.apollo.mutate({
            mutation: DispoReOrderMutation,
            variables: {
                orderId,
                qty,
                latestExpected: formatMomentDateToDefaultDateFormat(moment().add(1, 'months'))
            }
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['DispoReOrder'] && d?.data['DispoReOrder']['status']))
                  .subscribe((status: string) => {
                      if (!status || status === 'ERROR' || !!result['errors']) {
                          void this.apiService.presentErrorToast(result['errors'], 'Eine Nachbestellung konnte nicht beauftragt werden.');
                      } else {
                          void this.apiService.presentSuccessToast('Die Nachbestellung wurde beauftragt.');
                      }
                  }).unsubscribe();
            },
            error: error => this.apiService.presentErrorToast(error, 'Eine Nachbestellung konnte nicht beauftragt werden.')
        });
    }


    dispoEnquiry(contents: string, productName: string = null, contactPerson: string = null) {
        let email = this.profileSettings().user.email || '';
        const maxValidCharacter = 120;
        const remainingCharacter = maxValidCharacter - contactPerson.length - 3;
        if(email.length > remainingCharacter){
            email = email.substring(0, remainingCharacter);
        }
        void this.dataChangedForceState.setForceState({[DCK.ordersChanged]: null});
        this.apollo.mutate({
            mutation: DispoEnquiryMutation,
            variables: {
                contents: this.formatInputs(contents),
                productName: this.formatInputs(productName),
                contactPerson: this.formatInputs(contactPerson + ' - ' + email)
            }
        }).subscribe({
            next: result => {
                from([result])
                    .pipe(map(d => d?.data && d?.data['DispoEnquiry'] && d?.data['DispoEnquiry']['status']))
                    .subscribe((status: string) => {
                        if (!status || status === 'ERROR' || !!result['errors']) {
                            void this.apiService.presentErrorToast(result['errors'], 'Die Dispoanfrage konnte nicht gespeichert werden.');
                        } else {
                            void this.apiService.presentSuccessToast('Die Anfrage wurde erfolgreich an Ihr Kundenservice-Team versendet. Alle Anfragen sind im Bereich "Kommunikation" einsehbar.');
                        }
                    });
            },
            error: error => this.apiService.presentErrorToast(error, 'Die Dispoanfrage konnte nicht gespeichert werden.')
        });
    }

     additionalExtend(orderId: number, extendByWeeks: number) {
        void this.dataChangedForceState.setForceState({[DCK.ordersChanged]: null});
        this.apollo.mutate({
            mutation: AdditionalExtendMutation,
            variables: {orderId, extendByWeeks}
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['AdditionalExtend'] && d?.data['AdditionalExtend']['status']))
                  .subscribe((status: string) => {
                      if (!status || status === 'ERROR' || !!result['errors']) {
                          void this.apiService.presentErrorToast(result['errors'], 'Die Verlängerung konnte nicht gespeichert werden.');
                      } else {
                          void this.apiService.presentSuccessToast('Die Verlängerung um ' + extendByWeeks +
                            ' Wochen wurde erfolgreich beantragt.');
                      }
                  });
            },
            error: error => this.apiService.presentErrorToast(error, 'Die Verlängerung konnte nicht gespeichert werden.')
        });
    }

    dispoAcknowledge(orderId) {
        void this.dataChangedForceState.setForceState({[DCK.ordersChanged]: null});
        this.apollo.mutate({
            mutation: DispoAcknowledgeExtendMutation,
            variables: {orderId}
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['DispoAcknowledge'] && d?.data['DispoAcknowledge']['status']))
                  .subscribe((status: string) => {
                      if (!status || status === 'ERROR' || !!result['errors']) {
                          void this.apiService.presentErrorToast(result['errors'], 'Beim Markieren der Bestellung ist ein Fehler aufgetreten.');
                      } else {
                          void this.apiService.presentSuccessToast('Die Bestellung wurde als angekommen markiert.');
                      }
                  });
            },
            error: error => this.apiService.presentErrorToast(error, 'Beim Markieren der Bestellung ist ein Fehler aufgetreten.')
        });
    }

    upsertOrderFilters(filters: OrderFiltersInterface) {
        void this.dataChangedForceState.setForceState({[DCK.orderFilterChanged]: null});
        Object.keys(filters).forEach(key => {
            filters[key] = this.formatInputs(filters[key]);
        });
        this.apollo.mutate({
            mutation: UpsertOrderFiltersMutation,
            variables: {filters: OrdersMutations._normalizeFilters(filters)}
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['insert_orderFilters'] && d?.data['insert_orderFilters']['affected_rows']))
                  .subscribe((affectedRows: number) => {
                      if (affectedRows >= 1) {
                          // Removed the truthy case -> showed up with every interaction -> distracting for the user
                      } else {
                          void this.apiService.presentErrorToast(result['errors'], 'Die Filter konnten nicht gesetzt werden.');
                      }
                  });
            },
            error: error => this.apiService.presentErrorToast(error, 'Die Filter konnten nicht gesetzt werden.')
        });
    }

    insertOrderFiltersHistory(filters: OrderFiltersInterface) {
        Object.keys(filters).forEach(key => {
            filters[key] = this.formatInputs(filters[key]);
        });

        const normalizedFilters = OrdersMutations._normalizeFilters(filters);
        this.apollo.mutate({
            mutation: SaveOrderFiltersHistoryMutation,
            variables: { ...normalizedFilters }
        }).subscribe({
            next: () => {
                // Do nothing
            },
            error: () => null
        });
    }
}
