export const environment = {
  name: 'prod',
  production: true,
  clientApp: false,
  frontendType: 'pharmacy',
  keycloak: {
    baseUrl: 'https://login.sanacorp.de/auth',
    realm: 'Apotheken',
    shouldAddToken: 'login.sanacorp.de',
  },
  aws_config: {
    aws_cognito_identity_pool_id: 'eu-central-1:15d111b9-ffcd-4b86-b656-4e00cb02664e',
    aws_cognito_region: 'eu-central-1',
    aws_mandatory_sign_in: 'disable',
    aws_user_pools_id: 'eu-central-1_Of7fBovYK',
    aws_user_pools_web_client_id: '1hhlpg51fha27mosj2tuq4o5s5',
    aws_appsync_graphqlEndpoint: 'https://sbenphpftbbfhkuzed6elmq5d4.appsync-api.eu-central-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-central-1',
    aws_appsync_authenticationType: 'AWS_IAM',
  },
  bucket: {
    aws_user_files_s3_bucket: 'sanacorp-assets-store-prod',
    aws_user_files_s3_bucket_base_url: 'https://sanacorp-assets-store-prod.s3.eu-central-1.amazonaws.com',
    aws_image_base_url: 'https://medien.meineapotheke.de/apothekenbilder',
  },
  msgCache: {
    enabled: true,
    lifetime: 3600000, // 1 day
  },
  pushNotificationPublicKey: 'BFPQN5rG6rLE40lNAjriQtt_QeTGAdYyzn0XciBScxPTWiNRdRgt1VPSzJvZ7jjyCdXAZqK7lJZ1s_tpVPnHxMI',
  sentryDSN: 'https://3506fed5d6c94f7db903049be35b2e48@sentry.sanacorp.cloud/9',
  animated: true,
  checkForHolidays: true,
  meaChatUrl: 'https://apotheke.meineapotheke.de',
  featureFlags: {
    showCommonChatMessagePharmacyInfo: false,
    showToastOnEndUserLogout: false,
    showWelcomeMessageForIntent: false,
    setsAppContext: false,
    showCustomAlertText: true,
    showEmailNotificationInput: false,
    showOpenTicketReminder: true,
    enableBrowserBackOnCustomModalClosing: true,
    showLegalInfoOnImageUploadModal: true,
    changeIconWhenChangingTabTitle: true,
    indicateSelfTyping: true,
    isNativeApp: false,
    requiresShadowRoot: false,
    logoutOnUserError: true,
    usesGoogleAnalytics: true,
    trackTicketUpdates: true,
    messageTextMaxLength: 4000,
    saveNotificationSettingsInStorage: false,
  },
};
