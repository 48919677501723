import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    inject
} from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { OrderStatus, OrderStatusFilter } from '../../enums/orders.enum';


/**
 *
 * Usage:
 * <app-widget>
 *   <div header></div>
 *   <div content></div>
 *   <div footer></div>
 * </app-widget>
 */
@Component({
    selector: 'app-select-popover',
    templateUrl: './select-popover.component.html',
    styleUrls: ['./select-popover.component.scss'],
})

export class SelectPopoverComponent implements OnInit {
    private popoverController = inject(PopoverController);

    @Input() options: {
        id: any;
        title: string;
        children: {
            id: any;
            title: string;
        }[];
        disabled?: boolean;
        tooltipKey?: string;
    }[] = [];
    @Input() selected: any;
    @Input() useCheckboxes = false;
    @Output() itemClicked = new EventEmitter<any>();

    checkedItems: Array<OrderStatus|OrderStatusFilter> = [];

    // Popover Controller, provided by ionic
    popover;

    constructor() { }

    ngOnInit() {
        if(this.selected) {
            this.checkedItems = this.selected.split(',');
        }
    }

    dismiss(data: any) {
        if (this.popover && this.popover.dismiss) {
            this.popover.dismiss(data);
        } else if(this.popoverController) {
            this.popoverController.dismiss(data);
        }
    }
    /**
     * called if button was clicked
     *
     * @param id - Id of button
     */
    onButtonClick(id: any) {
        this.itemClicked.emit(id);
        this.dismiss({id});
    }

    submitFilter(){
        if(this.checkedItems.length === 0){
            this.itemClicked.emit('ALL');
            this.dismiss({multipleIds: 'ALL'});
        } else {
            const allIndex = this.checkedItems.findIndex(item => item === 'ALL');
            if(allIndex >= 0){
                this.checkedItems.splice(allIndex, 1);
            }
            this.itemClicked.emit(this.checkedItems.join(','));
            this.dismiss(this.checkedItems.join(','));
        }
    }


    updateCheckboxes(id) {
        const itemIndex = this.checkedItems.findIndex(item => id === item);
        const isChecked = itemIndex === -1;
        if(isChecked) {
            this.checkedItems.push(id);
        } else {
            this.checkedItems.splice(itemIndex, 1);
        }

        // Check/Uncheck children of a group
        const children = this.options.find(option => option.id === id)?.children;
        if(children) {
            if(isChecked) {
                children.forEach(child => {
                    if(this.checkedItems.findIndex(item => child.id === item) === -1) {
                        this.checkedItems.push(child.id);
                    }
                });
            } else {
                children.forEach(child => {
                    const childIndex = this.checkedItems.findIndex(item => child.id === item);
                    if(childIndex >= 0) {
                        this.checkedItems.splice(childIndex, 1);
                    }
                });
            }
        }

        // check if parents should be checked / unchecked because all children are set
        this.options.filter(option => option.children)
          .forEach(option => {
              const allChildrenChecked = option.children.every(item => this.checkedItems.includes(item.id));
              if(allChildrenChecked
              && this.checkedItems.indexOf(option.id) === -1) {
                  this.checkedItems.push(option.id);
              } else if(this.checkedItems.indexOf(option.id) !== -1 && !allChildrenChecked) {
                  this.checkedItems.splice(this.checkedItems.indexOf(option.id), 1);
              }
          });


        // Update object and delete possible duplicates
        this.checkedItems = [...(this.checkedItems.filter(
          (item, index, inputArray) =>
            inputArray.indexOf(item) === index
        ))];
    }

    /**
     * track by
     *
     * @param index - Index of the item in the array
     * @param item - The item to track
     */
    trackBy(index, item) {
        return item.id;
    }
}
