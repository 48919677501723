import { Action, createReducer, on } from '@ngrx/store';
import update from 'immutability-helper';
import { v4 as uuid } from 'uuid';
import { getIsSmallDevice } from '../../../../../essentials/util/src/getIsSmallDevice';
import {
  setAppearance,
  setIsOnline,
  setIsSmallDevice,
  setIsVisible,
  setMinimiseEmailNotification,
  setShowEmailNotification,
} from '../actions/device.actions';
import { DeviceState } from '../state/device.state';

export const SHOW_EMAIL_NOTIFICATION = 'showEmailNotification';
export const MINIMISE_EMAIL_NOTIFICATION = 'minimiseEmailNotification';

export const initialDeviceState: DeviceState = {
  frontendSessionId: uuid(),
  isOnline: true,
  isVisible: true,
  isSmallDevice: getIsSmallDevice(),
  showEmailNotification: localStorage.getItem(SHOW_EMAIL_NOTIFICATION) === 'true',
  minimiseEmailNotification: localStorage.getItem(MINIMISE_EMAIL_NOTIFICATION) === 'true',
  appearance: undefined,
};

const _deviceReducer = createReducer(
  initialDeviceState,
  on(setIsOnline, (state, { isOnline }) =>
    update(state, {
      isOnline: { $set: isOnline },
    })
  ),
  on(setIsVisible, (state, { isVisible }) =>
    update(state, {
      isVisible: { $set: isVisible },
    })
  ),
  on(setIsSmallDevice, (state, { isSmallDevice }) =>
    update(state, {
      isSmallDevice: { $set: isSmallDevice },
    })
  ),
  on(setShowEmailNotification, (state, { showEmailNotification }) =>
    update(state, { showEmailNotification: { $set: showEmailNotification } })
  ),
  on(setMinimiseEmailNotification, (state, { minimiseEmailNotification }) =>
    update(state, { minimiseEmailNotification: { $set: minimiseEmailNotification } })
  ),
  on(setAppearance, (state, { appearance }) => update(state, { appearance: { $set: appearance } }))
);

export function deviceReducer(state: DeviceState | undefined, action: Action): DeviceState {
  return _deviceReducer(state, action);
}
