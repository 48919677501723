export enum MeaFiltersTypeEnum {
    date = 'dateFilter',
    type = 'typeFilter',
    search = 'searchFilter',
    filterButton = 'filterButton',
}

export enum MeaDownloadTypes {
    ALL = 'Alle',
    MARKETING = 'Marketing',
    INFO = 'Information',
}

export enum SortableMeaDownloadColumns {
    LABEL = 'label',
    DATE = 'createdAt',
    DOCUMENT_FORMAT = 'file',
}

export enum MeaDownloadsStorageKeys {
    FILTERS = 'meaDownloadsFilters',
    SORT = 'meaDownloadsSort',
}

export enum MeaChatType {
    ENDUSER = 'enduser',
    PHARMACY = 'pharmacy'
}

export enum LoginStatus {
    /** Success: Login succeeded */
    Success = 'Success',
    /** LoginError: Login failed */
    LoginError = 'LoginError',
    /** RestorePrivateKeyPasswordError: The password does not match the stored value */
    RestorePrivateKeyPasswordError = 'RestorePrivateKeyPasswordError',
    /** RestorePrivateKeyError: The entered private key does not match the stored value */
    RestorePrivateKeyError = 'RestorePrivateKeyError',
    /** RestorePrivateKey: The private key needs to be entered and submitted */
    RestorePrivateKey = 'RestorePrivateKey',
    /** BackupPrivateKey: The private key needs to be created */
    BackupPrivateKey = 'BackupPrivateKey'
}
