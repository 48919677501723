export enum ButtonTypes {
    CANCEL = 'cancel',
    VIEW = 'view',
    DOWNLOAD = 'download',
    PRINT = 'print',
    COPY = 'copy',
    EXTEND = 'extend',
    DELETE = 'delete',
    ARCHIVE = 'archive',
    CLOSE = 'close',
    MODAL = 'modal',
    ACCEPT = 'accept',
    PAGE_CALENDAR = 'page_calendar',
    NOTE = 'note',
    SUPPORT = 'support',
    MARK_AS_SOLUTION = 'mark_as_solution',
    UNMARK_SOLUTION = 'unmark_solution',
    MEAMIND_CREATE_SOLUTION_COMMENT = 'meamind_create_solution_comment',
    MEAMIND_DELETE_ANSWER = 'meamind_delete_answer',
    MEAMIND_DELETE_COMMENT = 'meamind_delete_comment',
    MEAMIND_EDIT_ANSWER = 'meamind_edit_comment',
    MEAMIND_EDIT_COMMENT = 'meamind_edit_comment',
    LOGOUT = 'logout'
}
