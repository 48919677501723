import { Inject, Injectable } from '@angular/core';
import FeatureFlags from '../../../../essentials/types/src/feature-flags';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let ga: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  private readonly trackers = ['meachat', 'crossdomain'];
  private readonly googleAnalyticsEnabled: boolean;

  constructor(@Inject('config') private config: { production: boolean; featureFlags: FeatureFlags }) {
    this.googleAnalyticsEnabled = this.config && this.config.production && this.config.featureFlags.usesGoogleAnalytics;
  }

  sendEvent(eventCategory: string, eventAction: string, eventLabel?: string, eventValue?: number) {
    if (this.googleAnalyticsEnabled && typeof ga !== 'undefined') {
      this.trackers.forEach((tracker) => {
        ga(`${tracker}.send`, 'event', eventCategory, eventAction, eventLabel, eventValue);
      });
    }
  }
}

export const enum PUSH_NOTIFICATIONS {
  CATEGORY_SETTINGS = 'Einstellungen',
  CATEGORY_CHAT = 'Chat',
  ACTIVATE = 'Benachrichtigung aktivieren',
  ACTIVATED = 'Benachrichtigung aktiviert',
  DEACTIVATE = 'Benachrichtigung deaktivieren',
  DEACTIVATED = 'Benachrichtigung deaktiviert',
}

export const enum FAVORITES {
  CATEGORY = 'Apotheke',
  SELECT = 'Favorit wählen',
  DESELECT = 'Favorit abwählen',
}

export const enum MESSAGES {
  CATEGORY = 'Chat',
  ACTION = 'Erste Nachricht verschicken',
}
