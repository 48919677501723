import gql from 'graphql-tag';

export default gql`
  query getAllChatPartnerMetadataForEnduser($nextToken: String) {
    getAllChatPartnerMetadataForEnduser(nextToken: $nextToken) {
      __typename
      chatPartnerMetadata {
        __typename
        cognitoId
        chatPartnerId
        isSuspended
      }
      nextToken
    }
  }
`;
