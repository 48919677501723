import { StatisticTypeEnum } from '../enums/statistics-type.enum';

export const StatisticNumberKeys = [
    StatisticTypeEnum.ORDERS_OPEN,
    StatisticTypeEnum.ORDERS_ENROUTE,
    StatisticTypeEnum.ORDERS_PENDING,
    StatisticTypeEnum.ORDERS_OPEN_CANCELATIONS,
];

export const StatisticsSalesTypes =  [
    {
        id: 'rxWithoutHP',
        label: 'RX ohne HP'
    },
    {
        id: 'withoutHP',
        label: 'ohne HP'
    },{
        id: 'withHP',
        label: 'nur HP'
    },{
        id: 'all',
        label: 'Gesamt'
    }
];

export const StatisticsPackageTypes = [
    {
        id: 'rxWithoutHP',
        label: 'RX ohne HP'
    },
    {
        id: 'withoutHP',
        label: 'ohne HP'
    },{
        id: 'withHP',
        label: 'nur HP'
    },{
        id: 'all',
        label: 'Gesamt'
    }
];
