
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';

// Components
import { OrderSearchModalComponent } from '../order-search-modal/order-search-modal.component';

// Core Files
import { OrderDetailInterface } from '../../../../../../core/core.interfaces';
import { OrderQueries, OrdersMutations } from '../../../../../../core/core.store';
import { QuantityValidator } from '../../../../../../core/core.validators';
import { OrderType } from '../../../../../../core/enums/orders.enum';
import { ModalService } from '../../../../../../core/services/modal.service';
import { OrderService } from '../../../../../../core/services/order.service';
import { matomoIdsEnum, ModalClassEnum, FullLogisticsRoutesEnum } from '../../../../../../core/core.enums';
import { unsubscribe, unsubscribeAll } from '../../../../../../core/core.utils';


@Component({
    selector: 'app-order-copy',
    templateUrl: './order-copy.component.html',
    styleUrls: ['./order-copy.component.scss'],
})
export class OrderCopyComponent implements OnInit, OnChanges {
    // Adds class to modal
    static modalClass = ModalClassEnum.large;

    @Input() orderId: number = null;

    matomoId = matomoIdsEnum.orderCopy;

    order: OrderDetailInterface;
    searchText = '';
    orders: Array<OrderDetailInterface> = [];
    additionalSearchResults = false;
    maxNumberOfRows = 9;
    validationFormGroup: FormGroup;
    singleDeliveryType: OrderType = OrderType.DISPOSITION;
    orderSubscription: Subscription;

    constructor(
        private orderQueries: OrderQueries,
        private ordersMutations: OrdersMutations,
        public formBuilder: FormBuilder,
        private navCtrl: NavController,
        private modalService: ModalService,
        private orderService: OrderService
    ) {
    }

    ngOnInit() {
        if (this.orderId > 0) {
            unsubscribe(this.orderSubscription);
            this.orderSubscription = this.orderQueries.getOrderById(this.orderId).subscribe(order => {
                this.order = order;
            });

        }

        this.validationFormGroup = this.formBuilder.group({
            qty: ['1', Validators.compose(QuantityValidator())]
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.orderId) {
            if (this.orderId > 0) {
                this.ngOnInit();
            }
        }
    }

    ionViewWillLeave(): void {
        unsubscribeAll([
            this.orderSubscription,
        ]);
    }

    /**
     * fires if user wants to select a different order to copy
     * User has pressed the create disposition request icon. Show modal window
     */
    async onChangeFilterCriteriaClick() {
        await this.modalService.dismiss();
        const orderSearchModal = await this.modalService.create(
            OrderSearchModalComponent,
            {
                focusOnSearch: true,
                searchText: this.searchText,
                saveFilters: true
            }
        );
        await this.modalService.present(orderSearchModal);

        // after closing the modal, the return values are processed
        orderSearchModal.onDidDismiss().then((result) => {
            if (result['data']) {
                this.navCtrl.navigateRoot(FullLogisticsRoutesEnum.orders);
            }
        });
    }

    /**
     * fires if user has selected a order to copy
     *
     * @param orderId - id of the selected order
     */
    onSelectedOrderChange(orderId: number) {
      this.orderId = orderId;
      this.ngOnInit();
    }

    /**
     * send the disposition request via api call
     *
     * @param values - form values
     */
    async sendDispositionRequest(values) {
        if (this.orderService.isCopyEnabled(this.order)) {
            this.ordersMutations.dispoReOrder(this.orderId, parseInt(values.qty, 10));
            await this.modalService.dismiss();
        }
    }

}
