import { Pipe, PipeTransform } from '@angular/core';
import { getPathAsString, isSettingReadOnly } from '../formatting/settings.formatting';

@Pipe({
    name: 'isSettingReadOnly'
})
export class IsSettingReadOnlyPipe implements PipeTransform {

    transform(elementPathArray: Array<string>, settings, settingsConfig): boolean {
        const elementPath = getPathAsString(elementPathArray);
        return isSettingReadOnly(elementPath, settings, settingsConfig);
    }
}
