import gql from 'graphql-tag';
import { userConversationAttributes } from '../attributes';

export default gql`
    mutation updateUserConversationLink($id: ID!, $conversationLink: String) {
        updateUserConversation(id: $id, conversationLink: $conversationLink) {
            ${userConversationAttributes}
        }
    }
`;
