@if (requiresShadowRoot) {
  <mea-shadow-root>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </mea-shadow-root>
} @else {
  <ng-container *ngTemplateOutlet="content"></ng-container>
}

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
