import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { gql } from '@apollo/client/core';
import { Observable, map } from 'rxjs';
import {
    EducationFiltersInterface,
    EducationInterface
} from '../../../core.interfaces';

import {
    QueryFetchPolicy,
    FetchPolicyKeys as FPK,
    EducationLocationsEnum
} from '../../../core.enums';
import { DataChangedKeys as DCK } from '../../../enums/data-changed-keys.enum';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { QueryWrapper } from '../query.wrapper';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { formatStrapiEducations, formatStrapiEducation } from '../utils';


export const GetEducations  = (queryName) => gql`
    query ${queryName}($filters: EducationFiltersInput, $limit: Int) {
        educations(
            filters: $filters
            pagination: {
                limit: $limit
            }
        ) {
            data{
                id
                attributes {
                    content
                    shortContent
                    publishedAt
                    headline
                    educationCategory {
                        data {
                            id
                            attributes {
                                category
                            }
                        }
                    }
                    educationLocation {
                        data {
                            id
                            attributes {
                                location
                                color
                            }
                        }
                    }
                    image {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const GetEducationById = (queryName) => gql`
    query ${queryName}($id: ID!) {
        education(id:$id) {
            data{
                id
                attributes{
                    publishedAt
                    headline
                    shortContent
                    content
                    educationCategory{
                        data{
                            id
                            attributes{
                                category
                            }
                        }
                    }
                    educationLocation {
                        data {
                            id
                            attributes {
                                location
                                color
                            }
                        }
                    }
                    image{
                        data{
                            attributes{
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const AllEducationQueries = [
    GetEducations('test'),
    GetEducationById('test')
];

@Injectable()
export class EducationQueries extends QueryWrapper {
    fetchPolicies = {
        [FPK.getEducations]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getEducationById]: QueryFetchPolicy.NETWORK_ONLY
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar
    ) {
        super(apollo, dataChangedVar, {
            [DCK.educationChanged]: [
                FPK.getEducations,
                FPK.getEducationById
            ]
        });}

    /**
     * Return all available educations
     */
    public getEducations(
        educationFilters: EducationFiltersInterface
    ): Observable<Array<EducationInterface>> {
        const fetchPolicyKey = FPK.getEducations;
        const filters = this.createFiltersWhere(educationFilters);

        return this.apollo.watchQuery({
                query: GetEducations(fetchPolicyKey),
                variables: {
                    filters,
                    // TODO - Use only after this is solved https://github.com/strapi/strapi/issues/11892
                    // sort: ['timespan.dateTimeFrom:desc'],
                    limit: GraphQLLimits.educations},
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['educations']))
            .pipe(map(data => formatStrapiEducations(data)))
            .pipe(map(
                (educations: Array<EducationInterface>) =>
                (!educations ? [] : educationFilters?.search ?
                    educations.reduce((array, education) => {
                        if (education) {
                            const searchString = educationFilters.search ? educationFilters.search.toLowerCase() : '';
                            const containsSearchString = !educationFilters.search
                                || (education.headline && education.headline.toLowerCase().includes(searchString))
                                || (education.content && education.content.toLowerCase().includes(searchString)
                                || (education.shortContent && education.shortContent.toLowerCase().includes(searchString)));

                            if (containsSearchString) {
                                array.push(education);
                            }

                            return array;
                        }
                    }, []
                ): educations)
            ))
            .pipe(map(d => {
                if (d) this.updateFetchPolicy(fetchPolicyKey);
                return d;
            })) as Observable<Array<EducationInterface>>;
    }

    /**
     * Return the education by the given id
     *
     * @param id - Id of the education
     * @param fetchPolicy - Fetch policy
     */
    public getEducationById(
        id: string,
        fetchPolicy: QueryFetchPolicy = QueryFetchPolicy.CACHE_AND_NETWORK
    ): Observable<EducationInterface> {
        const fetchPolicyKey = FPK.getEducationById;
        return this.apollo.watchQuery({
                query: GetEducationById(fetchPolicyKey),
                variables: { id },
                fetchPolicy
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['education']))
            .pipe(map(d => formatStrapiEducation(d?.data)))
            .pipe(map(d => {
                if (d) this.updateFetchPolicy(fetchPolicyKey);
                return d;
            }))  as Observable<EducationInterface>;
    }

    /**
     * Create a where from the given education filters
     *
     * @param filters - the education filters
     */
    private createFiltersWhere = (filters: EducationFiltersInterface) => {
        // it's important that where is an empty object not an empty array, otherwise the filters won't work
        const where = {};
        if (filters) {
            if (filters.location && filters.location !== EducationLocationsEnum.ALL.toString()) {
                where['educationLocation'] = {id: {eq: filters.location}};
            }
        }

        return where;
    }
}
