import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TrendingBadgeComponent } from './trending-badge.component';
import { CoreModule } from '../../core/core.module';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        CoreModule,
        NgOptimizedImage
    ],
    exports: [
        TrendingBadgeComponent
    ],
    declarations: [
        TrendingBadgeComponent
    ]
})
export class TrendingBadgeModule {
}
