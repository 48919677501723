export enum FieldLengthEnum {
    communicationZoneTitle,
    communicationZoneContent,
    communicationZoneContactPerson,
    cancelOrder,
    dispoEnquiry,
    dispoEnquiryQty,
    dispoEnquiryProductName,
    dispoEnquiryNarcotic,
    dispoEnquiryPackageSize,
    dispoEnquiryDosageForm,
    dispoEnquiryProducer,
    dispoEnquiryPZN,
    dispoEnquiryAdditionalInformation,
    returnRequest,
    returnRequestRemarks
}

export enum InputValidationTableNameEnum {
    communicationZone = 'communicationZone'
}


export enum InputValidationFieldEnum {
    title = 'title',
    content = 'content',
    contactPerson = 'contactPerson'
}
