import gql from 'graphql-tag';
import { endUserAttributes } from '../attributes';

export default gql`
  mutation updateGuestNotificationEmail($cognitoId: ID!, $guestNotificationEmail: String!) {
    updateEndUser(cognitoId: $cognitoId, user: { guestNotificationEmail: $guestNotificationEmail }) {
      ${endUserAttributes}
    }
  }
`;
