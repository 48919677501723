import { inject, Injectable } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, distinctUntilChanged, map, Observable, switchMap, take, tap } from 'rxjs';
import { ProfileSettingsVar } from '../store/locals/profileSettings.var';
import { StaticPagesQueries } from '../store/graphql/queries/static-pages.graphql';
import { AcceptedTermsInterface, StaticPageInterface, TermsShowAcceptInterface } from '../interfaces/static-page.interface';
import { AcceptTermsStorageKey, StaticPageTypeEnum } from '../enums/static-page-type.enum';
import { StaticPagesMutations } from '../store/graphql/mutations/static-pages.graphql';
import { AppRoutesEnum } from '../enums/routes.enum';
import { now, nowAsMoment } from '../formatting/date.formatting';
import { PharmacyStateVar } from '../store/locals/pharmacyState.var';

@Injectable({
    providedIn: 'root',
})
export class StaticPageService {
    private pharmacyStateVar = inject(PharmacyStateVar);
    private profileSettingsVar = inject(ProfileSettingsVar);

    private isPharmacyOwner$: Observable<boolean>;
    private acceptedTerms$ = new BehaviorSubject<AcceptedTermsInterface>(null);
    private version$ = new BehaviorSubject<string>(null);
    private showAccept$: BehaviorSubject<TermsShowAcceptInterface> = new BehaviorSubject({
        show: true,
        isDisabled: false,
        isAccepted: false
    });

    private _activePharmacy = toSignal(this.pharmacyStateVar.getActivePharmacy());

    constructor(
        private alertController: AlertController,
        private staticPagesQueries: StaticPagesQueries,
        private staticPagesMutations: StaticPagesMutations
    ) {
        this.isPharmacyOwner$ = toObservable(this.profileSettingsVar.profileSettings).pipe(
            map(settings => settings.user.isPharmacyOwner)
        );
    }

    /**
     * Return a single static page by its type. The type is resolved by the activated route.
     * For general terms and conditions an accept button is shown if the user is a pharmacy owner
     * and the version of the accepted terms is not the same as the current version.
     */
    getStaticPageByRoute(activatedRoute: ActivatedRoute, pageType?: StaticPageTypeEnum): Observable<StaticPageInterface> {
        return activatedRoute.data.pipe(
            switchMap(routeData => {
                return this.staticPagesQueries.getStaticPageByType(pageType ? pageType : routeData.pageType).pipe(
                    // static pages components are not destroyed for some reason, therefore we must complete the query here
                    take(1),
                    switchMap((page : StaticPageInterface) => {
                        // this is necessary, as the acceptedTerms pipe would try to log out the customer again
                        if (window.location.pathname === AppRoutesEnum.login
                            || window.location.pathname === AppRoutesEnum.loginCallback) {
                            this.toggleShowAccept(false);
                            return new Observable<StaticPageInterface>(subscriber => {
                                subscriber.next(page);
                            });
                        }
                        if (!page) {
                            return new Observable<StaticPageInterface>(subscriber => {
                                subscriber.next(null);
                            });
                        }
                        return this.isPharmacyOwner$.pipe(
                            switchMap(isOwner => {
                                return this.staticPagesQueries.getAcceptedTermsVersion().pipe(
                                    map((acceptedTerms) => {
                                        this.acceptedTerms$.next(acceptedTerms);
                                        this.toggleShowAccept(page.pageType === StaticPageTypeEnum.GENERAL_TERMS &&
                                            isOwner && acceptedTerms?.versionNumber !== page.versionNumber,
                                            page.versionNumber === acceptedTerms?.versionNumber);
                                        return {
                                            ...page
                                        };
                                    })
                                );
                            }),
                            tap(() => {
                                this.version$.next(page.versionNumber);
                            })
                        );
                    })
                );
            })
        );
    }

    getAcceptedTerms(): Observable<AcceptedTermsInterface> {
        return this.acceptedTerms$.asObservable();
    }

    getShowAccept(): Observable<TermsShowAcceptInterface> {
        return this.showAccept$.asObservable().pipe(
            distinctUntilChanged()
        );
    }

    /**
     * User has clicked the accept button, a confirmation email is sent to the user and the button is disabled.
     */
    async acceptTerms(): Promise<boolean> {
        return new Promise(async resolve => {
            const response = await this.staticPagesMutations.sendTermsConfirmationEmail(this.version$.value);
            if (response.success) {
                if (!response?.alreadyAccepted) {
                    const alert = await this.alertController.create({
                        header: 'Bestätigungsmail gesendet',
                        message: 'Innerhalb von 15 Minuten erhalten Sie eine Email zur Bestätigung. Bitte klicken Sie auf den Link in der Email.',
                        buttons: ['Ok']
                    });
                    await alert.present();
                    localStorage.setItem(`${AcceptTermsStorageKey.ACCEPTED_TERMS_KEY}_${this._activePharmacy().activePharmacyId}`, now());
                }
                this.toggleShowAccept(true);
                resolve(true);
            }
            resolve(false);
        });
    }

    /**
     * Confirms against the backend that the provided token is valid.
     * @param token The token from the confirmation email
     */
    async confirmTermsToken(token: string) {
       const isConfirmed = await this.staticPagesMutations.confirmTermsToken(token);
       localStorage.removeItem(`${AcceptTermsStorageKey.ACCEPTED_TERMS_KEY}_${this._activePharmacy().activePharmacyId}`);
       this.toggleShowAccept(!isConfirmed.confirmed, isConfirmed.isActivePharmacyConfirmed);
       if (isConfirmed.confirmed && isConfirmed.isActivePharmacyConfirmed) {
           const result = await this.staticPagesQueries.getAcceptedTermsVersionQueryRef.refetch();
           const accepted: AcceptedTermsInterface = {
               versionNumber: result.data['pharmacyStore'][0]['acceptedTermsAndConditionVersion'] || null,
               acceptedAt: result.data['pharmacyStore'][0]['acceptedTermsAndConditionTime'] || null
           };
           this.acceptedTerms$.next(accepted);
       }
    }

    private toggleShowAccept(show: boolean, isAccepted = false) {
        const acceptClickedLastTime = localStorage.getItem(`${AcceptTermsStorageKey.ACCEPTED_TERMS_KEY}_${this._activePharmacy().activePharmacyId}`);
        const difference = nowAsMoment().diff(acceptClickedLastTime, 'minutes');
        this.showAccept$.next({
            show,
            isDisabled: difference <= 15,
            isAccepted: isAccepted ? isAccepted : this.showAccept$.value.isAccepted
        });
    }

}
