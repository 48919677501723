import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Subject } from 'rxjs';
import { LogoutType } from '../../../../essentials/types/src/logoutType';

@Injectable({
  providedIn: 'root',
})
export class CustomAmplifyService {
  logoutEvent$: Subject<LogoutType>;

  private readonly _auth: typeof Auth;

  constructor() {
    this.logoutEvent$ = new Subject<LogoutType>();
    this._auth = Auth;
  }

  auth(): typeof Auth {
    return this._auth;
  }
}
