import gql from 'graphql-tag';
import { messageAttributes } from '../attributes';

export default gql`
  subscription createdMessage($recipientId: ID!) {
    createdMessage(recipientId: $recipientId) {
      ${messageAttributes}
    }
  }
`;
