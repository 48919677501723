import { Injectable } from '@angular/core';
import { DocumentQueries } from '../store/graphql/queries/documents.graphql';
import { ToastService } from './toast.service';
import { DocumentMutations } from '../store/graphql/mutations/documents.graphql';
import { DocumentFiltersInterface } from '../interfaces/document.interface';
import { defaultDeliveryReceiptFilters, defaultInvoiceFilters } from '../config/document-filters.config';
import { DocumentType } from '../enums/documents.enum';
import { unsubscribe } from '../core.utils';

@Injectable({
    providedIn: 'root',
})
export class DocumentService {

    constructor(
        private documentMutation: DocumentMutations,
        private documentQueries: DocumentQueries,
        private toastService: ToastService
    ) { }

    /**
     * Gets the pfd as integer array
     *
     * @param id - The id of the document
     */
    async getDeliveryDocumentPdf(id: number) : Promise<{result: number[], error: string}> {
        return new Promise<{result: number[], error: string}>((resolve) => {
            const documentSubscription = this.documentQueries.getDocumentsPdf(id).subscribe(pdf => {
                unsubscribe(documentSubscription);
                if (pdf && pdf.data && pdf.data.length > 0) {
                    resolve({result: pdf.data.split(',').map(n => parseInt(n, 10)), error: null});
                } else {
                    this.toastService.presentError(
                        pdf && pdf['message'] ?
                            pdf['message'] :
                            'Lieferschein konnte nicht abgerufen werden. In Sanacorp Connect sind nur die Lieferscheine der letzten 2 Jahre verfügbar.'
                    ).then(() => resolve({result: [], error: pdf['message']}));
                }
            });
        });
    }

    /**
     * Save new document filters depending on existing filters
     *
     * @param updatedFilters - Updated filters
     * @param documentType - Document type
     */
    saveDocumentFilters(updatedFilters: DocumentFiltersInterface, documentType = DocumentType.LS) {
        if (updatedFilters.id) {
            delete updatedFilters.id;
        }
        switch (documentType) {
            case DocumentType.LS:
                this.documentMutation.upsertDocumentFilters({...defaultDeliveryReceiptFilters, ...updatedFilters});
                break;
            case DocumentType.RE:
                this.documentMutation.upsertDocumentFilters({...defaultInvoiceFilters, ...updatedFilters});
        }
    }

    deleteSearchFilter(documentType = DocumentType.LS) {
        switch (documentType) {
            case DocumentType.LS:
                this.documentMutation.deleteSearchFilter(documentType);
                break;
        }
    }
}
