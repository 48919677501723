import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';
import { MeaUser, PushRepetition } from '../../../../../essentials/types/src/chatUser';
import { LoadStatus } from '../../../../../essentials/types/src/loadStatus';
import { LogoutType } from '../../../../../essentials/types/src/logoutType';

export const initUser = createAction(`${ActionType.User}: Init user`);
export const loadInitialUser = createAction(`${ActionType.User}: Load initialUser`);

export const setUserOnLogin = createAction(`${ActionType.User}: Set user on login`, props<{ user: MeaUser }>());
export const updateUser = createAction(`${ActionType.User}: Update user`, props<{ partialUser: Partial<MeaUser> }>());

export const logoutUser = createAction(`${ActionType.User}: Logout user`, props<{ logoutType: LogoutType }>());

export const setUserLoadStatus = createAction(
  `${ActionType.User}: Set initial user load status`,
  props<{ userLoadStatus: LoadStatus }>()
);
export const setPushNotificationRepetitionInterval = createAction(
  `${ActionType.User}: Set pushNotificationRepetitionInterval`,
  props<{ pushNotificationRepetitionInterval: PushRepetition }>()
);

export const clearUserOnLogout = createAction(`${ActionType.User}: Clear user on logout`);
export const resetUserState = createAction(`${ActionType.User}: Reset user state`);

export const deleteGuestSessionAttempt = createAction(`${ActionType.User}: Attempt to delete guest user session`);

export const rehydrateUser = createAction(`${ActionType.User}: Rehydrate user`, props<{ user: MeaUser }>());

export const checkSsoSessionStatus = createAction(
  `${ActionType.User}: Check sso session status`,
  props<{ user: MeaUser }>()
);
