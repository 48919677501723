import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';

export const startSelfTyping = createAction(
  `${ActionType.Chat}: Start self typing`,
  props<{ conversationId: string; timestamp: number }>()
);

export const stopSelfTyping = createAction(
  `${ActionType.Chat}: Stop self typing`,
  props<{ conversationId: string; startTimestamp: number }>()
);

export const emitUserTyping = createAction(
  `${ActionType.Chat}: Emit user typing`,
  props<{ conversationId: string; ownCognitoId: string; chatPartnerCognitoId?: string }>()
);
