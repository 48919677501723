<div>
    <div class="modal-header" *ngIf="!fullScale">
        <ion-text>
            <span>{{ title }}  <ng-content select="[titleExtension]"></ng-content></span>
            <span *ngIf="subtitle" class="subtitle">{{ subtitle }}</span>
        </ion-text>
        <ng-content select="[headerActions]"></ng-content>
        <ion-icon name="close" size="large" class="clickable" (click)="closeModal()"></ion-icon>
    </div>
    <div class="modal-content" id="modal-content"
         [class.no-background]="!contentBG"
         [class.ion-no-padding]="fullScale || !contentPadding"
         [class.modal-content-flex]="contentFlex"
         [class.overflow-hidden]="contentOverflowHidden"
         (scroll)="useScrollListener ? scrolled($event) : null"
    >
        <ion-icon name="close" size="large" class="clickable" (click)="closeModal()" *ngIf="fullScale"></ion-icon>
        <div [class.ion-padding]="!fullScale">
            <ng-content select="[content]"></ng-content>
        </div>
    </div>
    <div class="modal-footer">
        <ng-content select="[footer]"></ng-content>
    </div>
</div>
