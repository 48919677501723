import { computed, inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, tap } from 'rxjs';
import { NotificationQueries } from '../store/graphql/queries/notification.graphql';
import { NotificationInterface, NotificationWidgetRowInterface } from '../interfaces/notification.interface';
import { formatDateTimeInComparisonToToday, formatDateTimeToMoment } from '../formatting/date.formatting';
import { NotificationReminderService } from './notificationReminder.service';
import { NotificationTypeLabelConfig } from '../config/notification-type.config';
import { NotificationReminderTypeLabels } from '../pipes/label-notification-reminder-type.pipe';
import { NotificationsEnum, NotificationsReminderTypeEnum } from '../enums/notifications.enum';
import { InvoicesTypeFilterConfig } from '../config/invoices.config';
import { splitTextFunction } from '../pipes/slice-text.pipe';
import { OffersAppAreaEnum } from '../enums/offers.enum';
import { MeaChatService } from './mea-chat.service';
import { NotificationGoToEntryService } from './notification-go-to-entry.service';
import { AccessGuard } from '../../guard/access.guard';
import { NotificationViewComponent } from '../../pages/calendar/widgets/notification-view/notification-view.component';
import { NotificationReminderViewComponent } from '../../pages/calendar/widgets/notification-reminder-view/notification-reminder-view.component';
import { ModalService } from './modal.service';
import { unsubscribe } from '../util/subscriptions.util';
import { InformationMutations } from '../store/graphql/mutations/information.graphql';

@Injectable({
    providedIn: 'root'
})
export class NotificationWidgetService {
    private notificationQueries = inject(NotificationQueries);
    private notificationReminderService = inject(NotificationReminderService);
    private notificationGoToEntryService = inject(NotificationGoToEntryService);
    private meaChatService = inject(MeaChatService);
    private modalService = inject(ModalService);
    private informationMutations = inject(InformationMutations);

    private _notifications = signal<NotificationInterface[]>(null);
    public notifications = this._notifications.asReadonly();
    private _notificationsStream = toSignal<NotificationInterface[]>(
        this.notificationQueries.getNotificationsByTypes()
            .pipe(
                tap(notifications => {
                    if (notifications?.length) {
                        this._notifications.set(notifications);
                    }
                })
            )
    );

    public notificationReminder = toSignal(
        this.notificationReminderService.getLocalNotificationReminder().pipe(
            map(reminder => (
                reminder ? reminder.map(notification => ({
                    ...notification,
                    created_at: notification.data.dateTimeFrom,
                    message: notification.data.title
                })) : []
            ))
        )
    );

    private _meaChatConversations = this.meaChatService.conversations;
    public meaChatConversations = computed<NotificationWidgetRowInterface[]>(() => {
        const conversations = this._meaChatConversations();
        return conversations?.map(conversation => {
            return {
                id: conversation.id,
                rowCss: 'ion-justify-content-between',
                canGoToEntry: true,
                goToEntry: () => this.meaChatService.redirectToMeaChatConversation(conversation.id.toString()),
                onRowClick: () => {},
                columns: [
                    {
                        data: conversation.time,
                        css: 'first-column no-shrink ion-padding-right-big'
                    },
                    {data: conversation.chatPartnerName, css: 'size-auto no-overflow ion-padding-right-big', overflowFade: true},
                    {
                        data: conversation?.intent || 'neue Nachricht',
                        css: 'last-column no-shrink ion-text-left no-overflow',
                        isLastCol: true,
                        colWidth: '195px',
                        overflowFade: true
                    }
                ],
                isRead: conversation.unreadMessagesCount === 0,
                type: conversation.intent
            };
        });
    });

    /**
     * Returns a signal that contains notifications and notificationReminders
     */
    public allNotifications = computed<NotificationWidgetRowInterface[]>(() => {
        const allNotifications = [];
        if (this.notificationReminder()) {
            allNotifications.push(...this.notificationReminder());
        }
        if (this._notifications()) {
            allNotifications.push(...this._notifications());
        }
        if (allNotifications.length) {
            allNotifications.sort((a, b) => a.created_at < b.created_at ? 1 : a.created_at > b.created_at ? -1 : 0);
        }
        return allNotifications?.map(notification => {
            return {
                id: notification.id,
                rowCss: 'ion-justify-content-between',
                onRowClick: (event) => this.onNotificationClick(event, notification.id, notification.type),
                canGoToEntry: this.notificationGoToEntryService.checkHasEntry(notification.type),
                goToEntry: () => this.notificationGoToEntry(notification),
                columns: [
                    {data: this.notificationLabel(notification), css: 'first-column no-overflow size-auto', overflowFade: true},
                    {
                        data: formatDateTimeInComparisonToToday(notification.created_at),
                        css: 'ion-text-right ion-text-wrap last-column',
                        isLastCol: true
                    }
                ],
                isRead: notification.isRead,
                type: notification.type
            };
        });
    });


    public notificationLabel(notification: NotificationInterface, shortLabel = false, fullMessage = false): string {
        if(shortLabel){
            let label = NotificationTypeLabelConfig[notification.type] || NotificationReminderTypeLabels[notification.type] || '';

            if (notification.payload && notification.payload['productName']) {
                label = label.replace('{{productName}}', notification.payload['productName'] + ' - ');
            }
            if (notification.payload?.appArea) {
                label = this.setOfferPrefix(label, notification.payload.appArea);
            }
            return label;
        }

        let notificationMessage = notification.message;

        if(notificationMessage) {
            notificationMessage = notificationMessage.replace(new RegExp('__(.*?)__','g'), '$1'); // Format underline
            notificationMessage = notificationMessage.replace(new RegExp('##(.*?)##','g'), '$1'); // Format strong
            notificationMessage = notificationMessage.replace(new RegExp('\\*\\*(.*?)\\*\\*','g'), '$1'); // Format italic
        }

        switch (notification.type) {
            case NotificationsEnum.REMINDER_APPOINTMENT:
            case NotificationsEnum.REMINDER_SEMINAR_REGISTRATION:
                return  this.getReminderLabel(notification);
            case NotificationsEnum.NEW_USER_WELCOME:
                return  'Willkommen in Sanacorp Connect';
            case NotificationsEnum.NEW_USER_DATA_POLICY:
                return  'Nutzungsbedingungen';
            case NotificationsEnum.COOPERATION_REVENUE_CURRENT_YEAR:
                return 'mea(R) Kooperation: Jahresziel erreicht';
            case NotificationsEnum.SALES_VOLUME_WITHOUT_HIGH_PRICED:
                return 'Monatsziel ' + formatDateTimeToMoment(notification.created_at, true).format('MMMM YYYY') + ' erreicht.';
            case NotificationsEnum.NEW_OFFER:
                if (notification.payload?.appArea) {
                    notificationMessage = this.setOfferPrefix(notificationMessage, notification.payload.appArea);
                }
                return (notification.payload && notification.payload['name']) ?
                    notification.payload['name'] + ': ' + notificationMessage :
                    notificationMessage;
            case NotificationsEnum.NEW_DOCUMENT:
                if (notification.payload['documentSubType']) {
                    const subtype = InvoicesTypeFilterConfig.find(type => type.id === notification.payload['documentSubType']);
                    if (subtype) {
                        return 'Neue ' + subtype.title + ' ' + notification.payload['recDateFormatted'];
                    }
                }
                return notificationMessage;
            case NotificationsEnum.NEW_SURVEY:
                if (notification.payload['title']) {
                    return `Neue Umfrage: ${notification.payload['title']}`;
                }
                return notificationMessage;
            default:
                if (notification.payload && notification.payload['productName']) {
                    if(fullMessage) {
                        return notification.payload['productName'] + ' ' + notification.payload['packageSize'] + ' - ' + notificationMessage;
                    }
                    return splitTextFunction((notification.payload['productName'] + ' ' + notification.payload['packageSize']), 18)
                        + ' ' + notificationMessage;
                }
                return notificationMessage;
        }
    }

    /**
     * Searches and replaces the placeholder {{appArea}} with the correct app area.
     * @param text - Text to prefix
     * @param appArea - area array
     * @private
     */
    private setOfferPrefix(text: string, appArea: Array<OffersAppAreaEnum>): string {
        const replaceTerm = appArea.includes(OffersAppAreaEnum.SANACORP) ? 'Sanacorp' : 'mea';
        return text.replace('{{appArea}}', replaceTerm);
    }

    private getReminderLabel = (notification) => {
        const prefix = NotificationReminderTypeLabels[notification.type] || '' + ': ';
        const message  = (notification.data && notification.data.title) ? notification.data.title : notification.message;
        return  prefix + ' ' + message;
    }

    /**
     * User clicked a single notification
     *
     * @param event - The click event
     * @param id - The notification id
     * @param type - The notification type
     */
    private async onNotificationClick(event, id, type) {
        if (event.target.className.includes('clickable')) {
            return;
        }
        switch(type) {
            case NotificationsReminderTypeEnum.APPOINTMENT_REMINDER:
            case NotificationsReminderTypeEnum.SEMINAR_REGISTRATION_REMINDER:
                await this.presentNotificationReminderModal(id, type);
                break;
            default:
                await this.presentNotificationModal(id, type);
        }
    }

    /**
     * Opens the notification modal
     *
     * @param notificationId - The notification id
     * @param type - The notification type
     * @param notification - The notification object
     */
    private async presentNotificationModal(notificationId, type, notification = null) {
        const modal = await this.modalService.create(NotificationViewComponent, {
            notificationId,
            notificationItem: notification,
            type,
            isUnreadButton: true
        });

        return await this.modalService.present(modal);
    }

    /**
     * Opens the notification modal
     *
     * @param notificationReminderID - The notification reminder id
     * @param notificationType - The notification reminder type
     */
    async presentNotificationReminderModal(notificationReminderID: number, notificationType: NotificationsReminderTypeEnum) {
        const modal = await this.modalService.create(
            NotificationReminderViewComponent,
            {
                notificationReminderId: notificationReminderID,
                notificationType,
                isUnreadButton: false
            }
        );

        return await this.modalService.present(modal);
    }

    /**
     * Redirects the user to the corresponding entry of the notification
     * @param notification
     * @private
     */
    private notificationGoToEntry(notification: NotificationInterface) {
        this.changeNotificationReadState(notification);
        const notificationObservable = this.notificationQueries.getNotificationById(
            notification.id
        ).subscribe(_notification => {
            if(_notification) {
                void this.notificationGoToEntryService.goToEntry(_notification, false);
                unsubscribe(notificationObservable);
            }
        });
    }

    /**
     * Update read status of notification
     *
     * @param notification - The notification object
     */
    private changeNotificationReadState(notification: NotificationInterface) {
        if (notification) {
            this.informationMutations.upsertNotificationReadStatus(notification.id, true);
        }
    }
}
