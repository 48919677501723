import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { ItemSearchInterface } from '../../../interfaces/item-search.interface';

const GetItemSearch = gql`
    query getItemSearch {
        PharmazieItemSearch {
            url
        }
    }
`;

export const AllItemSearchQueries = [
    GetItemSearch
];

@Injectable()
export class ItemSearchQueries {
    constructor(
        private apollo: Apollo
    ) { }

    /**
     * Returns the item search data
     */
    public getItemSearch(): Observable<ItemSearchInterface> {
        return this.apollo.watchQuery({
            query: GetItemSearch,
            fetchPolicy: 'network-only'
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['PharmazieItemSearch'])) as Observable<ItemSearchInterface>;
    }
}

