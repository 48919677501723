import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { take } from 'rxjs';
import { SafetyDatasheetQueries } from '../store/graphql/queries/safety-datasheet.graphql';
import { SafetyDatasheetMutations } from '../store/graphql/mutations/safety-datasheet.graphql';

import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root',
})
export class SafetyDatasheetsService {
    private toastService = inject(ToastService);

    private safetyDatasheetsQueries = inject(SafetyDatasheetQueries);
    private safetyDatasheetsMutations = inject(SafetyDatasheetMutations);


    private _safetyDataSheets$ = this.safetyDatasheetsQueries.getSafetyDataSheets();
    safetyDataSheets = toSignal(this._safetyDataSheets$);

    /**
     * Update the search string for the PZN list
     *
     * @param search - The search string
     */
    executePZNSearch(search: string) {
        this.safetyDatasheetsMutations.requestSafetyDataSheetPdf(search);
    }


    getSafetyDataSheetPdf(id: number): Promise<{result: number[], error: string}> {
        return new Promise((resolve) => {
            this.safetyDatasheetsQueries.getSafetyDataSheetPdf(id).pipe(
                take(1)
            ).subscribe(pdf => {
                if (pdf?.data?.length > 0) {
                    resolve({result: pdf.data.split(',').map(n => parseInt(n, 10)), error: null});
                } else {
                    this.toastService.presentError(
                        /*pdf?.message ?
                            pdf.message :*/
                            'Leider ist ein Fehler beim Abrufen der PDF aufgetreten. Bitte versuchen Sie es später erneut.'
                    ).then(() => resolve({result: [], error: pdf?.message}));
                }
            });
        });
    }
}
