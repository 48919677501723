import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'filePreviewImage'
})
export class FilePreviewImagePipe implements PipeTransform {
    transform(fileUrl: string, newExtension: string): string {

        if(!fileUrl) {
            return '';
        }

        // Check file extension
        const fileExtension = fileUrl.split('.').pop();
        switch(fileExtension) {
            case 'pdf':
                const urlParts = /^(https?:\/\/[^\/]+)(\/?.*)\.pdf$/i.exec(fileUrl);
                if(!urlParts) {
                    return ''; // Return empty string if the fileUrl is not valid
                }
                return `${urlParts[1]}/thumbnails${urlParts[2]}.${newExtension}`;
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'webp':
                return fileUrl;
            default:
                return '';
        }
    }
}
