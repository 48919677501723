import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import {
    AuthStorageKeyEnum,
    FetchPolicyKeys as FPK,
    DataChangedKeys as DCK,
    MeamindQuestionFilterEnum,
    QueryFetchPolicy
} from '../../../core.enums';

import {
    MeamindItemInterface,
    MeamindQuestionFilterInterface
} from '../../../interfaces/meamind.interface';
import { MeamindFilterDefault } from '../../../config/meamind-filters.config';
import { QueryWrapper } from '../query.wrapper';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';

const GetQuestions = (queryName) => gql`
    query ${queryName}($where: meamind_bool_exp, $limit: Int!, $offset: Int!) {
        meamind(where: $where, limit: $limit, offset: $offset, order_by: {created_at: desc}) {
            id
            title
            created_at
            solved
            numberOfAnswers
            numberOfComments
            tags {
                tag {
                    name
                }
            }
        }
    }
`;

const GetMyQuestions = (queryName) => gql`
    query ${queryName}($where: meamindByPharmacy_bool_exp, $limit: Int!, $offset: Int!) {
        meamindByPharmacy(where: $where, limit: $limit, offset: $offset, order_by: {created_at: desc}) {
            id
            title
            content
            created_at
            solved
            numberOfAnswers
            numberOfComments
            tags {
                tag {
                    name
                }
            }
        }
    }
`;

const GetQuestionsOfMyAnswers = (queryName) => gql`
    query ${queryName}($where: meamindAnswersByPharmacy_bool_exp, $limit: Int!, $offset: Int!) {
        meamindAnswersByPharmacy(where: $where, limit: $limit, offset: $offset, order_by: {created_at: desc}) {
            id
            title
            content
            solved
            created_at
            numberOfAnswers
            numberOfComments
            tags {
                tag {
                    name
                }
            }
        }
    }
`;

const GetQuestionById = (queryName) => gql`
    query ${queryName}($id: Int!) {
        meamind_by_pk(id: $id) {
            id
            type
            title
            content
            created_at
            solved
            isEdited
            referenceId
            tags {
                tag {
                    name
                }
            }
            pharmacyStore {
                apiUser
            }
            meamindAnswers {
                id
                title
                content
                type
                created_by
                created_at
                solved
                isEdited
                pharmacyStore {
                    apiUser
                }
                meamindAnswers {
                    id
                    title
                    content
                    type
                    created_by
                    created_at
                    isEdited
                    pharmacyStore {
                        apiUser
                    }
                }
            }
        }
    }
`;

const SearchQuestions = (queryName) => gql`
    query ${queryName}($search: String, $limit: Int, $offset: Int) {
        meamind_search(args: {search: $search, _limit: $limit, _offset: $offset}) {
            id
            title
            created_at
            solved
            tags {
                tag {
                    name
                }
            }
            numberOfAnswers
            numberOfComments
        }
    }
`;
const GenerateTags = gql`
    query GenerateTags($content : String!) {
        GenerateTags(content: $content) {
            tags
        }
    }
`;


export const AllMeamindQueries = [
    GetQuestions('test'),
    GetMyQuestions('test'),
    GetQuestionById('test'),
    GetQuestionsOfMyAnswers('test'),
    SearchQuestions('test'),
    GenerateTags
];

@Injectable()
export class MeamindQueries extends QueryWrapper {

    fetchPolicies = {
        [FPK.getQuestions]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getMyQuestions]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getQuestionById]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getQuestionsOfMyAnswers]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.searchQuestions]: QueryFetchPolicy.NETWORK_ONLY
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar) {
        super(apollo, dataChangedVar, {
            [DCK.meamindChanged]:[
                    FPK.getQuestions,
                    FPK.getMyQuestions,
                    FPK.getQuestionById,
                    FPK.getQuestionsOfMyAnswers,
                    FPK.searchQuestions
            ]
        });
    }


    /**
     * Returns all sorting fields
     *
     * @param limit - Maximum number of items to return
     * @param offset - Offset for pagination
     * @param filter - Filter for questions
     * @param search - Search string
     */
    public getQuestions(
        limit: number = 80,
        offset: number = 0,
        filter: MeamindQuestionFilterInterface = MeamindFilterDefault,
        search: string = '',
    ): Observable<MeamindItemInterface[]> {
        let fetchPolicyKey: FPK;
        const variables: {
            where?: any,
            search?: string,
            limit: number,
            offset: number
        } = {
            where: {type:{_eq: 'question'}},
            limit,
            offset
        };

        // Filter for solved/unsolved questions
        if(filter.filters.indexOf(MeamindQuestionFilterEnum.solved) !== -1) {
            variables.where['solved'] = {_eq:true};
        } else if(filter.filters.indexOf(MeamindQuestionFilterEnum.unsolved) !== -1) {
            variables.where['solved'] = {_eq:false};
        }

        if (search && search.trim() !== '') {
            delete variables.where;
            variables.search = search;
            fetchPolicyKey = FPK.searchQuestions;
            return this.apollo.watchQuery({
                query: SearchQuestions(fetchPolicyKey),
                variables,
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
            })
                .valueChanges
                .pipe(map(d => d?.data && d?.data['meamind_search']))
                .pipe(map(d => d && d.map(
                    question => ({
                        ...question,
                        tags: question?.tags?.map(tag => tag.tag.name)
                    })
                )))
                .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<MeamindItemInterface[]>;
        }

        // Filter by own pharmacy answers
        if(filter.filters.indexOf(MeamindQuestionFilterEnum.mySolutions) !== -1) {
            fetchPolicyKey = FPK.getQuestionsOfMyAnswers;
            return this.apollo.watchQuery({
                query: GetQuestionsOfMyAnswers(fetchPolicyKey),
                variables,
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['meamindAnswersByPharmacy']))
            .pipe(map(questions => questions?.map(question => ({
                ...question,
                tags: question?.tags?.map(tag => tag.tag.name)
            }))))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<MeamindItemInterface[]>;
        }


        // Filter by own pharmacy questions
        if(filter.filters.indexOf(MeamindQuestionFilterEnum.myQuestions) !== -1) {
            fetchPolicyKey = FPK.getMyQuestions;
            return this.apollo.watchQuery({
                query: GetMyQuestions(fetchPolicyKey),
                variables,
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['meamindByPharmacy']))
            .pipe(map(questions => questions?.map(question => ({
                ...question,
                tags: question?.tags?.map(tag => tag.tag.name)
            }))))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<MeamindItemInterface[]>;
        }

        // Search for all questions
        fetchPolicyKey = FPK.getQuestions;
        return this.apollo.watchQuery({
                query: GetQuestions(fetchPolicyKey),
                variables,
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['meamind']))
            .pipe(map(questions => questions?.map(question => ({
                ...question,
                tags: question?.tags?.map(tag => tag.tag.name)
            }))))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<MeamindItemInterface[]>;
    }

    /**
     * Returns the details of a question
     */
    public getQuestionById(id: number): Observable<MeamindItemInterface> {
        const fetchPolicyKey = FPK.getQuestionById;
        return this.apollo.watchQuery({
                query: GetQuestionById(fetchPolicyKey),
                variables: {id},
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['meamind_by_pk']))
            .pipe(map(question => {
                const apiUser = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
                return question && ({
                        ...question,
                        isMyItem: question?.pharmacyStore?.apiUser === apiUser,
                        meamindAnswers: question?.meamindAnswers && Array.isArray(question.meamindAnswers) ? [
                            ...question?.meamindAnswers.map(meaAnswer => (
                                {
                                    ...meaAnswer,
                                    isMyItem: meaAnswer?.pharmacyStore?.apiUser === apiUser,
                                    meamindAnswers: [
                                        ...meaAnswer?.meamindAnswers.map(meaComment => (
                                            {
                                                ...meaComment,
                                                isMyItem: meaComment?.pharmacyStore?.apiUser === apiUser
                                            }
                                        ))
                                    ]
                                }
                            ))
                        ] : []
                    });
                }
            ))
            .pipe(map(question => question && ({
                ...question,
                tags: question?.tags?.map(tag => tag.tag.name)
            })))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<MeamindItemInterface>;
    }

    /**
     * Returns a list of tags
     */
    public generateTags(content: string): Observable<any> {
        return this.apollo.watchQuery({
                query: GenerateTags,
                variables: {content},
                fetchPolicy: QueryFetchPolicy.NETWORK_ONLY
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['GenerateTags'] && d?.data['GenerateTags']['tags']));
    }
}

