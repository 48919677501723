import { gql } from '@apollo/client/core';
import { from, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { DateRangeOptionCodes, DataChangedKeys as DCK } from '../../../core.enums';
import { ReturnsFiltersInterface } from '../../../interfaces/returns.interface';
import { ApiService } from '../../../services/api.service';
import { DataChangedForceStateVar } from '../../locals/dataChangeForceState.var';

export const UpsertReturnsFiltersMutation = gql`
    mutation insertReturnsFiltersMutation($filters: [returnsFilters_insert_input!]!) {
        insert_returnsFilters(
            objects: $filters,
            on_conflict: {
                constraint: returnsFilters_userId_key,
                update_columns: [
                    dateOption
                    dateFrom
                    dateTo
                    deliveryNoteDateOption
                    deliveryNoteDateFrom
                    deliveryNoteDateTo
                    deliveryNoteNumber
                    producer
                    pzn
                    search
                    status
                    type
                ]
            }
        ) {
            affected_rows
        }
    }
`;

export const AllReturnsMutations = [
    UpsertReturnsFiltersMutation
];

@Injectable()
export class ReturnsMutations {
    constructor(
        private apollo: Apollo,
        private apiService: ApiService,
        private dataChangedForceState: DataChangedForceStateVar
    ) {}


    static _normalizeFilters(normalizedFilters) {
        // reformat empty strings to null
        if(normalizedFilters['dateFrom'] && normalizedFilters['dateFrom'] === '') {
            normalizedFilters['dateFrom'] = null;
        }
        if(normalizedFilters['dateTo'] && normalizedFilters['dateTo'] === '') {
            normalizedFilters['dateTo'] = null;
        }

        // reformat all date options but individual to dateFrom/To to null
        if (normalizedFilters['dateOption'] && normalizedFilters['dateOption'] !== DateRangeOptionCodes.individual) {
            normalizedFilters['dateFrom'] = null;
            normalizedFilters['dateTo'] = null;
        }

        return normalizedFilters;
    }

    /**
     * trim values
     * @param data - Data to trim (string or null)
     * @return string|null
     */
    formatInputs = (data) => {
        if (typeof data === 'string') {
            return data.trim().length > 0 ? data.trim() : null;
        }
        return data;
    }

    upsertReturnsFilters(filters: ReturnsFiltersInterface) {
        void this.dataChangedForceState.setForceState({[DCK.returnsFilterChanged]: null});
        Object.keys(filters).forEach(key => {
            filters[key] = this.formatInputs(filters[key]);
        });
        this.apollo.mutate({
            mutation: UpsertReturnsFiltersMutation,
            variables: {filters: ReturnsMutations._normalizeFilters(filters)}
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['insert_returnsFilters'] && d?.data['insert_returnsFilters']['affected_rows']))
                  .subscribe((affectedRows: number) => {
                      if (affectedRows <= 0) {
                          void this.apiService.presentErrorToast(result['errors'], 'Die Filter konnten nicht gesetzt werden.');
                      }
                  });
            },
            error: error => this.apiService.presentErrorToast(error, 'Die Filter konnten nicht gesetzt werden.')
        });
    }
}
