export enum AuthStorageKeyEnum {
    accessToken = 'sconnect_access_token',
    refreshToken = 'sconnect_refresh_token',
    expiresAt = 'sconnect_expires_at',
    activePharmacy = 'sconnect_active_pharmacy',
    meamindAccessToken = 'meamind_access_token',
    meamindRefreshToken = 'meamind_refresh_token',
    meamindExpiresAt = 'meamind_expires_at'
}

