<app-modal  i18n-title title="{{titleOrderType}} {{details() ? details().orderIdOrg + ' ' : ''}}{{details() && details().productName}}" >
    <ng-container headerActions></ng-container>
    <ng-container content  *ngIf="details()">
        <ion-grid class="ion-no-padding">
            <ion-row class="ion-no-padding form">
                <ion-col size="16" class="ion-padding">
                    <ion-list>
                        <ion-item class="background-input" *ngIf="details().expectedDelivery">
                            <ion-input
                                i18n-label
                                label="Lieferung"
                                class="gray full-opacity"
                                disabled="true"
                                [value]="details().expectedDelivery"
                            ></ion-input>
                        </ion-item>
                        <ion-item class="background-input" *ngIf="notesById()">
                            <ion-textarea
                                i18n-label
                                aria-label="Interne Notiz"
                                label="Interne Notiz"
                                class="large gray full-opacity"
                                disabled="true"
                                [value]="notesById().note"
                            ></ion-textarea>
                        </ion-item>
                        <ion-item class="background-input" *ngIf="details().status === deliveryStatus.CANCELLED && details().cancellationReason && details().cancellationReason.trim().length > 0">
                            <ion-label class="label-fixed" slot="start" i18n>Stornogrund</ion-label>
                            <div class="ion-textarea-container disabled">
                                <div>{{details().cancellationReason}}</div>
                            </div>
                        </ion-item>
                        <ion-item class="background-input" *ngIf="details().orderDeliveries && details().orderDeliveries.length > 0">
                            <ion-label class="ion-align-self-start label-fixed margin-big" slot="start" i18n>Lieferungen</ion-label>
                            <ion-grid class="ion-no-padding ion-grid-container disabled table-border">
                                <ion-row class="ion-align-items-center">
                                    <ion-col size="{{isProduction ? 10 : 9}}" class="ion-text-left ion-padding-start ion-padding-bottom-half ion-padding-top-half">Zeitraum</ion-col>
                                    <ion-col size="4" class="ion-text-center ion-padding-bottom-half ion-padding-top-half" i18n>Menge</ion-col>
                                    <ion-col size="5" class="ion-text-left ion-padding-bottom-half ion-padding-top-half" i18n>Lieferschein</ion-col>
                                    <ion-col size="5" class="ion-text-center ion-padding-bottom-half ion-padding-top-half" i18n>Status</ion-col>
                                    <ion-col size="1" class="ion-text-center ion-padding-bottom-half ion-padding-top-half" *ngIf="!isProduction" i18n></ion-col>
                                </ion-row>
                                <ion-row *ngFor="let delivery of details().orderDeliveries | orderBy:'deliveryId'; let i = index trackBy:trackBy" class="ion-align-items-center">
                                    <ion-col size="{{isProduction ? 10 : 9}}" class="ion-text-left ion-padding-start ws-normal">{{delivery['expectedDelivery']}}</ion-col>
                                    <ion-col size="4" class="ion-text-center" i18n>{{delivery['quantity']}}</ion-col>
                                    <ion-col size="5" class="ion-text-left" i18n>{{delivery['deliveryNoteNumber'] | documentNumberFormatted}}</ion-col>
                                    <ion-col size="5" class="ion-text-center" i18n><app-badge [code]="delivery['status']" class="ion-justify-content-center"></app-badge></ion-col>
                                    <ion-col size="1" class="ion-text-center" *ngIf="!isProduction" i18n>
                                        <ion-icon name="ellipsis-vertical" mode="md" class="clickable"
                                             (click)="presentDeliveryPopover($event, delivery['deliveryId'])"
                                             size="small"></ion-icon>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </ion-item>
                        <ion-item class="background-input" *ngIf="details().price || details().extraCosts">
                            <ion-label class="ion-align-self-start label-fixed margin-big" slot="start" i18n>voraussichtliche Kosten*</ion-label>
                            <div>
                                <ion-grid class="ion-no-padding table ion-grid-container disabled table-border">
                                    <ion-row class="ion-align-items-left">
                                        <ion-col appTooltip ttk="order_price" ttp="top"
                                                size="6" class="ion-text-left ion-padding-bottom-half ion-padding-top-half" i18n>Einzelpreis*</ion-col>
                                        <ion-col appTooltip ttk="order_extraCosts" ttp="top"
                                                size="auto" class="ion-text-left ion-padding-bottom-half ion-padding-top-half" i18n>Zusatzkosten des Lieferanten</ion-col>
                                    </ion-row>
                                    <ion-row class="ion-align-items-left">
                                        <ion-col size="6" class="ion-text-left" i18n>{{details().price === null ? 'nicht bekannt' : details().price | formatCurrency}}</ion-col>
                                        <ion-col size="auto" class="ion-text-left" i18n>{{details().extraCosts === null ? 'keine oder nicht bekannt ' : details().extraCosts | formatCurrency}}</ion-col>
                                    </ion-row>
                                </ion-grid>
                                <span>
                                    * falls es sich um einen Einzelimport aus dem Ausland nach §73 Abs.3 AMG handelt, kann es vorkommen, dass der hier vorausgesagte Einzelpreis bei der tatsächlichen Berechnung korrigiert werden muss.
                                    Dies hängt mit dem Beschaffungsprozess und möglichen Kursschwankungen zusammen.
                                </span>
                            </div>
                        </ion-item>
                        <ion-item class="background-input" *ngIf="details().orderAuditLogs">
                            <ion-label class="ion-align-self-start label-fixed" slot="start" i18n>Historie</ion-label>
                            <ion-text class="history-item-text ion-text-container disabled">
                                <ng-container *ngFor="let group of auditLogs">
                                    <p class="group-title"><span>{{group.title}}</span></p>
                                    <p *ngFor="let log of group.items" [class.highlight]="highlightedAuditLog && (highlightedAuditLog.id === log.id)">
                                        <strong>{{log['created_at'] | formatDateTimeToDateTime : true}}</strong> - {{(log.text | auditLogLabel) | replacePayload:log.payload:details().orderIdOrg}}
                                    </p>
                                </ng-container>
                            </ion-text>
                        </ion-item>
                    </ion-list>
                </ion-col>
                <ion-col size="8" class="ion-no-padding">
                    <app-sidebar [id]="'order-view-sidebar'" [data]="sidebar" [hasHeader]="false" [hasFooter]="false"></app-sidebar>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ng-container>
    <!-- Footer -->
    <ng-container footer *ngIf="details() && tooltips">
        <ion-button
                [class.disabled]="!availableActions[buttonTypes.CANCEL]"
                appTooltip
                ttk="{{tooltips[buttonTypes.CANCEL]}}"
                ttp="left"
                ttColor="{{tooltipColorScheme}}"
                i18n
                class="button-tertiary"
                (click)="availableActions[buttonTypes.CANCEL] ? onCancelButtonClick() : null"
        >Stornieren</ion-button>
        <ion-button
                i18n
                [class.disabled]="!availableActions[buttonTypes.COPY]"
                appTooltip
                ttk="{{tooltips[buttonTypes.COPY]}}"
                ttp="left"
                class="button-tertiary"
                (click)="availableActions[buttonTypes.COPY] ? onCopyButtonClick() : null"
        >Nachbestellen</ion-button>
        <ion-button
                i18n
                [class.disabled]="!availableActions[buttonTypes.EXTEND]"
                appTooltip
                ttk="{{tooltips[buttonTypes.EXTEND]}}"
                ttp="left"
                class="button-tertiary"
                (click)="availableActions[buttonTypes.EXTEND] ? onExtensionButtonClick() : null"
        >Verlängern</ion-button>
        <ion-button
                i18n
                [class.disabled]="!availableActions[buttonTypes.ACCEPT]"
                color="{{isAcknowledgeAvailable ? 'primary' : ''}}"
                appTooltip
                ttk="{{tooltips[buttonTypes.ACCEPT]}}"
                ttp="left"
                class="button-tertiary"
                (click)="availableActions[buttonTypes.ACCEPT] ? onAcknowledgeDispoClick() : null"
        >Ware angekommen</ion-button>
        <ion-button
                i18n
                [class.disabled]="!availableActions[buttonTypes.SUPPORT]"
                appTooltip
                ttk="{{tooltips[buttonTypes.SUPPORT]}}"
                ttp="left"
                class="button-tertiary"
                (click)="availableActions[buttonTypes.SUPPORT] ? onSupportButtonClick() : null"
        >Kundenservice kontaktieren</ion-button>
        <ion-button
                i18n
                appTooltip
                ttk="order_note_button"
                ttp="left"
                (click)="onRecordNoteClick()"
                class="button-tertiary">Interne Notiz
        </ion-button>
    </ng-container>
</app-modal>
