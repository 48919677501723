import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ToolbarStateInterface } from '../../interfaces/menu-state.interface';

export const GetToolbarState = gql`
    query GetToolbarState {
        toolbarState @client {
            pageInformationOrders
            pageInformationReturns
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class ToolbarStateVar {

    constructor(apollo: Apollo) {
        this.apollo = apollo;
    }
    private defaultValue: ToolbarStateInterface = {
        pageInformationOrders: null,
        pageInformationReturns: null
    };
    private toolbarStateObservable: Observable<ToolbarStateInterface> = new Observable<ToolbarStateInterface>();
    private apollo: Apollo;
    private cache;

    static formatPageInformation(dataCount) {
        let pageSubtitle = null;
        if (dataCount) {
            switch (dataCount) {
                case 0:
                    pageSubtitle = '';
                    break;
                case 1:
                    pageSubtitle = dataCount.toString() + ' Ergebnis gefunden';
                    break;
                default:
                    pageSubtitle = dataCount.toString() + ' Ergebnisse gefunden';
                    break;
            }
        }
        return pageSubtitle;
    }

    init() {
        if(this.apollo.client) {
            this.cache = this.apollo.client.cache;
            this.toolbarStateObservable = new Observable<ToolbarStateInterface>(subscriber => {
                this.cache.watch({
                    query: GetToolbarState,
                    callback: res => {
                        subscriber.next(res.result && res.result.toolbarState || this.defaultValue);
                    },
                    immediate: true,
                    optimistic: true,
                });
            });
        }
    }

    get(): Observable<ToolbarStateInterface> {
        this.init();
        return this.toolbarStateObservable;
    }

    getViaPromise() {
        return new Promise<ToolbarStateInterface>((resolve) => {
            this.init();
            if (this.cache) {
                this.cache.watch({
                    query: GetToolbarState,
                    callback: res => {
                        resolve(res.result && res.result.toolbarState || this.defaultValue);
                    },
                    immediate: true,
                    optimistic: true,
                });
            } else {
                resolve(null);
            }
        });
    }

    set(toolbarState: ToolbarStateInterface) {
        this.init();
        if (this.cache) {
            this.cache.writeQuery({
                query: GetToolbarState,
                data: toolbarState
            });
        }
    }

    async setPageInformation(orderCount = null, returnsCount = null) {
        this.init();
        const oldToolbarData = await this.getViaPromise();
        if (this.cache && oldToolbarData) {
            const pageInformationOrders = orderCount !== null ?
              ToolbarStateVar.formatPageInformation(orderCount) :
              oldToolbarData.pageInformationOrders;
            const pageInformationReturns = returnsCount !== null ?
              ToolbarStateVar.formatPageInformation(returnsCount) :
              oldToolbarData.pageInformationReturns;
            this.cache.writeQuery({
                query: GetToolbarState,
                data: {
                    toolbarState: {
                        ...oldToolbarData,
                        pageInformationOrders,
                        pageInformationReturns,
                    }
                }
            });
        }
    }
}
