import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PdfService {

    constructor(
    ) { }

    /**
     * Converts byte array to base 64
     *
     * @param document - Byte Array
     */
    private static byteArray2Base64(document: number[]) : string {
        let pdfAsBase64    = '';
        const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

        const bytes         = new Uint8Array(document);
        const byteLength    = bytes.byteLength;
        const byteRemainder = byteLength % 3;
        const mainLength    = byteLength - byteRemainder;

        let a, b, c, d;
        let chunk;

        // Main loop deals with bytes in chunks of 3
        for (let i = 0; i < mainLength; i = i + 3) {
            // Combine the three bytes into a single integer
            chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

            // Use bitmasks to extract 6-bit segments from the triplet
            a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
            b = (chunk & 258048)   >> 12; // 258048   = (2^6 - 1) << 12
            c = (chunk & 4032)     >>  6; // 4032     = (2^6 - 1) << 6
            d = chunk & 63;               // 63       = 2^6 - 1

            // Convert the raw binary segments to the appropriate ASCII encoding
            pdfAsBase64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
        }

        // Deal with the remaining bytes and padding
        if (byteRemainder === 1) {
            chunk = bytes[mainLength];

            a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

            // Set the 4 least significant bits to zero
            b = (chunk & 3)   << 4; // 3   = 2^2 - 1

            pdfAsBase64 += encodings[a] + encodings[b] + '==';
        } else if (byteRemainder === 2) {
            chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

            a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
            b = (chunk & 1008)  >>  4; // 1008  = (2^6 - 1) << 4

            // Set the 2 least significant bits to zero
            c = (chunk & 15)    <<  2; // 15    = 2^4 - 1

            pdfAsBase64 += encodings[a] + encodings[b] + encodings[c] + '=';
        }
        return pdfAsBase64;
    }

    /**
     * Converts base 64 to blob
     *
     * @param document - Document as base64
     * @param contentType - Document content type
     * @param sliceSize - Slice size
     */
    private static base64toBlob(document, contentType = 'application/pdf', sliceSize = 512) : Blob {
        const byteCharacters = atob(document);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            byteArrays.push(new Uint8Array(byteNumbers));
        }

        return new Blob(byteArrays, {type: contentType});
    }

    /**
     * Prints the pfd as integer array
     *
     * @param pdf - Pdf as integer array
     * @param newTab - New tab
     */
    static print(pdf: number[], newTab) {
        const pdfAsBase64 = PdfService.base64toBlob(PdfService.byteArray2Base64(pdf));
        newTab.location = URL.createObjectURL(pdfAsBase64);
    }

    /**
     * Prints the pfd as integer array
     *
     * @param pdf - Pdf as integer array
     * @param filename - Filename of the document
     */
    download(pdf: number[], filename) {
        const pdfAsBase64 = PdfService.base64toBlob(PdfService.byteArray2Base64(pdf));
        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(pdfAsBase64);
        link.classList.add('matomo_ignore');
        link.download = filename;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    }
}
