import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input
} from '@angular/core';
import { BulkActionCodeEnum } from '../../../enums/bulk-action.enum';
import { BulkModeVar } from '../../../store/locals/bulk-mode.var';
import { ToastService } from '../../../services/toast.service';

@Component({
    selector: 'app-bulk-td',
    templateUrl: './bulk-td.component.html',
    styleUrls: ['./bulk-td.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkTdComponent {
    private bulkModeVar = inject(BulkModeVar);
    private toastService = inject(ToastService);
    protected readonly BulkActionCodeEnum = BulkActionCodeEnum;

    @Input() row: any;
    @Input() bulkSelectionCountMax = 30;

    bulkModeActive = this.bulkModeVar.isActive;
    bulkSelection = this.bulkModeVar.selectedItems;
    bulkSelectAction = this.bulkModeVar.selectedAction;


    /**
     * User clicked this element
     * @param event - click event
     * @param id - id
     */
    onBulkItemClicked(event, id: number) {
        if(id) {
            this.bulkModeVar.updateSelection(id, event.target.checked);

            if (this.bulkSelection().length >= this.bulkSelectionCountMax) {
                void this.toastService.presentNotice(`Es können maximal ${this.bulkSelectionCountMax} Aufträge gleichzeitig bearbeitet werden.`);
            }
        }

        // this.setSelectedBulkOrders(orderId, checked);
    }
}
