import { Pipe, PipeTransform } from '@angular/core';
import { MediaInterface } from '../interfaces/banner.interface';
import { BannerScreenMinWidthEnum, BannerSizeEnum } from '../enums/banner.enum';


@Pipe({
    name: 'mediaToSrcset'
})
export class MediaToSrcsetPipe implements PipeTransform {
    transform(
        media: MediaInterface[]
    ): {
        srcset: string;
        minWidth: string;
    }[] {
        let srcsetConfig = [];
        if (media?.length > 0) {
            Object.keys(BannerSizeEnum).forEach(size => {
                const url = media.find(it => it.size.toUpperCase() === BannerSizeEnum[size].toUpperCase())?.image?.url;
                if (url) {
                    if (!srcsetConfig) {
                        srcsetConfig = [];
                    }
                    srcsetConfig.push({
                        srcset: url,
                        minWidth: `(min-width: ${BannerScreenMinWidthEnum[size]}px)`
                    });
                }
            });
            if (srcsetConfig?.length > 0) {
                srcsetConfig = srcsetConfig.sort((a, b) => {
                    return BannerScreenMinWidthEnum[a.minWidth] - BannerScreenMinWidthEnum[b.minWidth];
                });
            }
        }
        return srcsetConfig;
    }
}
