import { ButtonInputInterface } from '../interfaces/button-action.interface';
import { ActionButtonTypesEnum } from '../enums/action-button-types.enum';

export const ToolbarActionButtons: Array<ButtonInputInterface> = [
    {code: ActionButtonTypesEnum.NEW_ORDER_REQUEST,             label: 'Dispositionsanfrage',           isDisabled: false},
    {code: ActionButtonTypesEnum.NEW_RETURN_REQUEST,            label: 'Retoure anmelden',              isDisabled: false},
    {code: ActionButtonTypesEnum.NEW_APPOINTMENT,               label: 'Termin',                        isDisabled: false},
    {code: ActionButtonTypesEnum.NEW_MEAMIND_QUESTION,          label: 'meamind-Experten fragen',       isDisabled: false},
    {code: ActionButtonTypesEnum.NEW_COMMUNICATION_ZONE_ITEM,   label: 'Kundenservice kontaktieren',    isDisabled: false}
];

export const OrderHeaderActionButtons: Array<ButtonInputInterface> = [
    ToolbarActionButtons.find(item => item.code === ActionButtonTypesEnum.NEW_ORDER_REQUEST),
    ToolbarActionButtons.find(item => item.code === ActionButtonTypesEnum.NEW_RETURN_REQUEST),
];
