import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, Observable, take, tap } from 'rxjs';
import { StatisticsMutations } from '../../store/graphql/mutations/statistics.graphql';
import { BusinessFigureLoadingInterface } from '../../interfaces/statistics.interface';
import { BusinessFigureQueries } from '../../store/graphql/queries/business-figure.graphql';
import { ToastService } from '../toast.service';
import { BusinessFigurePdfTypeEnum } from '../../enums/statistics.enum';

@Injectable({
    providedIn: 'root',
})
export class AssortmentService {
    private isLoadingNewPdf$: Array<BehaviorSubject<boolean>> = [];
    private pdfTypes = Object.keys(BusinessFigurePdfTypeEnum).map(key => BusinessFigurePdfTypeEnum[key]);
    constructor(
        private statisticsMutations: StatisticsMutations,
        private statisticsQueries: BusinessFigureQueries,
        private toastService: ToastService
    ) {
        this.pdfTypes.forEach(type => {
            this.isLoadingNewPdf$[type] = new BehaviorSubject<boolean>(false);
        });
    }

    getLoadingStatus(type: BusinessFigurePdfTypeEnum): Observable<Array<BusinessFigureLoadingInterface>> {
        return this.statisticsQueries.getBusinessFigureLoading(type).pipe(
            distinctUntilChanged(),
            tap(loadingStatus => {
                if (loadingStatus?.length > 0) {
                    this.isLoadingNewPdf$[type].next(false);
                }
            })
        );
    }

    getBusinessFigureSubscriptions() : Observable<Array<BusinessFigurePdfTypeEnum>> {
        return this.statisticsQueries.getBusinessFigureSubscriptions().pipe(
            map((data: any[]) => data.map(item => item.subType))
        );
    }

    insertBusinessFigureSubscriptions(types: BusinessFigurePdfTypeEnum[]) {
        this.statisticsMutations.insertBusinessFigureSubscriptions(types);
    }

    deleteBusinessFigureSubscription(types: BusinessFigurePdfTypeEnum[]) {
        this.statisticsMutations.deleteBusinessFigureSubscriptions(types);
    }

    getIsLoadingNewPdf(type: BusinessFigurePdfTypeEnum): Observable<boolean> {
        return this.isLoadingNewPdf$[type].asObservable();
    }

    getPdf(id: number): Promise<{result: number[], error: string}> {
        return new Promise((resolve) => {
            this.statisticsQueries.getBusinessFigurePdf(id).pipe(
                take(1)
            ).subscribe(pdf => {
                if (pdf?.data?.length > 0) {
                    resolve({result: pdf.data.split(',').map(n => parseInt(n, 10)), error: null});
                } else {
                    this.toastService.presentError(
                        pdf?.message ?
                            pdf.message :
                            'Leider ist ein Fehler beim Abrufen der PDF aufgetreten. Bitte versuchen Sie es später erneut.'
                    ).then(() => resolve({result: [], error: pdf?.message}));
                }
            });
        });
    }

    requestBusinessFigurePdf(type: string, fromDate: string, toDate: string, producerId: number = null) {
        this.isLoadingNewPdf$[type].next(true);
        this.statisticsMutations.requestBusinessFigurePdf(type, fromDate, toDate, producerId);
    }
}
