import gql from 'graphql-tag';

export default gql`
  mutation deleteConversation($conversationId: ID!) {
    deleteConversation(id: $conversationId) {
      id
      deletionRecord {
        cognitoId
        deletedAt
      }
    }
  }
`;
