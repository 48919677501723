import { Injectable } from '@angular/core';
import Autolinker from 'autolinker';

@Injectable({
  providedIn: 'root',
})
export class AutolinkerService {
  phoneNumberRegex = /[+0][\d \-()]{6,}[\d)]/gm;
  phoneNumberNegativeLookbehind = /[\d-]/gm;

  link(value: string): string | undefined {
    if (!value) {
      return undefined;
    }
    let msg: string = Autolinker.link(value, {
      urls: true,
      email: true,
      phone: false,
      newWindow: true,
      stripPrefix: false,
    });
    const matches = [];
    let match = this.phoneNumberRegex.exec(msg);
    while (match !== null) {
      matches.push(match);
      match = this.phoneNumberRegex.exec(msg);
    }
    for (let a = matches.length - 1; a >= 0; a--) {
      const matchAtIndex = matches[a] as RegExpExecArray;
      const before = msg.substring(matchAtIndex.index - 1, matchAtIndex.index);

      if (this.phoneNumberNegativeLookbehind.exec(before) === null) {
        msg = this.replaceAt(
          msg,
          matchAtIndex.index,
          matchAtIndex.index + (matchAtIndex[0]?.length as number),
          `<a target="_blank" rel="noopener noreferrer" href="tel:${matchAtIndex[0]}">${matchAtIndex[0]}</a>`
        );
      }
    }
    return msg;
  }

  private replaceAt = (string: string, start: number, end: number, replace: string) =>
    string.substring(0, start) + replace + string.substring(end);
}
