import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IonicSlides, ModalController } from '@ionic/angular';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { SurveyService } from '../../core.services';
import {
    MultipleChoiceSurveyInterface,
    SurveyInterface
} from '../../interfaces/survey.interface';
import { SurveyOtherAnswerId, SurveyQuestionTypeEnum } from '../../enums/survey.enum';
import { ModalClassEnum } from '../../enums/modal-class.enum';

@Component({
    selector: 'app-survey-modal',
    templateUrl: './survey-modal.component.html',
    styleUrls: ['./survey-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyModalComponent implements OnInit {
    static modalClass = ModalClassEnum.medium;

    @Input({required: true}) surveyId: number;

    @ViewChild('surveySwiper') surveySlider: ElementRef;


    survey$: Observable<SurveyInterface>;
    currentSlideIndex$ = new BehaviorSubject<string>('0');

    answerTracker$: Observable<boolean[]>;
    swiperModules = [IonicSlides];
    questionTypes = SurveyQuestionTypeEnum;
    otherAnswerId = SurveyOtherAnswerId.other;

    isIntroductionShort: boolean = false;

    constructor(
        private surveyService: SurveyService,
        private modalController: ModalController
    ) {
    }

    ngOnInit() {
        this.survey$ = this.surveyService.getSurveyById(this.surveyId).pipe(
            tap((survey) => {
                if (survey) {
                    const wordCount = survey.introduction.split(' ').length;
                    this.isIntroductionShort = wordCount < 80;
                }
            })
        );

        this.answerTracker$ = this.surveyService.getAnswerTracker();

    }

    slidePrev() {
        this.surveySlider?.nativeElement?.swiper.slidePrev();
    }

    slideNext() {
        this.surveySlider?.nativeElement?.swiper.slideNext();
    }

    onSlideChange() {
        this.currentSlideIndex$.next(this.surveySlider?.nativeElement?.swiper.activeIndex.toString());
    }

    onAnswerSelected(
        isChecked: boolean,
        surveyId: number,
        question: MultipleChoiceSurveyInterface,
        answerId: number,
        slideIndex: string
    ) {
        this.surveyService.setAnswerSelected(question.id, answerId, isChecked, question.isMultipleChoice, Number(slideIndex));
    }

    onFreeTextInput(questionId: number, text: string, type: SurveyQuestionTypeEnum, slideIndex: string, answerId?: number) {
        this.surveyService.setAnswerFreeText(questionId, answerId, text, type, Number(slideIndex));
    }

    submitSurvey() {
        this.surveyService.submitSurvey();
        void this.modalController.dismiss();
    }
}
