import { Injectable } from '@angular/core';

import { AuthStorageKeyEnum } from '../core/enums/authStorageKey.enum';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {
    isLoggedIn = false;

    constructor() { }


    /**
     * Activates the route only if the user has provided some login data
     */
    canActivate(): Promise<boolean> {
        return new Promise(resolve => {
            const token = localStorage.getItem(AuthStorageKeyEnum.accessToken);
            resolve(token && token.trim().length > 0);
        });
    }
}
