
<ng-container *ngTemplateOutlet="appointment?.lockedBySanacorp ? sanacorpAppointment : regularAppointment">
</ng-container>

<ng-template #regularAppointment>
    <app-modal i18n-title title="{{appointment ? 'Termin bearbeiten' : 'Termin erstellen'}}"
               *ngIf="(!appointment || (dataLoaded && appointment))">


    <ng-container headerActions></ng-container>
    <ng-container content *ngIf="appointmentId && !appointment">
        <ion-spinner name="crescent"></ion-spinner>
    </ng-container>
    <ng-container content *ngIf="appointmentFormGroup">
        <div class="form-container">
            <form [formGroup]="appointmentFormGroup" (ngSubmit)="saveAppointment(appointmentFormGroup.value)" class="form">
                <ion-item class="background-input"  appTooltip ttp="bottom" ttk="appointment_title">
                    <ion-input i18n-label
                               type="text"
                               formControlName="title"
                               placeholder="Kurzbeschreibung"
                               label="Titel"
                               class="full-opacity"
                               [ngClass]="{
                                    'invalid': appointmentFormGroup.controls.title?.dirty | formInputIsInvalid : appointmentFormGroup.controls.title?.valid,
                                    'required': !appointment,
                                   }"
                               [placeholder]="tooltips.appointment_title">
                    </ion-input>
                </ion-item>
                <ion-item class="background-input"  appTooltip ttp="bottom" ttk="appointment_description">
                    <ion-textarea
                            i18n-label
                            type="text"
                            formControlName="description"
                            class="large"
                            [ngClass]="{
                            'invalid': appointmentFormGroup.controls.description?.dirty | formInputIsInvalid : appointmentFormGroup.controls.description?.valid,
                        }"
                            label="Beschreibung"
                            aria-label="Beschreibung"
                            [placeholder]="tooltips.appointment_description"
                            rows="5" auto-grow="true">
                    </ion-textarea>
                </ion-item>
                <ion-item class="background-input"  appTooltip ttp="bottom" ttk="appointment_datetime">
                    <ion-label class="label-container" position="fixed" slot="start" i18n>Datum</ion-label>
                    <div class="ion-select-container">
                        <app-date-selection-widget
                                [title]=""
                                [dateRangeOptions]="dateRangeOption"
                                [selectedDateRangeId]="defaultDateRangeOption"
                                [defaultFromDate]="selectedFromDate"
                                [defaultToDate]="selectedToDate"
                                [defaultFromTime]="selectedFromTime"
                                [defaultToTime]="selectedToTime"
                                [displayDatesInsteadOfDateRange]="true"
                                [displayTime]="true"
                                [displayOnlyFutureDates]="false"
                                [displayOnlyPastDates]="false"
                                [displayIncludingToday]="true"
                                (datesEvent)="updateDatesAndTimes($event)">
                        </app-date-selection-widget>
                    </div>
                </ion-item>
                <ion-item class="background-input"  appTooltip ttp="bottom" ttk="appointment_reminder" *ngIf="!appointment">
                    <ion-label class="h3 label-container" position="fixed" slot="start" i18n>Erinnerung</ion-label>
                    <div class="ion-select-container">
                        <ion-select (ionChange)="onReminderFilterChange($event)"
                                    aria-label="Erinnerung"
                                    [value]="selectedReminder.id"
                                    i18n-okText okText="Ok"
                                    i18n-cancelText cancelText="Abbruch"
                                    class="select-filter">
                            <ion-select-option *ngFor="let reminderOption of notificationReminderConfig; trackBy: trackBy"
                                               [value]="reminderOption.id">{{reminderOption.title}}</ion-select-option>
                        </ion-select>
                    </div>
                </ion-item>
            </form>
            <div class="required-fields" *ngIf="!appointment"><span class="required">*</span> Benötigte Felder</div>
        </div>
    </ng-container>
    <!-- Footer -->
    <ng-container footer *ngIf="appointmentFormGroup">
        <ion-button *ngIf="appointment" (click)="onAppointmentDeleteClick()" i18n class="button-tertiary">Termin löschen</ion-button>
        <ion-button
                id="{{matomoId}}"
                [disabled]="lockedWhileSaving"
                [class.disabled]="!appointmentFormGroup.valid"
                appTooltip
                ttk="{{!appointmentFormGroup.valid ? 'invalid_form' : null}}"
                ttp="left"
                (click)="appointmentFormGroup.valid ? saveAppointment(appointmentFormGroup.value) : null"
                i18n
        >Termin speichern</ion-button>
    </ng-container>
    </app-modal>
</ng-template>

<ng-template #sanacorpAppointment>
    <app-modal i18n-title title="Termin Sanacorp"
               [contentPadding]="false"
               *ngIf="(!appointment || (dataLoaded && appointment))">


    <ng-container headerActions></ng-container>
    <ng-container content *ngIf="appointmentId && !appointment">
        <ion-spinner name="crescent"></ion-spinner>
    </ng-container>
    <ng-container content>
        <div *ngIf="!appointment" class="ion-padding ion-margin display-flex ion-justify-content-center">
            <ion-spinner name="crescent"></ion-spinner>
        </div>
        <div *ngIf="!appointment" class="ion-padding ion-margin display-flex ion-justify-content-center">
            <ion-spinner name="crescent"></ion-spinner>
        </div>
        <ion-grid class="ion-no-padding appointment-content-grid" *ngIf="appointment">
            <ion-row class="ion-no-padding full-height">
                <ion-col size="18" size-xs="14" size-sm="16" size-md="17" size-lg="18" size-xl="17" class="ion-padding">
                    <markdown class="ion-text-left" [data]="appointment?.description"></markdown>
                </ion-col>
                <ion-col size="6" size-xs="10" size-sm="8" size-md="7" size-lg="6" size-xl="7" class="ion-no-padding appointment-sidebar">
                    <div class="details-container">
                        <ion-text class="label ion-margin-top" i18n>Titel</ion-text>
                        <ion-text class="value" i18n [innerHTML]="appointment.title"></ion-text>
                    </div>
                    <div class="details-container">
                        <ion-text class="label ion-margin-top" i18n>Datum</ion-text>
                        <ion-text class="value" i18n [innerHTML]="appointment.dateTimeFrom | formatDateTimeRange:appointment.dateTimeTo:1"></ion-text>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>

    </ng-container>

    <!-- Footer -->
    <ng-container footer *ngIf="appointment && appointment.formId">
        <div class="display-flex ion-justify-content-between">
            <div appTooltip
                 ttp="bottom"
                 [ttk]="this.isAlreadySubmitted ? 'already_sent' : null"
                 class="clickable"
                 *ngIf="this.appointment.formId && this.appointment.formButton && !(appointment.dateTimeFrom | isDateSameOrBeforeToday)">
                <ion-button
                        [disabled]="this.isAlreadySubmitted"
                        class="button-tertiary button-solid"
                        [innerHTML]="this.appointment.formButton"
                        (click)="openAppointmentFormModalWindow(this.appointment.formId, this.appointment.formButton)"
                        i18n></ion-button>
            </div>
        </div>
    </ng-container>
    </app-modal>
</ng-template>
