<ion-list  lines="full" [ngClass]="labelMaxDigits">
    <ion-item
            *ngFor="let buttonElement of buttonArray"
            button="{{!buttonElement.isHeadline}}"
            [class.disabled]="buttonElement.isDisabled"
            appTooltip
            ttk="{{buttonElement.tooltipKey}}"
            ttp="left"
            [ttColor]="buttonElement?.tooltipColorScheme ? buttonElement.tooltipColorScheme : tooltipColorSchemeDefault"
            id="{{buttonElement.code === logoutButton ? matomoId : null}}"
            class="popover-button-{{buttonElement.style}} {{buttonElement.cssClass}}"
            mode="md"
            (click)="!buttonElement.isDisabled && buttonElement.code ? onButtonClick($event, buttonElement.code, buttonElement.payload) : null">
        <ion-icon *ngIf="buttonElement.style === 'primary' && buttonElement.icon" name="{{buttonElement.icon}}" class="ion-padding-end" size="24px"></ion-icon>
        <ion-label *ngIf="!buttonElement.isHeadline && buttonElement.code === changeStoreButton" class="change-store-label">
            <span>{{buttonElement.payload.apiUser}}</span>
            <span>-</span>
            <span>{{buttonElement.label}}</span>
        </ion-label>
        <ion-label
                *ngIf="(buttonElement.label && buttonElement.code !== changeStoreButton) || (buttonElement.isHeadline && buttonElement.code == changeStoreButton)"
               class="{{buttonElement.style === 'primary' ? 'button-primary' : ''}}">
            {{buttonElement.label}}
        </ion-label>
        <ion-icon *ngIf="buttonElement.icon === 'default-arrow-right'" name="arrow-forward-outline" class="ion-padding-end" size="24px" slot="end"></ion-icon>
        <ion-skeleton-text *ngIf="buttonElement.code === null" style="width:100%"></ion-skeleton-text>
    </ion-item>
</ion-list>

