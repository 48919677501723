import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { CommonState } from '../../../../../../store/src/common-store/common.state';
import {
  loadMoreShopProducts,
  searchForShopProducts,
  searchForShopProductsFailure,
  searchForShopProductsSuccess,
  setShopProductSearchTerm,
} from '../../../../../../store/src/common-store/shop-product-search/actions/shop-product-search.actions';
import {
  selectShopProductLimitOfLastSearch,
  selectShopProductSearchResults,
  selectShopProductSearchTerm,
} from '../../../../../../store/src/common-store/shop-product-search/selectors/shop-product-search.selectors';
import { ProductSearchLimit } from '../../../../../../essentials/types/src/productSearchLimit';
import { Logger } from '../../../../../../essentials/util/src/logger';
import { ShopApiService } from '../../../services/shop-api.service';

const logger = new Logger('ShopProductSearchEffects');

@Injectable()
export class ShopProductSearchEffects {
  setSearchTerm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setShopProductSearchTerm),
      map(({ shopApiBaseUrl, searchTerm }) => searchForShopProducts({ shopApiBaseUrl, searchTerm, limit: 10 }))
    )
  );

  scrollToBottom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreShopProducts),
      withLatestFrom(
        this.store.select(selectShopProductLimitOfLastSearch),
        this.store.select(selectShopProductSearchResults),
        this.store.select(selectShopProductSearchTerm)
      ),
      filter(([_, limit, existingSearchResults]) => limit !== 99 && !(limit && existingSearchResults.length < limit)),
      switchMap(async ([{ shopApiBaseUrl }, currentLimit, _existingSearchResults, searchTerm]) => {
        let newLimit: ProductSearchLimit = 10;
        if (currentLimit === 10) {
          newLimit = 20;
        } else if (currentLimit === 20) {
          newLimit = 50;
        } else if (currentLimit === 50) {
          newLimit = 99;
        }
        return searchForShopProducts({ shopApiBaseUrl, searchTerm, limit: newLimit });
      })
    )
  );

  searchForShopProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchForShopProducts),
      switchMap(async ({ shopApiBaseUrl, searchTerm, limit }) => {
        try {
          const searchResults = await this.shopApiService.searchForProducts(shopApiBaseUrl, {
            term: searchTerm,
            limit: limit.toString(),
          });
          return searchForShopProductsSuccess({ searchResults });
        } catch (e) {
          logger.error('Error searching for shop products', e);
          return searchForShopProductsFailure();
        }
      })
    )
  );

  constructor(
    private actions$: Actions,
    private shopApiService: ShopApiService,
    private store: Store<CommonState>
  ) {}
}
