import { Injectable } from '@angular/core';
import { ComponentRef } from '@ionic/core';
import { OrdersMutations } from '../store/graphql/mutations/orders.graphql';
import { defaultOrderFilters } from '../config/order-filters.config';
import { formatMomentDateToDefaultDateTimeFormat } from '../formatting/date.formatting';
import { DateRangeOptionCodes, DocumentType, TableTypeEnum } from '../core.enums';
import { OrderFiltersInterface } from '../interfaces/order.interface';
import { DocumentMutations } from '../store/graphql/mutations/documents.graphql';
import { ReturnsMutations } from '../store/graphql/mutations/returns.graphql';
import { ReturnsFiltersInterface } from '../interfaces/returns.interface';
import { DocumentFiltersInterface } from '../interfaces/document.interface';
import { defaultDeliveryReceiptFilters, defaultInvoiceFilters } from '../config/document-filters.config';
import { defaultReturnsFilters } from '../config/returns-filter.config';
import { ModalService } from './modal.service';
import { OffersMutations } from '../store/graphql/mutations/offers.graphql';
import { SubscriptionTriggerService } from './subscription-trigger.service';

@Injectable({
    providedIn: 'root',
})
export class FilterService {
    type: TableTypeEnum;

    constructor(
        private modalService: ModalService,
        private ordersMutations: OrdersMutations,
        private returnsMutations: ReturnsMutations,
        private offersMutations: OffersMutations,
        private documentMutation: DocumentMutations,
        private subscriptionTriggerService: SubscriptionTriggerService
    ) { }

    init(type) {
        this.type = type;
    }


    /**
     * Updates the date filters
     *
     * @param event - Event from the date filter
     * @param confId - Id of the element
     * @param filters - Filters to update
     */
    updateDateFilter(event, confId, filters) {
        let option = DateRangeOptionCodes.individual;


        if(event.dateRangeId && event.dateRangeId !== DateRangeOptionCodes.individual) {
            option = event.dateRangeId;
        }
        const from = formatMomentDateToDefaultDateTimeFormat(event.fromDate);
        const to = formatMomentDateToDefaultDateTimeFormat(event.toDate);

        const updateObj = {
            [confId + 'Option']: option,
            [confId + 'From']: from !== '' ? from : null,
            [confId + 'To']: to !== '' ? to : null
        };
        filters = { ...filters, ...updateObj };

        this.saveFilters(filters);
    }
    /**
     * User has pressed the create disposition request icon. Show modal window
     *
     * @param focusOnSearch - focus on search input
     * @param searchText - search text
     * @param searchModalComponent - search modal component
     */
    async openSearchModal(focusOnSearch: boolean, searchText: string, searchModalComponent: ComponentRef) {
        const orderSearchModal = await this.modalService.create(
            searchModalComponent,
            {
                focusOnSearch,
                searchText
            }
        );
        await this.modalService.present(orderSearchModal);

        // after closing the modal, the return values are processed
        orderSearchModal.onDidDismiss().then((result) => {
            if (result['data']) {
                // Send full query
                this.saveFilters(result['data']);
            }
        });
    }

    /**
     * Save new filters depending on existing filters
     *
     * @param updatedFilters - Updated filters
     */
    saveFilters(
        updatedFilters: OrderFiltersInterface|ReturnsFiltersInterface|DocumentFiltersInterface
    ) {
        this.subscriptionTriggerService.dataSetLoading.next(false);
        if (updatedFilters.id) {
            delete updatedFilters.id;
        }
        delete updatedFilters['__typename'];
        switch (this.type) {
            case TableTypeEnum.Delivery:
            case TableTypeEnum.Invoice:
                switch (updatedFilters['documentType']) {
                    case DocumentType.LS:
                        this.documentMutation.upsertDocumentFilters({...defaultDeliveryReceiptFilters, ...updatedFilters});
                        break;
                    case DocumentType.RE:
                        this.documentMutation.upsertDocumentFilters({...defaultInvoiceFilters, ...updatedFilters});
                }
                break;
            case TableTypeEnum.Order:
                this.ordersMutations.upsertOrderFilters({...defaultOrderFilters, ...updatedFilters});
                break;
            case TableTypeEnum.Return:
                this.returnsMutations.upsertReturnsFilters({...defaultReturnsFilters, ...updatedFilters});
                break;
            case TableTypeEnum.Offers:
                if (updatedFilters['activeOffer'] && parseInt(updatedFilters['activeOffer'], 10) > 0) {
                    this.offersMutations.insertOfferQuote(updatedFilters['activeOffer'], [], true).then(() => {
                        this.subscriptionTriggerService.dataChanged.next(true);
                        this.subscriptionTriggerService.dataSetLoading.next(false);
                    });
                }
                break;
            default:
                this.subscriptionTriggerService.dataSetLoading.next(false);
                break;
        }
    }
}
