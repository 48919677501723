import { Pipe, PipeTransform } from '@angular/core';

/**
 * Filter Excluded FiltersPipe
 */
@Pipe({
    name: 'filterExcluded'
})
export class FilterExcludedPipe implements PipeTransform {

    transform(items: []) {
        if(!items) {
            return items;
        }
        return items.filter(item => !item['excludeFromFilter']);
    }
}
