import dayjs from 'dayjs';
import { Dictionary } from 'ts-essentials';
import { AddonStatus } from '../../types/src/addonStatus';
import { AddonType } from '../../types/src/addonType';
import { BackendAddonStatus } from '../../types/src/backendMeaUser';
import { MeaUser } from '../../types/src/chatUser';
import { assertUnreachable } from './typescript.util';

export class AddonUtil {
  public static mapBackendAddonsToAddons(backendAddons: BackendAddonStatus[]): Dictionary<AddonStatus> {
    return backendAddons.reduce(
      (acc, addon) => ({
        ...acc,
        [addon.type]: AddonUtil.mapBackendAddonToAddon(addon),
      }),
      {}
    );
  }

  public static mapBackendAddonToAddon(backendAddonStatus: BackendAddonStatus): AddonStatus {
    if (backendAddonStatus.status === 'SUBSCRIBED') {
      return { status: backendAddonStatus.status, subscriptionTimestamp: backendAddonStatus.subscriptionTimestamp };
    } else if (backendAddonStatus.status === 'CANCELLED') {
      return { status: backendAddonStatus.status, expirationTimestamp: backendAddonStatus.expirationTimestamp };
    }
    return assertUnreachable(backendAddonStatus);
  }

  public static canUserUseAddon(user: MeaUser | undefined, addonType: AddonType): boolean {
    return this.canAddonBeUsed(this.getAddonStatusOfUser(user, addonType));
  }

  public static getAddonStatusOfUser(user: MeaUser | null | undefined, addonType: AddonType): AddonStatus | undefined {
    return user?.addons?.[addonType];
  }

  public static canAddonBeUsed(addonStatus: AddonStatus | undefined): boolean {
    return !!addonStatus && (addonStatus.status === 'SUBSCRIBED' || addonStatus.expirationTimestamp > dayjs().unix());
  }
}
