import { Routes } from '@angular/router';

import { AuthGuard } from './guard/auth.guard';
import { LoginCallbackPage } from './pages/login/login-callback.page';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/loading/loading.module').then(m => m.LoadingPageModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'login/callback',
        component: LoginCallbackPage
    },
    {
        path: 'waiting',
        loadChildren: () => import('./pages/waiting/waiting.module').then(m => m.WaitingPageModule)
    },
    {
        path: 'app',
        loadChildren: () => import('./navigation/navigation.module').then(m => m.NavigationModule),
        canActivate: [AuthGuard]
    },
    {path: '**', redirectTo: ''}
];
