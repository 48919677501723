<ng-container *ngIf="!downloadDisabled; else disabledDownloadTemplate">
    <ion-icon src="assets/icons/download.svg" mode="md" class="clickable {{matomoClass}}"
              size="small"
              appTooltip
              [ttk]="useGeneralTooltips ? 'pdf_viewer_download' : 'delivery_receipt_download'"
              ttp="left"
              (click)="onDownloadClicked()"
    ></ion-icon>
</ng-container>
<ng-container *ngIf="!printDisabled; else disabledPrintTemplate">
    <ion-icon name="print-sharp" mode="md" class="clickable {{matomoClass}}"
              size="small"
              appTooltip
              [ttk]="useGeneralTooltips ? 'pdf_viewer_print' : 'delivery_receipt_print'"
              ttp="left"
              (click)="onPrintClicked()"
    ></ion-icon>
</ng-container>


<!-- templates -->
<ng-template #disabledDownloadTemplate>
    <ion-icon src="assets/icons/download.svg" mode="md" class="disabled"
              size="small"
    ></ion-icon>
</ng-template>
<ng-template #disabledPrintTemplate>
    <ion-icon name="print-sharp" mode="md" class="disabled"
              size="small"
              appTooltip
              [ttk]="downloadDisabled ? null : 'pdf_viewer_not_printable'"
              ttp="top"
    ></ion-icon>
</ng-template>
<ion-spinner *ngIf="isLoading$ | async" class="loadingSpinner" name="crescent" appTooltip [ttk]="tooltipKey" ttp="top"></ion-spinner>
