import { Injectable } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { ToastController } from '@ionic/angular';
import { ToastButton } from '@ionic/core/dist/types/components/toast/toast-interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CustomToastController {
  constructor(
    private toastController: ToastController,
    private translateService: TranslateService
  ) {}

  async createAndPresentToast({
    message,
    buttons,
    cssClass,
    interpolateParams,
  }: {
    message: string;
    buttons?: (ToastButton | string)[];
    cssClass?: string;
    interpolateParams?: { [key: string]: any };
  }): Promise<void> {
    const toast = await this.createToast({ message, buttons, cssClass, interpolateParams });
    await toast.present();
  }

  async createToast({
    message,
    buttons,
    cssClass,
    interpolateParams,
  }: {
    message: string;
    buttons?: (ToastButton | string)[];
    cssClass?: string;
    interpolateParams?: { [key: string]: any };
  }): Promise<HTMLIonToastElement> {
    const translatedMessage = this.translateService.instant(message, interpolateParams);
    return this.toastController.create({
      message: translatedMessage,
      mode: 'ios',
      cssClass: cssClass ?? 'ion-toast-styling',
      duration: 4000,
      buttons: buttons ?? [{ text: 'OK' }],
      swipeGesture: 'vertical',
    });
  }

  async getTop() {
    return this.toastController.getTop();
  }
}
