import { Component, Input, ViewChild, TemplateRef, EventEmitter, Output, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { IonicSlides } from '@ionic/angular';
import { SwiperOptions } from 'swiper/types';
import { ColorInterface } from '../../interfaces/theme-color.interface';
import { ThemeColorConfig } from '../../config/theme-color.config';


@Component({
    selector: 'app-slider-horizontal',
    templateUrl: './slider-horizontal.component.html',
    styleUrls: ['./slider-horizontal.component.scss'],
})
export class SliderHorizontalComponent implements OnChanges {
    @Input() items: Array<any>;
    @Input() imageField: string;
    @Input() isLoading: boolean;
    @Input() sliderPrefix = '';
    @Input() themeColor?: ColorInterface = ThemeColorConfig.primary;

    @Input() ionCardTemplate: TemplateRef<any>;
    @Input() titleTemplate: TemplateRef<any>;
    @Input() contentTemplate: TemplateRef<any>;
    @Input() headerImageTemplate: TemplateRef<any>;

    @ViewChild('swiper') swiperRef: ElementRef | undefined;

    @Output() itemClicked = new EventEmitter<any>();

    swiperConfig: SwiperOptions;

    constructor() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isLoading || changes.sliderPrefix.currentValue !== changes.sliderPrefix.previousValue) {
            this.swiperConfig = {
                modules: [IonicSlides],
                slidesPerView: 3,
                slidesPerGroup: 1,
                spaceBetween:28, // check with margin of swiper-slide in scss
                breakpoints: {
                    0: {
                        slidesPerView: 3,
                        slidesPerGroup: 1
                    },
                    960: {
                        slidesPerView: 3,
                        slidesPerGroup: 1
                    },
                    1280: {
                        slidesPerView: 3,
                        slidesPerGroup: 1
                    },
                },
                freeMode: false,
                navigation: {
                    nextEl: `.${this.sliderPrefix}slider-horizontal-next`,
                    prevEl: `.${this.sliderPrefix}slider-horizontal-prev`,
                }
            };
            setTimeout(() => {
                this.swiperRef?.nativeElement?.swiper?.update();
            }, 0);
        }
    }

    moveToSlide(isNext: boolean) {
        const swiper = this.swiperRef.nativeElement?.swiper;
        isNext ? swiper?.slideNext() : swiper?.slidePrev();
    }
    /**
     * User pressed a single item
     *
     * @param item - Clicked item
     */
    async onItemClick(item: any) {
        this.itemClicked?.emit(item);
    }
}
