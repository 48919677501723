import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
    HostListener,
    TemplateRef, ViewChild
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ComponentRef } from '@ionic/core';
import { Subscription } from 'rxjs';
import { isEqual } from 'lodash';
import {
    SortDirectionEnum,
    SortableOrderFieldsEnum,
    SortableDocumentEnum,
    TableFieldsEnum,
    SortableUserAdministrationFieldsEnum,
    DateRangeOptionCodes,
    BadgeTypeEnum,
    SortableOffersFieldsEnum
} from '../../core.enums';
import {
    FilterResultInterface,
    FilterResultItemInterface,
    ListFiltersInterface
} from '../../interfaces/filter-result.interface';
import { OrderFilterResultConfig } from '../../config/order-filters.config';
import {
    DeliveriesDocumentsFilterResultConfig,
    InvoiceDocumentsFilterResultConfig
} from '../../config/document-filters.config';
import { ReturnsFilterResultConfig } from '../../config/returns-filter.config';
import { AdditionalDateRangeConfig, DateRangeConfig, getDateRangesWithoutDates } from '../../config/date-range.config';
import { formatDateTimeToDate } from '../../formatting/date.formatting';
import { TableTypeEnum } from '../../enums/table-type.enum';
import { ListSortInterface } from '../../interfaces/sorting.interface';
import { unsubscribeAll } from '../../util/subscriptions.util';

@Component({
    selector: 'app-table-old-old',
    template: '',
    animations: [
        trigger('rowExpansionTrigger', [
            state('void', style({
                transform: 'translateX(-10%)',
                opacity: 0
            })),
            state('active', style({
                transform: 'translateX(0)',
                opacity: 1
            })),
            transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class TableOldComponent implements OnChanges {

    constructor() {}

    @ViewChild('table') table;
    @ViewChild('rows') rows : TemplateRef<any>;
    @ViewChild('rowExpansion') rowExpansion : TemplateRef<any>;

    @Input() rowSelectionChange: number;
    @Output() selectedRowEvent: EventEmitter<number> = new EventEmitter();

    tableValues: Array<any>;
    numberOfRows = 100;
    numToleratedItems = Math.floor(this.numberOfRows / 5);

    tableType: TableTypeEnum;
    badgeType = BadgeTypeEnum.FILTER_RESULT;
    searchModalComponent: ComponentRef;
    statusErrorMessage: string;
    searchInProgress: boolean;
    sorting: ListSortInterface = {
        field: null,
        direction: null
    };
    columns: Array<{
        id: any,
        field?: SortableOrderFieldsEnum|SortableDocumentEnum|TableFieldsEnum|SortableOffersFieldsEnum|SortableUserAdministrationFieldsEnum,
        class: string,
        header: string,
        sortable: boolean,
        sort?: SortDirectionEnum,
        tooltip?: string
    }> = [];

    hasRowExpansion = false;

    subTableKey: string;
    subTableIdKey: string;
    filters: ListFiltersInterface;
    isLoading = {data: true, count: true};

    dataKey: string;
    isLazy = true;

    markedRow = 0;
    maximumRows = this.numberOfRows;
    forceUpdate = null;
    currentIndex = 0;
    totalRows = 0;
    expandedRowKeys = [];
    /**
     * Defines a map of expanded rows.
     * Must not be null!
     */
    initialExpandedRows: { [key: string]: boolean } = {'-1': false};

    isTableScrollInitialized = false;
    scrollTopPossible = false;
    scrollBottomPossible = true;
    sortEnum = SortDirectionEnum;
    noResultDateInformation: FilterResultItemInterface;
    noResultInformation: FilterResultInterface;

    dataChangedVarSubscription: Subscription;

    /**
     * Checks if filters have changed
     *
     * @param oldFilters - the old filters object
     * @param newFilters - the new filters object
     */
    static checkIfFiltersHaveChanged(oldFilters, newFilters) {
        return !oldFilters || !isEqual(oldFilters, newFilters);
    }

    @HostListener('document:keydown.ArrowUp', ['$event']) onArrowUpHandler(event: KeyboardEvent) {
        if (event?.srcElement['tagName'] === 'TEXTAREA' || event?.srcElement['tagName'] === 'INPUT') {
            return;
        }
        event.preventDefault();
        this.moveToDifferentRow(-1);
    }

    @HostListener('document:keydown.ArrowDown', ['$event']) onArrowDownHandler(event: KeyboardEvent) {
        if (event?.srcElement['tagName'] === 'TEXTAREA' || event?.srcElement['tagName'] === 'INPUT') {
            return;
        }
        event.preventDefault();
        this.moveToDifferentRow(1);
    }
    @HostListener('document:keydown.ArrowLeft', ['$event'])
    onArrowLeftHandler(event: KeyboardEvent) {
        if (event?.srcElement['tagName'] === 'TEXTAREA' || event?.srcElement['tagName'] === 'INPUT') {
            return;
        }
        event.preventDefault();
        if (this.subTableKey) {
            this.moveToDifferentLevel(-1);
        }
    }
    @HostListener('document:keydown.ArrowRight', ['$event'])
    onArrowRightHandler(event: KeyboardEvent) {
        if (event?.srcElement['tagName'] === 'TEXTAREA' || event?.srcElement['tagName'] === 'INPUT') {
            return;
        }
        event.preventDefault();
        if (this.subTableKey) {
            this.moveToDifferentLevel(1);
        }
    }

    init() { }
    clear() {
        unsubscribeAll([
            this.dataChangedVarSubscription
        ]);
    }

    initScrollHandler() {
        if (!this.isTableScrollInitialized) {
            const table = document.querySelector(
                ('app-' + this.tableType + '-table-old') + (this.isLazy ? ' .p-scroller' : ' .p-datatable-wrapper')
            );
            if (table) {
                table.addEventListener('scroll', this.onTableScroll.bind(this));
                this.isTableScrollInitialized = true;
            }
        }
    }
    loadFilters() { }
    loadSorting() { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.rowSelectionChange && changes.rowSelectionChange.currentValue) {
            switch (changes.rowSelectionChange.currentValue) {
                case -1:
                case 1:
                    this.moveToDifferentRow(changes.rowSelectionChange.currentValue);
                    break;
            }
        }
    }

    setParameters() {
        if (this.totalRows === 0) {
            let config : FilterResultInterface;
            switch (this.tableType) {
                case TableTypeEnum.Delivery:
                    config = DeliveriesDocumentsFilterResultConfig;
                    break;
                case TableTypeEnum.Invoice:
                    config = InvoiceDocumentsFilterResultConfig;
                    break;
                case TableTypeEnum.Order:
                    config = OrderFilterResultConfig;
                    break;
                case TableTypeEnum.OrderBulk:
                    config = {
                        ...OrderFilterResultConfig,
                        hasSearchModal: false,
                    };
                    break;
                case TableTypeEnum.Return:
                    config = ReturnsFilterResultConfig;
                    break;
                default:
                    return;
            }

            if(config && config.items && this.filters) {
                config.items = config.items.map((conf) => ({
                    ...conf,
                    value: this.getFilterValue(conf)
                }));
                this.noResultInformation = {
                    ...config, items: config.items.filter((item) =>
                        (item.value && item.value.toString().length > 0 && item.value.toString().toLowerCase() !== 'all')
                    )
                };
                this.noResultDateInformation = this.noResultInformation.items.find(item => item.isRecDate);
            }
        }
    }


    /**
     * initData
     */
    initData() {
        this.loadFilters();
        this.loadSorting();
    }


    /**
     * is called after all data are loaded
     */
    afterDataLoad(data, isExpanded = true) {
        this.tableValues = data;
        if(data && data[0] && data[0].id) {
            this.markRow(data[0].id, false);
            if (this.hasRowExpansion && isExpanded) {
                const firstElementWithSubItems = this.tableValues.find(item => (item && item[this.subTableKey] ?
                    item[this.subTableKey].find(subItem => subItem?.id)
                : false));

                // Set initial expanded row
                if (firstElementWithSubItems) {
                    this.initialExpandedRows = {[firstElementWithSubItems.id]: true};
                }
            }
        }
        this.initScrollHandler();
    }

    getFilterValue = (conf: FilterResultItemInterface) => {
        if (conf.isDate) {
            const dateOption = this.filters[conf.id + 'Option'];
            if (getDateRangesWithoutDates(dateOption)) {
                return null;
            }
            if (dateOption === DateRangeOptionCodes.individual) {
                const fromDate = this.formatDate(conf.id + 'From');
                const toDate = this.formatDate(conf.id + 'To');
                return fromDate === toDate ? fromDate : fromDate + ' - ' + toDate;
            }

            const item = [
                ...DateRangeConfig,
                AdditionalDateRangeConfig[DateRangeOptionCodes.unknown],
                AdditionalDateRangeConfig[DateRangeOptionCodes.nextWeek],
                AdditionalDateRangeConfig[DateRangeOptionCodes.thisYear]
            ].find(dateRange => dateRange.id === dateOption);
            return item && item.title || '';
        }
        if (conf.translation) {
            const translationObject = conf.translation.find(translation => translation.id === this.filters[conf.id]);
            return translationObject ? translationObject.title : null;
        }
        return this.filters[conf.id];
    }

    formatDate = (key) => {
        const date = formatDateTimeToDate(this.filters[key], false);
        return date === 'invalid date' ? '' : date;
    }

    /**
     * Sort columns
     */
    sortColumns() {
        this.columns = this.columns.map(column => (
            {...column, sort: (column.field === this.sorting.field ? this.sorting.direction : null)}
        ));
    }


    /**
     * moves to previous or next row
     *
     * @param rowChangeValue - this value defines if the next (+1) or previous (-1) row is selected
     */
    moveToDifferentRow = (rowChangeValue) => {
        let currentIndex = this.tableValues.findIndex(item => item?.id === this.markedRow);

        // Marked Row isn't a row from the main data, so it has to be a sub data row.
        if(currentIndex === -1 && this.subTableKey) {
            const rowValue = this.tableValues.find(item => item && item[this.subTableKey] ?
                item[this.subTableKey].find(document => document && document[this.subTableIdKey] === this.markedRow) :
                false
            );
            if(rowValue && rowValue[this.subTableKey]) {
                currentIndex = rowValue[this.subTableKey].findIndex(document => document[this.subTableIdKey] === this.markedRow);

                // Move between sub row
                let newIndex = currentIndex + rowChangeValue;
                const newRow = rowValue[this.subTableKey][newIndex];
                if (newRow) {
                    this.markRow(newRow[this.subTableIdKey], true);
                } else {
                    // If there is no sub row left, move to next or previous main row
                    currentIndex = this.tableValues.findIndex(dItem => dItem.id === rowValue.id);
                    newIndex = currentIndex + rowChangeValue;
                    const newMainRow = this.tableValues[newIndex];
                    if (newMainRow && rowChangeValue === 1) {
                        this.markRow(newMainRow.id, true);
                    } else {
                        this.markRow(rowValue.id, true);
                    }
                }
            }
        } else {
            // If current main row is expanded, move to first sub row
            if (this.tableValues[currentIndex]
            && this.tableValues[currentIndex].id
            && this.table?.expandedRowKeys[this.tableValues[currentIndex].id]
                && this.tableValues[currentIndex][this.subTableKey].length > 0
                && rowChangeValue === 1) {
                this.markRow(this.tableValues[currentIndex][this.subTableKey][0][this.subTableIdKey], true);

            } else {
                const newIndex = currentIndex + rowChangeValue;
                const newRow = this.tableValues[newIndex];
                if (newRow) {
                    // If we move back, and the previous element is expanded, move to the last available sub row
                    if (this.subTableKey && rowChangeValue === -1 && this.table?.expandedRowKeys[newRow.id]
                        && newRow[this.subTableKey].length > 0) {
                        this.markRow(newRow[this.subTableKey][newRow[this.subTableKey].length - 1][this.subTableIdKey], true);
                    } else {
                        // Move to next row
                        this.markRow(newRow.id, true);
                    }
                }
            }
        }
    };

    /**
     * Moves to sub rows (one level down) or to main rows (one level up)
     *
     * @param rowChangeValue - this value defines if we should move a level up (+1) or down (-1).
     * @param markRows - if this is true, the row should be marked
     * @param altRowId - if set, use this row id
     */
    moveToDifferentLevel = (rowChangeValue, markRows = true, altRowId = null) => {
        const rowId = altRowId ? altRowId : this.markedRow;
        let rowValue = this.tableValues.find(item => item?.id === rowId);

        // Move to sub row
        if(rowChangeValue === 1) {
            if(rowValue && rowValue[this.subTableKey].length > 0) {
                if (markRows) {
                    this.markRow(rowValue[this.subTableKey][0][this.subTableIdKey], true);
                }
                if(this.table?.expandedRowKeys && !this.table?.expandedRowKeys[rowValue.id]) {
                    this.table.expandedRowKeys = {...this.table.expandedRowKeys, [rowValue.id]: true};
                }
            }
        } else {
            if(rowValue) {
                // If the user is on a rowValue, simply collapse the row
                if (markRows) {
                    this.markRow(rowValue.id, true);
                }
                if(this.table?.expandedRowKeys?.[rowValue.id]) {
                    delete this.table.expandedRowKeys[rowValue.id];
                }
            } else {
                // main row not found, must be a sub row
                rowValue = this.tableValues.find(item => item && item[this.subTableKey] ?
                    item[this.subTableKey].find(document => document && document[this.subTableIdKey] === rowId) :
                    false
                );
                if(rowValue) {
                    if (markRows) {
                        this.markRow(rowValue.id, true);
                    }

                    if(this.table?.expandedRowKeys[rowValue.id]) {
                        delete this.table.expandedRowKeys[rowValue.id];
                    }
                }
            }
        }
    };

    /**
     * marks row and scrolls into view
     *
     * @param rowId - The unique identifier of the row.
     * @param scrollToView - A boolean that defines whether the selected row should be scrolled into view.
     */
    markRow(rowId: number, scrollToView: boolean = false) {
        if (this.markedRow !== rowId) {
            this.markedRow = rowId;
            this.selectedRowEvent.emit(rowId);
            if (scrollToView) {
                this.scrollToView();
            }
        }
    }

    /**
     * event fires if a row is clicked/selected
     *
     * @param event - The event source
     * @param rowId - The unique identifier of the row.
     * @param expand - expands the row if it is expandable
     */
    markRowClick(event: any, rowId: number, expand = false) {
        if (!event.target.className.includes('clickable')
            && !event.target.className.includes('native-input')
            && !event.target.className.includes('qty-input')
        ) {
            this.markRow(rowId, false);
            if (expand && this.hasRowExpansion) {
                this.moveToDifferentLevel(1, false);
            }
        }
    }

    /**
     * hides sidebar
     */
    hideSidebar() {
        this.markedRow = -1;
        this.selectedRowEvent.emit(-1);
    }

    /**
     * scrolls to selected table-old row
     */
    scrollToView() {
        const table = document.getElementById(this.tableType + 'Table');
        const currentElement = table ? table.querySelector('tr.active') : null;
        if (currentElement && !this.isLoading.data && !this.isLoading.count) {
            if (currentElement.previousElementSibling && currentElement.previousElementSibling.previousElementSibling) {
                currentElement.previousElementSibling.previousElementSibling.scrollIntoView({behavior: 'smooth'});
            } else if (currentElement.previousElementSibling) {
                currentElement.previousElementSibling.scrollIntoView({behavior: 'smooth'});
            } else {
                currentElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    /**
     * Table triggers a lazy load event
     *
     * @param event - The event source
     */
    onLazyLoad(event) {
        if (this.maximumRows !== event.rows || this.currentIndex !== event.first) {
            // this needs to be called everytime - otherwise firefox won't load e.g. orders if there are 30 items and user scrolls to bottom
            this.currentIndex = event.first || 0;
            this.maximumRows = event.rows < this.numberOfRows ? this.numberOfRows : event.rows;
            this.forceUpdate = event.forceUpdate;
            if(event.rows >= this.numberOfRows) {
                this.loadAdditionalData();
            }
        }
    }

    /**
     * Show shadow on top and/or bottom of the table-old if more data are available via scrolling.
     *
     * @param event - The event source
     */
    onTableScroll(event) {
        const scrollTop = event.target.scrollTop;
        const scrollHeight = event.target.scrollHeight;
        const elementHeight = event.target.offsetHeight;
        const rowHeight = elementHeight / this.maximumRows;
        this.scrollTopPossible = (scrollTop > rowHeight);
        this.scrollBottomPossible = (scrollHeight - (scrollTop + elementHeight) > rowHeight);
    }
    /**
     * is called on lazy load
     */
    loadAdditionalData() {}

    /**
     * change sort order of the given column
     *
     * @param event - The field name and sort direction
     */
    saveSortOrder(event: {fieldName: string, sortDirection: string}) {}

    /**
     * change sort order of the given column
     *
     * @param columnId - The id of the column to change sort order
     */
    onSortOrderChange = (columnId) => {
        const columnIndex = this.columns.findIndex(col => col.id === columnId);
        if (this.columns[columnIndex] && this.columns[columnIndex].sortable) {
            // reset column sorting unless it is the already sorted column
            this.columns.forEach(column => { column.sort = columnId !== column.id ? null : column.sort; });

            // set new sort order
            this.columns[columnIndex].sort = (this.columns[columnIndex].sort === SortDirectionEnum.asc)
                ? SortDirectionEnum.desc
                : SortDirectionEnum.asc;
            this.saveSortOrder({fieldName: this.columns[columnIndex].field, sortDirection: this.columns[columnIndex].sort});
        }
    };

    /**
     * track by
     *
     * @param index - The item index
     * @param item - the item to use for tracking
     *
     * Note: do not make static!
     */
    trackBy(index, item) {
        return item.id;
    }
}
