<ng-container *ngIf="options.length > 0" >
    <ion-list
            class="ion-no-margin"
            [class.ion-no-padding]="useCheckboxes"
            [class.checkbox-groups]="useCheckboxes"
            lines="none">
        <ion-item-group *ngFor="let option of options;trackBy:trackBy">
            <ion-item button
                      [class.disabled]="option.disabled"
                      (click)="!useCheckboxes && !option.disabled ? onButtonClick(option.id) : useCheckboxes ? updateCheckboxes(option.id)  : null"
                      appTooltip
                      ttk="{{option.tooltipKey}}"
                      ttp="left"
                      detail="false"
                      lines="full">
                <ion-checkbox
                    slot="end"
                    class="full-width"
                    *ngIf="useCheckboxes"
                    [checked]="option.id | isInArray:checkedItems"
                >
                    {{option.title}}
                </ion-checkbox>
                <div *ngIf="!useCheckboxes" class="label">
                    {{option.title}}
                </div>
            </ion-item>
            <ng-container *ngIf="option.children && option.children.length > 0">
                <ion-item button
                          class="ion-padding-start"
                          *ngFor="let child of option.children"
                          (click)="updateCheckboxes(child.id)">
                    <ion-checkbox
                        slot="end"
                        class="full-width"
                        *ngIf="useCheckboxes"
                        labelPlacement="start"
                        [checked]="child.id | isInArray:checkedItems"
                    >
                    {{child.title}}
                    </ion-checkbox>
                    <div *ngIf="!useCheckboxes" class="label">
                        {{child.title}}
                    </div>
                </ion-item>
            </ng-container>
        </ion-item-group>
    </ion-list>
    <ion-button
            *ngIf="useCheckboxes"
            (click)="submitFilter()"
            color="primary"
            class="ion-margin"
            expand="block"
            size="large"
            i18n>Anwenden</ion-button>
</ng-container>

