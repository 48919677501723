export enum BadgeTypeEnum {
    ORDER = 'ORDER',
    ORDER_BULK_ACTION = 'ORDER_BULK_ACTION',
    DOCUMENT_CANCEL = 'DOCUMENT_CANCEL',
    DELIVERY_RECEIPT_SUBTYPE = 'DELIVERY_RECEIPT_SUBTYPE',
    INVOICE = 'INVOICE',
    RETURNS_STATUS = 'RETURNS_STATUS',
    RETURNS_TYPE = 'RETURNS_TYPE',
    FILTER_RESULT = 'FILTER_RESULT',
    OFFERS_ACTION_BADGE = 'OFFERS_ACTION_BADGE',
    NOTIFICATION = 'NOTIFICATION',
    USER_ADMINISTRATION = 'USER_ADMINISTRATION',
    MEA_SHOP = 'MEA_SHOP',
    MEA_DOWNLOADS = 'MEA_DOWNLOADS',
    MEA_DOWNLOADS_MULTIPLE = 'MEA_DOWNLOADS_MULTIPLE',
    EDUCATION_LOCATION = 'EDUCATION_LOCATION',
    DELIVERED_ITEMS = 'DELIVERED_ITEMS',
}
