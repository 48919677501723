import { Pipe, PipeTransform } from '@angular/core';
import { StatisticTypeEnum } from '../enums/statistics-type.enum';

@Pipe({
    name: 'statisticTypeNote'
})
export class StatisticTypeNotePipe implements PipeTransform {

    transform(type: StatisticTypeEnum): string {
        switch (type) {
            case StatisticTypeEnum.ORDERS_ENROUTE:
            case StatisticTypeEnum.ORDERS_OPEN:
            case StatisticTypeEnum.ORDERS_OPEN_CANCELATIONS:
            case StatisticTypeEnum.ORDERS_PENDING:
            case StatisticTypeEnum.SALES_VOLUME:
            case StatisticTypeEnum.SALES_VOLUME_JUST_HIGH_PRICED:
            case StatisticTypeEnum.SALES_VOLUME_WITHOUT_HIGH_PRICED:
            default:
                return '';
        }

    }
}
