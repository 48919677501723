export enum DeliveryReceiptOrderType {
    ALL = 'ALL',
    AO = 'AO',
    AP = 'AP',
    EA = 'EA',
    GH = 'GH',
    GK = 'GK',
    N_PLUS = 'N+',
    NA = 'NA',
    NB = 'NB',
    UE = 'UE',
    WE = 'WE',
}

export enum DeliveryReceiptOrderTypeFormatted {
    ALL = 'ALL',
    NA = 'NA',
    UE = 'UE',
    SO = 'SO',
}

export enum DocumentStatus {
    CANCELLED = 'CANCELLED'
}

export enum DocumentSubType {
    ALL = 'ALL',
    CHARTIER = 'CHARTIER',
    CHARBLUT = 'CHARBLUT',
    CHARDOKU = 'CHARDOKU',
    CHARSECU = 'CHARSECU',
    E_TR = 'E_TR',
    E_SR = 'E_SR'
}

export enum DocumentType {
    ALL = 'ALL',
    LS = 'LS',
    CH = 'CH',
    RE = 'RE'
}

export enum DocumentPdfFilenamePrefix {
    LS = '_LS',
    CH_TIER = '_Char_Tier',
    CH_BLUT = '_Char_Transf',
    CH_DOKU = '_Char_Sonst',
    CH_SECU = '_Char_Secur',
    E_SR = '_Rech',
    E_TR = '_TRech'
}
