import { Component, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
    animations: [
        trigger('contentVisible', [
            state('visible', style({
                opacity: 1,
                visibility: 'visible',
                height: 'auto',
                padding: '.5rem 0 1rem 0'
            })),
            state('hidden', style({
                opacity: 0,
                visibility: 'hidden',
                height: '1px',
                padding: 0

            })),
            transition('visible=>hidden', animate('250ms')),
            transition('hidden=>visible', animate('250ms')),
        ]),
    ]
})
export class AccordionComponent implements OnInit {

    @Input() items: Array<any> = [];
    @Input() key = '';
    @Input() value = '';
    activeIndex = -1;

    constructor() {
    }

    ngOnInit() {
    }

    /**
     * User has pressed a accordion item
     *
     * @param index - index of accordion item
     * @param event - Click event
     */
    public onAccordionClick(index: number, event) {
        if(this.activeIndex === index) {
            // avoid that accordion is closed on video_player play/stop/change volume etc.
            if (event.target && event.target.outerHTML && event.target.outerHTML.includes('vjs-')) {
                return;
            }
            this.activeIndex = -1;
        } else {
            this.activeIndex = index;
        }
    }
}
