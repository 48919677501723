export enum DateRangeOptionCodes {
    all = 'all',
    unknown = 'unknown',
    today = 'today',
    lastWeek = 'lastWeek',
    thisWeek = 'thisWeek',
    nextWeek = 'nextWeek',
    lastMonth = 'lastMonth',
    beforeLastMonth = 'beforeLastMonth',
    thisMonth = 'thisMonth',
    lastQuarter = 'lastQuarter',
    thisQuarter = 'thisQuarter',
    thisYear = 'thisYear',
    lastYear = 'lastYear',
    individual = 'individual'
}
