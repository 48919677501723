import gql from 'graphql-tag';
import { backendUserConversationQueryAttributes } from '../queries/getUserConversations';

export default gql`
  mutation createUserConversation(
    $otherParticipantId: ID!
    $conversationId: ID!
    $otherParticipantEncryptedConversationPassword: String!
    $myEncryptedConversationPassword: String!
  ) {
    createUserConversations(
      otherParticipantId: $otherParticipantId
      conversationId: $conversationId
      otherParticipantEncryptedConversationPassword: $otherParticipantEncryptedConversationPassword
      myEncryptedConversationPassword: $myEncryptedConversationPassword
    ) {
      ownerId
      otherParticipantId
      userConversations {
        ${backendUserConversationQueryAttributes}
      }
    }
  }
`;
