import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { gql } from '@apollo/client/core';
import { ApiService } from '../../../services/api.service';
import { PharmacyStoreStateVar } from '../../locals/pharmacyStoreState.var';
import { AuthStorageKeyEnum } from '../../../enums/authStorageKey.enum';

const SendTermsEmailMutation = gql`
    mutation sendTermsEmail($versionNumber: String!) {
        SendTermsAndConditionsEmail(versionNumber: $versionNumber) {
            status
            message
        }
    }
`;

const ConfirmTermsTokenMutation = gql`
    mutation confirmTermsToken($token: String!) {
        ConfirmTermsAndConditions(token: $token) {
            status
            message
            pharmacyStoreId
        }
    }
`;

export const AllStaticPagesMutations = [
    SendTermsEmailMutation,
    ConfirmTermsTokenMutation
];

@Injectable()
export class StaticPagesMutations {
    private pharmacyStoreStateVar = inject(PharmacyStoreStateVar);

    pharmacyStores = this.pharmacyStoreStateVar.pharmacyStoresState;

    constructor(
        private apollo: Apollo,
        private apiService: ApiService,
    ) {
    }

    sendTermsConfirmationEmail(version: string): Promise<{success: boolean, alreadyAccepted: boolean}> {
        return new Promise((resolve, reject) => {
            this.apollo.mutate({
                mutation: SendTermsEmailMutation,
                variables: {
                    versionNumber: version
                }
            }).subscribe({
                next: (result) => {
                    if (result?.data && result.data['SendTermsAndConditionsEmail']?.status.toLowerCase() === 'success') {
                        if (result.data['SendTermsAndConditionsEmail']?.message === 'already accepted') {
                            void this.apiService.presentSuccessToast('Sie haben die Allgemeinen Geschäftsbedingungen bereits akzeptiert.');
                            resolve({success: true, alreadyAccepted: true});
                        }
                        resolve({success: true, alreadyAccepted: false});
                    } else {
                        void this.apiService.presentErrorToast(result?.errors, 'Die E-Mail konnte nicht versendet werden. Bitte versuchen Sie es später erneut.');
                        reject(result?.errors);
                    }
                },
                error: (err) => {
                    void this.apiService.presentErrorToast(err, 'Die E-Mail konnte nicht versendet werden. Bitte versuchen Sie es später erneut.');
                    reject(err);
                }
            });
        });
    }

    confirmTermsToken(token: string): Promise<{confirmed: boolean, isActivePharmacyConfirmed?: boolean}> {
        return new Promise((resolve) => {
            this.apollo.mutate({
                mutation: ConfirmTermsTokenMutation,
                variables: {
                    token
                }
            }).subscribe({
                next: (result) => {
                    if (result?.data && result.data['ConfirmTermsAndConditions']?.status.toLowerCase() === 'success') {
                            const acceptedPharmacy = this.pharmacyStores().find(
                                p => result.data['ConfirmTermsAndConditions']?.pharmacyStoreId.toString() === p.pharmacyStoreId.toString()
                            );
                            const activePharmacy = this.pharmacyStores().find(
                                p => localStorage.getItem(AuthStorageKeyEnum.activePharmacy) === p.apiUser.toString()
                            );
                            if (!acceptedPharmacy?.apiUser || !acceptedPharmacy?.name) {
                                void this.apiService.presentSuccessToast('Erfolgreich bestätigt, vielen Dank.');
                                resolve({confirmed: true, isActivePharmacyConfirmed: null});
                                return;
                            }
                            void this.apiService.presentSuccessToast(`Erfolgreich bestätigt für Apotheke ${acceptedPharmacy.apiUser} ${acceptedPharmacy.name}, vielen Dank.`);
                            resolve({confirmed: true, isActivePharmacyConfirmed: activePharmacy.pharmacyStoreId.toString() === result.data['ConfirmTermsAndConditions']?.pharmacyStoreId.toString()});
                    } else {
                        void this.apiService.presentErrorToastNoDuration(result?.errors, 'Der angegebene Link ist ungültig oder abgelaufen. Bitte versuchen Sie es erneut.');
                        resolve({confirmed: false, isActivePharmacyConfirmed: null});
                    }
                },
                error: (err) => {
                    void this.apiService.presentErrorToastNoDuration(err, 'Der angegebene Link ist ungültig oder abgelaufen. Bitte versuchen Sie es erneut.');
                    resolve({confirmed: false, isActivePharmacyConfirmed: null});
                }
            });
        });
    }
}
