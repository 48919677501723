import { Directive, HostListener } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

/**
 * The trim accessor for writing trimmed value and listening to changes that is
 * used by the {link NgModel}, {link FormControlDirective}, and
 * {link FormControlName} directives.
 */
@Directive({
    // tslint:disable-next-line:directive-selector
    selector: `
 ion-searchbar:not([readonly]):not(.ng-trim-ignore),
 ion-input:not([type=checkbox]):not([type=radio]):not([type=password]):not(.ng-trim-ignore),
 input:not([type=checkbox]):not([type=radio]):not([type=password]):not(.ng-trim-ignore),
 ion-textarea:not(.ng-trim-ignore),
 quill-editor,
 :not([readonly]):not(.ng-trim-ignore)[ngDefaultControl]’
 `,})
export class TrimInputsDirective implements ControlValueAccessor {
@HostListener('ionBlur', ['$event.target.value'])
    onIonBlur(val: string) {
        this.writeValue(val);
    }
@HostListener('blur', ['$event.target.value'])
    onBlurInput(val) {
        if (typeof val === 'string') {
            // native input has already trimmed values and doesn't override no change values
            // super.writeValue(val + '.');
            this.writeValue(val);
        }
    }
@HostListener('onBlur', ['$event.editor.container.textContent'])
    onBlur(val: string) {
        this.writeValue(val);
    }

    writeValue(value: any): void {
        if (typeof value === 'string') {
            value = value.trim();
           // super.writeValue(value);
        }
    }

    registerOnChange(fn: any): void {
    }

    registerOnTouched(fn: any): void {
    }
}
