import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

import { ButtonInputInterface, ButtonClickResponseInterface } from '../../core.interfaces';
import { ButtonTypes, matomoIdsEnum, TooltipColorSchemeEnum, UserPopoverButtonTypes } from '../../core.enums';


/**
 *
 * Usage:
 * <app-widget>
 *   <div header></div>
 *   <div content></div>
 *   <div footer></div>
 * </app-widget>
 */
@Component({
    selector: 'app-popover-actions',
    templateUrl: './popover-actions.component.html',
    styleUrls: ['./popover-actions.component.scss'],
})


export class PopoverActionsComponent implements OnInit {
    @Input() buttonArray: Array<ButtonInputInterface> = [];
    @Output() buttonClickEvent = new EventEmitter<ButtonClickResponseInterface>();

    constructor() { }

    matomoId = matomoIdsEnum.logout;
    logoutButton = ButtonTypes.LOGOUT;
    changeStoreButton = UserPopoverButtonTypes.CHANGE_STORE;
    tooltipColorSchemeDefault = TooltipColorSchemeEnum.DEFAULT;
    labelMaxDigits: string = 'max-digits-none';

    ngOnInit() { 

        // Get maximum digits of apiUser in Change Store button array
        let maxDigits: number = this.buttonArray
            .filter(button => !button.isHeadline && button.code === this.changeStoreButton)
            .map(button => button.payload.apiUser.length)
            .reduce((a, b) => Math.max(a, b), 0);

        this.labelMaxDigits = `max-digits-${maxDigits}`;
    }

    /**
     * called if action button was clicked
     *
     * @param event - Click event
     * @param buttonCode - Code of button
     * @param payload - Payload object
     */
    onButtonClick(event, buttonCode, payload) {
        const eventParameters: ButtonClickResponseInterface = {
            code: buttonCode,
            event,
            payload
        };
        this.buttonClickEvent.emit(eventParameters);
    }

}
