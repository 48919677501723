import { Injectable, computed, signal } from '@angular/core';
import { SecurityStateVarInterface } from '../../interfaces/settings.interface';
import { toObservable } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root',
})
export class SecurityStateVar {
    private _securityState = signal<SecurityStateVarInterface>({
        isLocked: true,
        hasPin: true,
    });
    public securityState = this._securityState.asReadonly();
    public securityStateObservable = toObservable(this.securityState);

    public isLocked = computed(() => this.securityState()?.isLocked);
    public hasPin = computed(() => this.securityState()?.hasPin);

    set(isLocked: boolean, hasPin: boolean = true) {
        this._securityState.set({ isLocked, hasPin });
    }
}
