export enum ReturnsStatusEnum {
    all = 'Alle',
    goodsReturned = 'Ware zurück',
    credited = 'gutgeschrieben',
    processing = 'in Bearbeitung',
    withoutCalculation = 'ohne Berechnung',
    returnMissing = 'Retoure fehlt',
    cancelled = 'Storniert Kunde',
    destroyed = 'vernichtet',
}

export enum ReturnsTypeEnum {
    all = 'Alle',
    withdrawal = 'Rücknahme',
    recall = 'Rückruf',
    stockDefect = 'Lagerfehler',
    return = 'Retoure'
}

export enum ReturnGoodTypesEnum {
    btm ='BtM',
    dangerGoods = 'Gefahrgut',
    highPrised = 'Hochpreiser',
    coolingGoods = 'Kühlware'
}

export enum ReturnGoodMaxSlides {
    maxSlides = 5
}
