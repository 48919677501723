export enum CommunicationZoneFilterEnum {
    myRequests = 'myRequests',
    myRequestsSolved = 'myRequestsSolved',
    myRequestsUnsolved = 'myRequestsUnsolved',
    allRequests = 'allRequests',
    allRequestsSolved = 'allRequestsSolved',
    allRequestsUnsolved = 'allRequestsUnsolved'
}

export enum CommunicationZoneReferenceTypeEnum {
    LS_DOCUMENT = 'LS_DOCUMENT',
    CH_DOCUMENT = 'CH_DOCUMENT',
    RE_DOCUMENT = 'RE_DOCUMENT',
    RETURNS = 'RETURNS',
    ORDERS = 'ORDERS'
}

