export enum BulkActionCodeEnum {
    DEFAULT = 'default',
    CANCEL = 'cancel',
    EXTEND = 'extend',
    DISPO_ACKNOWLEDGE = 'dispoAcknowledge',
    ARCHIVE = 'archive',
    DELETE = 'delete',
    MARK_AS_UNREAD = 'markAsUnread',
    MARK_AS_READ = 'markAsRead',

}

export enum BulkOptionCodeEnum {
    ACTIVATE = 'activate',
    DEACTIVATE = 'deactivate',
    SELECT_ALL = 'selectAll',
    SELECT_NONE = 'selectNone'
}


export enum BulkActionTypeEnum {
    ORDERS = 'orders'
}

export enum OrderBulkTypeForApi {
    CANCEL_ORDER = 'cancelOrder',
    ADDITIONAL_EXTEND = 'additionalExtend',
    DISPO_ACKNOWLEDGE = 'dispoAcknowledge'
}
