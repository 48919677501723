import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { NotificationQueries } from '../store/graphql/queries/notification.graphql';
import { NotificationReminderInterface } from '../interfaces/notification.interface';
import { environment } from '../../../environments/environment';
import { compareDates, endOf, startOf } from '../formatting/date.formatting';
import { CompareDateEnum } from '../core.enums';
import { NotificationRemindersVar } from '../store/locals/notificationReminders.var';
import { unsubscribe, unsubscribeAll } from '../util/subscriptions.util';

@Injectable({
    providedIn: 'root',
})
export class NotificationReminderService {

    constructor(
        private notificationRemindersVar: NotificationRemindersVar,
        private notificationQueries: NotificationQueries,
    ) {
        this.initLocalNotificationReminder();
    }
    // The IDs of the intervals need to be static to be the same in every object instance. This enables interval clearing
    // outside of this service, e.g. in login.page via login.service. It also prevents multiple intervals per use case.
    static localNotificationReminderInterval;
    static notificationReminderInterval;

    reminder: Array<NotificationReminderInterface> = [];

    localNotificationReminderSubscription: Subscription;

    notificationReminderSubscription: Subscription;
    notificationReminderObservable: Observable<Array<NotificationReminderInterface>>;

    /**
     * clears all intervals
     */
    static clearIntervals = () => {
        clearInterval(NotificationReminderService.notificationReminderInterval);
        clearInterval(NotificationReminderService.localNotificationReminderInterval);
    }

    /**
     * inits local notification reminder
     */
    private initLocalNotificationReminder() {
        this.notificationReminderObservable = new Observable(subscriber => {
            this.callLocalNotificationReminderQuery(subscriber);
            clearInterval(NotificationReminderService.localNotificationReminderInterval);
            NotificationReminderService.localNotificationReminderInterval = setInterval(_ => {
                this.callLocalNotificationReminderQuery(subscriber);
            }, environment.pollingInterval.notificationReminder);
        });
    }

    /**
     * call local notification query
     */
    private callLocalNotificationReminderQuery(subscriber) {
        const nowAsString = endOf('minute', true, null, 1, true);
        unsubscribe(this.localNotificationReminderSubscription);
        this.localNotificationReminderSubscription = this.notificationRemindersVar.get()
            .subscribe((notificationReminders: Array<NotificationReminderInterface>) => {
                if(notificationReminders && Array.isArray(notificationReminders)) {
                    subscriber.next(notificationReminders
                        .filter(reminder => compareDates(nowAsString, reminder.reminder, CompareDateEnum.isSameOrAfter))
                        .sort((a, b) => (
                            a.reminder < b.reminder ? -1 : (a.reminder > b.reminder ? 1 : 0)
                        )));
                } else {
                    subscriber.next([]);
                }
            });
    }

    /**
     * get local notification reminder
     */
    getLocalNotificationReminder(): Observable<Array<NotificationReminderInterface>> {
        return this.notificationReminderObservable;
    }

    /**
     * Stops all subscriptions
     */
    clearAllSubscribers = () => {
        unsubscribeAll([
            this.localNotificationReminderSubscription,
            this.notificationReminderSubscription
        ]);
    };

    /**
     * get notification reminder
     */
    getNotificationReminderPolling() {
        clearInterval(NotificationReminderService.notificationReminderInterval);
        this.callNotificationReminderQuery();
        NotificationReminderService.notificationReminderInterval = setInterval(_ => {
            this.callNotificationReminderQuery();
        }, environment.pollingInterval.notificationReminder);
    }

    /**
     * call notification query
     */
    public callNotificationReminderQuery() {
        unsubscribe(this.notificationReminderSubscription);
        this.notificationReminderSubscription = this.notificationQueries.getNotificationsReminder(
            startOf('minute', false, null, environment.reminder.notification.minutesBefore)
        ).subscribe((reminders: Array<NotificationReminderInterface>) => {
            if(reminders) {
                this.notificationRemindersVar.set(reminders);
            }
        });
    }
}
