import { createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Product } from '../../../../../essentials/types/src/product';
import { ShopProductSearchState } from '../state/shop-product-search.state';

const selectShopProductSearchState = (state: { shopProductSearch: ShopProductSearchState }) => state.shopProductSearch;

export const selectShopProductSearchTerm = createSelector(
  selectShopProductSearchState,
  (state: ShopProductSearchState) => state.searchTerm
);

export const selectShopProductLimitOfLastSearch = createSelector(
  selectShopProductSearchState,
  (state: ShopProductSearchState) => state.limitOfLastSearch
);

export const selectShopProductSearchResults = createSelector(
  selectShopProductSearchState,
  (state: ShopProductSearchState) => state.searchResults
);

export const selectShopProductRequestState = createSelector(
  selectShopProductSearchState,
  (state: ShopProductSearchState) => state.requestState
);

export const pipeDeduplicatedShopProductSearchResults = pipe(
  select(selectShopProductSearchResults),
  map((searchResults) => deduplicateSearchResults(searchResults)),
  shareReplay(1)
);

const deduplicateSearchResults = (allResults: Product[]): Product[] => {
  const deduplicatedResults: Product[] = [];
  for (const result of allResults) {
    const indexOfExistingEntryWithSameName = deduplicatedResults.findIndex(
      (existingEntry) => existingEntry.name && existingEntry.name === result.name
    );
    if (indexOfExistingEntryWithSameName === -1) {
      deduplicatedResults.push(result);
    } else {
      const existingEntryWithSameName = deduplicatedResults[indexOfExistingEntryWithSameName] as Product;
      if (result.price && (!existingEntryWithSameName.price || result.price < existingEntryWithSameName.price)) {
        deduplicatedResults[indexOfExistingEntryWithSameName] = result;
      }
    }
  }
  return deduplicatedResults;
};
