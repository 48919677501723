import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { SanavendiDetailsInterface } from '../../../interfaces/sanavendi.interface';

export const GetSanavendiDetails = gql`
    query GetSanavendiDetails {
        getSanavendiDetails {
            data {
                calculationMethodName
                margin
                statisticsLink
                downloadLink
            }
            statusCode
        }
    }
`;

export const AllSanavendiQueries = [
    GetSanavendiDetails
];

@Injectable()
export class SanavendiQueries {
    constructor(
        private apollo: Apollo
    ) {}

    public getSanavendiDetails(fetchPolicy: QueryFetchPolicy = QueryFetchPolicy.CACHE_AND_NETWORK) {
        return this.apollo.watchQuery({
            query: GetSanavendiDetails,
            variables: {},
            fetchPolicy
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['getSanavendiDetails'])) as Observable<SanavendiDetailsInterface>;
    }
}
