import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';

export const analyticsSubmitMessage = createAction(`${ActionType.Analytics}: User submits message`);

export const analyticsLoginUser = createAction(`${ActionType.Analytics}: Login user`);

export const analyticsUserLoginComplete = createAction(`${ActionType.Analytics}: User login complete`);

export const analyticsStartResetKeyPair = createAction(`${ActionType.Analytics}: Start reset key pair`);

export const analyticsCompletedResetKeyPair = createAction(`${ActionType.Analytics}: Completed reset key pair`);

export const analyticsNewSession = createAction(`${ActionType.Analytics}: New User Session started`);

export const analyticsDuettEvent = createAction(
  `${ActionType.Analytics}: Send Duett event`,
  props<{ event: { [key: string]: any; event_type: string } }>()
);
