import { Component } from '@angular/core';
import { SconnectAuthService } from '../../core/services/authentication/sconnect.auth.service';
@Component({
    selector: 'app-login-callback',
    template: '',
})
export class LoginCallbackPage {

    constructor(
        private scAuthService : SconnectAuthService
    ) {}

    /**
     * default ionic function
     */
    ionViewDidEnter() {
        void this.scAuthService.tryResolveLogin();
    }
}
