import { Pipe, PipeTransform } from '@angular/core';
import { OrderTypesAllConfig, OrderTypesConfig } from '../config/order-types.config';
import { DocumentType } from '../enums/documents.enum';

/**
 * This pipe returns the description of a delivery status by its id
 */
@Pipe({
    name: 'orderTypeFilterLabel'
})
export class OrderTypeFilterLabelPipe implements PipeTransform {

    transform(val: DocumentType): string {
        const deliveryTypes = OrderTypesConfig.find(
            type => type.id.toUpperCase() === val.toUpperCase()
        );
        return deliveryTypes ? deliveryTypes.title : OrderTypesAllConfig.title;
    }
}
