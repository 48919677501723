export enum ActionButtonTypesEnum {
    NEW_ORDER_REQUEST = 'newOrderRequest',
    NEW_RETURN_REQUEST = 'newReturnRequest',
    NEW_APPOINTMENT = 'newAppointment',
    NEW_MEAMIND_QUESTION = 'newMeamindQuestion',
    NEW_COMMUNICATION_ZONE_ITEM = 'newCommunicationZoneItem'
}

export enum ActionButtonsEnum {
    ALL_ACTION_BUTTONS,
    ORDERS_ACTION_BUTTONS
}
