import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { VersionStateVar, APP_VERSION_KEY } from '../store/locals/versionState.var';

import { environment } from '../../../environments/environment';
import packageInfo from '../../../../../package.json';
import { SconnectAuthService } from './authentication/sconnect.auth.service';
import { VersionCheckService } from './version-check.service';
import { VersionType } from '../enums/version-type.enum';
import { AppRoutesEnum } from '../enums/routes.enum';
const CACHE_VERSION_KEY = 'sconnect-cache-version';

@Injectable({
    providedIn: 'root',
})
export class EnvironmentService {
    overrideReloadInformation = true;

    constructor(
        private http: HttpClient,
        private versionStateVar: VersionStateVar,
        private router: Router,
        private alertController: AlertController,
        private sconnectAuthService: SconnectAuthService,
        private versionCheckService: VersionCheckService
    ) {
    }
    appVersionSubscription: Subscription;
    checkVersionInterval;


    /**
     * Output some app information
     */
    static appInfo() {
        const cacheVersion = parseInt(localStorage.getItem(CACHE_VERSION_KEY), 10);
        const appVersion = parseInt(localStorage.getItem(APP_VERSION_KEY), 10);

        console.log(
            `
%cSanacorp Sconnect
%cApp Version: ${packageInfo.version}
Build Number: ${packageInfo.buildNumber}
Server App Version: ${appVersion}
Cache Version: ${cacheVersion}            ` ,
            'color:#b60258;font-size: 20px; font-weight: bold; margin-bottom: 10px',
            'line-height: 2; margin-bottom: 5px'
        );
    }

    /**
     * add check app version interval
     */
    async initializeChecks() {
        await this.checkForUpdate();
        setTimeout(() => {
            // we have to re-check the version as the reloadInformation can't be set to false immediately
            this.checkForUpdate();
        }, 200);
        this.clear();
        this.checkVersionInterval = setInterval(() => {
            this.checkForUpdate();
        }, 20000);
    }

    /**
     * clears the intervals - called before initializeChecks and on page leave
     */
    clear() {
        clearInterval(this.checkVersionInterval);
    }

    /**
     * Check app version
     */
    public checkForUpdate = async () => {
        const versionInfo = await this.versionCheckService.check();
        const isLoggedIn = await this.sconnectAuthService.isLoggedIn();

        if (!versionInfo) {
            return;
        }
        if(versionInfo.isNewVersionAvailable) {
            // Reload the app if the user isn't logged in
            if (!isLoggedIn) {

                this.versionStateVar.set({versionsId: versionInfo.latestVersion, reloadInformation: false});
                await this.sconnectAuthService.logoutAndRefresh(false);
            } else {
                const isWaiting = this.router.url.startsWith(AppRoutesEnum.waiting);
                // Fore reload the app
                if (versionInfo.forceReload || versionInfo.latestVersionType === VersionType.logoutReload) {
                    if (isWaiting) {
                        await this.sconnectAuthService.logoutAndRefresh();
                    } else {

                        this.versionStateVar.set({versionsId: versionInfo.latestVersion, reloadInformation: false});
                        await this.onReloadVersion();
                    }

                    // Handle different version types
                } else if (versionInfo.latestVersionType === VersionType.systemInformation) {
                    if (isWaiting) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 10000);
                    } else {

                        this.overrideReloadInformation = false;
                        this.versionStateVar.set({versionsId: versionInfo.latestVersion, reloadInformation: true});
                    }
                }
            }
        } else if (this.overrideReloadInformation) {
            // Set reload information to false if there is no new version available
            this.versionStateVar.set({versionsId: versionInfo.latestVersion, reloadInformation: false});
        }
    }

    /**
     * Check if the app version of the server matches the local app version.
     * Delete cache and logout the user if there is a new version available.
     *
     */
    private async onReloadVersion() {
        const alert = await this.alertController.create({
            header: 'Neue Sanacorp Connect Version',
            message: 'Es steht eine neue Version von Sanacorp Connect zur Verfügung. Die App wird neu geladen. Sie werden ggf. ausgeloggt.',
            buttons: [
                {
                    text: 'Ok',
                    role: 'cancel',
                    cssClass: 'primary',
                    handler: async () => {
                        await this.sconnectAuthService.logoutAndRefresh();
                    }
                }
            ]
        });
        await alert.present();
    }

    /**
     * Check the cache version of the user, reset it and redirect the user to login if necessary
     */
    checkCacheVersion(): Promise<void> {
        return new Promise(resolve => {
            const cacheVersion = parseInt(window.localStorage.getItem(CACHE_VERSION_KEY), 10);
            // No cache version available or not the same as defined
            if (!cacheVersion || cacheVersion !== environment.cacheVersion) {
                window.localStorage.setItem(CACHE_VERSION_KEY, environment.cacheVersion.toString());
                // Only delete cache if the version has changed (prevent endless deletion of indexedDB
                if (cacheVersion && parseInt(window.localStorage.getItem(CACHE_VERSION_KEY), 10) === environment.cacheVersion) {
                    setTimeout(() => {
                        void this.sconnectAuthService.logout(false);
                        resolve();
                    }, 1000);
                } else {
                    resolve();
                }
            } else {
                resolve();
            }
        });
    }
}

