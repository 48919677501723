/* tslint:disable:member-ordering */

import { Component, ElementRef, HostBinding, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { HintPositionEnum } from '../hint-position.enum';
import { NgxHintStorageService } from './ngx-hint-storage.service';

@Component({
  selector: 'ngx-hint',
  template: '',
  styleUrls: ['./ngx-hint.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NgxHintComponent {

  private ELEMENT_ID = 'ngx-hint';
  public hintRef!: ElementRef;
  hintPos: {top: number, left: number} = {top: 0, left: 0};

  /**
   * Content of the hint
   */
  @Input() content = '';

  /**
   * Position of the dot
   */
  @Input() dotPos: HintPositionEnum = HintPositionEnum.TOP;

  /**
   * Offset in pixels
   */
  @Input() dotPosOffset: {x: number, y: number} = {x: 0, y: 0};

  /**
   * Defines the area the hint appears. Prevents disappear of multiple hints with the same text.
   */
  @Input() area = '';

  /**
   * Optional: open hint on click, defaults to true
   * Use this parameter to prevent hint opening on click.
   */
  @Input() openOnClick = true;

  @HostBinding('class') get elementClass(){
    if(this.storageService.isHintViewed(this.content + this.area)) {
      return 'ngx-hint-viewed';
    }

    return 'ngx-hint-dot ngx-hint-dot-'+ this.dotPos.toString();
  }

  @HostBinding('style.transform') get marginTop() {
    const {x,y} = this.dotPosOffset;
    if (x && y) {
      return `translate(${this.dotPosOffset.x}px, ${this.dotPosOffset.y}px)`;
    }
    if (x) {
      return `translateX(${this.dotPosOffset.x}px)`;
    }
    if (y) {
      return `translateY(${this.dotPosOffset.y}px)`;
    }
    return '';
  }

  @HostListener('click') onMouseClick() {
    if (this.openOnClick) {
      this.hideHint();
      this.hintPos = {top: undefined, left: undefined};
      this.updateHint();
    }
  }

  @HostListener('document:click', ['$event'])
  onOutsideMouseClick(event) {
    const element = document.getElementById(this.ELEMENT_ID);
    if (!this.dotRef.nativeElement.contains(event.target)
    && element && !element.contains(event.target)
    && this.dotRef.nativeElement.tagName !== event.target.tagName) {
      this.hideHint(false);
    }
  }


  constructor(
      private dotRef:ElementRef,
      private storageService: NgxHintStorageService
  ) { }


  /**
   * Create hint with content and set attributes
   */
  createHint() {
    let hintElement = document.getElementById(this.ELEMENT_ID);
    if(!hintElement) {
      hintElement = document.createElement('div');
      hintElement.id = this.ELEMENT_ID;
      document.body.append(hintElement);
    }

    hintElement.className = 'ngx-hint-container';
    hintElement.innerHTML =
        `
            <div class="ngx-hint-arrow"></div>
            <p>${this.content}</p>
            <button id="ngx-hint-button">Nicht mehr anzeigen</button>
        `;

    document.getElementById('ngx-hint-button').addEventListener('click', () => this.hideHint(true));
    return hintElement;
  }


  /**
   * Update Hint position
   */
  updateHint() {
    const hintElement = this.createHint();
    const dotPos = this.dotRef.nativeElement.getBoundingClientRect();
    const winH = window.innerHeight;
    const winW = window.innerWidth;

    let arrowPos: string;

    if(dotPos.x >= winW / 2) {
      this.hintPos.left = dotPos.x - hintElement.clientWidth + dotPos.width;
      arrowPos = 'right';
    } else {
      this.hintPos.left = dotPos.x - dotPos.width;
      arrowPos = 'left';
    }
    if(dotPos.y >= winH / 2) {
      this.hintPos.top = dotPos.y - hintElement.clientHeight - dotPos.height;
      arrowPos += '-bottom';
    } else {
      this.hintPos.top = dotPos.y + dotPos.height;
      arrowPos += '-top';
    }


    hintElement.style.top = `${this.hintPos.top}px`;
    hintElement.style.left = `${this.hintPos.left}px`;
    hintElement.classList.add('ngx-hint-arrow-' + arrowPos);

  }

  /**
   * Remove the current hint
   */
  hideHint(markAsViews = false) {
    const hintElement = document.getElementById(this.ELEMENT_ID);
    if(hintElement) {
      document.getElementById('ngx-hint-button').removeEventListener('click',() => this.hideHint(true));
      hintElement.remove();
      if(markAsViews) {
        this.storageService.setHintViewed(this.content + this.area);
      }
    }
  }
}
