import { Injectable } from '@angular/core';
import { BulkActionCodeEnum } from '../enums/bulk-action.enum';
import { OrderStatus, OrderStatusFilter, OrderType } from '../enums/orders.enum';
import { OrderFiltersInterface } from '../interfaces/order.interface';
import { TableTypeEnum } from '../enums/table-type.enum';
import { formatDateTimeToMoment, today } from '../formatting/date.formatting';
import { DateRangeOptionCodes } from '../enums/date-range.enum';

@Injectable({
    providedIn: 'root'
})
export class BulkActionService {
        constructor() { }

    getBulkActionFilters(selectedBulkAction: BulkActionCodeEnum, tableType: TableTypeEnum, currentFilters: OrderFiltersInterface) {
        if (tableType === TableTypeEnum.Order || tableType === TableTypeEnum.OrderBulk) {
            const filters: OrderFiltersInterface = {};
            switch (selectedBulkAction) {
                case BulkActionCodeEnum.DISPO_ACKNOWLEDGE:
                    filters.type = OrderType.DISPOSITION;
                    filters.status = OrderStatusFilter.DELEGATED;
                    break;
                case BulkActionCodeEnum.CANCEL:
                    filters.type = OrderType.ADDITIONAL;
                    filters.status = `${OrderStatus.PENDING},${OrderStatus.DELEGATED},${OrderStatus.PARTIALLY_COMPLETED}`; // Do NOT insert whitespace
                    break;
                case BulkActionCodeEnum.EXTEND:
                    const maxExtensionDays = 180;
                    const maxExtensionDate = formatDateTimeToMoment(today()).add(maxExtensionDays, 'days');

                    filters.type = OrderType.ADDITIONAL;
                    // Do NOT insert whitespace
                    filters.status = `${OrderStatus.PENDING},${OrderStatus.ENROUTE},${OrderStatus.DELEGATED},${OrderStatus.PARTIALLY_COMPLETED}`;

                    filters.expiryDateOption = DateRangeOptionCodes.individual;
                    // If from-date is before today, set it to today, otherwise use the existing from-date filter.
                    if(formatDateTimeToMoment(currentFilters.expiryDateFrom).isBefore(today()) || !currentFilters.expiryDateFrom) {
                        filters.expiryDateFrom = today();
                    } else {
                        filters.expiryDateFrom = currentFilters.expiryDateFrom;
                    }

                    // If to-date is after today + 180 days, set it to today + 180, otherwise use the existing to-date filter.
                    if(formatDateTimeToMoment(currentFilters.expiryDateTo).isAfter(maxExtensionDate) || !currentFilters.expiryDateTo) {
                        filters.expiryDateTo = maxExtensionDate.toISOString();
                    } else {
                        filters.expiryDateTo = currentFilters.expiryDateTo;
                    }
                    // Check if the from-date is after the to-date, if so, set the from-date to today.
                    if (formatDateTimeToMoment(filters.expiryDateFrom).isAfter(formatDateTimeToMoment(filters.expiryDateTo))) {
                        filters.expiryDateFrom = today();
                    }

                    break;
            }
            return filters;
        }
        return null;
    }
}
