import { Injectable, inject } from '@angular/core';

import { MATOMO_CONFIGURATION } from './matomo-configuration';

declare global {
  /**
   * Extend Window interface in order to introduce the Matomo _paq attribute
   */
  interface Window {
    _paq: any;
    _mtm: any;
  }
}

/**
 * Service for injecting the Matomo tracker in the application.
 * This service shall no longer be used directly within an application.
 */
@Injectable()
export class MatomoInjector {
  /**
   * Matomo configuration provided by DI
   */
  private readonly configuration = inject(MATOMO_CONFIGURATION);

  /**
   * Creates an instance of MatomoInjector.
   */
  constructor() {
    try {
      window['_paq'] = window['_paq'] || (!!this.configuration.scriptUrl ? [] : { push: () => {} });
      window._mtm = window._mtm || [];
    } catch (e) {
      if (!(e instanceof ReferenceError)) {
        throw e;
      }
    }
  }

  /**
   * Configures and injects the Matomo tracker in the DOM.
   *
   * @param {string} [url=null] - The URL of the Matomo server. Defaults to null, in which case it will use the scriptURL
   * defined in the configuration.
   * @param {Array} [customProperties] - An optional array of custom properties.
   * @throws {Error} - This method will throw an exception if an error occurs that is not a ReferenceError.
   * @returns {void}
   */
  init(url: string = null, customProperties?: Array<{}>): void {
    try {
      // use the provided url over the one from the configuration/environment, unless the provided url is null
      const matomoUrl = url ?? this.configuration.scriptUrl;
      if (this.configuration?.requireConsent === true) {
        window['_paq'].push(['requireConsent']);
      } else if (this.configuration?.requireCookieConsent === true) {
        window['_paq'].push(['requireCookieConsent']);
      }
      if (this.configuration?.skipTrackingInitialPageView === false) {
        window['_paq'].push(['trackPageView']);
        if (
          this.configuration?.trackLinks === true &&
          this.configuration?.routeTracking?.enable === false
        ) {
          setTimeout(() => {
            window['_paq'].push([
              'enableLinkTracking',
              this.configuration?.trackLinkValue ?? false,
            ]);
          }, 0);
        }
      }

      if (customProperties) {
        window['_paq'].push(...customProperties);
      }
      if (this.configuration.trackers?.length) {
        const [mainTracker, ...otherTrackers] = this.configuration.trackers;
        window['_paq'].push(['setTrackerUrl', mainTracker.trackerUrl]);
        window['_paq'].push(['setSiteId', mainTracker.siteId.toString()]);
        otherTrackers.forEach((tracker) =>
          window['_paq'].push(['addTracker', tracker.trackerUrl, tracker.siteId.toString()])
        );
      }
      if (!!matomoUrl) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = matomoUrl;
        const firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode?.insertBefore(script, firstScript);
      }
    } catch (e) {
      if (!(e instanceof ReferenceError)) {
        throw e;
      }
    }
  }
}
