import { Pipe, PipeTransform } from '@angular/core';
import { PharmaciesEditModalInterface, PharmacyRights, UserAdministrationInterface } from '../interfaces/user-administration.interface';
import { PharmacyStoreNamesInterface } from '../interfaces/customer.interface';
import { UserAccessModulesConfig } from '../config/user-administration.config';
import { UserAccessModulesEnum } from '../enums/user-administration.enum';

@Pipe({
    name: 'userAdminPharmacyOwnerAndCount'
})
export class UserAdminPharmacyOwnerAndCountPipe implements PipeTransform {
    transform(ownerEmail: string, user: UserAdministrationInterface): number {
        if (user && ownerEmail === user.email) {
            return -1;
        }
        const pharmacies = user.pharmacies.filter(pharmacy => {
            const modules = UserAccessModulesConfig.filter(module => (
                pharmacy[module] && pharmacy[module].length > 0
            ));
            return modules && modules.length > 0;
        });

        return pharmacies ? pharmacies.length : 0;
    }
}

@Pipe({
    name: 'userAdminGetPharmacyStoreName'
})
export class UserAdminGetPharmacyStoreNamePipe implements PipeTransform {
    transform(apiUser: string, pharmacyStoreNames: PharmacyStoreNamesInterface[]): string {
        return pharmacyStoreNames.find(item => item.apiUser === apiUser).name;
    }
}


@Pipe({
    name: 'userAdminIsCurrentUser'
})
export class UserAdminIsCurrentUserPipe implements PipeTransform {
    transform(email: string, currentUserMail: string): boolean {
        return currentUserMail && email && currentUserMail.toLowerCase() === email.toLowerCase();
    }
}

@Pipe({
    name: 'userAdminCanActivate'
})
export class UserAdminCanActivatePipe implements PipeTransform {
    transform(currentModule: PharmacyRights, pharmacies: PharmaciesEditModalInterface[], pipeRefresh: number): boolean {
        if (currentModule.isDisabled) {
            return false;
        }
        if (currentModule.accessModule === UserAccessModulesEnum.SACADEMY) {
            const activatedPharmacy = pharmacies.find(pharmacy => pharmacy.rights.find(right => (
                right.accessModule === currentModule.accessModule && right.isActivated
            )));
            if (activatedPharmacy) {
                // only true, if activatedPharmacy.rights equals currentModule
                return currentModule.isActivated;
            }
        }
        return true;
    }
}
