import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MarkdownComponent } from 'ngx-markdown';
import { ModalComponent } from './modal.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        MarkdownComponent
    ],
    exports: [
        ModalComponent
    ],
    declarations: [
        ModalComponent
    ]
})
export class ModalModule {
}
