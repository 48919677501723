import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { delay, map, throttleTime, withLatestFrom } from 'rxjs/operators';
import {
  emitUserTyping,
  startSelfTyping,
  stopSelfTyping,
} from '../../../../../../store/src/common-store/chat-store/actions/chat-typing.actions';
import { CommonState } from '../../../../../../store/src/common-store/common.state';
import { selectFrontendSessionId } from '../../../../../../store/src/common-store/device-store/selectors/device.selectors';
import { AppsyncMessageService } from '../../../services/appsync/appsync-message.service';

@Injectable()
export class ChatTypingEffects {
  emitTyping$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(emitUserTyping),
        throttleTime(1500),
        withLatestFrom(this.store.select(selectFrontendSessionId)),
        map(async ([{ conversationId, ownCognitoId, chatPartnerCognitoId }, frontendSessionId]) => {
          if (conversationId && ownCognitoId && frontendSessionId) {
            await this.appsyncMessageService.emitStartTyping({
              participantId: ownCognitoId,
              conversationId,
              otherParticipantId: chatPartnerCognitoId,
              frontendSessionId,
            });
          }
        })
      ),
    { dispatch: false }
  );

  updateSelfTypingInStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startSelfTyping),
      delay(3000),
      map(({ conversationId, timestamp }) =>
        stopSelfTyping({
          conversationId,
          startTimestamp: timestamp,
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<CommonState>,
    private appsyncMessageService: AppsyncMessageService
  ) {}
}
