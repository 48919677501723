import gql from 'graphql-tag';

export default gql`
  query getPharmacyImage($cognitoId: ID!, $imageType: PharmacyImageType!) {
    getPharmacyImage(cognitoId: $cognitoId, imageType: $imageType) {
      __typename
      fileId
      image
    }
  }
`;
