import { isMeaUser } from '../../types/src/chatUser';
import { Conversation } from '../../types/src/conversation';

export class ChatPartnerMetadataUtil {
  public static getChatPartnerIdAsEnduser(conversation: Conversation): string {
    const chatPartner = conversation.chatPartner;
    return isMeaUser(chatPartner) ? chatPartner.cognitoId : '';
  }

  public static getChatPartnerIdAsPharmacy(conversation: Conversation): string {
    const chatPartner = conversation.chatPartner;
    return isMeaUser(chatPartner)
      ? ChatPartnerMetadataUtil.getUserId(chatPartner)
      : `conversationId_${conversation.id}`;
  }

  public static getUserId({ cognitoId }: { cognitoId: string }) {
    return `cognitoId_${cognitoId}`;
  }
}
