import { Pipe, PipeTransform } from '@angular/core';
import { OrderInterface } from '../interfaces/order.interface';

@Pipe({
    name: 'arraySplit'
})
export class ArraySplitPipe implements PipeTransform {

    transform(value: any, maxSize: number = 1): Array<any> {

        if (!value) {
            return [];
        }

        const splittedArray = [];
        for (let i = 0; i < value.length; i += maxSize) {
            splittedArray.push(value.slice(i, i + maxSize));
        }
        return splittedArray;
    }

}

@Pipe({
    name: 'arraySplitOrders'
})
export class ArraySplitOrdersPipe implements PipeTransform {

    transform(value: Array<OrderInterface>, maxSize: number = 1): Array<Array<OrderInterface>> {

        if (!value) {
            return [];
        }

        const splittedArray = [];
        for (let i = 0; i < value.length; i += maxSize) {
            splittedArray.push(value.slice(i, i + maxSize));
        }
        return splittedArray as Array<Array<OrderInterface>>;
    }

}
