import { Pipe, PipeTransform } from '@angular/core';


/**
 * Filters a list of strings by the given filter (string).
 * Use the limit parameter to splice the amount of returned values.
 *
 * Usage:
 *  ... | filterArray:array:'test':'title'
 */
@Pipe({
    name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {

    transform(elements: Array<any>, filter: string, key: string, ignoreCase = false): Array<any> {
        if(elements && filter) {
            if(ignoreCase) {
                return elements.filter(element => element[key] === filter);
            } else {
                const filterInLowerCase = filter.toLowerCase();
                return elements.filter(element => element[key].toLowerCase().indexOf(filterInLowerCase) > -1);
            }
        } else {
            return elements;
        }
    }
}
