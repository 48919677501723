import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { from, map } from 'rxjs';
import { ApiService } from '../../../services/api.service';

export const UpsertSanavendiRegisterMutation = gql`
    mutation insertSanavendiRegisterMutation {
        SanavendiRegister {
            status
            message
        }
    }
`;
export const AllSanavendiMutations = [
    UpsertSanavendiRegisterMutation
];

@Injectable()
export class SanavendiMutations {
    constructor(
        private apollo: Apollo,
        private apiService: ApiService,
    ) {}

    sanavendiRegister(callable) {
        this.apollo.mutate( {
            mutation: UpsertSanavendiRegisterMutation
        }).subscribe({
            next: result => {
                from([result])
                    .pipe(map(d => d?.data && d?.data['SanavendiRegister'] && d?.data['SanavendiRegister']['status']))
                    .subscribe((status: string) => {
                        if (!status || status === 'ERROR' || !!result['errors']) {
                            void this.apiService.presentErrorToast(
                                result['errors'],
                                'Die Anfrage konnte nicht an Ihr SanaVendi-Team übermittelt werden.'
                            );
                        } else {
                            if (callable) {
                                callable();
                            }
                            void this.apiService.presentSuccessToastNoDuration('Ihre Anfrage wurde erfolgreich übermittelt und wird nun von Ihrem SanaVendi-Team bearbeitet.');
                        }
                    }).unsubscribe();
            },
            error: error => {
                void this.apiService.presentErrorToast(error, 'Die Anfrage konnte nicht an Ihr SanaVendi-Team übermittelt werden.');
            }
        });
    }

}
