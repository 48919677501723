import { Dayjs } from 'dayjs';
import Address from './address';
import { BackendMeaUser } from './backendMeaUser';
import { MeaUser } from './chatUser';
import { EmergencyOpeningStatus } from './openingHours';

export interface Pharmacy {
  // pharmacyId
  id: string;
  cognitoId: string;
  address: Address;
  contactEmail?: string;
  sanacorpCustomerId: string;
  // array of opening timings, e.g.
  // ['Closed', 'Closed', 'Closed', '08:00 -- 14:00, 15:00 -- 20:00', 'Closed', '08:00 -- 14:00', 'Closed'];
  openingHours: string[];
  notdienst?: NotdienstEntry[];
  contactName: string;
  description?: string;
  name: string;
  shippingDetails: string;
  telephone: string;
  website: string;
  googlePlacesId: string;
  disabled: boolean;
  distance?: number;
  privacyPolicyLink?: string;
  onlineShop?: string;
  shopApiBaseUrl?: string;
  detailsPage?: string;
}

export interface PharmacyWithChatUser extends Pharmacy {
  pharmacyChatUser: Omit<MeaUser, 'pharmacy'>;
}

export interface BackendPharmacyWithChatUser extends Pharmacy {
  pharmacyChatUser: Omit<BackendMeaUser, 'pharmacy'>;
}

export enum OpeningStatusClass {
  Open = 'status-open',
  Closed = 'status-closed',
}

export interface PharmacyOpeningStatus {
  text: string;
  class: OpeningStatusClass;
}

export interface PharmacyDynamicProperties {
  isOpen: boolean;
  wasOpen: boolean;
  willOpenSoon: boolean;
  currCloseDiff: number;
  openingHoursFormatted: OpeningHoursWeek;
  currRange: OpeningHoursRange | null;
  nextRange: OpeningHoursRange | null;
  isOnEmergencyDuty: boolean;
  emergencyDutyStartsSoon: boolean;
  emergencyDutyStartDiff: number;
  emergencyDutyEndsSoon: boolean;
  emergencyDutyToday: EmergencyHoursRange | null;
  emergencyHoursFormatted: UpcomingEmergencyHours;
  emergencyOpeningStatus: EmergencyOpeningStatus;
  isOnHoliday: boolean;
  vacationRanges: UpcomingVacationRanges;
  isOnVacation: boolean;
  vacationEndDate: Dayjs | null;
}

export type OpeningHoursWeek = OpeningHoursDay[];
export type OpeningHoursDay = OpeningHoursEntry[];

export interface OpeningHoursEntry {
  range: OpeningHoursRange;
  wasOpen: boolean;
  isOpen: boolean;
  willOpen: boolean;
  closeDiff: number;
}

export interface OpeningHoursRange {
  from: Dayjs;
  to: Dayjs;
}

export type UpcomingEmergencyHours = EmergencyHoursRange[];

export interface EmergencyHoursRange {
  from: Dayjs;
  to: Dayjs;
}

export type UpcomingVacationRanges = VacationRange[];

export interface VacationRange {
  from: Dayjs;
  to: Dayjs;
}

export interface NotdienstEntry {
  von: string;
  bis: string;
}

export interface PharmacyLoginDetails {
  id: string;
  contactName?: string;
  cognitoId?: string;
}

export default Pharmacy;
