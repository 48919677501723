import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { BannerTypeEnum } from '../../../enums/banner.enum';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { BannerInterface } from '../../../interfaces/banner.interface';
import { formatStrapiBannerData } from '../utils';
import { FetchPolicyKeys as FPK } from '../../../enums/fetch-policy-keys.enum';
import { DataChangedKeys as DCK } from '../../../enums/data-changed-keys.enum';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { QueryWrapper } from '../query.wrapper';

export const GetBanners = (queryName) => gql`
    query ${queryName}($limit: Int, $filters: BannerFiltersInput, $sort: [String], $mediaLimit: Int) {
        banners(pagination: {limit: $limit}, filters: $filters, sort: $sort) {
            data {
                id
                attributes {
                    url
                    buttonTextMobile
                    mandatoryText
                    media(pagination: {limit: $mediaLimit}) {
                        id
                        size
                        image {
                            data {
                                id
                                attributes {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const AllBannerQueries = [
    GetBanners('test')
];

@Injectable()
export class BannerQueries extends QueryWrapper {

    fetchPolicies = {
        [FPK.getBanners]: QueryFetchPolicy.NETWORK_ONLY
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar
    ) {
        super(apollo, dataChangedVar, {
            [DCK.bannerDataChanged]: [FPK.getBanners]
        });
    }

    public getBanners(type: BannerTypeEnum): Observable<BannerInterface[]> {
        const fetchPolicyKey = FPK.getBanners;
        return this.apollo.watchQuery({
            query: GetBanners(fetchPolicyKey),
            variables: {
                limit: GraphQLLimits.banner, filters: {type: {eq: type}}, sort: ['id:asc'],
                mediaLimit: GraphQLLimits.bannerMedia
            },
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['banners']))
            .pipe(map(bannerData => formatStrapiBannerData(bannerData)))
            .pipe(map(d => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;}));
    }
}
