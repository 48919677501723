import gql from 'graphql-tag';

export default gql`
  mutation updateArchivedByEnduser($conversationId: ID!, $archivedByEnduser: Boolean!) {
    updateArchivedByEnduser(id: $conversationId, archivedByEnduser: $archivedByEnduser) {
      __typename
      id
      archivedByEnduser
    }
  }
`;
