import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus } from '../enums/orders.enum';
import { OrderStatusFilterConfig } from '../config/order-status.config';

/**
 * This pipe returns the description of a delivery status by its id
 */
@Pipe({
    name: 'orderStatusFilterLabel'
})
export class OrderStatusFilterLabelPipe implements PipeTransform {

    transform(val: OrderStatus): string {
        const deliveryStates = OrderStatusFilterConfig.find(
            status => status.id.toUpperCase() === val.toUpperCase()
        );

        return deliveryStates ? deliveryStates.title : '';
    }

}
