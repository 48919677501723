import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { CombinedPharmacy } from '../../../../essentials/types/src/combinedPharmacy';
import Pharmacy, { OpeningHoursDay, OpeningHoursWeek } from '../../../../essentials/types/src/pharmacy';
import { DynamicPropertiesService } from './dynamic-properties.service';

dayjs.extend(weekday);

@Injectable({ providedIn: 'root' })
export class OpeningHoursService {
  constructor(
    private dynamicPropertiesService: DynamicPropertiesService,
    private translateService: TranslateService
  ) {}

  public getAndFormatWeeklyOpeningHours(pharmacy: CombinedPharmacy | Pharmacy): string {
    const openingHoursFormatted = this.dynamicPropertiesService.getOpeningHoursWeek(pharmacy);

    return this.formatWeeklyOpeningHours(openingHoursFormatted).join('\n');
  }

  public getAndFormatTodaysOpeningHours(pharmacy: CombinedPharmacy | Pharmacy): string {
    const openingHoursFormatted = this.dynamicPropertiesService.getOpeningHoursWeek(pharmacy);
    const singleDayOpeningHours = openingHoursFormatted[dayjs().weekday()] as OpeningHoursDay;

    return this.formatDailyOpeningHours(singleDayOpeningHours, true);
  }

  public formatWeeklyOpeningHours(openingHours: OpeningHoursWeek): string[] {
    const lines: string[] = [];
    openingHours.forEach((day, index) => {
      const prefix = this.getWeekDayPrefix(index);
      lines.push(`${prefix}\t${this.formatDailyOpeningHours(day, false)}`);
    });
    return lines;
  }

  private formatDailyOpeningHours(day: OpeningHoursDay, continuous: boolean) {
    const from = this.translateService.instant('from');
    const to = this.translateService.instant('to');
    const and = this.translateService.instant('and');
    const oclock = this.translateService.instant('oclock');
    const closed = this.translateService.instant('Closed');

    let line = '';
    if (day && day[0] && day[0].range.from && day[0].range.to) {
      line += continuous ? `${from} ` : '';
      day.forEach(({ range }, entryIndex) => {
        if (range.from && range.to) {
          line += `${range.from.format('HH:mm')} ${continuous ? `${oclock} ${to}` : '-'} ${range.to.format('HH:mm')} `;
          if (entryIndex < day.length - 1) {
            line += continuous ? `${oclock} ` : '';
            line += `${and} `;
          }
        }
      });
      line += oclock;
    } else {
      line += closed;
    }
    return line;
  }

  private getWeekDayPrefix(index: number) {
    switch (index) {
      case 0:
        return 'Mo:';
      case 1:
        return 'Di:';
      case 2:
        return 'Mi:';
      case 3:
        return 'Do:';
      case 4:
        return 'Fr:';
      case 5:
        return 'Sa:';
      case 6:
        return 'So:';
    }

    return undefined;
  }
}
