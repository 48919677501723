import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WidgetComponent } from './widget.component';
import { CoreModule } from '../../core/core.module';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        CoreModule,
        NgOptimizedImage
    ],
    exports: [
        WidgetComponent
    ],
    declarations: [
        WidgetComponent
    ]
})
export class WidgetModule {
}
