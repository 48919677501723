import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, IonicSlides } from '@ionic/angular';
import { Subscription } from 'rxjs';

// Core Files
import { CommunicationZoneMutations, DocumentQueries } from '../../../../../../core/core.store';
import {
    ContactPersonService,
    InputValidationService,
    ModalService,
    OrderService,
    UtilService
} from '../../../../../../core/core.services';
import {
    DocumentType,
    FieldLengthEnum, InputValidationFieldEnum, InputValidationTableNameEnum,
    matomoIdsEnum,
    ModalClassEnum,
    ReturnGoodMaxSlides,
    ReturnGoodTypesEnum
} from '../../../../../../core/core.enums';
import { FieldLengths, TOOLTIPS } from '../../../../../../core/core.config';
import { ToastService } from '../../../../../../core/services/toast.service';
import {
    ReturnRequestContactNameValidator,
    ReturnRequestDeliveryDateValidator,
    ReturnRequestDeliveryNrValidator,
    ReturnRequestExpirationValidator,
    ReturnRequestPZNValidator,
    ReturnRequestQtyValidator,
    ReturnRequestReasonValidator
} from '../../../../../../core/validators/return-request.validator';
import { formatDateTimeToDate, getDateBeforeXDays, today } from '../../../../../../core/formatting/date.formatting';
import { ReturnsRequestSlide } from '../../../../../../core/interfaces/returns.interface';
import { DocumentNumberSelectionInterface } from '../../../../../../core/interfaces/document.interface';
import { unsubscribe, unsubscribeAll } from '../../../../../../core/core.utils';
import { InputValidationInterface } from '../../../../../../core/interfaces/input-validation.interface';

@Component({
    selector: 'app-returns-request',
    templateUrl: './returns-request.component.html',
    styleUrls: ['./returns-request.component.scss'],
})
export class ReturnsRequestComponent implements OnInit {
    // Adds class to modal with auto height
    static modalClass = ModalClassEnum.mediumHigh;

    @ViewChild('returnGoodsSlider') returnGoodsSlider;

    swiperModules = [IonicSlides];
    constructor(
        private alertController: AlertController,
        private contactPersonService: ContactPersonService,
        private communicationZoneMutation: CommunicationZoneMutations,
        private documentQueries: DocumentQueries,
        public formBuilder: FormBuilder,
        private modalService: ModalService,
        private validationService: InputValidationService,
        private toastService: ToastService,
        private utilService: UtilService
    ) {
        // This needs to be in the constructor, else debounce doesn't work
        this.getDeliveryNrCollection = utilService.debounce(this.getDeliveryNrCollection, 500);
    }

    tooltips = TOOLTIPS;
    matomoClass = matomoIdsEnum.returnsRequest;
    returnGoodTypesEnum = ReturnGoodTypesEnum;
    returnGoodsSelectValue: ReturnGoodTypesEnum;
    deliveryNrCollection: Array<DocumentNumberSelectionInterface> = [];
    contactNames: Array<string>;
    showContactList = false;
    showDeliveryNumberList = false;
    validationFieldsEnum = InputValidationFieldEnum;
    validators: InputValidationInterface[];
    validatorSubscription: Subscription;

    today;
    dateSixWeeksAgo;
    fromDate: string;

    formGroup: FormGroup;

    activeSlide = 0;
    slides: ReturnsRequestSlide[] = [];
    initialSlide: ReturnsRequestSlide = {
        contactName: '',
        remarks: '',
        type: ReturnGoodTypesEnum.btm,
        quantity: null,
        pzn: null,
        expirationDate: '',
        coolingChain: false,
        securPharm: false,
        reason: '',
        deliveryNr: '',
        deliveryDate: ''
    };

    fieldLengths = {
        remarks: FieldLengths[FieldLengthEnum.returnRequestRemarks]
    };

    maxSlides = ReturnGoodMaxSlides.maxSlides;
    alertEditMessage = 'Ihre letzte Eingabe enthält Fehler oder ist unvollständig. Wollen Sie die letzte Eingabe verwerfen?';
    alertRemoveMessage = 'Möchten Sie diese Retoure wirklich verwerfen?';

    deliveryNrCollectionSubscription: Subscription;

    ngOnInit() {
        this.slides.push(this.initialSlide);
        this.today = today();
        this.dateSixWeeksAgo = getDateBeforeXDays(6 * 7);
        this.returnGoodsSelectValue = ReturnGoodTypesEnum.btm;
        this.fromDate = this.dateSixWeeksAgo;   // BtM and cooling goods have a 6 weeks restriction, older products can not be returned
        this.setValidationFormGroup();
        unsubscribe(this.validatorSubscription);
        this.validatorSubscription = this.validationService.getInputValidators(InputValidationTableNameEnum.communicationZone)
            .subscribe(validators => {
                if (validators) {
                    this.validators = validators;
                    this.setValidationFormGroup();
                }
            });


        this.contactNames = this.contactPersonService.get();
        this.getDeliveryNrCollection('');
    }

    ionViewWillLeave() {
        unsubscribeAll([
            this.deliveryNrCollectionSubscription,
            this.validatorSubscription
        ]);
    }


    onReturnsTypeSelectionChange(event) {
        if (event.detail.value) {
            const date = this.formGroup.controls.deliveryDate.value;
            this.returnGoodsSelectValue = event.detail.value;
            // to update the validation function we have to remove and re-attach the form control
            this.formGroup.removeControl('deliveryDate');
            this.formGroup.addControl('deliveryDate', new FormControl(
                date, [InputValidationService.noWhitespaceValidator, ...ReturnRequestDeliveryDateValidator(this.returnGoodsSelectValue)]));
            if (date) {
                this.formGroup.controls.deliveryDate.markAsDirty();
            }
            this.fromDate = this.returnGoodsSelectValue === ReturnGoodTypesEnum.btm ||
                this.returnGoodsSelectValue === ReturnGoodTypesEnum.coolingGoods ? this.dateSixWeeksAgo : null;
            this.getDeliveryNrCollection(this.formGroup.controls.deliveryNr.value);
            this.formGroup.updateValueAndValidity();
        }
    }

    /**
     * Save current slide and append a new slide to swiper.
     */
    onAppendSlideClick() {
        if (this.slides.length < this.maxSlides) {
            this.saveSlide();
            this.slides.push(this.initialSlide);
            this.returnGoodsSlider.nativeElement.swiper.update();
            this.slideTo(this.slides.length - 1);
            this.formGroup.markAsPristine();
        }
    }

    /**
     * If formGroup is valid, then go one step back in callStack.
     */
    onBackClick() {
        const index = this.activeSlide - 1;
        if (this.formGroup.valid) {
            this.slideTo(index, true);
        } else {
            void this.presentAlert(index, this.activeSlide, this.alertEditMessage);
        }
    }

    onRemoveSlideClick(index: number) {
        const slideToIndex = index <= this.activeSlide ? this.activeSlide - 1 : this.activeSlide;
        void this.presentAlert(slideToIndex, index, this.alertRemoveMessage);
    }

    onClickOutsideOfDeliveryNrInput(activeIndex) {
        if (activeIndex === this.activeSlide) {
            this.onDeliveryNrFocusChange(false);
        }
    }

    /**
     * Edit selected slide by loading previous user input and filling it into form.
     */
    editSlide(index: number) {
        if (this.formGroup.valid) {
            this.saveSlide();
            this.slideTo(index);
            this.formGroup.markAllAsTouched();
            this.formGroup.updateValueAndValidity();
        } else if (index !== this.activeSlide) {
            void this.presentAlert(index, this.activeSlide, this.alertEditMessage);
        }
    }

    slideTo(slideIndex: number, saveSlides = false) {
        if (this.returnGoodsSlider?.nativeElement?.swiper) {
            if (saveSlides) {
                this.saveSlide();
            }
            this.returnGoodsSlider?.nativeElement?.swiper.slideTo(slideIndex);
            this.activeSlide = slideIndex;
            this.setFormGroupValues();
            this.onDeliveryNrFocusChange(false);
        }
    }

    onClickOutsideOfContactInput() {
        this.showContactList = false;
    }

    onContactClicked(contact: string) {
        this.showContactList = false;
        this.formGroup.controls.contactName.setValue(contact);
    }

    onDeliveryNrInput(event) {
        this.getDeliveryNrCollection(event.detail.value);
    }

    onDeliveryNrFocusChange(value) {
        this.showDeliveryNumberList = value;
    }

    onDeliveryNumButtonClick(deliveryCollection: DocumentNumberSelectionInterface) {
        this.formGroup.patchValue({
            ...this.formGroup.value,
            deliveryNr: deliveryCollection.documentNumber,
            deliveryDate: deliveryCollection.recDate
        });
        this.onDeliveryNrFocusChange(false);
    }

    /**
     * Remove slide from slider as well as from callStack.
     */
    private removeSlide(index: number) {
        if (index <= this.activeSlide) {
            this.activeSlide -= 1;
        }
        this.slides.splice(index, 1);
        this.setFormGroupValues();
    }

    private saveSlide() {
        const value = this.formGroup.value;
        this.slides[this.activeSlide] = {
            contactName: value.contactName,
            remarks: value.remarks,
            type: value.type,
            quantity: value.quantity,
            pzn: value.pzn,
            expirationDate: value.expirationDate,
            coolingChain: value.coolingChain,
            securPharm: value.securPharm,
            reason: value.reason,
            deliveryNr: value.deliveryNr,
            deliveryDate: value.deliveryDate,
            isValid: this.formGroup.valid
        };
        this.slides.forEach(slide => {
            slide.contactName = value.contactName;
            slide.remarks = value.remarks;
        });
        this.contactPersonService.set(value.contactName).then(contacts => {
            if (contacts) {
                this.contactNames = contacts;
            }
        });
    }

    private async presentAlert(slideToIndex: number, removeSlideIndex: number, message: string) {
        const alert = await this.alertController.create({
            header: 'Eingabe verwerfen?',
            message,
            buttons: [
                {
                    text: 'Abbrechen',
                    role: 'cancel'
                },
                {
                    text: 'Verwerfen',
                    handler: () => {
                        this.removeSlide(removeSlideIndex);
                        this.slideTo(slideToIndex);
                        this.formGroup.markAllAsTouched();
                        this.formGroup.updateValueAndValidity();
                    }
                }
            ]
        });
        await alert.present();
    }

    /**
     * validate the form data
     */
    private setValidationFormGroup() {
        this.formGroup = this.formBuilder.group({
            contactName: ['', [InputValidationService.noWhitespaceValidator, ...ReturnRequestContactNameValidator()]],
            remarks: ['', [InputValidationService.noWhitespaceValidator, Validators.maxLength(this.fieldLengths.remarks)]],
            type: [ReturnGoodTypesEnum.btm],
            quantity: ['', [InputValidationService.noWhitespaceValidator, ...ReturnRequestQtyValidator()]],
            pzn: ['', [InputValidationService.noWhitespaceValidator, ...ReturnRequestPZNValidator()]],
            expirationDate: ['', [InputValidationService.noWhitespaceValidator, ...ReturnRequestExpirationValidator()]],
            coolingChain: [false],
            securPharm: [true],
            reason: ['', [InputValidationService.noWhitespaceValidator, ...ReturnRequestReasonValidator()]],
            deliveryNr: ['', [InputValidationService.noWhitespaceValidator, ...ReturnRequestDeliveryNrValidator()]],
            deliveryDate:['', [InputValidationService.noWhitespaceValidator, ...ReturnRequestDeliveryDateValidator(this.returnGoodsSelectValue)]],
        });
    }

    private setFormGroupValues() {
        const value = this.slides[this.activeSlide];
        this.formGroup.patchValue({
            contactName: this.slides[0]?.contactName ? this.slides[0].contactName : value?.contactName,
            remarks: this.slides[0]?.remarks ? this.slides[0].remarks : value?.remarks,
            type: value?.type,
            quantity: value?.quantity,
            pzn: value?.pzn,
            expirationDate: value?.expirationDate,
            coolingChain: value?.coolingChain,
            securPharm: value?.securPharm,
            reason: value?.reason,
            deliveryNr: value?.deliveryNr,
            deliveryDate: value?.deliveryDate
        });
    }

    /**
     * send the disposition request via api call
     *
     * @param values : array
     */
    async sendReturnRequest(values) {
        if (this.slides.length > 0) {
            this.saveSlide();
            const title = 'Anmeldung ' + this.slides.length + (this.slides.length === 1 ? ' Retoure' : ' Retouren');
            let content = 'Bitte informieren Sie uns über den weiteren Ablauf.\nVielen Dank.\n\n';
            content += 'Anmerkungen: ' + OrderService.formatInput(values.remarks) + '\n\n';

            this.slides.forEach(slide => {
                content += 'Typ: ' + slide.type + '\n';
                content += 'Menge: ' + OrderService.formatInput(slide.quantity) + '\n';
                content += 'PZN: ' + OrderService.formatInput(slide.pzn) + '\n';
                content += 'Verfall: ' + OrderService.formatInput(slide.expirationDate) + '\n';
                content += 'Kühlkette: ';
                content +=  slide.coolingChain ? 'Ja\n' : 'Nein\n';
                content += 'Securpharmartikel: ';
                content +=  slide.securPharm ? 'Ja\n' : 'Nein\n';
                if (slide.type === this.returnGoodTypesEnum.btm) {
                    content += 'BfArM online Verfahren: Ja Teilnahme\n';
                }
                content += 'Retourengrund: ' + OrderService.formatInput(slide.reason) + '\n';
                content += 'LS-Nr.: ' + OrderService.formatInput(slide.deliveryNr) + '\n';
                content += 'LS-Dat.: ' + OrderService.formatInput(formatDateTimeToDate(slide.deliveryDate)) + '\n\n';
            });

            if (content.length > FieldLengths[FieldLengthEnum.returnRequest]) {
                return this.toastService.presentWarning(
                    'Bitte kürzen Sie Ihren Text auf maximal ' + FieldLengths[FieldLengthEnum.returnRequest] + ' Zeichen.'
                );
            }
            this.communicationZoneMutation.insertCommunicationZone({
                title,
                content,
                contactPerson: OrderService.formatInput(values.contactName)
            });
            await this.modalService.dismiss();
        }
    }

    private getDeliveryNrCollection(search) {
        unsubscribe(this.deliveryNrCollectionSubscription);
        this.deliveryNrCollectionSubscription = this.documentQueries.getDocumentNumberAndDateCollection(search, DocumentType.LS, this.fromDate)
            .subscribe(numbers => {
                this.deliveryNrCollection = numbers;
            });
    }
}
