export enum LogisticsRoutesEnum {
    orders = 'orders',
    deliveryReceipts = 'delivery',
    invoices = 'invoices',
    returns = 'returns',
    terms = 'terms',
    datasheets = 'datasheets'
}

export enum FullLogisticsRoutesEnum {
    orders = '/app/logistics/orders',
    deliveryReceipts = '/app/logistics/delivery',
    invoices = '/app/logistics/invoices',
    returns = '/app/logistics/returns',
    terms = '/app/logistics/terms',
    datasheets = '/app/logistics/datasheets'
}
