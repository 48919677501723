import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WidgetSmallComponent } from './widget-small.component';
import { CoreModule } from '../../core/core.module';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        CoreModule,
        NgOptimizedImage
    ],
    exports: [
        WidgetSmallComponent
    ],
    declarations: [
        WidgetSmallComponent
    ]
})
export class WidgetSmallModule {
}
