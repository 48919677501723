import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { TrackingStateInterface } from '../../interfaces/tracking-state.interface';

export const GetTrackingState = gql`
    query GetTrackingState {
        trackingState @client {
            deliverySearchStart
            deliverySearchText
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class TrackingStateVar {
    private defaultValue: TrackingStateInterface = {
        deliverySearchStart: 0,
        deliverySearchText: ''
    };

    private trackingStateObservable: Observable<TrackingStateInterface>;
    private apollo: Apollo;
    private cache;

    constructor(apollo: Apollo) {
        this.apollo = apollo;
    }

    init() {
        if(this.apollo.client) {
            this.cache = this.apollo.client.cache;
            this.trackingStateObservable = new Observable<TrackingStateInterface>(subscriber => {
                this.cache.watch({
                    query: GetTrackingState,
                    callback: res => {
                        subscriber.next(res.result && res.result.trackingState || this.defaultValue);
                    },
                    immediate: true,
                    optimistic: true,
                });
            });
        }
    }

    get(): Observable<TrackingStateInterface> {
        this.init();
        return this.trackingStateObservable;
    }

    set(trackingState: TrackingStateInterface) {
        this.init();
        if(this.cache) {
            this.cache.writeQuery({
                query: GetTrackingState,
                data: {
                    trackingState
                }
            });
        }
    }
}
