import { Component, inject, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { FormQueries, NewsPostQueries } from '../../../../core/core.store';
import { NewsPostInterface } from '../../../../core/core.interfaces';
import { ModalClassEnum } from '../../../../core/enums/modal-class.enum';
import { VideoPlayerService } from '../../../../core/services/videoPlayer.service';
import { unsubscribe, unsubscribeAll } from '../../../../core/core.utils';
import { ModalService } from '../../../../core/services/modal.service';

@Component({
    selector: 'app-news-post-modal',
    templateUrl: './news-post-modal.component.html',
    styleUrls: ['./news-post-modal.component.scss'],
})
export class NewsPostModalComponent implements OnInit {
    // Adds class to modal
    static modalClass = ModalClassEnum.large;

    private formQueries = inject(FormQueries);
    private newsPostQueries = inject(NewsPostQueries);
    private videoPlayer = inject(VideoPlayerService);
    private modalService = inject(ModalService);

    @Input() newsId = '';

    newsPost: NewsPostInterface;
    videoHtml = null;
    newsSubscription: Subscription;
    formResultSubscription: Subscription;

    isAlreadySubmitted = false;
    isFormLoading = true;
    openModal = false;

    ngOnInit() {
        if (this.newsId) {
            this.isFormLoading = true;
            unsubscribe(this.newsSubscription);
            this.newsSubscription = this.newsPostQueries.getNewsPost(this.newsId).subscribe(newsPost => {
                this.newsPost = newsPost;

                if (newsPost && newsPost.videoLink) {
                    // supports vimeo and custom urls - youtube would need another plugin videojs/youtube
                    this.videoHtml = this.videoPlayer.init([newsPost.videoLink]);
                }

                if(this.newsPost?.formId){
                    this.checkFormExist();
                }else{
                    this.isFormLoading = false;
                }
            });
        }
    }

    ionViewWillLeave(): void {
        unsubscribeAll([
            this.newsSubscription,
            this.formResultSubscription,
        ]);
    }

    checkFormExist(){
        unsubscribe(this.formResultSubscription);
        this.formResultSubscription = this.formQueries.getFormResult(Number(this.newsPost?.formId))
            .subscribe(formResult => {
                if (formResult && formResult[0]?.id) {
                    this.isAlreadySubmitted = true;
                }else{
                    this.isAlreadySubmitted = false;
                }
                this.isFormLoading = false;
            });
    }
    /**
     * Open a new tab with the external link url
     */
    onExternalLinkClick() {
        window.open(this.newsPost.link, '_system');
    }

    async onFormSubmitted(event: boolean) {
        if(event){
            this.isAlreadySubmitted = true;
            await this.modalService.dismiss();
            await this.modalService.dismiss();
        }
    }

    async onNewsFormModal(){
        this.openModal = true;
    }
    async onNewsFormModalClose(){
        this.openModal = false;
    }
}
