import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { chatReducer } from '../../../../store/src/common-store/chat-store/reducers/chat.reducer';
import { deviceReducer } from '../../../../store/src/common-store/device-store/reducers/device.reducer';
import { errorHeaderReducer } from '../../../../store/src/common-store/error-header-store/reducers/error-header.reducer';
import { loadingStatusReducer } from '../../../../store/src/common-store/loading-status-store/reducers/loading-status.reducer';
import { releaseReducer } from '../../../../store/src/common-store/release-store/reducers/release.reducer';
import { shopProductSearchReducer } from '../../../../store/src/common-store/shop-product-search/reducers/shop-product-search.reducer';
import { userReducer } from '../../../../store/src/common-store/user-store/reducers/user.reducer';
import { ChatConversationUpdateEffects } from './chat-store/effects/chat-conversation-update.effects';
import { ChatConversationEffects } from './chat-store/effects/chat-conversation.effects';
import { ChatDecryptionEffects } from './chat-store/effects/chat-decryption.effects';
import { ChatMessageEffects } from './chat-store/effects/chat-message.effects';
import { ChatTypingEffects } from './chat-store/effects/chat-typing.effects';
import { DeviceEffects } from './device-store/effects/device.effects';
import { CommonAnalyticsEffects } from './other/effects/common-analytics.effects';
import { SubscriptionEffects } from './other/effects/subscription.effects';
import { ShopProductSearchEffects } from './shop-product-search/effects/shop-product-search.effects';
import { UserEffects } from './user-store/effects/user.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('chatData', chatReducer),
    StoreModule.forFeature('deviceData', deviceReducer),
    StoreModule.forFeature('errorHeader', errorHeaderReducer),
    StoreModule.forFeature('loadingStatus', loadingStatusReducer),
    StoreModule.forFeature('releaseData', releaseReducer),
    StoreModule.forFeature('shopProductSearch', shopProductSearchReducer),
    StoreModule.forFeature('userData', userReducer),
    EffectsModule.forFeature([
      ChatConversationEffects,
      ChatDecryptionEffects,
      ChatMessageEffects,
      ChatConversationUpdateEffects,
      ChatTypingEffects,
      CommonAnalyticsEffects,
      DeviceEffects,
      ShopProductSearchEffects,
      SubscriptionEffects,
      UserEffects,
    ]),
  ],
})
export class CommonStoreModule {}
