import gql from 'graphql-tag';

export default gql`
  mutation createConversation($intent: ConversationIntent, $appContext: AppContext, $earliestExpirationTimestamp: Int) {
    createConversation(
      intent: $intent
      appContext: $appContext
      earliestExpirationTimestamp: $earliestExpirationTimestamp
    ) {
      __typename
      id
      firstSegmentId
      createdAt
      intent
      earliestExpirationTimestamp
    }
  }
`;
