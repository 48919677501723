import { gql } from '@apollo/client/core';
import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable, Subscription, switchMap } from 'rxjs';

import { QueryFetchPolicy } from '../../../enums/api.enum';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import {
    OffersBannerInterface,
    OffersFilterInterface,
    OfferDetailsInCartInterface,
    OffersInterface,
    OffersInfoInterface
} from '../../../interfaces/offers.interface';
import { today } from '../../../formatting/date.formatting';
import { PharmacyStoreStateVar } from '../../locals/pharmacyStoreState.var';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { formatStrapiOffersConnectionData, getFiltersStrapi } from '../utils';
import { unsubscribeAll } from '../../../core.utils';
import { QueryWrapper } from '../query.wrapper';
import { FetchPolicyKeys as FPK } from '../../../enums/fetch-policy-keys.enum';
import{ DataChangedKeys as DCK } from '../../../enums/data-changed-keys.enum';
import { OffersAppAreaEnum } from '../../../enums/offers.enum';
import { PharmacyStoreInterface } from '../../../interfaces/customer.interface';

export const offerFields = `data {
                id
                attributes {
                    enableForAllPharmacies
                    customerNumbers
                    name
                    appArea
                    fromDate
                    toDate
                    publishedAt
                    isExtended
                    image {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    bannerContentRight {
                        interfererHead
                        interfererContent
                    }
                    actionBadge {
                        text
                        backgroundColor
                        textColor
                    }
                    imageType
                    bannerTexts {
                        headline
                        subheadline
                        content
                    }
                    offerFilters(pagination: {limit: $offerFiltersLimit}) {
                        membershipTypes {
                            data {
                                attributes {
                                    name
                                }
                            }
                        }
                    }
                }
            }`;
export const GetOffersWithDetails = (queryName) => gql`
    query ${queryName}($filters: OfferFiltersInput, $offerProductsLimit: Int, $offerGraduatedPriceLimit: Int, $offerFiltersLimit: Int) {
        offers(filters: $filters, pagination: {limit: 1}) {
            data {
                id
                attributes {
                    name
                    orderType
                    appArea
                    fromDate
                    toDate
                    description
                    descriptionHtml
                    checkoutAggreement
                    publishedAt
                    image {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    enableForAllPharmacies
                    customerNumbers
                    offerFilters(pagination: {limit: $offerFiltersLimit}) {
                        membershipTypes {
                            data {
                                attributes {
                                    name
                                }
                            }
                        }
                    }
                    offerProducts(pagination: {limit: $offerProductsLimit}) {
                        id
                        isActive
                        graduatedPrice(pagination: {limit: $offerGraduatedPriceLimit}) {
                            quantity
                            price
                            discount
                        }
                        offerProduct {
                            data {
                                attributes {
                                    productName
                                    unit
                                    vaccineDoses
                                    dosageForm
                                    pzn
                                    producer
                                    aep
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GetOffersBannerByOfferId = (queryName) => gql`
    query ${queryName}($filters: OfferFiltersInput, $offerNewsLimit: Int, $offerFiltersLimit: Int) {
        offers(filters: $filters, pagination: {limit: 1}) {
            data {
                id
                attributes {
                    enableForAllPharmacies
                    customerNumbers
                    name
                    appArea
                    description
                    descriptionHtml
                    publishedAt
                    image {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    imageType
                    bannerContentRight {
                        interfererHead
                        interfererContent
                    }
                    bannerTexts {
                        headline
                        subheadline
                        content
                    }
                    offerNews(pagination: {limit: $offerNewsLimit}) {
                        news
                    }
                    offerFilters(pagination: {limit: $offerFiltersLimit}) {
                        membershipTypes {
                            data {
                                attributes {
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GetOffersBanner = (queryName) => gql`
    query ${queryName}($limit: Int, $filters: OfferFiltersInput, $sort: [String], $offerFiltersLimit: Int) {
        offers(pagination: {limit: $limit}, filters: $filters, sort: $sort) {
            ${offerFields}
        }
    }
`;

export const GetOffersInfo = (queryName) => gql`
    query ${queryName}($limit: Int, $filters: OfferFiltersInput, $sort: [String], $offerFiltersLimit: Int) {
        offers(pagination: {limit: $limit}, filters: $filters, sort: $sort) {
            data {
                id
                attributes {
                    enableForAllPharmacies
                    customerNumbers
                    fromDate
                    toDate
                    publishedAt
                    image {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    imageType
                    bannerTexts {
                        headline
                    }
                    offerFilters(pagination: {limit: $offerFiltersLimit}) {
                        membershipTypes {
                            data {
                                attributes {
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GetOffersFilter = (queryName) => gql`
    query ${queryName}($filters: OfferFiltersInput, $offerFiltersLimit: Int) {
        offers(filters: $filters, pagination: {limit: 1}) {
            data {
                id
                attributes {
                    name
                    offerCanBuy
                    enableForAllPharmacies
                    customerNumbers
                    fromDate
                    toDate
                    publishedAt
                    offerFilters(pagination: {limit: $offerFiltersLimit}) {
                        membershipTypes {
                            data {
                                attributes {
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const getOfferQuote = (queryName) => gql`
    query ${queryName}($limit: Int, $offerId: String) {
        offerQuote(limit: $limit, where: {offerId: {_eq: $offerId}}) {
            id
            offerId
            offerQuoteItems {
                productId
                quantity
            }
        }
    }
`;

export const getOffersVariables = (pharmacy: PharmacyStoreInterface, offerId = null, limit: number = GraphQLLimits.offers, appArea = OffersAppAreaEnum.SANACORP) => {
    const todayAsString = today();
    const additionalFilters = getFiltersStrapi(pharmacy);
    if (offerId) {
        return {
            filters: {
                id: {eq: offerId},
                fromDate: {
                    lte: todayAsString
                },
                toDate: {
                    gte: todayAsString
                },
                publishedAt: {notNull: true},
                ...additionalFilters
            }
        };
    }
    return {
        limit,
        filters: {
            fromDate: {
                lte: todayAsString
            },
            toDate: {
                gte: todayAsString
            },
            publishedAt: {notNull: true},
            ...(appArea ? {appArea: {containsi: appArea}} : {}),
            ...additionalFilters
        }
    };
};


export const AllOffersQueries = [
    GetOffersWithDetails('test'),
    GetOffersBannerByOfferId('test'),
    GetOffersBanner('test'),
    GetOffersInfo('test'),
    GetOffersFilter('test'),
    getOfferQuote('test')
];

@Injectable()
export class OffersQueries extends QueryWrapper {
    private pharmacyStoreStateVar = inject(PharmacyStoreStateVar);

    fetchPolicies = {
        [FPK.getOffersWithDetails]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getOffersBanner]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getOffersBannerByOfferId]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getOffersInfo]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getOffersFilter]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getOfferQuote]: QueryFetchPolicy.NETWORK_ONLY
    };

    activePharmacyStore$ = this.pharmacyStoreStateVar.activePharmacyStoreState$;

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar
    ) {
        super(apollo, dataChangedVar, {
            [DCK.offerChanged]: [FPK.getOffersBanner, FPK.getOffersBannerByOfferId, FPK.getOffersFilter, FPK.getOffersInfo, FPK.getOffersWithDetails],
            [DCK.offerProductChanged]: [FPK.getOffersWithDetails],
            [DCK.offerQuoteChanged]: [FPK.getOfferQuote]
        });
    }

    offersListSubscription: Subscription;

    public unsubscribeAll() {
        unsubscribeAll([
            this.offersListSubscription
        ]);
    }

    public getOffersWithDetails(offerId): Observable<OffersInterface> {
        const fetchPolicyKey = FPK.getOffersWithDetails;
        return this.activePharmacyStore$.pipe(
            switchMap((pharmacy) =>
                this.apollo
                    .watchQuery({
                        query: GetOffersWithDetails(fetchPolicyKey),
                        variables: {
                            ...getOffersVariables(pharmacy, offerId),
                            offerProductsLimit: GraphQLLimits.offerProducts,
                            offerGraduatedPriceLimit: GraphQLLimits.offerGraduatedPrice,
                            offerFiltersLimit: GraphQLLimits.offerFilters,
                        },
                        fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                    })
                    .valueChanges.pipe(
                        map((d) => d?.data && d?.data['offers'] && d?.data['offers']['data']?.length > 0 && d?.data['offers']['data'][0])
                    )
                    .pipe(map((offersWithDetailsData) => formatStrapiOffersConnectionData(offersWithDetailsData)))
                    .pipe(map((offer: OffersInterface) => this.sortGraduatedPrices(offer)))
                    .pipe(map((offer: OffersInterface) => this.padPZN(offer)))
                    .pipe(
                        map((d) => {
                            if (d) this.updateFetchPolicy(fetchPolicyKey);
                            return d;
                        })
                    )
            )
        );
    }

    public getOffersBanner(appArea: OffersAppAreaEnum): Observable<OffersBannerInterface[]> {
        const fetchPolicyKey = FPK.getOffersBanner;
        return this.activePharmacyStore$.pipe(
            switchMap((pharmacy) =>
                this.apollo
                    .watchQuery({
                        query: GetOffersBanner(fetchPolicyKey),
                        variables: {
                            ...getOffersVariables(pharmacy, null, GraphQLLimits.offers, appArea),
                            sort: ['fromDate:desc', 'toDate:asc'],
                            offerFiltersLimit: GraphQLLimits.offerFilters,
                        },
                        fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                    })
                    .valueChanges.pipe(map((d) => d?.data && d?.data['offers']))
                    .pipe(map((offersBannerData) => formatStrapiOffersConnectionData(offersBannerData)))
                    .pipe(
                        map((d) => {
                            if (d) this.updateFetchPolicy(fetchPolicyKey);
                            return d;
                        })
                    )
            )
        );
    }

    public getOffersBannerByOfferId(offerId): Observable<OffersBannerInterface> {
        const fetchPolicyKey = FPK.getOffersBannerByOfferId;
        return this.activePharmacyStore$.pipe(
            switchMap((pharmacy) =>
                this.apollo
                    .watchQuery({
                        query: GetOffersBannerByOfferId(fetchPolicyKey),
                        fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                        variables: {
                            ...getOffersVariables(pharmacy, offerId),
                            offerNewsLimit: GraphQLLimits.offerNews,
                            offerFiltersLimit: GraphQLLimits.offerFilters,
                        },
                    })
                    .valueChanges.pipe(
                        map((d) => d?.data && d?.data['offers'] && d?.data['offers']['data']?.length > 0 && d?.data['offers']['data'][0])
                    )
                    .pipe(map((offersBannerData) => formatStrapiOffersConnectionData(offersBannerData)))
                    .pipe(
                        map((d) => {
                            if (d) this.updateFetchPolicy(fetchPolicyKey);
                            return d;
                        })
                    )
            )
        );
    }

    public getOffersInfo(): Observable<OffersInfoInterface[]> {
        const fetchPolicyKey = FPK.getOffersInfo;
        return this.activePharmacyStore$.pipe(
            switchMap((pharmacy) =>
                this.apollo
                    .watchQuery({
                        query: GetOffersInfo(fetchPolicyKey),
                        variables: {
                            ...getOffersVariables(pharmacy),
                            sort: ['fromDate:desc', 'toDate:asc'],
                            offerFiltersLimit: GraphQLLimits.offerFilters,
                        },
                        fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                    })
                    .valueChanges.pipe(map((d) => d?.data && d?.data['offers']))
                    .pipe(map((offersInfoData) => formatStrapiOffersConnectionData(offersInfoData)))
                    .pipe(
                        map((d) => {
                            if (d) this.updateFetchPolicy(fetchPolicyKey);
                            return d;
                        })
                    )
            )
        );
    }

    public getOffersFilter(offerId): Observable<OffersFilterInterface> {
        const fetchPolicyKey = FPK.getOffersFilter;
        return this.activePharmacyStore$.pipe(
            switchMap((pharmacy) =>
                this.apollo
                    .watchQuery({
                        query: GetOffersFilter(fetchPolicyKey),
                        variables: {
                            ...getOffersVariables(pharmacy, offerId),
                            offerFiltersLimit: GraphQLLimits.offerFilters,
                        },
                        fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                    })
                    .valueChanges.pipe(
                        map((d) => d?.data && d?.data['offers'] && d?.data['offers']['data']?.length > 0 && d?.data['offers']['data'][0])
                    )
                    .pipe(map((offersFilterData) => formatStrapiOffersConnectionData(offersFilterData)))
                    .pipe(
                        map((d) => {
                            if (d) this.updateFetchPolicy(fetchPolicyKey);
                            return d;
                        })
                    )
            )
        );
    }

    public getOfferQuote(id: number): Observable<OfferDetailsInCartInterface[]> {
        const fetchPolicyKey = FPK.getOfferQuote;
        const offerId = id.toString();
        return this.apollo.watchQuery({
            query: getOfferQuote(fetchPolicyKey),
            variables: {offerId},
            fetchPolicy:this.getFetchPolicy(fetchPolicyKey),
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['offerQuote']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<OfferDetailsInCartInterface[]>;
    }
    
    private sortGraduatedPrices = (offer: OffersInterface): OffersInterface => {
        return (offer?.offerProducts && offer.offerProducts.length > 0) ? {
                    ...offer,
                    offerProducts: offer.offerProducts.map(offerProduct => (
                        (offerProduct.graduatedPrice && offerProduct.graduatedPrice.length > 0) ? (
                            {
                                ...offerProduct,
                                graduatedPrice: offerProduct.graduatedPrice
                                    .slice().sort((a, b) => a.quantity > b.quantity ? 1 : -1)
                            }
                        ) : offerProduct
                    ))
            } : offer;
    }

    private padPZN = (offer: OffersInterface): OffersInterface => {
        return offer ? {
            ...offer,
            offerProducts: offer?.offerProducts ? offer.offerProducts.map(offerProduct => ({
                    ...offerProduct,
                    offerProduct: {...offerProduct.offerProduct, pzn: offerProduct.offerProduct.pzn?.padStart(8, '0')}
                }
            )) : []
        } : offer;
    }
}
