import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ModalClassEnum, PdfViewerType, StaticPageTypeEnum } from '../../core.enums';
import { AcceptedTermsInterface, StaticPageInterface, TermsShowAcceptInterface } from '../../interfaces/static-page.interface';
import { ModalService } from '../../services/modal.service';
import { StaticPageService } from '../../services/static-page.service';

@Component({
    selector: 'app-static-page-modal',
    templateUrl: './static-page-modal.component.html',
    styleUrls: ['./static-page-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaticPageModalComponent implements OnInit {
    // Adds class to modal
    static modalClass = ModalClassEnum.medium;

    @Input() pageType: StaticPageTypeEnum;

    terms$: Observable<StaticPageInterface>;
    showAccept$: Observable<TermsShowAcceptInterface>;
    acceptedTerms$: Observable<AcceptedTermsInterface>;

    pageTypes = StaticPageTypeEnum;
    pdfDownloadType = PdfViewerType.terms;

    constructor(
        private modalService: ModalService,
        private staticPageService: StaticPageService,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {
        this.terms$ = this.staticPageService.getStaticPageByRoute(this.activatedRoute, this.pageType);
        this.showAccept$ = this.staticPageService.getShowAccept();
        this.acceptedTerms$ = this.staticPageService.getAcceptedTerms();
    }

    async acceptTerms() {
        const isSucessfull = await this.staticPageService.acceptTerms();
        if (isSucessfull) {
            this.closeModal();
        }
    }

    closeModal() {
        void this.modalService.dismiss();
    }
}
