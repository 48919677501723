import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { MenuStateInterface } from '../../interfaces/menu-state.interface';

export const GetMenuState = gql`
    query GetMenuState {
        menuState @client {
            isOpen
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MenuStateVar {
    private defaultValue: MenuStateInterface = {
        isOpen: false
    };
    private menuStateObservable: Observable<MenuStateInterface> = new Observable<MenuStateInterface>();
    private apollo: Apollo;
    private cache;

    constructor(apollo: Apollo) {
        this.apollo = apollo;
    }

    init() {
        if(this.apollo.client) {
            this.cache = this.apollo.client.cache;
            this.menuStateObservable = new Observable<MenuStateInterface>(subscriber => {
                this.cache.watch({
                    query: GetMenuState,
                    callback: res => {
                        subscriber.next(res.result && res.result.menuState || this.defaultValue);
                    },
                    immediate: true,
                    optimistic: true,
                });
            });
        }
    }

    get(): Observable<MenuStateInterface> {
        this.init();
        return this.menuStateObservable;
    }

    set(menuState: MenuStateInterface) {
        this.init();
        if (this.cache) {
            this.cache.writeQuery({
                query: GetMenuState,
                data: menuState
            });
        }
    }

    setIsOpen(isOpen){
        this.init();
        if (this.cache) {
            this.cache.writeQuery({
                query: GetMenuState,
                data: {
                    menuState: {isOpen}
                }
            });
        }
    }
}
