import {
    BadgeTypeEnum,
    DateRangeOptionCodes,
    DeliveryReceiptOrderTypeFormatted,
    DocumentSubType,
    DocumentType,
} from '../core.enums';
import { DocumentFiltersInterface } from '../interfaces/document.interface';
import { FilterResultInterface, FilterResultItemInterface } from '../interfaces/filter-result.interface';
import {
    DeliveryReceiptOrderTypeFilterConfig,
    DeliveryReceiptSubTypesWithAll
} from './delivery-receipt.config';
import { InvoicesTypeFilterConfig } from './invoices.config';
import { GetDateRangeConfig } from './date-range.config';

const defaultDocumentFilters : DocumentFiltersInterface = {
    search: '',
    recDateOption: DateRangeOptionCodes.all,
    recDateFrom: null,
    recDateTo: null,
    documentType: DocumentType.LS,
    documentNumber: null,
    documentSubType: DocumentSubType.ALL,
    orderType: DeliveryReceiptOrderTypeFormatted.ALL,
    hasBatchDocument: false,
};

export const defaultDeliveryReceiptFilters : DocumentFiltersInterface = {
    ...defaultDocumentFilters,
    recDateOption: DateRangeOptionCodes.thisMonth,
    documentType: DocumentType.LS,
};


export const defaultInvoiceFilters : DocumentFiltersInterface = {
    ...defaultDocumentFilters,
    documentType: DocumentType.RE,
};

export const useChildDocumentTable = (filters: DocumentFiltersInterface) => {
    return (filters && (filters.hasBatchDocument || filters.documentSubType !== DocumentSubType.ALL));
};

const DocumentSearchResultItemsConfig : FilterResultItemInterface[] = [
    {
        id: 'recDate',
        label: 'Belegdatum',
        isDate: true,
        isRecDate: true,
        dateRangeOption: GetDateRangeConfig()
    }
];

export const InvoiceDocumentsFilterResultConfig : FilterResultInterface = {
    hasDateFilter: true,
    hasSearchModal: false,
    defaultFilters: defaultInvoiceFilters,
    items: [
        ...DocumentSearchResultItemsConfig,
        {
            id: 'documentSubType',
            label: 'Typ',
            translation: InvoicesTypeFilterConfig,
            isBatch: false,
            batchType: BadgeTypeEnum.INVOICE
        }
    ]};

export const DeliveriesDocumentsFilterResultConfig : FilterResultInterface = {
    hasDateFilter: true,
    hasSearchModal: true,
    defaultFilters: defaultDeliveryReceiptFilters,
    items: [
        ...DocumentSearchResultItemsConfig,
        {
            id: 'orderType',
            label: 'Auftragsart',
            translation: DeliveryReceiptOrderTypeFilterConfig
        },
        {
            id: 'hasBatchDocument',
            label: 'Nur mit Chargenbeleg',
            translation: [
                {id: true, title: 'Ja', shortcode: 'Ja'},
                {id: false, title: 'Nein', shortcode: 'Nein'},
            ],
            isBoolean: true
        },
        {
            id: 'documentSubType',
            label: 'Chargenbelegtyp',
            translation: DeliveryReceiptSubTypesWithAll,
            isBatch: false,
            batchType: BadgeTypeEnum.DELIVERY_RECEIPT_SUBTYPE
        },
        {
            id: 'documentNumber',
            label: 'Belegnummer',
            searchLabel: 'Nr',
            searchOnly: true
        },
        {
            id: 'search',
            label: 'Suche',
            searchOnly: true
        }
    ]};
