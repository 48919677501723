import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApiService } from '../../../services/api.service';
import { DataChangedKeys as DCK } from '../../../core.enums';
import { DataChangedForceStateVar } from '../../locals/dataChangeForceState.var';

export const UpsertSortingMutation = gql`
    mutation insertSortingMutation($sort: [sorting_insert_input!]!) {
        insert_sorting(
            objects: $sort,
            on_conflict: {
                constraint: sorting_userId_tableName_key,
                update_columns: [tableName, fieldName, sortDirection]
            }
        ) {
            affected_rows
        }
    }
`;
export const AllSortingMutations = [
    UpsertSortingMutation
];

@Injectable()
export class SortingMutations {
    constructor(
        private apollo: Apollo,
        private apiService: ApiService,
        private dataChangedForceState: DataChangedForceStateVar
    ) {}

    upsertSorting(sort: object) {
        void this.dataChangedForceState.setForceState({[DCK.sortingChanged]: null});
        return new Promise(resolve => {
            this.apollo.mutate( {
                mutation: UpsertSortingMutation,
                variables: {sort}
            }).subscribe({
                next: result => {
                    resolve(result);
                },
                error: error => {
                    this.apiService.presentErrorToast(error, 'Die Sortierung konnte nicht gesetzt werden')
                      .then(() => resolve(null));
                }
            });
        });
    }

}
