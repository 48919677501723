import { Pipe, PipeTransform } from '@angular/core';
import { DocumentInterface } from '../interfaces/document.interface';
import { AuthStorageKeyEnum } from '../enums/authStorageKey.enum';
import { DocumentPdfFilenamePrefix, DocumentSubType, DocumentType } from '../enums/documents.enum';

@Pipe({
    name: 'documentDownloadFilename'
})
export class DocumentDownloadFilenamePipe implements PipeTransform {
    transform(document: DocumentInterface, childRecDate: string = null): string {
        const activePharmacy = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
        const documentType = document.documentType;
        const documentSubType = document.documentSubType;
        let filenamePrefix = '';

        if (documentType === DocumentType.LS) {
            filenamePrefix = DocumentPdfFilenamePrefix.LS;
        } else {
            switch (documentSubType) {
                case DocumentSubType.CHARTIER:
                    filenamePrefix = DocumentPdfFilenamePrefix.CH_TIER;
                    break;
                case DocumentSubType.CHARBLUT:
                    filenamePrefix = DocumentPdfFilenamePrefix.CH_BLUT;
                    break;
                case DocumentSubType.CHARSECU:
                    filenamePrefix = DocumentPdfFilenamePrefix.CH_SECU;
                    break;
                case DocumentSubType.CHARDOKU:
                    filenamePrefix = DocumentPdfFilenamePrefix.CH_DOKU;
                    break;
                case DocumentSubType.E_SR:
                    filenamePrefix = DocumentPdfFilenamePrefix.E_SR;
                    break;
                case DocumentSubType.E_TR:
                    filenamePrefix = DocumentPdfFilenamePrefix.E_TR;
                    break;
            }
        }

        let filename = childRecDate ? childRecDate : document.recDate;
        filename += activePharmacy ? '_' + activePharmacy : '';
        filename += filenamePrefix ? filenamePrefix : '';
        filename += document.documentNumber ? document.documentNumber : '';
        filename += '_SC.pdf';
        return filename;
    }
}
