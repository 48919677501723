import { Subscription } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { QueryFetchPolicy } from '../../enums/api.enum';
import { DataChangedStateVar } from '../locals/dataChangeState.var';
import { FetchPolicyKeys } from '../../enums/fetch-policy-keys.enum';
import { DataChangedKeys } from '../../enums/data-changed-keys.enum';
import { unsubscribe } from '../../util/subscriptions.util';

export class QueryWrapper {
    protected fetchPolicies = {};
    protected ignoredPolicies = [];
    protected noRefetch = [];


    private readonly dataChangedVarSubscription: Subscription;
    private dataChanged = {};

    constructor(
        private apolloClient: Apollo,
        private dataChangeVar: DataChangedStateVar,
        private dataChangeKeyGroups: {[key in DataChangedKeys]?: Array<FetchPolicyKeys> }) {

        // Listen for data change events and reset fetch policies if necessary
        unsubscribe(this.dataChangedVarSubscription);
        this.dataChangedVarSubscription = this.dataChangeVar.get().subscribe(dataChanged => {
            const keys = Object.keys(dataChangeKeyGroups);
            keys.forEach(dataChangeKey => {
                const fetchPoliciesToRefresh = dataChangeKeyGroups[dataChangeKey];
                const queriesForRefetch = [];

                if (dataChanged[dataChangeKey] && (!this.dataChanged || this.dataChanged[dataChangeKey] !== dataChanged[dataChangeKey])) {
                    this.dataChanged[dataChangeKey] = dataChanged[dataChangeKey];
                    let hasChanges = false;
                    Object.keys(this.fetchPolicies).forEach((key) => {
                        if ((this.fetchPolicies[key] !== QueryFetchPolicy.NETWORK_ONLY || this.ignoredPolicies.includes(key))
                        && fetchPoliciesToRefresh.indexOf(key) >= 0) {
                            this.fetchPolicies[key] = QueryFetchPolicy.NETWORK_ONLY;
                            queriesForRefetch.push(key);
                            hasChanges = true;
                        }
                    });
                    if (hasChanges) {
                        this.fetchPolicies = {...this.fetchPolicies};
                        apolloClient.client['queryManager'].queries.forEach((query) => {
                            if (queriesForRefetch.find(key => (key === query.observableQuery.queryName))) {
                                try {
                                    const getSelection = (_selection) => {
                                        return _selection.selectionSet ? _selection.selectionSet.selections[0] : null;
                                    };
                                    let selection = query.document.definitions[0];
                                    selection = getSelection(selection);
                                    let fieldNames = [selection.name.value];
                                    if (selection && selection.name.value === 'pharmacyStore') {
                                        selection = getSelection(selection);
                                        fieldNames = [selection.name.value.substring(0, selection.name.value.length - 1)];
                                    }
                                    fieldNames.forEach(fieldName => {
                                        apolloClient.client.cache.evict({
                                            id: 'ROOT_QUERY',
                                            fieldName,
                                            broadcast: false
                                        });
                                    });
                                } catch (e) {}
                                apolloClient.client.cache.gc();
                            }
                        });
                        // If the use "active", the amount of queries for refetch will be triplet.
                        // Otherwise, we could use the list of FetchPolicies (Value = QueryName) to refetch only necessary queries.
                        queriesForRefetch.forEach(key => {
                            let updateFetchPolicy = true;
                            if (!this.noRefetch.includes(key)) {
                                const process = apolloClient.client.refetchQueries({
                                    include: [key]
                                });
                                updateFetchPolicy = process?.queries?.length > 0;
                            }
                            if (updateFetchPolicy) {
                                this.updateFetchPolicy(key);
                            }
                        });

                    }
                }
            });
        });
    }

    /**
     * Update Fetch Policy of given query key
     * @param key - Key of the query
     */
    getFetchPolicy(key) {
        if (!this.fetchPolicies[key]) {
            throw Error('Invalid Fetch Policy key!');
        }
        return this.fetchPolicies[key];
    }

    /**
     * Update Fetch Policy of given query key
     * @param key - Key of the query
     */
    updateFetchPolicy(key) {
        if (!this.fetchPolicies[key]) {
            throw Error('Invalid Fetch Policy key!');
        }
        const fetchPolicy = this.fetchPolicies[key];
        if (this.fetchPolicies[key] === QueryFetchPolicy.NETWORK_ONLY && !this.ignoredPolicies.includes(key)) {
            this.fetchPolicies = {...this.fetchPolicies, [key]: QueryFetchPolicy.CACHE_FIRST};
        }
        return fetchPolicy;
    }
}
