import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { BannerModule } from './banner/banner.module';
import { FiltersModule } from './filters/filters.module';
import { ModalModule } from './modal/modal.module';
import { SimpleModalModule } from './simple-modal/simple-modal.module';
import { TrendingBadgeModule } from './trending-badge/trending-badge.module';
import { WidgetModule } from './widget/widget.module';
import { WidgetSmallModule } from './widget-small/widget-small.module';

const modules = [
    CommonModule,
    IonicModule,
    BannerModule,
    FiltersModule,
    ModalModule,
    TrendingBadgeModule,
    SimpleModalModule,
    WidgetModule,
    WidgetSmallModule,
];

@NgModule({
    imports: modules,
    exports: modules
})
export class UiModule {
}
