import Holidays, { HolidaysTypes } from 'date-holidays';
import { Dayjs } from 'dayjs';
import { FederalStates, LocalHoliday, PharmacyHolidaySettings } from '../../types/src/openingHours';
import { postalCodeRecords } from '../../types/src/postalCodeRecords';

const augsburgerFriedensfest: LocalHoliday = 'Augsburger Friedensfest';
const mariaHimmelfahrt: LocalHoliday = 'Mariä Himmelfahrt';
const fronleichnam: LocalHoliday = 'Fronleichnam';

export class HolidayUtil {
  static localHolidays: { [key in FederalStates]?: LocalHoliday[] } = {
    BY: [augsburgerFriedensfest, mariaHimmelfahrt],
    SN: [fronleichnam],
    TH: [fronleichnam],
  };

  public static checkForHolidays(postalCode: string, now: Dayjs): boolean {
    const holidayOptions: HolidaysTypes.Options = { types: ['public'] };
    const hd = new Holidays();

    const state = postalCodeRecords[postalCode];
    if (state) {
      if (this.checkForAugsburgPostalCode(postalCode)) {
        hd.init('DE', FederalStates.BY, 'A', holidayOptions);
      } else {
        hd.init('DE', state, holidayOptions);
      }
    } else {
      hd.init('DE', holidayOptions);
    }

    return !!hd.isHoliday(now.toDate());
  }

  public static checkForHolidaysWithSettings({ state, customHolidays }: PharmacyHolidaySettings, now: Dayjs): boolean {
    const holidayOptions: HolidaysTypes.Options = { types: ['public'] };
    const hd = new Holidays();
    hd.init('DE', state, holidayOptions);

    if (FederalStates.BY === state) {
      if (!this.isMariaHimmelfahrtHolidayInBY(state, customHolidays)) {
        hd.init('DE', FederalStates.BY, 'EVANG', holidayOptions);
      }

      if (this.isAugsburgerFriedensfestHolidayInBY(state, customHolidays)) {
        const rule = this.getAugsburgHolidayRule();
        hd.setRule(rule);
      }
    } else if (
      (FederalStates.SN === state || FederalStates.TH === state) &&
      this.isFronleichnamHolidayInSNTH(state, customHolidays)
    ) {
      if (state === FederalStates.SN) {
        hd.init('DE', state, 'BZ', holidayOptions);
      } else if (state === FederalStates.TH) {
        hd.init('DE', state, 'EIC', holidayOptions);
      }
    }

    return !!hd.isHoliday(now.toDate());
  }

  private static checkForAugsburgPostalCode(postalCode: string) {
    const augsburgPostalCodes = [];
    for (let i = 86150; i <= 86199; i++) {
      augsburgPostalCodes.push(i.toString());
    }
    return augsburgPostalCodes.includes(postalCode);
  }

  private static isMariaHimmelfahrtHolidayInBY(state: FederalStates.BY, customHolidays: LocalHoliday[]) {
    return this.localHolidays[state]?.includes(mariaHimmelfahrt) && customHolidays.includes(mariaHimmelfahrt);
  }

  private static isAugsburgerFriedensfestHolidayInBY(state: FederalStates.BY, customHolidays: LocalHoliday[]) {
    return (
      this.localHolidays[state]?.includes(augsburgerFriedensfest) && customHolidays.includes(augsburgerFriedensfest)
    );
  }

  private static isFronleichnamHolidayInSNTH(
    state: FederalStates.SN | FederalStates.TH,
    customHolidays: LocalHoliday[]
  ) {
    return this.localHolidays[state]?.includes(fronleichnam) && customHolidays.includes(fronleichnam);
  }

  private static getAugsburgHolidayRule() {
    const hd = new Holidays('DE', FederalStates.BY, 'A');
    return hd.getRule('08-08');
  }
}
