import gql from 'graphql-tag';
import { chatPartnerMetadataAttributes } from '../attributes';

export default gql`
  subscription updatedChatPartnerMetadataForPharmacy($cognitoId: ID!) {
    updatedChatPartnerMetadataForPharmacy(cognitoId: $cognitoId) {
      ${chatPartnerMetadataAttributes}
    }
  }
`;
