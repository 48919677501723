import gql from 'graphql-tag';

export default gql`
  subscription selfStartedTyping($participantId: ID!) {
    selfStartedTyping(participantId: $participantId) {
      __typename
      participantId
      conversationId
      otherParticipantId
      frontendSessionId
    }
  }
`;
