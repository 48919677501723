import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { PrintPdfTableTypeEnum } from '../../../enums/tableToPdf.enum';
import { PrintPdfTableInterface } from '../../../interfaces/pdf.interface';
import { ReturnsFiltersInterface } from '../../../interfaces/returns.interface';
import { OrderFiltersInterface } from '../../../interfaces/order.interface';

export const PrintPdfReturns = gql`
    query PrintPdfReturns($filters: ReturnsFilter!) {
        PrintPdfReturns(filters: $filters) {
            status
            message
            errorCode
            data
        }
    }
`;

export const PrintPdfOrders = gql`
    query PrintPdfOrders($filters: OrdersFilter!) {
        PrintPdfOrders(filters: $filters) {
            status
            message
            errorCode
            data
        }
    }
`;

export const AllPdfQueries = [
    PrintPdfReturns,
    PrintPdfOrders
];

@Injectable()
export class PdfQueries {
    constructor(
        private apollo: Apollo
    ) {}


    /**
     * Return amount of available orders
     */
    public printPdfByList(tableType: PrintPdfTableTypeEnum, filters?: ReturnsFiltersInterface): Observable<PrintPdfTableInterface> {

        const useFilters = [PrintPdfTableTypeEnum.returns, PrintPdfTableTypeEnum.orders].includes(tableType);

        const query = tableType === PrintPdfTableTypeEnum.returns
            ? PrintPdfReturns : PrintPdfOrders;

        if (useFilters && !filters) {
            throw new Error('Filters are required for returns');
        }
        const filtersFormatted: ReturnsFiltersInterface | OrderFiltersInterface = {};
        if (useFilters && filters) {
            for (const filter in filters) {
                if (filters[filter]?.length && filters[filter] !== 'Alle' && filters[filter] !== 'all') {
                    filtersFormatted[filter] = filters[filter];
                }
            }
        }
        const variables = useFilters ? {filters: filtersFormatted} : {type: tableType};

        return this.apollo.watchQuery({
            query,
            variables,
            fetchPolicy: QueryFetchPolicy.NETWORK_ONLY
        })
            .valueChanges
            .pipe(map(d => {
                return tableType === PrintPdfTableTypeEnum.returns
                    ? d?.data && d?.data['PrintPdfReturns'] : tableType === PrintPdfTableTypeEnum.orders
                        ? d?.data && d?.data['PrintPdfOrders'] : d?.data && d?.data['PrintPdfByList'];
            })) as Observable<PrintPdfTableInterface>;
    }
}
