import { Component, inject, Input, OnInit, signal, ViewChild } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Moment } from 'moment';
import { Observable, Subscription, switchMap, tap } from 'rxjs';
import isEqual from 'lodash/isEqual';

// Components
import { SelectPopoverComponent } from '../../../../../../core/components/select-popover/select-popover.component';

// Core Files
import { DateRangeOptionCodes, OrderStatusFilter, ModalClassEnum } from '../../../../../../core/core.enums';
import {
    OrderTypesAllConfig,
    OrderTypesConfig,
    GetDateRangeConfig,
    ExpiryDateRangeConfig,
    OrderStatusFilterConfig,
    getOrderTypesWithAll,
    OrderDefaultFilters,
    OrderFilterResultConfig
} from '../../../../../../core/core.config';
import { DateRangeInterface, OrderFiltersInterface } from '../../../../../../core/core.interfaces';
import { OrderQueries, OrdersMutations } from '../../../../../../core/core.store';
import { formatDateTimeToMoment, formatMomentDateToDefaultDateFormat } from '../../../../../../core/core.formatting';
import { ModalService, PopoverService } from '../../../../../../core/core.services';
import { unsubscribe } from '../../../../../../core/core.utils';

enum filterFieldsEnum {
    PZN = 'pzn',
    PRODUCER = 'producer'
}
@Component({
    selector: 'app-order-search-modal',
    templateUrl: './order-search-modal.component.html',
    styleUrls: ['./order-search-modal.component.scss'],
})
export class OrderSearchModalComponent implements OnInit {
    // Adds class to modal
    static modalClass = ModalClassEnum.large;

    @Input() focusOnSearch: boolean;
    @Input() searchText: string;
    @Input() saveFilters = true;
    @ViewChild('searchInput') searchInput;

    private orderQueries = inject(OrderQueries);
    private popoverService = inject(PopoverService);
    private modalService = inject(ModalService);
    private ordersMutations = inject(OrdersMutations);

    producerSearch = signal('');
    producersList$ = toObservable(this.producerSearch).pipe(
        switchMap(search => {
            if (search?.length) {
                return this.orderQueries.getProducerCollection(search);
            } else {
                return new Observable<string[]>(subscriber => {
                    subscriber.next([]);
                });
            }
        })
    );
    producersList = toSignal(this.producersList$, {initialValue: []});
    selectedProducer = signal('');

    pznSearch = signal('');
    pznList$ = toObservable(this.pznSearch).pipe(
        switchMap(search => {
            if (search?.length) {
                return this.orderQueries.getPZNCollection(search);
            } else {
                return new Observable<string[]>(subscriber => {
                    subscriber.next([]);
                });
            }
        })
    );
    pznList = toSignal(this.pznList$, {initialValue: []});
    selectedPZN = signal('');

    orderFilters$ = this.orderQueries.getOrderFilters().pipe(
        tap((filters) => {
            if (filters?.producer) {
                this.producerSearch.set(filters.producer);
            }
            if (filters?.pzn) {
                this.pznSearch.set(filters.pzn);
            }
        })
    );
    /**
     * Is only used to activate the observable.
     */
    private _orderFiltersReadOnly = toSignal(this.orderFilters$);

    filterFields = {
        pzn: filterFieldsEnum.PZN,
        producer: filterFieldsEnum.PRODUCER
    };

    filters: OrderFiltersInterface = {};
    statusFilterConfig = OrderFilterResultConfig.items.find(item => item.id === 'status');

    expiryDateRangeOptions: Array<DateRangeInterface> = ExpiryDateRangeConfig();
    defaultExpiryDateRangeOption: DateRangeOptionCodes = DateRangeOptionCodes.all;
    defaultExpiryFromDate: Moment;
    defaultExpiryToDate: Moment;

    selectedExpiryDateRangeOption = DateRangeOptionCodes.all;
    selectedExpiryFromDate: Moment;
    selectedExpiryToDate: Moment;

    recTimeRangeOptions: Array<DateRangeInterface> = GetDateRangeConfig();
    defaultRecTimeRangeOption: DateRangeOptionCodes  = DateRangeOptionCodes.all;
    defaultRecTimeFromDate: Moment;
    defaultRecTimeToDate: Moment;

    selectedRecTimeRangeOption = DateRangeOptionCodes.all;
    selectedOrderFromDate: Moment;
    selectedOrderToDate: Moment;

    selectedDeliveryType = OrderTypesAllConfig;

    search: string;
    filterId;
    orderFiltersSubscription: Subscription;

    defaultFilters = OrderDefaultFilters;

    constructor() {}

    ngOnInit() {
        this.search = this.searchText;
        unsubscribe(this.orderFiltersSubscription);
        this.orderFiltersSubscription = this.orderQueries.getOrderFilters().subscribe(filters => {
            if(filters) {
                this.applyFilters(filters);
            }
            unsubscribe(this.orderFiltersSubscription);
        });
    }


    ionViewDidEnter() {
        if(this.searchInput && this.searchInput.el) {
            this.searchInput.setFocus();
            this.searchInput.el.setFocus();
        }
    }

    applyFilters(filters) {
        this.filters = filters;
        this.filterId = filters.id;
        this.search = (filters.search) ? filters.search : '';

        if (filters.type && filters.type !== 'all') {
            this.selectedDeliveryType = OrderTypesConfig.find(type => type.id === filters.type);
            if(!this.selectedDeliveryType) {
                this.selectedDeliveryType = OrderTypesAllConfig;
            }
        } else {
            this.selectedDeliveryType = OrderTypesAllConfig;
        }

        if(filters.recTimeOption && filters.recTimeOption !== DateRangeOptionCodes.individual) {
            this.defaultRecTimeRangeOption = filters.recTimeOption;
            this.selectedRecTimeRangeOption = filters.recTimeOption;
        } else if(filters.recTimeFrom && filters.recTimeTo) {
            this.defaultRecTimeRangeOption = DateRangeOptionCodes.individual;
            this.defaultRecTimeFromDate = formatDateTimeToMoment(filters.recTimeFrom);
            this.defaultRecTimeToDate = formatDateTimeToMoment(filters.recTimeTo);
            this.selectedRecTimeRangeOption = DateRangeOptionCodes.individual;
            this.selectedOrderFromDate = formatDateTimeToMoment(filters.recTimeFrom);
            this.selectedOrderToDate = formatDateTimeToMoment(filters.recTimeTo);
        } else {
            this.defaultRecTimeRangeOption = DateRangeOptionCodes.all;
            this.selectedRecTimeRangeOption = DateRangeOptionCodes.all;
        }

        if(filters.expiryDateOption && filters.expiryDateOption !== DateRangeOptionCodes.individual) {
            this.defaultExpiryDateRangeOption = filters.expiryDateOption;
            this.selectedExpiryDateRangeOption = filters.expiryDateOption;
        } else if(filters.expiryDateFrom && filters.expiryDateTo) {
            this.defaultExpiryDateRangeOption = DateRangeOptionCodes.individual;
            this.defaultExpiryFromDate = formatDateTimeToMoment(filters.expiryDateFrom);
            this.defaultExpiryToDate = formatDateTimeToMoment(filters.expiryDateTo);
            this.selectedExpiryDateRangeOption = DateRangeOptionCodes.individual;
            this.selectedExpiryFromDate = formatDateTimeToMoment(filters.expiryDateFrom);
            this.selectedExpiryToDate = formatDateTimeToMoment(filters.expiryDateTo);
        } else {
            this.defaultExpiryDateRangeOption = DateRangeOptionCodes.all;
        }

        this.selectedPZN.set(filters.pzn ? filters.pzn : '');
        this.selectedProducer.set(filters.producer ? filters.producer : '');

    }

    /**
     * Updates the date filter
     *
     * @param event - Event of the date filter
     * @param isExpiryDate - If the date filter is for the expiry date
     */
    updateDateFilter(event, isExpiryDate= false) {
        let option = DateRangeOptionCodes.individual;

        if(event.dateRangeId && event.dateRangeId !== DateRangeOptionCodes.individual) {
            option = event.dateRangeId;
        }

        const from = event.fromDate;
        const to = event.toDate;

        if(isExpiryDate) {
            this.selectedExpiryDateRangeOption = option;
            this.selectedExpiryFromDate = from;
            this.selectedExpiryToDate = to;
        } else {
            this.selectedRecTimeRangeOption = option;
            this.selectedOrderFromDate = from;
            this.selectedOrderToDate = to;
        }
    }

    /**
     * Show a popover with all available delivery types
     *
     * @param event - Event of the click
     */
    async presentDeliveryTypePopover(event: any) {
        this.focusOnSearch = false;
        const deliveryTypePopover = await this.popoverService.create({
            component: SelectPopoverComponent,
            componentProps: {
                options: getOrderTypesWithAll(),
                selectedKey: this.selectedDeliveryType.id
            },
            event,
            translucent: true,
            cssClass: 'delivery-type-popover'
        });

        deliveryTypePopover.onDidDismiss().then(result => {
            if(result.data && result.data.id && result.data.id !== 'all') {
                this.selectedDeliveryType = OrderTypesConfig.find(type => type.id === result.data.id);
            } else if (result.data && result.data.id) {
                this.selectedDeliveryType = OrderTypesAllConfig;
            }
        });

        return await this.popoverService.present(deliveryTypePopover);
    }

    popoverSearch(search: string, type: filterFieldsEnum) {
        switch (type) {
            case filterFieldsEnum.PZN:
                this.pznSearch.set(search);
                break;
            case filterFieldsEnum.PRODUCER:
                this.producerSearch.set(search);
                break;
        }
    }
    onPopoverItemSelect(event: string, type: filterFieldsEnum) {
        switch (type) {
            case filterFieldsEnum.PZN:
                this.selectedPZN.set(event);
                break;
            case filterFieldsEnum.PRODUCER:
                this.selectedProducer.set(event);
                break;
        }
    }
    /**
     * Show a popover with all available delivery states
     *
     * @param event - Event of the click
     */
    async presentDeliveryStatusPopover(event: any) {
        this.focusOnSearch = false;
        const deliveryStatusPopover = await this.popoverService.create({
            component: SelectPopoverComponent,
            componentProps: {
                options: OrderStatusFilterConfig.filter(item => !item['excludeFromFilter']),
                selected: this.filters[this.statusFilterConfig.id],
                useCheckboxes: this.statusFilterConfig.useCheckboxes
            },
            event,
            translucent: true,
        });

        deliveryStatusPopover.onDidDismiss().then(result => {
            if(result.data) {
                if(result.data.multipleIds) {
                    this.filters[this.statusFilterConfig.id] = result.data.multipleIds;
                } else {
                    this.filters[this.statusFilterConfig.id] = OrderStatusFilter.ALL;
                }
            }
            this.filters = {...this.filters};
        });

        return await this.popoverService.present(deliveryStatusPopover);
    }

    clearFilters() {
        this.selectedExpiryDateRangeOption = DateRangeOptionCodes.all;
        this.selectedExpiryFromDate = null;
        this.selectedExpiryToDate = null;

        this.selectedRecTimeRangeOption = DateRangeOptionCodes.all;
        this.selectedOrderFromDate = null;
        this.selectedOrderToDate = null;

        this.filters[this.statusFilterConfig.id] =OrderStatusFilter.ALL;
        this.selectedDeliveryType = OrderTypesAllConfig;

        this.selectedPZN.set('');
        this.selectedProducer.set('');
        this.search = '';
    }

    /**
     * Reset all filters and execute a new search
     */
    clearFilterAndSearch() {
        this.clearFilters();
        void this.applyFilterAndSearch(false);
    }

    /**
     * applies filter and search and closes the search modal
     */
    async applyFilterAndSearch(saveHistory = true) {
        this.focusOnSearch = false;

        const updatedFilters = {
            expiryDateOption: this.selectedExpiryDateRangeOption,
            expiryDateFrom: this.selectedExpiryFromDate ? formatMomentDateToDefaultDateFormat(this.selectedExpiryFromDate) : '',
            expiryDateTo: this.selectedExpiryToDate ? formatMomentDateToDefaultDateFormat(this.selectedExpiryToDate) : '',
            recTimeOption: this.selectedRecTimeRangeOption,
            recTimeFrom: this.selectedOrderFromDate ? formatMomentDateToDefaultDateFormat(this.selectedOrderFromDate) : '',
            recTimeTo: this.selectedOrderToDate ? formatMomentDateToDefaultDateFormat(this.selectedOrderToDate) : '',
            type: this.selectedDeliveryType.id,
            status: this.filters[this.statusFilterConfig.id],
            producer: this.selectedProducer(),
            pzn: this.selectedPZN(),
            search: this.search
        };

        if(this.saveFilters && saveHistory && !isEqual(updatedFilters, this.defaultFilters)) {
            this.ordersMutations.insertOrderFiltersHistory(updatedFilters);
        }

        await this.modalService.dismiss(updatedFilters);
    }

    /**
     * deletes given filter
     *
     * @param type - filter type
     */
    deleteFilter(type: filterFieldsEnum) {
        switch (type) {
            case filterFieldsEnum.PZN:
                this.selectedPZN.set('');
                break;
            case filterFieldsEnum.PRODUCER:
                this.selectedProducer.set('');
                break;
        }
    }

    onAdoptSearch(response) {
        this.applyFilters(response.filters);
        if(response.shouldExecuteSearch) {
            void this.applyFilterAndSearch(false);
        }
    }
}
