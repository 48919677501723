export const SoundInterval: Array<{seconds: number, label: string}> = [
    { seconds: 0,       label: 'Aus'                },
    { seconds: 10,      label: 'Alle 10 Sekunden'   },
    { seconds: 30,      label: 'Alle 30 Sekunden'   },
    { seconds: 60,      label: 'Jede Minute'        },
    { seconds: 300,     label: 'Alle 5 Minuten'     },
    { seconds: 600,     label: 'Alle 10 Minuten'    },
    { seconds: 1200,    label: 'Alle 20 Minuten'    }
];

export const DefaultSoundIntervals = {
    common: 600,
    chat: 600
};
