import { ImageViewData } from '../../../types/src/imageViewData';
import { DecryptedImageMedia } from '../../../types/src/messageMedia';
import { MimeTypeEnum } from '../../../types/src/mimeTypeEnum';

export class DataUrlUtil {
  static extractContentFromDataUrl(dataUrl: string): string {
    const content = dataUrl.split('base64,')[1];
    if (!content) {
      throw new Error('Invalid data url format');
    }
    return content;
  }

  static convertContentToDataUrl(content: string, { mimeType = MimeTypeEnum.JPG } = {}): string {
    return `data:${mimeType};base64,${content}`;
  }

  static getPreviewImage(image: DecryptedImageMedia): ImageViewData | undefined {
    const imageSrc =
      image.externalPreviewLink ||
      image.externalLink ||
      (image.decryptedPreview && this.convertContentToDataUrl(image.decryptedPreview)) ||
      (image.decryptedContent && this.convertContentToDataUrl(image.decryptedContent));
    return imageSrc ? { src: imageSrc, exifOrientation: image.exifOrientation } : undefined;
  }
}
