import dayjs, { Dayjs } from 'dayjs';

export interface MeaDateOptions {
  enforceDate?: boolean;
}

export class MeaDateUtil {
  static transformTimestamp(value: number | null, option?: MeaDateOptions): string {
    const enforceDate = option?.enforceDate;

    if (!value) {
      return '';
    }

    const timestamp = dayjs(value * 1000);
    if (enforceDate) {
      return this.getDateStringForDate(timestamp);
    }

    if (this.isToday(timestamp)) {
      return this.getDateStringForToday(timestamp);
    } else if (this.isYesterday(timestamp)) {
      return this.getDateStringForYesterday();
    } else {
      return this.getDateStringForDate(timestamp);
    }
  }

  private static isToday(timestamp: Dayjs): boolean {
    const now = dayjs();
    return timestamp.isSame(now, 'day');
  }

  private static isYesterday(timestamp: Dayjs): boolean {
    const now = dayjs();
    return timestamp.isSame(now.subtract(1, 'day'), 'day');
  }

  private static getDateStringForDate(timestamp: Dayjs) {
    return timestamp.format('DD.MM.YYYY');
  }

  private static getDateStringForToday(timestamp: Dayjs) {
    return timestamp.format('HH:mm');
  }

  private static getDateStringForYesterday() {
    return 'Gestern';
  }
}
