import {
    Component, effect, ElementRef, EventEmitter, HostListener, inject, Input, OnInit, Output, signal, Signal, ViewChild
} from '@angular/core';
import { IonicSlides, NavController } from '@ionic/angular';
import { FullLogisticsRoutesEnum } from '../../enums/logisticsRoutes.enum';
import { TableWidgetColumnInterface } from '../../interfaces/table-widget.interface';
import { TableWidgetColumnTypeEnum } from '../../enums/table-type.enum';
import { ColorInterface } from '../../interfaces/theme-color.interface';
import { BadgeTypeEnum } from '../../enums/badge.enum';
import { UtilService } from '../../core.services';
import { PopoverActionItemInterface } from '../../interfaces/popover-action-item.interface';

@Component({
    selector: 'app-table-widget',
    templateUrl: './table-widget.component.html',
    styleUrls: ['./table-widget.component.scss'],
})
export class TableWidgetComponent implements OnInit {
    private utilService = inject(UtilService);
    private navCtrl = inject(NavController);

    @Input({required: true}) isLoading = true;
    @Input({required: true}) data: Signal<Array<any>> = signal([]).asReadonly();
    @Input({required: true}) columns: Array<TableWidgetColumnInterface> = [];
    @Input() displayWidgetHeader = true;
    @Input() title = 'Aufträge';
    @Input() titleTtk = 'orders_widget_information';
    @Input() iconName = 'ellipsis-vertical';
    @Input() showMoreButton = true;
    @Input() themeColor?: ColorInterface;

    /**
     * This is required to accurately calculate the maximum rows to display.
     */
    @Input() rowHeight = 34;
    /**
     * Used in the maximumRows calculation. The algorithm tries to fit as many rows into the table to match
     * the approxContentHeight as best as possible.
     */
    @Input() approxContentHeight: number;
    /**
     * Set the icon margin right
     */
    @Input() widgetInfoIconMarginRight? = '0';

    @Input() widgetKey = ''; // unique key for the widget
    @Input() popoverActionItems: PopoverActionItemInterface[] = [];


    /**
     * Emit an event when the user clicks on a row, so that parent component can react accordingly
     */
    @Output() onRowClicked = new EventEmitter<{event: any, id: number | string}>();
    /**
     * Emit an event when the user clicks on the ellipsis icon, so that parent component can construct the right popover
     */
    @Output() onPopoverClicked = new EventEmitter<{event: MouseEvent, id: number}>();

    @ViewChild('tableSlider') tableSlider: ElementRef;
    @ViewChild('contentContainer') contentContainer: ElementRef;
    @ViewChild('footerContainer') footerContainer: ElementRef;

    swiperModules = [IonicSlides];

    maximumRows = 1;

    hasTableHeader = false;
    contentHeight: number = null;
    actionsHeight = 0;
    footerHeight = 0;
    // Pagination Variables
    currentPage = 1;
    pageCount = 1;
    skeletonArray = [];
    columnTypes = TableWidgetColumnTypeEnum;
    badgeTypes = BadgeTypeEnum;

    isCalculationDone = false;

    constructor(
    ) {
        effect(() => {
            if (this.data()) {
                this.calculateMaximumRows();
                this.resetPageCount();
            }
        });
        this.calculateMaximumRows = this.utilService.debounce(this.calculateMaximumRows, 50);
    }

    ngOnInit() {
        this.calculateMaximumRows();
        this.hasTableHeader = this.columns.some(column => column.hasOwnProperty('label'));
    }

    @HostListener('window:resize')
    onResize() {
        if (this.tableSlider?.nativeElement?.swiper?.slideTo) {
            this.tableSlider?.nativeElement?.swiper?.slideTo(0);
        }
        this.calculateMaximumRows();
        this.updatePageCount();
    }

    /**
     * Calculate the maximum rows to display depending on the height of the
     * content. The actions container is also the row height reference.
     */
    calculateMaximumRows() {
        if (this.footerContainer && this.footerContainer.nativeElement
            && this.contentContainer && this.contentContainer.nativeElement) {
            this.contentHeight = this.contentContainer.nativeElement.getBoundingClientRect().height - 8;    // 8px accounts for margin top
            this.contentHeight = this.approxContentHeight && (this.contentHeight < this.approxContentHeight) ?
                this.approxContentHeight :
                this.contentHeight;
            if (this.contentHeight > 0) {
                if(!this.isCalculationDone) {this.isCalculationDone = true;}
                const ratio = this.contentHeight / this.rowHeight;
                const shouldCeil = parseFloat(ratio.toString().substring(ratio.toString().indexOf('.') + 1, ratio.toString().indexOf('.') + 3)) >= 90;
                const maximumRows = shouldCeil ? Math.ceil(ratio) : Math.floor(ratio);
                // Only update if necessary to prevent performance issues
                if (this.maximumRows !== maximumRows && maximumRows > 0) {
                    this.maximumRows = maximumRows;
                    this.skeletonArray = new Array(maximumRows);
                    this.updatePageCount();
                }
            } else {
                setTimeout(() => {
                    // Fallback when the slider is not initialized but all the content is already finished.
                    this.calculateMaximumRows();
                }, 250);
            }
        } else {
            setTimeout(() => {
                // Fallback when the slider is not initialized but all the content is already finished.
                this.calculateMaximumRows();
            }, 50);
        }
    }

    resetPageCount() {
        setTimeout(() => {
            this.pageCount = this.tableSlider?.nativeElement?.childElementCount || 1;
            this.currentPage =  1;
            if (this.tableSlider?.nativeElement?.swiper?.update) {
                this.tableSlider.nativeElement.swiper.update();
                this.tableSlider.nativeElement.swiper.slideTo(0);
            }
        },100);
    }

    /**
     * Update page count of the slider
     */
    updatePageCount() {
        // Without the timeout, the slider isn't ready to receive the correct page count
        setTimeout(() => {
            this.pageCount = this.tableSlider?.nativeElement?.childElementCount || 1;
            this.currentPage = this.tableSlider?.nativeElement?.swiper?.activeIndex + 1 || 1;
            if (this.tableSlider?.nativeElement?.swiper?.update) {
                this.tableSlider?.nativeElement?.swiper?.update();
            }
        },250);
    }

    /**
     * Emit an event when the user clicks on a row, so that parent component can react accordingly.
     *
     * @param event Click event from click listener
     * @param id unique identifier of data in row
     */
    async onRowPressed(event: any, id: number | string) {
        this.onRowClicked.emit({event, id});
    }

    /**
     * Emit an event when the user clicks on the ellipsis icon, so that parent component can construct the right popover.
     *
     * @param event Click event from click listener
     * @param id unique identifier of data in row
     */
    async presentPopover(event: MouseEvent, id: number) {
        this.onPopoverClicked.emit({event, id});
    }

    /**
     * redirects user to oder page
     */
    onMoreButtonClick() {
        void this.navCtrl.navigateRoot(FullLogisticsRoutesEnum.orders);
    }

    /**
     * Slide back
     */
    previousSlide(){
        if (this.tableSlider?.nativeElement?.swiper) {
            this.tableSlider.nativeElement.swiper.slidePrev();
        }
    }

    /**
     * Slide forward
     */
    nextSlide() {
        if (this.tableSlider?.nativeElement?.swiper) {
            this.tableSlider.nativeElement.swiper.slideNext();
        }
    }


    /**
     * track by
     *
     * @param index - Index of the item in the array
     * @param item - The item to track
     */
    trackBy(index, item) {
        return item.id;
    }
}
