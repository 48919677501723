import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';
import { Product } from '../../../../../essentials/types/src/product';
import { ProductSearchLimit } from '../../../../../essentials/types/src/productSearchLimit';

export const setShopProductSearchTerm = createAction(
  `${ActionType.ShopProductSearch}: Set shop product search term`,
  props<{ shopApiBaseUrl: string; searchTerm: string }>()
);

export const loadMoreShopProducts = createAction(
  `${ActionType.ShopProductSearch}: Load more results`,
  props<{ shopApiBaseUrl: string }>()
);

export const searchForShopProducts = createAction(
  `${ActionType.ShopProductSearch}: Search for shop products`,
  props<{ shopApiBaseUrl: string; searchTerm: string; limit: ProductSearchLimit }>()
);

export const searchForShopProductsSuccess = createAction(
  `${ActionType.ShopProductSearch}: Successful search for shop products`,
  props<{ searchResults: Product[] }>()
);

export const searchForShopProductsFailure = createAction(
  `${ActionType.ShopProductSearch}: Failed search for shop products`
);

export const resetShopProductsSearch = createAction(`${ActionType.ShopProductSearch}: Reset shop products search`);
