import { DateRangeInterface } from '../interfaces/date-range.interface';
import { DateRangeOptionCodes } from '../enums/date-range.enum';
import { endOf, endOfCurrent, startOf, startOfCurrent, today } from '../formatting/date.formatting';

export const AdditionalDateRangeConfig = {
    [DateRangeOptionCodes.unknown]:
        {id: DateRangeOptionCodes.unknown,      title: 'Unbekannt',     sortOrder: 50},
    [DateRangeOptionCodes.nextWeek]:
        {id: DateRangeOptionCodes.nextWeek,     title: 'Nächste Woche', sortOrder: 250},
    [DateRangeOptionCodes.thisYear]:
        {id: DateRangeOptionCodes.thisYear,     title: 'Dieses Jahr',   sortOrder: 900},
};

/**
 * get the general, unsorted date range config
 */
export const DateRangeConfig: DateRangeInterface[]  = [
    {id: DateRangeOptionCodes.all,          title: 'Alle',              sortOrder: 100},
    {id: DateRangeOptionCodes.today,        title: 'Heute',             sortOrder: 200},
    {id: DateRangeOptionCodes.thisWeek,     title: 'Aktuelle Woche',    sortOrder: 300},
    {id: DateRangeOptionCodes.lastWeek,     title: 'Letzte Woche',      sortOrder: 400},
    {id: DateRangeOptionCodes.thisMonth,    title: 'Aktueller Monat',   sortOrder: 500},
    {id: DateRangeOptionCodes.lastMonth,    title: 'Letzter Monat',     sortOrder: 600},
    {id: DateRangeOptionCodes.thisQuarter,  title: 'Aktuelles Quartal', sortOrder: 700},
    {id: DateRangeOptionCodes.lastQuarter,  title: 'Letztes Quartal',   sortOrder: 800},
    {id: DateRangeOptionCodes.individual,   title: 'Individuell',       sortOrder: 1500}
];

/**
 * gets the date range for delivery dates
 */
export const ExpiryDateRangeConfig = () => {
    // json parse and stringify to clone the array, that it does not affect the original one
    const expiryDateRangeConfig = [...DateRangeConfig];

    expiryDateRangeConfig.push(AdditionalDateRangeConfig[DateRangeOptionCodes.unknown]);
    expiryDateRangeConfig.push(AdditionalDateRangeConfig[DateRangeOptionCodes.nextWeek]);

    return expiryDateRangeConfig.sort((a, b) => a.sortOrder - b.sortOrder);
};

/**
 * gets the date range for delivery dates
 */
export const DeliveryDateRangeConfig = () => {
    // json parse and stringify to clone the array, that it does not affect the original one
    const deliveryDateRangeConfig = [...DateRangeConfig];

    deliveryDateRangeConfig.push(AdditionalDateRangeConfig[DateRangeOptionCodes.unknown]);
    deliveryDateRangeConfig.push(AdditionalDateRangeConfig[DateRangeOptionCodes.nextWeek]);

    return deliveryDateRangeConfig.sort((a, b) => a.sortOrder - b.sortOrder);
};

/**
 * get date range from DateRangeConfig
 */
export const GetDateRangeConfig = () => {
    return DateRangeConfig.sort((a, b) => a.sortOrder - b.sortOrder);
};

/**
 * gets the date range for appointments dates
 */
export const AppointmentDateRangeConfig = () => {
    const allowedDateRangeOptionCodes = [DateRangeOptionCodes.individual];
    return DateRangeConfig
        .filter(item => allowedDateRangeOptionCodes.includes(item.id))
        .sort((a, b) => a.sortOrder - b.sortOrder);
};

export const SeminarDateRangeConfig = () => {
    const dateRange =  DateRangeConfig.filter(item => {
        return !(item.id === DateRangeOptionCodes.lastMonth
        || item.id === DateRangeOptionCodes.lastQuarter
        || item.id === DateRangeOptionCodes.lastWeek);

    });
    dateRange.push(AdditionalDateRangeConfig[DateRangeOptionCodes.nextWeek]);
    dateRange.push(AdditionalDateRangeConfig[DateRangeOptionCodes.thisYear]);
    dateRange.sort((a, b) => a.sortOrder - b.sortOrder);

    return dateRange;
};

/**
 * gets the fromDate or toDate of the given date range option
 *
 * @param dateRange - Data range option code
 * @param isFromDate - if it is false the toDate will be loaded
 */
export const getDateFromConfig = (dateRange: DateRangeOptionCodes, isFromDate: boolean) => {
    switch (dateRange) {
        case DateRangeOptionCodes.today:
            return isFromDate ? today() : today();
        case DateRangeOptionCodes.lastWeek:
            return isFromDate ? startOf('week', false) : endOf('week', false);
        case DateRangeOptionCodes.beforeLastMonth:
            return isFromDate ?
                startOf('month', false, null, 2) :
                endOf('month', false, null, 2);
        case DateRangeOptionCodes.lastMonth:
            return isFromDate ? startOf('month', false) : endOf('month', false);
        case DateRangeOptionCodes.lastQuarter:
            return isFromDate ? startOf('quarter', false) : endOf('quarter', false);
        case DateRangeOptionCodes.thisWeek:
            return isFromDate ? startOfCurrent('week') : endOfCurrent('week');
        case DateRangeOptionCodes.thisMonth:
            return isFromDate ? startOfCurrent('month') : endOfCurrent('month');
        case DateRangeOptionCodes.thisQuarter:
            return isFromDate ? startOfCurrent('quarter') : endOfCurrent('quarter');
        case DateRangeOptionCodes.nextWeek:
            return isFromDate ? startOf('week', true) : endOf('week', true);
        case DateRangeOptionCodes.thisYear:
            return isFromDate ? startOf('year', false, null, 0) : endOf('year', false, null, 0);
        case DateRangeOptionCodes.lastYear:
            return isFromDate ? startOf('year', false, null, 1) : endOf('year', false, null, 1);
        case DateRangeOptionCodes.all:
        case DateRangeOptionCodes.individual:
        case DateRangeOptionCodes.unknown:
            return null;
    }
};

/**
 * gets the fromDate or toDate of the given date range option
 *
 * @param dateRange - Data range option code
 */
export const getDateRangesWithoutDates = (dateRange: DateRangeOptionCodes) => {
    switch (dateRange) {
        case DateRangeOptionCodes.all:
        case DateRangeOptionCodes.unknown:
            return true;
        default:
            return false;
    }
};

export const formatDateFilters = (filters: object, optionFieldName, fromDateFieldName, toDateFieldName) => {
    if (filters
        && filters[optionFieldName] !== DateRangeOptionCodes.individual
        && filters[optionFieldName] !== DateRangeOptionCodes.all
        && filters[optionFieldName] !== DateRangeOptionCodes.unknown) {
        filters[fromDateFieldName] = getDateFromConfig(filters[optionFieldName], true);
        filters[toDateFieldName] = getDateFromConfig(filters[optionFieldName], false);
    }
    return filters;
};

