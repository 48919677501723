import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';
import { MessageMetaData } from '../../../../../essentials/types/src/messageMetaData';

export const createAndSendMessage = createAction(
  `${ActionType.Chat}: Send message`,
  props<{
    textMessage: string;
    senderId: string;
    conversationId: string;
    additionalMetadata?: Partial<MessageMetaData>;
  }>()
);
