<ion-segment (ionChange)="_onChange($event)" [value]="value" *ngIf="!_showPopover" [scrollable]="true">
    <ion-segment-button value="{{item.id}}" *ngFor="let item of items; index as i" mode="ios"
                        appTooltip ttp="top" ttk="statistics_{{item.id}}">
        <ion-label i18n>{{item.label}}</ion-label>
    </ion-segment-button>
</ion-segment>

<ion-button *ngIf="_showPopover" (click)="_onPopoverClick($event)">
    {{ items | findAttr:value:'id':'label'}}
    <ion-icon name="caret-down-sharp"></ion-icon>
</ion-button>
<ion-popover [isOpen]="_isPopoverOpen" (didDismiss)="_isPopoverOpen = false" #segmentPopover>
    <ng-template>
        <app-popover-actions [buttonArray]="_popoverButtons" (buttonClickEvent)="_onPopoverButtonClick($event)"></app-popover-actions>
    </ng-template>
</ion-popover>
