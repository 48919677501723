export enum OrderType {
    DISPOSITION = 'disposition',
    ADDITIONAL = 'additional',
    UE = 'UE',
    N_PLUS = 'N_PLUS',
    ALL = 'all'
}

export enum OrderTypeShort {
    DISPOSITION = 'D',
    ADDITIONAL = 'N',
    UE = 'Ü',
    N_PLUS = 'B'
}

export enum OrderStatus {
    ALL = 'ALL',
    PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',
    COMPLETED = 'COMPLETED',
    PENDING = 'PENDING',
    ENROUTE = 'ENROUTE',
    DELEGATED = 'DELEGATED',
    CANCELLED = 'CANCELLED',
}

export enum OrderStatusFilter {
    ALL = 'ALL',
    OPEN = 'OPEN_GROUP',
    COMPLETED = 'COMPLETED_GROUP',
    DELEGATED = 'DELEGATED_GROUP',
    ENROUTE = 'ENROUTE_GROUP'
}
