import { Pipe, PipeTransform } from '@angular/core';
import { StatisticTypeEnum } from '../enums/statistics-type.enum';

@Pipe({
    name: 'statisticTypeLabel'
})
export class StatisticTypeLabelPipe implements PipeTransform {

    transform(type: StatisticTypeEnum): string {

        switch (type) {
            case StatisticTypeEnum.ORDERS_ENROUTE:
                return 'auf\ndem Weg';
            case StatisticTypeEnum.ORDERS_OPEN:
                return 'Offene\nBestellungen';
            case StatisticTypeEnum.ORDERS_OPEN_CANCELATIONS:
                return 'Offene Stronos';
            case StatisticTypeEnum.ORDERS_PENDING:
                return 'Bestellungen in Bearbeitung';
            case StatisticTypeEnum.SALES_VOLUME:
                return 'Gesamtumsatz in €';
            case StatisticTypeEnum.SALES_VOLUME_WITHOUT_HIGH_PRICED:
                return 'Gesamtumsatz ohne Hochpreiser';
            case StatisticTypeEnum.SALES_VOLUME_JUST_HIGH_PRICED:
                return 'Gesamtumsatz nur Hochpreiser';
            default:
                return '';
        }

    }
}
