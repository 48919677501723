<app-modal i18n-title title="Kontaktieren Sie das Kundenservice-Team der Sanacorp">
    <ng-container headerActions></ng-container>
    <ng-container content>
        <div class="form-container">
            <form [formGroup]="validationFormGroup" class="form">
                <ion-item class="background-input" *ngIf="!returnsId && !orderId && !isReadonly" appTooltip ttp="bottom" ttk="communication_zone_title">
                    <ion-input
                        i18n-label
                        type="text"
                        formControlName="title"
                        label="Frage"
                        class="required"
                        [ngClass]="{
                            'invalid': validationFormGroup.controls.title?.dirty | formInputIsInvalid : validationFormGroup.controls.title?.valid,
                        }"
                        [minlength]="(validationFieldsEnum.title | inputValidationMinLength:validators)"
                        [maxlength]="validationFieldsEnum.title | inputValidationMaxLength:validators"
                        [placeholder]="tooltips.communication_zone_title"></ion-input>
                    <ion-icon name="alert-circle"
                              color="primary"
                              *ngIf="validationFormGroup.controls.title?.dirty | formInputIsInvalid : validationFormGroup.controls.title?.valid"
                              appTooltip
                              [tt]="validationFormGroup.controls.title | formInputErrorMessage : validationFormGroup.controls.title.value"
                              ttp="right">
                    </ion-icon>
                </ion-item>
                <ion-item class="background-input" *ngIf="(returnsId || orderId || isReadonly) && title.length > 0" appTooltip ttp="bottom" ttk="seminar_suggestion_description">
                    <ion-input
                        i18n-label
                        type="text"
                        class="gray full-opacity"
                        formControlName="title"
                        label="Frage"
                        readonly
                        [placeholder]="tooltips.communicationZone_question_title_prefilled"
                    ></ion-input>
                </ion-item>
                <app-dropdown-input
                        label="Ansprechpartner" i18n-label
                        appTooltip ttp="bottom" ttk="communication_zone_contact_person"
                        formControlName="contactPerson"
                        placeholder="{{tooltips.communication_zone_contact_person}}"
                        inputClass="large"
                        isRequired="true"
                        [validationFieldEnum]="validationFieldsEnum.contactPerson"
                        [validators]="validators"
                        [items]="contactNames"
                        [isInvalid]="validationFormGroup.controls.contactPerson?.dirty | formInputIsInvalid : validationFormGroup.controls.contactPerson?.valid"
                        [tooltipInvalidValue]="validationFormGroup.controls.contactPerson | formInputErrorMessage : validationFormGroup.controls.contactPerson.value"
                ></app-dropdown-input>
                <div class="form-field-container zero-z-index">
                    <ion-item class="background-input wysiwyg" appTooltip ttp="bottom" ttk="communication_zone_description">
                            <ion-textarea i18n-label formControlName="content" class="auto-grow textarea-wrapper"
                                          class="required large"
                                          [ngClass]="{
                                            'invalid': validationFormGroup.controls.content?.dirty | formInputIsInvalid : validationFormGroup.controls.content?.valid,
                                          }"
                                          aria-label="Beschreibung" label="Beschreibung"
                                          [minlength]="(validationFieldsEnum.content | inputValidationMinLength:validators)"
                                          [maxlength]="(validationFieldsEnum.content | inputValidationMaxLength:validators) - this.contentPrefix.length"
                                          [placeholder]="tooltips.communication_zone_description"
                                          rows="10" auto-grow="true"><div class="preContent" *ngIf="contentPrefix.length > 0">{{contentPrefix}}</div>
                            </ion-textarea>
                            <ion-icon name="alert-circle"
                                      color="primary"
                                      *ngIf="validationFormGroup.controls.content?.dirty | formInputIsInvalid : validationFormGroup.controls.content?.valid"
                                      appTooltip
                                      [tt]="validationFormGroup.controls.content | formInputErrorMessage : validationFormGroup.controls.content.value"
                                      ttp="right">
                            </ion-icon>
                    </ion-item>
                    <div class="field-limit large" i18n>Noch {{(validationFieldsEnum.content | inputValidationMaxLength:validators) - this.contentPrefix.length - validationFormGroup.value.content.length}} Zeichen</div>
                </div>
            </form>
            <div class="required-fields"><span class="required">*</span> Benötigte Felder</div>
        </div>
    </ng-container>
    <!-- Footer -->
    <ng-container footer>
        <div>
            <ion-button
                    id="{{matomoId}}"
                    [class.disabled]="!validationFormGroup.valid"
                    appTooltip
                    ttk="{{!validationFormGroup.valid ? (returnsId > 0 || orderId > 0 ? 'communicationZone_invalid_order' : 'communicationZone_invalid') : null}}"
                    ttp="left"
                    (click)="validationFormGroup.valid ? onSubmitCommunicationZoneData(validationFormGroup.value) : null"
                    i18n
            >Kundenservice kontaktieren</ion-button>
        </div>
    </ng-container>
</app-modal>
