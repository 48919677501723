import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { QueryWrapper } from '../query.wrapper';
import { FetchPolicyKeys as FPK } from '../../../enums/fetch-policy-keys.enum';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { DataChangedKeys as DCK } from '../../../enums/data-changed-keys.enum';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { InfoModalInterface } from '../../../interfaces/info-modal.interface';
import { formatInfoModalData } from '../utils';
import { today } from '../../../formatting/date.formatting';

export const GetInfoModals = (queryName) => gql`
    # Write your query or mutation here
    query ${queryName} ($limit: Int, $filters: InfoModalFiltersInput) {
        infoModals (pagination: { limit: $limit },filters: $filters, sort:["releaseNote.version:desc"]) {
            data {
                id
                attributes {
                    releaseNote {
                        data {
                            attributes {
                                version
                            }
                        }
                    }
                    infoModalEntry {
                        id
                        title
                        content
                    }
                }
            }
        }
    }
`;

export const AllInfoModalsQueries = [
    GetInfoModals('test')
];

@Injectable({providedIn: 'root'})
export class InfoModalsQueries extends QueryWrapper {

    fetchPolicies = {
        [FPK.getInfoModals]: QueryFetchPolicy.NETWORK_ONLY
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar
    ) {
        super(apollo, dataChangedVar, {
            [DCK.infoModalChanged]: [FPK.getInfoModals]
        });
    }

    public getInfoModals(): Observable<InfoModalInterface[]> {
        const fetchPolicyKey = FPK.getInfoModals;
        return this.apollo.watchQuery({
                query: GetInfoModals(fetchPolicyKey),
                variables: {
                    limit: GraphQLLimits.infoModals,
                    filters: {
                        releaseNote: {
                            date: {
                                lte: today()
                            }
                        }
                    }
                },
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
            })
                .valueChanges
            .pipe(map(d => d?.data && d?.data['infoModals']))
            .pipe(map(infoModalData => formatInfoModalData(infoModalData)))
            .pipe(map(d => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;}));
    }
}