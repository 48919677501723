import { ChangeDetectionStrategy, Component, effect, EventEmitter, Input, OnInit, Output, Signal, signal, untracked } from '@angular/core';

@Component({
    selector: 'app-input-popover',
    templateUrl: './input-popover.component.html',
    styleUrls: ['./input-popover.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputPopoverComponent implements OnInit {
    /**
     * Data that is displayed in the dropdown. The data must be an array of strings.
     */
    @Input({required: true}) data: Signal<string[]>;
    /**
     * Term a user has already searched and filtered for. The filters are saved and must be displayed in the input field, if a previous search was
     * conducted.
     */
    @Input({required: true, alias: 'search'}) searchTerm: Signal<string>;
    /**
     * A function that transforms the data before it is displayed in the dropdown. The function receives the data as an array of strings.
     * Also, a revert functionality must be provided to remove the transformation before the selected item is emitted.
     */
    @Input() dataTransform: (data: string[], removeTransformation: boolean) => string[];
    /**
     * The label of the input field. If not provided, nothing is displayed.
     */
    @Input() label: string;
    /**
     * The placeholder of the input field. If not provided, nothing is displayed.
     */
    @Input() placeholder = '';
    /**
     * Max height of the dropdown displaying the search results. Default is 200px.
     */
    @Input() maxHeightDropdown = '200px';
    /**
     * Enable this option to show the search icon at the end of the input field. The icon will disappear when the user types into the input field.
     */
    @Input() searchIconEnd = false;
    /**
     * Emits an event, when the user typed into the search input field. The event is debounced by 300ms.
     */
    @Output() searchChange = new EventEmitter<string|null>();
    /**
     * Emits an event, when the user selected an item from the dropdown. All transformations are reverted before the event is emitted.
     */
    @Output() onItemSelected = new EventEmitter<string>();
    dataSignal = signal<string[]>([]);
    selectedItemSignal = signal<string>(null);
    showHasMore = signal(false);
    showSearchIcon = signal(true);
    isItemSelected = signal(false);
    inputStyleClass = signal('');
    selectedItem: string;

    constructor() {
        effect(() => {
            const data = this.data();
            untracked(() => {
                if (data?.length && this.dataTransform) {
                    this.dataSignal.set(this.dataTransform(data, false));
                } else {
                    this.dataSignal.set(data);
                }
            });
        });

        effect(() => {
            const search = this.searchTerm();
            untracked(() => {
                this.selectedItemSignal.set(search?.length ? search : null);
            });
        });

        effect(() => {
            this.selectedItem = this.selectedItemSignal();
            if (this.selectedItem?.length) {
                untracked(() => {
                    this.showSearchIcon.set(false);
                });
            }
        });

        effect(() => {
            const data = this.dataSignal();
            untracked(() => {
                this.showHasMore.set(data?.length > 99);
            });
        });
    }

    ngOnInit(): void {
        if (this.searchIconEnd) {
            this.inputStyleClass.update(prev => {
                return prev?.length ? `${prev} search-icon-end` : 'search-icon-end';
            });
        }
    }

    onSearch(event, delay: number = 0, isClear = false) {
        if (delay > 0) {
            // The empty message is shown before the transition animation for the closing dropdown is finished. For an empty search, when the
            // dropdown is closed, the empty message is shown for a split second. The delay prevents this.
            setTimeout(() => {
                this._onSearch(event?.query ?? null, isClear);
            }, delay);
        } else {
            this._onSearch(event?.query ?? null, isClear);
        }
        if (isClear) {
            this.isItemSelected.set(false);
            this.showSearchIcon.set(true);
        }
    }

    private _onSearch(value: string|null, isClear: boolean) {
        this.searchChange.emit(value);
        if (isClear) {
            this.searchChange.emit(null);
            this.onItemSelected.emit(null);
        }
    }

    onUserInput(event: string) {
        this.showSearchIcon.set(!event?.length);
    }

    onSelect(event: string) {
        const transformed = this.dataTransform ? this.dataTransform([event], true)[0] : event;
        this.onItemSelected.emit(transformed);
        this.selectedItemSignal.set(event);
        this.isItemSelected.set(true);
    }

    onDropdownVisibilityChange(isVisible: boolean) {
        if (!isVisible && !this.isItemSelected()) {
            this.showSearchIcon.set(true);
            this.selectedItemSignal.set(null);
            this.searchChange.emit(null);
            this.onItemSelected.emit(null);
        }
        this.inputStyleClass.update(prev => {
            let classes = prev;
            if (!isVisible) {
                classes = prev?.replace(' dropdown-visible', '');
            }
            if (isVisible && !prev?.includes('dropdown-visible')) {
                classes = prev?.length ? `${prev} dropdown-visible` : 'dropdown-visible';
            }
            return classes;
        });
    }
}
