import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_VERSION_KEY } from '../store/locals/versionState.var';
import { VersionType } from '../enums/version-type.enum';

@Injectable({
    providedIn: 'root',
})
export class VersionCheckService {

    constructor(private http: HttpClient) {}


    /**
     * Get current version from local storage
     *
     * @private
     */
    static getCurrentVersion() {
        const currentVersion = window.localStorage.getItem(APP_VERSION_KEY);
        return currentVersion && parseInt(currentVersion, 10) ? parseInt(currentVersion, 10) : 0;
    }

    check(): Promise<{
        latestVersion: number,
        latestVersionType: VersionType,
        isNewVersionAvailable: boolean,
        forceReload: boolean,
    }> {
        return new Promise(resolve => { try {
            this.http.get(window.location.origin + '/version.json?t=' + new Date().getTime())
              .subscribe({
                  next: async (response: any) => {
                const currentVersion = VersionCheckService.getCurrentVersion();
                let latestVersion = 0;
                let latestVersionType = null;

                // Detect latest version and its type
                for (const type in VersionType) {
                    if (type && response[type]) {
                        latestVersionType = latestVersion >= response[type] ? latestVersion : type;
                        latestVersion = latestVersion >= response[type] ? latestVersion : response[type];
                    }
                }

                // Cancel check, if there is no version
                if (latestVersion === 0) {
                    resolve({
                        latestVersion: 0,
                        latestVersionType: VersionType.systemInformation,
                        isNewVersionAvailable: false,
                        forceReload: false
                    });
                    return;
                }

                // Set version, if there is no current version
                if (currentVersion === 0) {
                    resolve({
                        latestVersion,
                        latestVersionType: VersionType.systemInformation,
                        isNewVersionAvailable: false,
                        forceReload: false
                    });
                    return;
                }

                // Return latest version and if its a new version
                resolve({
                    latestVersion,
                    latestVersionType,
                    isNewVersionAvailable: currentVersion !== latestVersion,
                    forceReload: response[VersionType.logoutReload] > currentVersion
                });
                },
                error:   () => {
                    resolve(null);
                }
          });
        } catch (e) {
            // Do nothing
            resolve(null);
        }});
    }


}
