import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'formatPercent',
})
export class FormatPercentPipe implements PipeTransform {
    transform(
        value: number,
        minimumFractionDigits = 2,
        locale = 'de'
    ): string | null {
        if(!value) {
            return '0 %';
        }
        return Intl.NumberFormat(locale, {minimumFractionDigits}).format((Math.round(value * 100) / 100)) + ' %';
    }
}
