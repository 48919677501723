<app-modal class="search-modal" i18n-title title="Aufträge filtern" >
    <ng-container headerActions></ng-container>
    <ng-container content>
        <ion-grid class="search-filters">
            <ion-row>
                <ion-col size="16" class="ion-padding">
                    <ion-searchbar #searchInput
                                   mode="md"
                                   name="search"
                                   ngDefaultControl
                                   [(ngModel)]="search"
                                   (keyup.enter)="applyFilterAndSearch()"
                                   class="ion-no-padding ion-margin-bottom"
                                   searchIcon="search-outline"
                                   clearIcon="close-outline"
                                   i18n-cancelButtonText
                                   cancelButtonText="Abbrechen"
                                   i18n-placeholder placeholder="Suche (PZN, Hersteller, Name ...) "
                                   autocomplete="off"
                                   autocorrect="off"></ion-searchbar>

                    <ion-grid class="ion-no-padding filter-wrapper">
                        <ion-row class="ion-padding-bottom">
                            <ion-col size="8">
                                <app-date-selection-widget title="Bestelldatum"
                                                           [dateRangeOptions]="recTimeRangeOptions"
                                                           [selectedDateRangeId]="defaultRecTimeRangeOption"
                                                           [defaultFromDate]="defaultRecTimeFromDate"
                                                           [defaultToDate]="defaultRecTimeToDate"
                                                           [displayOnlyFutureDates]="false"
                                                           [displayOnlyPastDates]="true"
                                                           [displayIncludingToday]="true"
                                                           (datesEvent)="updateDateFilter($event, false)"
                                ></app-date-selection-widget>
                            </ion-col>
                            <ion-col size="8">
                                <app-date-selection-widget title="Ablaufdatum"
                                                           [dateRangeOptions]="expiryDateRangeOptions"
                                                           [selectedDateRangeId]="defaultExpiryDateRangeOption"
                                                           [defaultFromDate]="defaultExpiryFromDate"
                                                           [defaultToDate]="defaultExpiryToDate"
                                                           [displayOnlyFutureDates]="false"
                                                           [displayOnlyPastDates]="false"
                                                           [displayIncludingToday]="true"
                                                           (datesEvent)="updateDateFilter($event, true)"
                                ></app-date-selection-widget>
                            </ion-col>

                            <ion-col size="8" class="ion-padding-start">
                                <ion-text i18n class="filter-label">Typ</ion-text>
                                <ion-text class="filter-text" (click)="presentDeliveryTypePopover($event)">{{selectedDeliveryType.title}} <ion-icon name="caret-down-sharp"></ion-icon></ion-text>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col size="8">
                                <ion-text i18n class="filter-label">Status</ion-text>
                                <ion-text class="filter-text" (click)="presentDeliveryStatusPopover($event)">{{statusFilterConfig | filterResultValue: filters[statusFilterConfig.id]}} <ion-icon name="caret-down-sharp"></ion-icon></ion-text>
                            </ion-col>
                            <ion-col size="8" class="ion-padding-right-big">
                                <app-input-popover [data]="pznList"
                                                   [search]="pznSearch"
                                                   placeholder="Suche nach PZN"
                                                   maxHeightDropdown="250px"
                                                   [label]="'PZN'" i18n-label
                                                   (onItemSelected)="onPopoverItemSelect($event, filterFields.pzn)"
                                                   (searchChange)="popoverSearch($event, filterFields.pzn)"
                                />
                            </ion-col>
                            <ion-col size="8" class="ion-padding-end">
                                <app-input-popover [data]="producersList"
                                                   [search]="producerSearch"
                                                   placeholder="Suche nach Hersteller"
                                                   maxHeightDropdown="250px"
                                                   [label]="'Hersteller'" i18n-label
                                                   (onItemSelected)="onPopoverItemSelect($event, filterFields.producer)"
                                                   (searchChange)="popoverSearch($event, filterFields.producer)"
                                />
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-col>
                <ion-col size="8" class="last-searches-wrapper ion-no-padding">
                    <app-order-search-history (adoptSearch)="onAdoptSearch($event)"></app-order-search-history>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ng-container>
    <ng-container footer>
        <div class="display-flex ion-justify-content-between">
            <ion-button (click)="clearFilterAndSearch()" class="button-secondary" i18n>Filter entfernen</ion-button>
            <ion-button (click)="applyFilterAndSearch()" i18n>Suche und Filter anwenden</ion-button>
        </div>
    </ng-container>
</app-modal>
