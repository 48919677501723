<div class="container">
    <h1>Frohe Ostern!</h1>
    <div class="bunny-icon">
        <div class="egg"></div>
        <div class="bunny">
            <div class="head">
                <div class="ears">
                    <div class="ear ear-left"></div>
                    <div class="ear ear-right"></div>
                </div>
                <div class="face">
                    <div class="eyes"></div>
                    <div class="nose"></div>
                    <div class="mouth"></div>
                    <div class="blush"></div>
                </div>
            </div>
            <div class="paws"></div>
        </div>
    </div>
</div>
