import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { from, map } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { SendSurveyInterface } from '../../../interfaces/survey.interface';

export const UpsertSurveyAnswers = gql`
    mutation sendSurvey($sendSurveyId: Int!, $selectedAnswers: [SelectedSurveyAnswer!]!) {
        SendSurvey(id: $sendSurveyId, selectedAnswers: $selectedAnswers) {
            status
            message
        }
    }
`;
export const AllSurveyMutations = [
    UpsertSurveyAnswers
];

@Injectable()
export class SurveyMutations {
    constructor(
        private apollo: Apollo,
        private apiService: ApiService,
    ) {}

    submitSurvey(surveyAnswers: SendSurveyInterface) {
        this.apollo.mutate( {
            mutation: UpsertSurveyAnswers,
            variables: {
                sendSurveyId: surveyAnswers.sendSurveyId,
                selectedAnswers: surveyAnswers.selectedAnswers
            }
        }).subscribe({
            next: result => {
                from([result])
                    .pipe(map(d => d?.data && d?.data['SendSurvey'] && d?.data['SendSurvey']['status']))
                    .subscribe((status: string) => {
                        if (!status || status === 'ERROR' || !!result['errors']) {
                            void this.apiService.presentErrorToast(
                                result['errors'],
                                'Die Umfrage konnte nicht übermittelt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.'
                            );
                        } else {
                            void this.apiService.presentSuccessToastNoDuration('Vielen Dank für Ihre Teilnahme an der Umfrage.');
                        }
                    }).unsubscribe();
            },
            error: error => {
                void this.apiService.presentErrorToast(error, 'Die Umfrage konnte nicht übermittelt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.');
            }
        });
    }

}
