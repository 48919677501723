import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { QuantityValidator } from './qty.validator';
import { ReturnGoodTypesEnum } from '../enums/returns.enum';
import { dateDifferenceToToday, today } from '../formatting/date.formatting';

export function ReturnRequestContactNameValidator() {
    return [
        Validators.required,
        Validators.maxLength(50)
    ];
}

export function ReturnRequestQtyValidator() {
    return [
        Validators.required,
        ...QuantityValidator(true, 9999)
    ];
}

export function ReturnRequestPZNValidator() {
    return [
        Validators.required,
        Validators.maxLength(8)
    ];
}

export function ReturnRequestExpirationValidator() {
    return [
        Validators.required,
        Validators.maxLength(10)
    ];
}

export function ReturnRequestReasonValidator() {
    return [
        Validators.required,
        Validators.maxLength(20)
    ];
}

export function ReturnRequestDeliveryNrValidator() {
    return [
        Validators.required,
        Validators.maxLength(10)
    ];
}

export function ReturnRequestDeliveryDateValidator(returnGoodType: string) {
    const validators = [
        Validators.required,
        Validators.maxLength(10),
        isDateInFuture(),
    ];
    if (returnGoodType === ReturnGoodTypesEnum.btm || returnGoodType === ReturnGoodTypesEnum.coolingGoods) {
        validators.push(dateInThresholdWeeks(6));
    }

    return validators;
}

function isDateInFuture(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
        const isGreaterThanToday = control.value > today();
        return isGreaterThanToday ? {dateInFuture: {value: control.value}} : null;
    };
}

function dateInThresholdWeeks(threshold: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
        const dayDifference = dateDifferenceToToday(control.value);
        const isGreaterThanWeeks = dayDifference > (threshold * 7);
        return isGreaterThanWeeks ? {dateBeforeThreshold: {difference: dayDifference, thresholdWeeks: threshold}} : null;
    };
}
