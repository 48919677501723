import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LogoutType } from '../../../../essentials/types/src/logoutType';
import { CustomToastController } from '../../../ui-components/src/ionic/controllers/custom-toast.controller';

@Injectable({
  providedIn: 'root',
})
export class LogoutNotificationService {
  constructor(
    private toastController: CustomToastController,
    private translateService: TranslateService
  ) {}
  public async showLogoutToast(logoutType: LogoutType): Promise<void> {
    const message = this.getMessageForLogoutType(logoutType);
    if (message) {
      await this.toastController.createAndPresentToast({ message });
    }
  }

  private getMessageForLogoutType(logoutType: LogoutType): string | undefined {
    const partialTranslateKey = this.getPartialTranslateKeyForLogoutType(logoutType);
    if (!partialTranslateKey) {
      return;
    }
    const translateKey = 'LOGOUT.' + partialTranslateKey;
    const translation = this.translateService.instant(translateKey);
    return translation === translateKey ? '' : translation;
  }

  private getPartialTranslateKeyForLogoutType = (logoutType: LogoutType): string | undefined => {
    switch (logoutType) {
      case LogoutType.ActiveDeleted:
        return 'ACTIVE_DELETED';
      case LogoutType.ActiveLogout:
        return 'ACTIVE_LOGOUT';
      case LogoutType.BackendDeleted:
        return 'BACKEND_DELETED';
      case LogoutType.Expired:
        return 'EXPIRED';
      case LogoutType.InconsistentWithBackend:
        return 'INCONSISTENT_WITH_BACKEND';
      case LogoutType.NoPrivateKey:
        return 'NO_PRIVATE_KEY';
      case LogoutType.BackendDisabledPharmacy:
        return 'DISABLED';
      case LogoutType.SignInFailed:
        return 'SIGN_IN_FAILED';
      case LogoutType.SilentExpired:
        return 'SILENT_EXPIRED';
      case LogoutType.SsoSessionExpired:
        return 'SSO_SESSION_EXPIRED';
    }

    return undefined;
  };
}
