import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { TOOLTIPS } from '../config/tooltip-texts.config';
import { TooltipColorSchemeEnum } from '../enums/tooltip-color-scheme.enum';

/**
 * Tooltip Directive
 *
 * Parameters:
 * tt  - Tooltip Title: The text to show
 * ttk - Tooltip Key: The key to use the predefined texts (overrides tt-parameter)
 * ttp - (Optional) Position of the tooltip (top, left, right, bottom), default is set to bottom
 * ttd - (Optional) Delay until the tooltip becomes visible or invisible, default is set to immediately
 * ttColor - (Optional) Define a color scheme that is used (red, default), default is set to dark background and white font
 *
 * Usage:
 * <ion-button ... appTooltip tt="Ihr Referent" ttk='seminar_is_locked' ttp="left" ttd="500" >
 */
@Directive({
    selector: '[appTooltip]'
})
export class TooltipDirective {
    // tslint:disable-next-line:no-input-rename
    @Input('tt') tooltipText: string|object;
    // tslint:disable-next-line:no-input-rename
    @Input('ttk') tooltipKey: string;
    // tslint:disable-next-line:no-input-rename
    @Input('ttkFb') tooltipKeyFallback: string;
    // tslint:disable-next-line:no-input-rename
    @Input('ttp') placement = 'bottom';
    // tslint:disable-next-line:no-input-rename
    @Input('ttd') delay = 0;
    // tslint:disable-next-line:no-input-rename
    @Input('ttColor') colorScheme = TooltipColorSchemeEnum.DEFAULT;

    tooltip: HTMLElement;
    offset = 10;
    ttCheckInterval = null;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2
    ) { }

    @HostListener('mouseover') onMouseOver() {
        const tooltipObj = this.tooltipKey && TOOLTIPS[this.tooltipKey]
            ? TOOLTIPS[this.tooltipKey]
            : (this.tooltipKeyFallback && TOOLTIPS[this.tooltipKeyFallback]
                ? TOOLTIPS[this.tooltipKeyFallback]
                : this.tooltipText
            );
        if (!this.tooltip && tooltipObj) {
            this.show(tooltipObj);
        } else if (this.tooltip) { this.hide(); }
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this.tooltip) { this.hide(); }
    }
    @HostListener('click') onMouseClick() {
        // needed if invalid for is validated on click (e.g. trim onBlur)
        const tooltipObj = this.tooltipKey && TOOLTIPS[this.tooltipKey]
            ? TOOLTIPS[this.tooltipKey]
            : (this.tooltipKeyFallback && TOOLTIPS[this.tooltipKeyFallback]
                    ? TOOLTIPS[this.tooltipKeyFallback]
                    : this.tooltipText
            );
        if (this.tooltip && (!tooltipObj)) { this.hide(); }
    }
    @HostListener('window:keyup.Escape', ['$event'])
    keyEvent() {
        // needed for closing the modal by escape key
        if (this.tooltip) {
            this.hide();
        }
    }
    @HostListener('window:keydown.Enter', ['$event'])
    @HostListener('window:keydown.Space', ['$event'])
    keyEventDown(event: KeyboardEvent) {
        // needed for form whitespace validation (e.g. if tab was pressed)
        if (this.tooltip && event.code !== 'Enter' && event.code !== 'Space') {
            this.hide();
        }
    }

    show(tooltipObj) {
        const newCreated = this.create(tooltipObj);
        if(newCreated) {
            this.setPosition();
        }
        this.renderer.addClass(this.tooltip, 'tooltip-show');

        if(this.ttCheckInterval) {
            clearInterval(this.ttCheckInterval);
        }

        /*
        * Check every second if the mouse is still on this element
        * If not, close the tooltip
        * Notice: Mouseleave is required, otherwise the tooltip might close even
        * if the user hovers the next element.
        */
        this.ttCheckInterval = setInterval(() => {
            if(!this.el.nativeElement.matches(':hover')) {
                this.hide();
                clearInterval(this.ttCheckInterval);
            }
        }, 1000);
    }

    hide() {
        if(this.ttCheckInterval) {
            clearInterval(this.ttCheckInterval);
        }
        if(!this.tooltip) {
            return;
        }
        this.renderer.removeClass(this.tooltip, 'tooltip-show');
        window.setTimeout(() => {
            if (this.tooltip) {
                this.renderer.removeChild(document.body, this.tooltip);
                this.tooltip = null;
            }
        }, this.delay);
    }

    /**
     * Create the tooltip html element
     *
     * @param tooltipObj - The tooltip object
     */
    create(tooltipObj) {
        this.tooltip = document.getElementById('tooltip');
        if(!this.tooltip) {
            this.tooltip = this.renderer.createElement('span');
            this.tooltip.id = 'tooltip';
        }

        // Prevent override of existing tooltip
        if(this.tooltip.classList.contains('tooltip-show')) {
            return false;
        }


        if(typeof tooltipObj === 'string') {
            // Render a simple text tooltip
            this.renderer.appendChild(this.tooltip, this.renderer.createText(tooltipObj));

        } else if(tooltipObj.list) {
            // Render a list tooltip
            const ul = this.renderer.createElement('ul');
            tooltipObj.list.forEach(item => {
                const li = this.renderer.createElement('li');
                this.renderer.appendChild(li, this.renderer.createText(item));
                this.renderer.appendChild(ul, li);
            });

            this.renderer.appendChild(this.tooltip,this.renderer.createText(tooltipObj.title));
            this.renderer.appendChild(this.tooltip, ul);
        } else if (typeof tooltipObj === 'object' && tooltipObj.length > 0) {
            // Render styling in tooltips
            tooltipObj.forEach((obj)=>{
                Object.keys(obj).forEach(key => {
                    switch (key) {
                        case 'underline':
                            const u = this.renderer.createElement('u');
                            this.renderer.appendChild(u, this.renderer.createText(obj[key]));
                            this.renderer.appendChild(this.tooltip, u);
                            break;
                        case 'normal':
                        default:
                            const childSpan = this.renderer.createElement('span');
                            this.renderer.appendChild(childSpan, this.renderer.createText(obj[key]));
                            this.renderer.appendChild(this.tooltip, childSpan);
                            break;

                    }
                });
            });
        }

        this.renderer.appendChild(document.body, this.tooltip);

        this.renderer.addClass(this.tooltip, 'tooltip');
        this.renderer.addClass(this.tooltip, `tooltip-${this.placement}`);
        if (this.colorScheme === TooltipColorSchemeEnum.RED) {
            this.renderer.addClass(this.tooltip, 'tooltip-red');
        }

        this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
        this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`);
        this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`);
        this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);

        return true;
    }

    setPosition() {
        const hostPos = this.el.nativeElement.getBoundingClientRect();
        const tooltipPos = this.tooltip.getBoundingClientRect();
        const scrollPos = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

        let top, left;

        if (this.placement === 'top') {
            top = hostPos.top - tooltipPos.height - this.offset;
            left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
        }

        if (this.placement === 'bottom') {
            top = hostPos.bottom + this.offset;
            left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
        }

        if (this.placement === 'left') {
            top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
            left = hostPos.left - tooltipPos.width - this.offset;
        }

        if (this.placement === 'right') {
            top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
            left = hostPos.right + this.offset;
        }

        // first move to the bottom, then to the left
        // ("left bottom" should be handled separate)
        if (this.placement === 'bottom-left') {
            top = hostPos.bottom + this.offset;
            left = hostPos.left;
        }

        this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
        this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
    }
}
