import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

    transform(value: Array<any>, key: string = '', reverse: boolean = false): Array<any> {

        // Sort by value if no key is provided
        if(key === '') {
            return [...value].sort((a,b) => {
                if(a > b) {
                    return reverse ? 1 : -1;
                }
                return reverse ? -1 : 1;
            });
        }

        return [...value].sort((a,b) => {
             if(a[key] > b[key]) {
                 return reverse ? 1 : -1;
             }
             return reverse ? -1 : 1;
        });

    }

}

