/**
 * ATTENTION: Add the enum in the specific enum like UserDataChangedKey
 * AND in the general enum DataChangedKey
 */

export enum UserDataChangedKeys {
    appointmentChanged = 'appointmentChanged',
    documentSearchChanged = 'documentSearchChanged',
    orderFilterChanged = 'orderFilterChanged',
    documentDeliveriesFilterChanged = 'documentDeliveriesFilterChanged',
    documentInvoicesFilterChanged = 'documentInvoicesFilterChanged',
    returnsFilterChanged = 'returnsFilterChanged',
    readNotificationChanged = 'readNotificationChanged',
    offerQuoteChanged = 'offerQuoteChanged',
    settingsCalendarSanacorpEventsChanged = 'settingsCalendarSanacorpEventsChanged',
    settingsNotificationChanged = 'settingsNotificationChanged',
    settingsSecurityChanged = 'settingsSecurityChanged',
    userSettingsChanged = 'userSettingsChanged',
    sortingChanged = 'sortingChanged',
    userStorageChanged = 'userStorageChanged',
    surveyUserChanged = 'surveyUserChanged',
}

export enum PharmacyUserDataChangedKeys {
    formResultChanged = 'formResultChanged',
}

export enum PharmacyUserDataChangedCountKeys {
    notificationReadNotArchivedCount = 'notificationReadNotArchivedCount'
}

export enum PharmacyDataChangedKeys {
    communicationZoneChanged = 'communicationZoneChanged',
    documentDeliveriesChanged = 'documentDeliveriesChanged',
    documentInvoicesChanged = 'documentInvoicesChanged',
    noteChanged = 'noteChanged',
    notificationChanged = 'notificationChanged',
    ordersChanged = 'ordersChanged',
    returnsChanged = 'returnsChanged',
    pharmacyStoreSettingsChanged = 'pharmacyStoreSettingsChanged',
    businessFigureChanged = 'businessFigureChanged',
    businessFigureLoadingStatusChanged = 'businessFigureLoadingStatusChanged',
    businessFigureDuettChanged = 'businessFigureDuettChanged',
    businessFigureSubscriptionsChanged = 'businessFigureSubscriptionsChanged',
    producerChanged = 'producerChanged',
    safetyDataSheetsChanged = 'safetyDataSheetsChanged'
}

export enum PharmacyDataChangedCountKeys {
    notificationNotArchivedCount = 'notificationNotArchivedCount',
}

export enum UserDataChangedCountKeys {
    showReleaseNoteHint = 'showReleaseNoteHint'
}

export enum GlobalDataChangedKeys {
    meamindChanged = 'meamindChanged'
}
export enum StrapiDataChangedKeys  {
    newsPostChanged = 'newsPostChanged',
    formChanged = 'formChanged',
    offerChanged = 'offerChanged',
    offerProductChanged = 'offerProductChanged',
    bannerDataChanged = 'bannerDataChanged',
    downloadsChanged = 'downloadsChanged',
    downloadTypeChanged = 'downloadTypeChanged',
    infoModalChanged = 'infoModalChanged',
    surveyChanged = 'surveyChanged',
    educationChanged = 'educationChanged',
    sanacorpAppointmentChanged = 'sanacorpAppointmentChanged',
}
export enum DataChangedKeys {
    bannerDataChanged = 'bannerDataChanged',
    businessFigureDuettChanged = 'businessFigureDuettChanged',
    businessFigureSubscriptionsChanged = 'businessFigureSubscriptionsChanged',
    orderFilterChanged = 'orderFilterChanged',
    documentDeliveriesFilterChanged = 'documentDeliveriesFilterChanged',
    documentInvoicesFilterChanged = 'documentInvoicesFilterChanged',
    returnsFilterChanged = 'returnsFilterChanged',
    pharmacyStoreSettingsChanged = 'pharmacyStoreSettingsChanged',
    settingsCalendarSanacorpEventsChanged = 'settingsCalendarSanacorpEventsChanged',
    settingsNotificationChanged = 'settingsNotificationChanged',
    settingsSecurityChanged = 'settingsSecurityChanged',
    userSettingsChanged = 'userSettingsChanged',
    appointmentChanged = 'appointmentChanged',
    privateAppointmentChanged = 'privateAppointmentChanged',
    communicationZoneChanged = 'communicationZoneChanged',
    documentDeliveriesChanged = 'documentDeliveriesChanged',
    documentInvoicesChanged = 'documentInvoicesChanged',
    documentSearchChanged = 'documentSearchChanged',
    meamindChanged = 'meamindChanged',
    newsPostChanged = 'newsPostChanged',
    formChanged = 'formChanged',
    formResultChanged = 'formResultChanged',
    noteChanged = 'noteChanged',
    notificationChanged = 'notificationChanged',
    readNotificationChanged = 'readNotificationChanged',
    offerChanged = 'offerChanged',
    offerProductChanged = 'offerProductChanged',
    offerQuoteChanged = 'offerQuoteChanged',
    ordersChanged = 'ordersChanged',
    returnsChanged = 'returnsChanged',
    settingsChanged = 'settingsChanged',
    userStorageChanged = 'userStorageChanged',
    sortingChanged = 'sortingChanged',
    businessFigureChanged = 'businessFigureChanged',
    businessFigureLoadingStatusChanged = 'businessFigureLoadingStatusChanged',
    downloadsChanged = 'downloadsChanged',
    downloadTypeChanged = 'downloadTypeChanged',
    infoModalChanged = 'infoModalChanged',
    surveyChanged = 'surveyChanged',
    surveyUserChanged = 'surveyUserChanged',
    educationChanged = 'educationChanged',
    sanacorpAppointmentChanged = 'sanacorpAppointmentChanged',
    producerChanged = 'producerChanged',
    dynamicPageChanged = 'dynamicPageChanged',
    safetyDataSheetsChanged = 'safetyDataSheetsChanged',
}

export enum DataChangedCountKeys {
    notificationNotArchivedCount = 'notificationNotArchivedCount',
    notificationReadNotArchivedCount = 'notificationReadNotArchivedCount',
    showReleaseNoteHint = 'showReleaseNoteHint'
}


