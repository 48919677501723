import gql from 'graphql-tag';

export default gql`
  subscription updatedChatPartnerMetadataForEnduser($chatPartnerId: ID!) {
    updatedChatPartnerMetadataForEnduser(chatPartnerId: $chatPartnerId) {
      __typename
      cognitoId
      chatPartnerId
      isSuspended
    }
  }
`;
