import { inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { NotificationReminderInterface } from '../../interfaces/notification.interface';

const GetNotificationReminders = gql`
    query GetNotificationReminders {
        notificationReminders @client {
            id
            type
            minutesBefore
            reminder
            data {
                id
                title
                dateTimeFrom
                dateTimeTo
            }
        }
    }
`;
@Injectable({
    providedIn: 'root',
})
export class NotificationRemindersVar {
    private apollo = inject(Apollo);

    private notificationRemindersObservable: Observable<NotificationReminderInterface[]>;
    private cache;
    private defaultValue = [];


    init() {
        if(this.apollo.client) {
            this.cache = this.apollo.client.cache;
            this.notificationRemindersObservable = new Observable<NotificationReminderInterface[]>(subscriber => {
                this.cache.watch({
                    query: GetNotificationReminders,
                    callback: res => {
                        subscriber.next(res.result && res.result.notificationReminders || this.defaultValue);
                    },
                    immediate: true,
                    optimistic: true
                });
            });
        }
    }

    get(): Observable<NotificationReminderInterface[]> {
        this.init();
        return this.notificationRemindersObservable;
    }

    set(notificationReminders: NotificationReminderInterface[]) {
        this.init();
        if (this.cache) {
            this.cache.writeQuery({
                query: GetNotificationReminders,
                data: {
                    notificationReminders
                }
            });
        }
    }
}
