import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { from, map } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { OrderBulkTypeForApi } from '../../../enums/bulk-action.enum';
import { DataChangedForceStateVar } from '../../locals/dataChangeForceState.var';
import { DataChangedKeys as DCK } from '../../../core.enums';

export const OrderBulkChanges = gql`
    mutation OrderBulkChanges($orderIds: [ID!]!, $type: OrderBulkType!, $cancellationReason: String, $extendByWeeks: Int) {
        OrderBulkChanges(orderIds: $orderIds, type: $type, cancellationReason: $cancellationReason, extendByWeeks: $extendByWeeks) {
            status
            code
            message
            failedItemIds
        }
    }
`;

export const AllBulkChangesMutations = [
    OrderBulkChanges
];

@Injectable()
export class BulkChangesMutations {

    constructor(
        private apollo: Apollo,
        private apiService: ApiService,
        private dataChangedForceState: DataChangedForceStateVar
    ) {}

    orderBulkChanges(
        orderIds: Array<number|string>,
        type: OrderBulkTypeForApi,
        cancellationReason: string = null,
        extendByWeeks: number = null): Promise<Array<string|number>> {
        void this.dataChangedForceState.setForceState({[DCK.ordersChanged]: null});
        return new Promise( (resolve, reject) => {
            this.apollo.mutate({
                mutation: OrderBulkChanges,
                variables: {
                    orderIds,
                    type,
                    cancellationReason,
                    extendByWeeks
                }
            }).subscribe({
                next: result => {
                    from([result])
                        .pipe(map(d => d?.data['OrderBulkChanges'] && d.data['OrderBulkChanges']))
                        .subscribe((response) => {
                            if (!response['status'] || response['status'] === 'ERROR' || !!result['errors']) {
                                if (response['code'] === 100) {
                                    void this.apiService.presentErrorToast(result['errors'], 'Einige Änderungen können nicht vorgenommen werden. Bitte entfernen Sie die markierten Aufträge aus der Liste.');
                                    resolve(response['failedItemIds']);
                                } else {
                                    void this.apiService.presentErrorToast(result['errors'], 'Die Änderung konnte nicht gespeichert werden.');
                                    reject();
                                }
                            } else {
                                void this.apiService.presentSuccessToast('Änderungen wurden erfolgreich beantragt.');
                                resolve(null);
                            }
                        });
                },
                error: error => this.apiService.presentErrorToast(error, 'Die Änderung konnte nicht gespeichert werden.')
            });
        });
    }
}
