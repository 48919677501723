import { Component, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ModalClassEnum } from '../../enums/modal-class.enum';
import { BulkActionCodeEnum, BulkActionTypeEnum, OrderBulkTypeForApi } from '../../enums/bulk-action.enum';
import { OrderBulkActionConfig } from '../../config/order-bulk-action.config';
import { ToastService } from '../../services/toast.service';
import { BulkChangesMutations } from '../../store/graphql/mutations/bulk-changes.graphql';
import { FieldLengths } from '../../config/input-validation.config';
import { FieldLengthEnum } from '../../enums/input-validation.enum';
import { matomoIdsEnum } from '../../enums/matomo-ids.enum';
import { BulkChangesInterface } from '../../interfaces/bulk-changes.interface';
import { OrderDetailInterface } from '../../interfaces/order.interface';
import { ReturnsDetailInterface  } from '../../interfaces/returns.interface';
import { BulkModeVar } from '../../store/locals/bulk-mode.var';
import { maxExtensionDays } from '../../services/order.service';

@Component({
    selector: 'app-bulk-action-modal',
    templateUrl: './bulk-action-modal.component.html',
    styleUrls: ['./bulk-action-modal.component.scss'],
})
export class BulkActionModalComponent {
    // Adds class to modal
    static modalClass = ModalClassEnum.large;

    @Input() bulkType: BulkActionTypeEnum;
    @Input() items: OrderDetailInterface[] | ReturnsDetailInterface[];
    @Input() bulkAction: BulkActionCodeEnum;

    title: string;
    subtitle: string;
    bulkItems: Array<BulkChangesInterface> = [];
    ctaButtonLabel: string;
    bulkAlertTooltip: string;

    itemHasError : boolean;

    constructor(
        private alertController: AlertController,
        private bulkChangesMutations: BulkChangesMutations,
        private bulkModeVar: BulkModeVar,
        private modalController: ModalController,
        private toastService: ToastService,
    ) {
    }

    ionViewDidEnter() {
        this.setTitle();
        this.getBulkItems();
    }

    setTitle() {
        const orderActionConfig = OrderBulkActionConfig.find(action => action.code === this.bulkAction);
        if (orderActionConfig) {
            this.ctaButtonLabel = orderActionConfig.label;
            this.title = orderActionConfig.title;
            this.bulkAlertTooltip = orderActionConfig.tooltipKey;
        }
    }

    getBulkItems() {
        switch (this.bulkType) {
            case BulkActionTypeEnum.ORDERS:
                this.bulkItems = (this.items as OrderDetailInterface[]).map(order => ({
                    id: order.id,
                    type: order.type,
                    orderId: order.orderId,
                    productName: order.productName,
                    packageSize: order.packageSize,
                    pzn: order.pzn,
                    expiryDate: order.expiryDate
                }));
                this.setSubtitle();
                break;
        }
    }

    removeItem(itemId: number) {
        this.bulkItems.splice(this.bulkItems.findIndex(item => item.id === itemId), 1);
        this.itemHasError = this.bulkItems.some(item => item.hasError);
        this.setSubtitle();
    }

    async submitBulkChanges() {
        if (this.bulkItems.length === 0 || this.bulkItems.some(item => item.hasError)) {
            return;
        }
        const bulkItemIds = this.bulkItems.map(item => item.id);
        switch (this.bulkAction) {
            case BulkActionCodeEnum.CANCEL:
                await this.cancelOrder(bulkItemIds);
                break;
            case BulkActionCodeEnum.EXTEND:
                await this.extendOrder(bulkItemIds);
                break;
            case BulkActionCodeEnum.DISPO_ACKNOWLEDGE:
                await this.arrivedOrder(bulkItemIds);
                break;
        }
    }

    async closeModal(isSuccess = true) {
        await this.modalController.dismiss();
        if (isSuccess) {
            this.bulkModeVar.setActive(false);
        }
    }

    private setSubtitle() {
        this.subtitle = `${this.bulkItems?.length || 0} ${this.bulkItems?.length === 1 ? 'Auftrag' : 'Aufträge'} ausgewählt`;
    }

    private async cancelOrder(bulkItemIds: Array<number>) {
        const cancelAlert = await this.alertController.create({
            header: 'Stornierung von Bestellungen',
            cssClass: 'medium',
            message: 'Möchten Sie die ausgewählten Bestellungen wirklich stornieren?',
            inputs: [{
                name: 'reason',
                type: 'text',
                placeholder: 'Stornierungsgrund',
                attributes: {
                    maxlength: FieldLengths[FieldLengthEnum.cancelOrder]
                }
            }],
            buttons: [{
                text: 'Abbrechen',
                handler: () => {}
            }, {
                text: 'OK',
                cssClass: matomoIdsEnum.orderCancelCssClass,
                handler: data => {
                    if (data.reason.length > 1 && data.reason.length <= FieldLengths[FieldLengthEnum.cancelOrder]) {
                        this.bulkChangesMutations.orderBulkChanges(
                            bulkItemIds,
                            OrderBulkTypeForApi.CANCEL_ORDER,
                            data.reason
                        ).then(failedIds => {
                                if (failedIds) {
                                    this.bulkItems.forEach(item => {
                                        if (failedIds.includes(item.id.toString())) {
                                            item.hasError = true;
                                        }
                                    });
                                } else {
                                    this.closeModal();
                                    return true;
                                }
                            });
                        return true;
                    }
                    this.toastService.presentWarning(
                        data.reason.length <= 1 ?
                            'Bitte tragen Sie einen Stornierungsgrund ein.' :
                            'Maximale Nachrichtenlänge von ' + FieldLengths[FieldLengthEnum.cancelOrder] + ' Zeichen überschritten: ' +
                            data.reason.length + ' Zeichen. Ein Versenden ist bei >' + FieldLengths[FieldLengthEnum.cancelOrder] +
                            ' Zeichen nicht möglich'
                    );
                    return false;
                }
            }]
        });
        await cancelAlert.present();
    }

    private async extendOrder(bulkItemIds: Array<number>) {
        const inputFields = [];
        for (let i = 1; i <= 4; i++) {
            inputFields.push({
                type: 'radio',
                value: i,
                label: i +' Wochen'
            });
        }
        const extendAlert = await this.alertController.create({
            header: 'Aufträge verlängern',
            message: `Bitte wählen Sie den Verlängerungszeitraum aus: (nur ${maxExtensionDays} Tage verlängerbar):`,
            inputs: inputFields,
            buttons: [{
                text: 'Abbrechen',
                handler: () => {}
            }, {
                text: 'OK',
                handler: data => {
                    if (data) {
                        this.bulkChangesMutations.orderBulkChanges(
                            bulkItemIds,
                            OrderBulkTypeForApi.ADDITIONAL_EXTEND,
                            null,
                            data
                        ).then(failedIds => {
                            if (failedIds) {
                                this.bulkItems.forEach(item => {
                                    if (failedIds.includes(item.id.toString())) {
                                        item.hasError = true;
                                        this.itemHasError = true;
                                    }
                                });
                            } else {
                                this.closeModal();
                                return true;
                            }
                        });
                        return true;
                    }
                    void this.toastService.presentWarning('Bitte wählen Sie einen Verlängerungszeitraum aus.');
                    return false;
                }
            }]
        });
        await extendAlert.present();
    }

    private async arrivedOrder(bulkItemIds: Array<number>) {
        const acceptOrCancelAlert = await this.alertController.create({
            header: 'Ware angekommen?',
            cssClass: 'medium',
            message: 'Ist der Auftrag erledigt und haben Sie die Ware bereits erhalten?',
            buttons: [{
                text: 'Ja',
                handler: () => {
                    this.bulkChangesMutations.orderBulkChanges(
                        bulkItemIds,
                        OrderBulkTypeForApi.DISPO_ACKNOWLEDGE,
                        null,
                    ).then(failedIds => {
                        if (failedIds) {
                            this.bulkItems.forEach(item => {
                                if (failedIds.includes(item.id.toString())) {
                                    item.hasError = true;
                                }
                            });
                        } else {
                            this.closeModal();
                            return true;
                        }
                    });
                    return true;
                }
            }, {
                text: 'Nein',
                handler: () => {
                    return true;
                }
            }]
        });
        await acceptOrCancelAlert.present();
    }
}
