import { CommunicationZoneFormEnum } from '../enums/communication-zone-form.enum';

export const CommunicationZoneFormsSubstituteConfig = {
    customerEmail: '{{CUSTOMER_EMAIL}}',
    documentNumber: '{{DOCUMENT_NUMBER}}',
    documentRecDate: '{{DOCUMENT_REC_DATE}}'
};


export const CommunicationZoneFormsConfig = [
    {
        formId: CommunicationZoneFormEnum.documentCH,
        values: {
            title: 'Rückfrage zu Chargenbeleg Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber,
            content: 'Chargenbeleg Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber + ' vom ' +
                CommunicationZoneFormsSubstituteConfig.documentRecDate +'\n\n',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.documentLS,
        values: {
            title: 'Rückfrage zu Lieferschein Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber,
            content: 'Lieferschein Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber + ' vom ' +
                CommunicationZoneFormsSubstituteConfig.documentRecDate +'\n\n',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.editProfile,
        values: {
            title: 'Änderung Nutzerprofil',
            content: 'Bitte ändern Sie folgende Daten des Nutzerprofils ' + CommunicationZoneFormsSubstituteConfig.customerEmail + ' \n\n[Ihre Änderungen]\n\n\n\n\nVielen Dank, Ihr Apothekenteam',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.companyChangeRequest,
        values: {
            title: 'Änderung Apothekenstammdaten',
            content: 'Bitte ändern Sie folgende Daten meiner Apothekenadresse:\n\n[Ihre Änderungen]\n\n\nBitte übernehmen Sie die Änderung ebenfalls in meine Digitalprodukte.\n\nVielen Dank Ihr Apothekenteam',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.cooperationBonusRequest,
        values: {
            title: 'Interesse an mea Kooperation',
            content: 'Ich habe Interesse an der mea Kooperation und damit verbundenen Einkaufsvorteilen. Bitte kontaktieren Sie mich.\n\n',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.documentESR,
        values: {
            title: 'Rückfrage zur Monatsrechnung Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber,
            content: 'Monatsrechnung Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber + ' vom ' +
                CommunicationZoneFormsSubstituteConfig.documentRecDate +'\n\n',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.documentETR,
        values: {
            title: 'Rückfrage zur Teilrechnung Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber,
            content: 'Teilrechnung Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber + ' vom ' +
                CommunicationZoneFormsSubstituteConfig.documentRecDate +'\n\n',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.meaChatContact,
        values: {
            title: 'Interesse an mea Chat',
            content: 'Guten Tag,\n\nWir möchten mea Chat genauer kennenlernen und wünschen uns weitere Informationen.\n\nBitte kontaktieren Sie uns.',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.invoicesContact,
        values: {
            title: 'Umstellung auf e-Rechnung',
            content: 'Guten Tag,\n\nwir möchten zukünftig e-Rechnungen erhalten und wünschen uns weitere Informationen zum Ablauf.\n\nBitte kontaktieren Sie uns.',
            contactPerson: ''
        }
    }
];
