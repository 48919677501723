export enum StaticPageTypeEnum {
    DATA_POLICY= 'dataPolicy',
    IMPRINT = 'imprint',
    TERMS_OF_USE = 'termsOfUse',
    GENERAL_TERMS = 'termsAndCondition',
}

export enum AcceptTermsStorageKey {
    ACCEPTED_TERMS_KEY = 'acceptedTerms',
}
