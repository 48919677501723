<ng-container *ngIf="!isBulkModeEnabled">
    <ion-grid>
        <ion-row *ngIf="filters && filterConfig" class="ion-padding-bottom-half">
            <ion-col class="{{type === tableTypeOffers ? 'no-padding-bottom' : ''}} no-padding-right" size-xl="18" sizeLg="17">
                <ng-content select="[leftSectionActionTemplate]"></ng-content>
                <div *ngIf="showLeftFiltersContainer" class="filters-container">
                    <ng-container *ngTemplateOutlet="filtersTemplate"></ng-container>
                    <div class="display-flex overflow-auto">
                        <div *ngIf="filterConfig.hasSearchModal" (click)="onSearchButtonPressed($event)" class="search-field-button">
                            <ion-text i18n slot="start" class="search-field-text" *ngIf="filterActive">{{searchFieldText}}</ion-text>
                            <ion-text i18n slot="start" *ngIf="!filterActive">Suche</ion-text>
                            <ion-icon src="assets/icons/cancel.svg" (click)="clearFilter()" *ngIf="filterActive" slot="end" class="clickable"></ion-icon>
                            <ion-icon name="search" mode="ios" slot="end" *ngIf="!filterActive"></ion-icon>
                        </div>
                    </div>
                    <ng-content select="[midSectionActionTemplate]"></ng-content>
                </div>
            </ion-col>
            <ion-col class="{{noBottomPadding ? 'no-padding-bottom' : ''}} no-padding-left" size-xl="6" sizeLg="7">
                <div class="filters-container">
                    <div *ngIf="filterConfig.hasSearchModal">
                        <ion-button  class="filter-button" [class.active]="filterActive" (click)="onFilterButtonPressed()" fill="clear">
                            <ion-icon name="filter-outline" color="medium"></ion-icon>
                        </ion-button>
                    </div>
                    <ng-container>
                        <ion-button
                                *ngIf="hasBulkSelection" class="square-button bulk-select button-tertiary"
                                (click)="changeBulkActionMode(true)"
                                appTooltip ttp="bottom" ttk="bulk_selection"
                        >
                            <ion-icon name="square-outline"></ion-icon>
                            <ion-icon name="caret-down-sharp"></ion-icon>
                        </ion-button>
                        <ion-button
                                *ngIf="printButtonType"
                                (click)="printButton.enabled && !printButton.loading ? onPrintActionButtonCLick() : null"
                                class="square-button button-tertiary"
                                [class.disabled]="!printButton.enabled && !printButton.loading"
                                appTooltip ttp="bottom" ttk="{{printButton.enabled ? 'print_list_enabled' : printButton.loading ? 'print_list_loading' : 'print_list_disabled'}}"
                        >
                            <ion-spinner *ngIf="printButton.loading" name="crescent" class="ion-margin-end print-button-spinner"></ion-spinner>
                            <ion-icon name="print-outline" *ngIf="!printButton.loading"></ion-icon>
                        </ion-button>
                        <ion-button
                                id="export_button_{{exportButtonType}}"
                                *ngIf="exportButtonType"
                                class="square-button button-tertiary"
                                [class.disabled]="!exportButton.enabled && !exportButton.loading"
                        >
                            <ion-spinner *ngIf="exportButton.loading" name="crescent" class="ion-margin-end print-button-spinner"></ion-spinner>
                            <ion-icon name="download-outline" *ngIf="!exportButton.loading"></ion-icon>
                            <ion-popover trigger="export_button_{{exportButtonType}}" triggerAction="hover" show-backdrop="false" dismissOnSelect="true">
                                <ng-template>
                                    <ion-list>
                                        <ion-item detail="false" lines="none" button="true"
                                                  *ngFor="let option of exportConfig"
                                                  (click)="onExportClick(option.type)">
                                            {{option.label}}
                                        </ion-item>
                                    </ion-list>
                                </ng-template>
                            </ion-popover>
                        </ion-button>
                        <ng-content select="[actionTemplate]"></ng-content>
                    </ng-container>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</ng-container>

<ng-container *ngIf="isBulkModeEnabled">
    <div class="display-flex filters-container ion-padding-bottom">
        <div>
            <span class="display-flex-column">
                <ion-label class="filter-label" i18n>Aktion</ion-label>
                <app-badge id="bulk-action-popover" class="badge-highlight-pulse" [badgeType]="bulkActionBadgeType" [code]="bulkSelectAction" isClickable="true" [icon]="'caret-down-sharp'"></app-badge>
            </span>
            <ion-popover trigger="bulk-action-popover" triggerAction="click" dismissOnSelect="true">
                <ng-template>
                    <ion-list>
                        <ion-item lines="none" button="true"
                                  *ngFor="let action of bulkActionConfig"
                                  (click)="bulkActionSelected(action.code)">
                            {{action.label}}
                        </ion-item>
                    </ion-list>
                </ng-template>
            </ion-popover>
        </div>
        <div class="bulk-filters-container display-flex">
            <ng-container *ngTemplateOutlet="filtersTemplate"></ng-container>
        </div>
        <div class="display-flex bulk-action-button-container">
            <ion-text *ngIf="bulkSelectionCount > 0" class="bulk-selection-count" i18n>{{bulkSelectionCount}} Aufträge ausgewählt</ion-text>
            <ion-button *ngIf="bulkActionLabel !== bulkActionDefaultLabel" (click)="submitBulkAction()" color="primary" class="highlight-pulse">{{bulkActionLabel}}</ion-button>
            <ion-button class="button-tertiary" (click)="changeBulkActionMode(false)" i18n>Abbrechen</ion-button>
        </div>
    </div>
</ng-container>

<ng-template #filtersTemplate>
    <div class="display-flex toggle-filter-container">
        <ng-container *ngFor="let conf of filterConfig.items">
            <ng-container *ngIf="!conf.searchOnly">
                <app-date-selection-widget *ngIf="conf.isDate" [title]="conf.label"
                                           [dateRangeOptions]="conf.dateRangeOption"
                                           [selectedDateRangeId]="filters[conf.id + 'Option']"
                                           [defaultFromDate]="filters[conf.id + 'From'] | formatDateTimeToMoment"
                                           [defaultToDate]="filters[conf.id + 'To'] | formatDateTimeToMoment"
                                           [displayOnlyFutureDates]="false"
                                           [displayOnlyPastDates]="true"
                                           [displayIncludingToday]="true"
                                           [readonly]="isBulkModeEnabled"
                                           (datesEvent)="updateDateFilter($event, conf.id)"
                ></app-date-selection-widget>
                <div *ngIf="!conf.isDate && !conf.isBoolean">
                    <ion-text i18n class="filter-label">{{conf.label}}</ion-text>
                    <ion-text *ngIf="!conf.isBatch && !conf.isSpecialPopover" class="filter-text" [class.disabled]="isBulkModeEnabled" (click)="presentPopover($event, conf)">{{(conf | filterResultValue: filters[conf.id]: bulkActionSelectionChanged)}} <ion-icon *ngIf="!isBulkModeEnabled" name="caret-down-sharp"></ion-icon></ion-text>
                    <app-badge *ngIf="conf.isBatch" class="filter-text" (click)="presentPopover($event, conf)" [badgeType]="conf.batchType" [code]="filters[conf.id] ? filters[conf.id] : (conf.translation && conf.translation[0] ? conf.translation[0].id : null)" [icon]="'caret-down-sharp'"></app-badge>
                    <div class="filter-text filter-clear-wrapper" *ngIf="conf.isSpecialPopover">
                        <ion-text (click)="presentSpecialPopover($event, conf.id)" *ngIf="filters[conf.id]">{{filters[conf.id]}}</ion-text>
                        <ion-text (click)="presentSpecialPopover($event, conf.id)" *ngIf="!filters[conf.id]" i18n>Alle <ion-icon name="caret-down-sharp"></ion-icon></ion-text>
                        <ion-icon src="assets/icons/cancel.svg" *ngIf="filters[conf.id]" (click)="presentSpecialPopover(null, conf.id)"></ion-icon>
                    </div>
                </div>
                <div class="toggle-wrapper" *ngIf="conf.isBoolean">
                    <ion-label i18n class="filter-label">{{conf.label}}</ion-label>
                    <ion-toggle mode="ios" [(ngModel)]="toggleValue" (click)="onTogglePressed(conf.id)"></ion-toggle>
                </div>
                <ng-content select="[filterEndTemplate]"></ng-content>
            </ng-container>
        </ng-container>
    </div>
</ng-template>
