import gql from 'graphql-tag';
import MessageMedia from '../../../../../essentials/types/src/messageMedia';
import { messageAttributes } from '../attributes';

export interface CreateMessageInput {
  conversationId: string;
  recipientId?: string;
  senderId: string;
  media: MessageMedia[];
  displayAsPharmacyMessage?: boolean;
  displayOwnAsUnread?: boolean;
  automatic?: boolean;
  customMessageId?: string;
}

export interface DecryptedCreateMessageInput extends CreateMessageInput {
  decryptedTextContent?: string;
}

export interface EncryptedCreateMessageInput extends CreateMessageInput {
  encryptedTextContent?: string;
}

export default gql`
  mutation createMessage(
    $conversationId: ID!
    $encryptedTextContent: String
    $decryptedTextContent: String
    $recipientId: ID
    $senderId: ID
    $media: [MessageMediaInput!]!
    $displayAsPharmacyMessage: Boolean
    $displayOwnAsUnread: Boolean
    $automatic: Boolean
    $initialMessage: Boolean
    $type: Type
    $customMessageId: String
  ) {
    createMessage(
      conversationId: $conversationId
      message: {
        encryptedTextContent: $encryptedTextContent
        decryptedTextContent: $decryptedTextContent
        recipientId: $recipientId
        senderId: $senderId
        media: $media
        displayAsPharmacyMessage: $displayAsPharmacyMessage
        displayOwnAsUnread: $displayOwnAsUnread
        automatic: $automatic
        initialMessage: $initialMessage
        type: $type
      }
      customMessageId: $customMessageId
    ) {
      ${messageAttributes}
    }
  }
`;
