import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
export const UpdatePharmacyStatus = gql`
    mutation UpdatePharmacyStatus {
        UpdatePharmacyStatus {
            status
            message
        }
    }
`;

export const AllAuthMutations = [
    UpdatePharmacyStatus
];

@Injectable()
export class AuthMutations {

    constructor(
        private apollo: Apollo,
    ) {
    }

    updatePharmacyStatus() {
        this.apollo.mutate({
            mutation: UpdatePharmacyStatus
        }).subscribe({ });
    }
}

