import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

export const splitTextFunction = (text: string, length: number = 10, preventSplitWords = false, addMore = false) => {
    if(text && text.length > length) {
        if(preventSplitWords) {
            const regexStr = '.{1,' + length + '}(\\s|$)';
            const regex = new RegExp(regexStr, 'g');
            const textArray = text.match(regex);
            let returnText = textArray[0];
            let i = 1;
            while ((returnText+textArray[i]).length < length && textArray[i]) {
                returnText += textArray[i];
                i++;
            }
            return returnText + (addMore ?  '<span class="more">mehr</span>' : + ' ...');
        }
        return text.slice(0, length - 3) + '...' + (addMore ? '<span class="more">mehr</span>' : '');
    }
    return text;
};

@Pipe({
    name: 'sliceText'
})
export class SliceTextPipe implements PipeTransform {

    transform(text: string | SafeHtml = '', length: number = 10, preventSplitWords = false, addMore = false): string {
        if(!text){
            return '';
        }
        return splitTextFunction(text.toString(), length, preventSplitWords, addMore);
    }
}
@Pipe({
    name: 'convertLineBreak'
})
export class ConvertLineBreakPipe implements PipeTransform {

    transform(text: string): string {
        if(text) {
            return text.replace(new RegExp('\r?\n','g'), '<br />');
        }
        return text;
    }
}
@Pipe({
    name: 'formatModalMessage'
})
export class FormatModalMessage implements PipeTransform {

    transform(text: string): string {
        if(text) {
            text = text.replace(new RegExp('__(.*?)__','g'), '<u>$1</u>'); // Format underline
            text = text.replace(new RegExp('##(.*?)##','g'), '<strong>$1</strong>'); // Format strong
            text = text.replace(new RegExp('\\*\\*(.*?)\\*\\*','g'), '<i>$1</i>'); // Format italic
        }
        return text;
    }
}
