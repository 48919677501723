import { createSelector } from '@ngrx/store';
import { Dictionary } from 'ts-essentials';
import { ChatPartnerMetadata } from '../../../../essentials/types/src/chatPartnerMetadata';
import { Conversation } from '../../../../essentials/types/src/conversation';
import { ChatPartnerMetadataUtil } from '../../../../essentials/util/src/chat-partner-metadata.util';
import { PharmacyChatPartnerState } from './pharmacy-chat-partner.state';

const selectChatPartnerState = (state: { pharmacyChatPartnerMetadata: PharmacyChatPartnerState }) =>
  state.pharmacyChatPartnerMetadata;

export const selectChatPartnerLoadStatus = createSelector(
  selectChatPartnerState,
  (state: PharmacyChatPartnerState) => state.pharmacyChatPartnerLoadStatus
);

export const selectChatPartnerMetadataDictionary = createSelector(
  selectChatPartnerState,
  (state: PharmacyChatPartnerState) => state.pharmacyChatPartnerMetadataDictionary
);

export const selectChatPartnerMetadata = (chatPartnerId: string) =>
  createSelector(
    selectChatPartnerMetadataDictionary,
    (dictionary: Dictionary<ChatPartnerMetadata>) => dictionary[chatPartnerId]
  );

export const selectChatPartnerSuspendedStatus = (conversation: Conversation) =>
  createSelector(selectChatPartnerMetadataDictionary, (dictionary: Dictionary<ChatPartnerMetadata>) => {
    const chatPartnerMetadata = dictionary[ChatPartnerMetadataUtil.getChatPartnerIdAsPharmacy(conversation)];
    return !!chatPartnerMetadata?.isSuspended;
  });
