export enum BannerTypeEnum {
    DASHBOARD = 'Dashboard',
    OFFERS = 'Angebote',
    NEWS = 'News',
    MEA_INFO = 'MeaInfo',
    MEA_OFFER = 'MeaAngebote',
}

/**
 * Possible sizes of the banner picture. These should be used to avoid aspect ratio issues.
 * Keep the order, since the html source tags are being called in this order, and they have to start with the smallest.
 *
 * SMALL 1380x424px
 * MIDDLE 1860x572px
 * BIG 2280x700px
 */
export enum BannerSizeEnum {
    SMALL = 'klein',
    MIDDLE = 'mittel',
    BIG = 'gross'
}

export enum BannerScreenMinWidthEnum {
    SMALL = '1024',
    MIDDLE = '1224',
    BIG = '1424'
}
