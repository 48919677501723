import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ComponentRef } from '@ionic/core';
import { Moment } from 'moment';
import { read, writeFile } from 'xlsx';
import { Subscription } from 'rxjs';

// Core Files
import {
    BadgeTypeEnum,
    BulkActionCodeEnum,
    BulkActionTypeEnum,
    DateRangeOptionCodes,
    ExportFormatEnum,
    ExportTableTypeEnum,
    PrintPdfTableTypeEnum,
    TableTypeEnum
} from '../../core.enums';
import {
    DateRangeInterface,
    FilterResultInterface,
    FilterResultItemInterface,
    ListFiltersInterface,
} from '../../core.interfaces';
import { DateRangeConfig, ExportConfig, GetDateRangeConfig, OrderBulkActionConfig } from '../../core.config';
import { formatDateToCustomFormat, getDate } from '../../core.formatting';
import { ModalService, PopoverService } from '../../core.services';

import { ExcelQueries } from '../../store/graphql/queries/excel.graphql';
import { SelectPopoverComponent } from '../select-popover/select-popover.component';
import { FilterService } from '../../services/filter.service';
import { PdfProviderService } from '../../services/pdfProvider.service';
import { BulkActionModalComponent } from '../bulk-action-modal/bulk-action-modal.component';
import { SelectedBulkOrdersVar } from '../../store/locals/bulk-changes-orders.var';
import { ApiService } from '../../services/api.service';
import { BulkModeVar } from '../../store/locals/bulk-mode.var';
import { unsubscribe, unsubscribeAll } from '../../util/subscriptions.util';
import { BulkActionService } from '../../services/bulkAction.service';

@Component({
    selector: 'app-table-old-header',
    templateUrl: './table-header.component.html',
    styleUrls: ['./table-header.component.scss'],
})
export class TableHeaderComponent implements OnChanges {
    @Input() type: TableTypeEnum;
    @Input() filters: ListFiltersInterface = {};
    @Input() toggleValue;
    @Input() filterConfig: FilterResultInterface;
    @Input() searchModalComponent: ComponentRef;
    @Input() printButtonType: PrintPdfTableTypeEnum;
    @Input() exportButtonType: ExportTableTypeEnum;
    @Input() noBottomPadding = false;
    @Input() hasBulkSelection = false;
    @Input() showLeftFiltersContainer = true;
    @Output() saveFilterEvent: EventEmitter<object> = new EventEmitter();
    @Output() specialPopoverClick: EventEmitter<{ event: any, confId: string }> = new EventEmitter();

    private bulkModeVar = inject(BulkModeVar);
    private bulkActionService = inject(BulkActionService);
    private selectedBulkOrdersVar = inject(SelectedBulkOrdersVar);
    private popoverService = inject(PopoverService);
    private filterService = inject(FilterService);
    private tableToPdfService = inject(PdfProviderService);
    private apiService = inject(ApiService);
    private excelQueries = inject(ExcelQueries);
    private modalService = inject(ModalService);


    printButton: {enabled: boolean, loading: boolean} = {enabled: true, loading: false};
    exportButton: {enabled: boolean, loading: boolean} = {enabled: true, loading: false};
    dateRangeOptions: Array<DateRangeInterface> = GetDateRangeConfig();
    defaultDateRangeOption: DateRangeOptionCodes;
    defaultFromDate: Moment;
    defaultToDate: Moment;
    exportConfig = ExportConfig.excel;

    searchText = '';
    searchFieldText = '';

    filterActive = false;
    tableTypeOffers = TableTypeEnum.Offers;

    isBulkModeEnabled: boolean;
    bulkSelectAction: BulkActionCodeEnum;
    bulkSelectionCount: number;
    bulkActionConfig = OrderBulkActionConfig.filter(action => action.code !== BulkActionCodeEnum.DEFAULT);
    bulkActionLabel = OrderBulkActionConfig.find(action => action.code === BulkActionCodeEnum.DEFAULT).label;
    bulkActionDefaultLabel = this.bulkActionLabel;
    bulkActionBadgeType = BadgeTypeEnum.ORDER_BULK_ACTION;
    bulkActionSelectionChanged = 0;
    filtersBeforeBulkMode: ListFiltersInterface = {};

    bulkChangesOrdersSubscription: Subscription;
    bulkModeSubscription: Subscription;
    bulkActionSubscription: Subscription;

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.filters && changes.filters.currentValue) {
            this.updateSearchFieldText(this.filters);
        }
    }

    initBulkMode() {
      /*  unsubscribe(this.bulkModeSubscription);
        this.bulkModeSubscription = this.bulkModeVar.getIsBulkModeEnabled().subscribe((isBulkModeEnabled) => {
            this.isBulkModeEnabled = isBulkModeEnabled;
            if (!isBulkModeEnabled && this.filtersBeforeBulkMode && Object.keys(this.filtersBeforeBulkMode)?.length) {
                this.filters = this.filtersBeforeBulkMode;
            }
        });
        unsubscribe(this.bulkActionSubscription);
        this.bulkActionSubscription = this.bulkModeVar.getBulkActionSelected().subscribe((bulkAction) => {
            this.bulkSelectAction = bulkAction;
        });*/
        unsubscribe(this.bulkChangesOrdersSubscription);
        this.bulkChangesOrdersSubscription = this.selectedBulkOrdersVar.getSelectedBulkItems().subscribe((selectedOrders) => {
            this.bulkSelectionCount = selectedOrders?.length || 0;
        });
    }

    clearBulkMode() {
        this.changeBulkActionMode(false);
        unsubscribeAll([
            this.bulkChangesOrdersSubscription,
            this.bulkModeSubscription,
            this.bulkActionSubscription
        ]);
    }

    /**
     * Updates the search field text
     *
     * @param filters - the filters
     */
    updateSearchFieldText(filters: any = null) {
        const searchFieldTextArray = [];
        this.searchFieldText = '';

        if(!filters) {
            return;
        }

        // make sure, that search is the first entry
        if(filters.search) {
            searchFieldTextArray.push(filters.search);
        }

        this.filterConfig.items.forEach((conf) => {
            let value = filters[conf.id];
            if (conf.searchOnly && conf.id !== 'search') {
                if (conf.isDate) {
                    const dateRangeOption = filters[conf.id + 'Option'];
                    if (dateRangeOption === DateRangeOptionCodes.individual) {
                        const fromDate = filters[conf.id + 'From'];
                        const toDate = filters[conf.id + 'To'];
                        if(fromDate === toDate) {
                            value = formatDateToCustomFormat(fromDate);
                        } else {
                            value = formatDateToCustomFormat(filters[conf.id + 'From']) + ' - ' +
                                formatDateToCustomFormat(filters[conf.id + 'To']);
                        }
                    } else if (DateRangeOptionCodes.all !== dateRangeOption) {
                        value = DateRangeConfig.find(item => item.id === dateRangeOption)?.title;
                    }
                }
                if(value) {
                    searchFieldTextArray.push(conf.searchLabel + ': ' + value);
                }
            }
        });
        this.searchFieldText = searchFieldTextArray.join(' - ');
        this.filterActive = searchFieldTextArray.length > 0;
    }

    /**
     * Show a popover with all available delivery types
     *
     * @param event - Event of the click
     * @param conf - The filter config
     */
    async presentPopover(event: any, conf: FilterResultItemInterface) {
        if (!conf.translation || conf.translation.length === 0 || this.isBulkModeEnabled) {
            return;
        }

        const selectPopover = await this.popoverService.create({
            component: SelectPopoverComponent,
            componentProps: {
                options: conf.translation.filter(item => !item['excludeFromFilter']),
                selected: this.filters[conf.id],
                useCheckboxes: conf.useCheckboxes
            },
            event,
            translucent: true
        });

        selectPopover.onDidDismiss().then(result => {
            const data = result.data;
            if(data) {
                if (data.id && conf.translation.find(type => type.id === data.id)) {
                    this.filters[conf.id] = data.id;
                } else if (data.id) {
                    this.filters[conf.id] = conf.translation[0];
                } else if(data.multipleIds) {
                    this.filters[conf.id] = data.multipleIds;
                }
            }

            this.saveFilters(this.filters);
        });

        return await this.popoverService.present(selectPopover);
    }

    /**
     * Updates the date filters
     *
     * @param event - Event of the change
     * @param confId - Id of the element
     */
    updateDateFilter(event, confId) {
        this.filterService.init(this.type);
        this.filterService.updateDateFilter(event, confId, this.filters);
    }

    onTogglePressed(configId) {
        this.filters[configId] = !this.toggleValue;
        this.saveFilters(this.filters);
    }

    /**
     * Open new browser tab and print table-old as PDF.
     */
    async onPrintActionButtonCLick() {
        if (this.printButtonType) {
            this.printButton = { enabled: false, loading: true};

            // disable print button for 30 seconds
            setTimeout(() => {
                this.printButton = { enabled: true, loading: false};
            }, 30000);

            if (await this.tableToPdfService.getTablePdf(this.printButtonType, this.filters)) {
                this.printButton.loading = false;
            } else {
                this.printButton = { enabled: true, loading: false};
            }
        }
    }
    /**
     * Open new browser tab and print table-old as PDF.
     */
    async onExportClick(format: ExportFormatEnum = ExportFormatEnum.XLSX) {
        if(this.exportButtonType) {
            this.exportButton = { enabled: false, loading: true};

            this.excelQueries.exportExcelByList(this.exportButtonType).subscribe(res => {
                if(res?.data && res?.filename) {
                    const buf = res.data.split(',').map(n => parseInt(n, 10));
                    const wb = read(Uint8Array.from(buf).buffer, {type: 'buffer'});

                    const date = '_' + getDate();

                    switch(format) {
                        case ExportFormatEnum.XLSX:
                            writeFile(wb, res?.filename + date + '.xlsx', {compression: true});
                        break;
                        case ExportFormatEnum.CSV:
                            writeFile(wb, res?.filename+ date + '.csv', {bookType: 'csv'});
                        break;
                    }
                } else if(res?.status === 'ERROR') {
                    void this.apiService.presentErrorToast(null, res.message);
                }

                // Reset loading animation
                this.exportButton = {enabled: true, loading: false};
            });
        }
    }

    /**
     * User has pressed the create disposition request icon. Show modal window
     *
     * @param event - Event of the click
     */
    async onSearchButtonPressed(event: any) {
        if (!event.target.className.includes('clickable')) {
            return await this.openSearchModal(true);
        }
    }

    /**
     * User has pressed the create disposition request icon. Show modal window
     */
    async onFilterButtonPressed() {
        return await this.openSearchModal(false);
    }

    /**
     * User has pressed the create disposition request icon. Show modal window
     *
     * @param focusOnSearch - Should the search input be focused
     */
    async openSearchModal(focusOnSearch: boolean) {
        this.filterService.init(this.type);
        await this.filterService.openSearchModal(focusOnSearch, this.searchText, this.searchModalComponent);
    }

    presentSpecialPopover(event, confId) {
        this.specialPopoverClick.emit({event, confId});
    }

    /**
     * Saves the filters
     */
    saveFilters(filters) {
        this.filterService.init(this.type);
        this.filterService.saveFilters(filters);
    }

    /**
     * Resets the filters
     */
    clearFilter() {
        this.filterActive = false;
        this.searchFieldText = '';
        if (this.filterConfig) {
            this.filterConfig.items.forEach((conf) => {
                if (conf.searchOnly) {
                    if (conf.isDate) {
                        this.filters[conf.id + 'Option'] = '';
                        this.filters[conf.id + 'From'] = '';
                        this.filters[conf.id + 'To'] = '';
                    } else {
                        this.filters[conf.id] = '';
                    }
                }
            });
        }
        this.saveFilters(this.filters);
    }

    async submitBulkAction() {
        const modal = await this.modalService.create(
            BulkActionModalComponent,
            {
                bulkType: BulkActionTypeEnum.ORDERS,
                bulkAction: this.bulkSelectAction,
            }
        );
        await this.modalService.present(modal);
    }

    changeBulkActionMode(isEnabled: boolean) {
        this.bulkSelectionCount = 0;
        this.bulkModeVar.setActive(isEnabled);
        this.selectedBulkOrdersVar.clearSelectedBulkItems();
        if (isEnabled) {
            this.filtersBeforeBulkMode = {...this.filters};
        } else if (this.filtersBeforeBulkMode && Object.keys(this.filtersBeforeBulkMode)?.length) {
            this.filters = {...this.filtersBeforeBulkMode};
            this.bulkActionSelectionChanged += 1;
            this.bulkActionLabel = OrderBulkActionConfig.find(item => item.code === BulkActionCodeEnum.DEFAULT).label;
            this.bulkActionSelected(BulkActionCodeEnum.DEFAULT);
        }
    }

    bulkActionSelected(action: BulkActionCodeEnum) {
        this.bulkSelectAction = action;
        this.bulkActionLabel= OrderBulkActionConfig.find(item => item.code === action).label;
        this.bulkSelectionCount = 0;
        this.selectedBulkOrdersVar.clearSelectedBulkItems();
        if (action !== BulkActionCodeEnum.DEFAULT) {
        //    this.bulkModeVar.setBulkActionSelected(action);
        }

        const bulkActionFilters = this.bulkActionService.getBulkActionFilters(action, this.type, this.filters);
        this.filters = {...this.filters, ...bulkActionFilters};
        this.bulkActionSelectionChanged += 1;
    }

}
