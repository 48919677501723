export enum StatisticsType {
    PERCENTAGE = '%',
    PIECE = 'St'
}
export enum StatisticDevelopmentType {
    positive = 'positive',
    negative = 'negative',
    neutral = 'neutral'
}

export enum StatisticsPerDayType {
    ORDER_DATA_DAILY_ORDERS_ADDITIONAL = 'ORDER_DATA_DAILY_ORDERS_ADDITIONAL',
    ORDER_DATA_DAILY_ORDERS_DISPOSITION = 'ORDER_DATA_DAILY_ORDERS_DISPOSITION'
}

export enum StatisticComponentTypes {
    DailyOrdersBarChart     = 'dailyOrdersBarChart',
    SalesForecastBarChart   = 'salesForecastBarChart',
    SalesRevenueBarChart    = 'salesRevenueBarChart',
    SalesGrowthDonutChart   = 'salesGrowthDonutChart'
}

export enum StatisticTypeEnum {
    ORDERS_ENROUTE                          = 'ENROUTE_ORDERS',
    ORDERS_OPEN                             = 'OPEN_ORDERS',
    ORDERS_PENDING                          = 'PENDING_ORDERS',
    ORDERS_OPEN_CANCELATIONS                = 'OPEN_CANCELLATIONS',

    ORDER_DATA_MONTHLY_ORDERS_ADDITIONAL    = 'ORDER_DATA_MONTHLY_ORDERS_ADDITIONAL',
    ORDER_DATA_MONTHLY_ORDERS_DISPOSITION   = 'ORDER_DATA_MONTHLY_ORDERS_DISPOSITION',
    ORDER_DATA_MONTHLY_ORDERS               = 'ORDER_DATA_MONTHLY_ORDERS',
    ORDER_DATA_MONTHLY_PACKAGES             = 'ORDER_DATA_MONTHLY_PACKAGES',

    SALES_VOLUME                            = 'SALES_VOLUME',                       // Gesamtumsatz in €
    SALES_VOLUME_WITHOUT_HIGH_PRICED        = 'SALES_VOLUME_WITHOUT_HIGH_PRICED',   // Gesamtumsatz ohne Hochpreiser in €
    SALES_VOLUME_JUST_HIGH_PRICED           = 'SALES_VOLUME_JUST_HIGH_PRICED',      // Gesamtumsatz nur Hochpreiser in €
    AVG_PACKAGE_SALES                       = 'AVG_PACKAGES_SALES',                 // Ø Packungswert AEK € (nur RX)

    TARGET_SALES                            = 'TARGET_SALES',                              // Zielumsatz pro Jahr (Kooperationsumsatz)
    COOPERATION_REVENUE_LAST_MONTH          = 'COOPERATION_REVENUE_LAST_MONTH',            // Kooperationsumsatz abgeschlossener Monat
    COOPERATION_REVENUE_CURRENT_YEAR        = 'COOPERATION_REVENUE_CURRENT_YEAR',          // Kooperationsumsatz aktuelles Jahr kumuliert
    COOPERATION_REVENUE_DISCOUNT_PERCENT    = 'COOPERATION_REVENUE_DISCOUNT_PERCENT',      // Rabatt bei Zielerreichung
    COOPERATION_REVENUE_DISCOUNT_ABSOLUTE   = 'COOPERATION_REVENUE_DISCOUNT_ABSOLUTE',     // Absolutrabatt bei Zielerreichung
    COOPERATION_REVENUE_TARGET_DIFFERENCE   = 'COOPERATION_REVENUE_TARGET_DIFFERENCE',     // Differenzumsatz zum Monatsziel
    COOPERATION_NUMBER_PARTICIPATED_MONTHS  = 'COOPERATION_NUMBER_PARTICIPATED_MONTHS',    // Anzahl teilgenommene Monate im Jahr

                                                                                            // RX Artikel sind verschreibungspflichtige Artikel
    SALES_VOLUME_RX_WITHOUT_HIGH_PRICED     = 'SALES_VOLUME_RX_WITHOUT_HIGH_PRICED',        // Umsatz RX ohne HP
    PACKAGES_SALES                          = 'PACKAGES_SALES',                             // Packungsanzahl gesamt
    PACKAGES_SALES_RX_WITHOUT_HIGH_PRICED   = 'PACKAGES_SALES_RX_WITHOUT_HIGH_PRICED',      // Packungsanzahl RX ohne HP
    PACKAGES_SALES_JUST_HIGH_PRICED         = 'PACKAGES_SALES_JUST_HIGH_PRICED',            // Packungsanzahl gesamt nur HP
    PACKAGES_SALES_WITHOUT_HIGH_PRICED      = 'PACKAGES_SALES_WITHOUT_HIGH_PRICED',         // Packungsanzahl gesamt ohne HP

    /*
    * Berechnung verbleibender Retourenfreibetrag:
    * RETURNS_ALLOWANCE_REMAINING = RETURNS_REVENUE * (RETURNS_ALLOWANCE_PERCENT / 100) - RETURNS_ALLOWANCE_USED
    */
    RETURNS_ALLOWANCE_USED = 'RETURNS_ALLOWANCE_USED',          // bereits aufgebrauchter Retourenfreibetrag (gutsWert in Facade)
    RETURNS_REVENUE = 'RETURNS_REVENUE',                        // Umsatz der Apotheke, dieser wird zur Berechnung des Retourenfreibetrags benutzt
    RETURNS_ALLOWANCE_PERCENT = 'RETURNS_ALLOWANCE_PERCENT',    // Quote in % für den freien Anteil, zur Berechnung des Retourenfreibetrags benutzt
    RETURNS_ALLOWANCE_REMAINING = 'RETURNS_ALLOWANCE_REMAINING',    // verbleibender Retourenfreibetrag in €: für Apotheke interessant
    RETURNS_UPDATED_DATE = 'RETURNS_UPDATED_DATE',              // Datum der letzten Aktualisierung
}

export enum StatisticGeneralTypeEnum {
    SALES = 'SALES',
    PACKAGES = 'PACKAGES',
    ORDERS = 'ORDERS'
}

/*
PENDING_ORDERS - Alle Orders mit status “Pending”
OPEN_PACKAGES - Alle offenen Paketstücke (Alles außer DELEGATED, CANCELLED und COMPLETED)
OPEN_ORDERS - Alle offenen Orders (Alles außer CANCELLED und COMPLETED)
OPEN_CANCELLATIONS - Alle Orders mit status isCancelled die noch nicht auf status COMPLETED oder CANCELLED sind
ENROUTE_ORDERS - Alle Orders die gerade im status ENROUE sind
 */
