import { NotificationFilterEnum, NotificationOptionEnum, NotificationsEnum } from '../enums/notifications.enum';
import { SortEnum } from '../enums/sort.enum';
import { ViewTypeEnum } from '../enums/view-type.enum';
import { BulkActionCodeEnum, BulkOptionCodeEnum } from '../enums/bulk-action.enum';

export const MeaChatNotificationTypes = [
    NotificationsEnum.NEW_MEACHATMESSAGE
];

export const NotificationFiltersConfig = [
    {
        id: NotificationFilterEnum.ALL,
        title: 'Posteingang',
        disabled: false,
        tooltipKey: ''
    },
    {
        id: NotificationFilterEnum.UNREAD,
        title: 'Ungelesen',
        disabled: false,
        tooltipKey: ''
    },
    {
        id: NotificationFilterEnum.ARCHIVED,
        title: 'Archiv',
        disabled: false,
        tooltipKey: ''
    }
];

export const NotificationSortConfig = [
    {
        id: SortEnum.LATEST,
        title: 'Neueste',
        disabled: false,
        tooltipKey: ''
    },
    {
        id: SortEnum.OLDEST,
        title: 'Älteste',
        disabled: false,
        tooltipKey: ''
    }
];
export const NotificationViewConfig = [
    {
        id: ViewTypeEnum.TABLE,
        disabled: false,
        tooltipKey: '',
        icon: 'assets/icons/view-table.svg'
    },
    {
        id: ViewTypeEnum.LIST,
        disabled: false,
        tooltipKey: '',
        icon: 'assets/icons/view-list.svg'
    }
];

export const NotificationBulkConfig = (isActive = false, allSelected = false) => {
    return [
        (
            !isActive ? {
                id: BulkOptionCodeEnum.ACTIVATE,
                title: 'Mehrfachauswahl aktivieren',
            } : {
                id: BulkOptionCodeEnum.DEACTIVATE,
                title: 'Mehrfachauswahl deaktivieren',
            }
        ),
        (
            !allSelected ? {
                id: BulkOptionCodeEnum.SELECT_ALL,
                title: 'Alle auswählen',
            } : {
                id: BulkOptionCodeEnum.SELECT_NONE,
                title: 'Nichts auswählen',
            }
        )
    ];
};

export const NotificationBulkActionsConfig = (archiveAvailable = true, unreadAvailable = true, readAvailable = true) : Array<{
    code: BulkActionCodeEnum,
    label: string,
    class: string
}> => {
    const options = [
        {code: BulkActionCodeEnum.DELETE,                       label: 'Löschen',                   class: 'button-tertiary'}
    ];
    if(archiveAvailable) {
        options.push({code: BulkActionCodeEnum.ARCHIVE,         label: 'Archivieren',               class: 'button-tertiary'});
    }
    if(unreadAvailable) {
        options.push({code: BulkActionCodeEnum.MARK_AS_UNREAD,  label: 'Als ungelesen markieren',   class: 'button-tertiary'});
    }
    if(readAvailable) {
        options.push({code: BulkActionCodeEnum.MARK_AS_READ,    label: 'Als gelesen markieren',     class: 'button-tertiary'});
    }

    return options;
};

export const NotificationOptionsConfig = [
    {
        id: NotificationOptionEnum.MARK_AS_UNREAD,
        title: 'Als ungelesen markieren',
    },
    {
        id: NotificationOptionEnum.ARCHIVE,
        title: 'Benachrichtigung archivieren',
    },
    {
        id: NotificationOptionEnum.GO_TO_ENTRY,
        title: 'Zum Eintrag',
    },
    {
        id: NotificationOptionEnum.GO_TO_HELP,
        title: 'Zur Hilfe'
    },
    {
        id: NotificationOptionEnum.GO_TO_DATA_POLICY,
        title: 'Nutzungsbedingungen',
    }
];
