export enum NotificationsEnum {
    COMMON_NOTIFICATION = 'COMMON_NOTIFICATION',
    REMINDER_APPOINTMENT = 'REMINDER_APPOINTMENT',
    REMINDER_SEMINAR_REGISTRATION = 'REMINDER_SEMINAR_REGISTRATION',
    ORDER_ADDITIONAL_DEADLINE = 'ORDER_ADDITIONAL_DEADLINE',
    ORDER_DISPO_DIRECT_OUT_OF_DATE = 'ORDER_DISPO_DIRECT_OUT_OF_DATE',
    NEW_MEACHATMESSAGE = 'NEW_MEACHATMESSAGE',
    ORDER_STATUS_ENROUTE = 'ORDER_STATUS_ENROUTE',
    ORDER_STATUS_CANCELLED = 'ORDER_STATUS_CANCELLED',
    NEW_RELEASE_NOTE = 'NEW_RELEASE_NOTE',
    NEW_STATIC_PAGE = 'NEW_STATIC_PAGE',
    NEW_NEWS = 'NEW_NEWS',
    NEW_IMPORTANT_NEWS = 'NEW_IMPORTANT_NEWS',
    ORDER_QUANTITY_CHANGE = 'ORDER_QUANTITY_CHANGE',
    TOUR_NOTIFICATION = 'TOUR_NOTIFICATION',
    NEW_MAINTENANCE = 'NEW_MAINTENANCE',
    COOPERATION_REVENUE_CURRENT_YEAR = 'COOPERATION_REVENUE_CURRENT_YEAR',
    SALES_VOLUME_WITHOUT_HIGH_PRICED = 'SALES_VOLUME_WITHOUT_HIGH_PRICED',
    NEW_USER_WELCOME = 'NEW_USER_WELCOME',
    NEW_USER_DATA_POLICY = 'NEW_USER_DATA_POLICY',
    RETURNS_PRODUCT_MISSING = 'RETURNS_PRODUCT_MISSING',
    RETURNS_STOCK_ERROR = 'RETURNS_STOCK_ERROR',
    NEW_OFFER = 'NEW_OFFER',
    NEW_DOCUMENT = 'NEW_DOCUMENT',
    ORDER_CANCEL_ERROR = 'ORDER_CANCEL_ERROR',
    ORDER_EXTEND_ERROR = 'ORDER_EXTEND_ERROR',
    MEAMIND_NEW_QUESTION = 'MEAMIND_NEW_QUESTION',
    MEAMIND_NEW_ANSWER = 'MEAMIND_NEW_ANSWER',
    MEAMIND_NEW_COMMENT = 'MEAMIND_NEW_COMMENT',
    MEAMIND_UPDATE_QUESTION = 'MEAMIND_UPDATE_QUESTION',
    MEAMIND_UPDATE_ANSWER = 'MEAMIND_UPDATE_ANSWER',
    MEAMIND_UPDATE_COMMENT = 'MEAMIND_UPDATE_COMMENT',
    MEASHOP_NEW_ORDER = 'MEASHOP_NEW_ORDER',
    NEW_SURVEY = 'NEW_SURVEY',
    NEW_ASSORTMENT_STATISTIC = 'NEW_ASSORTMENT_STATISTIC',
}

export enum NotificationsReminderTypeEnum {
    APPOINTMENT_REMINDER = 'appointmentReminder',
    SEMINAR_REGISTRATION_REMINDER = 'seminarRegistrationReminder'
}

export enum NotificationSoundsEnum {
    DEFAULT = 'sanacorp_earcon.mp3',
    CHAT = 'notification.mp3'
}

export enum NotificationGeneralTypeEnum {
    COMMON = 'common',
    CHAT = 'chat'
}

export enum NotificationFilterEnum {
    ALL,
    UNREAD,
    READ,
    ARCHIVED
}
export enum NotificationTypeEnum {
    MEASHOP = 'MEASHOP',
    ORDERS = 'ORDERS',
    OFFERS = 'OFFERS',
    NEWS = 'NEWS',
    DOCUMENTS = 'DOCUMENTS',
    STATISTICS = 'STATISTICS',
    MEACHAT = 'MEACHAT',
    MEAMIND = 'MEAMIND',
    SURVEY = 'SURVEY',
    INFO = 'INFO',
    APPOINTMENTS = 'APPOINTMENTS'
}

export enum NotificationOptionEnum {
    MARK_AS_READ,
    MARK_AS_UNREAD,
    ARCHIVE,
    GO_TO_ENTRY,
    GO_TO_HELP,
    GO_TO_DATA_POLICY
}
