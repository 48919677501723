import { Injectable } from '@angular/core';
import { SortingMutations } from '../store/graphql/mutations/sorting.graphql';
import {
    defaultOrderSorting,
    defaultDeliverySorting,
    defaultInvoiceSorting,
    defaultReturnsSorting,
    defaultOffersSorting
} from '../config/sorting.config';
import {
    SortingDocumentInterface,
    SortingOrderInterface,
    SortingInterface,
    SortingReturnsInterface,
    SortingOffersInterface
} from '../interfaces/sorting.interface';
import { UtilService } from './util.service';

@Injectable({
    providedIn: 'root',
})
export class SortingService {

    constructor(
        private sortingMutations: SortingMutations,
        private utilService: UtilService
    ) {
        this.saveOrderSorting = this.utilService.debouncePromise(this.saveOrderSorting, 1000);
        this.saveDeliverySorting = this.utilService.debouncePromise(this.saveDeliverySorting, 1000);
        this.saveInvoiceSorting = this.utilService.debouncePromise(this.saveInvoiceSorting, 1000);
        this.saveReturnsSorting = this.utilService.debouncePromise(this.saveReturnsSorting, 1000);
        this.saveOffersSorting = this.utilService.debouncePromise(this.saveOffersSorting, 1000);

    }

    /**
     * Save new sorting
     *
     * @param newFilters - new sorting
     */
    private saveSorting(newFilters: SortingInterface) {
        return this.sortingMutations.upsertSorting(newFilters);
    }

    /**
     * Save new order sorting
     *
     * @param updatedSorting - new sorting
     */
    saveOrderSorting = (updatedSorting: SortingOrderInterface) => {
        return this.saveSorting({...defaultOrderSorting, ...updatedSorting});
    }

    /**
     * Save new delivery sorting
     *
     * @param updatedSorting - new sorting
     */
    saveDeliverySorting = (updatedSorting: SortingDocumentInterface) => {
        return this.saveSorting({...defaultDeliverySorting, ...updatedSorting});
    }

    /**
     * Save new invoice sorting
     *
     * @param updatedSorting - new sorting
     */
    saveInvoiceSorting = (updatedSorting: SortingDocumentInterface) => {
        return this.saveSorting({...defaultInvoiceSorting, ...updatedSorting});
    }

    /**
     * Save new returns sorting
     *
     * @param updatedSorting - new sorting
     */
    saveReturnsSorting = (updatedSorting: SortingReturnsInterface) => {
        return this.saveSorting({...defaultReturnsSorting, ...updatedSorting});
    }

    /**
     * Save new offers sorting
     *
     * @param updatedSorting - new sorting
     */
    saveOffersSorting = (updatedSorting: SortingOffersInterface) => {
        return this.saveSorting({...defaultOffersSorting, ...updatedSorting});
    }
}
