import { Inject, Injectable } from '@angular/core';
import { MeaUser } from '../../../../essentials/types/src/chatUser';
import { LogoutType } from '../../../../essentials/types/src/logoutType';
import { MeaConfig } from '../../../../essentials/types/src/mea-config';
import { CustomAmplifyService } from './amplify.service';
import { AppsyncUserService } from './appsync/appsync-user.service';
import { PrivateKeyStoreService } from './encryption/private-key-store.service';
import { PrivateKeyValidationService } from './encryption/private-key-validation.service';

export type UserOfCurrentSession =
  | { userType: 'known'; user: MeaUser }
  | { userType: 'anonymous'; logoutType?: LogoutType };

@Injectable({
  providedIn: 'root',
})
export class InitUserService {
  isPharmacy: boolean;

  constructor(
    private appSyncUserService: AppsyncUserService,
    private amplifyService: CustomAmplifyService,
    @Inject('config') private config: MeaConfig,
    private privateKeyStoreService: PrivateKeyStoreService,
    private privateKeyValidationService: PrivateKeyValidationService
  ) {
    this.isPharmacy = !config.clientApp;
  }

  public async getUserOfCurrentSession(): Promise<UserOfCurrentSession> {
    if (this.isPharmacy) {
      const isPharmacyLoggedIn = await this.isPharmacyLoggedIn();
      if (!isPharmacyLoggedIn) {
        return { userType: 'anonymous' };
      }
    }

    const dbUserData: MeaUser | undefined = await this.appSyncUserService.getUserFromBackend(
      this.isPharmacy ? 'PHARMACY' : 'ENDUSER'
    );
    if (!dbUserData) {
      return { userType: 'anonymous' };
    }

    const isUserExpired = await this.checkDeleteAccountAt(dbUserData.deleteAccountAt);

    if (dbUserData.userStatus !== 'ACTIVE' || isUserExpired) {
      return { userType: 'anonymous', logoutType: LogoutType.SilentExpired };
    }

    const privateKeyFromStorage = await this.privateKeyStoreService.getPrivateKey(dbUserData.cognitoId);
    if (privateKeyFromStorage) {
      dbUserData.privateKey = privateKeyFromStorage;
    }

    if (!this.privateKeyValidationService.isPrivateKeyValid(dbUserData.privateKey, dbUserData.publicKey)) {
      const logoutType = dbUserData.isGuest
        ? LogoutType.InvalidPrivateKeyOnInitialLoadForGuest
        : LogoutType.InvalidPrivateKeyOnInitialLoadForNonGuestUser;
      return { userType: 'anonymous', logoutType };
    }

    return { userType: 'known', user: dbUserData };
  }

  async isPharmacyLoggedIn(): Promise<boolean> {
    // client.query throws an unrecoverable error if no credentials are present. Hence we first check for the pharmacy
    // if credentials exist. If not the catch block catches this error
    try {
      await this.amplifyService.auth().currentAuthenticatedUser({ bypassCache: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  private async checkDeleteAccountAt(deleteAccountAt?: number) {
    if (!deleteAccountAt) {
      return false;
    }
    const currentTimestamp = Date.now() / 1000;
    return deleteAccountAt <= currentTimestamp;
  }
}
