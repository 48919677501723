<app-expandable-sidebar
        [title]="'Letzte Suchen'"
        [isSidebarVisible]="!selectedSearchHistoryItem"
        [isDetailsVisible]="!!selectedSearchHistoryItem">

    <!-- Loading -->
    <ng-container content *ngIf="isLoading">
        <div *ngFor="let item of [1,2]" class="list-item skeleton">
            <ion-text>
                <ion-skeleton-text animated></ion-skeleton-text>
            </ion-text>
            <div class="display-flex">
                <ion-skeleton-text animated></ion-skeleton-text>
                <ion-skeleton-text animated></ion-skeleton-text>
            </div>
        </div>
    </ng-container>

    <!-- No questions found -->
    <ng-container content *ngIf="!isLoading && searchHistoryItems && searchHistoryItems.length === 0">
        <div class="display-flex full-height ion-justify-content-center no-items">
            <ion-text class="h4" color="medium" i18n>Sie haben noch keine Suche ausgeführt.</ion-text>
        </div>
    </ng-container>

    <!-- Results List -->
    <ng-container content *ngIf="!isLoading && searchHistoryItems && searchHistoryItems.length > 0">
        <div *ngFor="let searchHistoryItem of searchHistoryItems" class="list-item" (click)="onSearchHistoryItemClick(searchHistoryItem, false)">
            <ion-text class="filters">
                <p *ngIf="searchHistoryItem.search && searchHistoryItem.search !== ''" class="title">{{searchHistoryItem.search}}</p>
                <p>


                    <span i18n
                          *ngIf="searchHistoryItem['recTimeOption'] !== individualDateRange && searchHistoryItem['recTimeOption'] !== allDateRange">
                        Bestelldatum: <strong>{{searchHistoryItem['recTimeOption'] | dateRangeToLabel}}</strong></span>
                    <span i18n
                          *ngIf="searchHistoryItem['recTimeOption'] === individualDateRange &&
                          searchHistoryItem['recTimeFrom'] && searchHistoryItem['recTimeTo'] && searchHistoryItem['recTimeFrom'] !== searchHistoryItem['recTimeTo']">
                        Bestelldatum: <strong>{{searchHistoryItem['recTimeFrom'] | dateTimeToDate : true }} - {{searchHistoryItem['recTimeTo'] | dateTimeToDate : true }}</strong></span>
                    <span i18n
                          *ngIf="searchHistoryItem['recTimeOption'] === individualDateRange &&
                          searchHistoryItem['recTimeFrom'] && (searchHistoryItem['recTimeFrom'] === searchHistoryItem['recTimeTo'] || !searchHistoryItem['recTimeTo'])">
                        Bestelldatum: <strong>{{searchHistoryItem['recTimeFrom'] | dateTimeToDate : true }}</strong></span>

                    <span i18n
                          *ngIf="searchHistoryItem['expiryDateOption'] !== individualDateRange && searchHistoryItem['expiryDateOption'] !== allDateRange">
                        Ablaufdatum: <strong>{{searchHistoryItem['expiryDateOption'] | dateRangeToLabel}}</strong></span>
                    <span i18n
                          *ngIf="searchHistoryItem['expiryDateOption'] === individualDateRange &&
                          searchHistoryItem['expiryDateFrom'] && searchHistoryItem['expiryDateTo'] && searchHistoryItem['expiryDateFrom'] !== searchHistoryItem['expiryDateTo']">
                        Ablaufdatum: <strong>{{searchHistoryItem['expiryDateFrom'] | dateTimeToDate : true }} - {{searchHistoryItem['expiryDateTo'] | dateTimeToDate : true }}</strong></span>
                    <span i18n
                          *ngIf="searchHistoryItem['expiryDateOption'] === individualDateRange &&
                          searchHistoryItem['expiryDateFrom'] && (searchHistoryItem['expiryDateFrom'] === searchHistoryItem['expiryDateTo'] || !searchHistoryItem['expiryDateTo']) ">
                        Ablaufdatum: <strong>{{searchHistoryItem['expiryDateFrom'] | dateTimeToDate : true }}</strong></span>

                    <span i18n *ngIf="searchHistoryItem.status && searchHistoryItem.status !== defaultFilters.status">Status: <strong>{{searchHistoryItem.status | orderStatusFilterLabel}}</strong></span>
                    <span i18n *ngIf="searchHistoryItem['producer']">Hersteller: <strong>{{searchHistoryItem['producer']}}</strong></span>
                    <span i18n *ngIf="searchHistoryItem.type">Typ: <strong>{{searchHistoryItem.type | orderTypeFilterLabel}}</strong></span>
                    <span i18n *ngIf="searchHistoryItem['pzn']">PZN: <strong>{{searchHistoryItem['pzn']}}</strong></span>
                </p>

            </ion-text>
            <ion-text class="date" i18n>Zuletzt gesucht am {{searchHistoryItem['updated_at'] | dateTimeToDate : true}}</ion-text>
            <ion-icon name="arrow-forward-outline" (click)="onSearchHistoryItemClick(searchHistoryItem, true)"></ion-icon>
        </div>
    </ng-container>

    <!-- Details View -->
    <ng-container details>

    </ng-container>

</app-expandable-sidebar>
