import gql from 'graphql-tag';

export default gql`
  query getRecentSentMessages($lastDays: Int, $nextToken: String) {
    getRecentSentMessages(lastDays: $lastDays, nextToken: $nextToken) {
      __typename
      messages {
        id
        senderId
        recipientId
        conversationId
        createdAt
      }
      nextToken
    }
  }
`;
