import { Pipe, PipeTransform } from '@angular/core';
import stc from 'string-to-color';

@Pipe({
    name: 'tagColor'
})
export class TagColorPipe implements PipeTransform {

    transform(tag: string): string {
        return stc(tag);
    }
}
