import { AppContext } from './appContext';
import { Appointment } from './appointment';
import { DeletionEntry, TicketEvent } from './backendConversation';
import { ChatUser, ExternalUser, MeaUser } from './chatUser';
import { ConversationIntent } from './conversationIntent';
import ConversationType from './conversationType';
import DecryptionStatus from './decryptionStatus';
import { LoadStatus } from './loadStatus';
import Message from './message';
import { SubmittedShoppingCart } from './shoppingCart';
import TicketStatus from './ticketStatus';

export const isGoogleConversation = (conversation: Conversation): conversation is GoogleConversation =>
  conversation.type === ConversationType.GOOGLE_BUSINESS;
export const isMeaConversation = (conversation: Conversation): conversation is MeaConversation => !conversation.type;
export const isConversationOpen = (conversation: Conversation): boolean =>
  conversation.ticketHistory?.at(-1)?.updatedStatus !== TicketStatus.CLOSED;
export const hasLastMessage = (
  conversationAndLastMessage: ConversationAndLastMessage
): conversationAndLastMessage is ConversationWithLastMessage => {
  const { conversation, lastMessage } = conversationAndLastMessage;
  const hideConversation =
    !!lastMessage?.initialMessage && !conversation.encryptedShoppingCart && !conversation.encryptedAppointment;
  return !!lastMessage && !hideConversation;
};

export interface Conversation {
  id: string;
  segments: ConversationSegment[];
  chatPartner: ChatUser;
  messagesInitializationStatus: LoadStatus;

  type?: ConversationType;
  intent?: ConversationIntent;
  appContext?: AppContext;
  ticketHistory?: TicketEvent[];
  archivedByEnduser?: boolean;
  deletionRecord?: DeletionEntry[];
  reminderNotification?: boolean;
  showReminder?: boolean;
  conversationLink?: string;
  earliestExpirationTimestamp?: number;
  shoppingCart?: SubmittedShoppingCart;
  appointment?: Appointment;
  encryptedShoppingCart?: string;
  encryptedAppointment?: string;
}

export interface MeaConversation extends Conversation {
  type: undefined;
  chatPartner: MeaUser;
}

export interface GoogleConversation extends Conversation {
  type: ConversationType.GOOGLE_BUSINESS;
  chatPartner: ExternalUser;
}

export interface ConversationSegment {
  id: string;
  createdAt: number;

  encryptedConversationPassword?: string;
  decryptedConversationPassword?: string;
  encryptedChatPartnerNickname?: string;
  decryptedChatPartnerNickname?: string;
  decryptionStatus: DecryptionStatus | 'unencrypted';

  backendUserConversationId: string;
}

export interface ConversationAndLastMessage {
  conversation: Conversation;
  lastMessage?: Message;
}

export interface ConversationWithLastMessage {
  conversation: Conversation;
  lastMessage: Message;
}
