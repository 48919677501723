import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';
import BackendUserConversation from '../../../../../essentials/types/src/backendUserConversation';
import { MeaUser } from '../../../../../essentials/types/src/chatUser';
import { ConversationAndLastMessage } from '../../../../../essentials/types/src/conversation';
import { ConversationIntent } from '../../../../../essentials/types/src/conversationIntent';
import { LoadStatus } from '../../../../../essentials/types/src/loadStatus';

import { PersistedChatState } from '../../../../../essentials/types/src/persistedChatState';

export const populateChatStateFromStorage = createAction(
  `${ActionType.Chat}: Populate chat state from storage`,
  props<{ persistedChatState: PersistedChatState }>()
);

export const initConversations = createAction(`${ActionType.Chat}: Init conversations`);

export const loadConversations = createAction(
  `${ActionType.Chat}: Load conversations`,
  props<{ conversationsLoadStatus: LoadStatus.LoadingInitial | LoadStatus.Revalidating }>()
);

export const loadConversationsFailure = createAction(`${ActionType.Chat}: Load conversations failure`);

export const setConversations = createAction(
  `${ActionType.Chat}: Set conversations`,
  props<{ conversationsAndLastMessages: ConversationAndLastMessage[] }>()
);

export const newConversation = createAction(
  `${ActionType.Chat}: New conversation`,
  props<{ conversation: BackendUserConversation }>()
);

export const newOrUpdateConversation = createAction(
  `${ActionType.Chat}: Store new or updated conversation`,
  props<{ conversation: BackendUserConversation }>()
);

export const createConversation = createAction(
  `${ActionType.Chat}: Create conversation`,
  props<{ chatPartner: MeaUser; intent: ConversationIntent }>()
);

export const setActiveConversation = createAction(
  `${ActionType.Chat}: Set Active Conversation`,
  props<{ id: string }>()
);

export const clearActiveConversation = createAction(`${ActionType.Chat}: Clear Active Conversation`);

export const setRecentConversations = createAction(
  `${ActionType.Chat}: Set recent conversations`,
  props<{ conversationsAndLastMessages: ConversationAndLastMessage[] }>()
);

export const openImagePickerOnInit = createAction(
  `${ActionType.Chat}: Open image picker on init`,
  props<{ conversationId: string }>()
);

export const stopOpeningImagePickerOnInit = createAction(
  `${ActionType.Chat}: Stop opening image picker on init`,
  props<{ conversationId: string }>()
);

export const openShopProductsModal = createAction(`${ActionType.Chat}: Open shop products modal`);

export const openVideoCallInviteModal = createAction(`${ActionType.Chat}: Open video-call invite modal`);
