<app-modal  i18n-title title="Retoure {{details()?.productName || ''}}" >
    <ng-container headerActions></ng-container>
    <ng-container content *ngIf="details()">
        <div class="content-container">
            <div class="left-content form">
                <ion-list>
                    <ion-item class="background-input" *ngIf="notesById()">
                        <ion-textarea
                                i18n-label
                                label="Interne Notiz"
                                disabled="true"
                                class="large gray"
                                [value]="notesById().note"
                        ></ion-textarea>
                    </ion-item>
                    <ion-item class="background-input" *ngIf="details().rejection && details().rejection.trim().length > 0">
                        <ion-input
                            i18n-label
                            class="full-opacity gray"
                            label="Ablehnungsgrund"
                            [value]="details().rejection"
                            disabled="true"
                        ></ion-input>
                    </ion-item>
                </ion-list>
            </div>
            <div class="right-content">
                <app-sidebar [id]="'returns-view-sidebar'" [data]="sidebar" [hasHeader]="false" [hasFooter]="false"></app-sidebar>
            </div>
        </div>
    </ng-container>
    <!-- Footer -->
    <ng-container footer *ngIf="details()">
        <ion-button
                i18n
                appTooltip
                ttp="left"
                class="button-tertiary"
                (click)="onSupportButtonClick()"
        >Kundenservice kontaktieren</ion-button>
        <ion-button i18n (click)="onRecordNoteClick()" class="button-tertiary">Interne Notiz</ion-button>
    </ng-container>
</app-modal>
