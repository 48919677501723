<app-modal
        *ngIf="infoModal"
        i18n-title
        title="Das ist neu bei Sanacorp Connect"
        [contentBG]="false"
        [contentFlex]="true">
    <ng-container titleExtension *ngIf="version !== ''">
        <span class="ion-margin-start fs-subheadline-bigger fw-text">v{{version}}</span>
    </ng-container>
    <ng-container content>
        <ion-grid class="info-modal-content ion-no-padding ion-padding-top">
            <ion-row class="ion-no-padding full-height">
                <ion-col size="7" class="info-headers ion-no-padding">
                    <div class="info-headers-menu">
                        <ng-container *ngFor="let item of infoModal?.entries; trackBy: trackBy;">
                            <div class="info-header" (click)="onHeaderClick(item)" [class.active]="currentItem.id === item.id">
                                {{ item.title }}
                            </div>
                        </ng-container>
                    </div>
                    <div class="info-headers-footer ion-padding-bottom">
                        <a
                           [routerLink]="SettingsRoutesEnum.releaseNotes"
                           (click)="onCloseButtonClick()"
                           class="inline-link-dark info-modal-release-notes-link" i18n>
                            <strong>{{pageTitles[NavigationEnum.releaseHistory]}}</strong>
                        </a>
                    </div>
                </ion-col>
                <ion-col size="17" class="info-content ion-no-padding">
                    <div class="info-content-heading">{{getActiveItem()?.title}}</div>
                    <div class="info-content-main" [innerHTML]="getActiveItem()?.content | sanitizeHtml"></div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ng-container>
    <ng-container footer>
        <ion-button class="button-tertiary"
                    (click)="setPreviousItemAsActive()"
                    [disabled]="getActiveItem().id === getLowestID()">Zurück</ion-button>
        <ion-button class="button-tertiary"
                    (click)="setNextItemAsActive()"
                    *ngIf="getActiveItem().id !== getHighestID()">Weiter</ion-button>
        <ion-button class="button-primary"
                    (click)="onCloseButtonClick()"
                    *ngIf="getActiveItem().id === getHighestID()">Verstanden</ion-button>
    </ng-container>
</app-modal>
<app-modal
    *ngIf="!infoModal"
    i18n-title
    title="Das ist neu bei Sanacorp Connect"
    [contentBG]="true"
    [contentFlex]="true">
    <ng-container titleExtension *ngIf="version !== ''">
        <span class="ion-margin-start fs-subheadline-bigger fw-text">v{{version}}</span>
    </ng-container>
    <ng-container content>
        <!--Show the message "Es gibt aktuell nichts Neues" if the data is empty or still loading-->
        <div class="no-results-small" style="align-items: center;">
            <ion-col size="24" class="ion-padding-start ion-padding-end">
                <ion-text>
                    <h1 i18n>Es gibt aktuell nichts Neues</h1>
                </ion-text>
            </ion-col>
        </div>
    </ng-container>
    <ng-container footer>
        <ion-button class="button-primary" (click)="onCloseButtonClick()">Verstanden</ion-button>
    </ng-container>
</app-modal>