import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Subscription } from 'apollo-angular';
import {
    UserDataChangedKeys,
    PharmacyDataChangedKeys,
    GlobalDataChangedKeys,
    DataChangedKeys,
    PharmacyDataChangedCountKeys,
    PharmacyUserDataChangedKeys,
    UserDataChangedCountKeys,
    PharmacyUserDataChangedCountKeys
} from '../../../enums/data-changed-keys.enum';


const dataChangedPharmacy = gql`
    subscription getDataPharmacyChanged {
        dataChangePharmacy(limit:1) {
            ${Object.values(PharmacyDataChangedKeys).join( )}
            ${Object.values(PharmacyDataChangedCountKeys).join( )}
        }
    }`;

const dataChangedUser = gql`
    subscription getDataUserChanged {
        dataChangeUser(limit:1) {
            ${Object.values(UserDataChangedKeys).map(key => (
                key === UserDataChangedKeys.readNotificationChanged ? `${DataChangedKeys.readNotificationChanged}: ${DataChangedKeys.notificationChanged}` : key
            )).join( )}
            ${Object.values(UserDataChangedCountKeys).join( )}
        }
    }`;

const dataChangedPharmacyUser = gql`
    subscription getDataPharmacyUserChanged {
        dataChangePharmacyUser(limit:1) {
            ${Object.values(PharmacyUserDataChangedKeys).join( )}
            ${Object.values(PharmacyUserDataChangedCountKeys).join( )}
        }
    }`;

const dataChangedGlobal = gql`
    subscription getDataGlobalChanged {
        dataChangeGlobal(limit:1) {
            ${Object.values(GlobalDataChangedKeys).join( )}
        }
    }`;



@Injectable()
export class GetDataChangedPharmacySubscription extends Subscription {
    document = dataChangedPharmacy;
}

@Injectable()
export class GetDataChangedUserSubscription extends Subscription {
    document = dataChangedUser;
}

@Injectable()
export class GetDataChangedPharmacyUserSubscription extends Subscription {
    document = dataChangedPharmacyUser;
}

@Injectable()
export class GetDataChangedGlobalSubscription extends Subscription {
    document = dataChangedGlobal;
}

export const AllInformationSubscriptions = [
    dataChangedPharmacy,
    dataChangedUser,
    dataChangedGlobal,
    dataChangedPharmacyUser
];
