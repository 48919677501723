import { BulkActionCodeEnum } from '../enums/bulk-action.enum';

export const OrderBulkActionConfig: Array<{
    code: BulkActionCodeEnum,
    label: string,
    color: string,
    title?: string,
    tooltipKey?: string,
}> = [
    {code: BulkActionCodeEnum.DEFAULT,
        label: 'AUSWÄHLEN',
        color: 'order-bulk-action-default',
    },
    {code: BulkActionCodeEnum.CANCEL,
        label: 'Stornieren',
        color: 'order-bulk-action-cancel',
        title: 'Aufträge stornieren',
        tooltipKey: 'bulk_cancel_alert',
    },
    {code: BulkActionCodeEnum.EXTEND,
        label: 'Verlängern',
        color: 'order-bulk-action-extend',
        title: 'Aufträge verlängern',
        tooltipKey: 'bulk_extend_alert',
    },
    {code: BulkActionCodeEnum.DISPO_ACKNOWLEDGE,
        label: 'Ware angekommen',
        color: 'order-bulk-action-arrived',
        title: 'Aufträge als angekommen markieren',
        tooltipKey: 'bulk_dispo_acknowledge_alert',
    },
];
