import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import isEqual from 'lodash-es/isEqual';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  firstValueFrom,
  map,
  Observable,
  shareReplay,
} from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { Dictionary } from 'ts-essentials';
import { AppsyncPharmacyPhotoService } from '../../../common/resources/src/services/appsync/appsync-pharmacy-photo.service';
import { UnreadMessagesCountService } from '../../../common/resources/src/services/unread-messages-count.service';
import {
  pipeConversationsAndLastMessages,
  selectActiveConversation,
  selectActiveConversationId,
  selectConversationsLoadStatus,
} from '../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { CommonState } from '../../../store/src/common-store/common.state';
import { selectUser } from '../../../store/src/common-store/user-store/selectors/user.selectors';
import { ChatPartnerMetadata } from '../../../essentials/types/src/chatPartnerMetadata';
import { isMeaUser, isPharmacyChatUser, MeaUser } from '../../../essentials/types/src/chatUser';
import { Conversation, ConversationWithLastMessage, hasLastMessage } from '../../../essentials/types/src/conversation';
import { LoadStatus } from '../../../essentials/types/src/loadStatus';
import { Authors } from '../../../essentials/types/src/messageAuthor';
import { ChatPartnerMetadataServiceInterface } from '../../../essentials/types/src/service-interfaces/chat-partner-metadata.service.interface';
import { UserType } from '../../../essentials/types/src/userType';
import { ChatPartnerMetadataUtil } from '../../../essentials/util/src/chat-partner-metadata.util';
import { ChatPartnerWithChats, ChatPartnerWithMetadata } from '../types/chatPartner';
import { ChatConversationsUtil } from '../util/chat-conversations.util';
import { ChatPartnerDecryptionService } from './chat-partner-decryption.service';

@Injectable({
  providedIn: 'root',
})
export class ChatConversationsService {
  activeConversationIdUser$ = new BehaviorSubject<string | undefined>(undefined);
  activeConversationIdPharmacy$ = new BehaviorSubject<string | undefined>(undefined);
  conversationsLoadStatusError$ = this.store.select(selectConversationsLoadStatus).pipe(
    map((conversationsLoadStatus) => conversationsLoadStatus === LoadStatus.Error),
    distinctUntilChanged()
  );

  conversationsWithLastMessage$: Observable<ConversationWithLastMessage[]> = this.store.pipe(
    pipeConversationsAndLastMessages,
    map((conversationsAndLastMessages) => conversationsAndLastMessages.filter(hasLastMessage))
  );

  pharmacyConversationsWithLastMessage$: Observable<ConversationWithLastMessage[]> =
    this.conversationsWithLastMessage$.pipe(
      map((allConversationsWithLastMessage) =>
        allConversationsWithLastMessage.filter(({ conversation }) => isPharmacyChatUser(conversation.chatPartner))
      ),
      shareReplay(1)
    );

  decryptedChatPartnerMetadataDictionary$: Observable<Dictionary<ChatPartnerMetadata>> =
    this.chatPartnerDecryptionService.decryptedChatPartnerMetadataDictionary$;

  activePharmacyChatPartner$: Observable<ChatPartnerWithChats | undefined> = combineLatest([
    this.store.pipe(pipeConversationsAndLastMessages),
    this.activeConversationIdPharmacy$,
    this.decryptedChatPartnerMetadataDictionary$,
  ]).pipe(
    map(([conversationsAndLastMessages, activeConversationIdPharmacy, decryptedChatPartnerMetadataDictionary]) => {
      const conversationAndLastMessage = conversationsAndLastMessages.find(
        ({ conversation }) => conversation.id === activeConversationIdPharmacy
      );
      if (!conversationAndLastMessage) {
        return undefined;
      }
      const chatPartnerId =
        conversationAndLastMessage.conversation &&
        ChatPartnerMetadataUtil.getChatPartnerIdAsPharmacy(conversationAndLastMessage.conversation);
      const chatPartner = conversationAndLastMessage.conversation.chatPartner;
      const chatPartnerName = (chatPartner as MeaUser).pharmacy?.name || 'Unbekannte Apotheke';
      const chatPartnerNickname = decryptedChatPartnerMetadataDictionary[chatPartnerId]?.decryptedChatPartnerNickname;
      return {
        conversations: [conversationAndLastMessage],
        chatPartner,
        chatPartnerName,
        chatPartnerNickname,
        chatPartnerDisplayName: ChatConversationsUtil.getDisplayName(chatPartnerName, chatPartnerNickname),
      };
    }),
    shareReplay(1)
  );

  pharmacyChatPartners$: Observable<ChatPartnerWithChats[]> = combineLatest([
    this.pharmacyConversationsWithLastMessage$,
    this.activePharmacyChatPartner$,
    this.decryptedChatPartnerMetadataDictionary$,
  ]).pipe(
    map(([pharmacyConversationsWithLastMessage, activePharmacyChatPartner, decryptedChatPartnerMetadataDictionary]) => {
      const sortedArray = [...pharmacyConversationsWithLastMessage].sort(
        (a, b) => b.lastMessage.createdAt - a.lastMessage.createdAt
      );
      const pharmacyChatPartners = sortedArray.map((conversationWithLastMessage) => {
        const chatPartnerId = ChatPartnerMetadataUtil.getChatPartnerIdAsPharmacy(
          conversationWithLastMessage.conversation
        );
        const chatPartner = conversationWithLastMessage.conversation.chatPartner;
        const chatPartnerName = (chatPartner as MeaUser).pharmacy?.name || 'Unbekannte Apotheke';
        const chatPartnerNickname = decryptedChatPartnerMetadataDictionary[chatPartnerId]?.decryptedChatPartnerNickname;
        return {
          conversations: [conversationWithLastMessage],
          chatPartner,
          chatPartnerName,
          chatPartnerNickname,
          chatPartnerDisplayName: ChatConversationsUtil.getDisplayName(chatPartnerName, chatPartnerNickname),
        };
      });
      if (
        activePharmacyChatPartner &&
        !pharmacyChatPartners.find(
          ({ chatPartner }) =>
            (chatPartner as MeaUser).cognitoId === (activePharmacyChatPartner.chatPartner as MeaUser).cognitoId
        )
      ) {
        return [activePharmacyChatPartner, ...pharmacyChatPartners];
      }
      return pharmacyChatPartners;
    }),
    shareReplay(1)
  );

  hasUnreadPharmacyMessage$: Observable<boolean> = combineLatest([
    this.pharmacyConversationsWithLastMessage$,
    this.unreadMessagesCountService.unreadMessageCountPerConversation$,
  ]).pipe(
    map(([pharmacyConversationsWithLastMessage, unreadMessageCountPerConversation]) => {
      for (const { conversation } of pharmacyConversationsWithLastMessage) {
        if (unreadMessageCountPerConversation[conversation.id]) {
          return true;
        }
      }
      return false;
    }),
    shareReplay(1)
  );

  enduserConversationsWithLastMessage$: Observable<ConversationWithLastMessage[]> =
    this.conversationsWithLastMessage$.pipe(
      map((conversationsWithLastMessage) =>
        conversationsWithLastMessage.filter(({ conversation }) => !isPharmacyChatUser(conversation.chatPartner))
      ),
      shareReplay(1)
    );

  hasUnreadEnduserMessage$: Observable<boolean> = combineLatest([
    this.enduserConversationsWithLastMessage$,
    this.unreadMessagesCountService.unreadMessageCountPerConversation$,
  ]).pipe(
    map(([enduserConversationsWithLastMessage, unreadMessageCountPerConversation]) => {
      for (const { conversation } of enduserConversationsWithLastMessage) {
        if (unreadMessageCountPerConversation[conversation.id]) {
          return true;
        }
      }
      return false;
    })
  );

  enduserChatPartnersDictionary$: Observable<Dictionary<ChatPartnerWithMetadata>> = combineLatest([
    this.enduserConversationsWithLastMessage$,
    this.decryptedChatPartnerMetadataDictionary$,
  ]).pipe(
    map(([conversationsWithLastMessage, decryptedChatPartnerMetadataDictionary]) => {
      if (!conversationsWithLastMessage || conversationsWithLastMessage.length === 0) {
        return {};
      }
      const conversationsByChatPartners =
        ChatConversationsUtil.splitConversationsIntoChatPartners(conversationsWithLastMessage);
      return ChatConversationsUtil.addMetadataToEnduserChatPartners(
        conversationsByChatPartners,
        decryptedChatPartnerMetadataDictionary
      );
    }),
    shareReplay(1)
  );

  activeEnduserChatPartnerId$: Observable<string | undefined> = combineLatest([
    this.enduserChatPartnersDictionary$,
    this.store.select(selectActiveConversationId),
  ]).pipe(
    map(([enduserChatPartnersDictionary, activeConversationId]) => {
      if (activeConversationId) {
        return ChatConversationsUtil.getActiveChatPartnerId(enduserChatPartnersDictionary, activeConversationId);
      }
      return undefined;
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  activeEnduserChatPartner$: Observable<(ChatPartnerWithMetadata & { conversationArray: Conversation[] }) | undefined> =
    combineLatest([this.enduserChatPartnersDictionary$, this.activeEnduserChatPartnerId$]).pipe(
      map(([enduserChatPartnersDictionary, activeChatPartnerId]) => {
        const chatPartnerWithMetadata: ChatPartnerWithMetadata | undefined = activeChatPartnerId
          ? enduserChatPartnersDictionary[activeChatPartnerId]
          : undefined;
        return (
          chatPartnerWithMetadata && {
            ...chatPartnerWithMetadata,
            conversationArray: chatPartnerWithMetadata.conversations.map(({ conversation }) => conversation),
          }
        );
      }),
      shareReplay(1)
    );

  enduserChatPartners$: Observable<{
    openChatPartnerConversations: ChatPartnerWithMetadata[];
    closedChatPartnerConversations: ChatPartnerWithMetadata[];
  }> = this.enduserChatPartnersDictionary$.pipe(
    map((enduserChatPartnersDictionary) => {
      return ChatConversationsUtil.splitChatPartnerConversationsIntoCategoriesAndSortByTimestamp(
        enduserChatPartnersDictionary
      );
    }),
    shareReplay(1)
  );

  private activePharmacyChatPartnerName$: Observable<{ cognitoId?: string; name: string } | undefined> =
    this.activePharmacyChatPartner$.pipe(
      map((chatPartner) =>
        chatPartner
          ? {
              name: chatPartner.chatPartnerName,
              cognitoId: isMeaUser(chatPartner.chatPartner) ? chatPartner.chatPartner.cognitoId : undefined,
            }
          : undefined
      )
    );

  private activeEnduserChatPartnerName$: Observable<{ name: string } | undefined> = this.activeEnduserChatPartner$.pipe(
    map((chatPartner) => (chatPartner ? { name: chatPartner.chatPartnerName } : undefined))
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  messageAuthors$: Observable<Authors> = combineLatest([
    this.activePharmacyChatPartnerName$,
    this.activeEnduserChatPartnerName$,
    this.store.select(selectUser).pipe(map((user) => user?.pharmacy?.name || 'Apotheke')),
  ]).pipe(
    distinctUntilChanged(isEqual),
    switchMap(async ([pharmacyChatPartner, enduserChatPartner, pharmacyName]): Promise<Authors> => {
      const pharmacyAuthor = { name: pharmacyName };
      if (pharmacyChatPartner) {
        const cognitoId = pharmacyChatPartner.cognitoId;
        if (!cognitoId) {
          return { user: { name: pharmacyChatPartner.name }, pharmacy: pharmacyAuthor };
        }
        let cachedLogo = await firstValueFrom(this.appsyncPharmacyPhotoService.getLoadedPharmacyLogo(cognitoId));
        if (!cachedLogo) {
          await this.appsyncPharmacyPhotoService.loadPharmacyLogo(cognitoId, pharmacyChatPartner.sanacorpCustomerId);
          cachedLogo = await firstValueFrom(this.appsyncPharmacyPhotoService.getLoadedPharmacyLogo(cognitoId));
        }
        return {
          user: { name: pharmacyChatPartner.name, image: cachedLogo?.logo },
          pharmacy: pharmacyAuthor,
        };
      } else if (enduserChatPartner) {
        return { user: { name: enduserChatPartner.name, image: true }, pharmacy: pharmacyAuthor };
      }
      return { user: { name: 'Unbekannter Nutzer' }, pharmacy: pharmacyAuthor };
    })
  );

  constructor(
    @Inject('ChatPartnerMetadataService') private chatPartnerMetadataService: ChatPartnerMetadataServiceInterface,
    private appsyncPharmacyPhotoService: AppsyncPharmacyPhotoService,
    private chatPartnerDecryptionService: ChatPartnerDecryptionService,
    private store: Store<CommonState>,
    private unreadMessagesCountService: UnreadMessagesCountService
  ) {
    this.store.select(selectActiveConversation).subscribe((conversation) => {
      if (!conversation) {
        this.activeConversationIdUser$.next(undefined);
        this.activeConversationIdPharmacy$.next(undefined);
      } else {
        if (conversation.chatPartner.userType === UserType.PharmacyChatUser) {
          this.activeConversationIdPharmacy$.next(conversation.id);
        } else {
          this.activeConversationIdUser$.next(conversation.id);
        }
      }
    });
  }

  async getConversationIdForChatPartnerTab(isPharmaciesTab: boolean): Promise<string | undefined> {
    let conversationId: string | undefined;
    if (isPharmaciesTab) {
      conversationId = await this.getIdOfPharmacyConversationToOpen();
    } else {
      conversationId = await firstValueFrom(this.activeConversationIdUser$);
    }
    return conversationId;
  }

  async getLoadedChatPartnerName(cognitoId: string) {
    const chatPartnerId = ChatPartnerMetadataUtil.getUserId({ cognitoId });
    const loadedChatPartner = await firstValueFrom(
      combineLatest([
        this.enduserChatPartnersDictionary$.pipe(map((dictionary) => dictionary[chatPartnerId])),
        this.chatPartnerMetadataService.chatPartnerLoadStatus$,
      ]).pipe(
        filter(
          ([chatPartner, loadStatus]) =>
            [LoadStatus.UpToDate, LoadStatus.Error].includes(loadStatus) && !chatPartner?.isDecryptingChatPartnerName
        ),
        map(([chatPartner]) => chatPartner)
      )
    );
    return loadedChatPartner?.chatPartnerName;
  }

  private async getIdOfPharmacyConversationToOpen() {
    return (
      (await firstValueFrom(this.activeConversationIdPharmacy$)) ||
      firstValueFrom(
        this.pharmacyChatPartners$.pipe(
          map((pharmacyChatPartners) => pharmacyChatPartners[0]?.conversations[0]?.conversation.id)
        )
      )
    );
  }
}
