<div class="default-loader with-bg-light is-overlay" [hidden]="(!isLoading.data && !isLoading.count) || searchInProgress">
    <ion-spinner name="crescent" color="primary"></ion-spinner>
</div>

<div class="no-results" *ngIf="!isLoading.data && !isLoading.count" [hidden]="count > 0">
    <ion-text>
        <h1 *ngIf="!statusErrorMessage" i18n>Es konnten keine Treffer gefunden werden. <br />Verändern Sie ggf. die Suchkriterien.</h1>
        <h1 *ngIf="statusErrorMessage" i18n>{{statusErrorMessage}}</h1>
        <div class="filter-text" *ngIf="noResultInformation">
            <app-badge *ngFor="let filter of noResultInformation.items;trackBy:trackBy" [badgeType]="badgeType" code='{{filter.label}} &bdquo;{{filter.value}}&ldquo;'></app-badge>
            <div class="button-container">
                <app-date-selection-widget *ngIf="noResultInformation.hasDateFilter && noResultDateInformation"
                                           [title]="noResultDateInformation.label"
                                           [dateRangeOptions]="noResultDateInformation.dateRangeOption"
                                           [selectedDateRangeId]="filters[noResultDateInformation.id + 'Option']"
                                           [defaultFromDate]="filters[noResultDateInformation.id + 'From'] | formatDateTimeToMoment"
                                           [defaultToDate]="filters[noResultDateInformation.id + 'To'] | formatDateTimeToMoment"
                                           [displayOnlyFutureDates]="false"
                                           [displayButtonInsteadOfDateRange]="true"
                                           [displayOnlyPastDates]="true"
                                           [displayIncludingToday]="true"
                                           (datesEvent)="updateDateFilter($event, noResultDateInformation.id)"
                ></app-date-selection-widget>
                <ion-button *ngIf="noResultInformation.hasSearchModal" (click)="openSearchModal(true)">Filterkriterien ändern</ion-button>
            </div>
        </div>
    </ion-text>
</div>
<div class="search-in-progress" [hidden]="!searchInProgress">
    <div>
        <ion-spinner color="primary" name="crescent"></ion-spinner>
        <ion-text><h1 i18n>Suche wird durchgeführt, dies dauert einen Moment. <br /> Bitte warten...</h1></ion-text>
    </div>
</div>
