import { Subscription } from 'rxjs';

/**
 * unsubscribe all subscriptions
 *
 * @param subscribers - Array of subscriptions to unsubscribe
 */
export const unsubscribeAll = (subscribers: Array<Subscription>) => {
    if (!Array.isArray(subscribers) || subscribers?.length === 0) {
        return;
    }
    for (const subscriber of subscribers) {
        unsubscribe(subscriber);
    }
};

/**
 * unsubscribe one subscription
 *
 * @param subscriber - The subscription to unsubscribe
 */
export const unsubscribe = (subscriber: Subscription) => {
    if (subscriber && typeof subscriber?.unsubscribe === 'function'
    ) {
        subscriber.unsubscribe();
    }
};
