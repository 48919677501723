<ion-grid>
    <ion-row *ngIf="!showDetails">
        <ion-col size="8" class="ion-align-items-center ion-no-padding" [class.display-flex]="isLoading">
            <div class="chart-wrapper" >
                <div class="donut-chart" #donutChart id="{{uniqueID}}"></div>
                <ion-spinner *ngIf="isLoading" name="crescent"></ion-spinner>
            </div>
        </ion-col>
        <ion-col size="16" class="ion-no-padding" *ngIf="!isLoading && chosenItem">
            <ion-button fill="clear" (click)="presentTypePopover($event)">{{chosenItem['statisticType'] | statisticTypeLabel}}</ion-button>
            <ion-label class="ion-no-margin ion-no-padding">{{chosenItem.value > 0 ? "+" : chosenItem.value < 0 ? "-" : "~"}} {{chosenItem.value || 0}} %</ion-label>
            <ion-text color="medium" class="ion-no-margin ion-no-padding">{{chosenItem['statisticType'] | statisticTypeNote}}</ion-text>
        </ion-col>
        <ion-col size="16" class="ion-no-padding" *ngIf="isLoading">
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
    </ion-row>
    <ion-row *ngIf="!isLoading && showDetails">
        <ion-col class="ion-no-padding" *ngFor="let item of data">
            <ion-text>{{item.statisticType | statisticTypeLabel}}</ion-text>
            <ion-text class="ion-no-margin ion-no-padding">{{item.value > 0 ? "+" : "-"}} {{item.value}} %</ion-text>
            <ion-text color="medium" class="ion-no-margin ion-no-padding">{{item.statisticType | statisticTypeNote}}</ion-text>
        </ion-col>
    </ion-row>
</ion-grid>
