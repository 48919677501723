import { Component, Input } from '@angular/core';
import { StatisticDevelopmentType } from '../../enums/statistics-type.enum';

@Component({
    selector: 'app-icon-circle',
    templateUrl: './icon-circle.component.html',
    styleUrls: ['./icon-circle.component.scss'],
})
export class IconCircleComponent {

    @Input() iconName = 'alert-outline';
    @Input() type = StatisticDevelopmentType.neutral;

    constructor() {
    }
}
