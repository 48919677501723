export enum MeaRoutesEnum {
    dynamicContent = 'dynamic-content',
    cooperationPartner = 'cooperation-partner',
    info = 'info',
    shop = 'shop',
    chat = 'chat',
    offers = 'offers',
    downloads = 'downloads',
}

export enum FullMeaRoutesEnum {
    dynamicContent = '/app/mea/dynamic-content',
    cooperationPartner = '/app/mea/cooperation-partner',
    info = '/app/mea/info',
    shop = '/app/mea/shop',
    chat = '/app/mea/chat',
    offers = '/app/mea/offers',
    downloads = '/app/mea/downloads',
}
