import {
    NotificationsSettingInterface,
    NotificationSettingsConfigInterface,
    DependsOnSettingsConfigInterface, CalendarSettingInterface, CalendarSettingsConfigInterface
} from '../interfaces/settings.interface';

const PATH_SEPARATOR = '/';

/**
 * checks if setting is read only
 *
 * @param currentSetting : string
 * @param settings : NotificationsSettingInterface|CalendarSettingInterface
 * @param settingsConfig : NotificationSettingsConfigInterface|CalendarSettingsConfigInterface
 */
export const isSettingReadOnly = (
    currentSetting: string,
    settings: NotificationsSettingInterface|CalendarSettingInterface,
    settingsConfig: NotificationSettingsConfigInterface|CalendarSettingsConfigInterface
) => {
    const currentElementSettingsConfig = getElementByPath(settingsConfig, getPathAsArray(currentSetting));
    const isElementReadOnly = currentElementSettingsConfig['readonly'];

    if (currentElementSettingsConfig['depends'] && Array.isArray(currentElementSettingsConfig['depends']) && !isElementReadOnly) {
        if (checkDependingElements(settings, currentElementSettingsConfig['depends'])) {
            return true;
        }
    }
    return isElementReadOnly;

};

export const getElementByPath = (notifications, currentSettingArray) => {
    let currentElement = notifications;
    currentSettingArray.forEach((element) => {
        currentElement = currentElement[element];
    });
    return currentElement;
};

export const checkDependingElements = (settings, dependingElements: Array<DependsOnSettingsConfigInterface>) => {
    let isReadOnly = false;
    dependingElements.forEach((element) => {
        const dependingElement = getElementByPath(settings, getPathAsArray(element.path));
        if (dependingElement !== element.value) {
            isReadOnly = true;
            return;
        }
    });
    return isReadOnly;
};

export const getPathAsArray = (element) => {
    return element.split(PATH_SEPARATOR);
};

export const getPathAsString = (elementArray) => {
    return elementArray.join(PATH_SEPARATOR);
};
