import { Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
@Component({
    selector: 'app-full-page-loader',
    templateUrl: './full-page-loader.component.html',
    styleUrls: ['full-page-loader.component.scss'],
    animations: [
        trigger('fullPageLoaderVisibility', [
            state('visible', style({
                opacity: '1'
            })),
            state('invisible', style({
                opacity: '0'
            })),
            transition('visible=>invisible', animate('100ms')),
            transition('invisible=>visible', animate('100ms'))
        ])
    ]
})
export class FullPageLoaderComponent {
    @Input() isFullPageLoaderActive = false;
    @Input() fullPageLoaderText = '';
}
