<swiper-container
        *ngIf="!isLoading"
        [modules]="swiperModules"
        [freeMode]="true"
        [slidesPerView]="'auto'"
        class="slider-nav-slides"
        #slideNav>
    <swiper-slide>
        <div class="slider-nav">
            <div *ngFor="let element of elements; index as i"
                 [class.selected]="activeIndex === i"
                 id="{{sectionIdPrefix}}{{i}}">
                <ion-text (click)="onSelectTab(i)" i18n>{{element[key]}}</ion-text>
            </div>

        </div>
        <div class="slider-nav-divider">
            <div class="active-element-indicator"
                 [style.width]="activeTabWidth"
                 [style.transform]="'translate(' + activeTabLeft + ', 0)'"></div>
        </div>
    </swiper-slide>
</swiper-container>
