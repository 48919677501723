import { Validators } from '@angular/forms';

export function PasswordValidator() {
    return [
        Validators.required,
        Validators.maxLength(40),
        Validators.minLength(10),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[+`~!@#$%^&*()_\\-+={}[\\]\\\\|:;"\'<>,\\.\\?\\/]).{1,}$'),
    ];
}

export function PINValidator() {
    return [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
        Validators.pattern('^\\d+$')
    ];
}

export function IntValidator() {
    return [
        Validators.pattern('^(0|[1-9][0-9]*)$'),
        // int has a max. value of 2147483647
        Validators.max(2000000000)
    ];
}

export function CustomerPositionValidator() {
    return [
        Validators.required
    ];
}

export function CustomerRolesValidator() {
    return [
        Validators.required
    ];
}

export function TokenValidator() {
    return [
        Validators.required
    ];
}
