import {
    DeliveryReceiptOrderType,
    DeliveryReceiptOrderTypeFormatted,
    DocumentStatus,
    DocumentSubType,
    DocumentType
} from '../enums/documents.enum';
import {
    DeliveryReceiptOrderTypeFilterInterface,
    DeliveryReceiptOrderTypeInterface,
    DocumentSubTypeInterface,
    DeliveryReceiptTypeInterface,
    DocumentStatusInterface
} from '../interfaces/document.interface';

export const DeliveryReceiptOrderTypeConfig: DeliveryReceiptOrderTypeInterface[]  = [
    {
        id: DeliveryReceiptOrderType.AO,
        title: 'Apo. Orga.mittel',
        shortcode: 'Apo. Orga.mittel',
        sortOrder: 10
    },
    {
        id: DeliveryReceiptOrderType.AP,
        title: 'Auftrag Privatsrt.',
        shortcode: 'Auftrag Privatsrt.',
        sortOrder: 20
    },
    {
        id: DeliveryReceiptOrderType.N_PLUS,
        title: 'NA Plus',
        shortcode: 'NA Plus',
        sortOrder: 30
    },
    {
        id: DeliveryReceiptOrderType.NB,
        title: 'Nachberechnung',
        shortcode: 'Nachberechnung',
        sortOrder: 40
    },
    {
        id: DeliveryReceiptOrderType.WE,
        title: 'Weiterberechnung',
        shortcode: 'Weiterberechnung',
        sortOrder: 50
    },
    {
        id: DeliveryReceiptOrderType.EA,
        title: 'Einrichtungsauftrag',
        shortcode: 'Einrichtungsauftrag',
        sortOrder: 60
    },
    {
        id: DeliveryReceiptOrderType.NA,
        title: 'Normalauftrag',
        shortcode: 'Normalauftrag',
        sortOrder: 70
    },
    {
        id: DeliveryReceiptOrderType.UE,
        title: 'Überweisungsauftrag',
        shortcode: 'Überweisungsauftrag',
        sortOrder: 80
    },
    {
        id: DeliveryReceiptOrderType.GH,
        title: 'Hausintern',
        shortcode: 'Hausintern',
        sortOrder: 90
    },
    {
        id: DeliveryReceiptOrderType.GK,
        title: 'Gutschrift',
        shortcode: 'Gutschrift',
        sortOrder: 100
    },
].sort((a, b) => a.sortOrder - b.sortOrder);

export const DeliveryReceiptOrderTypeFilterConfig: DeliveryReceiptOrderTypeFilterInterface[] = [
    {
        id: DeliveryReceiptOrderTypeFormatted.ALL,
        title: 'Alle',
        shortcode: 'Alle',
        sortOrder: 1
    },
    {
        id: DeliveryReceiptOrderTypeFormatted.SO,
        title: 'Sonstige',
        shortcode: 'SO',
        sortOrder: 2
    },
    {
        id: DeliveryReceiptOrderTypeFormatted.NA,
        title: 'Normalauftrag',
        shortcode: 'NA',
        sortOrder: 3
    },
    {
        id: DeliveryReceiptOrderTypeFormatted.UE,
        title: 'Überweiser-Auftrag',
        shortcode: 'UE',
        sortOrder: 4
    },
];

/**
 * Formats orderType to show only specific types
 * @param orderType - Type of order
 */
export const formatOrderTypes = (orderType: DeliveryReceiptOrderType): DeliveryReceiptOrderTypeFormatted => {
    switch (orderType) {
        case DeliveryReceiptOrderType.UE:
            return DeliveryReceiptOrderTypeFormatted.UE;
        case DeliveryReceiptOrderType.NA:
            return DeliveryReceiptOrderTypeFormatted.NA;
        default:
            return DeliveryReceiptOrderTypeFormatted.SO;
    }
};
/**
 * Gets filter for orderType
 * @param orderType - Type of order
 */
export const orderTypeFilter = (orderType: DeliveryReceiptOrderTypeFormatted): object => {
    switch (orderType) {
        case DeliveryReceiptOrderTypeFormatted.UE:
        case DeliveryReceiptOrderTypeFormatted.NA:
            return {_eq: orderType};
        default:
            return {_nin: [DeliveryReceiptOrderTypeFormatted.UE, DeliveryReceiptOrderTypeFormatted.NA]};
    }
};

export const DeliveryReceiptStatusConfig: DocumentStatusInterface[] = [
    {
        id: DocumentStatus.CANCELLED,
        color: 'delivery-status-cancelled',
        title: 'Storniert',
        shortcode: 'Storniert',
        sortOrder: 1
    }
];

export const DeliveryReceiptSubTypeAllConfig: DocumentSubTypeInterface = {
    id: DocumentSubType.ALL,
    color: '',
    title: 'Alle',
    shortcode: 'Alle',
    sortOrder: 1
};

export const DeliveryReceiptSubTypeConfig: DocumentSubTypeInterface[]  = [
    {
        id: DocumentSubType.CHARBLUT,
        color: 'delivery-receipt-subtype-charblut',
        title: 'Transfusionsgesetz',
        shortcode: 'Transfusion',
        sortOrder: 10
    },
    {
        id: DocumentSubType.CHARTIER,
        color: 'delivery-receipt-subtype-chartier',
        title: 'Tierarzneimittel',
        shortcode: 'Tier-AM',
        sortOrder: 20
    },
    {
        id: DocumentSubType.CHARDOKU,
        color: 'delivery-receipt-subtype-chardoku',
        title: 'Sonstige',
        shortcode: 'Sonstige',
        sortOrder: 30
    },
    {
        id: DocumentSubType.CHARSECU,
        color: 'delivery-receipt-subtype-charsecu',
        title: 'SecurPharm Dokumentation',
        shortcode: 'SecurPharm',
        sortOrder: 40
    },
].sort((a, b) => a.sortOrder - b.sortOrder);

export const DeliveryReceiptSubTypesWithAll = [DeliveryReceiptSubTypeAllConfig, ...DeliveryReceiptSubTypeConfig];

export const DeliveryReceiptTypeAllConfig: DeliveryReceiptTypeInterface = {
    id: DocumentType.ALL,
    title: 'Alle',
    titlePlural: 'Alle',
    shortcode: 'Alle',
};

export const DeliveryReceiptTypeConfig: DeliveryReceiptTypeInterface[]  = [
    {
        id: DocumentType.LS,
        title: 'Lieferschein',
        titlePlural: 'Lieferscheine',
        shortcode: 'LS'
    },
    {
        id: DocumentType.CH,
        title: 'Chargenbeleg',
        titlePlural: 'Chargenbelege',
        shortcode: 'CH'
    },
    {
        id: DocumentType.RE,
        title: 'Rechnung',
        titlePlural: 'Rechnungen',
        shortcode: 'RE'
    },
];

export const DeliveryReceiptTypesWithAll = [DeliveryReceiptTypeAllConfig, ...DeliveryReceiptTypeConfig];


