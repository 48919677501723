import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

import { ProfileSettingInterface } from '../../interfaces/settings.interface';

@Injectable({
    providedIn: 'root',
})
export class ProfileSettingsVar {
    private _profileSettings = signal<ProfileSettingInterface>({
        user: {
            id: '',
            prefix: '',
            title: '',
            firstName: '',
            lastName: '',
            email: '',
            position: '',
            isPharmacyOwner: null,
            userAccessRights: [],
            chatRoles: [],
        },
        customerNumber: '',
        lastAuth: {
            lastAuthDateTime: '',
        },
        contactNames: [],
    });
    public profileSettings = this._profileSettings.asReadonly();
    public profileSettingsObservable = toObservable(this.profileSettings);

    set(profile: ProfileSettingInterface) {
        this._profileSettings.set(profile);
    }

    async setContactNames(contactNames: Array<string>) {
        this._profileSettings.update((oldProfileData) => ({
            ...oldProfileData,
            contactNames,
        }));
    }

    async setNewProfileData(newProfileData: ProfileSettingInterface) {
        this._profileSettings.update((oldProfileData) => ({
            ...oldProfileData,
            ...newProfileData,
        }));
    }
}
