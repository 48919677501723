import { Pipe, PipeTransform } from '@angular/core';
import { CommunicationZoneInterface } from '../interfaces/communication-zone.interface';
import { CommunicationZoneReferenceTypeEnum } from '../enums/communication-zone.enum';

@Pipe({
    name: 'communicationZoneHasReference'
})
export class CommunicationZoneHasReferencePipe implements PipeTransform {

    transform(communicationZoneData: CommunicationZoneInterface): boolean {
        if(!communicationZoneData || !communicationZoneData.payload || !communicationZoneData.payload['referenceType']) {
            return !!communicationZoneData.orderId;
        }

        return communicationZoneData.payload['referenceType'] === CommunicationZoneReferenceTypeEnum.RETURNS;
    }
}
