import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
    name: 'datePickerToDate'
})
export class DatePickerToDatePipe implements PipeTransform {
    transform(value: { day: number, month: number, year: number }, compareDate: Date): Date|boolean {
        if(compareDate) {
            // dayjs - diff() and isSame() has a bug, so we need to compare strings
            // simply try dayjs(`2023-09-31`).diff(dayjs('2023-10-01'), 'days');
            return `${value.year}-${value.month}-${value.day}` === `${compareDate.getFullYear()}-${compareDate.getMonth()}-${compareDate.getDate()}`;
        }

        return dayjs(`${value.year}-${value.month}-${value.day}`).toDate();
    }
}
