import { Injectable } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { Storage } from '@ionic/storage-angular';
import { firstValueFrom, of } from 'rxjs';
import { mergeMap, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage$ = of(undefined).pipe(
    mergeMap(() => this.init()),
    shareReplay(1)
  );

  constructor(protected ionicStorage: Storage) {}

  public async set(key: string, value: any): Promise<void> {
    const storage = await this.getStorage();
    return storage.set(key, value);
  }

  public async get(key: string): Promise<any> {
    const storage = await this.getStorage();
    return storage.get(key);
  }

  public async remove(key: string): Promise<void> {
    const storage = await this.getStorage();
    return storage.remove(key);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public async forEach(iteratorCallback: (value: any, key: string, iterationNumber: Number) => any): Promise<void> {
    const storage = await this.getStorage();
    return storage.forEach(iteratorCallback);
  }

  public async keys(): Promise<string[]> {
    const storage = await this.getStorage();
    return storage.keys();
  }

  protected async init(): Promise<Storage> {
    return this.ionicStorage.create();
  }

  private async getStorage() {
    return firstValueFrom(this.storage$);
  }
}
