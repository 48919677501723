export enum HttpMethod {
    DELETE = 'DELETE',
    POST = 'POST',
    GET = 'GET',
    PUT = 'PUT'
}

export enum HttpHeaders {
    CT_JSON = 'Content-Type: application/json',
    AUTH_BASIC = 'Authorization: Basic {AUTH_TOKEN}'
}

export enum QueryFetchPolicy {
    CACHE_FIRST = 'cache-first',
    NETWORK_ONLY = 'network-only',
    CACHE_ONLY = 'cache-only',
    NO_CACHE = 'no-cache',
    STANDBY = 'standby',
    CACHE_AND_NETWORK = 'cache-and-network'
}

export enum MaxExecutionErrorMessage {
    prefix = 'Error: GraphQL error: ',
    startsWith = 'You are trying to access ',
    endsWith = ' too often'
}

export enum PasswordEnum {
    temporaryPassword = 'Account is not fully set up'
}
