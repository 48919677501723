/**
 * Add a dash symbol ("-") after the first two characters of the input strings.
 * Also, a function for removing the transformation is provided.
 * @param data Array of strings to be formatted
 * @param revertTransformation If true, the transformation will be reverted
 */
export function deliveryNumberFormatting(data: string[], revertTransformation = false): string[] {
    if (!revertTransformation) {
        // apply transformation
        const formattedData: string[] = [];
        data.forEach(item => {
            formattedData.push(item.length > 2 ? item.substring(0,2) + '-' + item.substring(2) : '');
        });
        return formattedData;
    }

    // remove transformation
    const formattedData: string[] = [];
    data.forEach(item => {
        formattedData.push(item.trim().replace('-', ''));
    });
    return formattedData;
}
