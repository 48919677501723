export interface PharmacyHolidaySettings {
  state: FederalStates;
  customHolidays: LocalHoliday[];
}

export interface PharmacyVacation {
  from?: string;
  until: string;
}

export enum EmergencyOpeningStatus {
  DEFAULT = 'DEFAULT',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum FederalStates {
  BW = 'BW',
  BY = 'BY',
  BE = 'BE',
  BB = 'BB',
  HB = 'HB',
  HH = 'HH',
  HE = 'HE',
  NI = 'NI',
  MV = 'MV',
  NW = 'NW',
  RP = 'RP',
  SL = 'SL',
  SN = 'SN',
  ST = 'ST',
  SH = 'SH',
  TH = 'TH',
}

export type LocalHoliday = 'Fronleichnam' | 'Mariä Himmelfahrt' | 'Augsburger Friedensfest';
