import { createSelector, select } from '@ngrx/store';
import isNil from 'lodash-es/isNil';
import omitBy from 'lodash-es/omitBy';
import { pipe } from 'rxjs';
import { AddonType } from '../../../../../essentials/types/src/addonType';
import { InitialMessages } from '../../../../../essentials/types/src/initialMessages';
import { LoadStatus } from '../../../../../essentials/types/src/loadStatus';
import { AddonUtil } from '../../../../../essentials/util/src/addon.util';
import { isNotNullOrUndefined } from '../../../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { CommonState } from '../../common.state';

const selectUserData = (state: CommonState) => state.userData;

export const selectUser = createSelector(selectUserData, ({ user }) => user);

export const selectPushNotificationRepetitionInterval = createSelector(
  selectUserData,
  ({ pushNotificationRepetitionInterval }) => pushNotificationRepetitionInterval
);

export const selectUserLoadStatus = createSelector(selectUserData, ({ userLoadStatus }) => userLoadStatus);

export const selectUserHadInitialLoad = createSelector(selectUserLoadStatus, (userHydrationStatus) =>
  [LoadStatus.UpToDate, LoadStatus.Stale, LoadStatus.Revalidating].includes(userHydrationStatus)
);

export const selectIsLoadingUser = createSelector(selectUserLoadStatus, (userHydrationStatus) =>
  [LoadStatus.LoadingInitial, LoadStatus.Revalidating].includes(userHydrationStatus)
);

export const selectCognitoId = createSelector(selectUser, (user) => (user ? user.cognitoId : null));

export const selectIsGuest = createSelector(selectUser, (user) => user && user.isGuest);

export const selectGuestNotificationEmail = createSelector(selectUser, (user) => user && user.guestNotificationEmail);

export const selectDeleteAccountAt = createSelector(selectUser, (user) => user && user.deleteAccountAt);

// isRegistered is needed, since !(isGuest$ | async) evaluates to false until the observables returns a value
export const selectIsRegistered = createSelector(selectUser, (user) => user && !user.isGuest);

export const selectFavoritePharmacies = createSelector(selectUser, (user) => (user ? user.favoritePharmacies : null));

export const selectPharmacy = createSelector(selectUser, (user) => (user ? user.pharmacy : null));

export const pipeUserIsReady = pipe(select(selectCognitoId), isNotNullOrUndefined());

export const selectInitialMessages = createSelector(
  selectUser,
  (user): InitialMessages => (user?.initialMessages ? omitBy(user.initialMessages, isNil) : {})
);

export const selectProductCardsSentLast30Days = createSelector(
  selectUser,
  (user) => user && user.productCardsSentLast30Days
);

export const selectFavoritedByAppUserCount = createSelector(
  selectUser,
  (user) => user?.favoritedByAppUserIds?.length || 0
);

export const selectFavoritedByAppUserCountAtStartOfMonth = createSelector(
  selectUser,
  (user) => user?.favoritedByAppUserCountAtStartOfMonth || 0
);

export const selectCustomAppointmentTypes = createSelector(selectUser, (user) => user?.customAppointmentTypes || []);

export const selectHiddenDefaultAppointmentTypes = createSelector(
  selectUser,
  (user) => user?.hiddenDefaultAppointmentTypes || []
);

export const selectAddonStatus = (addon: AddonType) =>
  createSelector(selectUser, (user) => AddonUtil.getAddonStatusOfUser(user, addon));
export const selectIsSubscribedToAddon = (addon: AddonType) =>
  createSelector(selectUser, (user) => user?.addons?.[addon]?.status === 'SUBSCRIBED');

export const selectCanUseAddon = (addon: AddonType) =>
  createSelector(selectAddonStatus(addon), (addonStatus) => AddonUtil.canAddonBeUsed(addonStatus));
