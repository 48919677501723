<ng-container *ngIf="shouldRotateDevice && !isNotSupportedDevice">
    <ng-lottie [options]="options"></ng-lottie>
    <ion-text i18n>Bitte drehen Sie Ihr Tablet ins Querformat, für eine optimale Darstellung von Sanacorp Connect.</ion-text>
</ng-container>
<ng-container *ngIf="isNotSupportedDevice">
    <img src="/assets/sanacorp-logo.svg"  alt="Sanacorp"/>
    <ion-text i18n>
        Die Nutzung von Sanacorp Connect ist ausschließlich für Tablets und Desktop mit einer Mindestbreite
        von 1024 Pixel möglich.
    </ion-text>
</ng-container>

