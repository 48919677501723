import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppsyncChatPartnerMetadataService } from '../../../../common/resources/src/services/appsync/appsync-chat-partner-metadata.service';
import { EncryptionService } from '../../../../common/resources/src/services/encryption/encryption.service';
import { SubscriptionManagementService } from '../../../../common/resources/src/services/subscriptions/subscription-management.service';
import { CommonState } from '../../../../store/src/common-store/common.state';
import {
  deleteAllChatPartnerMetadata,
  editChatPartnerNickname,
  loadChatPartnerMetadata,
  loadChatPartnerMetadataFailure,
  loadChatPartnerMetadataSuccess,
  setChatPartnerNickname,
  updateChatPartnerMetadata,
} from '../../../../store/src/common-store/other/actions/chat-partner.actions';
import { startAppsyncSubscriptions } from '../../../../store/src/common-store/other/actions/subscription.actions';
import { setUserOnLogin } from '../../../../store/src/common-store/user-store/actions/user.actions';
import { selectCognitoId, selectUser } from '../../../../store/src/common-store/user-store/selectors/user.selectors';
import { selectChatPartnerLoadStatus } from '../../../../store/src/pharmacy/pharmacy-chat-partner-store/pharmacy-chat-partner.selectors';
import { PharmacyChatPartnerState } from '../../../../store/src/pharmacy/pharmacy-chat-partner-store/pharmacy-chat-partner.state';
import { LoadStatus } from '../../../../essentials/types/src/loadStatus';
import { ChatPartnerMetadataUtil } from '../../../../essentials/util/src/chat-partner-metadata.util';
import { Logger } from '../../../../essentials/util/src/logger';
import { isNotNullOrUndefined } from '../../../../essentials/util/src/rxjs/isNotNullOrUndefined';

const logger = new Logger('PharmacyChatPartnerEffects');

@Injectable()
export class PharmacyChatPartnerEffects {
  initChatPartnerMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setUserOnLogin),
      withLatestFrom(this.store.select(selectChatPartnerLoadStatus)),
      filter(([_, pharmacyChatPartnerLoadStatus]) =>
        [LoadStatus.Init, LoadStatus.Error, LoadStatus.Stale].includes(pharmacyChatPartnerLoadStatus)
      ),
      map(() => loadChatPartnerMetadata())
    )
  );

  loadChatPartnerMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadChatPartnerMetadata),
      mergeMap(async () => {
        try {
          const allChatPartnerMetadata = await this.appsyncChatPartnerMetadataService.getAllChatPartnerMetadata();
          return loadChatPartnerMetadataSuccess({ allChatPartnerMetadata });
        } catch (e) {
          logger.error('Error while loading chat partner metadata', e);
          return loadChatPartnerMetadataFailure();
        }
      })
    )
  );

  subscribeToChatPartnerUpdates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadChatPartnerMetadataSuccess),
        withLatestFrom(this.store.select(selectCognitoId).pipe(isNotNullOrUndefined())),
        tap(([_, cognitoId]) => this.subscribeToPharmacyChatPartnerUpdates(cognitoId))
      ),
    { dispatch: false }
  );

  revalidateChatPartnerMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startAppsyncSubscriptions),
      withLatestFrom(this.store.select(selectChatPartnerLoadStatus)),
      filter(([_, pharmacyChatPartnerLoadStatus]) => LoadStatus.Stale === pharmacyChatPartnerLoadStatus),
      map(() => loadChatPartnerMetadata())
    )
  );

  updateChatPartnerNickname$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editChatPartnerNickname),
        withLatestFrom(this.store.select(selectUser).pipe(isNotNullOrUndefined())),
        mergeMap(async ([{ conversation, chatPartnerNickname }, user]) => {
          try {
            const encryptedChatPartnerNickname = this.encryptionService.encryptUsingPublicKey(
              chatPartnerNickname,
              user.publicKey
            );
            if (encryptedChatPartnerNickname !== false) {
              const chatPartnerId = ChatPartnerMetadataUtil.getChatPartnerIdAsPharmacy(conversation);
              this.store.dispatch(
                setChatPartnerNickname({
                  decryptedChatPartnerNickname: chatPartnerNickname,
                  encryptedChatPartnerNickname,
                  chatPartnerId,
                  cognitoId: user.cognitoId,
                })
              );
              await this.appsyncChatPartnerMetadataService.updateChatPartnerMetadata(chatPartnerId, {
                encryptedChatPartnerNickname,
              });
            } else {
              logger.error('could not encrypt chat partner nickname');
            }
          } catch (e) {
            logger.error('error updating chat partner nickname', e);
          }
        })
      ),
    { dispatch: false }
  );

  deleteAllChatPartnerMetadata$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteAllChatPartnerMetadata),
        tap(async () => {
          try {
            await this.appsyncChatPartnerMetadataService.deleteAllChatPartnerMetadata();
          } catch (e) {
            logger.error('error deleting all chat partner metadata', e);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<CommonState & { pharmacyChatPartnerMetadata: PharmacyChatPartnerState }>,
    private appsyncChatPartnerMetadataService: AppsyncChatPartnerMetadataService,
    private encryptionService: EncryptionService,
    private subscriptionManagementService: SubscriptionManagementService
  ) {}

  private subscribeToPharmacyChatPartnerUpdates(cognitoId: string) {
    this.subscriptionManagementService.subscribe(
      'updatedChatPartnerMetadataForPharmacy',
      this.appsyncChatPartnerMetadataService.updatedChatPartnerMetadataForPharmacy(cognitoId),
      (updatedChatPartnerMetadata) => {
        this.store.dispatch(updateChatPartnerMetadata({ chatPartnerMetadata: updatedChatPartnerMetadata }));
      }
    );
  }
}
