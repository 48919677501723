<app-modal  i18n-title title="Interne Notiz erstellen/bearbeiten" >
    <ng-container headerActions></ng-container>
    <ng-container content>
        <div class="form-container">
            <form [formGroup]="noteFormGroup" (ngSubmit)="onSaveNote()" class="form">
                <ion-item class="background-input" *ngIf="noteType && sourceId"  appTooltip ttp="bottom" ttk="note">
                    <ion-textarea 
                        i18n-label
                        aria-label="Interne Notiz"
                        label="Interne Notiz"
                        class="required"
                        [ngClass]="{
                            'invalid': !noteFormGroup.controls.notes?.pristine | formInputIsInvalid : noteFormGroup.controls.notes?.valid,
                        }"
                        formControlName="notes"
                        type="text"
                        auto-grow="true"
                        class="order-note-textarea"
                        [placeholder]="tooltips.note" 
                    #noteTextarea>
                    </ion-textarea>
                    <ion-icon name="alert-circle"
                              color="primary"
                              *ngIf="!noteFormGroup.controls.notes?.pristine | formInputIsInvalid : noteFormGroup.controls.notes?.valid"
                              appTooltip
                              [tt]="noteFormGroup.controls.notes | formInputErrorMessage : noteFormGroup.controls.notes.value"
                              ttp="right">
                    </ion-icon>
                </ion-item>
            </form>
            <div class="required-fields"><span class="required">*</span> Benötigte Felder</div>
        </div>
        <ion-text *ngIf="!noteType || !sourceId">Für diesen Datensatz kann keine Notiz erstellt werden!</ion-text>
    </ng-container>
    <!-- Footer -->
    <ng-container footer *ngIf="noteType && sourceId">
        <div class="display-flex">
            <ion-button *ngIf="noteEntries && noteEntries.id" (click)="onDeleteNote()" class="button-tertiary" i18n>Notiz löschen</ion-button>
            <ion-button id="{{matomoId}}" [disabled]="!noteFormGroup.valid" (click)="onSaveNote()" i18n>Speichern</ion-button>
        </div>
    </ng-container>
</app-modal>
