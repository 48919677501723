import { SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MarkedOptions, MARKED_OPTIONS, MarkedRenderer } from 'ngx-markdown';
import { MarkdownModuleConfig } from 'ngx-markdown/src/markdown.module';
import { isString } from 'lodash';
import { environment } from '../../../environments/environment';
import { VideoPlayerService } from '../services/videoPlayer.service';

export function markedOptionsFactory(videoPlayer: VideoPlayerService, sanitizer: DomSanitizer): MarkedOptions {
    const renderer = new MarkedRenderer();
    renderer.image = (href, caption, alt) => {
        href = (isString(href) && href.includes('http')) ? href : environment.mediaUriStrapi + '/' + href;
        // strapi's wysiwyg editor wasn't handling conversion to new S3 URL, so they get converted here
        if (href.includes(environment.mediaUriStrapi + '/uploads/')) {
            href = href.replace(environment.mediaUriStrapi + '/uploads/', environment.mediaS3Uri);
        }

        if (href.match(/\.(mp4)$/) === null) {
            return ` <a href="${ href }" target="_blank">
                <img src="${ href }" alt="${alt}" />
            </a>`;
        } else {
            const player = sanitizer.sanitize(SecurityContext.HTML, videoPlayer.init([href]));
            return `<div class="video">${player ? player : ''}</div>`;
        }
    };

    // opens all links in a new tab
    // do not use renderer.link directly in the function (content will not render)
    const linkRenderer = renderer.link;
    renderer.link = (href, title, text) => {
        const html = linkRenderer.call(renderer, href, title, text);
        const mainURL = window.location.protocol +'//'+ window.location.host;
        return href && href.startsWith(mainURL) ?
            html :
            html.replace(/^<a /, '<a role="link" tabindex="0" target="_blank" rel="nofollow noopener noreferrer" ');
    };

    return {
        gfm: true,
        breaks: true,
        pedantic: false,
        // baseUrl: environment.mediaUriStrapi + '/',
        renderer
    };
}


export const MarkdownConfig: MarkdownModuleConfig = {
    loader: HttpClient,
    markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: markedOptionsFactory,
        deps: [VideoPlayerService, DomSanitizer]
    },
    sanitize: SecurityContext.NONE
};
