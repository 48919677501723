import { Action, createReducer, on } from '@ngrx/store';
import { hideErrorHeader, showErrorHeader } from '../actions/error-header.actions';
import { ErrorHeaderState, ErrorHeaderType } from '../state/error-header.state';

export const initialErrorHeaderState: ErrorHeaderState = {
  type: 'HIDDEN',
};

const _errorHeaderReducer = createReducer(
  initialErrorHeaderState,
  on(showErrorHeader, (_, { errorType }) => ({ type: errorType })),
  on(hideErrorHeader, (_) => ({ type: 'HIDDEN' as ErrorHeaderType }))
);

export function errorHeaderReducer(state: ErrorHeaderState | undefined, action: Action): ErrorHeaderState {
  return _errorHeaderReducer(state, action);
}
