import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

// Core Files
import { NewsPostQueries } from '../../../../core/core.store';
import { NewsPostInterface } from '../../../../core/core.interfaces';
import { unsubscribe, unsubscribeAll } from '../../../../core/core.utils';
import { ImageTypesEnum } from '../../../../core/enums/image-types.enum';

@Component({
    selector: 'app-news-sidebar',
    templateUrl: './news-sidebar.component.html',
    styleUrls: ['./news-sidebar.component.scss'],
})
export class NewsSidebarComponent implements OnInit, OnChanges, OnDestroy {

    constructor(
        private newsQueries: NewsPostQueries
    ) { }
    @Input() newsId: string;
    news: NewsPostInterface;
    speaker = '';
    newsSubscription: Subscription;

    protected readonly ImageTypes = ImageTypesEnum;

    ngOnInit() {
        unsubscribe(this.newsSubscription);
        this.newsSubscription = this.newsQueries.getNewsPost(this.newsId).subscribe(news => {
            this.news = news;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.newsId && this.newsId) {
            this.ngOnInit();
        }
    }

    ngOnDestroy(): void {
        unsubscribeAll([
            this.newsSubscription
        ]);
    }
}
