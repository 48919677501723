<ng-container *ngIf="appointment">
    <div class="appointment-information"
         [class.is-user-appointment]="!appointment.lockedBySanacorp"
         [class.is-seminar]="appointment.seminar && appointment.seminar.id > 0"
         (click)="onAppointmentClick($event)">
        <ion-text *ngIf="showAppointmentTitle">{{appointment.title | sliceText:25}}</ion-text>
        <ion-grid *ngIf="!appointment.seminar || appointment.isScheduled" class="ion-no-padding appointment-details">
            <ion-row>
                    <ion-icon name="calendar-outline" size="small"></ion-icon>
                    <ion-text [innerHTML]="appointment.dateTimeFrom | formatDateTimeRange:appointment.dateTimeTo:3"></ion-text>
            </ion-row>
            <ion-row class="time-row">
                <div>
                    <ion-icon name="time-outline" size="small"></ion-icon>
                    <ion-text [innerHTML]="appointment.dateTimeFrom | formatDateTimeRange:appointment.dateTimeTo:2"></ion-text>
                </div>
                <div *ngIf="appointment.lockedBySanacorp && appointment.formId && appointment.formButton && !(appointment.dateTimeFrom | isDateSameOrBeforeToday)">
                    <ion-text
                            class="form-button"
                            [innerHTML]="appointment.formButton"></ion-text>
                    <ion-icon name="arrow-forward-outline" size="medium" class="form-button-arrow"></ion-icon>
                </div>
            </ion-row>
        </ion-grid>
        <div *ngIf="appointment.seminar && !appointment.isScheduled">
            <ion-icon name="calendar-outline" size="small"></ion-icon>
            <ion-text i18n>Jederzeit<br /></ion-text>
        </div>
    </div>
    <div class="appointment-actions" *ngIf="showActions && appointment.seminar">
        <ion-icon src="assets/icons/check-unchecked.svg" [class.active]="appointment.seminar.registered" (click)="onAcceptAppointmentClick($event)"></ion-icon>
        <ion-icon src="assets/icons/cancel.svg" [class.active]="!appointment.seminar.registered && appointment.seminar.registered !== null" (click)="onCancelAppointmentClick($event)"></ion-icon>
    </div>
</ng-container>
