import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
    name: 'getImageUrl'
})
export class GetImageUrlPipe implements PipeTransform {

    transform(url: string): string {
        const mediaUri = environment.mediaUriStrapi;
        if(!url || typeof url !== 'string' || url === '[object Object]') {
            return '';
        }
        if(url.indexOf('http') > -1) {
            return url;
        }
        return mediaUri + url;
    }
}

