import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

import {
    compareDates,
    formatDateInComparisonToTime,
    formatDateInComparisonToToday,
    formatDateTimeInComparisonToToday,
    formatDateTimeToDate,
    formatDateTimeToMoment,
    formatDateTimeToTime,
    formatDateToCustomFormat,
    formatDateToDefaultDateFormat,
    getDatePartOfDate,
    today
} from '../formatting/date.formatting';
import { CompareDateEnum, DateEnum, DateRangeOptionCodes } from '../core.enums';
import { AdditionalDateRangeConfig, DateRangeConfig, getDateFromConfig } from '../config/date-range.config';

@Pipe({
    name: 'dateTimeToDate'
})
export class DateTimeToDatePipe implements PipeTransform {
    transform(value: string, isUTC: boolean): string {
        const formatDate = formatDateTimeToDate(value, isUTC);
        if(formatDate.toLowerCase() !== 'invalid date') {
            return formatDate;
        }
        return '';
    }
}

@Pipe({
    name: 'dateTimeToTime'
})
export class DateTimeToTimePipe implements PipeTransform {
    transform(value: string, isUTC: boolean, useDefaultFullTimeSign = true): string {
        return formatDateTimeToTime(value, isUTC, useDefaultFullTimeSign);
    }
}

/**
 * Pipe to check whether a given datetime is after 5 AM.
 */
@Pipe({
    name: 'isDateTimeAfter'
})
export class IsDateTimeAfter implements PipeTransform {
    transform(value: number, timeValue: number, type: 'hour' | 'minute', isUTC: boolean): boolean {
        const formatDateTime = formatDateTimeToMoment(value, isUTC);
        if( !( ['minute', 'hour'].includes(type.toLowerCase()) ) ) {
            throw new Error(`Unsupported type ${type}`);
        }
        switch(type) {
            case 'minute':
                return formatDateTime.minute() >= timeValue;
            case 'hour':
                return formatDateTime.hour() >= timeValue;
        }
    }
}

@Pipe({
    name: 'formatDateTimeToDateTime'
})
export class FormatDateTimeToDateTimePipe implements PipeTransform {
    transform(value: string, isUTC: boolean): string {
        return formatDateTimeToDate(value, isUTC) + ' - ' + formatDateTimeToTime(value, isUTC);
    }
}

@Pipe({
    name: 'formatDateTimeToMoment'
})
export class FormatDateTimeToMomentPipe implements PipeTransform {
    transform(value: string): Moment {
        return formatDateTimeToMoment(value);
    }
}

@Pipe({
    name: 'formatDateInComparisonToToday'
})
export class FormatDateInComparisonToTodayPipe implements PipeTransform {
    transform(value: string): string {
        return formatDateInComparisonToToday(value);
    }
}

@Pipe({
    name: 'formatDateTimeInComparisonToToday'
})
export class FormatDateTimeInComparisonToTodayPipe implements PipeTransform {
    transform(value: string): string {
        return formatDateTimeInComparisonToToday(value);
    }
}
@Pipe({
    name: 'formatDateInComparisonToTime'
})
export class FormatDateInComparisonToTimePipe implements PipeTransform {
    transform(value: string, isUTC: boolean): string {
        return formatDateInComparisonToTime(value, isUTC);
    }
}
@Pipe({
    name: 'formatDateToDayMonth'
})
export class FormatDateToDayMonth implements PipeTransform {
    transform(value: string, isUTC: boolean): string {
        return getDatePartOfDate(value, DateEnum.dayNum, isUTC) +
            '. ' +
            getDatePartOfDate(value, DateEnum.monthLabelLong, isUTC);
    }
}

@Pipe({
    name: 'formatDateToMonthYear'
})
export class FormatDateToMonthYear implements PipeTransform {
    transform(value: string, isUTC: boolean, isMonthLabelLong = true, longMonthLabelLengthLimit : number = 0): string {
        if (isMonthLabelLong) {
            let datePartOfDate : string;
            if (longMonthLabelLengthLimit > 0) {
                datePartOfDate = getDatePartOfDate(value, DateEnum.monthLabelLong, isUTC).substring(0, longMonthLabelLengthLimit);
            } else {
                datePartOfDate = getDatePartOfDate(value, DateEnum.monthLabelLong, isUTC);
            }
            return datePartOfDate +
                ' ' +
                getDatePartOfDate(value, DateEnum.year, isUTC);
        } else {
            return getDatePartOfDate(value, DateEnum.month, isUTC) +
                ' / ' +
                getDatePartOfDate(value, DateEnum.year, isUTC);
        }
    }
}

@Pipe({
    name: 'formatDateTimeRange'
})
export class FormatDateTimeRange implements PipeTransform {
    transform(datetimeFrom: string, datetimeTo: string,
              returnType: FormatDateTimeRangeReturnType = FormatDateTimeRangeReturnType.complete
    ): string {
        const startOfFrom = formatDateTimeToMoment(datetimeFrom, true).startOf('day');
        const startOfTo = formatDateTimeToMoment(datetimeTo, true).startOf('day');
        const differenceBetweenDays = startOfTo.diff(startOfFrom, 'days');

        const days = ' | ' + (differenceBetweenDays).toString() + (differenceBetweenDays > 1 ? ' Tage' : ' Tag');

        const fromDate = formatDateTimeToDate(datetimeFrom, true);
        const toDate = formatDateTimeToDate(datetimeTo, true);
        const fromTime = formatDateTimeToTime(datetimeFrom, true);
        const toTime = formatDateTimeToTime(datetimeTo, true);

        const isSameDay = startOfFrom.isSame(startOfTo);
        const isSameDayAndTime = moment(datetimeFrom).isSame(moment(datetimeTo));

        const dateWithDays = isSameDay ? fromDate : fromDate + ' ' + days;
        const date = isSameDay ? fromDate : fromDate + ' - ' + toDate;
        const time = (isSameDayAndTime ? fromTime : fromTime + ' - ' + toTime ) + ' Uhr';

        switch (returnType) {
            case FormatDateTimeRangeReturnType.complete:
                return date +
                    (isSameDayAndTime ? ' - ' : '<br>') +
                    time;

            case FormatDateTimeRangeReturnType.date:
                return date;

            case FormatDateTimeRangeReturnType.time:
                return time;

            case FormatDateTimeRangeReturnType.dateWithDays:
                return dateWithDays;

        }
    }
}

@Pipe({
    name: 'formatDateToCustomFormat'
})
export class FormatDateToCustomFormat implements PipeTransform {
    transform(date: string): string {
        return formatDateToCustomFormat(date);
    }
}

@Pipe({
    name: 'dateRangeToDate'
})
export class DateRangeToDate implements PipeTransform {
    transform(dateRangeOption: DateRangeOptionCodes, format: DateEnum): string {
        const from = getDateFromConfig(dateRangeOption, true);
        return getDatePartOfDate(from, format);
    }
}

@Pipe({
    name: 'dateRangeToLabel'
})
export class DateRangeToLabel implements PipeTransform {
    transform(dateRangeOption: DateRangeOptionCodes): string {
        const item = [
            ...DateRangeConfig,
            AdditionalDateRangeConfig[DateRangeOptionCodes.unknown],
            AdditionalDateRangeConfig[DateRangeOptionCodes.nextWeek],
            AdditionalDateRangeConfig[DateRangeOptionCodes.thisYear]
        ].find(dateRange => dateRange.id === dateRangeOption);
        return item && item.title || '';
    }
}

@Pipe({
    name: 'compareDates'
})
export class CompareDatesPipe implements PipeTransform {
    transform(
        date: string,
        dateToCompare: string = today(),
        compareDateEnum: CompareDateEnum = CompareDateEnum.diffInDays): number {
        return date && dateToCompare
            ? compareDates(dateToCompare, formatDateToDefaultDateFormat(date, true), compareDateEnum)
            : 0;
    }
}


@Pipe({
    name: 'isDateInList'
})
export class IsDateInListPipe implements PipeTransform {
    transform(list, date): boolean {
        if(list && list.length){
            const formattedDate = `${date.day}.${date.month}.${date.year}`;
            return !!list.find(({date:itemDate}) =>
              formattedDate === `${itemDate.getDate()}.${itemDate.getMonth()}.${itemDate.getFullYear()}`
            );
        }
        return false;
    }
}

@Pipe({
    name: 'isDateSameOrBeforeToday'
})
export class IsDateSameOrBeforeToday implements PipeTransform {
    transform(date: string): boolean {
        return formatDateTimeToMoment(date, true).isSameOrBefore(formatDateTimeToMoment(today()));
    }
}


enum FormatDateTimeRangeReturnType {
    complete,
    date,
    time,
    dateWithDays
}
