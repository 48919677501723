import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { from, map } from 'rxjs';
import { UserStorageInterface } from '../../../interfaces/user-storage.interface';

export const UpsertUserStorage = gql`
    mutation insertUserStorage($storageInput: [userStorage_insert_input!]!) {
        insert_userStorage(
            objects: $storageInput,
            on_conflict: {
                constraint: userStorage_pkey,
                update_columns: [
                    storage
                ]
            }
        ) {
            affected_rows
        }
    }
`;

export const AllUserStorageMutations = [
    UpsertUserStorage
];

@Injectable()
export class UserStorageMutations {
    constructor(
        private apollo: Apollo
    ) {}

    upsertUserStorage(storage: UserStorageInterface) {
        this.apollo.mutate({
            mutation: UpsertUserStorage,
            variables: {storageInput: storage}
        }).subscribe(result => {
            from([result])
                .pipe(map(d => d?.data && d?.data['insert_userStorage'] && d?.data['insert_userStorage']['affected_rows']))
                .subscribe(() => {
                    if (result['errors']) {
                        console.error(result['errors']);
                    }
                });
        });
    }
}
