export enum OffersAppAreaEnum {
    SANACORP = 'Sanacorp Angebotsbereich',
    MEA = 'mea Angebotsbereich'
}

export enum OffersOriginEnum {
    SANACORP = 'default',
    MEA = 'mea',
    MEA_DYNAMIC = 'meaDynamic'
}

export enum SortableOffersFieldsEnum {
    productName = 'productName',
    dosageForm = 'dosageForm',
    pzn = 'pzn'
}
