import { Logger as AmplifyLogger } from '@aws-amplify/core';
import * as Sentry from '@sentry/browser';

export class Logger {
  logger: AmplifyLogger;

  constructor(name: string) {
    this.logger = new AmplifyLogger(name);
    AmplifyLogger.LOG_LEVEL = 'INFO';
  }

  debug(...message: any[]) {
    this.logger.debug(...message);
  }

  info(...message: any[]) {
    this.logger.info(...message);
  }

  warn(message: string, ...data: any[]) {
    Sentry.captureMessage(message, 'warning');
    this.logger.warn(message, ...data);
  }

  error(message: string, error?: any) {
    if (error) {
      if (error instanceof Error) {
        const errorMessage = this.prependMessageToErrorMessage(error, message);
        Sentry.captureMessage(errorMessage, 'error');
      } else if (error.error) {
        if (error.error instanceof Error) {
          const errorMessage = this.prependMessageToErrorMessage(error.error, message);
          Sentry.captureMessage(errorMessage, 'error');
        } else {
          Sentry.captureMessage(`${message} - ${JSON.stringify(error.error)}`, 'error');
        }
      } else {
        const extractedError = this.extractNestedErrors(error);
        Sentry.captureMessage(`${message} - ${JSON.stringify(extractedError)}`, 'error');
      }
    } else {
      Sentry.captureMessage(message, 'error');
    }
    this.logger.error(message, error);
  }

  errorWithFingerprint(message: string, fingerprint: string) {
    Sentry.withScope((scope) => {
      scope.setFingerprint([fingerprint]);
      Sentry.captureMessage(message, 'error');
    });
    this.logger.error(message);
  }

  private prependMessageToErrorMessage(error: Error, message: string): string {
    return [message, error.message, JSON.stringify(error)].join(' - ');
  }

  private extractNestedErrors(error: any): unknown {
    if (error['errors']) {
      // i.e. AppSync errors, where truncation should rather affect data than errors
      return [{ errors: error['errors'] }, { data: error['data'] }];
    } else {
      return error;
    }
  }
}
