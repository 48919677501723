import { Injectable } from '@angular/core';
import { UserSettingKeysEnum } from '../core.enums';
import { SettingsQueries } from '../store/graphql/queries/settings.graphql';
import { SettingsMutations } from '../store/graphql/mutations/settings.graphql';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {

    constructor(
        private settingsQueries: SettingsQueries,
        private settingsMutations: SettingsMutations
    ) { }

    /**
     * Save new order filters depending on existing filters
     *
     * @param key - Key of the setting
     * @param value - Value of the setting
     */
    saveUserSetting(key: UserSettingKeysEnum, value: string) {
        this.settingsMutations.upsertUserSettings(key, value);
    }
}
