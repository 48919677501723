import update, { Spec } from 'immutability-helper';
import { BackendMeaUser } from '../../types/src/backendMeaUser';
import { MeaUser } from '../../types/src/chatUser';
import { BackendPharmacyWithChatUser, PharmacyWithChatUser } from '../../types/src/pharmacy';
import { AddonUtil } from './addon.util';
import { ShopProductUtil } from './shop-product.util';

export class MeaUserMappingUtil {
  public static mapBackendMeaUserToMeaUser(user: BackendMeaUser): MeaUser {
    const backendPharmacy = user.pharmacy;
    const addons = user.addons && AddonUtil.mapBackendAddonsToAddons(user?.addons);
    if (!backendPharmacy) {
      return { ...user, addons } as MeaUser;
    }
    const pharmacyUpdates: Spec<BackendPharmacyWithChatUser> = {
      shopApiBaseUrl: { $set: ShopProductUtil.getShopApiBaseUrl(backendPharmacy.onlineShop) },
    };
    const innerAddons = backendPharmacy.pharmacyChatUser?.addons;
    if (innerAddons) {
      pharmacyUpdates.pharmacyChatUser = { addons: { $set: AddonUtil.mapBackendAddonsToAddons(innerAddons) } };
    }
    const pharmacy = update(backendPharmacy, pharmacyUpdates) as PharmacyWithChatUser;
    return { ...user, addons, pharmacy };
  }
}
