import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { gql } from '@apollo/client/core';

const RemoveReleaseNoteHintMutation = gql`
    mutation removeReleaseNoteHint {
        RemoveReleaseNoteHint {
            status
            message
        }
    }
`;

export const AllReleaseNoteMutations = [
    RemoveReleaseNoteHintMutation
];

@Injectable()
export class ReleaseNoteMutations {
    constructor(
        private apollo: Apollo
    ) {
    }

    removeReleaseNoteHint() {
        this.apollo.mutate({
            mutation: RemoveReleaseNoteHintMutation
        }).subscribe({
            error: (err) => {
                console.error(err);
            }
        });
    }
}
