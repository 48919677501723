import { Component, HostListener, OnInit, Output, EventEmitter, HostBinding } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

/**
 *
 * Usage:
 * <app-rotate-device-screen></app-rotate-device-screen>
 */
@Component({
    selector: 'app-rotate-device-screen',
    templateUrl: './rotate-device-screen.component.html',
    styleUrls: ['./rotate-device-screen.component.scss']
})
export class RotateDeviceScreenComponent implements OnInit {
    options: AnimationOptions = {
        path: '/assets/lottie/rotate-phone.json',
    };
    shouldRotateDevice = false;
    isNotSupportedDevice = false;
    appMinWidth = 1024;

    @Output() isMessageVisible = new EventEmitter<boolean>();

    @HostBinding('class.is-visible') isVisible = false;

    @HostListener('window:resize')
    onResize() {
        this.checkDeviceDimensions();
    }

    constructor() {

    }

    ngOnInit() {
        this.checkDeviceDimensions();
    }

    /**
     * Check if current device is in portrait mode and smaller that the min app width
     */
    checkDeviceDimensions() {
        // Use screen instead of window
        // The values of window.innerHeight/innerWidth are manipulated if
        // dev tools is opened or keyboard (on mobile devices) is active
        const {availHeight, availWidth} = screen;
        const {innerHeight, innerWidth} = window;

        // Safari ignores the orientation of the device, so the available height and width will stay the same
        // We can handle this via the size of the browser
        const height = innerHeight < innerWidth ? Math.min(availHeight, availWidth) :  Math.max(availHeight, availWidth);
        const width = innerHeight < innerWidth ? Math.max(availHeight, availWidth) :  Math.min(availHeight, availWidth);

        let shouldRotate = width < height && width < this.appMinWidth;
        if(!shouldRotate && width > height && height < this.appMinWidth && window.innerWidth < window.innerHeight) {
            shouldRotate = true;
        }
        if(shouldRotate !== this.shouldRotateDevice){
            this.shouldRotateDevice = shouldRotate;
        }

        const isNotSupported = width < this.appMinWidth && height < this.appMinWidth;
        if(isNotSupported !== this.isNotSupportedDevice) {
            this.isNotSupportedDevice = isNotSupported;
        }

        const isMessageVisible = this.isNotSupportedDevice || this.shouldRotateDevice;
        this.isVisible = isMessageVisible;
        this.isMessageVisible.emit(isMessageVisible);
    }
}
