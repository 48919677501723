// should be called like the @Component selector between app- and -table
export enum TableTypeEnum {
    Delivery = 'delivery',
    Invoice = 'invoices',
    Order = 'order',
    OrderBulk = 'order-bulk',
    Return = 'returns',
    Offers = 'offers',
    MeaDownloads = 'mea-downloads',
    UserAdministration = 'user-administration'
}

export enum TableWidgetColumnTypeEnum {
    actions = 'actions',
    ionBadge = 'ionBadge',
    appBadge = 'appBadge',
}
