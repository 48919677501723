import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { IonicSlides } from '@ionic/angular';

@Component({
    selector: 'app-slide-nav',
    templateUrl: './slide-nav.component.html',
    styleUrls: ['./slide-nav.component.scss'],
})
export class SlideNavComponent implements OnInit, OnChanges {
    @ViewChild('slideNav') slideNav;

    @Input() elements: Array<any> = [];
    @Input() key = '';
    @Input() idPrefix = '';
    @Input() activeIndex = 0;
    @Input() scrollToItem = false;
    @Input() isLoading = false;
    @Output() updateSelectedTabIndex: EventEmitter<number> = new EventEmitter();

    swiperModules = [IonicSlides];

    activeTabWidth = '';
    activeTabLeft = '';

    sectionIdPrefix = '';

    constructor() {
    }

    ngOnInit() {
        this.sectionIdPrefix = this.idPrefix + '_section_';
        // if slider is not rendered when this event fires, the animation will be stuck
        // TODO check if the waiting can be done more elegantly
        setTimeout(() => {
            this.onSelectTab(this.activeIndex, true);
        },1000);
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.activeIndex) {
            this.onSelectTab(this.activeIndex, false);
        }
        if(changes.elements) {
            setTimeout(_ => {
                this.onSelectTab(this.activeIndex, false);
            },500);
        }
    }

    /**
     * Called if tab was selected
     *
     * @param index - index of selected tab
     * @param updateSelected - if true, the selected tab will be updated
     */
    onSelectTab(index: number, updateSelected = true) {
        this.activeIndex = index;

        const sectionButton = document.getElementById(this.sectionIdPrefix + index);
        if(sectionButton) {
            this.activeTabWidth = sectionButton.offsetWidth + 'px';
            this.activeTabLeft = sectionButton.offsetLeft + 'px';

            if(this.scrollToItem && this.slideNav?.nativeElement?.swiper) {
                this.slideNav?.nativeElement?.swiper?.setTransition(500);
                this.slideNav?.nativeElement?.swiper?.setTranslate(-1 * sectionButton.offsetLeft, 0);
            }

        } else {
            this.activeTabWidth = '';
            this.activeTabLeft = '';
        }

        if(updateSelected) {
            this.updateSelectedTabIndex.emit(index);
        }
    }

    /**
     * track by
     *
     * @param index - Index of the item in the array
     * @param item - The item to track
     */
    trackBy(index, item) {
        return item[this.key];
    }

}
