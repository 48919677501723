
export enum StatisticsSizeEnum {
    SMALL = 'small',
    DEFAULT = 'default'
}

export enum StatisticsRouteEnum {
    sales = 'sales',
    packages = 'packages',
    orders = 'orders',
    assortment = 'assortment',
}
export enum FullStatisticsRoutesEnum {
    sales = '/app/statistics/sales',
    packages = '/app/statistics/packages',
    orders = '/app/statistics/orders',
}

export enum BusinessFigurePdfTypeEnum {
    BTM = 'BTM',
    TFG = 'TFG',
    VETERINARY = 'VeterinaryDrugs',
    REIMPORTS = 'ReImports',
    DEFECT_REIMPORTS = 'DefectReImports',
    DEFECT_CONTRACT_ITEMS = 'DefectContractItems',
    PRODUCER = 'Producers',
    HIGH_PRICED_ITEMS = 'HighPricedItems'
}

export enum StatisticsAssortmentAlertEnum {
    SHOW_IN_FUTURE = 'showAssortmentAlertInFuture'
}
