<ng-container *ngIf="surveys$ | async as surveys">
    <ng-container *ngIf="surveys.length > 0; else noSurveys">
        <ng-container *ngFor="let survey of surveys">
            <div [id]="'pollModalTriggerId' + survey.id"
                 class="wrapper"
                 [class.clickable]="!survey?.isFilledOut"
                 [class.filled-out]="survey?.isFilledOut"
                 (click)="openModal(survey.id, survey?.isFilledOut)"
            >
                <ion-icon name="clipboard-outline"></ion-icon>
                <div class="content">
                    <h2 [class.survey-heading]="surveys.length > 2" i18n>{{survey.title}}</h2>
                    <div>{{survey.partner}}</div>
                    <div i18n>Umfrage läuft bis {{survey.toDate | dateTimeToDate: false}}</div>
                </div>
                <ion-img *ngIf="survey.logoUrl" [src]="survey.logoUrl" alt="Survey Logo" class="logo"/>
            </div>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #noSurveys>
    <div class="no-results" i18n>
        <ion-text class="h1">Zurzeit gibt es keine Umfragen</ion-text>
    </div>
</ng-template>
