
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'translate'
})
export class TranslatePipe implements PipeTransform {

    transform(key: any): string {

        // TODO If we use this more often, we should use a json file
        const translations = {
            date: 'Datum',
            sales: 'Umsatz\ngesamt',
            salesWithoutHP: 'Wert\nohne HP',
            salesWithoutHPPercent: 'Anteil\nohne HP %',
            salesOnlyHP: 'Wert\nnur HP',
            salesOnlyHPPercent: 'Anteil\nnur HP %',
            salesRXWithoutHP: 'Wert\nRX ohne HP',
            salesRXWithoutHPPercent: 'Anteil\nRX ohne HP %',
            coopBonusAnnual: 'Umsatz Kooperationsartikel',
            packages: 'Anzahl\ngesamt',
            packageWithoutHP: 'Anzahl\nohne HP',
            packageWithoutHPPercent: 'Anteil\nohne HP %',
            packageOnlyHP: 'Anzahl\nnur HP',
            packageOnlyHPPercent: 'Anteil\nnur HP %',
            packageRXWithoutHP: 'Anzahl\nRX ohne HP',
            packageRXWithoutHPPercent: 'Anteil\nRX ohne HP %'
        };

        return translations[key] || '';
    }
}
