import { Injectable, signal } from '@angular/core';
import { AppStateInterface } from '../../interfaces/app-state.interface';
import { toObservable } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root',
})
export class AppStateVar {
    private _appState = signal<AppStateInterface>({
        fullPageLoader: {
            isActive: false,
            text: '',
        },
    });
    public appState = this._appState.asReadonly();
    public appStateObservable = toObservable(this.appState);

    set(appState: AppStateInterface) {
        this._appState.set(appState);
    }
    setFullPageLoader(isActive: boolean, text = '') {
        this._appState.update((oldAppState) => ({
            ...oldAppState,
            fullPageLoader: {
                isActive,
                text,
            },
        }));
    }
}
