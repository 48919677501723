import { inject, Injectable, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { switchMap, tap } from 'rxjs';
import uniqBy from 'lodash/uniqBy';

import { EducationLocationsEnum } from '../enums/education-locations.enum';
import { EducationQueries } from '../store/graphql/queries/educations.graphql';
import { EducationFiltersInterface, EducationInterface } from '../interfaces/education.interface';

@Injectable({
    providedIn: 'root',
})
export class EducationService {
    private educationQueries = inject(EducationQueries);


    // Educations Signal
    private _educations = signal<EducationInterface[]>([]);
    public educations = this._educations.asReadonly();

    // isLoading Signal
    private _isLoading = signal<boolean>(true);
    public isLoading = this._isLoading.asReadonly();

    // Locations Signal
    private _locations = signal<Array<{id: string, title: string}>>([{
        id: EducationLocationsEnum.ALL.toString(),
        title: 'Alle'
    }]);
    public locations = this._locations.asReadonly();

    // Filters Signal
    private _filters = signal<EducationFiltersInterface>({
        location: EducationLocationsEnum.ALL.toString(),
        search: ''
    });
    public filters = this._filters.asReadonly();

    // Receive new educations and update the signals
    private educations$ = toObservable(this._filters).pipe(
        switchMap(filters => {
            this._isLoading.set(true);
            return this.educationQueries.getEducations(filters);
        }),
        tap(data => {
            this._educations.set(data || []);
            this._isLoading.set(false);
            const locations = this._locations();
            data.forEach((education) => {
                locations.push({
                    id: education.location.id.toString(),
                    title: education.location.name
                });
            });
            this._locations.set(uniqBy(locations, 'id'));
        })
    );
    // toSignal automatically subscribes and unsubscribes to the observable
    educationReadOnly = toSignal(this.educations$, {initialValue: [] as EducationInterface[]});

    constructor() {
    }

    /**
     * Update of filters
     *
     * @param filters - The changed filters
     */
    public setFilters(filters: EducationFiltersInterface) {
        this._filters.update((oldFilters) =>
            ({...oldFilters, ...filters})
        );
    }
}
