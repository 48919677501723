export enum ModalClassEnum {
    medium = 'modal-medium',
    large = 'modal-large',
    high = 'modal-high',
    mediumHigh = 'modal-medium-high',
    largeHigh = 'modal-large modal-high',
    autoWidthlargeHigh = 'modal-auto-width modal-high',
    autoHeight = 'modal-auto-height',
    autoHeightAutoWidth = 'modal-auto-height modal-auto-width',
    autoHeightMedium = 'modal-auto-height modal-medium',
    autoHeightLarge = 'modal-auto-height modal-large',
    notificationCenter = 'modal-notification-center',
    infoModal = 'modal-info-modal',
    alertModal = 'modal-alert',
}
