export enum CommunicationZoneFormEnum {
    documentCH = 'documentCH',
    documentLS = 'documentLS',
    editProfile = 'editProfile',
    companyChangeRequest = 'companyChangeRequest',
    cooperationBonusRequest = 'cooperationBonusRequest',
    documentESR = 'documentESR',
    documentETR = 'documentETR',
    meaChatContact = 'meaChatContact',
    invoicesContact = 'invoicesContact',
    returnsProductMissing = 'returnsProductMissing'
}

export enum CommunicationZoneFormSubstituteEnum {
    customerEmail = 'customerEmail',
    companyChangeRequest = 'companyChangeRequest',
    document = 'document'
}
