import { DateRangeOptionCodes } from '../enums/date-range.enum';
import { GetDateRangeConfig } from './date-range.config';
import { MeaDownloadsFiltersInterface } from '../interfaces/mea.interface';
import { MeaDownloadTypes, MeaFiltersTypeEnum } from '../enums/mea.enum';

export const MeaDownloadsFilterConfig: Array<MeaDownloadsFiltersInterface> = [
    {
        filterType: MeaFiltersTypeEnum.date,
        label: 'Datum',
        selectedValues: {
            dateOption: DateRangeOptionCodes.all,
            dateFrom: null,
            dateTo: null,
            dateRangeOptions: GetDateRangeConfig()
        }
    },
    {
        filterType: MeaFiltersTypeEnum.type,
        label: 'Typ',
        selectedValue: MeaDownloadTypes.ALL
    },
    {
        filterType: MeaFiltersTypeEnum.search,
        label: 'Suche',
        selectedValue: '',
    },
    {
        filterType: MeaFiltersTypeEnum.filterButton,
        selectedValues: {
            isActive: false
        }
    }
];

export const MeaDynamicPageMembershipTypes = ['mea premium', 'mea select'];