import { inject, Pipe, PipeTransform } from '@angular/core';
import { NotificationInterface } from '../interfaces/notification.interface';
import { NotificationsEnum, NotificationsReminderTypeEnum } from '../enums/notifications.enum';
import { NotificationWidgetService } from '../services/notificationWidget.service';
@Pipe({
    name: 'notificationButtonLabelByType'
})
export class NotificationButtonLabelByTypePipe implements PipeTransform {

    transform(notificationType: NotificationsEnum | NotificationsReminderTypeEnum = null): string {
        switch (notificationType) {
            case NotificationsEnum.NEW_USER_WELCOME:
                return  'Zur Hilfe';
            case NotificationsEnum.NEW_USER_DATA_POLICY:
                return  'Nutzungsbedingungen';
            default:
                return 'Zum Eintrag';
        }
    }
}
@Pipe({
    name: 'notificationLabelByType'
})
export class NotificationLabelByTypePipe implements PipeTransform {
    private notificationWidgetService = inject(NotificationWidgetService);

    transform(notification: NotificationInterface, shortLabel = false, fullMessage = false): string {
        return this.notificationWidgetService.notificationLabel(notification, shortLabel, fullMessage);
    }
}
