import {
    Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { TabsInterface } from '../../interfaces/tabs.interface';

/**
 * Usage:
 * content is displayed by activeIndex using a switch case
 * <app-tabs-widget>
 *     <ng-container content [ngSwitch]="activeIndex">
 *         <app-component *ngSwitchCase="condition"></app-component>
 *     </ng-container>
 * </app-tabs-widget>
 */
@Component({
    selector: 'app-tabs-widget',
    templateUrl: './tabs-widget.component.html',
    styleUrls: ['./tabs-widget.component.scss'],
})
export class TabsWidgetComponent implements OnInit {
    @Input() tabs: TabsInterface[];
    @Output() tabClicked: EventEmitter<number> = new EventEmitter();

    activeTabIndex = 0;

    constructor() {}

    ngOnInit() {
        if (this.tabs && this.tabs.length > 0) {
            this.activeTabIndex = +localStorage.getItem(this.tabs[0].activeTabKey);
            this.tabClicked.emit(this.activeTabIndex);
        }
    }

    onTabClick(tabIndex: number) {
        this.activeTabIndex = tabIndex;
        this.tabClicked.emit(tabIndex);
        localStorage.setItem(this.tabs[tabIndex].activeTabKey, tabIndex.toString());
    }
}
