import { computed, effect, inject, Injectable, signal, untracked } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Storage } from '@ionic/storage-angular';
import { map, Observable, switchMap, tap } from 'rxjs';
import { MeaDownloadsDataInterface, MeaDownloadsFiltersInterface, MeaDownloadTypesInterface, MeaSortInterface } from '../interfaces/mea.interface';
import { MeaDownloadsFilterConfig } from '../config/mea.config';
import { MeaDownloadsStorageKeys, MeaDownloadTypes, MeaFiltersTypeEnum, SortableMeaDownloadColumns } from '../enums/mea.enum';
import { MeaQueries } from '../store/graphql/queries/mea.graphql';
import { SortSimpleEnum } from '../enums/sort-direction.enum';

@Injectable({
    providedIn: 'root',
})
export class MeaDownloadsService {
    private meaQueries = inject(MeaQueries);
    private storage = inject(Storage);

    private filterConfig = MeaDownloadsFilterConfig;
    private _selectedId = signal<string>(null);
    public selectedId = this._selectedId.asReadonly();

    private _filters = signal<MeaDownloadsFiltersInterface[]>(this.filterConfig);
    public filters = this._filters.asReadonly();

    private _sorting = signal<MeaSortInterface>({field: SortableMeaDownloadColumns.LABEL, direction: SortSimpleEnum.asc});
    public sorting = this._sorting.asReadonly();
    private _downloads = signal<MeaDownloadsDataInterface[]>([]);
    public downloads = this._downloads.asReadonly();
    private downloads$ = toObservable(this._filters).pipe(
        switchMap(filters => {
            return this.meaQueries.getMeaDownloads(filters);
        }),
        tap(data => {
            this._downloads.set(data || []);
            if (data?.length) {
                // we need to set null first, to change the download types if the filter changes (if the first download has multiple download types)
                this._selectedId.set(null);
                this._selectedId.set(data[0].id);
            } else {
                this._selectedId.set(null);
            }
        })
    );
    // toSignal automatically subscribes and unsubscribes to the observable
    downloadsReadOnly = toSignal(this.downloads$, {initialValue: [] as MeaDownloadsDataInterface[]});

    public selectedDownload = computed(() => {
        const selectedId = this._selectedId();

        return untracked(() => {
            const downloads = this._downloads();
            if (!downloads?.length) {
                return null;
            }
            if (!selectedId) {
                this._selectedId.set(downloads[0].id);
                return;
            }
            const selectedDownload = downloads.find(d => d.id === selectedId);
            if (selectedDownload) {
                return selectedDownload;
            }
            this._selectedId.set(downloads[0].id);
        });
    });

    constructor() {
        this.storage.get(MeaDownloadsStorageKeys.FILTERS).then((filters: MeaDownloadsFiltersInterface[]) => {
            if (filters && filters.length > 0) {
                this._filters.set(filters);
            }
        });
        this.storage.get(MeaDownloadsStorageKeys.SORT).then((sort: MeaSortInterface) => {
            if (sort) {
                this._sorting.set(sort);
            }
        });

        /**
         * Change the sorting of the mea downloads, when sorting direction is changed.
         */
        effect(() => {
            const sorting = this._sorting();
            let sorted: MeaDownloadsDataInterface[] = [];
            untracked(() => {
                sorted = this.sortData();
                this._downloads.set(sorted);
            });
        });
    }

    /**
     * Get the mea download types
     */
    public getTypes(): Observable<MeaDownloadTypesInterface[]> {
        return this.meaQueries.getMeaDownloadTypes().pipe(map(d => {
            d.unshift({id: '0', type: MeaDownloadTypes.ALL, color: ''});
            return d;
        }));
    }

    /**
     * Set the id for a specific selected download item. For this item the sidebar is displayed.
     * @param id of the download item
     */
    public setSelected(id: string) {
        this._selectedId.set(id);
    }

    /**
     * Set new value(s) for a specific filter.
     * @param newFilter
     * @param filterType
     */
    public setFilters(newFilter: MeaDownloadsFiltersInterface, filterType: MeaFiltersTypeEnum) {
        this._filters.update((oldFilters) => {
            return oldFilters.map(item => {
                if (item.filterType === filterType) {
                    return {...item, ...newFilter};
                }
                if (item.filterType === MeaFiltersTypeEnum.filterButton) {
                    return {...item, selectedValues: {isActive: this.isFilterActive(newFilter, filterType)}};
                }
                return item;
            });
        });

        void this.storage.set(MeaDownloadsStorageKeys.FILTERS, this._filters());
    }

    /**
     * Set the sorting for the mea downloads.
     * @param field the field to be sorted by
     */
    public setSorting(field: SortableMeaDownloadColumns) {
        this._sorting.update((oldSorting) => ({
            field,
            direction: oldSorting.direction === SortSimpleEnum.asc ? SortSimpleEnum.desc : SortSimpleEnum.asc
        }));
        void this.storage.set(MeaDownloadsStorageKeys.SORT, this._sorting());
    }

    private sortData() {
        const sorting = this._sorting();
        const meaDownloads = this.downloads();
        if (!sorting || !meaDownloads) {
            return [];
        }

        return meaDownloads.sort((a, b) => {
            if (sorting.direction === SortSimpleEnum.asc) {
                return a[sorting.field] > b[sorting.field] ? 1 : -1;
            } else {
                return a[sorting.field] < b[sorting.field] ? 1 : -1;
            }
        });
    }

    /**
     * Check if one filter value is different from the default filter value.
     * @param newFilter value of the filter that was changed
     * @param filterType type of the filter that was changed
     * @private
     */
    private isFilterActive(newFilter: MeaDownloadsFiltersInterface, filterType: MeaFiltersTypeEnum): boolean {
        const filters = this._filters().map(f => {
            if (f.filterType === filterType) {
                return {...f, ...newFilter};
            }
            return f;
        });
        const defaultFilter = this.filterConfig;

        let isFilterActive = false;
        filters.forEach(filter => {
            if (isFilterActive) return;
            const defaultF = defaultFilter.find(f => f.filterType === filter.filterType);
            switch (filter.filterType) {
                case MeaFiltersTypeEnum.date:
                    if (filter.selectedValues.dateOption !== defaultF.selectedValues.dateOption) {
                        isFilterActive = true;
                    }
                    return;
                case MeaFiltersTypeEnum.type:
                    if (filter.selectedValue !== defaultF.selectedValue) {
                        isFilterActive = true;
                    }
                    return;
                case MeaFiltersTypeEnum.search:
                    if (filter.selectedValue !== defaultF.selectedValue) {
                        isFilterActive = true;
                    }
                    return;
            }
        });
        return isFilterActive;
    }
}
