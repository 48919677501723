<app-modal i18n-title title="{{notificationTypeTrans}}">
    <ng-container content>
        <div class="default-loader" [hidden]="!isLoading">
            <ion-spinner name="crescent" color="primary"></ion-spinner>
        </div>
        <div *ngIf="!isLoading && !notification">
            <ion-text class="display-flex" i18n>Keine Daten verfügbar</ion-text>
        </div>
        <ion-list *ngIf="notification" class="modal-list">
            <ion-item *ngIf="notification.payload && notification.payload['name']">
                <ion-text>
                    <h2>Name</h2>
                    <p>{{notification.payload['name']}}</p>
                </ion-text>
            </ion-item>
            <ion-item *ngIf="notification.payload && notification.payload['productName']">
                <ion-text>
                    <h2>Artikel</h2>
                    <p>{{notification.payload['productName']}} {{'-'}} {{notification.payload['packageSize']}}</p>
                </ion-text>
            </ion-item>
            <ion-item *ngIf="notification.payload && notification.payload['pzn']">
                <ion-text>
                    <h2>PZN</h2>
                    <p>{{notification.payload['pzn'].padStart(8, '0')}}</p>
                </ion-text>
            </ion-item>
            <ion-item *ngIf="notification.payload && notification.payload['cancellationReason']">
                <ion-text>
                    <h2>Stornogrund</h2>
                    <p>{{notification.payload['cancellationReason']}}</p>
                </ion-text>
            </ion-item>
            <ng-container *ngIf="notification.payload && notification.type === 'MEASHOP_NEW_ORDER'">
                <ion-item *ngIf="notification?.payload?.createdAt">
                    <ion-text>
                        <h2>Bestellzeitpunkt</h2>
                        <p>{{notification.payload.createdAt | formatDateTimeToDateTime : false}}</p>
                    </ion-text>
                </ion-item>
                <ion-item *ngIf="notification?.payload?.orderNumber">
                    <ion-text>
                        <h2>Bestellnummer</h2>
                        <p>{{notification.payload.orderNumber}}</p>
                    </ion-text>
                </ion-item>
                <ion-item *ngIf="notification?.payload?.orderId">
                    <ion-text>
                        <h2>Bestell-ID</h2>
                        <p>{{notification.payload.orderId}}</p>
                    </ion-text>
                </ion-item>
                <ion-item *ngIf="notification?.payload?.customerNumber">
                    <ion-text>
                        <h2>Kundennummer</h2>
                        <p>{{notification.payload.customerNumber}}</p>
                    </ion-text>
                </ion-item>
                <ion-item *ngIf="notification?.payload?.totalPrice">
                    <ion-text>
                        <h2>Warenkorbwert</h2>
                        <p>{{notification.payload.totalPrice | formatCurrency }}</p>
                    </ion-text>
                </ion-item>
                <ion-item *ngIf="notification?.payload?.deploymentType">
                    <ion-text>
                        <h2>Lieferart</h2>
                        <p>{{notification.payload.deploymentType}}</p>
                    </ion-text>
                </ion-item>
            </ng-container>
            <ion-item *ngIf="notification?.payload?.tour_message_text && notification.payload.tour_message_text.length > 0">
                <ion-text>
                    <h2 i18n>Details</h2>
                    <p innerHTML="{{notification.payload.tour_message_text | convertLineBreak}}"></p>
                </ion-text>
            </ion-item>
            <ion-item *ngIf="notification?.payload && notification?.payload['title'] && notification.type === NotificationsEnum.NEW_SURVEY">
                <ion-text i18n>
                    <h2>Name der Umfrage</h2>
                    <p>{{notification.payload['title']}}</p>
                </ion-text>
            </ion-item>
            <ion-item *ngIf="notification?.payload && notification?.payload['fromDate'] && notification?.payload['toDate']">
                <ion-text i18n>
                    <h2>Gültigkeit</h2>
                    <p>{{notification.payload['fromDate'] | dateTimeToDate: true}} - {{notification.payload['toDate'] | dateTimeToDate: true}}</p>
                </ion-text>
            </ion-item>
            <ion-item *ngIf="notification?.payload && notification?.payload['partner']">
                <ion-text i18n>
                    <h2>Partner</h2>
                    <p>{{notification.payload['partner']}}</p>
                </ion-text>
            </ion-item>
            <ion-item>
                <ion-text>
                    <h2 i18n>Erstelldatum</h2>
                    <p>{{notification.created_at | dateTimeToDate : true}}</p>
                </ion-text>
            </ion-item>
        </ion-list>
    </ng-container>
    <!-- Footer -->
    <ng-container footer>
        <ion-button class="button-tertiary" (click)="onDeleteClick()" i18n>Löschen</ion-button>
        <ion-button class="button-tertiary" i18n (click)="onChangeArchiveStatusClick()">Archivieren</ion-button>
        <ion-button *ngIf="isUnreadButton" class="button-tertiary" i18n (click)="markAsUnread()">Als ungelesen markieren</ion-button>
        <div appTooltip
             *ngIf="notification && (notification.type | notificationHasEntry)"
             ttp="bottom"
             [ttk]="notification.type | notificationGoToEntryPermission"
             class="clickable"
        >
            <ion-button i18n
                        *ngIf="notification && (notification.type | notificationHasEntry)"
                        [disabled]="notification.type | notificationGoToEntryPermission: true"
                        (click)="goToEntry()"
            >{{notification.type | notificationButtonLabelByType}}</ion-button>
        </div>
        <ion-button i18n *ngIf="notification && (notification.type | notificationAdditionalActionLabel : notification : order)" (click)="additionalActions()">
            {{notification.type | notificationAdditionalActionLabel : notification : order}}
        </ion-button>
    </ng-container>

</app-modal>
