import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionTriggerService {
    constructor() {
    }

    public dataChanged: EventEmitter<boolean> = new EventEmitter();
    public dataSetLoading: EventEmitter<boolean> = new EventEmitter();
}