import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { FetchPolicyKeys as FPK } from '../../../enums/fetch-policy-keys.enum';
import{ DataChangedKeys as DCK } from '../../../enums/data-changed-keys.enum';
import { UserStorageInterface } from '../../../interfaces/user-storage.interface';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { QueryWrapper } from '../query.wrapper';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { QueryFetchPolicy } from '../../../enums/api.enum';

const GetUserStorage = (queryName) => gql`
    query ${queryName}($limit: Int) {
        userStorage(limit: $limit) {
            storage
        }
    }
`;

export const AllUserStorageQueries = [
    GetUserStorage('test')
];

@Injectable()
export class UserStorageQueries extends QueryWrapper {
    fetchPolicies= {
        [FPK.getUserStorage]: QueryFetchPolicy.NETWORK_ONLY
    };
    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar,
    ) {
        super(apollo, dataChangedVar, {
            [DCK.userStorageChanged]: [FPK.getUserStorage]
        });
    }

    public getUserStorage(): Observable<UserStorageInterface> {
        const fetchPolicyKey = FPK.getUserStorage;
        return this.apollo.watchQuery({
            query: GetUserStorage(fetchPolicyKey),
            variables: {limit: GraphQLLimits.defaultLimit},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['userStorage']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<UserStorageInterface>;

    }
}
