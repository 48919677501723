import gql from 'graphql-tag';
import { backendUserConversationQueryAttributes } from './getUserConversations';

export default gql`
  query getUserConversation($id: ID!) {
    getUserConversation(id: $id) {
      ${backendUserConversationQueryAttributes}
    }
  }
`;
