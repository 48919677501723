import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { InputValidationInterface } from '../interfaces/input-validation.interface';
import { InputValidationTableNameEnum, InputValidationFieldEnum } from '../enums/input-validation.enum';
import { InputValidationQueries } from '../store/graphql/queries/inputValidation.graphql';

@Injectable({
    providedIn: 'root',
})
export class InputValidationService {
    public communicationZoneValidators: InputValidationInterface[] = [
        {
            tableName: 'communicationZone',
            field: 'title',
            isNullable: false,
            minLength: 1,
            maxLength: 128
        },
        {
            tableName: 'communicationZone',
            field: 'content',
            isNullable: false,
            minLength: 1,
            maxLength: 2000
        },
        {
            tableName: 'communicationZone',
            field: 'contactPerson',
            isNullable: false,
            minLength: 1,
            maxLength: 70
        }
    ];

    constructor(
        private inputValidationQueries: InputValidationQueries
    ) { }
    inputValidator: InputValidationInterface;

    static roundValidator(figure: number) {
        return Math.floor(figure / 5) * 5;
    }

    /**
     * returns all input validators
     *
     * @param tableName - The name of the table
     */
    getInputValidators(tableName: InputValidationTableNameEnum) {
        return this.inputValidationQueries.getInputValidatorsForTable(tableName);
    }

    getFormValidators(field: InputValidationFieldEnum, inputValidators: InputValidationInterface[], round = true) {
        const inputValidator = inputValidators ? inputValidators.find(validator => validator.field === field) : undefined;
        const formValidators = [];
        if (inputValidator) {
            formValidators.push(InputValidationService.noWhitespaceValidator);
            if (inputValidator.isNullable === false) {
                formValidators.push(Validators.required);
            }

            if (inputValidator.minLength !== null) {
                formValidators.push(Validators.minLength(
                    round ? InputValidationService.roundValidator(inputValidator.minLength): inputValidator.minLength
                ));
            }
            if (inputValidator.maxLength !== null) {
                formValidators.push(Validators.maxLength(
                    round ? InputValidationService.roundValidator(inputValidator.maxLength): inputValidator.maxLength
                ));
            }
        }
        return formValidators;
    }

    /**
     * check for whitespaces
     * @param control - Form control
     */
    static noWhitespaceValidator(control) {
        const isWhitespace = (control.value || '').toString().trim().length === 0 && (control.value || '').length > 0;
        const isValid = !isWhitespace;
        return isValid ? null : { whitespace: true };
    }

    /**
     * check that no numbers are contained in string
     * @param control - Form control
     */
    static noNumberValidator(control) {
        const hasNumber = control.value.search(/[0-9]/g) > -1;
        return !hasNumber ? null : { containsNumber: true };
    }
}
