import { Component } from '@angular/core';

@Component({
    selector: 'app-easter',
    templateUrl: './easter.component.html',
    styleUrls: ['./easter.component.scss'],
})
export class EasterComponent {

    constructor() {
    }

}
