<div>
    <ion-grid class="grid-background ion-no-padding">
        <ion-row class="ion-no-padding">
            <ion-col size="9" class="ion-no-padding ion-padding-horizontal"></ion-col>
            <ion-col size="15" class="ion-no-padding">
                <div #logoAnimation class="logo-animation"></div>
                <img src="{{logoLetterSrc}}" alt="" height="1228" width="878" class="menu-logo" />
            </ion-col>
        </ion-row>
    </ion-grid>
    <ion-grid class="grid-content ion-no-padding">
        <ion-row class="ion-no-padding">
            <ion-col size="9" class="ion-no-padding ion-padding-horizontal">
                <div class="login-form-wrapper">
                    <ion-img [src]="logoSrc" class="text-logo"></ion-img>
                </div>
            </ion-col>
            <ion-col size="15" class="ion-no-padding"></ion-col>
        </ion-row>
        <ion-row class="ion-no-padding">
            <ion-col size="9" class="ion-no-padding ion-padding-horizontal" *ngIf="waitingRoom === waitingRoomEnum.login">
                <div class="login-form-wrapper login">
                    <ion-text i18n color="primary" class="login-title">Bitte einloggen</ion-text>
                    <ion-text i18n class="login-text">Sanacorp Connect verbindet Sie mit der digitalen Welt der Sanacorp.<br /><br /> Nutzen Sie umfassende Kommunikationsmöglichkeiten, direkte Informationen und digitale Lösungen Ihrer Sanacorp.</ion-text>

                    <ion-button
                            (keyup.enter)="onLoginButtonPressed()"
                            (click)="onLoginButtonPressed()" i18n>
                        Mit Sanacorp Login anmelden
                    </ion-button>
                    <div class="text-link-container register">
                        <ion-text i18n>Noch kein Login?</ion-text>
                        <a [href]="'mailto:' + receiverMailAddress + mailContent" class="text-link" i18n>
                            Jetzt Zugangsdaten anfordern
                        </a>
                    </div>

                    <div class="copyright">
                        <ion-text>&copy; {{ currentYear }} </ion-text>
                        <ion-text i18n>Copyright Alle Rechte vorbehalten, Sanacorp Pharmahandel GmbH</ion-text>
                    </div>
                </div>
            </ion-col>
            <ion-col size="9" class="ion-no-padding ion-padding-horizontal" *ngIf="waitingRoom === waitingRoomEnum.isMaintenance">
                <div class="login-form-wrapper notice">
                    <div class="text-link-container">
                        <div class="headline">
                            <ion-text i18n>Wartungs-</ion-text>
                            <ion-text i18n></ion-text>
                            <ion-text i18n>arbeiten</ion-text>
                        </div>
                        <markdown [data]="maintenanceText"></markdown>
                    </div>
                    <div class="copyright">
                        <ion-text>&copy; {{ currentYear }} </ion-text>
                        <ion-text i18n>Copyright Alle Rechte vorbehalten, Sanacorp Pharmahandel GmbH</ion-text>
                    </div>
                </div>
            </ion-col>
            <ion-col size="9" class="ion-no-padding ion-padding-horizontal" *ngIf="waitingRoom === waitingRoomEnum.isNewUser">
                <div class="login-form-wrapper notice">
                    <div class="headline">
                        <ion-text i18n>Lieber Nutzer!</ion-text>
                    </div>
                    <ion-text class="login-text" i18n>
                        Die Daten Ihrer Apotheke konnten noch nicht geladen werden. Sobald die Daten geladen sind, werden Sie automatisch in die Anwendung geleitet.
                        Sollte innerhalb des aktuellen Tages keine Weiterleitung erfolgen, kontaktieren Sie bitte Ihren regionalen Kundenservice.
                        <br/><br/>Vielen Dank Ihre Sanacorp
                    </ion-text>
                    <div class="copyright">
                        <ion-text>&copy; {{ currentYear }} </ion-text>
                        <ion-text i18n>Copyright Alle Rechte vorbehalten, Sanacorp Pharmahandel GmbH</ion-text>
                    </div>
                </div>
            </ion-col>
            <ion-col size="15" class="ion-no-padding">
                <app-easter *ngIf="isEaster"></app-easter>
                <div>
                    <ion-text i18n>Sanacorp Connect</ion-text>
                    <ion-text i18n></ion-text>
                    <ion-text i18n>Das Portal zu Sanacorp</ion-text>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
    <ion-grid class="legal-links">
        <ion-row>
            <ion-col size="9"></ion-col>
            <ion-col size="15" class="ion-no-padding ion-text-right">
                <ion-button fill="clear" (click)="onLegalLinkClick(staticPagesTypeEnum.TERMS_OF_USE)">
                    <ion-label color="light" i18n>Nutzungsbedingungen</ion-label>
                </ion-button>
                <ion-button fill="clear" (click)="onLegalLinkClick(staticPagesTypeEnum.DATA_POLICY)">
                    <ion-label color="light" i18n>Datenschutz</ion-label>
                </ion-button>
                <ion-button fill="clear" (click)="onLegalLinkClick(staticPagesTypeEnum.IMPRINT)">
                    <ion-label color="light" i18n>Impressum</ion-label>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>
