import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UtilService {
    debounce(func, wait) {
        let timeout;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        };
    }

    debouncePromise(func, wait) {
        let timeout;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timeout);
            return new Promise((resolve, reject) => {
                timeout = setTimeout(() => {
                    timeout = null;
                    func.apply(context, args)
                        .then(resolve)
                        .catch(reject);
                }, wait);
            });
        };
    }

}
