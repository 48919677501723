import { AuditLogEnum } from '../enums/auditLog.enum';

export const AuditLogConfig = {
    [AuditLogEnum.ORDER_EXPIRE_EXTEND_REQUESTED]:
        {title: 'Es wurde eine Verlängerung des Auftrags {{order}} bis {{expiryDate}} von {{user}} beantragt.'},
    [AuditLogEnum.ORDER_EXPIRE_EXTEND_REQUESTED_SENT]:
        {title: 'Der Antrag zur Verlängerung des Auftrags {{order}} wurde erfolgreich von Sanacorp Connect entgegen genommen.'},
    [AuditLogEnum.ORDER_CANCELLATION_REQUESTED]:
        {title: 'Stornierung des Autrags {{order}} wurde von {{user}} beauftragt. Stornierungsgrund: {{reason}}'},
    [AuditLogEnum.ORDER_CANCELLATION_REQUESTED_SENT]:
        {title: 'Der Antrag zur Stornierung des Auftrags {{order}} wurde erfolgreich von Sanacorp Connect entgegen genommen.'},
    [AuditLogEnum.REORDER_REQUESTED]:
        {title: 'Nachbestellung des Autrags {{order}} wurde von {{user}} beauftragt.'},
    [AuditLogEnum.REORDER_REQUESTED_SENT]:
        {title: 'Der Antrag zur Nachbestellung des Auftrags {{order}} wurde erfolgreich von Sanacorp Connect entgegen genommen.'},
    [AuditLogEnum.ORDER_IS_CHANGED]:
        {title: 'Änderungen für Auftrag {{order}} sind von Sanacorp verfügbar.'},
    [AuditLogEnum.ORDER_IS_CANCELLED]:
        {title: 'Der Autrag {{order}} wurde storniert. Stornierungsgrund: {{reason}}'},
    [AuditLogEnum.ORDER_IS_CONFIRMED]:
        {title: 'Lieferung für Auftrag {{order}} wurde von {{user}} bestätigt.'},
    [AuditLogEnum.ORDER_CANCEL_ERROR]:
        {title: '{{message}}'},
    [AuditLogEnum.ORDER_CANCEL_SUCCESS]:
        {title: 'Der Antrag zur Stornierung des Auftrags {{order}} wurde erfolgreich zur Überprüfung übermittelt.'},
    [AuditLogEnum.ORDER_EXTEND_ERROR]:
        {title: '{{message}}'},
    [AuditLogEnum.ORDER_EXTEND_SUCCESS]:
        {title: 'Der Antrag zur Verlängerung des Auftrags {{order}} wurde erfolgreich zur Überprüfung übermittelt.'},
    [AuditLogEnum.REORDER_SUCCESS]:
        {title: 'Der Antrag zur Nachbestellung des Auftrags {{order}} wurde erfolgreich zur Überprüfung übermittelt.'}
};


export const GetAuditLogGroupTitle = (type: string): string => {
  switch (type) {
    case AuditLogEnum.ORDER_EXPIRE_EXTEND_REQUESTED:
    case AuditLogEnum.ORDER_EXPIRE_EXTEND_REQUESTED_SENT:
    case AuditLogEnum.ORDER_EXTEND_ERROR:
    case AuditLogEnum.ORDER_EXTEND_SUCCESS:
      return 'Verlängerung';
    case AuditLogEnum.ORDER_CANCELLATION_REQUESTED:
    case AuditLogEnum.ORDER_CANCELLATION_REQUESTED_SENT:
    case AuditLogEnum.ORDER_CANCEL_ERROR:
    case AuditLogEnum.ORDER_CANCEL_SUCCESS:
    case AuditLogEnum.ORDER_IS_CANCELLED:
      return 'Stornierung';
    case AuditLogEnum.REORDER_REQUESTED:
    case AuditLogEnum.REORDER_REQUESTED_SENT:
    case AuditLogEnum.REORDER_SUCCESS:
      return 'Nachbestellung';
    case AuditLogEnum.ORDER_IS_CHANGED:
      return 'Änderung';
    case AuditLogEnum.ORDER_IS_CONFIRMED:
      return 'Ware angekommen';
    default:
      return '';
  }
};
