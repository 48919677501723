import { Component, Input, OnInit, OnChanges, ViewChild, SimpleChanges, HostListener } from '@angular/core';

import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Array from 'd3-array';
import 'd3-transition';

@Component({
    selector: 'app-statistics-simple-bar-chart',
    templateUrl: './statistics-simple-bar-chart.component.html',
    styleUrls: ['./statistics-simple-bar-chart.component.scss'],
})
export class StatisticsSimpleBarChartComponent implements OnInit, OnChanges {

    constructor() {
        this.generateUniqueId();
    }
    @Input() data: [{label: string, value: number, highlighted: boolean}];
    @Input() isLoading = false;
    @Input() unit = '';
    @Input() size = '';
    @ViewChild('simpleBarChart') simpleBarChart;

    uniqueID = '';

    // Chart Variables
    width: number;
    height: number;
    svg: any;
    chart: any;
    lines: any;
    tooltip: any;
    barLabels: any;
    barColors: any;
    textColors: any;
    borderColors: any;
    xScale: any;
    yScale: any;
    xDomain: any;
    yDomain: any;
    yMax = 0;
    barWidth = 0;
    isChartInitialized = false;

    highlighting = false;
    tries = 0;

    @HostListener('window:resize')
    onResize() {
        if(this.simpleBarChart && this.simpleBarChart.nativeElement
        && this.simpleBarChart.nativeElement.offsetHeight) {
            // Remove svg first to make sure we receive the right sizes
            d3.select('#'+this.uniqueID + ' svg').remove();

            this.height = this.simpleBarChart.nativeElement.offsetHeight;
            this.width = this.simpleBarChart.nativeElement.offsetWidth;
            this.initSVG();
            this.drawChart();
        }
    }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.isLoading && this.data
        || changes.data && !this.isLoading) {
            if(!this.isChartInitialized) {
                this.initChart();
            }
        }
    }

    /**
     * Called if ion view did enter
     */
    ionViewDidEnter() {
        if(!this.isChartInitialized) {
            this.initChart();
        }
    }

    /**
     * Generate a unique id to prevent duplicate id's in html
     */
    generateUniqueId() {
        this.uniqueID = 'simpleBarChart_' + Math.round(Math.random() * 10000000);
    }

    /**
     * Check if it is possible to create the chart, initialize it if it is possible
     */
    initChart() {
        if(this.simpleBarChart && this.simpleBarChart.nativeElement
        && this.simpleBarChart.nativeElement.offsetHeight && !this.isChartInitialized && this.data) {
            this.height =  this.simpleBarChart.nativeElement.offsetHeight;
            this.width =  this.simpleBarChart.nativeElement.offsetWidth;
            this.initSVG();
            this.drawChart();
            this.isChartInitialized = true;
        } else if(this.tries < 1000) {
            this.tries++;
            setTimeout(() => {
                this.initChart();
            }, 250);
        }
    }

    /**
     * Initialize the chart svg
     */
    initSVG() {
        d3.select('#'+this.uniqueID + ' svg').remove();
        this.svg = d3.select('#'+this.uniqueID)
            .append('svg')
            .attr('height', '100%')
            .attr('width', '100%')
            .attr('preserveAspectRatio', 'xMinYMax meet')
            .attr('viewBox', `0 0 ${this.width} ${this.height}`);

        this.tooltip = d3.select('#'+this.uniqueID)
            .append('div')
            .attr('class', 'tooltip tooltip-top')
            .style('opacity', 0);

        this.chart = this.svg.append('g')
            .attr('class', 'bars')
            .attr('transform', 'translate(0,0)');
        this.lines = this.svg.append('g')
            .attr('class', 'lines')
            .attr('transform', 'translate(0,0)');
        this.barLabels = this.svg.append('g')
            .attr('class', 'labels')
            .attr('transform', 'translate(0,0)');

        this.yMax = d3Array.max(this.data, item => item.value);
        this.yMax = this.yMax > 0 ? this.yMax : 100;


        this.xDomain = this.data.map(item => item.label);
        this.yDomain = [0, this.yMax];
        this.barWidth = Math.floor(this.width / this.data.length) - 1;

        this.xScale = d3Scale.scaleBand()
            .padding(0.05)
            .domain(this.xDomain)
            .range([0, this.width + (this.barWidth / this.data.length)]);

        this.yScale = d3Scale.scaleLinear()
            .domain(this.yDomain)
            .range([this.height, 0]);

        this.barColors = d3Scale.scaleOrdinal()
            .range(['var(--ion-color-light)', 'var(--ion-color-primary-light)']);
        this.textColors = d3Scale.scaleOrdinal()
            .range(['var(--ion-color-medium)', 'var(--ion-color-primary)']);
        this.borderColors = d3Scale.scaleOrdinal()
            .range(['var(--ion-color-light)', 'var(--ion-color-primary)']);

    }


    /**
     * Draw the chart depending on the values
     */
    drawChart() {
        this.xScale.domain(this.data.map(item => item.label));
        this.yScale.domain([0, this.yMax]);

        const update = this.chart.selectAll('.bar')
            .data(this.data);
        update.remove().exit();

        this.drawLabels();
        update.enter()
            .append('rect')
            .on('mouseover', (event, item) => this.handleMouseOver(event, item))
            .on('mouseout', (event, item) => this.handleMouseOut(event, item))
            .attr('class', item => (item.highlighted ? 'bar highlighted' : 'bar'))
            .attr('x', item => this.xScale(item.label))
            .attr('width', this.xScale.bandwidth())
            .style('fill', item => this.barColors((item.highlighted ? 2 : 1)))
            .attr('y', item => this.yScale(item.value))
            .attr('height', item => this.height - this.yScale(item.value));

        update.enter()
            .append('rect')
            .attr('class', item => (item.highlighted ? 'bar highlighted' : 'bar') + ' border')
            .attr('x', item => this.xScale(item.label))
            .attr('y', item => this.yScale(item.value))
            .attr('width', this.xScale.bandwidth())
            .attr('height', 1)
            .style('fill', item => this.borderColors((item.highlighted ? 2 : 1)));

    }

    /**
     * User hovers a bar
     *
     * @param event - Mouseover event
     * @param item - the item
     */
    handleMouseOver(event, item) {
        d3.select(event.target)
            .style('fill', this.barColors(2));

        this.tooltip.transition()
            .duration(200)
            .style('opacity', 1);
        this.tooltip.html(item.value + ' ' + this.unit)
            .style('left', (this.xScale(item.label) - (this.barWidth / 2)) + 'px')
            .style('top', (this.yScale(item.value) - 5 ) + 'px')
            .style('transform', 'translateY(-100%)');
    }

    /**
     * User stops to hover the bar
     *
     * @param event - Mouseout event
     * @param item - the item
     */
    handleMouseOut(event, item) {
        d3.select(event.target)
            .style('fill', this.borderColors((item.highlighted ? 2 : 1)));
        this.tooltip.transition()
            .duration(500)
            .style('opacity', 0);
    }


    /**
     * Draw labels
     */
    drawLabels() {
        const update = this.barLabels.selectAll('.label')
            .data(this.data);
        update.remove().exit();

        update.enter().append('g')
            .attr('class', 'label')
            .attr('transform', item => 'translate(' + (this.xScale(item.label)  + (this.barWidth / 2) ) + ',0)')
            .append('text')
            .attr('class', item => item.highlighted ? 'current' : '')
            .attr('y', this.height - 4)
            .attr('text-anchor', 'middle')
            .text(item => item.label)
            .style('font-size', () => this.size === 'small' ?  'var(--ion-font-size-small-text)' : 'var(--ion-font-size-text)')
            .style('font-weight', () => 'var(--ion-font-weight-subheadline)')
            .style('fill',item => this.getTextColor(item.highlighted));
    }

    /**
     * Returns the color depending on the delivery
     *
     * @param highlighted - Use a different text color if highlighted is true
     */
    getTextColor(highlighted = false) {
        return this.textColors((highlighted ? 2 : 1));
    }

    /**
     * track by
     *
     * @param index - Index of the item
     * @param item - The item to track
     */
    trackBy = (index, item) => {
        return item.label;
    };
}
