import { Pipe, PipeTransform } from '@angular/core';
import { FilterResultItemInterface } from '../interfaces/filter-result.interface';
import { TableFilterTypeEnum } from '../enums/table.enum';
import { TableFilterConfigInterface } from '../interfaces/table.interface';

/**
 * Filters a list of strings by the given filter (string).
 * Use the limit parameter to splice the amount of returned values.
 * The changedIndicator is used to trigger the pipe when the filter changes.
 *
 * Usage:
 *  ... | filterStringArray:test:10
 */
@Pipe({
    name: 'filterResultValue'
})
export class FilterResultValuePipe implements PipeTransform {

    transform(conf: FilterResultItemInterface | TableFilterConfigInterface, unformattedValue: string, changedIndicator = 0): string {
        if(!conf) {
            return '';
        }
        let value : any = unformattedValue;
        if (value === undefined) {
            return '';
        }
        let translations = [];
        if ('translation' in conf) {
            translations = [...conf.translation];
        } else if('selectableValues' in conf) {
            translations = [...conf.selectableValues];
        }

        if('useCheckboxes' in conf && conf.useCheckboxes
        || 'type' in conf && conf.type === TableFilterTypeEnum.multiSelect) {
            const labels = [];
            let values = value ? value.split(',') : [];
            if (values.includes('ALL') || !value) {
                return translations[0].shortcode;
            }

            const availableParents = translations.filter(val => val.id.toString().indexOf('_GROUP') >= 0 && !val['excludeFromFilter']);
            const parents = values.filter(val => val.indexOf('_GROUP') >= 0);
            parents.forEach(parent => {
               const translObj = translations.find(transl => transl.id === parent);
               if(translObj) {
                   if(translObj['children']) {
                       translObj['children'].forEach(child => {
                           values = [...values.filter(val => val !== child.id)];
                       });
                   }
               }
            });

            if(parents.length === values.length && parents.length === availableParents.length) {
                return translations[0].shortcode;
            }


            translations
              .filter(transl => transl['children'])
              .forEach(transl => transl['children']
                .forEach(child => translations.push(child)));

            values.forEach(val => {
                const translObj = translations.find(transl => transl.id === val);
                if(translObj) {
                    labels.push(translObj.shortcode || translObj.title);
                }
            });

            if(labels.length > 2) {
                return labels.slice(0,2).join(', ') + ', ...';
            }

            return labels.join(', ');
        }
        if (!value && translations) {
            value = translations[0].id;
        } else if (!translations) {
            return value;
        }
        const translationObject = translations.find(translation => translation.id === value);
        const result = translationObject ? translationObject.shortcode : value;
        return result;
    }
}
