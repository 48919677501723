import gql from 'graphql-tag';
import { updateMessageAttributes } from '../attributes';

export default gql`
  subscription updatedReceivedMessage($recipientId: ID!) {
    updatedReceivedMessage(recipientId: $recipientId) {
      ${updateMessageAttributes}
    }
  }
`;
