import { TranslateService } from '@ngx-translate/core';
import { Dictionary } from 'ts-essentials';
import { ChatPartnerMetadata } from '../../../../essentials/types/src/chatPartnerMetadata';
import { isExternalUser, isPharmacyChatUser, MeaUser } from '../../../../essentials/types/src/chatUser';
import { ConversationWithLastMessage, isConversationOpen } from '../../../../essentials/types/src/conversation';
import ConversationType from '../../../../essentials/types/src/conversationType';
import { ChatPartnerMetadataUtil } from '../../../../essentials/util/src/chat-partner-metadata.util';
import { MeaDateUtil } from '../../../../essentials/util/src/mea-date.util';
import { ChatConversationsUtil } from '../../../../pharmacy-only/src/util/chat-conversations.util';
import { ConversationWithMetadata, SconnectConversation } from '../types/sconnectConversation';

export class SconnectConversationsUtil extends ChatConversationsUtil {
  public static mapConversationsToSconnectConversations(
    conversationsWithLastMessage: ConversationWithLastMessage[],
    decryptedChatPartnerMetadataDictionary: Dictionary<ChatPartnerMetadata>,
    unreadMessagesCountPerConversation: { [conversationId: string]: number },
    translateService: TranslateService
  ): SconnectConversation[] {
    const sortedConversations = this.sortConversations(conversationsWithLastMessage);
    const conversationsWithMetadata = this.addMetadataToConversations(
      sortedConversations,
      decryptedChatPartnerMetadataDictionary,
      unreadMessagesCountPerConversation
    );
    const sconnectConversations: SconnectConversation[] = [];
    for (const conversationWithMetadata of conversationsWithMetadata) {
      sconnectConversations.push(this.mapToSconnectConversation(conversationWithMetadata, translateService));
    }
    return sconnectConversations;
  }

  private static mapToSconnectConversation(
    conversationWithMetadata: ConversationWithMetadata,
    translateService: TranslateService
  ): SconnectConversation {
    const conversation = conversationWithMetadata.conversation.conversation;
    const intent =
      conversation.type === ConversationType.GOOGLE_BUSINESS
        ? 'GoogleMyBusiness'
        : conversation.appContext || conversation.intent;
    return {
      isConversationOpen: conversationWithMetadata.isConversationOpen,
      lastMessageTimestamp: conversationWithMetadata.lastMessageTimestamp,
      formattedLastMessageTimestamp: MeaDateUtil.transformTimestamp(conversationWithMetadata.lastMessageTimestamp),
      unreadMessagesCount: conversationWithMetadata.unreadMessagesCount,
      chatPartnerName: conversationWithMetadata.chatPartnerDisplayName,
      isDecryptingChatPartnerName:
        conversationWithMetadata.isDecryptingChatPartnerName ||
        conversationWithMetadata.isDecryptingChatPartnerNickname,
      id: conversation.id,
      intent: intent ? translateService.instant(intent) : undefined,
    };
  }

  private static addMetadataToConversations(
    conversationsWithLastMessage: ConversationWithLastMessage[],
    decryptedChatPartnerMetadataDictionary: Dictionary<ChatPartnerMetadata>,
    unreadMessagesCountPerConversation: { [conversationId: string]: number }
  ): ConversationWithMetadata[] {
    const conversationsWithMetadata: ConversationWithMetadata[] = [];
    for (const conversation of conversationsWithLastMessage) {
      const chatPartner = conversation.conversation.chatPartner;
      const chatPartnerId = ChatPartnerMetadataUtil.getChatPartnerIdAsPharmacy(conversation.conversation);
      const chatPartnerMetadata = decryptedChatPartnerMetadataDictionary[chatPartnerId];
      const chatPartnerName = isPharmacyChatUser(chatPartner)
        ? (chatPartner as MeaUser).pharmacy?.name || 'Unbekannte Apotheke'
        : chatPartnerMetadata?.decryptedChatPartnerChatname ||
          chatPartner.chatname ||
          (isExternalUser(chatPartner) ? 'Google-Nutzer' : '');

      const isDecryptingChatPartnerName = chatPartnerMetadata?.chatPartnerChatnameDecryptionStatus === 'encrypted';
      const chatPartnerNickname = chatPartnerMetadata?.decryptedChatPartnerNickname;
      const isDecryptingChatPartnerNickname = chatPartnerMetadata?.chatPartnerNicknameDecryptionStatus === 'encrypted';

      conversationsWithMetadata.push({
        conversation,
        isConversationOpen: isConversationOpen(conversation.conversation),
        lastMessageTimestamp: conversation.lastMessage.createdAt,
        unreadMessagesCount: unreadMessagesCountPerConversation[conversation.conversation.id] || 0,
        isDecryptingChatPartnerName,
        isDecryptingChatPartnerNickname,
        chatPartnerDisplayName: this.getDisplayName(chatPartnerName, chatPartnerNickname),
      });
    }
    return conversationsWithMetadata;
  }
}
