import { Injectable } from '@angular/core';
import { NavigationStart, Router, RouterEvent, Event } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { PopoverOptions } from '@ionic/core';
import { filter, Subscription } from 'rxjs';
import { unsubscribe, unsubscribeAll } from '../core.utils';

@Injectable({
    providedIn: 'root',
})
export class PopoverService {
    routerSubscription: Subscription;
    currentUrl: string;

    popoverIds: Array<string> = [];

    constructor(
        public popoverController: PopoverController,
        private router: Router
    ) { }

    public async create(opts: PopoverOptions): Promise<HTMLIonPopoverElement> {
        return await this.popoverController.create({...opts, id: this.generateUniqueId()});
    }

    clearRouterSubscriptions = () => {
        unsubscribeAll([
            this.routerSubscription
        ]);
    }

    async present(popover: HTMLIonPopoverElement): Promise<void> {
        this.clearRouterSubscriptions();
        this.currentUrl = this.router.url;
        unsubscribe(this.routerSubscription);
        this.routerSubscription = this.router.events
            .pipe(filter( event => event instanceof NavigationStart))
            .subscribe((event: Event|RouterEvent) => {
            if (event instanceof RouterEvent && event.url !== this.currentUrl) {
                if (this.popoverController) {
                    this.dismiss();

                    // close all existing popovers
                    while(this.popoverIds.length > 0) {
                        this.dismiss(undefined, undefined, this.popoverIds.shift());
                    }
                    this.clearRouterSubscriptions();
                }
            }
        });
        return await popover.present();
    }

    public async dismiss(data?: any, role?: string, id?: string): Promise<boolean> {
        this.clearRouterSubscriptions();
        try {
            return await this.popoverController.dismiss(data, role, id);
        } catch {}
    }

    /**
     * Generate a unique id for closing the element later
     * @private
     */
    private generateUniqueId() {
        let id = 'popover_view_' + Math.round(Math.random() * 10000000);
        while(this.popoverIds.indexOf(id) > -1) {
            id = 'popover_view_' + Math.round(Math.random() * 10000000);
        }
        this.popoverIds.push(id);
        return id;
    }
}
