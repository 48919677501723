import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { AppointmentReminderInterface } from '../../../../core/core.interfaces';
import {
    AppointmentQueries,
    AppointmentMutations,
    InformationMutations
} from '../../../../core/core.store';
import { ModalService } from '../../../../core/services/modal.service';
import { NotificationsReminderTypeEnum } from '../../../../core/core.enums';
import { NavigationRoutesEnum } from '../../../../navigation/navigation.routes';
import { unsubscribe, unsubscribeAll } from '../../../../core/core.utils';

@Component({
    selector: 'app-notification-reminder-view',
    templateUrl: './notification-reminder-view.component.html',
    styleUrls: ['./notification-reminder-view.component.scss'],
})
export class NotificationReminderViewComponent implements OnInit, OnChanges {
    @Input() notificationReminderId: number;
    @Input() notificationType: NotificationsReminderTypeEnum;
    @Input() isUnreadButton = true;

    notification: AppointmentReminderInterface;
    notificationObservable: Subscription;

    constructor(
        private appointmentQueries: AppointmentQueries,
        private appointmentMutations: AppointmentMutations,
        private informationMutations: InformationMutations,
        private modalService: ModalService,
        private navCtrl: NavController
    ) {
    }

    ngOnInit() {
       this.refreshData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.notificationReminderId) {
            this.ngOnInit();
        }
    }

    ionViewWillLeave(): void {
        unsubscribeAll([
            this.notificationObservable
        ]);
    }

    /**
     * Marks notification as unread
     */
    async markAsUnread() {
        this.changeNotificationReadState();
        this.refreshData(false);
        await this.modalService.dismiss();
    }

    /**
     * Refreshes the data
     *
     * @param update - Update read status of notification
     */
    refreshData(update = true) {
        unsubscribe(this.notificationObservable);
        switch (this.notificationType) {
            case NotificationsReminderTypeEnum.APPOINTMENT_REMINDER:
                this.notificationObservable = this.appointmentQueries.getAppointmentsReminderById(this.notificationReminderId)
                    .subscribe(notification => {
                    if (notification) {
                        this.notification = notification;
                        unsubscribe(this.notificationObservable);
                        if(update) {
                            this.changeNotificationReadState();
                        }
                    }
                });
                break;
            case NotificationsReminderTypeEnum.SEMINAR_REGISTRATION_REMINDER:
                return '';
        }
    }

    /**
     * Update read status of notification
     */
    changeNotificationReadState() {
        if ( this.notification) {
            this.informationMutations.convertReminderToNotification(this.notificationType, this.notification.id);
        }
    }

    /**
     * Delete single notification
     */
    async onDeleteSingleNotification() {
        if(this.notificationReminderId) {
            this.appointmentMutations.deleteSingleAppointmentReminder(this.notificationReminderId);
            this.refreshData(false);
            await this.modalService.dismiss();
        }
    }

    /**
     * Goes to the source of the notification
     */
    async onGoToEntry() {
        switch(this.notificationType) {
            case NotificationsReminderTypeEnum.APPOINTMENT_REMINDER:
                // Navigate to calendar
                await this.modalService.dismiss();
                return await this.navCtrl.navigateRoot(NavigationRoutesEnum.calendar);
            case NotificationsReminderTypeEnum.SEMINAR_REGISTRATION_REMINDER:
                // Navigate to seminars
                await this.modalService.dismiss();
                return await this.navCtrl.navigateRoot(NavigationRoutesEnum.seminars);
            default:
                // Navigate to dashboard
                await this.modalService.dismiss();
                return await this.navCtrl.navigateRoot(NavigationRoutesEnum.dashboard);
        }
    }
}
