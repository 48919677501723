import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { EnvironmentInterface } from '../../interfaces/environment.interface';

export const APP_VERSION_KEY = 'sconnect-app-version';

export const GetVersionState = gql`
    query GetVersionState {
        versionState @client {
            versionsId
            reloadInformation
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class VersionStateVar {
    private defaultValue: EnvironmentInterface = {
        versionsId: 0,
        reloadInformation: false
    };

    private versionStateObservable: Observable<EnvironmentInterface> = new Observable<EnvironmentInterface>();
    private apollo: Apollo;
    private cache;

    constructor(apollo: Apollo) {
        this.apollo = apollo;
    }

    init() {
        const storageVersion = window.localStorage.getItem(APP_VERSION_KEY);
        this.defaultValue.versionsId = storageVersion && parseInt(storageVersion, 10) ? parseInt(storageVersion, 10) : 0;
        if(this.apollo.client) {
            this.cache = this.apollo.client.cache;
            this.versionStateObservable = new Observable<EnvironmentInterface>(subscriber => {
                this.cache.watch({
                    query: GetVersionState,
                    callback: res => {
                        subscriber.next(res.result && res.result.versionState || this.defaultValue);
                    },
                    immediate: true,
                    optimistic: true,
                });
            });
        }
    }

    get(): Observable<EnvironmentInterface> {
        this.init();
        return this.versionStateObservable;
    }

    set(versionState: EnvironmentInterface) {
        this.init();
        window.localStorage.setItem(APP_VERSION_KEY, versionState.versionsId.toString());
        if (this.cache) {
            this.cache.writeQuery({
                query: GetVersionState,
                data: {
                    versionState
                }
            });
        }
    }
}
