<app-modal i18n-title title="{{notificationType | labelNotificationReminderType }}" >
    <ng-container headerActions></ng-container>
    <ng-container content *ngIf="notification">
        <ion-list class="modal-list">
            <ion-item>
                <ion-text>
                    <h2 i18n>Id</h2>
                    <p>{{notification.id}}</p>
                </ion-text>
            </ion-item>
            <ion-item>
                <ion-text>
                    <h2 i18n>Nachricht</h2>
                    <p>{{notification.appointment.dateTimeFrom | dateTimeToDate : true}} {{notification.appointment.title}}</p>
                </ion-text>
            </ion-item>
            <ion-item>
                <ion-text>
                    <h2 i18n>Datum</h2>
                    <p>{{notification.reminder | formatDateTimeInComparisonToToday}}</p>
                </ion-text>
            </ion-item>
            <ion-item>
                <ion-text>
                    <h2 i18n>Erstelldatum</h2>
                    <p>{{notification.reminder | dateTimeToDate : true}}</p>
                </ion-text>
            </ion-item>
        </ion-list>
    </ng-container>
    <!-- Footer -->
    <ng-container footer>
        <ion-button class="button-tertiary" i18n (click)="onDeleteSingleNotification()">Benachrichtigung löschen</ion-button>
        <ion-button *ngIf="isUnreadButton" class="button-tertiary" i18n (click)="markAsUnread()">Als ungelesen markieren</ion-button>
        <ion-button i18n (click)="onGoToEntry()">Zum Eintrag</ion-button>
    </ng-container>
</app-modal>
