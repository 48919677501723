import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import {
    FetchPolicyKeys as FPK,
    DataChangedKeys as DCK, QueryFetchPolicy
} from '../../../core.enums';
import { SortingInterface } from '../../../interfaces/sorting.interface';
import { defaultSorting } from '../../../config/sorting.config';
import { SortTableEnum } from '../../../enums/sort-table.enum';
import { QueryWrapper } from '../query.wrapper';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';

export const GetSorting = (queryName) => gql`
    query ${queryName}($tableName: String!) {
        sorting(limit:1, where: {tableName: {_eq: $tableName}}) {
            id
            tableName
            fieldName
            sortDirection
        }
    }
`;

export const AllSortingQueries = [
    GetSorting('test')
];

@Injectable()
export class SortingQueries extends QueryWrapper {
    fetchPolicies = {
        [FPK.getSorting]: QueryFetchPolicy.NETWORK_ONLY
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar
    ) {
        super(apollo, dataChangedVar, {
            [DCK.sortingChanged]: [
                FPK.getSorting
            ]
        });
    }


    /**
     * Returns all sorting fields
     */
    public getSorting(tableName: SortTableEnum = SortTableEnum.order): Observable<SortingInterface> {
        const fetchPolicyKey = FPK.getSorting;
        // without the sortingArray variable - there will be type errors in ide
        const sortingArray: Observable<Array<SortingInterface>> = this.apollo.watchQuery({
                query: GetSorting(fetchPolicyKey),
                variables: {tableName},
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['sorting'])) as Observable<Array<SortingInterface>>;

        return sortingArray.pipe(map((sorting: Array<SortingInterface>) => {
            return sorting && sorting[0] ? sorting[0] : defaultSorting(tableName);
        })).pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<SortingInterface>;
    }
}

