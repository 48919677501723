import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { MeaUser } from '../../../../../essentials/types/src/chatUser';
import { MeaUserMappingUtil } from '../../../../../essentials/util/src/mea-user-mapping.util';
import updateGuestNotificationEmail from '../../graphql/mutations/updateGuestNotificationEmail';
import getMyEndUser from '../../graphql/queries/getMyEndUser';
import getMyPharmacyUser from '../../graphql/queries/getMyPharmacyUser';
import endUserUpdated from '../../graphql/subscriptions/endUserUpdated';
import pharmacyChatUserUpdated from '../../graphql/subscriptions/pharmacyChatUserUpdated';
import { AppsyncService } from './appsync.service';

@Injectable({ providedIn: 'root' })
export class AppsyncUserService {
  constructor(private appSync: AppsyncService) {}

  // ************* Queries *************

  async getUserFromBackend(userType: 'ENDUSER' | 'PHARMACY'): Promise<MeaUser | undefined> {
    const client = await this.appSync.getClient();
    if (userType === 'ENDUSER') {
      const {
        data: { meEndUser },
      } = await client.query({ query: getMyEndUser });
      if (!meEndUser) {
        return undefined;
      }
      return { ...meEndUser, favoritePharmaciesCustomerIds: meEndUser.favoriteAppPharmaciesIds };
    } else if (userType === 'PHARMACY') {
      const {
        data: { mePharmacyChatUser },
      } = await client.query({ query: getMyPharmacyUser });
      return mePharmacyChatUser ? MeaUserMappingUtil.mapBackendMeaUserToMeaUser(mePharmacyChatUser) : undefined;
    }
    return undefined;
  }

  // ************* Mutations *************

  async updateGuestNotificationEmail(cognitoId: string, guestNotificationEmail: string): Promise<MeaUser> {
    const client = await this.appSync.getClient();
    const { data } = await client.mutate({
      mutation: updateGuestNotificationEmail,
      variables: { cognitoId, guestNotificationEmail },
    });
    return data.updateGuestNotificationEmail;
  }

  // ************* Subscriptions *************

  updatedEndUser(cognitoId: string): Observable<MeaUser> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: endUserUpdated,
            variables: { cognitoId },
          })
          .pipe(map(({ data: { endUserUpdated: user } }) => MeaUserMappingUtil.mapBackendMeaUserToMeaUser(user)))
      )
    );
  }

  updatedPharmacyChatUser(cognitoId: string): Observable<MeaUser> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: pharmacyChatUserUpdated,
            variables: { cognitoId },
          })
          .pipe(
            map(({ data: { pharmacyChatUserUpdated: user } }) => MeaUserMappingUtil.mapBackendMeaUserToMeaUser(user))
          )
      )
    );
  }
}
