import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { register } from 'swiper/element/bundle';

import { FiltersComponent } from './filters.component';
import { SimpleModalModule } from '../simple-modal/simple-modal.module';
import { ModalModule } from '../modal/modal.module';
import { SwiperDirective } from '../_core/directives/swiper.directive';

// Register swiper components
register();


@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        SimpleModalModule,
        ModalModule,
        FormsModule
    ],
    exports: [
        FiltersComponent
    ],
    declarations: [
        FiltersComponent,
        SwiperDirective
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA] // Required for Swiper elements
})
export class FiltersModule {
}
