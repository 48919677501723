<div class="content"
     [@listVisible]="isSidebarVisible ? 'visible' : 'hidden'">
    <ion-text class="h4" i18n>{{title}}</ion-text>

    <ng-container *ngIf="!isLoading">
        <ng-content select="[content]"></ng-content>
    </ng-container>

    <!-- Loading -->
    <ng-container content *ngIf="isLoading">
        <div *ngFor="let item of [1,2]" class="skeleton">
            <ion-text>
                <ion-skeleton-text animated></ion-skeleton-text>
            </ion-text>
            <div class="display-flex">
                <ion-skeleton-text animated></ion-skeleton-text>
                <ion-skeleton-text animated></ion-skeleton-text>
            </div>
        </div>
    </ng-container>
</div>
<div class="details-content"
     [@contentVisible]="isDetailsVisible ? 'visible' : 'hidden'">
    <ng-content select="[details]"></ng-content>
</div>
