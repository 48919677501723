/**
 * Origin: https://github.com/ultrasonicsoft/ng-connection-service
 *
 * Implemented to keep the service up to date
 */


import { EventEmitter, Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    fromEvent,
    Observable,
    Subscription,
    debounceTime,
    startWith
} from 'rxjs';
import { clone, defaults } from 'lodash';
import { unsubscribe, unsubscribeAll } from '../util/subscriptions.util';

/**
 * Instance of this interface is used to report current connection status.
 */
export interface ConnectionState {
    /**
     * "True" if browser has network connection. Determined by Window objects "online" / "offline" events.
     */
    hasNetworkConnection: boolean;
    /**
     * "True" if browser has Internet access. Determined by heartbeat system which periodically makes request to heartbeat Url.
     */
    hasInternetAccess: boolean;
}

/**
 * Instance of this interface could be used to configure "ConnectionStatusService".
 */
export interface ConnectionStatusServiceOptions {
    connectionEventEmitter?: EventEmitter<{isDisconnected: boolean, hasWSError: boolean}>;

}

/**
 * InjectionToken for specifing ConnectionStatusService options.
 */
export const ConnectionStatusServiceOptionsToken: InjectionToken<ConnectionStatusServiceOptions> = new InjectionToken('ConnectionStatusServiceOptionsToken');

@Injectable({
    providedIn: 'root'
})
export class ConnectionStatusService {
    private static DEFAULT_OPTIONS: ConnectionStatusServiceOptions = {
        connectionEventEmitter: new EventEmitter<{isDisconnected: boolean, hasWSError: boolean}>()
    };

    private stateChangeEventEmitter = new EventEmitter<ConnectionState>();

    private currentState: ConnectionState = {
        hasInternetAccess: false,
        hasNetworkConnection: window.navigator.onLine
    };
    private offlineSubscription: Subscription;
    private onlineSubscription: Subscription;
    private httpSubscription: Subscription;
    private readonly intervalSubscription;
    private serviceOptions: ConnectionStatusServiceOptions;

    /**
     * Current ConnectionStatusService options. Notice that changing values of the returned object has not effect on service execution.
     * You should use "updateOptions" function.
     */
    get options(): ConnectionStatusServiceOptions {
        return clone(this.serviceOptions);
    }

    constructor(
        private http: HttpClient,
        @Inject(ConnectionStatusServiceOptionsToken) @Optional() options: ConnectionStatusServiceOptions
    ) {
        this.serviceOptions = defaults({}, options, ConnectionStatusService.DEFAULT_OPTIONS);

        this.checkNetworkState();
        this.checkInternetState();

        clearInterval(this.intervalSubscription);
        this.intervalSubscription = setInterval(() => {
                this.emitEvent();
            }, 3000
        );
    }

    private checkInternetState() {
        unsubscribe(this.httpSubscription);
        this.httpSubscription = this.serviceOptions.connectionEventEmitter.subscribe(async connection => {
            this.currentState.hasInternetAccess = !connection.hasWSError && !connection.isDisconnected;
            this.emitEvent();
        });
    }

    private checkNetworkState() {
        unsubscribe(this.onlineSubscription);
        this.onlineSubscription = fromEvent(window, 'online').subscribe(() => {
            this.currentState.hasNetworkConnection = true;
            this.checkInternetState();
            this.emitEvent();
        });

        unsubscribe(this.offlineSubscription);
        this.offlineSubscription = fromEvent(window, 'offline').subscribe(() => {
            this.currentState.hasNetworkConnection = false;
            this.checkInternetState();
            this.emitEvent();
        });
    }

    private emitEvent() {
        this.stateChangeEventEmitter.emit(this.currentState);
    }

    ionViewWillLeave(): void {
        unsubscribeAll([
            this.offlineSubscription,
            this.onlineSubscription,
            this.httpSubscription
        ]);
        clearInterval(this.intervalSubscription);
    }

    /**
     * Monitor Network & Internet connection status by subscribing to this observer. If you set "reportCurrentState" to "false" then
     * function will not report current status of the connections when initially subscribed.
     * @param reportCurrentState Report current state when initial subscription. Default is "true"
     */
    monitor(reportCurrentState = true): Observable<ConnectionState> {
        return reportCurrentState ?
            this.stateChangeEventEmitter.pipe(
                debounceTime(300),
                startWith(this.currentState),
            )
            :
            this.stateChangeEventEmitter.pipe(
                debounceTime(300)
            );
    }

    /**
     * Update options of the service. You could specify partial options object. Values that are not specified will use default / previous
     * option values.
     * @param options Partial option values.
     */
    updateOptions(options: Partial<ConnectionStatusServiceOptions>) {
        this.serviceOptions = defaults({}, options, this.serviceOptions);
        this.checkInternetState();
    }

}
