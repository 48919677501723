import { Injectable } from '@angular/core';
import { NavigationStart, Router, RouterEvent, Event } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ComponentProps, ComponentRef } from '@ionic/core';
import { filter, Subscription } from 'rxjs';
import { unsubscribeAll, unsubscribe } from '../util/subscriptions.util';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    routerSubscription: Subscription;
    currentUrl: string;
    modalIds: Array<string> = [];

    constructor(
        public modalController: ModalController,
        private router: Router
    ) { }

    public async create(
        component: ComponentRef,
        componentProps: ComponentProps<ComponentRef> = {}
    ): Promise<HTMLIonModalElement> {
        const cssClass = component['modalClass'] ? component['modalClass'] : '';
        return await this.modalController.create({
            component,
            componentProps,
            cssClass,
            id: this.generateUniqueId()
        });
    }

    clearRouterSubscriptions = () => {
        unsubscribeAll([
            this.routerSubscription
        ]);
    }

    async present(modal: HTMLIonModalElement): Promise<void> {
        this.clearRouterSubscriptions();
        this.currentUrl = this.router.url;
        unsubscribe(this.routerSubscription);
        this.routerSubscription = this.router.events
            .pipe(filter( event => event instanceof NavigationStart))
            .subscribe(async (event: Event|RouterEvent) => {
            if (event instanceof RouterEvent && event.url !== this.currentUrl) {
                if (this.modalController) {
                    await this.dismiss();

                    // close all existing modals
                    while (this.modalIds.length > 0) {
                        await this.dismiss(undefined, undefined, this.modalIds.shift());
                    }
                    this.clearRouterSubscriptions();
                }
            }
        });
        return await modal.present();
    }

    public async dismiss(data?: any, role?: string, id?: string): Promise<boolean> {
        this.clearRouterSubscriptions();

        if (this.modalController) {
            try {
                return await this.modalController.dismiss(data, role, id);
            } catch {}
        }
        return new Promise(() => true);
    }

    /**
     * Generate a unique id for closing the element later
     * @private
     */
    private generateUniqueId() {
        let id = 'modal_view_' + Math.round(Math.random() * 10000000);
        while(this.modalIds.indexOf(id) > -1) {
            id = 'modal_view_' + Math.round(Math.random() * 10000000);
        }
        this.modalIds.push(id);
        return id;
    }
}
