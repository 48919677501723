<td class="col-bulk"
    [class.hidden]="!bulkModeActive() || bulkSelectAction() === BulkActionCodeEnum.DEFAULT">
    <ion-item lines="none" mode="md" *ngIf="bulkSelection() as bulkSelection">
        <ion-checkbox
                class="ion-justify-content-center"
                [disabled]="bulkSelection.length >= bulkSelectionCountMax && !(row?.id | isInArrayNotPure: bulkSelection)"
                [checked]="row?.id | isInArrayNotPure: bulkSelection"
                (ionChange)="onBulkItemClicked($event, row.id)"
                mode="md"
        ></ion-checkbox>
    </ion-item>
</td>
