import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Dictionary } from 'ts-essentials';
import { CommonState } from '../../../../../store/src/common-store/common.state';
import { Logger } from '../../../../../essentials/util/src/logger';
import { SubscriptionErrorHandlerService } from './subscription-error-handler.service';

const logger = new Logger('SubscriptionManagementService');

@Injectable({ providedIn: 'root' })
export class SubscriptionManagementService {
  private subscriptions: Dictionary<Subscription> = {};

  constructor(
    private store: Store<CommonState>,
    private subscriptionErrorHandlerService: SubscriptionErrorHandlerService
  ) {}

  subscribe<T>(key: string, observable: Observable<T>, dataHandler: (value: T) => void) {
    this.resetSubscription(key);
    this.subscriptions[key] = observable.subscribe({
      next: (data) => {
        this.subscriptionErrorHandlerService.resetSubscriptionErrors();
        dataHandler(data);
      },
      error: (error) => {
        delete this.subscriptions[key];
        this.subscriptionErrorHandlerService.subscriptionError$.next(error);
      },
      complete: () => {
        delete this.subscriptions[key];
      },
    });
  }

  public unsubscribeAll(): void {
    logger.info('Unsubscribing all subscriptions');
    for (const key of Object.keys(this.subscriptions)) {
      this.resetSubscription(key);
    }
    this.subscriptions = {};
  }

  private resetSubscription = (subscriptionKey: string) => {
    const subscription = this.subscriptions[subscriptionKey];
    if (subscription) {
      logger.info(`Unsubscribing ${subscriptionKey}`);
      subscription.unsubscribe();
      delete this.subscriptions[subscriptionKey];
    }
  };
}
