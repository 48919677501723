<app-modal *ngIf="terms$ | async as terms; else loading" [title]="pageType === pageTypes.GENERAL_TERMS ? 'Allgemeine Geschäftsbedingungen Sanacorp' : terms.title" i18n-title>
    <ng-container *ngIf="pageType === pageTypes.GENERAL_TERMS; else staticPagesContent" content>
        <app-pdf-viewer
                *ngIf="terms?.file"
                class="pdf-viewer"
                [type]="pdfDownloadType"
                [payload]="{id: terms.id, filename: terms.title, url: terms.file}"
                [useGeneralTooltips]="true"
        ></app-pdf-viewer>
        <ion-text class="content ion-padding-bottom display-inline-block" i18n>
            <p>
                <b>Zustimmung zur Aktualisierung unserer Allgemeinen Geschäftsbedingungen<br/> (nachfolgend „AGB“) betreffend Lieferungen und Leistungen:</b>
            </p>
            <p>
                Wir passen unsere AGB zum 01.01.2024 an, um den neuesten gesetzlichen Anforderungen zu entsprechen, die Verständlichkeit und Klarheit zu verbessern sowie die technologischen Entwicklungen und Änderungen in der Art und Weise, wie wir unsere Lieferungen und Leistungen erbringen, darzustellen.
            </p>
            <p>
                Die neuen AGB können Sie <a [href]="terms.synopsis" target="_blank">hier</a> abrufen, herunterladen und abspeichern. Dort finden Sie auch eine Übersicht über die konkreten Änderungen.
            </p>
            <p>
                Die AGB in der neuen Fassung sind unter oben genanntem Link wie auch ab 01.01.2024 unter <a href="https://www.sanacorp.de/agb/" target="_blank">https://www.sanacorp.de/agb/</a> dauerhaft hinterlegt und jederzeit für Sie einsehbar.
            </p>
            <p>
                Wir bitten Sie, die neuen AGB durch Bestätigung über Sanacorp Connect zu akzeptieren.
            </p>
            <p>
                Im Anschluss erhalten Sie eine Bestätigungsemail. Bitte bestätigen Sie den Erhalt durch einen entsprechenden Klick auf den bereitgestellten Link in der Bestätigungsemail. Zukünftige Änderungen werden Ihnen grundlegend über dieses Portal und auf unserer Website mitgeteilt. Beachten Sie bitte auch die entsprechende Aktualisierung der Nutzungsbedingungen auf diesem Portal.
            </p>
            <p>
                Nach dem 31.12.2023 neu getätigte Bestellungen werden wir auf Basis und unter Einbeziehung der neuen AGB ausliefern.
            </p>
        </ion-text>
    </ng-container>
    <ng-template #staticPagesContent>
        <ng-container content>
            <markdown class="ion-padding-bottom display-inline-block">{{terms.content}}</markdown>
        </ng-container>
    </ng-template>
    <ng-container *ngIf="pageType === pageTypes.GENERAL_TERMS" footer>
        <ng-container  *ngrxLet="showAccept$ | async as showAccept">
            <ion-button class="button-tertiary" (click)="closeModal()" i18n>Abbrechen</ion-button>
            <ion-button
                    [class.disabled]="showAccept.isDisabled"
                    *ngIf="showAccept.show"
                    (click)="!showAccept.isDisabled ? acceptTerms() : null"
                    color="primary"
                    appTooltip
                    [ttk]="showAccept.isDisabled ? 'terms_accept_button_deactivated' : null"
                    ttp="top"
                    i18n>
                Akzeptieren
            </ion-button>
            <ng-container *ngrxLet="acceptedTerms$ | async as acceptedTerms">
                <div class="accept" *ngIf="showAccept.isAccepted" i18n>
                    Akzeptiert am {{acceptedTerms?.acceptedAt ? (acceptedTerms.acceptedAt | dateTimeToDate: true) : 'Datum unbekannt'}}
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</app-modal>
<ng-template #loading>
    <div class="default-loader with-bg-light is-overlay">
        <ion-spinner name="crescent" color="primary"></ion-spinner>
    </div>
</ng-template>

