import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getValueById'
})
export class GetValueByIdPipe implements PipeTransform {

    transform(values: any, params: {id: any, field: string, altField: string} = null): any {
        const filteredValue = values.filter(item => item.id === params.id)[0];
        if(filteredValue && filteredValue[params.field]) {
            return filteredValue[params.field];
        } else if(filteredValue && filteredValue[params.altField]) {
            return filteredValue[params.altField];
        }
        return null;
    }

}

@Pipe({
    name: 'getValueByParam'
})
export class GetValueByParam implements PipeTransform {

    transform(values: any, params: {id: any, field: string, returnField: string} = null): any {
        const filteredValue = values.filter(item => item[params.field] === params.id)[0];
        if(filteredValue &&filteredValue[params.returnField]) {
            return filteredValue[params.returnField];
        }
        return null;
    }

}
