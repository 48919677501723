import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrderQueries } from '../../../../../../core/store/graphql/queries/order.graphql';
import { OrderFilterHistoryInterface } from '../../../../../../core/interfaces/order.interface';
import { OrderDefaultFilters } from '../../../../../../core/config/order-default-filters.config';
import {
    formatDateTimeToMoment,
    formatMomentDateToDefaultDateTimeFormat
} from '../../../../../../core/formatting/date.formatting';
import { DateRangeOptionCodes } from '../../../../../../core/enums/date-range.enum';
import { unsubscribe } from '../../../../../../core/core.utils';

@Component({
    selector: 'app-order-search-history',
    templateUrl: './order-search-history.component.html',
    styleUrls: ['./order-search-history.component.scss'],
})
export class OrderSearchHistoryComponent implements OnInit {

    @Output() adoptSearch = new EventEmitter<{
        filters: OrderFilterHistoryInterface,
        shouldExecuteSearch: boolean
        }>();


    orderFilterHistorySubscription: Subscription;
    searchHistoryItems = [];
    selectedSearchHistoryItem = null;
    isLoading = true;
    isDetailsVisible = false;
    defaultFilters = OrderDefaultFilters;
    individualDateRange = DateRangeOptionCodes.individual;
    allDateRange = DateRangeOptionCodes.all;

    constructor(private orderQueries: OrderQueries) {}

    ngOnInit() {
        unsubscribe(this.orderFilterHistorySubscription);
        this.orderFilterHistorySubscription = this.orderQueries.getOrderFiltersHistory().subscribe(orderFiltersHistory => {
            this.searchHistoryItems = orderFiltersHistory;

            this.isLoading = false;
            unsubscribe(this.orderFilterHistorySubscription);
        });

    }

    onSearchHistoryItemClick(filtersItem, shouldExecuteSearch) {

        const filters: OrderFilterHistoryInterface = {...filtersItem};

        if(filters.expiryDateFrom) {
            filters.expiryDateFrom = formatMomentDateToDefaultDateTimeFormat(formatDateTimeToMoment(filters.expiryDateFrom));
        }

        if(filters.expiryDateTo) {
            filters.expiryDateTo = formatMomentDateToDefaultDateTimeFormat(formatDateTimeToMoment(filters.expiryDateTo));
        }

        if(filters.recTimeFrom) {
            filters.recTimeFrom = formatMomentDateToDefaultDateTimeFormat(formatDateTimeToMoment(filters.recTimeFrom));
        }
        if(filters.recTimeTo) {
            filters.recTimeTo = formatMomentDateToDefaultDateTimeFormat(formatDateTimeToMoment(filters.recTimeTo));
        }

        this.adoptSearch.emit({filters, shouldExecuteSearch});
    }
}
