import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';

export const navigateToLoginPage = createAction(`${ActionType.Navigation}: Navigate to login page`);
export const navigateToSignupPage = createAction(`${ActionType.Navigation}: Navigate to signup page`);
export const redirectAfterDeletedConversation = createAction(
  `${ActionType.Navigation}: Redirect after deleted conversation`,
  props<{ deletedConversationId: string }>()
);

export const navigateToErrorPage = createAction(`${ActionType.Navigation}: Navigate to error page`);
export const navigateFromErrorPage = createAction(`${ActionType.Navigation}: Navigate from error page`);
