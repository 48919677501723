import { Component, Input } from '@angular/core';
import { Logger } from '../../../../../essentials/util/src/logger';
import { MeaButtonComponent } from '../../buttons/mea-button/mea-button.component';
import { OptionalShadowRootComponent } from '../../shadow-dom/optional-shadow-root/optional-shadow-root.component';
import { CustomModalController } from '../controllers/custom-modal.controller';

export interface MeaAlertButton {
  type?: 'primary' | 'secondary' | 'danger';
  text: string;
  role?: string;
  handler?: () => Promise<void> | void;
}

const logger = new Logger('MeaAlert');

@Component({
  selector: 'mea-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  imports: [OptionalShadowRootComponent, MeaButtonComponent],
})
export class AlertComponent {
  @Input() header: string | undefined;
  @Input() body: string | undefined;
  @Input() innerHtml: string | undefined;
  @Input() buttons: MeaAlertButton[] | undefined;

  private buttonIndexOfHandlerInProgress: number | undefined;

  constructor(private modalCtrl: CustomModalController) {}

  async onButtonClick(button: MeaAlertButton, buttonIndex: number) {
    if (button.handler) {
      this.buttonIndexOfHandlerInProgress = buttonIndex;
      try {
        await button.handler();
      } catch (error) {
        logger.error(`Unexpected error in handler of button '${button.text}'`, error);
      }
    }
    return this.modalCtrl.dismiss(undefined, button.role);
  }

  anyHandlerIsInProgress() {
    return this.buttonIndexOfHandlerInProgress !== undefined;
  }

  thisHandlerIsInProgress(buttonIndex: number) {
    return buttonIndex === this.buttonIndexOfHandlerInProgress;
  }
}
