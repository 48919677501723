import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { FetchPolicyKeys as FPK } from '../../../enums/fetch-policy-keys.enum';
import { QueryWrapper } from '../query.wrapper';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { DataChangedKeys as DCK } from '../../../enums/data-changed-keys.enum';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { SafetyDatasheetInterface } from '../../../interfaces/safety-datasheet.interface';

export const GetSafetyDataSheets =  (queryName) => gql`
    query ${queryName}($limit: Int!) {
        safetyDataSheets(limit: $limit) {
            id
            pzn
            created_at
            errorCode
            isFinished
        }
    }
`;

export const GetSafetyDataSheetPdf = gql`
    query  getSafetyDataSheetPdf($id: Int!) {
        SafetyDataSheetPdf(id: $id) {
            status
            message
            errorCode
            data
        }
    }
`;


export const AllSafetyDatasheetQueries  = [
    GetSafetyDataSheets('Test'),
    GetSafetyDataSheetPdf
];

@Injectable()
export class SafetyDatasheetQueries extends QueryWrapper {
    fetchPolicies = {
        [FPK.getSafetyDataSheets]: QueryFetchPolicy.NETWORK_ONLY
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar) {
        super(apollo, dataChangedVar, {
            [DCK.safetyDataSheetsChanged]: [
                FPK.getSafetyDataSheets
            ]
        });
    }

    getSafetyDataSheets(): Observable<SafetyDatasheetInterface[]> {
        const fetchPolicyKey = FPK.getSafetyDataSheets;
        return this.apollo.watchQuery( {
            query: GetSafetyDataSheets(fetchPolicyKey),
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            variables: {
                limit: GraphQLLimits.safetyDataSheets,
            }
        })
            .valueChanges
            .pipe(map(d => d?.data?.['safetyDataSheets'] && d.data['safetyDataSheets']))
            .pipe(map(d => (d as SafetyDatasheetInterface[]).filter(
                item => new Date(item.created_at) >= new Date(new Date().getTime() - 1000 * 60 * 60 * 24)
            )))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<SafetyDatasheetInterface[]> ;
    }

    getSafetyDataSheetPdf(id: number) {
        return this.apollo.watchQuery( {
            query: GetSafetyDataSheetPdf,
            fetchPolicy: QueryFetchPolicy.NETWORK_ONLY,
            variables: {
                id
            }
        })
            .valueChanges
            .pipe(map(d => d?.data?.['SafetyDataSheetPdf'] && d.data['SafetyDataSheetPdf']));
    }

}
