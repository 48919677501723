import { Apollo, gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModuleActivationInterface } from '../../interfaces/moduleActivation.interface';

export const GetModuleActivationState = gql`
    query GetModuleActivationState {
        activeModules @client {
            module
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class ModuleActivationStateVar {
    private defaultValue: ModuleActivationInterface[] = [];
    private moduleActivationStateObservable: Observable<ModuleActivationInterface[]> = new Observable<ModuleActivationInterface[]>();
    private apollo: Apollo;
    private cache;

    constructor(apollo: Apollo) {
        this.apollo = apollo;
    }

    init() {
        if (this.apollo.client) {
            this.cache = this.apollo.client.cache;
            this.moduleActivationStateObservable = new Observable<ModuleActivationInterface[]>(subscriber => {
                this.cache.watch({
                    query: GetModuleActivationState,
                    callback: res => {
                        subscriber.next(res.result && Array.isArray(res.result.activeModules) && res.result.activeModules || this.defaultValue);
                    },
                    immediate: true,
                    optimistic: true
                });
            });
        }
    }

    get(): Observable<ModuleActivationInterface[]> {
        this.init();
        return this.moduleActivationStateObservable;
    }

    set(activeModules) {
        this.init();
        if (this.cache) {
            this.cache.writeQuery({
                query: GetModuleActivationState,
                data: {
                    activeModules
                }
            });
        }
    }
}
