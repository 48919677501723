import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mea-shadow-root',
  template: '<ng-content></ng-content>',
  styleUrls: ['./shadow-root.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: true,
})
export class ShadowRootComponent {}
