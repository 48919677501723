<ion-text class="filter-label" *ngIf="label">{{label}}</ion-text>
<div>
    <ion-icon *ngIf="!searchIconEnd" name="search" class="autocomplete-search-icon" />
    <p-autoComplete
            [(ngModel)]="selectedItem"
            [suggestions]="dataSignal()"
            [emptyMessage]="'Keine Ergebnisse gefunden'"
            showEmptyMessage="true"
            [showClear]="true"
            [inputStyleClass]="inputStyleClass()"
            [scrollHeight]="maxHeightDropdown"
            [placeholder]="placeholder"
            appendTo="body"
            panelStyleClass="autocomplete-panel"
            (onKeyUp)="onUserInput($event?.target?.value)"
            (onSelect)="onSelect($event.value)"
            (onClear)="onSearch($event, 300, true)"
            (onShow)="onDropdownVisibilityChange(true)"
            (onHide)="onDropdownVisibilityChange(false)"
            (completeMethod)="onSearch($event)"
    >
        <ng-template pTemplate="footer">
            <div *ngIf="showHasMore()" class="autocomplete-footer" i18n>Es gibt noch<br>weitere Ergebnisse.<br>Verfeinern Sie die Suche.</div>
        </ng-template>
    </p-autoComplete>
    <ion-icon *ngIf="searchIconEnd && showSearchIcon()" name="search" class="autocomplete-search-icon end" />
</div>
