import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';
import {
  BackendMessage,
  BackendMessageUpdate,
  PaginatedBackendMessages,
} from '../../../../../essentials/types/src/backendMessage';
import Message, { MessageWithMedia } from '../../../../../essentials/types/src/message';
import { DecryptedMessageMedia, ImageMedia } from '../../../../../essentials/types/src/messageMedia';
import { MessageUpdate } from '../reducers/util/chat-reducer.util';

export const newLocalMessage = createAction(
  `${ActionType.Chat}: New local message`,
  props<{ message: BackendMessage }>()
);

export const newOwnBackendMessage = createAction(
  `${ActionType.Chat}: New own backend message`,
  props<{ message: BackendMessage }>()
);

export const newBackendMessage = createAction(
  `${ActionType.Chat}: New backend message`,
  props<{ message: BackendMessage }>()
);

export const updateMessage = createAction(
  `${ActionType.Chat}: Update Message`,
  props<{ message: BackendMessageUpdate }>()
);

export const updateMessages = createAction(
  `${ActionType.Chat}: Update Messages`,
  props<{ segmentUpdates: ({ segmentId: string; messageUpdates: MessageUpdate[] } | undefined)[] }>()
);

export const updateMessageLocally = createAction(
  `${ActionType.Chat}: Update Message Locally`,
  props<{ message: Message }>()
);

export const readMessage = createAction(`${ActionType.Chat}: Read Message`, props<{ message: BackendMessageUpdate }>());

export const readOwnMessage = createAction(
  `${ActionType.Chat}: Read own Message`,
  props<{ message: BackendMessageUpdate }>()
);

export const updateMessageMedia = createAction(
  `${ActionType.Chat}: Update messageMedia`,
  props<{ message: MessageWithMedia; media: DecryptedMessageMedia }>()
);

export const addInitialMessagesForConversation = createAction(
  `${ActionType.Chat}: Add initial messages for conversation`,
  props<{ conversationId: string; segmentMessages: { segmentId: string; messages: PaginatedBackendMessages }[] }>()
);

export const addMoreMessagesForConversation = createAction(
  `${ActionType.Chat}: Add more messages for conversation`,
  props<{ conversationId: string; segmentMessages: { segmentId: string; messages: PaginatedBackendMessages }[] }>()
);

export const initMessagesForConversation = createAction(
  `${ActionType.Chat}: Init messages for conversation`,
  props<{ conversationId: string }>()
);

export const loadInitialMessagesForConversation = createAction(
  `${ActionType.Chat}: Load initial messages for conversation`,
  props<{ conversationId: string }>()
);

export const loadInitialMessagesForConversationSuccess = createAction(
  `${ActionType.Chat}: Load initial messages for conversation success`,
  props<{ conversationId: string }>()
);

export const loadInitialMessagesForConversationFailure = createAction(
  `${ActionType.Chat}: Load initial messages for conversation failure`,
  props<{ conversationId: string }>()
);

export const loadMoreMessagesForConversation = createAction(
  `${ActionType.Chat}: Load more messages for conversation`,
  props<{ conversationId: string }>()
);

export const deleteMessage = createAction(
  `${ActionType.Chat}: delete message`,
  props<{ message: Message; confirmDeletion?: boolean }>()
);

export const saveExifOrientationChange = createAction(
  `${ActionType.Chat}: Save exif orientation change`,
  props<{ image: ImageMedia; exifOrientation: number; messageId: string }>()
);
