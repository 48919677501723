import { createSelector } from '@ngrx/store';
import { CommonState } from '../../common.state';

const selectDeviceData = (state: CommonState) => state.deviceData;

export const selectFrontendSessionId = createSelector(selectDeviceData, ({ frontendSessionId }) => frontendSessionId);
export const selectIsOnline = createSelector(selectDeviceData, ({ isOnline }) => isOnline);
export const selectIsVisible = createSelector(selectDeviceData, ({ isVisible }) => isVisible);
export const selectIsSmallDevice = createSelector(selectDeviceData, ({ isSmallDevice }) => isSmallDevice);

export const selectShowEmailNotification = createSelector(
  selectDeviceData,
  ({ showEmailNotification }) => showEmailNotification
);

export const selectIsEmailNotificationMinimised = createSelector(
  selectDeviceData,
  ({ minimiseEmailNotification }) => minimiseEmailNotification
);

export const selectAppearance = createSelector(selectDeviceData, ({ appearance }) => appearance);
