import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import videojs from 'video.js/dist/alt/video.core.novtt.js';
// https://github.com/videojs/videojs-vimeo/issues/152 - reason why the vimeo-plugin from videojs is not used
// import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm';

interface VideoPlayerOptionsInterface {
    fluid?: boolean;
    aspectRatio?: string;
    preload?: string;
    controls?: boolean;
    autoplay?: boolean;
    overrideNative?: boolean;
    techOrder?: string[];
    vimeo?: {color: string};
    html5?: {
        nativeVideoTracks?: boolean;
        nativeAudioTracks?: boolean;
        nativeTextTracks?: boolean;
        hls?: {
            withCredentials?: boolean;
            overrideNative?: boolean;
            debug?: boolean;
        };
    };
    sources?: {
        src: string,
        type: string,
    }[];
}

export const ValidVideoFormatsArray = [
    'mp4',
    'webm'
];

@Injectable({
    providedIn: 'root',
})
export class VideoPlayerService {
    defaultOptions: VideoPlayerOptionsInterface = {
        fluid: true,
        preload: 'metadata',
        controls: true,
        autoplay: false,
        overrideNative: true,
        vimeo: {color: '#fbc51b'},
        html5: {
            nativeVideoTracks: false,
            nativeAudioTracks: false,
            nativeTextTracks: false,
            hls: {
                withCredentials: false,
                overrideNative: true,
                debug: false
            }
        }
    };
    interval = [];
    players = [];

    constructor(private sanitizer: DomSanitizer) {
    }

    /**
     * return video player and html
     *
     * @param videoSrcArray - Array of video sources
     * @param specialOptions - Special options for video player
     */
    init(videoSrcArray: string[], specialOptions: VideoPlayerOptionsInterface = {}) : SafeHtml{
        const playerId = 'video_player_' +  Math.round(Math.random() * 10000000);
        let options: any = {...this.defaultOptions, ...specialOptions};
        if (videoSrcArray) {
            const videoSrc = videoSrcArray.map((src) => ({
                src: this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(src))
            }));
            options = {...options, ...{sources: videoSrc}};
        }
        if (options.sources) {
            options.sources = options.sources.map((option) => {
                if (option.src && !option.type) {
                    const srcParts = option.src.split('.');
                    const fileFormat = srcParts.pop();
                    if (ValidVideoFormatsArray.includes(fileFormat)) {
                        option.type = 'video/' + fileFormat;
                    } else if (option.src.includes('vimeo')) {
                        option.type = 'video/vimeo';
                    } else {
                        option.type = 'video/' + ValidVideoFormatsArray[0];
                    }
                }
                return option;
            });
        }

        // do not add await here - because it needs to be started after the html is displayed
        void this.startPlayer(playerId, options);
        return this.sanitizer.bypassSecurityTrustHtml(`<video id="${playerId}" class="video-js"></video>`);
    }

    async startPlayer(playerId, options) {
        /**
         * example
         * this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
         *     console.log('onPlayerReady', this);
         * });
         */
        let i = 0;
        this.interval[playerId] = setInterval(() => {
            if (document.getElementById(playerId)) {
                 this.players[playerId] = videojs(playerId, options);
                 clearInterval(this.interval[playerId]);
            } else if (i > 5) {
                clearInterval(this.interval[playerId]);
            }
            i++;
        }, 200);
    }

    /**
     * destroys the player
     */
    destroyPlayer() {
        // destroys player
        if (this.players.length > 0) {
            this.players.forEach(player => {
                if (player) {
                    player.dispose();
                }
            });
        }
        if (this.interval.length > 0) {
            this.interval.forEach(id => {
                clearInterval(id);
            });
        }
    }
}
