import { Injectable } from '@angular/core';
import { Logger } from '../../../../../essentials/util/src/logger';
import { EncryptionService } from './encryption.service';

const logger = new Logger('PrivateKeyValidationService');

@Injectable({
  providedIn: 'root',
})
export class PrivateKeyValidationService {
  constructor(private encryptionService: EncryptionService) {}

  isPrivateKeyValid(privateKey: string | null | undefined, publicKey: string): boolean {
    logger.info('Validating private key');

    if (!privateKey) {
      return false;
    }
    const testText = 'my-test-password';
    const encrypted = this.encryptionService.encryptUsingPublicKey(testText, publicKey);
    const decrypted = this.encryptionService.decryptUsingPrivateKey(encrypted as any, privateKey);
    if (decrypted !== testText) {
      logger.errorWithFingerprint('Failed to validate private key', 'signin');
    }
    return decrypted === testText;
  }
}
