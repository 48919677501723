import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { BackendMeaUser } from '../../../../../../essentials/types/src/backendMeaUser';
import BackendUserConversation from '../../../../../../essentials/types/src/backendUserConversation';
import { MeaUser } from '../../../../../../essentials/types/src/chatUser';
import { Conversation } from '../../../../../../essentials/types/src/conversation';
import { ConversationIntent } from '../../../../../../essentials/types/src/conversationIntent';
import { LoadStatus } from '../../../../../../essentials/types/src/loadStatus';
import { PharmacyWithChatUser } from '../../../../../../essentials/types/src/pharmacy';
import { InitialMessageUtil } from '../../../../../../essentials/util/src/initial-message.util';
import { Logger } from '../../../../../../essentials/util/src/logger';
import { MeaUserMappingUtil } from '../../../../../../essentials/util/src/mea-user-mapping.util';
import { isNotNullOrUndefined } from '../../../../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import {
  createConversation,
  initConversations,
  loadConversations,
  loadConversationsFailure,
  newConversation,
  newOrUpdateConversation,
  openImagePickerOnInit,
  setActiveConversation,
  setConversations,
} from '../../../../../../store/src/common-store/chat-store/actions/chat-conversation.actions';
import {
  newAppointment,
  newShoppingCartInChat,
  updateAppointment,
  updatedBackendAppointment,
  updatedBackendShoppingCartInChat,
  updateShoppingCartInChat,
} from '../../../../../../store/src/common-store/chat-store/actions/chat-decryption.actions';
import { createAndSendMessage } from '../../../../../../store/src/common-store/chat-store/actions/message-send.actions';
import {
  selectConversation,
  selectConversationPassword,
  selectConversations,
  selectConversationsLoadStatus,
} from '../../../../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { CommonState } from '../../../../../../store/src/common-store/common.state';
import { selectUser } from '../../../../../../store/src/common-store/user-store/selectors/user.selectors';
import { openAppointmentBookingModal } from '../../../../../../store/src/web/appointments/appointment.actions';
import { AppsyncConversationService } from '../../../services/appsync/appsync-conversation.service';
import { ConversationCreationService } from '../../../services/conversation-creation.service';
import { DynamicPropertiesService } from '../../../services/dynamic-properties.service';
import { VariableReplacementService } from '../../../services/variable-replacement.service';

const logger = new Logger('ChatConversationEffects');

@Injectable()
export class ChatConversationEffects {
  initConversations$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initConversations),
        withLatestFrom(this.store.select(selectConversationsLoadStatus)),
        map(([_, conversationsLoadStatus]) => {
          if (conversationsLoadStatus === LoadStatus.Init) {
            this.store.dispatch(loadConversations({ conversationsLoadStatus: LoadStatus.LoadingInitial }));
          } else if (conversationsLoadStatus === LoadStatus.Stale) {
            this.store.dispatch(loadConversations({ conversationsLoadStatus: LoadStatus.Revalidating }));
          }
        })
      ),
    { dispatch: false }
  );

  loadConversations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadConversations),
      switchMap(async () => {
        try {
          const conversationsAndLastMessages = await this.appsyncConversationService.getAllConversations();
          return setConversations({ conversationsAndLastMessages });
        } catch (e) {
          logger.error('Error initializing conversations', e);
          return loadConversationsFailure();
        }
      })
    )
  );

  setActiveConversation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setActiveConversation),
        withLatestFrom(this.store.select(selectConversations)),
        mergeMap(async ([{ id }, conversations]) => {
          try {
            const conversationToUpdate = conversations.find((conversation) => conversation.id === id) as Conversation;
            if (conversationToUpdate) {
              const updatedConversation =
                await this.appsyncConversationService.getUpdatesForConversation(conversationToUpdate);
              if (updatedConversation) {
                this.store.dispatch(newOrUpdateConversation({ conversation: updatedConversation }));
              }
            }
          } catch (e) {
            logger.error('Error updating conversation', e);
          }
        })
      ),
    { dispatch: false }
  );

  createConversation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createConversation),
        withLatestFrom(this.store.select(selectUser).pipe(isNotNullOrUndefined())),
        mergeMap(async ([{ chatPartner, intent }, currentUser]) => {
          const createdConversation = await this.conversationCreationService.createNewConversation(
            currentUser,
            chatPartner,
            { intent }
          );
          this.store.dispatch(newConversation({ conversation: createdConversation }));

          if (intent === 'UPLOAD_PRESCRIPTION') {
            this.store.dispatch(openImagePickerOnInit({ conversationId: createdConversation.conversationId }));
          } else if (intent === 'BOOK_APPOINTMENT') {
            this.store.dispatch(openAppointmentBookingModal());
          } else {
            await this.sendInitialMessage(currentUser, createdConversation, intent);
          }
        })
      ),
    { dispatch: false }
  );

  updatedBackendAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatedBackendAppointment),
      mergeMap(async ({ conversationId, encryptedAppointment }) => {
        const existingConversation = await firstValueFrom(this.store.select(selectConversation(conversationId)));
        if (existingConversation && !existingConversation.encryptedAppointment) {
          this.store.dispatch(newAppointment());
        }
        return updateAppointment({ conversationId, encryptedAppointment });
      })
    )
  );

  updatedBackendShoppingCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatedBackendShoppingCartInChat),
      mergeMap(async ({ conversationId, encryptedShoppingCart }) => {
        const existingConversation = await firstValueFrom(this.store.select(selectConversation(conversationId)));
        if (existingConversation && !existingConversation.encryptedShoppingCart) {
          this.store.dispatch(newShoppingCartInChat());
        }
        return updateShoppingCartInChat({ conversationId, encryptedShoppingCart });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private appsyncConversationService: AppsyncConversationService,
    private conversationCreationService: ConversationCreationService,
    private dynamicPropertiesService: DynamicPropertiesService,
    private store: Store<CommonState>,
    private variableReplacementService: VariableReplacementService
  ) {}

  private async sendInitialMessage(
    currentUser: MeaUser,
    createdConversation: BackendUserConversation,
    intent: ConversationIntent
  ) {
    const pharmacy = this.getPharmacy(createdConversation);
    if (!pharmacy) {
      return;
    }
    const appContext = InitialMessageUtil.mapConversationIntentToCurrentAppContext(intent);
    const isOpen = this.dynamicPropertiesService.getDynamicProperties(pharmacy).isOpen;
    const template = InitialMessageUtil.getTemplate({ appContext, isOpen }, pharmacy.pharmacyChatUser.initialMessages);
    const htmlContent = this.variableReplacementService.replaceVariables(template, {
      chatPartnerName: currentUser.chatname,
      pharmacy,
    });
    const messageInfo = {
      textMessage: htmlContent,
      senderId: currentUser.cognitoId,
      conversationId: createdConversation.conversationId,
      additionalMetadata: {
        displayAsPharmacyMessage: true,
        automatic: true,
        initialMessage: true,
      },
    };
    await firstValueFrom(
      this.store.select(selectConversationPassword(createdConversation.conversationId)).pipe(isNotNullOrUndefined())
    );
    this.store.dispatch(createAndSendMessage(messageInfo));
  }

  private getPharmacy(createdConversation: BackendUserConversation): PharmacyWithChatUser | undefined {
    const participants = createdConversation.participants as BackendMeaUser[];
    const chatPartner = participants.find((participant) => participant.cognitoId !== createdConversation.ownerId);
    const backendPharmacy = chatPartner?.pharmacy;
    if (!backendPharmacy) {
      return undefined;
    }
    return {
      ...backendPharmacy,
      pharmacyChatUser: MeaUserMappingUtil.mapBackendMeaUserToMeaUser(backendPharmacy.pharmacyChatUser),
    };
  }
}
