import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-meamind-tag',
    templateUrl: 'meamind-tag.component.html',
    styleUrls: ['meamind-tag.component.scss']
})
export class MeamindTagComponent {
    @Input() tag: string;
    @Input() tagIconVisible = true;

    constructor() {}
}
