import gql from 'graphql-tag';

export default gql`
  mutation createUserKey($user: ID!, $salt: String!, $key: String!) {
    createUserKey(userKey: { user: $user, salt: $salt, key: $key }) {
      __typename
      user
      salt
      key
    }
  }
`;
