import { Injectable } from '@angular/core';
import { ReturnsFiltersInterface } from '../interfaces/returns.interface';
import { ReturnsQueries } from '../store/graphql/queries/returns.graphql';
import { ReturnsMutations } from '../store/graphql/mutations/returns.graphql';
import { defaultReturnsFilters } from '../config/returns-filter.config';

@Injectable({
    providedIn: 'root'
})
export class OldReturnsService {
    constructor(
        private returnQueries: ReturnsQueries,
        private returnsMutations: ReturnsMutations
    ) {
    }

    /**
     * Save updated returns filters.
     *
     * @param updatedFilters - Updated filters
     */
    saveReturnsFilters(updatedFilters: ReturnsFiltersInterface) {
        if (updatedFilters && updatedFilters.id) {
            delete updatedFilters.id;
        }
        this.returnsMutations.upsertReturnsFilters({...defaultReturnsFilters, ...(updatedFilters || {})});


    }
}
