export const mailContentConfig: any = {
    requestAccessData: {
        content: `
        ?subject=Registrierung Sanacorp Connect&body=Guten Tag,%0D%0A%0D%0A
        unsere Apotheke interessiert sich für Sanacorp Connect.%0D%0A
        Wir bitten Sie um eine Rückmeldung zur Registrierung.%0D%0A%0D%0A
        Unsere Sanacorp Kundennummer:%0D%0A
        Sanacorp Niederlassung:%0D%0A
        Apothekenname:%0D%0A
        Apothekenadresse:%0D%0A%0D%0A%0D%0A
        Mit diesen Daten soll unser Nutzer angelegt werden:%0D%0A
        Vorname, Nachname:%0D%0A
        E-Mail-Adresse:%0D%0A%0D%0A%0D%0A
        Vielen Dank
        `.replace(/\s+/g, ' ')
    }
};
