import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, map, shareReplay } from 'rxjs';
import { UnreadMessagesCountService } from '../../../common/resources/src/services/unread-messages-count.service';
import { ChatConversationsService } from '../../../pharmacy-only/src/services/chat-conversations.service';
import { SconnectConversationsUtil } from './util/sconnect-conversations.util';

@Injectable({
  providedIn: 'root',
})
export class MeaChatConversationsService {
  hasUnreadMessages$ = combineLatest([
    this.chatConversationsService.hasUnreadPharmacyMessage$,
    this.chatConversationsService.hasUnreadEnduserMessage$,
  ]).pipe(
    map(([hasUnreadPharmacyMessage, hasUnreadEnduserMessage]) => hasUnreadEnduserMessage || hasUnreadPharmacyMessage)
  );

  enduserConversations$ = combineLatest([
    this.chatConversationsService.enduserConversationsWithLastMessage$,
    this.chatConversationsService.decryptedChatPartnerMetadataDictionary$,
    this.unreadMessagesCountService.unreadMessageCountPerConversation$,
  ]).pipe(
    map(
      ([conversationsWithLastMessage, decryptedChatPartnerMetadataDictionary, unreadMessagesCountPerConversation]) => {
        if (!conversationsWithLastMessage || conversationsWithLastMessage.length === 0) {
          return [];
        }
        return SconnectConversationsUtil.mapConversationsToSconnectConversations(
          conversationsWithLastMessage,
          decryptedChatPartnerMetadataDictionary,
          unreadMessagesCountPerConversation,
          this.translateService
        );
      }
    ),
    shareReplay(1)
  );

  openEnduserConversations$ = this.enduserConversations$.pipe(
    map((enduserConversations) => enduserConversations.filter((conversation) => conversation.isConversationOpen)),
    shareReplay(1)
  );

  pharmacyConversations$ = combineLatest([
    this.chatConversationsService.pharmacyConversationsWithLastMessage$,
    this.chatConversationsService.decryptedChatPartnerMetadataDictionary$,
    this.unreadMessagesCountService.unreadMessageCountPerConversation$,
  ]).pipe(
    map(
      ([conversationsWithLastMessage, decryptedChatPartnerMetadataDictionary, unreadMessagesCountPerConversation]) => {
        if (!conversationsWithLastMessage || conversationsWithLastMessage.length === 0) {
          return [];
        }
        return SconnectConversationsUtil.mapConversationsToSconnectConversations(
          conversationsWithLastMessage,
          decryptedChatPartnerMetadataDictionary,
          unreadMessagesCountPerConversation,
          this.translateService
        );
      }
    ),
    shareReplay(1)
  );

  constructor(
    private chatConversationsService: ChatConversationsService,
    private translateService: TranslateService,
    private unreadMessagesCountService: UnreadMessagesCountService
  ) {}
}
