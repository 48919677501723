import { inject, Pipe, PipeTransform } from '@angular/core';
import { NotificationsEnum, NotificationsReminderTypeEnum } from '../enums/notifications.enum';
import { OrderService } from '../services/order.service';
import { NotificationAndNotificationReminderInterface, NotificationInterface } from '../interfaces/notification.interface';
import { OrderDetailInterface } from '../interfaces/order.interface';
import { NotificationGoToEntryService } from '../services/notification-go-to-entry.service';
import { PermissionService } from '../services/permission.service';
import { UserAccessRightsEnum } from '../core.enums';

@Pipe({
    name: 'notificationHasEntry'
})
export class NotificationHasEntryPipe implements PipeTransform {
    constructor(
        private notificationGoToEntryService: NotificationGoToEntryService
    ) {
    }
    transform(notificationType: NotificationsEnum | NotificationsReminderTypeEnum): boolean {
        return this.notificationGoToEntryService.checkHasEntry(notificationType);
    }
}

@Pipe({
    name: 'notificationGoToEntryPermission'
})
export class NotificationGoToEntryPermissionPipe implements PipeTransform {
    private permissionService = inject(PermissionService);

    transform(notificationType: NotificationsEnum | NotificationsReminderTypeEnum, returnTypeBoolean: boolean = false): string | boolean {
        switch (notificationType) {
            case NotificationsEnum.NEW_DOCUMENT:
                const hasNoAccess = !this.permissionService.hasUserAccessRights()[UserAccessRightsEnum.INVOICES];
                return returnTypeBoolean ? hasNoAccess : hasNoAccess ? 'ua_no_access_invoice' : null;
            default:
                return returnTypeBoolean ? null : null;
        }
    }
}

@Pipe({
    name: 'notificationAdditionalActionLabel'
})
export class NotificationAdditionalActionLabelPipe implements PipeTransform {
    constructor(private orderService: OrderService) {
    }

    transform(notificationType: NotificationsEnum | NotificationsReminderTypeEnum,
              notification: NotificationInterface | NotificationAndNotificationReminderInterface,
              order: OrderDetailInterface = null): string|null {
        switch (notificationType) {
            case NotificationsEnum.ORDER_DISPO_DIRECT_OUT_OF_DATE:
                return this.orderService.isAcknowledgeEnabled(order) ? 'Ware angekommen' : null;
            case NotificationsEnum.RETURNS_PRODUCT_MISSING:
            case NotificationsEnum.RETURNS_STOCK_ERROR:
                return 'Kundenservice kontaktieren';
            default:
                return null;
        }
    }
}
